import { Bucket } from 'technical/fileManagement/types';

export abstract class FileBucket {
  public static matchBucketPath(path: string): Bucket | null {
    return (
      Object.values(Bucket).find((bucket) => {
        return path.startsWith(bucket);
      }) || null
    );
  }

  protected isBucketPath(path: string, bucket?: Bucket): boolean {
    if (bucket) {
      return path.startsWith(bucket);
    }

    return FileBucket.matchBucketPath(path) !== null;
  }

  protected removeBucketPart(filePath: string): string {
    const filePathParts = filePath.split('/');
    filePathParts.shift();
    return filePathParts.join('/');
  }
}
