// This function remove characters from string which are not recommended by GCP and AWS
// https://docs.aws.amazon.com/AmazonS3/latest/userguide/object-keys.html
// https://cloud.google.com/storage/docs/naming-objects
export const formatWithAuthorizedCharacter = (value: string) =>
  value.replace(/[&$@=;:+,?\\{}^%`[\]"'><~#|\s]/g, '_');

export const capitalizeFirstLetter = (value?: string | null) => {
  if (!value) {
    return '';
  }

  if (value.length === 1) {
    return value.toUpperCase();
  }

  return value.charAt(0).toUpperCase() + value.slice(1);
};
