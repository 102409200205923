export enum Routes {
  Home = '/',
  Dashboard = '/dashboard',
  Academy = '/academy',
  SignIn = '/sign-in',
  Logout = '/api/logout',
  FirstConnection = '/firstConnection',
  Sitemap = '/sitemap',
  NotFound = '/404',

  FormationPage = '/formation/[courseId]',
  ModulePage = '/formation/[courseId]/sequence/[sequenceId]/module/[id]',
  Footer = '/footer',
  FooterCms = '/footer/[cmsKey]',
  ManageAccount = '/account',
}

export enum ExternalRoutes {
  Paris2024 = 'https://www.paris2024.org',
  Youtube = 'https://www.youtube.com/channel/UCg4W1uf-i5X1nVaeWJsKuyA',
  Instagram = 'https://www.instagram.com/paris2024',
  Facebook = 'https://facebook.com/Paris2024/',
  Twitter = 'https://twitter.com/Paris2024',
  Linkedin = 'https://fr.linkedin.com/company/paris-2024-olympic-and-paralympic-games-bid',
}
