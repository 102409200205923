import React, { ReactNode, useState, useEffect } from 'react';
import Media from 'react-media';

const mobileWidth = 767;

export const mediaQuery = `(min-width: ${mobileWidth + 1}px)`;
export const mediaQueryMobile = `(max-width: ${mobileWidth}px)`;

interface Props {
  desktopContent?: ReactNode;
  mobileContent?: ReactNode;
}

export function useDetectWidth() {
  const [winWidth, detectWidth] = useState(0);

  useEffect(() => {
    const detectSize = () => {
      detectWidth(window?.innerWidth);
    };
    return detectSize();
  });

  const isDesktop = winWidth > mobileWidth;

  return {
    winWidth,
    isDesktop,
  };
}

const MediaComponent = ({ desktopContent, mobileContent }: Props) => {
  const { isDesktop, winWidth } = useDetectWidth();

  if (winWidth === 0) {
    return null;
  }

  if (isDesktop) {
    return (
      <>
        <Media queries={{ medium: mediaQuery }} render={() => desktopContent} />
        <Media
          queries={{ medium: mediaQueryMobile }}
          render={() => mobileContent}
        />
      </>
    );
  }

  return (
    <>
      <Media
        queries={{ medium: mediaQueryMobile }}
        render={() => mobileContent}
      />
      <Media queries={{ medium: mediaQuery }} render={() => desktopContent} />
    </>
  );
};

export default MediaComponent;
