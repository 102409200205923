import React from 'react';

export enum FilePathEnum {
  PRIVACY_POLICY = 'public/sitePolicy/AcademieParis2024_Politique_de_confidentialite.pdf',
  PRIVACY_POLICY_FR = 'public/sitePolicy/AcademieParis2024_Politique_de_confidentialite_FR.pdf',
  PRIVACY_POLICY_EN = 'public/sitePolicy/AcademieParis2024_Politique_de_confidentialite_EN.pdf',
  ACCESSIBILITY = 'public/sitePolicy/AcademieParis2024_Accessibilite.pdf',
  LEGAL_TERMS = 'public/sitePolicy/AcademieParis2024_Mentions_legales.pdf',
}

export const renderDownloadLink = (content: string, url?: string) => {
  if (url) {
    const isPdf = url.includes('.pdf');
    return (
      <a href={url} target={isPdf ? '_blank' : ''} download rel="noreferrer">
        {content}
      </a>
    );
  }

  return <>{content}</>;
};
