import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions =  {}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  json: any;
  numeric: any;
  timestamp: any;
  timestamptz: any;
  uuid: any;
};


export type AcceptPolicyResponse = {
  __typename?: 'AcceptPolicyResponse';
  success: Scalars['Boolean'];
};

export type AnonymizeResponse = {
  __typename?: 'AnonymizeResponse';
  anonymized?: Maybe<Scalars['ID']>;
  message?: Maybe<Scalars['String']>;
  success: Scalars['Boolean'];
};

export type AttributesInput = {
  attributeId?: Maybe<Scalars['String']>;
  code: Scalars['String'];
  directionId?: Maybe<Scalars['String']>;
  label: Scalars['String'];
  type: AttributesType;
};

export type AttributesResponse = {
  __typename?: 'AttributesResponse';
  message?: Maybe<Scalars['String']>;
  success: Scalars['Boolean'];
};

export enum AttributesType {
  Direction = 'direction',
  Fa = 'fa'
}

export type BasicResponse = {
  __typename?: 'BasicResponse';
  message?: Maybe<Scalars['String']>;
  success: Scalars['Boolean'];
};

/** Boolean expression to compare columns of type "Boolean". All fields are combined with logical 'AND'. */
export type Boolean_Comparison_Exp = {
  _eq?: Maybe<Scalars['Boolean']>;
  _gt?: Maybe<Scalars['Boolean']>;
  _gte?: Maybe<Scalars['Boolean']>;
  _in?: Maybe<Array<Scalars['Boolean']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['Boolean']>;
  _lte?: Maybe<Scalars['Boolean']>;
  _neq?: Maybe<Scalars['Boolean']>;
  _nin?: Maybe<Array<Scalars['Boolean']>>;
};

/** CourseAssignEmployeeDirectionInput */
export type CaEmployeeDirectionInput = {
  faIds: Array<Scalars['String']>;
  id: Scalars['String'];
};

/** CourseAssignEmployeeInput */
export type CaEmployeeInput = {
  directions: Array<CaEmployeeDirectionInput>;
  forManager?: Maybe<Scalars['Boolean']>;
  forStudent?: Maybe<Scalars['Boolean']>;
};

export type CaVolunteerInput = {
  fa: Array<Scalars['String']>;
  forHolder?: Maybe<Scalars['Boolean']>;
  forParisCityHall?: Maybe<Scalars['Boolean']>;
  forTeamLeader?: Maybe<Scalars['Boolean']>;
  isOlympics?: Maybe<Scalars['Boolean']>;
  isParalympics?: Maybe<Scalars['Boolean']>;
  isPreGames?: Maybe<Scalars['Boolean']>;
  isTestEvent?: Maybe<Scalars['Boolean']>;
  jobTitles: Array<Scalars['String']>;
  sites: Array<Scalars['String']>;
};

export type CmsPageInput = {
  key: Scalars['String'];
  text: TranslateValues;
  title: TranslateValues;
};

export type CmsPageResponse = {
  __typename?: 'CmsPageResponse';
  id?: Maybe<Scalars['String']>;
  success: Scalars['Boolean'];
};

export type CourseAssignInput = {
  courseId: Scalars['String'];
  employee?: Maybe<CaEmployeeInput>;
  forAll: Scalars['Boolean'];
  forEmployee: Scalars['Boolean'];
  forOther: Scalars['Boolean'];
  forVolunteer: Scalars['Boolean'];
  groupIds: Array<Scalars['String']>;
  publishAt: Scalars['String'];
  userIds: Array<Scalars['String']>;
  volunteer?: Maybe<CaVolunteerInput>;
};

export type CourseAssignResponse = {
  __typename?: 'CourseAssignResponse';
  message?: Maybe<Scalars['String']>;
  success: Scalars['Boolean'];
};

export enum CourseCategory {
  Culture = 'culture',
  Skill = 'skill'
}

export type CourseInput = {
  category: CourseCategory;
  completionPercentage: Scalars['Int'];
  description?: Maybe<TranslateValues>;
  descriptionShort?: Maybe<TranslateValues>;
  humanId: Scalars['String'];
  id?: Maybe<Scalars['String']>;
  imageHeadUri?: Maybe<Scalars['String']>;
  sequences?: Maybe<Array<SequenceInput>>;
  skillsOfCourse?: Maybe<Array<SkillOfCourseInput>>;
  status: CourseStatus;
  tagOfCourses?: Maybe<Array<Maybe<Scalars['String']>>>;
  thematicId?: Maybe<Scalars['String']>;
  title: TranslateValues;
};

export enum CourseProgressStatus {
  Completed = 'completed',
  Started = 'started'
}

export type CoursePublicationInput = {
  frontPageOrder?: Maybe<Scalars['Int']>;
  id: Scalars['String'];
  isEmployeesAssignment?: Maybe<Scalars['Boolean']>;
  isInFrontPage?: Maybe<Scalars['Boolean']>;
  isOtherAssignment?: Maybe<Scalars['Boolean']>;
  publicationDate: Scalars['String'];
  publicationScope?: Maybe<Scalars['String']>;
};

export type CourseResponse = {
  __typename?: 'CourseResponse';
  message?: Maybe<Scalars['String']>;
  success: Scalars['Boolean'];
};

export enum CourseStatus {
  Archived = 'archived',
  Draft = 'draft',
  Published = 'published',
  Validated = 'validated',
  WaitingValidation = 'waiting_validation'
}

export type DataAccessRequestInput = {
  status: Scalars['String'];
  type: Scalars['String'];
};

export type DataAccessRequestResponse = {
  __typename?: 'DataAccessRequestResponse';
  message?: Maybe<Scalars['String']>;
  success: Scalars['Boolean'];
};

export type DelegatedTokenResponse = {
  __typename?: 'DelegatedTokenResponse';
  success: Scalars['Boolean'];
  token?: Maybe<Scalars['String']>;
};

export type DeleteDraftCourseResponse = {
  __typename?: 'DeleteDraftCourseResponse';
  message?: Maybe<Scalars['String']>;
  success: Scalars['Boolean'];
};

export type DeleteModuleByIdInput = {
  id: Scalars['String'];
};

export type DeleteModuleByIdResponse = {
  __typename?: 'DeleteModuleByIdResponse';
  message?: Maybe<Scalars['String']>;
  success: Scalars['Boolean'];
};

export type DeleteUserFromEventSlotInput = {
  eventSlotId?: Maybe<Scalars['String']>;
  learnerId?: Maybe<Scalars['String']>;
};

export type DeleteUserFromEventSlotResponse = {
  __typename?: 'DeleteUserFromEventSlotResponse';
  message?: Maybe<Scalars['String']>;
  success: Scalars['Boolean'];
};

export type DownloadUrl = {
  __typename?: 'DownloadUrl';
  key: Scalars['String'];
  url: Scalars['String'];
};

export type DownloadUrlResponse = {
  __typename?: 'DownloadUrlResponse';
  downloadUrls?: Maybe<Array<DownloadUrl>>;
};

export type DuplicateCourseResponse = {
  __typename?: 'DuplicateCourseResponse';
  id?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
  success: Scalars['Boolean'];
};

export type EventData = {
  __typename?: 'EventData';
  firstSlot: Scalars['String'];
  id: Scalars['String'];
  learnerAmount: Scalars['Int'];
  slotAmount: Scalars['Int'];
  title: Scalars['String'];
};

export type EventFilters = {
  contentEventSlotsIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  language?: Maybe<Language>;
  search?: Maybe<Scalars['String']>;
};

export type EventOrderBy = {
  firstSlot?: Maybe<OrderByEnum>;
  learnerAmount?: Maybe<OrderByEnum>;
  slotAmount?: Maybe<OrderByEnum>;
  title?: Maybe<OrderByEnum>;
};

export type ExportMonitoringCourseInput = {
  courseId: Scalars['String'];
  monitoringCourseFilters: MonitoringCourseFilters;
};

export type ExportMonitoringCourseResponse = {
  __typename?: 'ExportMonitoringCourseResponse';
  filename?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
  path?: Maybe<Scalars['String']>;
  success: Scalars['Boolean'];
};

export type FaqInput = {
  language: Language;
  order: Scalars['Int'];
  question: Scalars['String'];
  response: Scalars['String'];
};

export type FaqResponse = {
  __typename?: 'FaqResponse';
  message?: Maybe<Scalars['String']>;
  success: Scalars['Boolean'];
};

export type File = {
  name?: Maybe<Scalars['String']>;
  size?: Maybe<Scalars['Int']>;
  url: Scalars['String'];
};

export type GetModuleResourceUriInput = {
  moduleId: Scalars['String'];
  resourceType: ResourceType;
  resourceUrl: Scalars['String'];
};

export type GetModuleResourceUriResponse = {
  __typename?: 'GetModuleResourceUriResponse';
  message?: Maybe<Scalars['String']>;
  success: Scalars['Boolean'];
  uri?: Maybe<Scalars['String']>;
};

export type GetMonitoringCourseInput = {
  courseId: Scalars['String'];
  monitoringCourseFilters: MonitoringCourseFilters;
  pagination: Pagination;
};

export type GetMonitoringCourseResponse = {
  __typename?: 'GetMonitoringCourseResponse';
  items?: Maybe<Array<MonitoringCourseData>>;
  message?: Maybe<Scalars['String']>;
  success: Scalars['Boolean'];
  total?: Maybe<Scalars['Int']>;
};

export type GetPaginatedEventListInput = {
  filters?: Maybe<EventFilters>;
  orderBy?: Maybe<EventOrderBy>;
  pagination: Pagination;
};

export type GetPaginatedEventListResponse = {
  __typename?: 'GetPaginatedEventListResponse';
  items?: Maybe<Array<EventData>>;
  message?: Maybe<Scalars['String']>;
  success: Scalars['Boolean'];
  total: Scalars['Int'];
};

export type GetPaginatedSlotAssignedLearnerListInput = {
  filters?: Maybe<SlotAssignedLearnerFilters>;
  moduleId?: Maybe<Scalars['String']>;
  orderBy?: Maybe<SlotAssignedLearnerOrderBy>;
  pagination: Pagination;
  slotId?: Maybe<Scalars['String']>;
};

export type GetPaginatedSlotAssignedLearnerListResponse = {
  __typename?: 'GetPaginatedSlotAssignedLearnerListResponse';
  items?: Maybe<Array<SlotAssignedLearnerData>>;
  message?: Maybe<Scalars['String']>;
  stat: SlotAssignedLearnerStat;
  success: Scalars['Boolean'];
  total: Scalars['Int'];
};

export type GroupInput = {
  description: Scalars['String'];
  humanId: Scalars['String'];
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  userSelectedIds?: Maybe<Array<Scalars['String']>>;
};

export type GroupResponse = {
  __typename?: 'GroupResponse';
  id?: Maybe<Scalars['String']>;
  success: Scalars['Boolean'];
};

export type HomeInput = {
  desktopImageUri: Scalars['String'];
  mobileImageUri: Scalars['String'];
  title: TranslateValues;
};

export type HomeResponse = {
  __typename?: 'HomeResponse';
  message?: Maybe<Scalars['String']>;
  success: Scalars['Boolean'];
};

export type ImportGroupInput = {
  humanId: Scalars['String'];
  name: Scalars['String'];
  pathImportFile: Scalars['String'];
};

export type ImportGroupResponse = {
  __typename?: 'ImportGroupResponse';
  doneActions?: Maybe<Scalars['Int']>;
  erroneousLineAmount?: Maybe<Scalars['Int']>;
  importGroupId?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
  pathErrorFile?: Maybe<Scalars['String']>;
  success: Scalars['Boolean'];
};

export type ImportJobTitleInput = {
  pathImportFile: Scalars['String'];
};

export type ImportJobTitleResponse = {
  __typename?: 'ImportJobTitleResponse';
  doneActions?: Maybe<Scalars['Int']>;
  message?: Maybe<Scalars['String']>;
  pathErrorFile?: Maybe<Scalars['String']>;
  success: Scalars['Boolean'];
};

export type ImportSkillInput = {
  pathImportFile: Scalars['String'];
  skillType: SkillType;
};

export type ImportSkillResponse = {
  __typename?: 'ImportSkillResponse';
  doneActions?: Maybe<Scalars['Int']>;
  message?: Maybe<Scalars['String']>;
  pathErrorFile?: Maybe<Scalars['String']>;
  success: Scalars['Boolean'];
};

export type ImportUsersToGroupInput = {
  groupId: Scalars['String'];
  pathImportFile: Scalars['String'];
};

/** Boolean expression to compare columns of type "Int". All fields are combined with logical 'AND'. */
export type Int_Comparison_Exp = {
  _eq?: Maybe<Scalars['Int']>;
  _gt?: Maybe<Scalars['Int']>;
  _gte?: Maybe<Scalars['Int']>;
  _in?: Maybe<Array<Scalars['Int']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['Int']>;
  _lte?: Maybe<Scalars['Int']>;
  _neq?: Maybe<Scalars['Int']>;
  _nin?: Maybe<Array<Scalars['Int']>>;
};

export enum Language {
  En = 'en',
  Fr = 'fr'
}

export type LogoutResponse = {
  __typename?: 'LogoutResponse';
  success: Scalars['Boolean'];
};

export type ModuleAnnexInput = {
  description: TranslateValues;
  link: Scalars['String'];
  title: TranslateValues;
};

export type ModuleCmi5Input = {
  pathArchiveCmi5En?: Maybe<Scalars['String']>;
  pathArchiveCmi5Fr?: Maybe<Scalars['String']>;
  pathFolderCmi5En?: Maybe<Scalars['String']>;
  pathFolderCmi5Fr?: Maybe<Scalars['String']>;
};

export type ModuleContentInput = {
  article?: Maybe<TranslateValues>;
  cmi5?: Maybe<ModuleCmi5Input>;
  contentCmi5Id?: Maybe<Scalars['String']>;
  embed?: Maybe<ModuleEmbedInput>;
  event?: Maybe<ModuleEventInput>;
  podcast?: Maybe<ModulePodcastInput>;
  pptOrPdf?: Maybe<TranslateFiles>;
  video?: Maybe<ModuleVideoInput>;
};

export enum ModuleContentType {
  Article = 'article',
  Cmi5 = 'cmi5',
  Embed = 'embed',
  Event = 'event',
  None = 'none',
  Podcast = 'podcast',
  PptOrPdf = 'ppt_or_pdf',
  Video = 'video'
}

export type ModuleEmbedInput = {
  audioTranscript?: Maybe<TranslateFiles>;
  link?: Maybe<Scalars['String']>;
  subtype?: Maybe<Scalars['String']>;
};

export type ModuleEventInput = {
  attachment?: Maybe<TranslateFiles>;
  eventSlots: Array<ModuleEventSlotInput>;
};

export type ModuleEventSlotInput = {
  address?: Maybe<Scalars['String']>;
  animators: Array<Scalars['String']>;
  description?: Maybe<TranslateValues>;
  endDate: Scalars['String'];
  id?: Maybe<Scalars['String']>;
  maxPlaces?: Maybe<Scalars['Int']>;
  room?: Maybe<Scalars['String']>;
  startDate: Scalars['String'];
  type: Scalars['String'];
  webexLink?: Maybe<Scalars['String']>;
};

export type ModuleInput = {
  annexes?: Maybe<Array<ModuleAnnexInput>>;
  content: ModuleContentInput;
  contentType: ModuleContentType;
  description: TranslateValues;
  duration: Scalars['Int'];
  id?: Maybe<Scalars['String']>;
  imageHeadUri: Scalars['String'];
  isAsynchronous: Scalars['Boolean'];
  tasks?: Maybe<Array<ModuleTaskInput>>;
  title: TranslateValues;
};

export type ModulePodcastInput = {
  podcastId?: Maybe<Scalars['String']>;
  transcriptUrl?: Maybe<TranslateFiles>;
  url: TranslateFiles;
};

export type ModuleResponse = {
  __typename?: 'ModuleResponse';
  humanId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
  success: Scalars['Boolean'];
};

export type ModuleTaskInput = {
  description: TranslateValues;
};

export type ModuleVideoCaptionInput = {
  en?: Maybe<TranslateFiles>;
  fr?: Maybe<TranslateFiles>;
};

export type ModuleVideoInput = {
  audioTranscript?: Maybe<TranslateFiles>;
  captions?: Maybe<ModuleVideoCaptionInput>;
  name: TranslateValues;
  path: TranslateValues;
  videoId: TranslateValues;
};

export type MonitoringCourseData = {
  __typename?: 'MonitoringCourseData';
  completionDate?: Maybe<Scalars['String']>;
  completionPercent: Scalars['Int'];
  completionStatus?: Maybe<CourseProgressStatus>;
  learner: MonitoringUserData;
  spendTime: Scalars['Int'];
};

export type MonitoringCourseFilters = {
  direction?: Maybe<Array<Scalars['String']>>;
  fa?: Maybe<Array<Scalars['String']>>;
  groups?: Maybe<Array<Scalars['String']>>;
};

export type MonitoringUserData = {
  __typename?: 'MonitoringUserData';
  email: Scalars['String'];
  firstName: Scalars['String'];
  id: Scalars['String'];
  lastName: Scalars['String'];
};

export enum OrderByEnum {
  Asc = 'asc',
  Desc = 'desc'
}

export type OrganisationAttributeInput = {
  attributeId?: Maybe<Scalars['String']>;
  label: Scalars['String'];
};

export type OrganisationAttributeResponse = {
  __typename?: 'OrganisationAttributeResponse';
  message?: Maybe<Scalars['String']>;
  success: Scalars['Boolean'];
};

export type Pagination = {
  limit: Scalars['Int'];
  offset: Scalars['Int'];
};

export type PublicationCourseResponse = {
  __typename?: 'PublicationCourseResponse';
  message?: Maybe<Scalars['String']>;
  success: Scalars['Boolean'];
};

export type ReferentialInput = {
  skillType: SkillType;
};

export type ReferentialJobTitleResponse = {
  __typename?: 'ReferentialJobTitleResponse';
  message?: Maybe<Scalars['String']>;
  referentialUrl?: Maybe<Scalars['String']>;
  success: Scalars['Boolean'];
};

export type ReferentialResponse = {
  __typename?: 'ReferentialResponse';
  message?: Maybe<Scalars['String']>;
  referentialUrl?: Maybe<Scalars['String']>;
  success: Scalars['Boolean'];
};

export type RefreshTokenResponse = {
  __typename?: 'RefreshTokenResponse';
  accessToken?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
  success: Scalars['Boolean'];
};

export enum ResourceType {
  Document = 'document',
  EmbedAudioTranscription = 'embedAudioTranscription',
  Podcast = 'podcast',
  PodcastAudioTranscription = 'podcastAudioTranscription',
  VideoAudioTranscription = 'videoAudioTranscription',
  VideoCaption = 'videoCaption'
}

export type RoleInput = {
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  roles: Array<Roles>;
};

export type RoleResponse = {
  __typename?: 'RoleResponse';
  message?: Maybe<Scalars['String']>;
  success: Scalars['Boolean'];
};

export enum Roles {
  Admin = 'admin',
  Anonymous = 'anonymous',
  DataManager = 'dataManager',
  ManageAdmin = 'manageAdmin',
  ManageAttribute = 'manageAttribute',
  ManageCms = 'manageCms',
  ManageCourse = 'manageCourse',
  ManageCoursePublication = 'manageCoursePublication',
  ManageDataAccess = 'manageDataAccess',
  ManageEvent = 'manageEvent',
  ManageImportUser = 'manageImportUser',
  ManageLearner = 'manageLearner',
  ManageTag = 'manageTag',
  ManageThematic = 'manageThematic',
  ManageVolunteerAttribute = 'manageVolunteerAttribute',
  User = 'user'
}

export type SaveCurrentUserEventSlotInput = {
  eventSlotId: Scalars['String'];
  isResubscription?: Maybe<Scalars['Boolean']>;
};

export type SaveUsersEventSlotInput = {
  eventSlotId: Scalars['String'];
  isResubscription?: Maybe<Array<Scalars['Boolean']>>;
  learnersId?: Maybe<Array<Scalars['String']>>;
};

export type SaveUsersEventSlotResponse = {
  __typename?: 'SaveUsersEventSlotResponse';
  message?: Maybe<Scalars['String']>;
  success: Scalars['Boolean'];
};

export type SequenceInput = {
  id?: Maybe<Scalars['String']>;
  moduleIds: Array<Scalars['String']>;
  title: TranslateValues;
};

export type SetUserAttendanceSlotInput = {
  eventSlotId: Scalars['String'];
  hasAttended: Scalars['Boolean'];
  userId?: Maybe<Scalars['String']>;
};

export type SetUserStatusResponse = {
  __typename?: 'SetUserStatusResponse';
  anonymized?: Maybe<Scalars['ID']>;
  message?: Maybe<Scalars['String']>;
  success: Scalars['Boolean'];
};

export type SetValidateSlotAttendanceInput = {
  eventSlotId: Scalars['String'];
};

export type SetValidateSlotAttendanceResponse = {
  __typename?: 'SetValidateSlotAttendanceResponse';
  message?: Maybe<Scalars['String']>;
  success: Scalars['Boolean'];
};

export type SignInResponse = {
  __typename?: 'SignInResponse';
  accessToken?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
  success: Scalars['Boolean'];
};

export type SkillInput = {
  family?: Maybe<Scalars['String']>;
  humanId: Scalars['String'];
  id?: Maybe<Scalars['String']>;
  name: TranslateValues;
  referential: Scalars['String'];
  skillLevels: Array<Maybe<SkillLevelInput>>;
};

export type SkillLevelInput = {
  description: TranslateValues;
  id?: Maybe<Scalars['String']>;
  level: Scalars['Int'];
  name: TranslateValues;
};

export type SkillOfCourseInput = {
  level: Scalars['Int'];
  skillId: Scalars['String'];
};

export type SkillResponse = {
  __typename?: 'SkillResponse';
  id?: Maybe<Scalars['String']>;
  success: Scalars['Boolean'];
};

export enum SkillType {
  Transverse = 'transverse',
  Volunteers = 'volunteers'
}

export type SlotAssignedLearnerData = {
  __typename?: 'SlotAssignedLearnerData';
  hasAttended: Scalars['Boolean'];
  id: Scalars['String'];
  isActive: Scalars['Boolean'];
  learner: SlotAssignedLearnerName;
  slot: SlotAssignedLearnerSlot;
  status: SlotAssignedLearnerStatus;
  userEventSlotId?: Maybe<Scalars['String']>;
};

export type SlotAssignedLearnerFilters = {
  search?: Maybe<Scalars['String']>;
};

export type SlotAssignedLearnerName = {
  __typename?: 'SlotAssignedLearnerName';
  firstName: Scalars['String'];
  lastName: Scalars['String'];
};

export type SlotAssignedLearnerOrderBy = {
  learner?: Maybe<OrderByEnum>;
  slot?: Maybe<OrderByEnum>;
  status?: Maybe<OrderByEnum>;
};

export type SlotAssignedLearnerSlot = {
  __typename?: 'SlotAssignedLearnerSlot';
  endDate?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['String']>;
};

export type SlotAssignedLearnerStat = {
  __typename?: 'SlotAssignedLearnerStat';
  assignedAmount: Scalars['Int'];
  subscribedAmount: Scalars['Int'];
};

export enum SlotAssignedLearnerStatus {
  NotSubscribedYet = 'notSubscribedYet',
  Subscribed = 'subscribed'
}

export type StartCmi5ContentInput = {
  language?: Maybe<Scalars['String']>;
  moduleId: Scalars['String'];
  preview?: Maybe<Scalars['Boolean']>;
};

export type StartCmi5ContentResponse = {
  __typename?: 'StartCmi5ContentResponse';
  success: Scalars['Boolean'];
  url?: Maybe<Scalars['String']>;
};

/** Boolean expression to compare columns of type "String". All fields are combined with logical 'AND'. */
export type String_Comparison_Exp = {
  _eq?: Maybe<Scalars['String']>;
  _gt?: Maybe<Scalars['String']>;
  _gte?: Maybe<Scalars['String']>;
  /** does the column match the given case-insensitive pattern */
  _ilike?: Maybe<Scalars['String']>;
  _in?: Maybe<Array<Scalars['String']>>;
  /** does the column match the given POSIX regular expression, case insensitive */
  _iregex?: Maybe<Scalars['String']>;
  _is_null?: Maybe<Scalars['Boolean']>;
  /** does the column match the given pattern */
  _like?: Maybe<Scalars['String']>;
  _lt?: Maybe<Scalars['String']>;
  _lte?: Maybe<Scalars['String']>;
  _neq?: Maybe<Scalars['String']>;
  /** does the column NOT match the given case-insensitive pattern */
  _nilike?: Maybe<Scalars['String']>;
  _nin?: Maybe<Array<Scalars['String']>>;
  /** does the column NOT match the given POSIX regular expression, case insensitive */
  _niregex?: Maybe<Scalars['String']>;
  /** does the column NOT match the given pattern */
  _nlike?: Maybe<Scalars['String']>;
  /** does the column NOT match the given POSIX regular expression, case sensitive */
  _nregex?: Maybe<Scalars['String']>;
  /** does the column NOT match the given SQL regular expression */
  _nsimilar?: Maybe<Scalars['String']>;
  /** does the column match the given POSIX regular expression, case sensitive */
  _regex?: Maybe<Scalars['String']>;
  /** does the column match the given SQL regular expression */
  _similar?: Maybe<Scalars['String']>;
};

export type TagCourseInput = {
  id?: Maybe<Scalars['String']>;
  title: TranslateValues;
};

export type TagCourseResponse = {
  __typename?: 'TagCourseResponse';
  id?: Maybe<Scalars['String']>;
  success: Scalars['Boolean'];
};

export type ThematicInput = {
  id?: Maybe<Scalars['String']>;
  label: TranslateValues;
};

export type ThematicResponse = {
  __typename?: 'ThematicResponse';
  id?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
  success: Scalars['Boolean'];
};

export type TranslateFiles = {
  en?: Maybe<File>;
  fr?: Maybe<File>;
};

export type TranslateValues = {
  en?: Maybe<Scalars['String']>;
  fr?: Maybe<Scalars['String']>;
};

export type UpdateUserModuleProgressionResponse = {
  __typename?: 'UpdateUserModuleProgressionResponse';
  success: Scalars['Boolean'];
};

export type UploadUrlResponse = {
  __typename?: 'UploadUrlResponse';
  filePath: Scalars['String'];
  publicUrl: Scalars['String'];
  uploadUrl: Scalars['String'];
};

export type UserInput = {
  email: Scalars['String'];
  firstName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  roleId?: Maybe<Scalars['String']>;
};

export type UserResponse = {
  __typename?: 'UserResponse';
  success: Scalars['Boolean'];
};

export type ValidateDataAccessRequestInput = {
  requestId: Scalars['String'];
};

export type ValidateDataAccessRequestResponse = {
  __typename?: 'ValidateDataAccessRequestResponse';
  message?: Maybe<Scalars['String']>;
  success: Scalars['Boolean'];
};

export type VolunteerAttributesInput = {
  attributeId?: Maybe<Scalars['String']>;
  code: Scalars['String'];
  label: TranslateValues;
  type: VolunteerAttributesType;
};

export type VolunteerAttributesResponse = {
  __typename?: 'VolunteerAttributesResponse';
  message?: Maybe<Scalars['String']>;
  success: Scalars['Boolean'];
};

export enum VolunteerAttributesType {
  JobTitle = 'jobTitle',
  Site = 'site'
}

/** columns and relationships of "cmsPage" */
export type CmsPage = {
  __typename?: 'cmsPage';
  createdAt: Scalars['timestamptz'];
  id: Scalars['uuid'];
  key: Scalars['String'];
  /** An object relationship */
  text: I18n;
  /** A computed field, executes function "cmspage_text_i18n" */
  textI18n?: Maybe<Scalars['String']>;
  textI18nId: Scalars['uuid'];
  /** An object relationship */
  title: I18n;
  /** A computed field, executes function "cmspage_title_i18n" */
  titleI18n?: Maybe<Scalars['String']>;
  titleI18nId: Scalars['uuid'];
  updatedAt: Scalars['timestamptz'];
  updatedBy?: Maybe<Scalars['uuid']>;
};

/** aggregated selection of "cmsPage" */
export type CmsPage_Aggregate = {
  __typename?: 'cmsPage_aggregate';
  aggregate?: Maybe<CmsPage_Aggregate_Fields>;
  nodes: Array<CmsPage>;
};

/** aggregate fields of "cmsPage" */
export type CmsPage_Aggregate_Fields = {
  __typename?: 'cmsPage_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<CmsPage_Max_Fields>;
  min?: Maybe<CmsPage_Min_Fields>;
};


/** aggregate fields of "cmsPage" */
export type CmsPage_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<CmsPage_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "cmsPage". All fields are combined with a logical 'AND'. */
export type CmsPage_Bool_Exp = {
  _and?: Maybe<Array<CmsPage_Bool_Exp>>;
  _not?: Maybe<CmsPage_Bool_Exp>;
  _or?: Maybe<Array<CmsPage_Bool_Exp>>;
  createdAt?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  key?: Maybe<String_Comparison_Exp>;
  text?: Maybe<I18n_Bool_Exp>;
  textI18n?: Maybe<String_Comparison_Exp>;
  textI18nId?: Maybe<Uuid_Comparison_Exp>;
  title?: Maybe<I18n_Bool_Exp>;
  titleI18n?: Maybe<String_Comparison_Exp>;
  titleI18nId?: Maybe<Uuid_Comparison_Exp>;
  updatedAt?: Maybe<Timestamptz_Comparison_Exp>;
  updatedBy?: Maybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "cmsPage" */
export enum CmsPage_Constraint {
  /** unique or primary key constraint on columns "key" */
  CmsPageKeyKey = 'cmsPage_key_key',
  /** unique or primary key constraint on columns "id" */
  CmsPagePkey = 'cmsPage_pkey'
}

/** input type for inserting data into table "cmsPage" */
export type CmsPage_Insert_Input = {
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  key?: Maybe<Scalars['String']>;
  text?: Maybe<I18n_Obj_Rel_Insert_Input>;
  textI18nId?: Maybe<Scalars['uuid']>;
  title?: Maybe<I18n_Obj_Rel_Insert_Input>;
  titleI18nId?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  updatedBy?: Maybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type CmsPage_Max_Fields = {
  __typename?: 'cmsPage_max_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  key?: Maybe<Scalars['String']>;
  textI18nId?: Maybe<Scalars['uuid']>;
  titleI18nId?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  updatedBy?: Maybe<Scalars['uuid']>;
};

/** aggregate min on columns */
export type CmsPage_Min_Fields = {
  __typename?: 'cmsPage_min_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  key?: Maybe<Scalars['String']>;
  textI18nId?: Maybe<Scalars['uuid']>;
  titleI18nId?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  updatedBy?: Maybe<Scalars['uuid']>;
};

/** response of any mutation on the table "cmsPage" */
export type CmsPage_Mutation_Response = {
  __typename?: 'cmsPage_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<CmsPage>;
};

/** on_conflict condition type for table "cmsPage" */
export type CmsPage_On_Conflict = {
  constraint: CmsPage_Constraint;
  update_columns?: Array<CmsPage_Update_Column>;
  where?: Maybe<CmsPage_Bool_Exp>;
};

/** Ordering options when selecting data from "cmsPage". */
export type CmsPage_Order_By = {
  createdAt?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  key?: Maybe<Order_By>;
  text?: Maybe<I18n_Order_By>;
  textI18n?: Maybe<Order_By>;
  textI18nId?: Maybe<Order_By>;
  title?: Maybe<I18n_Order_By>;
  titleI18n?: Maybe<Order_By>;
  titleI18nId?: Maybe<Order_By>;
  updatedAt?: Maybe<Order_By>;
  updatedBy?: Maybe<Order_By>;
};

/** primary key columns input for table: cmsPage */
export type CmsPage_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "cmsPage" */
export enum CmsPage_Select_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  Key = 'key',
  /** column name */
  TextI18nId = 'textI18nId',
  /** column name */
  TitleI18nId = 'titleI18nId',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  UpdatedBy = 'updatedBy'
}

/** input type for updating data in table "cmsPage" */
export type CmsPage_Set_Input = {
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  key?: Maybe<Scalars['String']>;
  textI18nId?: Maybe<Scalars['uuid']>;
  titleI18nId?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  updatedBy?: Maybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "cmsPage" */
export type CmsPage_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: CmsPage_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type CmsPage_Stream_Cursor_Value_Input = {
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  key?: Maybe<Scalars['String']>;
  textI18nId?: Maybe<Scalars['uuid']>;
  titleI18nId?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  updatedBy?: Maybe<Scalars['uuid']>;
};

/** update columns of table "cmsPage" */
export enum CmsPage_Update_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  Key = 'key',
  /** column name */
  TextI18nId = 'textI18nId',
  /** column name */
  TitleI18nId = 'titleI18nId',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  UpdatedBy = 'updatedBy'
}

export type CmsPage_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<CmsPage_Set_Input>;
  /** filter the rows which have to be updated */
  where: CmsPage_Bool_Exp;
};

/** columns and relationships of "contentArticle" */
export type ContentArticle = {
  __typename?: 'contentArticle';
  /** A computed field, executes function "article_content_i18n" */
  contentI18n?: Maybe<Scalars['String']>;
  contentI18nId: Scalars['uuid'];
  /** An object relationship */
  contentId?: Maybe<I18n>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  id: Scalars['uuid'];
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** aggregated selection of "contentArticle" */
export type ContentArticle_Aggregate = {
  __typename?: 'contentArticle_aggregate';
  aggregate?: Maybe<ContentArticle_Aggregate_Fields>;
  nodes: Array<ContentArticle>;
};

/** aggregate fields of "contentArticle" */
export type ContentArticle_Aggregate_Fields = {
  __typename?: 'contentArticle_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<ContentArticle_Max_Fields>;
  min?: Maybe<ContentArticle_Min_Fields>;
};


/** aggregate fields of "contentArticle" */
export type ContentArticle_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<ContentArticle_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "contentArticle". All fields are combined with a logical 'AND'. */
export type ContentArticle_Bool_Exp = {
  _and?: Maybe<Array<ContentArticle_Bool_Exp>>;
  _not?: Maybe<ContentArticle_Bool_Exp>;
  _or?: Maybe<Array<ContentArticle_Bool_Exp>>;
  contentI18n?: Maybe<String_Comparison_Exp>;
  contentI18nId?: Maybe<Uuid_Comparison_Exp>;
  contentId?: Maybe<I18n_Bool_Exp>;
  createdAt?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  updatedAt?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "contentArticle" */
export enum ContentArticle_Constraint {
  /** unique or primary key constraint on columns "id" */
  ContentArticlePkey = 'contentArticle_pkey'
}

/** input type for inserting data into table "contentArticle" */
export type ContentArticle_Insert_Input = {
  contentI18nId?: Maybe<Scalars['uuid']>;
  contentId?: Maybe<I18n_Obj_Rel_Insert_Input>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type ContentArticle_Max_Fields = {
  __typename?: 'contentArticle_max_fields';
  contentI18nId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type ContentArticle_Min_Fields = {
  __typename?: 'contentArticle_min_fields';
  contentI18nId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "contentArticle" */
export type ContentArticle_Mutation_Response = {
  __typename?: 'contentArticle_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<ContentArticle>;
};

/** input type for inserting object relation for remote table "contentArticle" */
export type ContentArticle_Obj_Rel_Insert_Input = {
  data: ContentArticle_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<ContentArticle_On_Conflict>;
};

/** on_conflict condition type for table "contentArticle" */
export type ContentArticle_On_Conflict = {
  constraint: ContentArticle_Constraint;
  update_columns?: Array<ContentArticle_Update_Column>;
  where?: Maybe<ContentArticle_Bool_Exp>;
};

/** Ordering options when selecting data from "contentArticle". */
export type ContentArticle_Order_By = {
  contentI18n?: Maybe<Order_By>;
  contentI18nId?: Maybe<Order_By>;
  contentId?: Maybe<I18n_Order_By>;
  createdAt?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  updatedAt?: Maybe<Order_By>;
};

/** primary key columns input for table: contentArticle */
export type ContentArticle_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "contentArticle" */
export enum ContentArticle_Select_Column {
  /** column name */
  ContentI18nId = 'contentI18nId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updatedAt'
}

/** input type for updating data in table "contentArticle" */
export type ContentArticle_Set_Input = {
  contentI18nId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "contentArticle" */
export type ContentArticle_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: ContentArticle_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type ContentArticle_Stream_Cursor_Value_Input = {
  contentI18nId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** update columns of table "contentArticle" */
export enum ContentArticle_Update_Column {
  /** column name */
  ContentI18nId = 'contentI18nId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updatedAt'
}

export type ContentArticle_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<ContentArticle_Set_Input>;
  /** filter the rows which have to be updated */
  where: ContentArticle_Bool_Exp;
};

/** columns and relationships of "contentAudioTranscript" */
export type ContentAudioTranscript = {
  __typename?: 'contentAudioTranscript';
  /** An object relationship */
  contentVideo: ContentVideo;
  contentVideoId: Scalars['uuid'];
  createdAt: Scalars['timestamptz'];
  id: Scalars['uuid'];
  language: Scalars['String'];
  size?: Maybe<Scalars['Int']>;
  updatedAt: Scalars['timestamptz'];
  url: Scalars['String'];
};

/** aggregated selection of "contentAudioTranscript" */
export type ContentAudioTranscript_Aggregate = {
  __typename?: 'contentAudioTranscript_aggregate';
  aggregate?: Maybe<ContentAudioTranscript_Aggregate_Fields>;
  nodes: Array<ContentAudioTranscript>;
};

export type ContentAudioTranscript_Aggregate_Bool_Exp = {
  count?: Maybe<ContentAudioTranscript_Aggregate_Bool_Exp_Count>;
};

export type ContentAudioTranscript_Aggregate_Bool_Exp_Count = {
  arguments?: Maybe<Array<ContentAudioTranscript_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<ContentAudioTranscript_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "contentAudioTranscript" */
export type ContentAudioTranscript_Aggregate_Fields = {
  __typename?: 'contentAudioTranscript_aggregate_fields';
  avg?: Maybe<ContentAudioTranscript_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<ContentAudioTranscript_Max_Fields>;
  min?: Maybe<ContentAudioTranscript_Min_Fields>;
  stddev?: Maybe<ContentAudioTranscript_Stddev_Fields>;
  stddev_pop?: Maybe<ContentAudioTranscript_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<ContentAudioTranscript_Stddev_Samp_Fields>;
  sum?: Maybe<ContentAudioTranscript_Sum_Fields>;
  var_pop?: Maybe<ContentAudioTranscript_Var_Pop_Fields>;
  var_samp?: Maybe<ContentAudioTranscript_Var_Samp_Fields>;
  variance?: Maybe<ContentAudioTranscript_Variance_Fields>;
};


/** aggregate fields of "contentAudioTranscript" */
export type ContentAudioTranscript_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<ContentAudioTranscript_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "contentAudioTranscript" */
export type ContentAudioTranscript_Aggregate_Order_By = {
  avg?: Maybe<ContentAudioTranscript_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<ContentAudioTranscript_Max_Order_By>;
  min?: Maybe<ContentAudioTranscript_Min_Order_By>;
  stddev?: Maybe<ContentAudioTranscript_Stddev_Order_By>;
  stddev_pop?: Maybe<ContentAudioTranscript_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<ContentAudioTranscript_Stddev_Samp_Order_By>;
  sum?: Maybe<ContentAudioTranscript_Sum_Order_By>;
  var_pop?: Maybe<ContentAudioTranscript_Var_Pop_Order_By>;
  var_samp?: Maybe<ContentAudioTranscript_Var_Samp_Order_By>;
  variance?: Maybe<ContentAudioTranscript_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "contentAudioTranscript" */
export type ContentAudioTranscript_Arr_Rel_Insert_Input = {
  data: Array<ContentAudioTranscript_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<ContentAudioTranscript_On_Conflict>;
};

/** aggregate avg on columns */
export type ContentAudioTranscript_Avg_Fields = {
  __typename?: 'contentAudioTranscript_avg_fields';
  size?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "contentAudioTranscript" */
export type ContentAudioTranscript_Avg_Order_By = {
  size?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "contentAudioTranscript". All fields are combined with a logical 'AND'. */
export type ContentAudioTranscript_Bool_Exp = {
  _and?: Maybe<Array<ContentAudioTranscript_Bool_Exp>>;
  _not?: Maybe<ContentAudioTranscript_Bool_Exp>;
  _or?: Maybe<Array<ContentAudioTranscript_Bool_Exp>>;
  contentVideo?: Maybe<ContentVideo_Bool_Exp>;
  contentVideoId?: Maybe<Uuid_Comparison_Exp>;
  createdAt?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  language?: Maybe<String_Comparison_Exp>;
  size?: Maybe<Int_Comparison_Exp>;
  updatedAt?: Maybe<Timestamptz_Comparison_Exp>;
  url?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "contentAudioTranscript" */
export enum ContentAudioTranscript_Constraint {
  /** unique or primary key constraint on columns "contentVideoId", "language" */
  IdxContentAudioTranscriptLanguageContentVideoId = 'IDX_content_audio_transcript_language_content_video_id',
  /** unique or primary key constraint on columns "id" */
  ContentAudioTranscriptPkey = 'contentAudioTranscript_pkey'
}

/** input type for incrementing numeric columns in table "contentAudioTranscript" */
export type ContentAudioTranscript_Inc_Input = {
  size?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "contentAudioTranscript" */
export type ContentAudioTranscript_Insert_Input = {
  contentVideo?: Maybe<ContentVideo_Obj_Rel_Insert_Input>;
  contentVideoId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  language?: Maybe<Scalars['String']>;
  size?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  url?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type ContentAudioTranscript_Max_Fields = {
  __typename?: 'contentAudioTranscript_max_fields';
  contentVideoId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  language?: Maybe<Scalars['String']>;
  size?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  url?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "contentAudioTranscript" */
export type ContentAudioTranscript_Max_Order_By = {
  contentVideoId?: Maybe<Order_By>;
  createdAt?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  language?: Maybe<Order_By>;
  size?: Maybe<Order_By>;
  updatedAt?: Maybe<Order_By>;
  url?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type ContentAudioTranscript_Min_Fields = {
  __typename?: 'contentAudioTranscript_min_fields';
  contentVideoId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  language?: Maybe<Scalars['String']>;
  size?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  url?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "contentAudioTranscript" */
export type ContentAudioTranscript_Min_Order_By = {
  contentVideoId?: Maybe<Order_By>;
  createdAt?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  language?: Maybe<Order_By>;
  size?: Maybe<Order_By>;
  updatedAt?: Maybe<Order_By>;
  url?: Maybe<Order_By>;
};

/** response of any mutation on the table "contentAudioTranscript" */
export type ContentAudioTranscript_Mutation_Response = {
  __typename?: 'contentAudioTranscript_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<ContentAudioTranscript>;
};

/** on_conflict condition type for table "contentAudioTranscript" */
export type ContentAudioTranscript_On_Conflict = {
  constraint: ContentAudioTranscript_Constraint;
  update_columns?: Array<ContentAudioTranscript_Update_Column>;
  where?: Maybe<ContentAudioTranscript_Bool_Exp>;
};

/** Ordering options when selecting data from "contentAudioTranscript". */
export type ContentAudioTranscript_Order_By = {
  contentVideo?: Maybe<ContentVideo_Order_By>;
  contentVideoId?: Maybe<Order_By>;
  createdAt?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  language?: Maybe<Order_By>;
  size?: Maybe<Order_By>;
  updatedAt?: Maybe<Order_By>;
  url?: Maybe<Order_By>;
};

/** primary key columns input for table: contentAudioTranscript */
export type ContentAudioTranscript_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "contentAudioTranscript" */
export enum ContentAudioTranscript_Select_Column {
  /** column name */
  ContentVideoId = 'contentVideoId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  Language = 'language',
  /** column name */
  Size = 'size',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  Url = 'url'
}

/** input type for updating data in table "contentAudioTranscript" */
export type ContentAudioTranscript_Set_Input = {
  contentVideoId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  language?: Maybe<Scalars['String']>;
  size?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  url?: Maybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type ContentAudioTranscript_Stddev_Fields = {
  __typename?: 'contentAudioTranscript_stddev_fields';
  size?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "contentAudioTranscript" */
export type ContentAudioTranscript_Stddev_Order_By = {
  size?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type ContentAudioTranscript_Stddev_Pop_Fields = {
  __typename?: 'contentAudioTranscript_stddev_pop_fields';
  size?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "contentAudioTranscript" */
export type ContentAudioTranscript_Stddev_Pop_Order_By = {
  size?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type ContentAudioTranscript_Stddev_Samp_Fields = {
  __typename?: 'contentAudioTranscript_stddev_samp_fields';
  size?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "contentAudioTranscript" */
export type ContentAudioTranscript_Stddev_Samp_Order_By = {
  size?: Maybe<Order_By>;
};

/** Streaming cursor of the table "contentAudioTranscript" */
export type ContentAudioTranscript_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: ContentAudioTranscript_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type ContentAudioTranscript_Stream_Cursor_Value_Input = {
  contentVideoId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  language?: Maybe<Scalars['String']>;
  size?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  url?: Maybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type ContentAudioTranscript_Sum_Fields = {
  __typename?: 'contentAudioTranscript_sum_fields';
  size?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "contentAudioTranscript" */
export type ContentAudioTranscript_Sum_Order_By = {
  size?: Maybe<Order_By>;
};

/** update columns of table "contentAudioTranscript" */
export enum ContentAudioTranscript_Update_Column {
  /** column name */
  ContentVideoId = 'contentVideoId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  Language = 'language',
  /** column name */
  Size = 'size',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  Url = 'url'
}

export type ContentAudioTranscript_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<ContentAudioTranscript_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<ContentAudioTranscript_Set_Input>;
  /** filter the rows which have to be updated */
  where: ContentAudioTranscript_Bool_Exp;
};

/** aggregate var_pop on columns */
export type ContentAudioTranscript_Var_Pop_Fields = {
  __typename?: 'contentAudioTranscript_var_pop_fields';
  size?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "contentAudioTranscript" */
export type ContentAudioTranscript_Var_Pop_Order_By = {
  size?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type ContentAudioTranscript_Var_Samp_Fields = {
  __typename?: 'contentAudioTranscript_var_samp_fields';
  size?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "contentAudioTranscript" */
export type ContentAudioTranscript_Var_Samp_Order_By = {
  size?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type ContentAudioTranscript_Variance_Fields = {
  __typename?: 'contentAudioTranscript_variance_fields';
  size?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "contentAudioTranscript" */
export type ContentAudioTranscript_Variance_Order_By = {
  size?: Maybe<Order_By>;
};

/** columns and relationships of "contentCmi5" */
export type ContentCmi5 = {
  __typename?: 'contentCmi5';
  /** An array relationship */
  contentCmi5AssignableUnits: Array<ContentCmi5AssignableUnit>;
  /** An aggregate relationship */
  contentCmi5AssignableUnits_aggregate: ContentCmi5AssignableUnit_Aggregate;
  createdAt?: Maybe<Scalars['timestamptz']>;
  id: Scalars['uuid'];
  updatedAt?: Maybe<Scalars['timestamptz']>;
};


/** columns and relationships of "contentCmi5" */
export type ContentCmi5ContentCmi5AssignableUnitsArgs = {
  distinct_on?: Maybe<Array<ContentCmi5AssignableUnit_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ContentCmi5AssignableUnit_Order_By>>;
  where?: Maybe<ContentCmi5AssignableUnit_Bool_Exp>;
};


/** columns and relationships of "contentCmi5" */
export type ContentCmi5ContentCmi5AssignableUnits_AggregateArgs = {
  distinct_on?: Maybe<Array<ContentCmi5AssignableUnit_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ContentCmi5AssignableUnit_Order_By>>;
  where?: Maybe<ContentCmi5AssignableUnit_Bool_Exp>;
};

/** columns and relationships of "contentCmi5AssignableUnit" */
export type ContentCmi5AssignableUnit = {
  __typename?: 'contentCmi5AssignableUnit';
  activityType?: Maybe<Scalars['String']>;
  contentCmi5Id: Scalars['uuid'];
  createdAt: Scalars['timestamptz'];
  /** An object relationship */
  descriptionI18n?: Maybe<I18n>;
  descriptionI18nId?: Maybe<Scalars['uuid']>;
  entitlementKey?: Maybe<Scalars['String']>;
  id: Scalars['uuid'];
  idCmi5: Scalars['String'];
  language?: Maybe<Scalars['String']>;
  launchMethod: Scalars['String'];
  launchParameters?: Maybe<Scalars['String']>;
  masteryScore?: Maybe<Scalars['numeric']>;
  moveOn?: Maybe<Scalars['String']>;
  pathArchiveCmi5?: Maybe<Scalars['String']>;
  pathFolderCmi5?: Maybe<Scalars['String']>;
  /** An object relationship */
  titleI18n?: Maybe<I18n>;
  titleI18nId?: Maybe<Scalars['uuid']>;
  updatedAt: Scalars['timestamptz'];
  url: Scalars['String'];
};

/** aggregated selection of "contentCmi5AssignableUnit" */
export type ContentCmi5AssignableUnit_Aggregate = {
  __typename?: 'contentCmi5AssignableUnit_aggregate';
  aggregate?: Maybe<ContentCmi5AssignableUnit_Aggregate_Fields>;
  nodes: Array<ContentCmi5AssignableUnit>;
};

export type ContentCmi5AssignableUnit_Aggregate_Bool_Exp = {
  count?: Maybe<ContentCmi5AssignableUnit_Aggregate_Bool_Exp_Count>;
};

export type ContentCmi5AssignableUnit_Aggregate_Bool_Exp_Count = {
  arguments?: Maybe<Array<ContentCmi5AssignableUnit_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<ContentCmi5AssignableUnit_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "contentCmi5AssignableUnit" */
export type ContentCmi5AssignableUnit_Aggregate_Fields = {
  __typename?: 'contentCmi5AssignableUnit_aggregate_fields';
  avg?: Maybe<ContentCmi5AssignableUnit_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<ContentCmi5AssignableUnit_Max_Fields>;
  min?: Maybe<ContentCmi5AssignableUnit_Min_Fields>;
  stddev?: Maybe<ContentCmi5AssignableUnit_Stddev_Fields>;
  stddev_pop?: Maybe<ContentCmi5AssignableUnit_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<ContentCmi5AssignableUnit_Stddev_Samp_Fields>;
  sum?: Maybe<ContentCmi5AssignableUnit_Sum_Fields>;
  var_pop?: Maybe<ContentCmi5AssignableUnit_Var_Pop_Fields>;
  var_samp?: Maybe<ContentCmi5AssignableUnit_Var_Samp_Fields>;
  variance?: Maybe<ContentCmi5AssignableUnit_Variance_Fields>;
};


/** aggregate fields of "contentCmi5AssignableUnit" */
export type ContentCmi5AssignableUnit_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<ContentCmi5AssignableUnit_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "contentCmi5AssignableUnit" */
export type ContentCmi5AssignableUnit_Aggregate_Order_By = {
  avg?: Maybe<ContentCmi5AssignableUnit_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<ContentCmi5AssignableUnit_Max_Order_By>;
  min?: Maybe<ContentCmi5AssignableUnit_Min_Order_By>;
  stddev?: Maybe<ContentCmi5AssignableUnit_Stddev_Order_By>;
  stddev_pop?: Maybe<ContentCmi5AssignableUnit_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<ContentCmi5AssignableUnit_Stddev_Samp_Order_By>;
  sum?: Maybe<ContentCmi5AssignableUnit_Sum_Order_By>;
  var_pop?: Maybe<ContentCmi5AssignableUnit_Var_Pop_Order_By>;
  var_samp?: Maybe<ContentCmi5AssignableUnit_Var_Samp_Order_By>;
  variance?: Maybe<ContentCmi5AssignableUnit_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "contentCmi5AssignableUnit" */
export type ContentCmi5AssignableUnit_Arr_Rel_Insert_Input = {
  data: Array<ContentCmi5AssignableUnit_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<ContentCmi5AssignableUnit_On_Conflict>;
};

/** aggregate avg on columns */
export type ContentCmi5AssignableUnit_Avg_Fields = {
  __typename?: 'contentCmi5AssignableUnit_avg_fields';
  masteryScore?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "contentCmi5AssignableUnit" */
export type ContentCmi5AssignableUnit_Avg_Order_By = {
  masteryScore?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "contentCmi5AssignableUnit". All fields are combined with a logical 'AND'. */
export type ContentCmi5AssignableUnit_Bool_Exp = {
  _and?: Maybe<Array<ContentCmi5AssignableUnit_Bool_Exp>>;
  _not?: Maybe<ContentCmi5AssignableUnit_Bool_Exp>;
  _or?: Maybe<Array<ContentCmi5AssignableUnit_Bool_Exp>>;
  activityType?: Maybe<String_Comparison_Exp>;
  contentCmi5Id?: Maybe<Uuid_Comparison_Exp>;
  createdAt?: Maybe<Timestamptz_Comparison_Exp>;
  descriptionI18n?: Maybe<I18n_Bool_Exp>;
  descriptionI18nId?: Maybe<Uuid_Comparison_Exp>;
  entitlementKey?: Maybe<String_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  idCmi5?: Maybe<String_Comparison_Exp>;
  language?: Maybe<String_Comparison_Exp>;
  launchMethod?: Maybe<String_Comparison_Exp>;
  launchParameters?: Maybe<String_Comparison_Exp>;
  masteryScore?: Maybe<Numeric_Comparison_Exp>;
  moveOn?: Maybe<String_Comparison_Exp>;
  pathArchiveCmi5?: Maybe<String_Comparison_Exp>;
  pathFolderCmi5?: Maybe<String_Comparison_Exp>;
  titleI18n?: Maybe<I18n_Bool_Exp>;
  titleI18nId?: Maybe<Uuid_Comparison_Exp>;
  updatedAt?: Maybe<Timestamptz_Comparison_Exp>;
  url?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "contentCmi5AssignableUnit" */
export enum ContentCmi5AssignableUnit_Constraint {
  /** unique or primary key constraint on columns "id" */
  ContentCmi5AssignableUnitPkey = 'contentCmi5AssignableUnit_pkey'
}

/** input type for incrementing numeric columns in table "contentCmi5AssignableUnit" */
export type ContentCmi5AssignableUnit_Inc_Input = {
  masteryScore?: Maybe<Scalars['numeric']>;
};

/** input type for inserting data into table "contentCmi5AssignableUnit" */
export type ContentCmi5AssignableUnit_Insert_Input = {
  activityType?: Maybe<Scalars['String']>;
  contentCmi5Id?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  descriptionI18n?: Maybe<I18n_Obj_Rel_Insert_Input>;
  descriptionI18nId?: Maybe<Scalars['uuid']>;
  entitlementKey?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  idCmi5?: Maybe<Scalars['String']>;
  language?: Maybe<Scalars['String']>;
  launchMethod?: Maybe<Scalars['String']>;
  launchParameters?: Maybe<Scalars['String']>;
  masteryScore?: Maybe<Scalars['numeric']>;
  moveOn?: Maybe<Scalars['String']>;
  pathArchiveCmi5?: Maybe<Scalars['String']>;
  pathFolderCmi5?: Maybe<Scalars['String']>;
  titleI18n?: Maybe<I18n_Obj_Rel_Insert_Input>;
  titleI18nId?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  url?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type ContentCmi5AssignableUnit_Max_Fields = {
  __typename?: 'contentCmi5AssignableUnit_max_fields';
  activityType?: Maybe<Scalars['String']>;
  contentCmi5Id?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  descriptionI18nId?: Maybe<Scalars['uuid']>;
  entitlementKey?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  idCmi5?: Maybe<Scalars['String']>;
  language?: Maybe<Scalars['String']>;
  launchMethod?: Maybe<Scalars['String']>;
  launchParameters?: Maybe<Scalars['String']>;
  masteryScore?: Maybe<Scalars['numeric']>;
  moveOn?: Maybe<Scalars['String']>;
  pathArchiveCmi5?: Maybe<Scalars['String']>;
  pathFolderCmi5?: Maybe<Scalars['String']>;
  titleI18nId?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  url?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "contentCmi5AssignableUnit" */
export type ContentCmi5AssignableUnit_Max_Order_By = {
  activityType?: Maybe<Order_By>;
  contentCmi5Id?: Maybe<Order_By>;
  createdAt?: Maybe<Order_By>;
  descriptionI18nId?: Maybe<Order_By>;
  entitlementKey?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  idCmi5?: Maybe<Order_By>;
  language?: Maybe<Order_By>;
  launchMethod?: Maybe<Order_By>;
  launchParameters?: Maybe<Order_By>;
  masteryScore?: Maybe<Order_By>;
  moveOn?: Maybe<Order_By>;
  pathArchiveCmi5?: Maybe<Order_By>;
  pathFolderCmi5?: Maybe<Order_By>;
  titleI18nId?: Maybe<Order_By>;
  updatedAt?: Maybe<Order_By>;
  url?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type ContentCmi5AssignableUnit_Min_Fields = {
  __typename?: 'contentCmi5AssignableUnit_min_fields';
  activityType?: Maybe<Scalars['String']>;
  contentCmi5Id?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  descriptionI18nId?: Maybe<Scalars['uuid']>;
  entitlementKey?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  idCmi5?: Maybe<Scalars['String']>;
  language?: Maybe<Scalars['String']>;
  launchMethod?: Maybe<Scalars['String']>;
  launchParameters?: Maybe<Scalars['String']>;
  masteryScore?: Maybe<Scalars['numeric']>;
  moveOn?: Maybe<Scalars['String']>;
  pathArchiveCmi5?: Maybe<Scalars['String']>;
  pathFolderCmi5?: Maybe<Scalars['String']>;
  titleI18nId?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  url?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "contentCmi5AssignableUnit" */
export type ContentCmi5AssignableUnit_Min_Order_By = {
  activityType?: Maybe<Order_By>;
  contentCmi5Id?: Maybe<Order_By>;
  createdAt?: Maybe<Order_By>;
  descriptionI18nId?: Maybe<Order_By>;
  entitlementKey?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  idCmi5?: Maybe<Order_By>;
  language?: Maybe<Order_By>;
  launchMethod?: Maybe<Order_By>;
  launchParameters?: Maybe<Order_By>;
  masteryScore?: Maybe<Order_By>;
  moveOn?: Maybe<Order_By>;
  pathArchiveCmi5?: Maybe<Order_By>;
  pathFolderCmi5?: Maybe<Order_By>;
  titleI18nId?: Maybe<Order_By>;
  updatedAt?: Maybe<Order_By>;
  url?: Maybe<Order_By>;
};

/** response of any mutation on the table "contentCmi5AssignableUnit" */
export type ContentCmi5AssignableUnit_Mutation_Response = {
  __typename?: 'contentCmi5AssignableUnit_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<ContentCmi5AssignableUnit>;
};

/** on_conflict condition type for table "contentCmi5AssignableUnit" */
export type ContentCmi5AssignableUnit_On_Conflict = {
  constraint: ContentCmi5AssignableUnit_Constraint;
  update_columns?: Array<ContentCmi5AssignableUnit_Update_Column>;
  where?: Maybe<ContentCmi5AssignableUnit_Bool_Exp>;
};

/** Ordering options when selecting data from "contentCmi5AssignableUnit". */
export type ContentCmi5AssignableUnit_Order_By = {
  activityType?: Maybe<Order_By>;
  contentCmi5Id?: Maybe<Order_By>;
  createdAt?: Maybe<Order_By>;
  descriptionI18n?: Maybe<I18n_Order_By>;
  descriptionI18nId?: Maybe<Order_By>;
  entitlementKey?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  idCmi5?: Maybe<Order_By>;
  language?: Maybe<Order_By>;
  launchMethod?: Maybe<Order_By>;
  launchParameters?: Maybe<Order_By>;
  masteryScore?: Maybe<Order_By>;
  moveOn?: Maybe<Order_By>;
  pathArchiveCmi5?: Maybe<Order_By>;
  pathFolderCmi5?: Maybe<Order_By>;
  titleI18n?: Maybe<I18n_Order_By>;
  titleI18nId?: Maybe<Order_By>;
  updatedAt?: Maybe<Order_By>;
  url?: Maybe<Order_By>;
};

/** primary key columns input for table: contentCmi5AssignableUnit */
export type ContentCmi5AssignableUnit_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "contentCmi5AssignableUnit" */
export enum ContentCmi5AssignableUnit_Select_Column {
  /** column name */
  ActivityType = 'activityType',
  /** column name */
  ContentCmi5Id = 'contentCmi5Id',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  DescriptionI18nId = 'descriptionI18nId',
  /** column name */
  EntitlementKey = 'entitlementKey',
  /** column name */
  Id = 'id',
  /** column name */
  IdCmi5 = 'idCmi5',
  /** column name */
  Language = 'language',
  /** column name */
  LaunchMethod = 'launchMethod',
  /** column name */
  LaunchParameters = 'launchParameters',
  /** column name */
  MasteryScore = 'masteryScore',
  /** column name */
  MoveOn = 'moveOn',
  /** column name */
  PathArchiveCmi5 = 'pathArchiveCmi5',
  /** column name */
  PathFolderCmi5 = 'pathFolderCmi5',
  /** column name */
  TitleI18nId = 'titleI18nId',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  Url = 'url'
}

/** input type for updating data in table "contentCmi5AssignableUnit" */
export type ContentCmi5AssignableUnit_Set_Input = {
  activityType?: Maybe<Scalars['String']>;
  contentCmi5Id?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  descriptionI18nId?: Maybe<Scalars['uuid']>;
  entitlementKey?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  idCmi5?: Maybe<Scalars['String']>;
  language?: Maybe<Scalars['String']>;
  launchMethod?: Maybe<Scalars['String']>;
  launchParameters?: Maybe<Scalars['String']>;
  masteryScore?: Maybe<Scalars['numeric']>;
  moveOn?: Maybe<Scalars['String']>;
  pathArchiveCmi5?: Maybe<Scalars['String']>;
  pathFolderCmi5?: Maybe<Scalars['String']>;
  titleI18nId?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  url?: Maybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type ContentCmi5AssignableUnit_Stddev_Fields = {
  __typename?: 'contentCmi5AssignableUnit_stddev_fields';
  masteryScore?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "contentCmi5AssignableUnit" */
export type ContentCmi5AssignableUnit_Stddev_Order_By = {
  masteryScore?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type ContentCmi5AssignableUnit_Stddev_Pop_Fields = {
  __typename?: 'contentCmi5AssignableUnit_stddev_pop_fields';
  masteryScore?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "contentCmi5AssignableUnit" */
export type ContentCmi5AssignableUnit_Stddev_Pop_Order_By = {
  masteryScore?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type ContentCmi5AssignableUnit_Stddev_Samp_Fields = {
  __typename?: 'contentCmi5AssignableUnit_stddev_samp_fields';
  masteryScore?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "contentCmi5AssignableUnit" */
export type ContentCmi5AssignableUnit_Stddev_Samp_Order_By = {
  masteryScore?: Maybe<Order_By>;
};

/** Streaming cursor of the table "contentCmi5AssignableUnit" */
export type ContentCmi5AssignableUnit_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: ContentCmi5AssignableUnit_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type ContentCmi5AssignableUnit_Stream_Cursor_Value_Input = {
  activityType?: Maybe<Scalars['String']>;
  contentCmi5Id?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  descriptionI18nId?: Maybe<Scalars['uuid']>;
  entitlementKey?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  idCmi5?: Maybe<Scalars['String']>;
  language?: Maybe<Scalars['String']>;
  launchMethod?: Maybe<Scalars['String']>;
  launchParameters?: Maybe<Scalars['String']>;
  masteryScore?: Maybe<Scalars['numeric']>;
  moveOn?: Maybe<Scalars['String']>;
  pathArchiveCmi5?: Maybe<Scalars['String']>;
  pathFolderCmi5?: Maybe<Scalars['String']>;
  titleI18nId?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  url?: Maybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type ContentCmi5AssignableUnit_Sum_Fields = {
  __typename?: 'contentCmi5AssignableUnit_sum_fields';
  masteryScore?: Maybe<Scalars['numeric']>;
};

/** order by sum() on columns of table "contentCmi5AssignableUnit" */
export type ContentCmi5AssignableUnit_Sum_Order_By = {
  masteryScore?: Maybe<Order_By>;
};

/** update columns of table "contentCmi5AssignableUnit" */
export enum ContentCmi5AssignableUnit_Update_Column {
  /** column name */
  ActivityType = 'activityType',
  /** column name */
  ContentCmi5Id = 'contentCmi5Id',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  DescriptionI18nId = 'descriptionI18nId',
  /** column name */
  EntitlementKey = 'entitlementKey',
  /** column name */
  Id = 'id',
  /** column name */
  IdCmi5 = 'idCmi5',
  /** column name */
  Language = 'language',
  /** column name */
  LaunchMethod = 'launchMethod',
  /** column name */
  LaunchParameters = 'launchParameters',
  /** column name */
  MasteryScore = 'masteryScore',
  /** column name */
  MoveOn = 'moveOn',
  /** column name */
  PathArchiveCmi5 = 'pathArchiveCmi5',
  /** column name */
  PathFolderCmi5 = 'pathFolderCmi5',
  /** column name */
  TitleI18nId = 'titleI18nId',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  Url = 'url'
}

export type ContentCmi5AssignableUnit_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<ContentCmi5AssignableUnit_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<ContentCmi5AssignableUnit_Set_Input>;
  /** filter the rows which have to be updated */
  where: ContentCmi5AssignableUnit_Bool_Exp;
};

/** aggregate var_pop on columns */
export type ContentCmi5AssignableUnit_Var_Pop_Fields = {
  __typename?: 'contentCmi5AssignableUnit_var_pop_fields';
  masteryScore?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "contentCmi5AssignableUnit" */
export type ContentCmi5AssignableUnit_Var_Pop_Order_By = {
  masteryScore?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type ContentCmi5AssignableUnit_Var_Samp_Fields = {
  __typename?: 'contentCmi5AssignableUnit_var_samp_fields';
  masteryScore?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "contentCmi5AssignableUnit" */
export type ContentCmi5AssignableUnit_Var_Samp_Order_By = {
  masteryScore?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type ContentCmi5AssignableUnit_Variance_Fields = {
  __typename?: 'contentCmi5AssignableUnit_variance_fields';
  masteryScore?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "contentCmi5AssignableUnit" */
export type ContentCmi5AssignableUnit_Variance_Order_By = {
  masteryScore?: Maybe<Order_By>;
};

/** columns and relationships of "contentCmi5Objective" */
export type ContentCmi5Objective = {
  __typename?: 'contentCmi5Objective';
  createdAt: Scalars['timestamptz'];
  descriptionI18nId?: Maybe<Scalars['uuid']>;
  id: Scalars['uuid'];
  idCmi5: Scalars['String'];
  titleI18nId?: Maybe<Scalars['uuid']>;
  updatedAt: Scalars['timestamptz'];
};

/** aggregated selection of "contentCmi5Objective" */
export type ContentCmi5Objective_Aggregate = {
  __typename?: 'contentCmi5Objective_aggregate';
  aggregate?: Maybe<ContentCmi5Objective_Aggregate_Fields>;
  nodes: Array<ContentCmi5Objective>;
};

/** aggregate fields of "contentCmi5Objective" */
export type ContentCmi5Objective_Aggregate_Fields = {
  __typename?: 'contentCmi5Objective_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<ContentCmi5Objective_Max_Fields>;
  min?: Maybe<ContentCmi5Objective_Min_Fields>;
};


/** aggregate fields of "contentCmi5Objective" */
export type ContentCmi5Objective_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<ContentCmi5Objective_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "contentCmi5Objective". All fields are combined with a logical 'AND'. */
export type ContentCmi5Objective_Bool_Exp = {
  _and?: Maybe<Array<ContentCmi5Objective_Bool_Exp>>;
  _not?: Maybe<ContentCmi5Objective_Bool_Exp>;
  _or?: Maybe<Array<ContentCmi5Objective_Bool_Exp>>;
  createdAt?: Maybe<Timestamptz_Comparison_Exp>;
  descriptionI18nId?: Maybe<Uuid_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  idCmi5?: Maybe<String_Comparison_Exp>;
  titleI18nId?: Maybe<Uuid_Comparison_Exp>;
  updatedAt?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "contentCmi5Objective" */
export enum ContentCmi5Objective_Constraint {
  /** unique or primary key constraint on columns "id" */
  ContentCmi5ObjectivePkey = 'contentCmi5Objective_pkey'
}

/** input type for inserting data into table "contentCmi5Objective" */
export type ContentCmi5Objective_Insert_Input = {
  createdAt?: Maybe<Scalars['timestamptz']>;
  descriptionI18nId?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  idCmi5?: Maybe<Scalars['String']>;
  titleI18nId?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type ContentCmi5Objective_Max_Fields = {
  __typename?: 'contentCmi5Objective_max_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  descriptionI18nId?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  idCmi5?: Maybe<Scalars['String']>;
  titleI18nId?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type ContentCmi5Objective_Min_Fields = {
  __typename?: 'contentCmi5Objective_min_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  descriptionI18nId?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  idCmi5?: Maybe<Scalars['String']>;
  titleI18nId?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "contentCmi5Objective" */
export type ContentCmi5Objective_Mutation_Response = {
  __typename?: 'contentCmi5Objective_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<ContentCmi5Objective>;
};

/** on_conflict condition type for table "contentCmi5Objective" */
export type ContentCmi5Objective_On_Conflict = {
  constraint: ContentCmi5Objective_Constraint;
  update_columns?: Array<ContentCmi5Objective_Update_Column>;
  where?: Maybe<ContentCmi5Objective_Bool_Exp>;
};

/** Ordering options when selecting data from "contentCmi5Objective". */
export type ContentCmi5Objective_Order_By = {
  createdAt?: Maybe<Order_By>;
  descriptionI18nId?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  idCmi5?: Maybe<Order_By>;
  titleI18nId?: Maybe<Order_By>;
  updatedAt?: Maybe<Order_By>;
};

/** primary key columns input for table: contentCmi5Objective */
export type ContentCmi5Objective_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "contentCmi5Objective" */
export enum ContentCmi5Objective_Select_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  DescriptionI18nId = 'descriptionI18nId',
  /** column name */
  Id = 'id',
  /** column name */
  IdCmi5 = 'idCmi5',
  /** column name */
  TitleI18nId = 'titleI18nId',
  /** column name */
  UpdatedAt = 'updatedAt'
}

/** input type for updating data in table "contentCmi5Objective" */
export type ContentCmi5Objective_Set_Input = {
  createdAt?: Maybe<Scalars['timestamptz']>;
  descriptionI18nId?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  idCmi5?: Maybe<Scalars['String']>;
  titleI18nId?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "contentCmi5Objective" */
export type ContentCmi5Objective_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: ContentCmi5Objective_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type ContentCmi5Objective_Stream_Cursor_Value_Input = {
  createdAt?: Maybe<Scalars['timestamptz']>;
  descriptionI18nId?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  idCmi5?: Maybe<Scalars['String']>;
  titleI18nId?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** update columns of table "contentCmi5Objective" */
export enum ContentCmi5Objective_Update_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  DescriptionI18nId = 'descriptionI18nId',
  /** column name */
  Id = 'id',
  /** column name */
  IdCmi5 = 'idCmi5',
  /** column name */
  TitleI18nId = 'titleI18nId',
  /** column name */
  UpdatedAt = 'updatedAt'
}

export type ContentCmi5Objective_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<ContentCmi5Objective_Set_Input>;
  /** filter the rows which have to be updated */
  where: ContentCmi5Objective_Bool_Exp;
};

/** aggregated selection of "contentCmi5" */
export type ContentCmi5_Aggregate = {
  __typename?: 'contentCmi5_aggregate';
  aggregate?: Maybe<ContentCmi5_Aggregate_Fields>;
  nodes: Array<ContentCmi5>;
};

/** aggregate fields of "contentCmi5" */
export type ContentCmi5_Aggregate_Fields = {
  __typename?: 'contentCmi5_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<ContentCmi5_Max_Fields>;
  min?: Maybe<ContentCmi5_Min_Fields>;
};


/** aggregate fields of "contentCmi5" */
export type ContentCmi5_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<ContentCmi5_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "contentCmi5". All fields are combined with a logical 'AND'. */
export type ContentCmi5_Bool_Exp = {
  _and?: Maybe<Array<ContentCmi5_Bool_Exp>>;
  _not?: Maybe<ContentCmi5_Bool_Exp>;
  _or?: Maybe<Array<ContentCmi5_Bool_Exp>>;
  contentCmi5AssignableUnits?: Maybe<ContentCmi5AssignableUnit_Bool_Exp>;
  contentCmi5AssignableUnits_aggregate?: Maybe<ContentCmi5AssignableUnit_Aggregate_Bool_Exp>;
  createdAt?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  updatedAt?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "contentCmi5" */
export enum ContentCmi5_Constraint {
  /** unique or primary key constraint on columns "id" */
  ContentCmi5Pkey = 'contentCmi5_pkey'
}

/** input type for inserting data into table "contentCmi5" */
export type ContentCmi5_Insert_Input = {
  contentCmi5AssignableUnits?: Maybe<ContentCmi5AssignableUnit_Arr_Rel_Insert_Input>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type ContentCmi5_Max_Fields = {
  __typename?: 'contentCmi5_max_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type ContentCmi5_Min_Fields = {
  __typename?: 'contentCmi5_min_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "contentCmi5" */
export type ContentCmi5_Mutation_Response = {
  __typename?: 'contentCmi5_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<ContentCmi5>;
};

/** input type for inserting object relation for remote table "contentCmi5" */
export type ContentCmi5_Obj_Rel_Insert_Input = {
  data: ContentCmi5_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<ContentCmi5_On_Conflict>;
};

/** on_conflict condition type for table "contentCmi5" */
export type ContentCmi5_On_Conflict = {
  constraint: ContentCmi5_Constraint;
  update_columns?: Array<ContentCmi5_Update_Column>;
  where?: Maybe<ContentCmi5_Bool_Exp>;
};

/** Ordering options when selecting data from "contentCmi5". */
export type ContentCmi5_Order_By = {
  contentCmi5AssignableUnits_aggregate?: Maybe<ContentCmi5AssignableUnit_Aggregate_Order_By>;
  createdAt?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  updatedAt?: Maybe<Order_By>;
};

/** primary key columns input for table: contentCmi5 */
export type ContentCmi5_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "contentCmi5" */
export enum ContentCmi5_Select_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updatedAt'
}

/** input type for updating data in table "contentCmi5" */
export type ContentCmi5_Set_Input = {
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "contentCmi5" */
export type ContentCmi5_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: ContentCmi5_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type ContentCmi5_Stream_Cursor_Value_Input = {
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** update columns of table "contentCmi5" */
export enum ContentCmi5_Update_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updatedAt'
}

export type ContentCmi5_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<ContentCmi5_Set_Input>;
  /** filter the rows which have to be updated */
  where: ContentCmi5_Bool_Exp;
};

/** columns and relationships of "contentDocument" */
export type ContentDocument = {
  __typename?: 'contentDocument';
  /** An array relationship */
  contentDocumentUploads: Array<ContentDocumentUpload>;
  /** An aggregate relationship */
  contentDocumentUploads_aggregate: ContentDocumentUpload_Aggregate;
  createdAt: Scalars['timestamptz'];
  id: Scalars['uuid'];
  updatedAt: Scalars['timestamptz'];
};


/** columns and relationships of "contentDocument" */
export type ContentDocumentContentDocumentUploadsArgs = {
  distinct_on?: Maybe<Array<ContentDocumentUpload_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ContentDocumentUpload_Order_By>>;
  where?: Maybe<ContentDocumentUpload_Bool_Exp>;
};


/** columns and relationships of "contentDocument" */
export type ContentDocumentContentDocumentUploads_AggregateArgs = {
  distinct_on?: Maybe<Array<ContentDocumentUpload_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ContentDocumentUpload_Order_By>>;
  where?: Maybe<ContentDocumentUpload_Bool_Exp>;
};

/** columns and relationships of "contentDocumentUpload" */
export type ContentDocumentUpload = {
  __typename?: 'contentDocumentUpload';
  contentDocumentId: Scalars['uuid'];
  createdAt: Scalars['timestamptz'];
  id: Scalars['uuid'];
  language: Scalars['String'];
  size?: Maybe<Scalars['Int']>;
  updatedAt: Scalars['timestamptz'];
  url: Scalars['String'];
};

/** aggregated selection of "contentDocumentUpload" */
export type ContentDocumentUpload_Aggregate = {
  __typename?: 'contentDocumentUpload_aggregate';
  aggregate?: Maybe<ContentDocumentUpload_Aggregate_Fields>;
  nodes: Array<ContentDocumentUpload>;
};

export type ContentDocumentUpload_Aggregate_Bool_Exp = {
  count?: Maybe<ContentDocumentUpload_Aggregate_Bool_Exp_Count>;
};

export type ContentDocumentUpload_Aggregate_Bool_Exp_Count = {
  arguments?: Maybe<Array<ContentDocumentUpload_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<ContentDocumentUpload_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "contentDocumentUpload" */
export type ContentDocumentUpload_Aggregate_Fields = {
  __typename?: 'contentDocumentUpload_aggregate_fields';
  avg?: Maybe<ContentDocumentUpload_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<ContentDocumentUpload_Max_Fields>;
  min?: Maybe<ContentDocumentUpload_Min_Fields>;
  stddev?: Maybe<ContentDocumentUpload_Stddev_Fields>;
  stddev_pop?: Maybe<ContentDocumentUpload_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<ContentDocumentUpload_Stddev_Samp_Fields>;
  sum?: Maybe<ContentDocumentUpload_Sum_Fields>;
  var_pop?: Maybe<ContentDocumentUpload_Var_Pop_Fields>;
  var_samp?: Maybe<ContentDocumentUpload_Var_Samp_Fields>;
  variance?: Maybe<ContentDocumentUpload_Variance_Fields>;
};


/** aggregate fields of "contentDocumentUpload" */
export type ContentDocumentUpload_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<ContentDocumentUpload_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "contentDocumentUpload" */
export type ContentDocumentUpload_Aggregate_Order_By = {
  avg?: Maybe<ContentDocumentUpload_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<ContentDocumentUpload_Max_Order_By>;
  min?: Maybe<ContentDocumentUpload_Min_Order_By>;
  stddev?: Maybe<ContentDocumentUpload_Stddev_Order_By>;
  stddev_pop?: Maybe<ContentDocumentUpload_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<ContentDocumentUpload_Stddev_Samp_Order_By>;
  sum?: Maybe<ContentDocumentUpload_Sum_Order_By>;
  var_pop?: Maybe<ContentDocumentUpload_Var_Pop_Order_By>;
  var_samp?: Maybe<ContentDocumentUpload_Var_Samp_Order_By>;
  variance?: Maybe<ContentDocumentUpload_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "contentDocumentUpload" */
export type ContentDocumentUpload_Arr_Rel_Insert_Input = {
  data: Array<ContentDocumentUpload_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<ContentDocumentUpload_On_Conflict>;
};

/** aggregate avg on columns */
export type ContentDocumentUpload_Avg_Fields = {
  __typename?: 'contentDocumentUpload_avg_fields';
  size?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "contentDocumentUpload" */
export type ContentDocumentUpload_Avg_Order_By = {
  size?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "contentDocumentUpload". All fields are combined with a logical 'AND'. */
export type ContentDocumentUpload_Bool_Exp = {
  _and?: Maybe<Array<ContentDocumentUpload_Bool_Exp>>;
  _not?: Maybe<ContentDocumentUpload_Bool_Exp>;
  _or?: Maybe<Array<ContentDocumentUpload_Bool_Exp>>;
  contentDocumentId?: Maybe<Uuid_Comparison_Exp>;
  createdAt?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  language?: Maybe<String_Comparison_Exp>;
  size?: Maybe<Int_Comparison_Exp>;
  updatedAt?: Maybe<Timestamptz_Comparison_Exp>;
  url?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "contentDocumentUpload" */
export enum ContentDocumentUpload_Constraint {
  /** unique or primary key constraint on columns "contentDocumentId", "language" */
  IdxContentDocumentUploadLanguageContentDocumentId = 'IDX_content_document_upload_language_content_document_id',
  /** unique or primary key constraint on columns "id" */
  ContentDocumentUploadPkey = 'contentDocumentUpload_pkey'
}

/** input type for incrementing numeric columns in table "contentDocumentUpload" */
export type ContentDocumentUpload_Inc_Input = {
  size?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "contentDocumentUpload" */
export type ContentDocumentUpload_Insert_Input = {
  contentDocumentId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  language?: Maybe<Scalars['String']>;
  size?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  url?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type ContentDocumentUpload_Max_Fields = {
  __typename?: 'contentDocumentUpload_max_fields';
  contentDocumentId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  language?: Maybe<Scalars['String']>;
  size?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  url?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "contentDocumentUpload" */
export type ContentDocumentUpload_Max_Order_By = {
  contentDocumentId?: Maybe<Order_By>;
  createdAt?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  language?: Maybe<Order_By>;
  size?: Maybe<Order_By>;
  updatedAt?: Maybe<Order_By>;
  url?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type ContentDocumentUpload_Min_Fields = {
  __typename?: 'contentDocumentUpload_min_fields';
  contentDocumentId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  language?: Maybe<Scalars['String']>;
  size?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  url?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "contentDocumentUpload" */
export type ContentDocumentUpload_Min_Order_By = {
  contentDocumentId?: Maybe<Order_By>;
  createdAt?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  language?: Maybe<Order_By>;
  size?: Maybe<Order_By>;
  updatedAt?: Maybe<Order_By>;
  url?: Maybe<Order_By>;
};

/** response of any mutation on the table "contentDocumentUpload" */
export type ContentDocumentUpload_Mutation_Response = {
  __typename?: 'contentDocumentUpload_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<ContentDocumentUpload>;
};

/** on_conflict condition type for table "contentDocumentUpload" */
export type ContentDocumentUpload_On_Conflict = {
  constraint: ContentDocumentUpload_Constraint;
  update_columns?: Array<ContentDocumentUpload_Update_Column>;
  where?: Maybe<ContentDocumentUpload_Bool_Exp>;
};

/** Ordering options when selecting data from "contentDocumentUpload". */
export type ContentDocumentUpload_Order_By = {
  contentDocumentId?: Maybe<Order_By>;
  createdAt?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  language?: Maybe<Order_By>;
  size?: Maybe<Order_By>;
  updatedAt?: Maybe<Order_By>;
  url?: Maybe<Order_By>;
};

/** primary key columns input for table: contentDocumentUpload */
export type ContentDocumentUpload_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "contentDocumentUpload" */
export enum ContentDocumentUpload_Select_Column {
  /** column name */
  ContentDocumentId = 'contentDocumentId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  Language = 'language',
  /** column name */
  Size = 'size',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  Url = 'url'
}

/** input type for updating data in table "contentDocumentUpload" */
export type ContentDocumentUpload_Set_Input = {
  contentDocumentId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  language?: Maybe<Scalars['String']>;
  size?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  url?: Maybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type ContentDocumentUpload_Stddev_Fields = {
  __typename?: 'contentDocumentUpload_stddev_fields';
  size?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "contentDocumentUpload" */
export type ContentDocumentUpload_Stddev_Order_By = {
  size?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type ContentDocumentUpload_Stddev_Pop_Fields = {
  __typename?: 'contentDocumentUpload_stddev_pop_fields';
  size?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "contentDocumentUpload" */
export type ContentDocumentUpload_Stddev_Pop_Order_By = {
  size?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type ContentDocumentUpload_Stddev_Samp_Fields = {
  __typename?: 'contentDocumentUpload_stddev_samp_fields';
  size?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "contentDocumentUpload" */
export type ContentDocumentUpload_Stddev_Samp_Order_By = {
  size?: Maybe<Order_By>;
};

/** Streaming cursor of the table "contentDocumentUpload" */
export type ContentDocumentUpload_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: ContentDocumentUpload_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type ContentDocumentUpload_Stream_Cursor_Value_Input = {
  contentDocumentId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  language?: Maybe<Scalars['String']>;
  size?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  url?: Maybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type ContentDocumentUpload_Sum_Fields = {
  __typename?: 'contentDocumentUpload_sum_fields';
  size?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "contentDocumentUpload" */
export type ContentDocumentUpload_Sum_Order_By = {
  size?: Maybe<Order_By>;
};

/** update columns of table "contentDocumentUpload" */
export enum ContentDocumentUpload_Update_Column {
  /** column name */
  ContentDocumentId = 'contentDocumentId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  Language = 'language',
  /** column name */
  Size = 'size',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  Url = 'url'
}

export type ContentDocumentUpload_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<ContentDocumentUpload_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<ContentDocumentUpload_Set_Input>;
  /** filter the rows which have to be updated */
  where: ContentDocumentUpload_Bool_Exp;
};

/** aggregate var_pop on columns */
export type ContentDocumentUpload_Var_Pop_Fields = {
  __typename?: 'contentDocumentUpload_var_pop_fields';
  size?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "contentDocumentUpload" */
export type ContentDocumentUpload_Var_Pop_Order_By = {
  size?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type ContentDocumentUpload_Var_Samp_Fields = {
  __typename?: 'contentDocumentUpload_var_samp_fields';
  size?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "contentDocumentUpload" */
export type ContentDocumentUpload_Var_Samp_Order_By = {
  size?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type ContentDocumentUpload_Variance_Fields = {
  __typename?: 'contentDocumentUpload_variance_fields';
  size?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "contentDocumentUpload" */
export type ContentDocumentUpload_Variance_Order_By = {
  size?: Maybe<Order_By>;
};

/** aggregated selection of "contentDocument" */
export type ContentDocument_Aggregate = {
  __typename?: 'contentDocument_aggregate';
  aggregate?: Maybe<ContentDocument_Aggregate_Fields>;
  nodes: Array<ContentDocument>;
};

/** aggregate fields of "contentDocument" */
export type ContentDocument_Aggregate_Fields = {
  __typename?: 'contentDocument_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<ContentDocument_Max_Fields>;
  min?: Maybe<ContentDocument_Min_Fields>;
};


/** aggregate fields of "contentDocument" */
export type ContentDocument_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<ContentDocument_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "contentDocument". All fields are combined with a logical 'AND'. */
export type ContentDocument_Bool_Exp = {
  _and?: Maybe<Array<ContentDocument_Bool_Exp>>;
  _not?: Maybe<ContentDocument_Bool_Exp>;
  _or?: Maybe<Array<ContentDocument_Bool_Exp>>;
  contentDocumentUploads?: Maybe<ContentDocumentUpload_Bool_Exp>;
  contentDocumentUploads_aggregate?: Maybe<ContentDocumentUpload_Aggregate_Bool_Exp>;
  createdAt?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  updatedAt?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "contentDocument" */
export enum ContentDocument_Constraint {
  /** unique or primary key constraint on columns "id" */
  ContentDocumentPkey = 'contentDocument_pkey'
}

/** input type for inserting data into table "contentDocument" */
export type ContentDocument_Insert_Input = {
  contentDocumentUploads?: Maybe<ContentDocumentUpload_Arr_Rel_Insert_Input>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type ContentDocument_Max_Fields = {
  __typename?: 'contentDocument_max_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type ContentDocument_Min_Fields = {
  __typename?: 'contentDocument_min_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "contentDocument" */
export type ContentDocument_Mutation_Response = {
  __typename?: 'contentDocument_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<ContentDocument>;
};

/** input type for inserting object relation for remote table "contentDocument" */
export type ContentDocument_Obj_Rel_Insert_Input = {
  data: ContentDocument_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<ContentDocument_On_Conflict>;
};

/** on_conflict condition type for table "contentDocument" */
export type ContentDocument_On_Conflict = {
  constraint: ContentDocument_Constraint;
  update_columns?: Array<ContentDocument_Update_Column>;
  where?: Maybe<ContentDocument_Bool_Exp>;
};

/** Ordering options when selecting data from "contentDocument". */
export type ContentDocument_Order_By = {
  contentDocumentUploads_aggregate?: Maybe<ContentDocumentUpload_Aggregate_Order_By>;
  createdAt?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  updatedAt?: Maybe<Order_By>;
};

/** primary key columns input for table: contentDocument */
export type ContentDocument_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "contentDocument" */
export enum ContentDocument_Select_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updatedAt'
}

/** input type for updating data in table "contentDocument" */
export type ContentDocument_Set_Input = {
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "contentDocument" */
export type ContentDocument_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: ContentDocument_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type ContentDocument_Stream_Cursor_Value_Input = {
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** update columns of table "contentDocument" */
export enum ContentDocument_Update_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updatedAt'
}

export type ContentDocument_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<ContentDocument_Set_Input>;
  /** filter the rows which have to be updated */
  where: ContentDocument_Bool_Exp;
};

/** columns and relationships of "contentEmbed" */
export type ContentEmbed = {
  __typename?: 'contentEmbed';
  /** An array relationship */
  contentEmbedAudioTranscripts: Array<ContentEmbedAudioTranscript>;
  /** An aggregate relationship */
  contentEmbedAudioTranscripts_aggregate: ContentEmbedAudioTranscript_Aggregate;
  createdAt: Scalars['timestamptz'];
  id: Scalars['uuid'];
  link: Scalars['String'];
  /** An array relationship */
  moduleContents: Array<ModuleContent>;
  /** An aggregate relationship */
  moduleContents_aggregate: ModuleContent_Aggregate;
  subtype?: Maybe<Scalars['String']>;
  updatedAt: Scalars['timestamptz'];
};


/** columns and relationships of "contentEmbed" */
export type ContentEmbedContentEmbedAudioTranscriptsArgs = {
  distinct_on?: Maybe<Array<ContentEmbedAudioTranscript_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ContentEmbedAudioTranscript_Order_By>>;
  where?: Maybe<ContentEmbedAudioTranscript_Bool_Exp>;
};


/** columns and relationships of "contentEmbed" */
export type ContentEmbedContentEmbedAudioTranscripts_AggregateArgs = {
  distinct_on?: Maybe<Array<ContentEmbedAudioTranscript_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ContentEmbedAudioTranscript_Order_By>>;
  where?: Maybe<ContentEmbedAudioTranscript_Bool_Exp>;
};


/** columns and relationships of "contentEmbed" */
export type ContentEmbedModuleContentsArgs = {
  distinct_on?: Maybe<Array<ModuleContent_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ModuleContent_Order_By>>;
  where?: Maybe<ModuleContent_Bool_Exp>;
};


/** columns and relationships of "contentEmbed" */
export type ContentEmbedModuleContents_AggregateArgs = {
  distinct_on?: Maybe<Array<ModuleContent_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ModuleContent_Order_By>>;
  where?: Maybe<ModuleContent_Bool_Exp>;
};

/** columns and relationships of "contentEmbedAudioTranscript" */
export type ContentEmbedAudioTranscript = {
  __typename?: 'contentEmbedAudioTranscript';
  /** An object relationship */
  contentEmbed: ContentEmbed;
  contentEmbedId: Scalars['uuid'];
  createdAt: Scalars['timestamptz'];
  id: Scalars['uuid'];
  language: Scalars['String'];
  size?: Maybe<Scalars['Int']>;
  updatedAt: Scalars['timestamptz'];
  url: Scalars['String'];
};

/** aggregated selection of "contentEmbedAudioTranscript" */
export type ContentEmbedAudioTranscript_Aggregate = {
  __typename?: 'contentEmbedAudioTranscript_aggregate';
  aggregate?: Maybe<ContentEmbedAudioTranscript_Aggregate_Fields>;
  nodes: Array<ContentEmbedAudioTranscript>;
};

export type ContentEmbedAudioTranscript_Aggregate_Bool_Exp = {
  count?: Maybe<ContentEmbedAudioTranscript_Aggregate_Bool_Exp_Count>;
};

export type ContentEmbedAudioTranscript_Aggregate_Bool_Exp_Count = {
  arguments?: Maybe<Array<ContentEmbedAudioTranscript_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<ContentEmbedAudioTranscript_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "contentEmbedAudioTranscript" */
export type ContentEmbedAudioTranscript_Aggregate_Fields = {
  __typename?: 'contentEmbedAudioTranscript_aggregate_fields';
  avg?: Maybe<ContentEmbedAudioTranscript_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<ContentEmbedAudioTranscript_Max_Fields>;
  min?: Maybe<ContentEmbedAudioTranscript_Min_Fields>;
  stddev?: Maybe<ContentEmbedAudioTranscript_Stddev_Fields>;
  stddev_pop?: Maybe<ContentEmbedAudioTranscript_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<ContentEmbedAudioTranscript_Stddev_Samp_Fields>;
  sum?: Maybe<ContentEmbedAudioTranscript_Sum_Fields>;
  var_pop?: Maybe<ContentEmbedAudioTranscript_Var_Pop_Fields>;
  var_samp?: Maybe<ContentEmbedAudioTranscript_Var_Samp_Fields>;
  variance?: Maybe<ContentEmbedAudioTranscript_Variance_Fields>;
};


/** aggregate fields of "contentEmbedAudioTranscript" */
export type ContentEmbedAudioTranscript_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<ContentEmbedAudioTranscript_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "contentEmbedAudioTranscript" */
export type ContentEmbedAudioTranscript_Aggregate_Order_By = {
  avg?: Maybe<ContentEmbedAudioTranscript_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<ContentEmbedAudioTranscript_Max_Order_By>;
  min?: Maybe<ContentEmbedAudioTranscript_Min_Order_By>;
  stddev?: Maybe<ContentEmbedAudioTranscript_Stddev_Order_By>;
  stddev_pop?: Maybe<ContentEmbedAudioTranscript_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<ContentEmbedAudioTranscript_Stddev_Samp_Order_By>;
  sum?: Maybe<ContentEmbedAudioTranscript_Sum_Order_By>;
  var_pop?: Maybe<ContentEmbedAudioTranscript_Var_Pop_Order_By>;
  var_samp?: Maybe<ContentEmbedAudioTranscript_Var_Samp_Order_By>;
  variance?: Maybe<ContentEmbedAudioTranscript_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "contentEmbedAudioTranscript" */
export type ContentEmbedAudioTranscript_Arr_Rel_Insert_Input = {
  data: Array<ContentEmbedAudioTranscript_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<ContentEmbedAudioTranscript_On_Conflict>;
};

/** aggregate avg on columns */
export type ContentEmbedAudioTranscript_Avg_Fields = {
  __typename?: 'contentEmbedAudioTranscript_avg_fields';
  size?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "contentEmbedAudioTranscript" */
export type ContentEmbedAudioTranscript_Avg_Order_By = {
  size?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "contentEmbedAudioTranscript". All fields are combined with a logical 'AND'. */
export type ContentEmbedAudioTranscript_Bool_Exp = {
  _and?: Maybe<Array<ContentEmbedAudioTranscript_Bool_Exp>>;
  _not?: Maybe<ContentEmbedAudioTranscript_Bool_Exp>;
  _or?: Maybe<Array<ContentEmbedAudioTranscript_Bool_Exp>>;
  contentEmbed?: Maybe<ContentEmbed_Bool_Exp>;
  contentEmbedId?: Maybe<Uuid_Comparison_Exp>;
  createdAt?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  language?: Maybe<String_Comparison_Exp>;
  size?: Maybe<Int_Comparison_Exp>;
  updatedAt?: Maybe<Timestamptz_Comparison_Exp>;
  url?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "contentEmbedAudioTranscript" */
export enum ContentEmbedAudioTranscript_Constraint {
  /** unique or primary key constraint on columns "contentEmbedId", "language" */
  IdxContentEmbedAudioTranscriptLanguageContentEmbedId = 'IDX_content_embed_audio_transcript_language_content_embed_id',
  /** unique or primary key constraint on columns "id" */
  ContentEmbedAudioTranscriptPkey = 'contentEmbedAudioTranscript_pkey'
}

/** input type for incrementing numeric columns in table "contentEmbedAudioTranscript" */
export type ContentEmbedAudioTranscript_Inc_Input = {
  size?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "contentEmbedAudioTranscript" */
export type ContentEmbedAudioTranscript_Insert_Input = {
  contentEmbed?: Maybe<ContentEmbed_Obj_Rel_Insert_Input>;
  contentEmbedId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  language?: Maybe<Scalars['String']>;
  size?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  url?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type ContentEmbedAudioTranscript_Max_Fields = {
  __typename?: 'contentEmbedAudioTranscript_max_fields';
  contentEmbedId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  language?: Maybe<Scalars['String']>;
  size?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  url?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "contentEmbedAudioTranscript" */
export type ContentEmbedAudioTranscript_Max_Order_By = {
  contentEmbedId?: Maybe<Order_By>;
  createdAt?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  language?: Maybe<Order_By>;
  size?: Maybe<Order_By>;
  updatedAt?: Maybe<Order_By>;
  url?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type ContentEmbedAudioTranscript_Min_Fields = {
  __typename?: 'contentEmbedAudioTranscript_min_fields';
  contentEmbedId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  language?: Maybe<Scalars['String']>;
  size?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  url?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "contentEmbedAudioTranscript" */
export type ContentEmbedAudioTranscript_Min_Order_By = {
  contentEmbedId?: Maybe<Order_By>;
  createdAt?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  language?: Maybe<Order_By>;
  size?: Maybe<Order_By>;
  updatedAt?: Maybe<Order_By>;
  url?: Maybe<Order_By>;
};

/** response of any mutation on the table "contentEmbedAudioTranscript" */
export type ContentEmbedAudioTranscript_Mutation_Response = {
  __typename?: 'contentEmbedAudioTranscript_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<ContentEmbedAudioTranscript>;
};

/** on_conflict condition type for table "contentEmbedAudioTranscript" */
export type ContentEmbedAudioTranscript_On_Conflict = {
  constraint: ContentEmbedAudioTranscript_Constraint;
  update_columns?: Array<ContentEmbedAudioTranscript_Update_Column>;
  where?: Maybe<ContentEmbedAudioTranscript_Bool_Exp>;
};

/** Ordering options when selecting data from "contentEmbedAudioTranscript". */
export type ContentEmbedAudioTranscript_Order_By = {
  contentEmbed?: Maybe<ContentEmbed_Order_By>;
  contentEmbedId?: Maybe<Order_By>;
  createdAt?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  language?: Maybe<Order_By>;
  size?: Maybe<Order_By>;
  updatedAt?: Maybe<Order_By>;
  url?: Maybe<Order_By>;
};

/** primary key columns input for table: contentEmbedAudioTranscript */
export type ContentEmbedAudioTranscript_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "contentEmbedAudioTranscript" */
export enum ContentEmbedAudioTranscript_Select_Column {
  /** column name */
  ContentEmbedId = 'contentEmbedId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  Language = 'language',
  /** column name */
  Size = 'size',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  Url = 'url'
}

/** input type for updating data in table "contentEmbedAudioTranscript" */
export type ContentEmbedAudioTranscript_Set_Input = {
  contentEmbedId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  language?: Maybe<Scalars['String']>;
  size?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  url?: Maybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type ContentEmbedAudioTranscript_Stddev_Fields = {
  __typename?: 'contentEmbedAudioTranscript_stddev_fields';
  size?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "contentEmbedAudioTranscript" */
export type ContentEmbedAudioTranscript_Stddev_Order_By = {
  size?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type ContentEmbedAudioTranscript_Stddev_Pop_Fields = {
  __typename?: 'contentEmbedAudioTranscript_stddev_pop_fields';
  size?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "contentEmbedAudioTranscript" */
export type ContentEmbedAudioTranscript_Stddev_Pop_Order_By = {
  size?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type ContentEmbedAudioTranscript_Stddev_Samp_Fields = {
  __typename?: 'contentEmbedAudioTranscript_stddev_samp_fields';
  size?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "contentEmbedAudioTranscript" */
export type ContentEmbedAudioTranscript_Stddev_Samp_Order_By = {
  size?: Maybe<Order_By>;
};

/** Streaming cursor of the table "contentEmbedAudioTranscript" */
export type ContentEmbedAudioTranscript_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: ContentEmbedAudioTranscript_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type ContentEmbedAudioTranscript_Stream_Cursor_Value_Input = {
  contentEmbedId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  language?: Maybe<Scalars['String']>;
  size?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  url?: Maybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type ContentEmbedAudioTranscript_Sum_Fields = {
  __typename?: 'contentEmbedAudioTranscript_sum_fields';
  size?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "contentEmbedAudioTranscript" */
export type ContentEmbedAudioTranscript_Sum_Order_By = {
  size?: Maybe<Order_By>;
};

/** update columns of table "contentEmbedAudioTranscript" */
export enum ContentEmbedAudioTranscript_Update_Column {
  /** column name */
  ContentEmbedId = 'contentEmbedId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  Language = 'language',
  /** column name */
  Size = 'size',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  Url = 'url'
}

export type ContentEmbedAudioTranscript_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<ContentEmbedAudioTranscript_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<ContentEmbedAudioTranscript_Set_Input>;
  /** filter the rows which have to be updated */
  where: ContentEmbedAudioTranscript_Bool_Exp;
};

/** aggregate var_pop on columns */
export type ContentEmbedAudioTranscript_Var_Pop_Fields = {
  __typename?: 'contentEmbedAudioTranscript_var_pop_fields';
  size?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "contentEmbedAudioTranscript" */
export type ContentEmbedAudioTranscript_Var_Pop_Order_By = {
  size?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type ContentEmbedAudioTranscript_Var_Samp_Fields = {
  __typename?: 'contentEmbedAudioTranscript_var_samp_fields';
  size?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "contentEmbedAudioTranscript" */
export type ContentEmbedAudioTranscript_Var_Samp_Order_By = {
  size?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type ContentEmbedAudioTranscript_Variance_Fields = {
  __typename?: 'contentEmbedAudioTranscript_variance_fields';
  size?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "contentEmbedAudioTranscript" */
export type ContentEmbedAudioTranscript_Variance_Order_By = {
  size?: Maybe<Order_By>;
};

/** aggregated selection of "contentEmbed" */
export type ContentEmbed_Aggregate = {
  __typename?: 'contentEmbed_aggregate';
  aggregate?: Maybe<ContentEmbed_Aggregate_Fields>;
  nodes: Array<ContentEmbed>;
};

/** aggregate fields of "contentEmbed" */
export type ContentEmbed_Aggregate_Fields = {
  __typename?: 'contentEmbed_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<ContentEmbed_Max_Fields>;
  min?: Maybe<ContentEmbed_Min_Fields>;
};


/** aggregate fields of "contentEmbed" */
export type ContentEmbed_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<ContentEmbed_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "contentEmbed". All fields are combined with a logical 'AND'. */
export type ContentEmbed_Bool_Exp = {
  _and?: Maybe<Array<ContentEmbed_Bool_Exp>>;
  _not?: Maybe<ContentEmbed_Bool_Exp>;
  _or?: Maybe<Array<ContentEmbed_Bool_Exp>>;
  contentEmbedAudioTranscripts?: Maybe<ContentEmbedAudioTranscript_Bool_Exp>;
  contentEmbedAudioTranscripts_aggregate?: Maybe<ContentEmbedAudioTranscript_Aggregate_Bool_Exp>;
  createdAt?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  link?: Maybe<String_Comparison_Exp>;
  moduleContents?: Maybe<ModuleContent_Bool_Exp>;
  moduleContents_aggregate?: Maybe<ModuleContent_Aggregate_Bool_Exp>;
  subtype?: Maybe<String_Comparison_Exp>;
  updatedAt?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "contentEmbed" */
export enum ContentEmbed_Constraint {
  /** unique or primary key constraint on columns "id" */
  ContentEmbedPkey = 'contentEmbed_pkey'
}

/** input type for inserting data into table "contentEmbed" */
export type ContentEmbed_Insert_Input = {
  contentEmbedAudioTranscripts?: Maybe<ContentEmbedAudioTranscript_Arr_Rel_Insert_Input>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  link?: Maybe<Scalars['String']>;
  moduleContents?: Maybe<ModuleContent_Arr_Rel_Insert_Input>;
  subtype?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type ContentEmbed_Max_Fields = {
  __typename?: 'contentEmbed_max_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  link?: Maybe<Scalars['String']>;
  subtype?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type ContentEmbed_Min_Fields = {
  __typename?: 'contentEmbed_min_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  link?: Maybe<Scalars['String']>;
  subtype?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "contentEmbed" */
export type ContentEmbed_Mutation_Response = {
  __typename?: 'contentEmbed_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<ContentEmbed>;
};

/** input type for inserting object relation for remote table "contentEmbed" */
export type ContentEmbed_Obj_Rel_Insert_Input = {
  data: ContentEmbed_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<ContentEmbed_On_Conflict>;
};

/** on_conflict condition type for table "contentEmbed" */
export type ContentEmbed_On_Conflict = {
  constraint: ContentEmbed_Constraint;
  update_columns?: Array<ContentEmbed_Update_Column>;
  where?: Maybe<ContentEmbed_Bool_Exp>;
};

/** Ordering options when selecting data from "contentEmbed". */
export type ContentEmbed_Order_By = {
  contentEmbedAudioTranscripts_aggregate?: Maybe<ContentEmbedAudioTranscript_Aggregate_Order_By>;
  createdAt?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  link?: Maybe<Order_By>;
  moduleContents_aggregate?: Maybe<ModuleContent_Aggregate_Order_By>;
  subtype?: Maybe<Order_By>;
  updatedAt?: Maybe<Order_By>;
};

/** primary key columns input for table: contentEmbed */
export type ContentEmbed_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "contentEmbed" */
export enum ContentEmbed_Select_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  Link = 'link',
  /** column name */
  Subtype = 'subtype',
  /** column name */
  UpdatedAt = 'updatedAt'
}

/** input type for updating data in table "contentEmbed" */
export type ContentEmbed_Set_Input = {
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  link?: Maybe<Scalars['String']>;
  subtype?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "contentEmbed" */
export type ContentEmbed_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: ContentEmbed_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type ContentEmbed_Stream_Cursor_Value_Input = {
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  link?: Maybe<Scalars['String']>;
  subtype?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** update columns of table "contentEmbed" */
export enum ContentEmbed_Update_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  Link = 'link',
  /** column name */
  Subtype = 'subtype',
  /** column name */
  UpdatedAt = 'updatedAt'
}

export type ContentEmbed_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<ContentEmbed_Set_Input>;
  /** filter the rows which have to be updated */
  where: ContentEmbed_Bool_Exp;
};

/** columns and relationships of "contentEvent" */
export type ContentEvent = {
  __typename?: 'contentEvent';
  /** An array relationship */
  contentEventSlots: Array<ContentEventSlot>;
  /** An aggregate relationship */
  contentEventSlots_aggregate: ContentEventSlot_Aggregate;
  id: Scalars['uuid'];
  /** An array relationship */
  moduleContents: Array<ModuleContent>;
  /** An aggregate relationship */
  moduleContents_aggregate: ModuleContent_Aggregate;
};


/** columns and relationships of "contentEvent" */
export type ContentEventContentEventSlotsArgs = {
  distinct_on?: Maybe<Array<ContentEventSlot_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ContentEventSlot_Order_By>>;
  where?: Maybe<ContentEventSlot_Bool_Exp>;
};


/** columns and relationships of "contentEvent" */
export type ContentEventContentEventSlots_AggregateArgs = {
  distinct_on?: Maybe<Array<ContentEventSlot_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ContentEventSlot_Order_By>>;
  where?: Maybe<ContentEventSlot_Bool_Exp>;
};


/** columns and relationships of "contentEvent" */
export type ContentEventModuleContentsArgs = {
  distinct_on?: Maybe<Array<ModuleContent_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ModuleContent_Order_By>>;
  where?: Maybe<ModuleContent_Bool_Exp>;
};


/** columns and relationships of "contentEvent" */
export type ContentEventModuleContents_AggregateArgs = {
  distinct_on?: Maybe<Array<ModuleContent_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ModuleContent_Order_By>>;
  where?: Maybe<ModuleContent_Bool_Exp>;
};

/** columns and relationships of "contentEventAttachment" */
export type ContentEventAttachment = {
  __typename?: 'contentEventAttachment';
  contentEventId: Scalars['uuid'];
  createdAt: Scalars['timestamptz'];
  id: Scalars['uuid'];
  language: Scalars['String'];
  size?: Maybe<Scalars['Int']>;
  updatedAt: Scalars['timestamptz'];
  url: Scalars['String'];
};

/** aggregated selection of "contentEventAttachment" */
export type ContentEventAttachment_Aggregate = {
  __typename?: 'contentEventAttachment_aggregate';
  aggregate?: Maybe<ContentEventAttachment_Aggregate_Fields>;
  nodes: Array<ContentEventAttachment>;
};

/** aggregate fields of "contentEventAttachment" */
export type ContentEventAttachment_Aggregate_Fields = {
  __typename?: 'contentEventAttachment_aggregate_fields';
  avg?: Maybe<ContentEventAttachment_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<ContentEventAttachment_Max_Fields>;
  min?: Maybe<ContentEventAttachment_Min_Fields>;
  stddev?: Maybe<ContentEventAttachment_Stddev_Fields>;
  stddev_pop?: Maybe<ContentEventAttachment_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<ContentEventAttachment_Stddev_Samp_Fields>;
  sum?: Maybe<ContentEventAttachment_Sum_Fields>;
  var_pop?: Maybe<ContentEventAttachment_Var_Pop_Fields>;
  var_samp?: Maybe<ContentEventAttachment_Var_Samp_Fields>;
  variance?: Maybe<ContentEventAttachment_Variance_Fields>;
};


/** aggregate fields of "contentEventAttachment" */
export type ContentEventAttachment_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<ContentEventAttachment_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type ContentEventAttachment_Avg_Fields = {
  __typename?: 'contentEventAttachment_avg_fields';
  size?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "contentEventAttachment". All fields are combined with a logical 'AND'. */
export type ContentEventAttachment_Bool_Exp = {
  _and?: Maybe<Array<ContentEventAttachment_Bool_Exp>>;
  _not?: Maybe<ContentEventAttachment_Bool_Exp>;
  _or?: Maybe<Array<ContentEventAttachment_Bool_Exp>>;
  contentEventId?: Maybe<Uuid_Comparison_Exp>;
  createdAt?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  language?: Maybe<String_Comparison_Exp>;
  size?: Maybe<Int_Comparison_Exp>;
  updatedAt?: Maybe<Timestamptz_Comparison_Exp>;
  url?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "contentEventAttachment" */
export enum ContentEventAttachment_Constraint {
  /** unique or primary key constraint on columns "contentEventId", "language" */
  IdxContentEventAttachmentLanguageContentEventId = 'IDX_content_event_attachment_language_content_event_id',
  /** unique or primary key constraint on columns "id" */
  ContentEventAttachmentPkey = 'contentEventAttachment_pkey'
}

/** input type for incrementing numeric columns in table "contentEventAttachment" */
export type ContentEventAttachment_Inc_Input = {
  size?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "contentEventAttachment" */
export type ContentEventAttachment_Insert_Input = {
  contentEventId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  language?: Maybe<Scalars['String']>;
  size?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  url?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type ContentEventAttachment_Max_Fields = {
  __typename?: 'contentEventAttachment_max_fields';
  contentEventId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  language?: Maybe<Scalars['String']>;
  size?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  url?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type ContentEventAttachment_Min_Fields = {
  __typename?: 'contentEventAttachment_min_fields';
  contentEventId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  language?: Maybe<Scalars['String']>;
  size?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  url?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "contentEventAttachment" */
export type ContentEventAttachment_Mutation_Response = {
  __typename?: 'contentEventAttachment_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<ContentEventAttachment>;
};

/** on_conflict condition type for table "contentEventAttachment" */
export type ContentEventAttachment_On_Conflict = {
  constraint: ContentEventAttachment_Constraint;
  update_columns?: Array<ContentEventAttachment_Update_Column>;
  where?: Maybe<ContentEventAttachment_Bool_Exp>;
};

/** Ordering options when selecting data from "contentEventAttachment". */
export type ContentEventAttachment_Order_By = {
  contentEventId?: Maybe<Order_By>;
  createdAt?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  language?: Maybe<Order_By>;
  size?: Maybe<Order_By>;
  updatedAt?: Maybe<Order_By>;
  url?: Maybe<Order_By>;
};

/** primary key columns input for table: contentEventAttachment */
export type ContentEventAttachment_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "contentEventAttachment" */
export enum ContentEventAttachment_Select_Column {
  /** column name */
  ContentEventId = 'contentEventId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  Language = 'language',
  /** column name */
  Size = 'size',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  Url = 'url'
}

/** input type for updating data in table "contentEventAttachment" */
export type ContentEventAttachment_Set_Input = {
  contentEventId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  language?: Maybe<Scalars['String']>;
  size?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  url?: Maybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type ContentEventAttachment_Stddev_Fields = {
  __typename?: 'contentEventAttachment_stddev_fields';
  size?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type ContentEventAttachment_Stddev_Pop_Fields = {
  __typename?: 'contentEventAttachment_stddev_pop_fields';
  size?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type ContentEventAttachment_Stddev_Samp_Fields = {
  __typename?: 'contentEventAttachment_stddev_samp_fields';
  size?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "contentEventAttachment" */
export type ContentEventAttachment_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: ContentEventAttachment_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type ContentEventAttachment_Stream_Cursor_Value_Input = {
  contentEventId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  language?: Maybe<Scalars['String']>;
  size?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  url?: Maybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type ContentEventAttachment_Sum_Fields = {
  __typename?: 'contentEventAttachment_sum_fields';
  size?: Maybe<Scalars['Int']>;
};

/** update columns of table "contentEventAttachment" */
export enum ContentEventAttachment_Update_Column {
  /** column name */
  ContentEventId = 'contentEventId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  Language = 'language',
  /** column name */
  Size = 'size',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  Url = 'url'
}

export type ContentEventAttachment_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<ContentEventAttachment_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<ContentEventAttachment_Set_Input>;
  /** filter the rows which have to be updated */
  where: ContentEventAttachment_Bool_Exp;
};

/** aggregate var_pop on columns */
export type ContentEventAttachment_Var_Pop_Fields = {
  __typename?: 'contentEventAttachment_var_pop_fields';
  size?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type ContentEventAttachment_Var_Samp_Fields = {
  __typename?: 'contentEventAttachment_var_samp_fields';
  size?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type ContentEventAttachment_Variance_Fields = {
  __typename?: 'contentEventAttachment_variance_fields';
  size?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "contentEventSlot" */
export type ContentEventSlot = {
  __typename?: 'contentEventSlot';
  address?: Maybe<Scalars['String']>;
  /** An array relationship */
  animators: Array<ContentEventSlot_Animator>;
  /** An aggregate relationship */
  animators_aggregate: ContentEventSlot_Animator_Aggregate;
  /** An object relationship */
  contentEvent: ContentEvent;
  contentEventId: Scalars['uuid'];
  createdAt: Scalars['timestamptz'];
  /** An object relationship */
  description?: Maybe<I18n>;
  /** A computed field, executes function "contenteventslot_description_i18n" */
  descriptionI18n?: Maybe<Scalars['String']>;
  descriptionI18nId?: Maybe<Scalars['uuid']>;
  endDate: Scalars['timestamptz'];
  id: Scalars['uuid'];
  maxPlaces?: Maybe<Scalars['Int']>;
  /** A computed field, executes function "contenteventslot_participant_count" */
  participantCount?: Maybe<Scalars['Int']>;
  room?: Maybe<Scalars['String']>;
  startDate: Scalars['timestamptz'];
  type: Scalars['String'];
  updatedAt: Scalars['timestamptz'];
  /** An array relationship */
  userEventSlots: Array<UserEventSlot>;
  /** An aggregate relationship */
  userEventSlots_aggregate: UserEventSlot_Aggregate;
  validateDate?: Maybe<Scalars['timestamp']>;
  webexLink?: Maybe<Scalars['String']>;
};


/** columns and relationships of "contentEventSlot" */
export type ContentEventSlotAnimatorsArgs = {
  distinct_on?: Maybe<Array<ContentEventSlot_Animator_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ContentEventSlot_Animator_Order_By>>;
  where?: Maybe<ContentEventSlot_Animator_Bool_Exp>;
};


/** columns and relationships of "contentEventSlot" */
export type ContentEventSlotAnimators_AggregateArgs = {
  distinct_on?: Maybe<Array<ContentEventSlot_Animator_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ContentEventSlot_Animator_Order_By>>;
  where?: Maybe<ContentEventSlot_Animator_Bool_Exp>;
};


/** columns and relationships of "contentEventSlot" */
export type ContentEventSlotUserEventSlotsArgs = {
  distinct_on?: Maybe<Array<UserEventSlot_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<UserEventSlot_Order_By>>;
  where?: Maybe<UserEventSlot_Bool_Exp>;
};


/** columns and relationships of "contentEventSlot" */
export type ContentEventSlotUserEventSlots_AggregateArgs = {
  distinct_on?: Maybe<Array<UserEventSlot_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<UserEventSlot_Order_By>>;
  where?: Maybe<UserEventSlot_Bool_Exp>;
};

/** aggregated selection of "contentEventSlot" */
export type ContentEventSlot_Aggregate = {
  __typename?: 'contentEventSlot_aggregate';
  aggregate?: Maybe<ContentEventSlot_Aggregate_Fields>;
  nodes: Array<ContentEventSlot>;
};

export type ContentEventSlot_Aggregate_Bool_Exp = {
  count?: Maybe<ContentEventSlot_Aggregate_Bool_Exp_Count>;
};

export type ContentEventSlot_Aggregate_Bool_Exp_Count = {
  arguments?: Maybe<Array<ContentEventSlot_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<ContentEventSlot_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "contentEventSlot" */
export type ContentEventSlot_Aggregate_Fields = {
  __typename?: 'contentEventSlot_aggregate_fields';
  avg?: Maybe<ContentEventSlot_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<ContentEventSlot_Max_Fields>;
  min?: Maybe<ContentEventSlot_Min_Fields>;
  stddev?: Maybe<ContentEventSlot_Stddev_Fields>;
  stddev_pop?: Maybe<ContentEventSlot_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<ContentEventSlot_Stddev_Samp_Fields>;
  sum?: Maybe<ContentEventSlot_Sum_Fields>;
  var_pop?: Maybe<ContentEventSlot_Var_Pop_Fields>;
  var_samp?: Maybe<ContentEventSlot_Var_Samp_Fields>;
  variance?: Maybe<ContentEventSlot_Variance_Fields>;
};


/** aggregate fields of "contentEventSlot" */
export type ContentEventSlot_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<ContentEventSlot_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "contentEventSlot" */
export type ContentEventSlot_Aggregate_Order_By = {
  avg?: Maybe<ContentEventSlot_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<ContentEventSlot_Max_Order_By>;
  min?: Maybe<ContentEventSlot_Min_Order_By>;
  stddev?: Maybe<ContentEventSlot_Stddev_Order_By>;
  stddev_pop?: Maybe<ContentEventSlot_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<ContentEventSlot_Stddev_Samp_Order_By>;
  sum?: Maybe<ContentEventSlot_Sum_Order_By>;
  var_pop?: Maybe<ContentEventSlot_Var_Pop_Order_By>;
  var_samp?: Maybe<ContentEventSlot_Var_Samp_Order_By>;
  variance?: Maybe<ContentEventSlot_Variance_Order_By>;
};

/** columns and relationships of "contentEventSlot_animator" */
export type ContentEventSlot_Animator = {
  __typename?: 'contentEventSlot_animator';
  animatorId: Scalars['uuid'];
  contentEventSlotId: Scalars['uuid'];
  /** An object relationship */
  contentEventSlot_animator_user: User;
  id: Scalars['uuid'];
};

/** aggregated selection of "contentEventSlot_animator" */
export type ContentEventSlot_Animator_Aggregate = {
  __typename?: 'contentEventSlot_animator_aggregate';
  aggregate?: Maybe<ContentEventSlot_Animator_Aggregate_Fields>;
  nodes: Array<ContentEventSlot_Animator>;
};

export type ContentEventSlot_Animator_Aggregate_Bool_Exp = {
  count?: Maybe<ContentEventSlot_Animator_Aggregate_Bool_Exp_Count>;
};

export type ContentEventSlot_Animator_Aggregate_Bool_Exp_Count = {
  arguments?: Maybe<Array<ContentEventSlot_Animator_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<ContentEventSlot_Animator_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "contentEventSlot_animator" */
export type ContentEventSlot_Animator_Aggregate_Fields = {
  __typename?: 'contentEventSlot_animator_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<ContentEventSlot_Animator_Max_Fields>;
  min?: Maybe<ContentEventSlot_Animator_Min_Fields>;
};


/** aggregate fields of "contentEventSlot_animator" */
export type ContentEventSlot_Animator_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<ContentEventSlot_Animator_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "contentEventSlot_animator" */
export type ContentEventSlot_Animator_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<ContentEventSlot_Animator_Max_Order_By>;
  min?: Maybe<ContentEventSlot_Animator_Min_Order_By>;
};

/** input type for inserting array relation for remote table "contentEventSlot_animator" */
export type ContentEventSlot_Animator_Arr_Rel_Insert_Input = {
  data: Array<ContentEventSlot_Animator_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<ContentEventSlot_Animator_On_Conflict>;
};

/** Boolean expression to filter rows from the table "contentEventSlot_animator". All fields are combined with a logical 'AND'. */
export type ContentEventSlot_Animator_Bool_Exp = {
  _and?: Maybe<Array<ContentEventSlot_Animator_Bool_Exp>>;
  _not?: Maybe<ContentEventSlot_Animator_Bool_Exp>;
  _or?: Maybe<Array<ContentEventSlot_Animator_Bool_Exp>>;
  animatorId?: Maybe<Uuid_Comparison_Exp>;
  contentEventSlotId?: Maybe<Uuid_Comparison_Exp>;
  contentEventSlot_animator_user?: Maybe<User_Bool_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "contentEventSlot_animator" */
export enum ContentEventSlot_Animator_Constraint {
  /** unique or primary key constraint on columns "contentEventSlotId", "animatorId" */
  ContentEventSlotAnimatorContentEventSlotIdAnimatorId = 'contentEventSlot_animator_contentEventSlotId_animatorId',
  /** unique or primary key constraint on columns "id" */
  ContentEventSlotAnimatorPkey = 'contentEventSlot_animator_pkey'
}

/** input type for inserting data into table "contentEventSlot_animator" */
export type ContentEventSlot_Animator_Insert_Input = {
  animatorId?: Maybe<Scalars['uuid']>;
  contentEventSlotId?: Maybe<Scalars['uuid']>;
  contentEventSlot_animator_user?: Maybe<User_Obj_Rel_Insert_Input>;
  id?: Maybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type ContentEventSlot_Animator_Max_Fields = {
  __typename?: 'contentEventSlot_animator_max_fields';
  animatorId?: Maybe<Scalars['uuid']>;
  contentEventSlotId?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "contentEventSlot_animator" */
export type ContentEventSlot_Animator_Max_Order_By = {
  animatorId?: Maybe<Order_By>;
  contentEventSlotId?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type ContentEventSlot_Animator_Min_Fields = {
  __typename?: 'contentEventSlot_animator_min_fields';
  animatorId?: Maybe<Scalars['uuid']>;
  contentEventSlotId?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "contentEventSlot_animator" */
export type ContentEventSlot_Animator_Min_Order_By = {
  animatorId?: Maybe<Order_By>;
  contentEventSlotId?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** response of any mutation on the table "contentEventSlot_animator" */
export type ContentEventSlot_Animator_Mutation_Response = {
  __typename?: 'contentEventSlot_animator_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<ContentEventSlot_Animator>;
};

/** on_conflict condition type for table "contentEventSlot_animator" */
export type ContentEventSlot_Animator_On_Conflict = {
  constraint: ContentEventSlot_Animator_Constraint;
  update_columns?: Array<ContentEventSlot_Animator_Update_Column>;
  where?: Maybe<ContentEventSlot_Animator_Bool_Exp>;
};

/** Ordering options when selecting data from "contentEventSlot_animator". */
export type ContentEventSlot_Animator_Order_By = {
  animatorId?: Maybe<Order_By>;
  contentEventSlotId?: Maybe<Order_By>;
  contentEventSlot_animator_user?: Maybe<User_Order_By>;
  id?: Maybe<Order_By>;
};

/** primary key columns input for table: contentEventSlot_animator */
export type ContentEventSlot_Animator_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "contentEventSlot_animator" */
export enum ContentEventSlot_Animator_Select_Column {
  /** column name */
  AnimatorId = 'animatorId',
  /** column name */
  ContentEventSlotId = 'contentEventSlotId',
  /** column name */
  Id = 'id'
}

/** input type for updating data in table "contentEventSlot_animator" */
export type ContentEventSlot_Animator_Set_Input = {
  animatorId?: Maybe<Scalars['uuid']>;
  contentEventSlotId?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "contentEventSlot_animator" */
export type ContentEventSlot_Animator_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: ContentEventSlot_Animator_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type ContentEventSlot_Animator_Stream_Cursor_Value_Input = {
  animatorId?: Maybe<Scalars['uuid']>;
  contentEventSlotId?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
};

/** update columns of table "contentEventSlot_animator" */
export enum ContentEventSlot_Animator_Update_Column {
  /** column name */
  AnimatorId = 'animatorId',
  /** column name */
  ContentEventSlotId = 'contentEventSlotId',
  /** column name */
  Id = 'id'
}

export type ContentEventSlot_Animator_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<ContentEventSlot_Animator_Set_Input>;
  /** filter the rows which have to be updated */
  where: ContentEventSlot_Animator_Bool_Exp;
};

/** input type for inserting array relation for remote table "contentEventSlot" */
export type ContentEventSlot_Arr_Rel_Insert_Input = {
  data: Array<ContentEventSlot_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<ContentEventSlot_On_Conflict>;
};

/** aggregate avg on columns */
export type ContentEventSlot_Avg_Fields = {
  __typename?: 'contentEventSlot_avg_fields';
  maxPlaces?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "contentEventSlot" */
export type ContentEventSlot_Avg_Order_By = {
  maxPlaces?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "contentEventSlot". All fields are combined with a logical 'AND'. */
export type ContentEventSlot_Bool_Exp = {
  _and?: Maybe<Array<ContentEventSlot_Bool_Exp>>;
  _not?: Maybe<ContentEventSlot_Bool_Exp>;
  _or?: Maybe<Array<ContentEventSlot_Bool_Exp>>;
  address?: Maybe<String_Comparison_Exp>;
  animators?: Maybe<ContentEventSlot_Animator_Bool_Exp>;
  animators_aggregate?: Maybe<ContentEventSlot_Animator_Aggregate_Bool_Exp>;
  contentEvent?: Maybe<ContentEvent_Bool_Exp>;
  contentEventId?: Maybe<Uuid_Comparison_Exp>;
  createdAt?: Maybe<Timestamptz_Comparison_Exp>;
  description?: Maybe<I18n_Bool_Exp>;
  descriptionI18n?: Maybe<String_Comparison_Exp>;
  descriptionI18nId?: Maybe<Uuid_Comparison_Exp>;
  endDate?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  maxPlaces?: Maybe<Int_Comparison_Exp>;
  participantCount?: Maybe<Int_Comparison_Exp>;
  room?: Maybe<String_Comparison_Exp>;
  startDate?: Maybe<Timestamptz_Comparison_Exp>;
  type?: Maybe<String_Comparison_Exp>;
  updatedAt?: Maybe<Timestamptz_Comparison_Exp>;
  userEventSlots?: Maybe<UserEventSlot_Bool_Exp>;
  userEventSlots_aggregate?: Maybe<UserEventSlot_Aggregate_Bool_Exp>;
  validateDate?: Maybe<Timestamp_Comparison_Exp>;
  webexLink?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "contentEventSlot" */
export enum ContentEventSlot_Constraint {
  /** unique or primary key constraint on columns "id" */
  ContentEventSlotPkey = 'contentEventSlot_pkey'
}

/** input type for incrementing numeric columns in table "contentEventSlot" */
export type ContentEventSlot_Inc_Input = {
  maxPlaces?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "contentEventSlot" */
export type ContentEventSlot_Insert_Input = {
  address?: Maybe<Scalars['String']>;
  animators?: Maybe<ContentEventSlot_Animator_Arr_Rel_Insert_Input>;
  contentEvent?: Maybe<ContentEvent_Obj_Rel_Insert_Input>;
  contentEventId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<I18n_Obj_Rel_Insert_Input>;
  descriptionI18nId?: Maybe<Scalars['uuid']>;
  endDate?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  maxPlaces?: Maybe<Scalars['Int']>;
  room?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['timestamptz']>;
  type?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  userEventSlots?: Maybe<UserEventSlot_Arr_Rel_Insert_Input>;
  validateDate?: Maybe<Scalars['timestamp']>;
  webexLink?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type ContentEventSlot_Max_Fields = {
  __typename?: 'contentEventSlot_max_fields';
  address?: Maybe<Scalars['String']>;
  contentEventId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  descriptionI18nId?: Maybe<Scalars['uuid']>;
  endDate?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  maxPlaces?: Maybe<Scalars['Int']>;
  room?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['timestamptz']>;
  type?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  validateDate?: Maybe<Scalars['timestamp']>;
  webexLink?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "contentEventSlot" */
export type ContentEventSlot_Max_Order_By = {
  address?: Maybe<Order_By>;
  contentEventId?: Maybe<Order_By>;
  createdAt?: Maybe<Order_By>;
  descriptionI18nId?: Maybe<Order_By>;
  endDate?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  maxPlaces?: Maybe<Order_By>;
  room?: Maybe<Order_By>;
  startDate?: Maybe<Order_By>;
  type?: Maybe<Order_By>;
  updatedAt?: Maybe<Order_By>;
  validateDate?: Maybe<Order_By>;
  webexLink?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type ContentEventSlot_Min_Fields = {
  __typename?: 'contentEventSlot_min_fields';
  address?: Maybe<Scalars['String']>;
  contentEventId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  descriptionI18nId?: Maybe<Scalars['uuid']>;
  endDate?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  maxPlaces?: Maybe<Scalars['Int']>;
  room?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['timestamptz']>;
  type?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  validateDate?: Maybe<Scalars['timestamp']>;
  webexLink?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "contentEventSlot" */
export type ContentEventSlot_Min_Order_By = {
  address?: Maybe<Order_By>;
  contentEventId?: Maybe<Order_By>;
  createdAt?: Maybe<Order_By>;
  descriptionI18nId?: Maybe<Order_By>;
  endDate?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  maxPlaces?: Maybe<Order_By>;
  room?: Maybe<Order_By>;
  startDate?: Maybe<Order_By>;
  type?: Maybe<Order_By>;
  updatedAt?: Maybe<Order_By>;
  validateDate?: Maybe<Order_By>;
  webexLink?: Maybe<Order_By>;
};

/** response of any mutation on the table "contentEventSlot" */
export type ContentEventSlot_Mutation_Response = {
  __typename?: 'contentEventSlot_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<ContentEventSlot>;
};

/** input type for inserting object relation for remote table "contentEventSlot" */
export type ContentEventSlot_Obj_Rel_Insert_Input = {
  data: ContentEventSlot_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<ContentEventSlot_On_Conflict>;
};

/** on_conflict condition type for table "contentEventSlot" */
export type ContentEventSlot_On_Conflict = {
  constraint: ContentEventSlot_Constraint;
  update_columns?: Array<ContentEventSlot_Update_Column>;
  where?: Maybe<ContentEventSlot_Bool_Exp>;
};

/** Ordering options when selecting data from "contentEventSlot". */
export type ContentEventSlot_Order_By = {
  address?: Maybe<Order_By>;
  animators_aggregate?: Maybe<ContentEventSlot_Animator_Aggregate_Order_By>;
  contentEvent?: Maybe<ContentEvent_Order_By>;
  contentEventId?: Maybe<Order_By>;
  createdAt?: Maybe<Order_By>;
  description?: Maybe<I18n_Order_By>;
  descriptionI18n?: Maybe<Order_By>;
  descriptionI18nId?: Maybe<Order_By>;
  endDate?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  maxPlaces?: Maybe<Order_By>;
  participantCount?: Maybe<Order_By>;
  room?: Maybe<Order_By>;
  startDate?: Maybe<Order_By>;
  type?: Maybe<Order_By>;
  updatedAt?: Maybe<Order_By>;
  userEventSlots_aggregate?: Maybe<UserEventSlot_Aggregate_Order_By>;
  validateDate?: Maybe<Order_By>;
  webexLink?: Maybe<Order_By>;
};

/** primary key columns input for table: contentEventSlot */
export type ContentEventSlot_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "contentEventSlot" */
export enum ContentEventSlot_Select_Column {
  /** column name */
  Address = 'address',
  /** column name */
  ContentEventId = 'contentEventId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  DescriptionI18nId = 'descriptionI18nId',
  /** column name */
  EndDate = 'endDate',
  /** column name */
  Id = 'id',
  /** column name */
  MaxPlaces = 'maxPlaces',
  /** column name */
  Room = 'room',
  /** column name */
  StartDate = 'startDate',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  ValidateDate = 'validateDate',
  /** column name */
  WebexLink = 'webexLink'
}

/** input type for updating data in table "contentEventSlot" */
export type ContentEventSlot_Set_Input = {
  address?: Maybe<Scalars['String']>;
  contentEventId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  descriptionI18nId?: Maybe<Scalars['uuid']>;
  endDate?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  maxPlaces?: Maybe<Scalars['Int']>;
  room?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['timestamptz']>;
  type?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  validateDate?: Maybe<Scalars['timestamp']>;
  webexLink?: Maybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type ContentEventSlot_Stddev_Fields = {
  __typename?: 'contentEventSlot_stddev_fields';
  maxPlaces?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "contentEventSlot" */
export type ContentEventSlot_Stddev_Order_By = {
  maxPlaces?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type ContentEventSlot_Stddev_Pop_Fields = {
  __typename?: 'contentEventSlot_stddev_pop_fields';
  maxPlaces?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "contentEventSlot" */
export type ContentEventSlot_Stddev_Pop_Order_By = {
  maxPlaces?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type ContentEventSlot_Stddev_Samp_Fields = {
  __typename?: 'contentEventSlot_stddev_samp_fields';
  maxPlaces?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "contentEventSlot" */
export type ContentEventSlot_Stddev_Samp_Order_By = {
  maxPlaces?: Maybe<Order_By>;
};

/** Streaming cursor of the table "contentEventSlot" */
export type ContentEventSlot_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: ContentEventSlot_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type ContentEventSlot_Stream_Cursor_Value_Input = {
  address?: Maybe<Scalars['String']>;
  contentEventId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  descriptionI18nId?: Maybe<Scalars['uuid']>;
  endDate?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  maxPlaces?: Maybe<Scalars['Int']>;
  room?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['timestamptz']>;
  type?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  validateDate?: Maybe<Scalars['timestamp']>;
  webexLink?: Maybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type ContentEventSlot_Sum_Fields = {
  __typename?: 'contentEventSlot_sum_fields';
  maxPlaces?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "contentEventSlot" */
export type ContentEventSlot_Sum_Order_By = {
  maxPlaces?: Maybe<Order_By>;
};

/** update columns of table "contentEventSlot" */
export enum ContentEventSlot_Update_Column {
  /** column name */
  Address = 'address',
  /** column name */
  ContentEventId = 'contentEventId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  DescriptionI18nId = 'descriptionI18nId',
  /** column name */
  EndDate = 'endDate',
  /** column name */
  Id = 'id',
  /** column name */
  MaxPlaces = 'maxPlaces',
  /** column name */
  Room = 'room',
  /** column name */
  StartDate = 'startDate',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  ValidateDate = 'validateDate',
  /** column name */
  WebexLink = 'webexLink'
}

export type ContentEventSlot_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<ContentEventSlot_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<ContentEventSlot_Set_Input>;
  /** filter the rows which have to be updated */
  where: ContentEventSlot_Bool_Exp;
};

/** aggregate var_pop on columns */
export type ContentEventSlot_Var_Pop_Fields = {
  __typename?: 'contentEventSlot_var_pop_fields';
  maxPlaces?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "contentEventSlot" */
export type ContentEventSlot_Var_Pop_Order_By = {
  maxPlaces?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type ContentEventSlot_Var_Samp_Fields = {
  __typename?: 'contentEventSlot_var_samp_fields';
  maxPlaces?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "contentEventSlot" */
export type ContentEventSlot_Var_Samp_Order_By = {
  maxPlaces?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type ContentEventSlot_Variance_Fields = {
  __typename?: 'contentEventSlot_variance_fields';
  maxPlaces?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "contentEventSlot" */
export type ContentEventSlot_Variance_Order_By = {
  maxPlaces?: Maybe<Order_By>;
};

/** aggregated selection of "contentEvent" */
export type ContentEvent_Aggregate = {
  __typename?: 'contentEvent_aggregate';
  aggregate?: Maybe<ContentEvent_Aggregate_Fields>;
  nodes: Array<ContentEvent>;
};

/** aggregate fields of "contentEvent" */
export type ContentEvent_Aggregate_Fields = {
  __typename?: 'contentEvent_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<ContentEvent_Max_Fields>;
  min?: Maybe<ContentEvent_Min_Fields>;
};


/** aggregate fields of "contentEvent" */
export type ContentEvent_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<ContentEvent_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "contentEvent". All fields are combined with a logical 'AND'. */
export type ContentEvent_Bool_Exp = {
  _and?: Maybe<Array<ContentEvent_Bool_Exp>>;
  _not?: Maybe<ContentEvent_Bool_Exp>;
  _or?: Maybe<Array<ContentEvent_Bool_Exp>>;
  contentEventSlots?: Maybe<ContentEventSlot_Bool_Exp>;
  contentEventSlots_aggregate?: Maybe<ContentEventSlot_Aggregate_Bool_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  moduleContents?: Maybe<ModuleContent_Bool_Exp>;
  moduleContents_aggregate?: Maybe<ModuleContent_Aggregate_Bool_Exp>;
};

/** unique or primary key constraints on table "contentEvent" */
export enum ContentEvent_Constraint {
  /** unique or primary key constraint on columns "id" */
  ContentEventPkey = 'contentEvent_pkey'
}

/** input type for inserting data into table "contentEvent" */
export type ContentEvent_Insert_Input = {
  contentEventSlots?: Maybe<ContentEventSlot_Arr_Rel_Insert_Input>;
  id?: Maybe<Scalars['uuid']>;
  moduleContents?: Maybe<ModuleContent_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type ContentEvent_Max_Fields = {
  __typename?: 'contentEvent_max_fields';
  id?: Maybe<Scalars['uuid']>;
};

/** aggregate min on columns */
export type ContentEvent_Min_Fields = {
  __typename?: 'contentEvent_min_fields';
  id?: Maybe<Scalars['uuid']>;
};

/** response of any mutation on the table "contentEvent" */
export type ContentEvent_Mutation_Response = {
  __typename?: 'contentEvent_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<ContentEvent>;
};

/** input type for inserting object relation for remote table "contentEvent" */
export type ContentEvent_Obj_Rel_Insert_Input = {
  data: ContentEvent_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<ContentEvent_On_Conflict>;
};

/** on_conflict condition type for table "contentEvent" */
export type ContentEvent_On_Conflict = {
  constraint: ContentEvent_Constraint;
  update_columns?: Array<ContentEvent_Update_Column>;
  where?: Maybe<ContentEvent_Bool_Exp>;
};

/** Ordering options when selecting data from "contentEvent". */
export type ContentEvent_Order_By = {
  contentEventSlots_aggregate?: Maybe<ContentEventSlot_Aggregate_Order_By>;
  id?: Maybe<Order_By>;
  moduleContents_aggregate?: Maybe<ModuleContent_Aggregate_Order_By>;
};

/** primary key columns input for table: contentEvent */
export type ContentEvent_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "contentEvent" */
export enum ContentEvent_Select_Column {
  /** column name */
  Id = 'id'
}

/** input type for updating data in table "contentEvent" */
export type ContentEvent_Set_Input = {
  id?: Maybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "contentEvent" */
export type ContentEvent_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: ContentEvent_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type ContentEvent_Stream_Cursor_Value_Input = {
  id?: Maybe<Scalars['uuid']>;
};

/** update columns of table "contentEvent" */
export enum ContentEvent_Update_Column {
  /** column name */
  Id = 'id'
}

export type ContentEvent_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<ContentEvent_Set_Input>;
  /** filter the rows which have to be updated */
  where: ContentEvent_Bool_Exp;
};

/** columns and relationships of "contentPodcast" */
export type ContentPodcast = {
  __typename?: 'contentPodcast';
  /** An array relationship */
  contentPodcastUploads: Array<ContentPodcastUpload>;
  /** An aggregate relationship */
  contentPodcastUploads_aggregate: ContentPodcastUpload_Aggregate;
  createdAt: Scalars['timestamptz'];
  id: Scalars['uuid'];
  updatedAt: Scalars['timestamptz'];
};


/** columns and relationships of "contentPodcast" */
export type ContentPodcastContentPodcastUploadsArgs = {
  distinct_on?: Maybe<Array<ContentPodcastUpload_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ContentPodcastUpload_Order_By>>;
  where?: Maybe<ContentPodcastUpload_Bool_Exp>;
};


/** columns and relationships of "contentPodcast" */
export type ContentPodcastContentPodcastUploads_AggregateArgs = {
  distinct_on?: Maybe<Array<ContentPodcastUpload_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ContentPodcastUpload_Order_By>>;
  where?: Maybe<ContentPodcastUpload_Bool_Exp>;
};

/** columns and relationships of "contentPodcastUpload" */
export type ContentPodcastUpload = {
  __typename?: 'contentPodcastUpload';
  contentPodcastId: Scalars['uuid'];
  createdAt: Scalars['timestamptz'];
  id: Scalars['uuid'];
  language: Scalars['String'];
  size?: Maybe<Scalars['Int']>;
  transcriptSize?: Maybe<Scalars['Int']>;
  transcriptUrl?: Maybe<Scalars['String']>;
  updatedAt: Scalars['timestamptz'];
  url: Scalars['String'];
};

/** aggregated selection of "contentPodcastUpload" */
export type ContentPodcastUpload_Aggregate = {
  __typename?: 'contentPodcastUpload_aggregate';
  aggregate?: Maybe<ContentPodcastUpload_Aggregate_Fields>;
  nodes: Array<ContentPodcastUpload>;
};

export type ContentPodcastUpload_Aggregate_Bool_Exp = {
  count?: Maybe<ContentPodcastUpload_Aggregate_Bool_Exp_Count>;
};

export type ContentPodcastUpload_Aggregate_Bool_Exp_Count = {
  arguments?: Maybe<Array<ContentPodcastUpload_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<ContentPodcastUpload_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "contentPodcastUpload" */
export type ContentPodcastUpload_Aggregate_Fields = {
  __typename?: 'contentPodcastUpload_aggregate_fields';
  avg?: Maybe<ContentPodcastUpload_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<ContentPodcastUpload_Max_Fields>;
  min?: Maybe<ContentPodcastUpload_Min_Fields>;
  stddev?: Maybe<ContentPodcastUpload_Stddev_Fields>;
  stddev_pop?: Maybe<ContentPodcastUpload_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<ContentPodcastUpload_Stddev_Samp_Fields>;
  sum?: Maybe<ContentPodcastUpload_Sum_Fields>;
  var_pop?: Maybe<ContentPodcastUpload_Var_Pop_Fields>;
  var_samp?: Maybe<ContentPodcastUpload_Var_Samp_Fields>;
  variance?: Maybe<ContentPodcastUpload_Variance_Fields>;
};


/** aggregate fields of "contentPodcastUpload" */
export type ContentPodcastUpload_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<ContentPodcastUpload_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "contentPodcastUpload" */
export type ContentPodcastUpload_Aggregate_Order_By = {
  avg?: Maybe<ContentPodcastUpload_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<ContentPodcastUpload_Max_Order_By>;
  min?: Maybe<ContentPodcastUpload_Min_Order_By>;
  stddev?: Maybe<ContentPodcastUpload_Stddev_Order_By>;
  stddev_pop?: Maybe<ContentPodcastUpload_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<ContentPodcastUpload_Stddev_Samp_Order_By>;
  sum?: Maybe<ContentPodcastUpload_Sum_Order_By>;
  var_pop?: Maybe<ContentPodcastUpload_Var_Pop_Order_By>;
  var_samp?: Maybe<ContentPodcastUpload_Var_Samp_Order_By>;
  variance?: Maybe<ContentPodcastUpload_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "contentPodcastUpload" */
export type ContentPodcastUpload_Arr_Rel_Insert_Input = {
  data: Array<ContentPodcastUpload_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<ContentPodcastUpload_On_Conflict>;
};

/** aggregate avg on columns */
export type ContentPodcastUpload_Avg_Fields = {
  __typename?: 'contentPodcastUpload_avg_fields';
  size?: Maybe<Scalars['Float']>;
  transcriptSize?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "contentPodcastUpload" */
export type ContentPodcastUpload_Avg_Order_By = {
  size?: Maybe<Order_By>;
  transcriptSize?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "contentPodcastUpload". All fields are combined with a logical 'AND'. */
export type ContentPodcastUpload_Bool_Exp = {
  _and?: Maybe<Array<ContentPodcastUpload_Bool_Exp>>;
  _not?: Maybe<ContentPodcastUpload_Bool_Exp>;
  _or?: Maybe<Array<ContentPodcastUpload_Bool_Exp>>;
  contentPodcastId?: Maybe<Uuid_Comparison_Exp>;
  createdAt?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  language?: Maybe<String_Comparison_Exp>;
  size?: Maybe<Int_Comparison_Exp>;
  transcriptSize?: Maybe<Int_Comparison_Exp>;
  transcriptUrl?: Maybe<String_Comparison_Exp>;
  updatedAt?: Maybe<Timestamptz_Comparison_Exp>;
  url?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "contentPodcastUpload" */
export enum ContentPodcastUpload_Constraint {
  /** unique or primary key constraint on columns "contentPodcastId", "language" */
  IdxContentPodcastUploadLanguageContentPodcastId = 'IDX_content_podcast_upload_language_content_podcast_id',
  /** unique or primary key constraint on columns "id" */
  ContentPodcastUploadPkey = 'contentPodcastUpload_pkey'
}

/** input type for incrementing numeric columns in table "contentPodcastUpload" */
export type ContentPodcastUpload_Inc_Input = {
  size?: Maybe<Scalars['Int']>;
  transcriptSize?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "contentPodcastUpload" */
export type ContentPodcastUpload_Insert_Input = {
  contentPodcastId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  language?: Maybe<Scalars['String']>;
  size?: Maybe<Scalars['Int']>;
  transcriptSize?: Maybe<Scalars['Int']>;
  transcriptUrl?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  url?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type ContentPodcastUpload_Max_Fields = {
  __typename?: 'contentPodcastUpload_max_fields';
  contentPodcastId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  language?: Maybe<Scalars['String']>;
  size?: Maybe<Scalars['Int']>;
  transcriptSize?: Maybe<Scalars['Int']>;
  transcriptUrl?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  url?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "contentPodcastUpload" */
export type ContentPodcastUpload_Max_Order_By = {
  contentPodcastId?: Maybe<Order_By>;
  createdAt?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  language?: Maybe<Order_By>;
  size?: Maybe<Order_By>;
  transcriptSize?: Maybe<Order_By>;
  transcriptUrl?: Maybe<Order_By>;
  updatedAt?: Maybe<Order_By>;
  url?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type ContentPodcastUpload_Min_Fields = {
  __typename?: 'contentPodcastUpload_min_fields';
  contentPodcastId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  language?: Maybe<Scalars['String']>;
  size?: Maybe<Scalars['Int']>;
  transcriptSize?: Maybe<Scalars['Int']>;
  transcriptUrl?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  url?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "contentPodcastUpload" */
export type ContentPodcastUpload_Min_Order_By = {
  contentPodcastId?: Maybe<Order_By>;
  createdAt?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  language?: Maybe<Order_By>;
  size?: Maybe<Order_By>;
  transcriptSize?: Maybe<Order_By>;
  transcriptUrl?: Maybe<Order_By>;
  updatedAt?: Maybe<Order_By>;
  url?: Maybe<Order_By>;
};

/** response of any mutation on the table "contentPodcastUpload" */
export type ContentPodcastUpload_Mutation_Response = {
  __typename?: 'contentPodcastUpload_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<ContentPodcastUpload>;
};

/** on_conflict condition type for table "contentPodcastUpload" */
export type ContentPodcastUpload_On_Conflict = {
  constraint: ContentPodcastUpload_Constraint;
  update_columns?: Array<ContentPodcastUpload_Update_Column>;
  where?: Maybe<ContentPodcastUpload_Bool_Exp>;
};

/** Ordering options when selecting data from "contentPodcastUpload". */
export type ContentPodcastUpload_Order_By = {
  contentPodcastId?: Maybe<Order_By>;
  createdAt?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  language?: Maybe<Order_By>;
  size?: Maybe<Order_By>;
  transcriptSize?: Maybe<Order_By>;
  transcriptUrl?: Maybe<Order_By>;
  updatedAt?: Maybe<Order_By>;
  url?: Maybe<Order_By>;
};

/** primary key columns input for table: contentPodcastUpload */
export type ContentPodcastUpload_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "contentPodcastUpload" */
export enum ContentPodcastUpload_Select_Column {
  /** column name */
  ContentPodcastId = 'contentPodcastId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  Language = 'language',
  /** column name */
  Size = 'size',
  /** column name */
  TranscriptSize = 'transcriptSize',
  /** column name */
  TranscriptUrl = 'transcriptUrl',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  Url = 'url'
}

/** input type for updating data in table "contentPodcastUpload" */
export type ContentPodcastUpload_Set_Input = {
  contentPodcastId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  language?: Maybe<Scalars['String']>;
  size?: Maybe<Scalars['Int']>;
  transcriptSize?: Maybe<Scalars['Int']>;
  transcriptUrl?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  url?: Maybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type ContentPodcastUpload_Stddev_Fields = {
  __typename?: 'contentPodcastUpload_stddev_fields';
  size?: Maybe<Scalars['Float']>;
  transcriptSize?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "contentPodcastUpload" */
export type ContentPodcastUpload_Stddev_Order_By = {
  size?: Maybe<Order_By>;
  transcriptSize?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type ContentPodcastUpload_Stddev_Pop_Fields = {
  __typename?: 'contentPodcastUpload_stddev_pop_fields';
  size?: Maybe<Scalars['Float']>;
  transcriptSize?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "contentPodcastUpload" */
export type ContentPodcastUpload_Stddev_Pop_Order_By = {
  size?: Maybe<Order_By>;
  transcriptSize?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type ContentPodcastUpload_Stddev_Samp_Fields = {
  __typename?: 'contentPodcastUpload_stddev_samp_fields';
  size?: Maybe<Scalars['Float']>;
  transcriptSize?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "contentPodcastUpload" */
export type ContentPodcastUpload_Stddev_Samp_Order_By = {
  size?: Maybe<Order_By>;
  transcriptSize?: Maybe<Order_By>;
};

/** Streaming cursor of the table "contentPodcastUpload" */
export type ContentPodcastUpload_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: ContentPodcastUpload_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type ContentPodcastUpload_Stream_Cursor_Value_Input = {
  contentPodcastId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  language?: Maybe<Scalars['String']>;
  size?: Maybe<Scalars['Int']>;
  transcriptSize?: Maybe<Scalars['Int']>;
  transcriptUrl?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  url?: Maybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type ContentPodcastUpload_Sum_Fields = {
  __typename?: 'contentPodcastUpload_sum_fields';
  size?: Maybe<Scalars['Int']>;
  transcriptSize?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "contentPodcastUpload" */
export type ContentPodcastUpload_Sum_Order_By = {
  size?: Maybe<Order_By>;
  transcriptSize?: Maybe<Order_By>;
};

/** update columns of table "contentPodcastUpload" */
export enum ContentPodcastUpload_Update_Column {
  /** column name */
  ContentPodcastId = 'contentPodcastId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  Language = 'language',
  /** column name */
  Size = 'size',
  /** column name */
  TranscriptSize = 'transcriptSize',
  /** column name */
  TranscriptUrl = 'transcriptUrl',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  Url = 'url'
}

export type ContentPodcastUpload_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<ContentPodcastUpload_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<ContentPodcastUpload_Set_Input>;
  /** filter the rows which have to be updated */
  where: ContentPodcastUpload_Bool_Exp;
};

/** aggregate var_pop on columns */
export type ContentPodcastUpload_Var_Pop_Fields = {
  __typename?: 'contentPodcastUpload_var_pop_fields';
  size?: Maybe<Scalars['Float']>;
  transcriptSize?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "contentPodcastUpload" */
export type ContentPodcastUpload_Var_Pop_Order_By = {
  size?: Maybe<Order_By>;
  transcriptSize?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type ContentPodcastUpload_Var_Samp_Fields = {
  __typename?: 'contentPodcastUpload_var_samp_fields';
  size?: Maybe<Scalars['Float']>;
  transcriptSize?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "contentPodcastUpload" */
export type ContentPodcastUpload_Var_Samp_Order_By = {
  size?: Maybe<Order_By>;
  transcriptSize?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type ContentPodcastUpload_Variance_Fields = {
  __typename?: 'contentPodcastUpload_variance_fields';
  size?: Maybe<Scalars['Float']>;
  transcriptSize?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "contentPodcastUpload" */
export type ContentPodcastUpload_Variance_Order_By = {
  size?: Maybe<Order_By>;
  transcriptSize?: Maybe<Order_By>;
};

/** aggregated selection of "contentPodcast" */
export type ContentPodcast_Aggregate = {
  __typename?: 'contentPodcast_aggregate';
  aggregate?: Maybe<ContentPodcast_Aggregate_Fields>;
  nodes: Array<ContentPodcast>;
};

/** aggregate fields of "contentPodcast" */
export type ContentPodcast_Aggregate_Fields = {
  __typename?: 'contentPodcast_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<ContentPodcast_Max_Fields>;
  min?: Maybe<ContentPodcast_Min_Fields>;
};


/** aggregate fields of "contentPodcast" */
export type ContentPodcast_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<ContentPodcast_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "contentPodcast". All fields are combined with a logical 'AND'. */
export type ContentPodcast_Bool_Exp = {
  _and?: Maybe<Array<ContentPodcast_Bool_Exp>>;
  _not?: Maybe<ContentPodcast_Bool_Exp>;
  _or?: Maybe<Array<ContentPodcast_Bool_Exp>>;
  contentPodcastUploads?: Maybe<ContentPodcastUpload_Bool_Exp>;
  contentPodcastUploads_aggregate?: Maybe<ContentPodcastUpload_Aggregate_Bool_Exp>;
  createdAt?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  updatedAt?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "contentPodcast" */
export enum ContentPodcast_Constraint {
  /** unique or primary key constraint on columns "id" */
  ContentPodcastPkey = 'contentPodcast_pkey'
}

/** input type for inserting data into table "contentPodcast" */
export type ContentPodcast_Insert_Input = {
  contentPodcastUploads?: Maybe<ContentPodcastUpload_Arr_Rel_Insert_Input>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type ContentPodcast_Max_Fields = {
  __typename?: 'contentPodcast_max_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type ContentPodcast_Min_Fields = {
  __typename?: 'contentPodcast_min_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "contentPodcast" */
export type ContentPodcast_Mutation_Response = {
  __typename?: 'contentPodcast_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<ContentPodcast>;
};

/** input type for inserting object relation for remote table "contentPodcast" */
export type ContentPodcast_Obj_Rel_Insert_Input = {
  data: ContentPodcast_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<ContentPodcast_On_Conflict>;
};

/** on_conflict condition type for table "contentPodcast" */
export type ContentPodcast_On_Conflict = {
  constraint: ContentPodcast_Constraint;
  update_columns?: Array<ContentPodcast_Update_Column>;
  where?: Maybe<ContentPodcast_Bool_Exp>;
};

/** Ordering options when selecting data from "contentPodcast". */
export type ContentPodcast_Order_By = {
  contentPodcastUploads_aggregate?: Maybe<ContentPodcastUpload_Aggregate_Order_By>;
  createdAt?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  updatedAt?: Maybe<Order_By>;
};

/** primary key columns input for table: contentPodcast */
export type ContentPodcast_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "contentPodcast" */
export enum ContentPodcast_Select_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updatedAt'
}

/** input type for updating data in table "contentPodcast" */
export type ContentPodcast_Set_Input = {
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "contentPodcast" */
export type ContentPodcast_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: ContentPodcast_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type ContentPodcast_Stream_Cursor_Value_Input = {
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** update columns of table "contentPodcast" */
export enum ContentPodcast_Update_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updatedAt'
}

export type ContentPodcast_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<ContentPodcast_Set_Input>;
  /** filter the rows which have to be updated */
  where: ContentPodcast_Bool_Exp;
};

/** columns and relationships of "contentVideo" */
export type ContentVideo = {
  __typename?: 'contentVideo';
  /** An array relationship */
  contentAudioTranscripts: Array<ContentAudioTranscript>;
  /** An aggregate relationship */
  contentAudioTranscripts_aggregate: ContentAudioTranscript_Aggregate;
  /** An array relationship */
  contentVideoDetails: Array<ContentVideoDetails>;
  /** An aggregate relationship */
  contentVideoDetails_aggregate: ContentVideoDetails_Aggregate;
  createdAt: Scalars['timestamptz'];
  id: Scalars['uuid'];
  updatedAt: Scalars['timestamptz'];
};


/** columns and relationships of "contentVideo" */
export type ContentVideoContentAudioTranscriptsArgs = {
  distinct_on?: Maybe<Array<ContentAudioTranscript_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ContentAudioTranscript_Order_By>>;
  where?: Maybe<ContentAudioTranscript_Bool_Exp>;
};


/** columns and relationships of "contentVideo" */
export type ContentVideoContentAudioTranscripts_AggregateArgs = {
  distinct_on?: Maybe<Array<ContentAudioTranscript_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ContentAudioTranscript_Order_By>>;
  where?: Maybe<ContentAudioTranscript_Bool_Exp>;
};


/** columns and relationships of "contentVideo" */
export type ContentVideoContentVideoDetailsArgs = {
  distinct_on?: Maybe<Array<ContentVideoDetails_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ContentVideoDetails_Order_By>>;
  where?: Maybe<ContentVideoDetails_Bool_Exp>;
};


/** columns and relationships of "contentVideo" */
export type ContentVideoContentVideoDetails_AggregateArgs = {
  distinct_on?: Maybe<Array<ContentVideoDetails_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ContentVideoDetails_Order_By>>;
  where?: Maybe<ContentVideoDetails_Bool_Exp>;
};

/** columns and relationships of "contentVideoCaption" */
export type ContentVideoCaption = {
  __typename?: 'contentVideoCaption';
  /** An object relationship */
  contentVideoDetail: ContentVideoDetails;
  contentVideoDetailsId: Scalars['uuid'];
  createdAt: Scalars['timestamptz'];
  id: Scalars['uuid'];
  language: Scalars['String'];
  size?: Maybe<Scalars['Int']>;
  updatedAt: Scalars['timestamptz'];
  url: Scalars['String'];
};

/** aggregated selection of "contentVideoCaption" */
export type ContentVideoCaption_Aggregate = {
  __typename?: 'contentVideoCaption_aggregate';
  aggregate?: Maybe<ContentVideoCaption_Aggregate_Fields>;
  nodes: Array<ContentVideoCaption>;
};

export type ContentVideoCaption_Aggregate_Bool_Exp = {
  count?: Maybe<ContentVideoCaption_Aggregate_Bool_Exp_Count>;
};

export type ContentVideoCaption_Aggregate_Bool_Exp_Count = {
  arguments?: Maybe<Array<ContentVideoCaption_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<ContentVideoCaption_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "contentVideoCaption" */
export type ContentVideoCaption_Aggregate_Fields = {
  __typename?: 'contentVideoCaption_aggregate_fields';
  avg?: Maybe<ContentVideoCaption_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<ContentVideoCaption_Max_Fields>;
  min?: Maybe<ContentVideoCaption_Min_Fields>;
  stddev?: Maybe<ContentVideoCaption_Stddev_Fields>;
  stddev_pop?: Maybe<ContentVideoCaption_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<ContentVideoCaption_Stddev_Samp_Fields>;
  sum?: Maybe<ContentVideoCaption_Sum_Fields>;
  var_pop?: Maybe<ContentVideoCaption_Var_Pop_Fields>;
  var_samp?: Maybe<ContentVideoCaption_Var_Samp_Fields>;
  variance?: Maybe<ContentVideoCaption_Variance_Fields>;
};


/** aggregate fields of "contentVideoCaption" */
export type ContentVideoCaption_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<ContentVideoCaption_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "contentVideoCaption" */
export type ContentVideoCaption_Aggregate_Order_By = {
  avg?: Maybe<ContentVideoCaption_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<ContentVideoCaption_Max_Order_By>;
  min?: Maybe<ContentVideoCaption_Min_Order_By>;
  stddev?: Maybe<ContentVideoCaption_Stddev_Order_By>;
  stddev_pop?: Maybe<ContentVideoCaption_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<ContentVideoCaption_Stddev_Samp_Order_By>;
  sum?: Maybe<ContentVideoCaption_Sum_Order_By>;
  var_pop?: Maybe<ContentVideoCaption_Var_Pop_Order_By>;
  var_samp?: Maybe<ContentVideoCaption_Var_Samp_Order_By>;
  variance?: Maybe<ContentVideoCaption_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "contentVideoCaption" */
export type ContentVideoCaption_Arr_Rel_Insert_Input = {
  data: Array<ContentVideoCaption_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<ContentVideoCaption_On_Conflict>;
};

/** aggregate avg on columns */
export type ContentVideoCaption_Avg_Fields = {
  __typename?: 'contentVideoCaption_avg_fields';
  size?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "contentVideoCaption" */
export type ContentVideoCaption_Avg_Order_By = {
  size?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "contentVideoCaption". All fields are combined with a logical 'AND'. */
export type ContentVideoCaption_Bool_Exp = {
  _and?: Maybe<Array<ContentVideoCaption_Bool_Exp>>;
  _not?: Maybe<ContentVideoCaption_Bool_Exp>;
  _or?: Maybe<Array<ContentVideoCaption_Bool_Exp>>;
  contentVideoDetail?: Maybe<ContentVideoDetails_Bool_Exp>;
  contentVideoDetailsId?: Maybe<Uuid_Comparison_Exp>;
  createdAt?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  language?: Maybe<String_Comparison_Exp>;
  size?: Maybe<Int_Comparison_Exp>;
  updatedAt?: Maybe<Timestamptz_Comparison_Exp>;
  url?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "contentVideoCaption" */
export enum ContentVideoCaption_Constraint {
  /** unique or primary key constraint on columns "contentVideoDetailsId", "language" */
  IdxContentVideoCaptionLanguageContentVideoId = 'IDX_content_video_caption_language_content_video_id',
  /** unique or primary key constraint on columns "id" */
  ContentVideoCaptionPkey = 'contentVideoCaption_pkey'
}

/** input type for incrementing numeric columns in table "contentVideoCaption" */
export type ContentVideoCaption_Inc_Input = {
  size?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "contentVideoCaption" */
export type ContentVideoCaption_Insert_Input = {
  contentVideoDetail?: Maybe<ContentVideoDetails_Obj_Rel_Insert_Input>;
  contentVideoDetailsId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  language?: Maybe<Scalars['String']>;
  size?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  url?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type ContentVideoCaption_Max_Fields = {
  __typename?: 'contentVideoCaption_max_fields';
  contentVideoDetailsId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  language?: Maybe<Scalars['String']>;
  size?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  url?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "contentVideoCaption" */
export type ContentVideoCaption_Max_Order_By = {
  contentVideoDetailsId?: Maybe<Order_By>;
  createdAt?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  language?: Maybe<Order_By>;
  size?: Maybe<Order_By>;
  updatedAt?: Maybe<Order_By>;
  url?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type ContentVideoCaption_Min_Fields = {
  __typename?: 'contentVideoCaption_min_fields';
  contentVideoDetailsId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  language?: Maybe<Scalars['String']>;
  size?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  url?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "contentVideoCaption" */
export type ContentVideoCaption_Min_Order_By = {
  contentVideoDetailsId?: Maybe<Order_By>;
  createdAt?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  language?: Maybe<Order_By>;
  size?: Maybe<Order_By>;
  updatedAt?: Maybe<Order_By>;
  url?: Maybe<Order_By>;
};

/** response of any mutation on the table "contentVideoCaption" */
export type ContentVideoCaption_Mutation_Response = {
  __typename?: 'contentVideoCaption_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<ContentVideoCaption>;
};

/** on_conflict condition type for table "contentVideoCaption" */
export type ContentVideoCaption_On_Conflict = {
  constraint: ContentVideoCaption_Constraint;
  update_columns?: Array<ContentVideoCaption_Update_Column>;
  where?: Maybe<ContentVideoCaption_Bool_Exp>;
};

/** Ordering options when selecting data from "contentVideoCaption". */
export type ContentVideoCaption_Order_By = {
  contentVideoDetail?: Maybe<ContentVideoDetails_Order_By>;
  contentVideoDetailsId?: Maybe<Order_By>;
  createdAt?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  language?: Maybe<Order_By>;
  size?: Maybe<Order_By>;
  updatedAt?: Maybe<Order_By>;
  url?: Maybe<Order_By>;
};

/** primary key columns input for table: contentVideoCaption */
export type ContentVideoCaption_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "contentVideoCaption" */
export enum ContentVideoCaption_Select_Column {
  /** column name */
  ContentVideoDetailsId = 'contentVideoDetailsId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  Language = 'language',
  /** column name */
  Size = 'size',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  Url = 'url'
}

/** input type for updating data in table "contentVideoCaption" */
export type ContentVideoCaption_Set_Input = {
  contentVideoDetailsId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  language?: Maybe<Scalars['String']>;
  size?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  url?: Maybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type ContentVideoCaption_Stddev_Fields = {
  __typename?: 'contentVideoCaption_stddev_fields';
  size?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "contentVideoCaption" */
export type ContentVideoCaption_Stddev_Order_By = {
  size?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type ContentVideoCaption_Stddev_Pop_Fields = {
  __typename?: 'contentVideoCaption_stddev_pop_fields';
  size?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "contentVideoCaption" */
export type ContentVideoCaption_Stddev_Pop_Order_By = {
  size?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type ContentVideoCaption_Stddev_Samp_Fields = {
  __typename?: 'contentVideoCaption_stddev_samp_fields';
  size?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "contentVideoCaption" */
export type ContentVideoCaption_Stddev_Samp_Order_By = {
  size?: Maybe<Order_By>;
};

/** Streaming cursor of the table "contentVideoCaption" */
export type ContentVideoCaption_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: ContentVideoCaption_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type ContentVideoCaption_Stream_Cursor_Value_Input = {
  contentVideoDetailsId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  language?: Maybe<Scalars['String']>;
  size?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  url?: Maybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type ContentVideoCaption_Sum_Fields = {
  __typename?: 'contentVideoCaption_sum_fields';
  size?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "contentVideoCaption" */
export type ContentVideoCaption_Sum_Order_By = {
  size?: Maybe<Order_By>;
};

/** update columns of table "contentVideoCaption" */
export enum ContentVideoCaption_Update_Column {
  /** column name */
  ContentVideoDetailsId = 'contentVideoDetailsId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  Language = 'language',
  /** column name */
  Size = 'size',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  Url = 'url'
}

export type ContentVideoCaption_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<ContentVideoCaption_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<ContentVideoCaption_Set_Input>;
  /** filter the rows which have to be updated */
  where: ContentVideoCaption_Bool_Exp;
};

/** aggregate var_pop on columns */
export type ContentVideoCaption_Var_Pop_Fields = {
  __typename?: 'contentVideoCaption_var_pop_fields';
  size?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "contentVideoCaption" */
export type ContentVideoCaption_Var_Pop_Order_By = {
  size?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type ContentVideoCaption_Var_Samp_Fields = {
  __typename?: 'contentVideoCaption_var_samp_fields';
  size?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "contentVideoCaption" */
export type ContentVideoCaption_Var_Samp_Order_By = {
  size?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type ContentVideoCaption_Variance_Fields = {
  __typename?: 'contentVideoCaption_variance_fields';
  size?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "contentVideoCaption" */
export type ContentVideoCaption_Variance_Order_By = {
  size?: Maybe<Order_By>;
};

/** columns and relationships of "contentVideoDetails" */
export type ContentVideoDetails = {
  __typename?: 'contentVideoDetails';
  /** An object relationship */
  contentVideo?: Maybe<ContentVideo>;
  /** An array relationship */
  contentVideoCaptions: Array<ContentVideoCaption>;
  /** An aggregate relationship */
  contentVideoCaptions_aggregate: ContentVideoCaption_Aggregate;
  contentVideoId?: Maybe<Scalars['uuid']>;
  createdAt: Scalars['timestamptz'];
  id: Scalars['uuid'];
  language?: Maybe<Scalars['String']>;
  updatedAt: Scalars['timestamptz'];
  uploadUrl?: Maybe<Scalars['String']>;
  videoId?: Maybe<Scalars['String']>;
  videoName?: Maybe<Scalars['String']>;
};


/** columns and relationships of "contentVideoDetails" */
export type ContentVideoDetailsContentVideoCaptionsArgs = {
  distinct_on?: Maybe<Array<ContentVideoCaption_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ContentVideoCaption_Order_By>>;
  where?: Maybe<ContentVideoCaption_Bool_Exp>;
};


/** columns and relationships of "contentVideoDetails" */
export type ContentVideoDetailsContentVideoCaptions_AggregateArgs = {
  distinct_on?: Maybe<Array<ContentVideoCaption_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ContentVideoCaption_Order_By>>;
  where?: Maybe<ContentVideoCaption_Bool_Exp>;
};

/** aggregated selection of "contentVideoDetails" */
export type ContentVideoDetails_Aggregate = {
  __typename?: 'contentVideoDetails_aggregate';
  aggregate?: Maybe<ContentVideoDetails_Aggregate_Fields>;
  nodes: Array<ContentVideoDetails>;
};

export type ContentVideoDetails_Aggregate_Bool_Exp = {
  count?: Maybe<ContentVideoDetails_Aggregate_Bool_Exp_Count>;
};

export type ContentVideoDetails_Aggregate_Bool_Exp_Count = {
  arguments?: Maybe<Array<ContentVideoDetails_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<ContentVideoDetails_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "contentVideoDetails" */
export type ContentVideoDetails_Aggregate_Fields = {
  __typename?: 'contentVideoDetails_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<ContentVideoDetails_Max_Fields>;
  min?: Maybe<ContentVideoDetails_Min_Fields>;
};


/** aggregate fields of "contentVideoDetails" */
export type ContentVideoDetails_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<ContentVideoDetails_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "contentVideoDetails" */
export type ContentVideoDetails_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<ContentVideoDetails_Max_Order_By>;
  min?: Maybe<ContentVideoDetails_Min_Order_By>;
};

/** input type for inserting array relation for remote table "contentVideoDetails" */
export type ContentVideoDetails_Arr_Rel_Insert_Input = {
  data: Array<ContentVideoDetails_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<ContentVideoDetails_On_Conflict>;
};

/** Boolean expression to filter rows from the table "contentVideoDetails". All fields are combined with a logical 'AND'. */
export type ContentVideoDetails_Bool_Exp = {
  _and?: Maybe<Array<ContentVideoDetails_Bool_Exp>>;
  _not?: Maybe<ContentVideoDetails_Bool_Exp>;
  _or?: Maybe<Array<ContentVideoDetails_Bool_Exp>>;
  contentVideo?: Maybe<ContentVideo_Bool_Exp>;
  contentVideoCaptions?: Maybe<ContentVideoCaption_Bool_Exp>;
  contentVideoCaptions_aggregate?: Maybe<ContentVideoCaption_Aggregate_Bool_Exp>;
  contentVideoId?: Maybe<Uuid_Comparison_Exp>;
  createdAt?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  language?: Maybe<String_Comparison_Exp>;
  updatedAt?: Maybe<Timestamptz_Comparison_Exp>;
  uploadUrl?: Maybe<String_Comparison_Exp>;
  videoId?: Maybe<String_Comparison_Exp>;
  videoName?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "contentVideoDetails" */
export enum ContentVideoDetails_Constraint {
  /** unique or primary key constraint on columns "id" */
  ContentVideoDetailsPkey = 'contentVideoDetails_pkey'
}

/** input type for inserting data into table "contentVideoDetails" */
export type ContentVideoDetails_Insert_Input = {
  contentVideo?: Maybe<ContentVideo_Obj_Rel_Insert_Input>;
  contentVideoCaptions?: Maybe<ContentVideoCaption_Arr_Rel_Insert_Input>;
  contentVideoId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  language?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  uploadUrl?: Maybe<Scalars['String']>;
  videoId?: Maybe<Scalars['String']>;
  videoName?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type ContentVideoDetails_Max_Fields = {
  __typename?: 'contentVideoDetails_max_fields';
  contentVideoId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  language?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  uploadUrl?: Maybe<Scalars['String']>;
  videoId?: Maybe<Scalars['String']>;
  videoName?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "contentVideoDetails" */
export type ContentVideoDetails_Max_Order_By = {
  contentVideoId?: Maybe<Order_By>;
  createdAt?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  language?: Maybe<Order_By>;
  updatedAt?: Maybe<Order_By>;
  uploadUrl?: Maybe<Order_By>;
  videoId?: Maybe<Order_By>;
  videoName?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type ContentVideoDetails_Min_Fields = {
  __typename?: 'contentVideoDetails_min_fields';
  contentVideoId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  language?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  uploadUrl?: Maybe<Scalars['String']>;
  videoId?: Maybe<Scalars['String']>;
  videoName?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "contentVideoDetails" */
export type ContentVideoDetails_Min_Order_By = {
  contentVideoId?: Maybe<Order_By>;
  createdAt?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  language?: Maybe<Order_By>;
  updatedAt?: Maybe<Order_By>;
  uploadUrl?: Maybe<Order_By>;
  videoId?: Maybe<Order_By>;
  videoName?: Maybe<Order_By>;
};

/** response of any mutation on the table "contentVideoDetails" */
export type ContentVideoDetails_Mutation_Response = {
  __typename?: 'contentVideoDetails_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<ContentVideoDetails>;
};

/** input type for inserting object relation for remote table "contentVideoDetails" */
export type ContentVideoDetails_Obj_Rel_Insert_Input = {
  data: ContentVideoDetails_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<ContentVideoDetails_On_Conflict>;
};

/** on_conflict condition type for table "contentVideoDetails" */
export type ContentVideoDetails_On_Conflict = {
  constraint: ContentVideoDetails_Constraint;
  update_columns?: Array<ContentVideoDetails_Update_Column>;
  where?: Maybe<ContentVideoDetails_Bool_Exp>;
};

/** Ordering options when selecting data from "contentVideoDetails". */
export type ContentVideoDetails_Order_By = {
  contentVideo?: Maybe<ContentVideo_Order_By>;
  contentVideoCaptions_aggregate?: Maybe<ContentVideoCaption_Aggregate_Order_By>;
  contentVideoId?: Maybe<Order_By>;
  createdAt?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  language?: Maybe<Order_By>;
  updatedAt?: Maybe<Order_By>;
  uploadUrl?: Maybe<Order_By>;
  videoId?: Maybe<Order_By>;
  videoName?: Maybe<Order_By>;
};

/** primary key columns input for table: contentVideoDetails */
export type ContentVideoDetails_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "contentVideoDetails" */
export enum ContentVideoDetails_Select_Column {
  /** column name */
  ContentVideoId = 'contentVideoId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  Language = 'language',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  UploadUrl = 'uploadUrl',
  /** column name */
  VideoId = 'videoId',
  /** column name */
  VideoName = 'videoName'
}

/** input type for updating data in table "contentVideoDetails" */
export type ContentVideoDetails_Set_Input = {
  contentVideoId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  language?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  uploadUrl?: Maybe<Scalars['String']>;
  videoId?: Maybe<Scalars['String']>;
  videoName?: Maybe<Scalars['String']>;
};

/** Streaming cursor of the table "contentVideoDetails" */
export type ContentVideoDetails_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: ContentVideoDetails_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type ContentVideoDetails_Stream_Cursor_Value_Input = {
  contentVideoId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  language?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  uploadUrl?: Maybe<Scalars['String']>;
  videoId?: Maybe<Scalars['String']>;
  videoName?: Maybe<Scalars['String']>;
};

/** update columns of table "contentVideoDetails" */
export enum ContentVideoDetails_Update_Column {
  /** column name */
  ContentVideoId = 'contentVideoId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  Language = 'language',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  UploadUrl = 'uploadUrl',
  /** column name */
  VideoId = 'videoId',
  /** column name */
  VideoName = 'videoName'
}

export type ContentVideoDetails_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<ContentVideoDetails_Set_Input>;
  /** filter the rows which have to be updated */
  where: ContentVideoDetails_Bool_Exp;
};

/** aggregated selection of "contentVideo" */
export type ContentVideo_Aggregate = {
  __typename?: 'contentVideo_aggregate';
  aggregate?: Maybe<ContentVideo_Aggregate_Fields>;
  nodes: Array<ContentVideo>;
};

/** aggregate fields of "contentVideo" */
export type ContentVideo_Aggregate_Fields = {
  __typename?: 'contentVideo_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<ContentVideo_Max_Fields>;
  min?: Maybe<ContentVideo_Min_Fields>;
};


/** aggregate fields of "contentVideo" */
export type ContentVideo_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<ContentVideo_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "contentVideo". All fields are combined with a logical 'AND'. */
export type ContentVideo_Bool_Exp = {
  _and?: Maybe<Array<ContentVideo_Bool_Exp>>;
  _not?: Maybe<ContentVideo_Bool_Exp>;
  _or?: Maybe<Array<ContentVideo_Bool_Exp>>;
  contentAudioTranscripts?: Maybe<ContentAudioTranscript_Bool_Exp>;
  contentAudioTranscripts_aggregate?: Maybe<ContentAudioTranscript_Aggregate_Bool_Exp>;
  contentVideoDetails?: Maybe<ContentVideoDetails_Bool_Exp>;
  contentVideoDetails_aggregate?: Maybe<ContentVideoDetails_Aggregate_Bool_Exp>;
  createdAt?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  updatedAt?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "contentVideo" */
export enum ContentVideo_Constraint {
  /** unique or primary key constraint on columns "id" */
  ContentVideoPkey = 'contentVideo_pkey'
}

/** input type for inserting data into table "contentVideo" */
export type ContentVideo_Insert_Input = {
  contentAudioTranscripts?: Maybe<ContentAudioTranscript_Arr_Rel_Insert_Input>;
  contentVideoDetails?: Maybe<ContentVideoDetails_Arr_Rel_Insert_Input>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type ContentVideo_Max_Fields = {
  __typename?: 'contentVideo_max_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type ContentVideo_Min_Fields = {
  __typename?: 'contentVideo_min_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "contentVideo" */
export type ContentVideo_Mutation_Response = {
  __typename?: 'contentVideo_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<ContentVideo>;
};

/** input type for inserting object relation for remote table "contentVideo" */
export type ContentVideo_Obj_Rel_Insert_Input = {
  data: ContentVideo_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<ContentVideo_On_Conflict>;
};

/** on_conflict condition type for table "contentVideo" */
export type ContentVideo_On_Conflict = {
  constraint: ContentVideo_Constraint;
  update_columns?: Array<ContentVideo_Update_Column>;
  where?: Maybe<ContentVideo_Bool_Exp>;
};

/** Ordering options when selecting data from "contentVideo". */
export type ContentVideo_Order_By = {
  contentAudioTranscripts_aggregate?: Maybe<ContentAudioTranscript_Aggregate_Order_By>;
  contentVideoDetails_aggregate?: Maybe<ContentVideoDetails_Aggregate_Order_By>;
  createdAt?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  updatedAt?: Maybe<Order_By>;
};

/** primary key columns input for table: contentVideo */
export type ContentVideo_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "contentVideo" */
export enum ContentVideo_Select_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updatedAt'
}

/** input type for updating data in table "contentVideo" */
export type ContentVideo_Set_Input = {
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "contentVideo" */
export type ContentVideo_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: ContentVideo_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type ContentVideo_Stream_Cursor_Value_Input = {
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** update columns of table "contentVideo" */
export enum ContentVideo_Update_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updatedAt'
}

export type ContentVideo_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<ContentVideo_Set_Input>;
  /** filter the rows which have to be updated */
  where: ContentVideo_Bool_Exp;
};

/** columns and relationships of "course" */
export type Course = {
  __typename?: 'course';
  category: Scalars['String'];
  completionPercentage: Scalars['numeric'];
  /** An object relationship */
  courseAssign?: Maybe<CourseAssign>;
  createdAt: Scalars['timestamptz'];
  /** An object relationship */
  creator?: Maybe<User>;
  creatorId?: Maybe<Scalars['uuid']>;
  /** An object relationship */
  d_courseUser?: Maybe<D_CourseUser>;
  /** An object relationship */
  description?: Maybe<I18n>;
  /** A computed field, executes function "course_description_i18n" */
  descriptionI18n?: Maybe<Scalars['String']>;
  descriptionI18nId?: Maybe<Scalars['uuid']>;
  /** An object relationship */
  descriptionShort?: Maybe<I18n>;
  /** A computed field, executes function "course_description_short_i18n" */
  descriptionShortI18n?: Maybe<Scalars['String']>;
  descriptionShortI18nId?: Maybe<Scalars['uuid']>;
  /** A computed field, executes function "course_duration" */
  duration?: Maybe<Scalars['Int']>;
  frontPageOrder?: Maybe<Scalars['Int']>;
  humanId: Scalars['String'];
  id: Scalars['uuid'];
  imageHeadUri?: Maybe<Scalars['String']>;
  isEmployeesAssignment?: Maybe<Scalars['Boolean']>;
  isInFrontPage?: Maybe<Scalars['Boolean']>;
  isOtherAssignment?: Maybe<Scalars['Boolean']>;
  isVolunteerAssignment?: Maybe<Scalars['Boolean']>;
  /** An object relationship */
  lastUpdatedBy?: Maybe<User>;
  lastUpdatedById?: Maybe<Scalars['uuid']>;
  lastUpdatedManuallyAt?: Maybe<Scalars['timestamptz']>;
  progression?: Maybe<Scalars['Int']>;
  publicationDate?: Maybe<Scalars['timestamptz']>;
  publicationScope?: Maybe<Scalars['String']>;
  /** An array relationship */
  sequences: Array<Sequence>;
  /** An aggregate relationship */
  sequences_aggregate: Sequence_Aggregate;
  /** An array relationship */
  skill_of_courses: Array<Skill_Of_Course>;
  /** An aggregate relationship */
  skill_of_courses_aggregate: Skill_Of_Course_Aggregate;
  spendTime?: Maybe<Scalars['Int']>;
  status: Scalars['String'];
  /** An array relationship */
  tag_of_courses: Array<Tag_Of_Course>;
  /** An aggregate relationship */
  tag_of_courses_aggregate: Tag_Of_Course_Aggregate;
  /** An object relationship */
  thematic?: Maybe<Thematic>;
  thematicId?: Maybe<Scalars['uuid']>;
  /** An object relationship */
  title?: Maybe<I18n>;
  titleI18n?: Maybe<Scalars['String']>;
  titleI18nId?: Maybe<Scalars['uuid']>;
  /** A computed field, executes function "course_description_unaccent" */
  unaccentDescriptionI18n?: Maybe<Scalars['String']>;
  /** A computed field, executes function "course_title_unaccent" */
  unaccentTitleI18n?: Maybe<Scalars['String']>;
  updatedAt: Scalars['timestamptz'];
  /** An array relationship */
  userCourseProgresses: Array<UserCourseProgress>;
  /** An aggregate relationship */
  userCourseProgresses_aggregate: UserCourseProgress_Aggregate;
};


/** columns and relationships of "course" */
export type CourseSequencesArgs = {
  distinct_on?: Maybe<Array<Sequence_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Sequence_Order_By>>;
  where?: Maybe<Sequence_Bool_Exp>;
};


/** columns and relationships of "course" */
export type CourseSequences_AggregateArgs = {
  distinct_on?: Maybe<Array<Sequence_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Sequence_Order_By>>;
  where?: Maybe<Sequence_Bool_Exp>;
};


/** columns and relationships of "course" */
export type CourseSkill_Of_CoursesArgs = {
  distinct_on?: Maybe<Array<Skill_Of_Course_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Skill_Of_Course_Order_By>>;
  where?: Maybe<Skill_Of_Course_Bool_Exp>;
};


/** columns and relationships of "course" */
export type CourseSkill_Of_Courses_AggregateArgs = {
  distinct_on?: Maybe<Array<Skill_Of_Course_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Skill_Of_Course_Order_By>>;
  where?: Maybe<Skill_Of_Course_Bool_Exp>;
};


/** columns and relationships of "course" */
export type CourseTag_Of_CoursesArgs = {
  distinct_on?: Maybe<Array<Tag_Of_Course_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Tag_Of_Course_Order_By>>;
  where?: Maybe<Tag_Of_Course_Bool_Exp>;
};


/** columns and relationships of "course" */
export type CourseTag_Of_Courses_AggregateArgs = {
  distinct_on?: Maybe<Array<Tag_Of_Course_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Tag_Of_Course_Order_By>>;
  where?: Maybe<Tag_Of_Course_Bool_Exp>;
};


/** columns and relationships of "course" */
export type CourseUserCourseProgressesArgs = {
  distinct_on?: Maybe<Array<UserCourseProgress_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<UserCourseProgress_Order_By>>;
  where?: Maybe<UserCourseProgress_Bool_Exp>;
};


/** columns and relationships of "course" */
export type CourseUserCourseProgresses_AggregateArgs = {
  distinct_on?: Maybe<Array<UserCourseProgress_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<UserCourseProgress_Order_By>>;
  where?: Maybe<UserCourseProgress_Bool_Exp>;
};

/** columns and relationships of "courseAssign" */
export type CourseAssign = {
  __typename?: 'courseAssign';
  /** An object relationship */
  course: Course;
  /** An object relationship */
  courseAssignEmployee?: Maybe<CourseAssignEmployee>;
  /** An array relationship */
  courseAssignGroups: Array<CourseAssignGroup>;
  /** An aggregate relationship */
  courseAssignGroups_aggregate: CourseAssignGroup_Aggregate;
  /** An object relationship */
  courseAssignOthers?: Maybe<CourseAssignOther>;
  /** An array relationship */
  courseAssignUsers: Array<CourseAssignUser>;
  /** An aggregate relationship */
  courseAssignUsers_aggregate: CourseAssignUser_Aggregate;
  /** An object relationship */
  courseAssignVolunteer?: Maybe<CourseAssignVolunteer>;
  courseId: Scalars['uuid'];
  createdAt: Scalars['timestamptz'];
  d_forEmployee: Scalars['Boolean'];
  d_forOther: Scalars['Boolean'];
  d_forVolunteer: Scalars['Boolean'];
  forAll: Scalars['Boolean'];
  hasAutoAssign: Scalars['Boolean'];
  id: Scalars['uuid'];
  publishAt?: Maybe<Scalars['timestamptz']>;
  /** A computed field, executes function "type_match" */
  type_match?: Maybe<Scalars['Boolean']>;
  updatedAt: Scalars['timestamptz'];
};


/** columns and relationships of "courseAssign" */
export type CourseAssignCourseAssignGroupsArgs = {
  distinct_on?: Maybe<Array<CourseAssignGroup_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<CourseAssignGroup_Order_By>>;
  where?: Maybe<CourseAssignGroup_Bool_Exp>;
};


/** columns and relationships of "courseAssign" */
export type CourseAssignCourseAssignGroups_AggregateArgs = {
  distinct_on?: Maybe<Array<CourseAssignGroup_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<CourseAssignGroup_Order_By>>;
  where?: Maybe<CourseAssignGroup_Bool_Exp>;
};


/** columns and relationships of "courseAssign" */
export type CourseAssignCourseAssignUsersArgs = {
  distinct_on?: Maybe<Array<CourseAssignUser_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<CourseAssignUser_Order_By>>;
  where?: Maybe<CourseAssignUser_Bool_Exp>;
};


/** columns and relationships of "courseAssign" */
export type CourseAssignCourseAssignUsers_AggregateArgs = {
  distinct_on?: Maybe<Array<CourseAssignUser_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<CourseAssignUser_Order_By>>;
  where?: Maybe<CourseAssignUser_Bool_Exp>;
};

/** columns and relationships of "courseAssignEmployee" */
export type CourseAssignEmployee = {
  __typename?: 'courseAssignEmployee';
  /** An array relationship */
  courseAssignEmployeeDirections: Array<CourseAssignEmployeeDirection>;
  /** An aggregate relationship */
  courseAssignEmployeeDirections_aggregate: CourseAssignEmployeeDirection_Aggregate;
  courseAssignId: Scalars['uuid'];
  createdAt: Scalars['timestamptz'];
  forManager?: Maybe<Scalars['Boolean']>;
  forStudent?: Maybe<Scalars['Boolean']>;
  id: Scalars['uuid'];
  updatedAt: Scalars['timestamptz'];
};


/** columns and relationships of "courseAssignEmployee" */
export type CourseAssignEmployeeCourseAssignEmployeeDirectionsArgs = {
  distinct_on?: Maybe<Array<CourseAssignEmployeeDirection_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<CourseAssignEmployeeDirection_Order_By>>;
  where?: Maybe<CourseAssignEmployeeDirection_Bool_Exp>;
};


/** columns and relationships of "courseAssignEmployee" */
export type CourseAssignEmployeeCourseAssignEmployeeDirections_AggregateArgs = {
  distinct_on?: Maybe<Array<CourseAssignEmployeeDirection_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<CourseAssignEmployeeDirection_Order_By>>;
  where?: Maybe<CourseAssignEmployeeDirection_Bool_Exp>;
};

/** columns and relationships of "courseAssignEmployeeDirection" */
export type CourseAssignEmployeeDirection = {
  __typename?: 'courseAssignEmployeeDirection';
  /** An array relationship */
  courseAssignEmployeeFas: Array<CourseAssignEmployeeFa>;
  /** An aggregate relationship */
  courseAssignEmployeeFas_aggregate: CourseAssignEmployeeFa_Aggregate;
  courseAssignEmployeeId: Scalars['uuid'];
  createdAt: Scalars['timestamptz'];
  directionId: Scalars['uuid'];
  id: Scalars['uuid'];
  updatedAt: Scalars['timestamptz'];
};


/** columns and relationships of "courseAssignEmployeeDirection" */
export type CourseAssignEmployeeDirectionCourseAssignEmployeeFasArgs = {
  distinct_on?: Maybe<Array<CourseAssignEmployeeFa_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<CourseAssignEmployeeFa_Order_By>>;
  where?: Maybe<CourseAssignEmployeeFa_Bool_Exp>;
};


/** columns and relationships of "courseAssignEmployeeDirection" */
export type CourseAssignEmployeeDirectionCourseAssignEmployeeFas_AggregateArgs = {
  distinct_on?: Maybe<Array<CourseAssignEmployeeFa_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<CourseAssignEmployeeFa_Order_By>>;
  where?: Maybe<CourseAssignEmployeeFa_Bool_Exp>;
};

/** aggregated selection of "courseAssignEmployeeDirection" */
export type CourseAssignEmployeeDirection_Aggregate = {
  __typename?: 'courseAssignEmployeeDirection_aggregate';
  aggregate?: Maybe<CourseAssignEmployeeDirection_Aggregate_Fields>;
  nodes: Array<CourseAssignEmployeeDirection>;
};

export type CourseAssignEmployeeDirection_Aggregate_Bool_Exp = {
  count?: Maybe<CourseAssignEmployeeDirection_Aggregate_Bool_Exp_Count>;
};

export type CourseAssignEmployeeDirection_Aggregate_Bool_Exp_Count = {
  arguments?: Maybe<Array<CourseAssignEmployeeDirection_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<CourseAssignEmployeeDirection_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "courseAssignEmployeeDirection" */
export type CourseAssignEmployeeDirection_Aggregate_Fields = {
  __typename?: 'courseAssignEmployeeDirection_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<CourseAssignEmployeeDirection_Max_Fields>;
  min?: Maybe<CourseAssignEmployeeDirection_Min_Fields>;
};


/** aggregate fields of "courseAssignEmployeeDirection" */
export type CourseAssignEmployeeDirection_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<CourseAssignEmployeeDirection_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "courseAssignEmployeeDirection" */
export type CourseAssignEmployeeDirection_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<CourseAssignEmployeeDirection_Max_Order_By>;
  min?: Maybe<CourseAssignEmployeeDirection_Min_Order_By>;
};

/** input type for inserting array relation for remote table "courseAssignEmployeeDirection" */
export type CourseAssignEmployeeDirection_Arr_Rel_Insert_Input = {
  data: Array<CourseAssignEmployeeDirection_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<CourseAssignEmployeeDirection_On_Conflict>;
};

/** Boolean expression to filter rows from the table "courseAssignEmployeeDirection". All fields are combined with a logical 'AND'. */
export type CourseAssignEmployeeDirection_Bool_Exp = {
  _and?: Maybe<Array<CourseAssignEmployeeDirection_Bool_Exp>>;
  _not?: Maybe<CourseAssignEmployeeDirection_Bool_Exp>;
  _or?: Maybe<Array<CourseAssignEmployeeDirection_Bool_Exp>>;
  courseAssignEmployeeFas?: Maybe<CourseAssignEmployeeFa_Bool_Exp>;
  courseAssignEmployeeFas_aggregate?: Maybe<CourseAssignEmployeeFa_Aggregate_Bool_Exp>;
  courseAssignEmployeeId?: Maybe<Uuid_Comparison_Exp>;
  createdAt?: Maybe<Timestamptz_Comparison_Exp>;
  directionId?: Maybe<Uuid_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  updatedAt?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "courseAssignEmployeeDirection" */
export enum CourseAssignEmployeeDirection_Constraint {
  /** unique or primary key constraint on columns "id" */
  CourseAssignEmployeeDirectionPkey = 'courseAssignEmployeeDirection_pkey',
  /** unique or primary key constraint on columns "directionId", "courseAssignEmployeeId" */
  CourseAssignEmployeeFaCourseAssignEmployeeIdDirectionIdKey = 'courseAssignEmployeeFa_courseAssignEmployeeId_directionId_key'
}

/** input type for inserting data into table "courseAssignEmployeeDirection" */
export type CourseAssignEmployeeDirection_Insert_Input = {
  courseAssignEmployeeFas?: Maybe<CourseAssignEmployeeFa_Arr_Rel_Insert_Input>;
  courseAssignEmployeeId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  directionId?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type CourseAssignEmployeeDirection_Max_Fields = {
  __typename?: 'courseAssignEmployeeDirection_max_fields';
  courseAssignEmployeeId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  directionId?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "courseAssignEmployeeDirection" */
export type CourseAssignEmployeeDirection_Max_Order_By = {
  courseAssignEmployeeId?: Maybe<Order_By>;
  createdAt?: Maybe<Order_By>;
  directionId?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  updatedAt?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type CourseAssignEmployeeDirection_Min_Fields = {
  __typename?: 'courseAssignEmployeeDirection_min_fields';
  courseAssignEmployeeId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  directionId?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "courseAssignEmployeeDirection" */
export type CourseAssignEmployeeDirection_Min_Order_By = {
  courseAssignEmployeeId?: Maybe<Order_By>;
  createdAt?: Maybe<Order_By>;
  directionId?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  updatedAt?: Maybe<Order_By>;
};

/** response of any mutation on the table "courseAssignEmployeeDirection" */
export type CourseAssignEmployeeDirection_Mutation_Response = {
  __typename?: 'courseAssignEmployeeDirection_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<CourseAssignEmployeeDirection>;
};

/** input type for inserting object relation for remote table "courseAssignEmployeeDirection" */
export type CourseAssignEmployeeDirection_Obj_Rel_Insert_Input = {
  data: CourseAssignEmployeeDirection_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<CourseAssignEmployeeDirection_On_Conflict>;
};

/** on_conflict condition type for table "courseAssignEmployeeDirection" */
export type CourseAssignEmployeeDirection_On_Conflict = {
  constraint: CourseAssignEmployeeDirection_Constraint;
  update_columns?: Array<CourseAssignEmployeeDirection_Update_Column>;
  where?: Maybe<CourseAssignEmployeeDirection_Bool_Exp>;
};

/** Ordering options when selecting data from "courseAssignEmployeeDirection". */
export type CourseAssignEmployeeDirection_Order_By = {
  courseAssignEmployeeFas_aggregate?: Maybe<CourseAssignEmployeeFa_Aggregate_Order_By>;
  courseAssignEmployeeId?: Maybe<Order_By>;
  createdAt?: Maybe<Order_By>;
  directionId?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  updatedAt?: Maybe<Order_By>;
};

/** primary key columns input for table: courseAssignEmployeeDirection */
export type CourseAssignEmployeeDirection_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "courseAssignEmployeeDirection" */
export enum CourseAssignEmployeeDirection_Select_Column {
  /** column name */
  CourseAssignEmployeeId = 'courseAssignEmployeeId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  DirectionId = 'directionId',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updatedAt'
}

/** input type for updating data in table "courseAssignEmployeeDirection" */
export type CourseAssignEmployeeDirection_Set_Input = {
  courseAssignEmployeeId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  directionId?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "courseAssignEmployeeDirection" */
export type CourseAssignEmployeeDirection_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: CourseAssignEmployeeDirection_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type CourseAssignEmployeeDirection_Stream_Cursor_Value_Input = {
  courseAssignEmployeeId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  directionId?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** update columns of table "courseAssignEmployeeDirection" */
export enum CourseAssignEmployeeDirection_Update_Column {
  /** column name */
  CourseAssignEmployeeId = 'courseAssignEmployeeId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  DirectionId = 'directionId',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updatedAt'
}

export type CourseAssignEmployeeDirection_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<CourseAssignEmployeeDirection_Set_Input>;
  /** filter the rows which have to be updated */
  where: CourseAssignEmployeeDirection_Bool_Exp;
};

/** columns and relationships of "courseAssignEmployeeFa" */
export type CourseAssignEmployeeFa = {
  __typename?: 'courseAssignEmployeeFa';
  /** An object relationship */
  courseAssignEmployeeDirection: CourseAssignEmployeeDirection;
  courseAssignEmployeeDirectionId: Scalars['uuid'];
  createdAt: Scalars['timestamptz'];
  faId: Scalars['uuid'];
  id: Scalars['uuid'];
  updatedAt: Scalars['timestamptz'];
};

/** aggregated selection of "courseAssignEmployeeFa" */
export type CourseAssignEmployeeFa_Aggregate = {
  __typename?: 'courseAssignEmployeeFa_aggregate';
  aggregate?: Maybe<CourseAssignEmployeeFa_Aggregate_Fields>;
  nodes: Array<CourseAssignEmployeeFa>;
};

export type CourseAssignEmployeeFa_Aggregate_Bool_Exp = {
  count?: Maybe<CourseAssignEmployeeFa_Aggregate_Bool_Exp_Count>;
};

export type CourseAssignEmployeeFa_Aggregate_Bool_Exp_Count = {
  arguments?: Maybe<Array<CourseAssignEmployeeFa_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<CourseAssignEmployeeFa_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "courseAssignEmployeeFa" */
export type CourseAssignEmployeeFa_Aggregate_Fields = {
  __typename?: 'courseAssignEmployeeFa_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<CourseAssignEmployeeFa_Max_Fields>;
  min?: Maybe<CourseAssignEmployeeFa_Min_Fields>;
};


/** aggregate fields of "courseAssignEmployeeFa" */
export type CourseAssignEmployeeFa_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<CourseAssignEmployeeFa_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "courseAssignEmployeeFa" */
export type CourseAssignEmployeeFa_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<CourseAssignEmployeeFa_Max_Order_By>;
  min?: Maybe<CourseAssignEmployeeFa_Min_Order_By>;
};

/** input type for inserting array relation for remote table "courseAssignEmployeeFa" */
export type CourseAssignEmployeeFa_Arr_Rel_Insert_Input = {
  data: Array<CourseAssignEmployeeFa_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<CourseAssignEmployeeFa_On_Conflict>;
};

/** Boolean expression to filter rows from the table "courseAssignEmployeeFa". All fields are combined with a logical 'AND'. */
export type CourseAssignEmployeeFa_Bool_Exp = {
  _and?: Maybe<Array<CourseAssignEmployeeFa_Bool_Exp>>;
  _not?: Maybe<CourseAssignEmployeeFa_Bool_Exp>;
  _or?: Maybe<Array<CourseAssignEmployeeFa_Bool_Exp>>;
  courseAssignEmployeeDirection?: Maybe<CourseAssignEmployeeDirection_Bool_Exp>;
  courseAssignEmployeeDirectionId?: Maybe<Uuid_Comparison_Exp>;
  createdAt?: Maybe<Timestamptz_Comparison_Exp>;
  faId?: Maybe<Uuid_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  updatedAt?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "courseAssignEmployeeFa" */
export enum CourseAssignEmployeeFa_Constraint {
  /** unique or primary key constraint on columns "faId", "courseAssignEmployeeDirectionId" */
  CourseAssignEmployeeFaCourseAssignEmployeeIdFaIdKey = 'courseAssignEmployeeFa_courseAssignEmployeeId_faId_key',
  /** unique or primary key constraint on columns "id" */
  CourseAssignEmployeeFaPkey = 'courseAssignEmployeeFa_pkey'
}

/** input type for inserting data into table "courseAssignEmployeeFa" */
export type CourseAssignEmployeeFa_Insert_Input = {
  courseAssignEmployeeDirection?: Maybe<CourseAssignEmployeeDirection_Obj_Rel_Insert_Input>;
  courseAssignEmployeeDirectionId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  faId?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type CourseAssignEmployeeFa_Max_Fields = {
  __typename?: 'courseAssignEmployeeFa_max_fields';
  courseAssignEmployeeDirectionId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  faId?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "courseAssignEmployeeFa" */
export type CourseAssignEmployeeFa_Max_Order_By = {
  courseAssignEmployeeDirectionId?: Maybe<Order_By>;
  createdAt?: Maybe<Order_By>;
  faId?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  updatedAt?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type CourseAssignEmployeeFa_Min_Fields = {
  __typename?: 'courseAssignEmployeeFa_min_fields';
  courseAssignEmployeeDirectionId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  faId?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "courseAssignEmployeeFa" */
export type CourseAssignEmployeeFa_Min_Order_By = {
  courseAssignEmployeeDirectionId?: Maybe<Order_By>;
  createdAt?: Maybe<Order_By>;
  faId?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  updatedAt?: Maybe<Order_By>;
};

/** response of any mutation on the table "courseAssignEmployeeFa" */
export type CourseAssignEmployeeFa_Mutation_Response = {
  __typename?: 'courseAssignEmployeeFa_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<CourseAssignEmployeeFa>;
};

/** on_conflict condition type for table "courseAssignEmployeeFa" */
export type CourseAssignEmployeeFa_On_Conflict = {
  constraint: CourseAssignEmployeeFa_Constraint;
  update_columns?: Array<CourseAssignEmployeeFa_Update_Column>;
  where?: Maybe<CourseAssignEmployeeFa_Bool_Exp>;
};

/** Ordering options when selecting data from "courseAssignEmployeeFa". */
export type CourseAssignEmployeeFa_Order_By = {
  courseAssignEmployeeDirection?: Maybe<CourseAssignEmployeeDirection_Order_By>;
  courseAssignEmployeeDirectionId?: Maybe<Order_By>;
  createdAt?: Maybe<Order_By>;
  faId?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  updatedAt?: Maybe<Order_By>;
};

/** primary key columns input for table: courseAssignEmployeeFa */
export type CourseAssignEmployeeFa_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "courseAssignEmployeeFa" */
export enum CourseAssignEmployeeFa_Select_Column {
  /** column name */
  CourseAssignEmployeeDirectionId = 'courseAssignEmployeeDirectionId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  FaId = 'faId',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updatedAt'
}

/** input type for updating data in table "courseAssignEmployeeFa" */
export type CourseAssignEmployeeFa_Set_Input = {
  courseAssignEmployeeDirectionId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  faId?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "courseAssignEmployeeFa" */
export type CourseAssignEmployeeFa_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: CourseAssignEmployeeFa_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type CourseAssignEmployeeFa_Stream_Cursor_Value_Input = {
  courseAssignEmployeeDirectionId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  faId?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** update columns of table "courseAssignEmployeeFa" */
export enum CourseAssignEmployeeFa_Update_Column {
  /** column name */
  CourseAssignEmployeeDirectionId = 'courseAssignEmployeeDirectionId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  FaId = 'faId',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updatedAt'
}

export type CourseAssignEmployeeFa_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<CourseAssignEmployeeFa_Set_Input>;
  /** filter the rows which have to be updated */
  where: CourseAssignEmployeeFa_Bool_Exp;
};

/** aggregated selection of "courseAssignEmployee" */
export type CourseAssignEmployee_Aggregate = {
  __typename?: 'courseAssignEmployee_aggregate';
  aggregate?: Maybe<CourseAssignEmployee_Aggregate_Fields>;
  nodes: Array<CourseAssignEmployee>;
};

/** aggregate fields of "courseAssignEmployee" */
export type CourseAssignEmployee_Aggregate_Fields = {
  __typename?: 'courseAssignEmployee_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<CourseAssignEmployee_Max_Fields>;
  min?: Maybe<CourseAssignEmployee_Min_Fields>;
};


/** aggregate fields of "courseAssignEmployee" */
export type CourseAssignEmployee_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<CourseAssignEmployee_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "courseAssignEmployee". All fields are combined with a logical 'AND'. */
export type CourseAssignEmployee_Bool_Exp = {
  _and?: Maybe<Array<CourseAssignEmployee_Bool_Exp>>;
  _not?: Maybe<CourseAssignEmployee_Bool_Exp>;
  _or?: Maybe<Array<CourseAssignEmployee_Bool_Exp>>;
  courseAssignEmployeeDirections?: Maybe<CourseAssignEmployeeDirection_Bool_Exp>;
  courseAssignEmployeeDirections_aggregate?: Maybe<CourseAssignEmployeeDirection_Aggregate_Bool_Exp>;
  courseAssignId?: Maybe<Uuid_Comparison_Exp>;
  createdAt?: Maybe<Timestamptz_Comparison_Exp>;
  forManager?: Maybe<Boolean_Comparison_Exp>;
  forStudent?: Maybe<Boolean_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  updatedAt?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "courseAssignEmployee" */
export enum CourseAssignEmployee_Constraint {
  /** unique or primary key constraint on columns "courseAssignId" */
  CourseAssignEmployeeCourseAssignIdKey = 'courseAssignEmployee_courseAssignId_key',
  /** unique or primary key constraint on columns "id" */
  CourseAssignEmployeePkey = 'courseAssignEmployee_pkey'
}

/** input type for inserting data into table "courseAssignEmployee" */
export type CourseAssignEmployee_Insert_Input = {
  courseAssignEmployeeDirections?: Maybe<CourseAssignEmployeeDirection_Arr_Rel_Insert_Input>;
  courseAssignId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  forManager?: Maybe<Scalars['Boolean']>;
  forStudent?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type CourseAssignEmployee_Max_Fields = {
  __typename?: 'courseAssignEmployee_max_fields';
  courseAssignId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type CourseAssignEmployee_Min_Fields = {
  __typename?: 'courseAssignEmployee_min_fields';
  courseAssignId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "courseAssignEmployee" */
export type CourseAssignEmployee_Mutation_Response = {
  __typename?: 'courseAssignEmployee_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<CourseAssignEmployee>;
};

/** input type for inserting object relation for remote table "courseAssignEmployee" */
export type CourseAssignEmployee_Obj_Rel_Insert_Input = {
  data: CourseAssignEmployee_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<CourseAssignEmployee_On_Conflict>;
};

/** on_conflict condition type for table "courseAssignEmployee" */
export type CourseAssignEmployee_On_Conflict = {
  constraint: CourseAssignEmployee_Constraint;
  update_columns?: Array<CourseAssignEmployee_Update_Column>;
  where?: Maybe<CourseAssignEmployee_Bool_Exp>;
};

/** Ordering options when selecting data from "courseAssignEmployee". */
export type CourseAssignEmployee_Order_By = {
  courseAssignEmployeeDirections_aggregate?: Maybe<CourseAssignEmployeeDirection_Aggregate_Order_By>;
  courseAssignId?: Maybe<Order_By>;
  createdAt?: Maybe<Order_By>;
  forManager?: Maybe<Order_By>;
  forStudent?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  updatedAt?: Maybe<Order_By>;
};

/** primary key columns input for table: courseAssignEmployee */
export type CourseAssignEmployee_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "courseAssignEmployee" */
export enum CourseAssignEmployee_Select_Column {
  /** column name */
  CourseAssignId = 'courseAssignId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  ForManager = 'forManager',
  /** column name */
  ForStudent = 'forStudent',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updatedAt'
}

/** input type for updating data in table "courseAssignEmployee" */
export type CourseAssignEmployee_Set_Input = {
  courseAssignId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  forManager?: Maybe<Scalars['Boolean']>;
  forStudent?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "courseAssignEmployee" */
export type CourseAssignEmployee_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: CourseAssignEmployee_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type CourseAssignEmployee_Stream_Cursor_Value_Input = {
  courseAssignId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  forManager?: Maybe<Scalars['Boolean']>;
  forStudent?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** update columns of table "courseAssignEmployee" */
export enum CourseAssignEmployee_Update_Column {
  /** column name */
  CourseAssignId = 'courseAssignId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  ForManager = 'forManager',
  /** column name */
  ForStudent = 'forStudent',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updatedAt'
}

export type CourseAssignEmployee_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<CourseAssignEmployee_Set_Input>;
  /** filter the rows which have to be updated */
  where: CourseAssignEmployee_Bool_Exp;
};

/** columns and relationships of "courseAssignGroup" */
export type CourseAssignGroup = {
  __typename?: 'courseAssignGroup';
  courseAssignId: Scalars['uuid'];
  createdAt: Scalars['timestamptz'];
  /** An object relationship */
  group: Group;
  groupId: Scalars['uuid'];
  id: Scalars['uuid'];
  updatedAt: Scalars['timestamptz'];
};

/** aggregated selection of "courseAssignGroup" */
export type CourseAssignGroup_Aggregate = {
  __typename?: 'courseAssignGroup_aggregate';
  aggregate?: Maybe<CourseAssignGroup_Aggregate_Fields>;
  nodes: Array<CourseAssignGroup>;
};

export type CourseAssignGroup_Aggregate_Bool_Exp = {
  count?: Maybe<CourseAssignGroup_Aggregate_Bool_Exp_Count>;
};

export type CourseAssignGroup_Aggregate_Bool_Exp_Count = {
  arguments?: Maybe<Array<CourseAssignGroup_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<CourseAssignGroup_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "courseAssignGroup" */
export type CourseAssignGroup_Aggregate_Fields = {
  __typename?: 'courseAssignGroup_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<CourseAssignGroup_Max_Fields>;
  min?: Maybe<CourseAssignGroup_Min_Fields>;
};


/** aggregate fields of "courseAssignGroup" */
export type CourseAssignGroup_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<CourseAssignGroup_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "courseAssignGroup" */
export type CourseAssignGroup_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<CourseAssignGroup_Max_Order_By>;
  min?: Maybe<CourseAssignGroup_Min_Order_By>;
};

/** input type for inserting array relation for remote table "courseAssignGroup" */
export type CourseAssignGroup_Arr_Rel_Insert_Input = {
  data: Array<CourseAssignGroup_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<CourseAssignGroup_On_Conflict>;
};

/** Boolean expression to filter rows from the table "courseAssignGroup". All fields are combined with a logical 'AND'. */
export type CourseAssignGroup_Bool_Exp = {
  _and?: Maybe<Array<CourseAssignGroup_Bool_Exp>>;
  _not?: Maybe<CourseAssignGroup_Bool_Exp>;
  _or?: Maybe<Array<CourseAssignGroup_Bool_Exp>>;
  courseAssignId?: Maybe<Uuid_Comparison_Exp>;
  createdAt?: Maybe<Timestamptz_Comparison_Exp>;
  group?: Maybe<Group_Bool_Exp>;
  groupId?: Maybe<Uuid_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  updatedAt?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "courseAssignGroup" */
export enum CourseAssignGroup_Constraint {
  /** unique or primary key constraint on columns "id" */
  CourseAssignGroupPkey = 'courseAssignGroup_pkey',
  /** unique or primary key constraint on columns "courseAssignId", "groupId" */
  UserAssignGroupCourseAssignIdGroupIdKey = 'userAssignGroup_courseAssignId_groupId_key'
}

/** input type for inserting data into table "courseAssignGroup" */
export type CourseAssignGroup_Insert_Input = {
  courseAssignId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  group?: Maybe<Group_Obj_Rel_Insert_Input>;
  groupId?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type CourseAssignGroup_Max_Fields = {
  __typename?: 'courseAssignGroup_max_fields';
  courseAssignId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  groupId?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "courseAssignGroup" */
export type CourseAssignGroup_Max_Order_By = {
  courseAssignId?: Maybe<Order_By>;
  createdAt?: Maybe<Order_By>;
  groupId?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  updatedAt?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type CourseAssignGroup_Min_Fields = {
  __typename?: 'courseAssignGroup_min_fields';
  courseAssignId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  groupId?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "courseAssignGroup" */
export type CourseAssignGroup_Min_Order_By = {
  courseAssignId?: Maybe<Order_By>;
  createdAt?: Maybe<Order_By>;
  groupId?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  updatedAt?: Maybe<Order_By>;
};

/** response of any mutation on the table "courseAssignGroup" */
export type CourseAssignGroup_Mutation_Response = {
  __typename?: 'courseAssignGroup_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<CourseAssignGroup>;
};

/** on_conflict condition type for table "courseAssignGroup" */
export type CourseAssignGroup_On_Conflict = {
  constraint: CourseAssignGroup_Constraint;
  update_columns?: Array<CourseAssignGroup_Update_Column>;
  where?: Maybe<CourseAssignGroup_Bool_Exp>;
};

/** Ordering options when selecting data from "courseAssignGroup". */
export type CourseAssignGroup_Order_By = {
  courseAssignId?: Maybe<Order_By>;
  createdAt?: Maybe<Order_By>;
  group?: Maybe<Group_Order_By>;
  groupId?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  updatedAt?: Maybe<Order_By>;
};

/** primary key columns input for table: courseAssignGroup */
export type CourseAssignGroup_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "courseAssignGroup" */
export enum CourseAssignGroup_Select_Column {
  /** column name */
  CourseAssignId = 'courseAssignId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  GroupId = 'groupId',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updatedAt'
}

/** input type for updating data in table "courseAssignGroup" */
export type CourseAssignGroup_Set_Input = {
  courseAssignId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  groupId?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "courseAssignGroup" */
export type CourseAssignGroup_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: CourseAssignGroup_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type CourseAssignGroup_Stream_Cursor_Value_Input = {
  courseAssignId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  groupId?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** update columns of table "courseAssignGroup" */
export enum CourseAssignGroup_Update_Column {
  /** column name */
  CourseAssignId = 'courseAssignId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  GroupId = 'groupId',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updatedAt'
}

export type CourseAssignGroup_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<CourseAssignGroup_Set_Input>;
  /** filter the rows which have to be updated */
  where: CourseAssignGroup_Bool_Exp;
};

/** columns and relationships of "courseAssignOther" */
export type CourseAssignOther = {
  __typename?: 'courseAssignOther';
  courseAssignId: Scalars['uuid'];
  createdAt: Scalars['timestamptz'];
  id: Scalars['uuid'];
  updatedAt: Scalars['timestamptz'];
};

/** aggregated selection of "courseAssignOther" */
export type CourseAssignOther_Aggregate = {
  __typename?: 'courseAssignOther_aggregate';
  aggregate?: Maybe<CourseAssignOther_Aggregate_Fields>;
  nodes: Array<CourseAssignOther>;
};

/** aggregate fields of "courseAssignOther" */
export type CourseAssignOther_Aggregate_Fields = {
  __typename?: 'courseAssignOther_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<CourseAssignOther_Max_Fields>;
  min?: Maybe<CourseAssignOther_Min_Fields>;
};


/** aggregate fields of "courseAssignOther" */
export type CourseAssignOther_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<CourseAssignOther_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "courseAssignOther". All fields are combined with a logical 'AND'. */
export type CourseAssignOther_Bool_Exp = {
  _and?: Maybe<Array<CourseAssignOther_Bool_Exp>>;
  _not?: Maybe<CourseAssignOther_Bool_Exp>;
  _or?: Maybe<Array<CourseAssignOther_Bool_Exp>>;
  courseAssignId?: Maybe<Uuid_Comparison_Exp>;
  createdAt?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  updatedAt?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "courseAssignOther" */
export enum CourseAssignOther_Constraint {
  /** unique or primary key constraint on columns "courseAssignId" */
  CourseAssignOtherCourseAssignIdKey = 'courseAssignOther_courseAssignId_key',
  /** unique or primary key constraint on columns "id" */
  CourseAssignOtherPkey = 'courseAssignOther_pkey'
}

/** input type for inserting data into table "courseAssignOther" */
export type CourseAssignOther_Insert_Input = {
  courseAssignId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type CourseAssignOther_Max_Fields = {
  __typename?: 'courseAssignOther_max_fields';
  courseAssignId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type CourseAssignOther_Min_Fields = {
  __typename?: 'courseAssignOther_min_fields';
  courseAssignId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "courseAssignOther" */
export type CourseAssignOther_Mutation_Response = {
  __typename?: 'courseAssignOther_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<CourseAssignOther>;
};

/** input type for inserting object relation for remote table "courseAssignOther" */
export type CourseAssignOther_Obj_Rel_Insert_Input = {
  data: CourseAssignOther_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<CourseAssignOther_On_Conflict>;
};

/** on_conflict condition type for table "courseAssignOther" */
export type CourseAssignOther_On_Conflict = {
  constraint: CourseAssignOther_Constraint;
  update_columns?: Array<CourseAssignOther_Update_Column>;
  where?: Maybe<CourseAssignOther_Bool_Exp>;
};

/** Ordering options when selecting data from "courseAssignOther". */
export type CourseAssignOther_Order_By = {
  courseAssignId?: Maybe<Order_By>;
  createdAt?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  updatedAt?: Maybe<Order_By>;
};

/** primary key columns input for table: courseAssignOther */
export type CourseAssignOther_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "courseAssignOther" */
export enum CourseAssignOther_Select_Column {
  /** column name */
  CourseAssignId = 'courseAssignId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updatedAt'
}

/** input type for updating data in table "courseAssignOther" */
export type CourseAssignOther_Set_Input = {
  courseAssignId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "courseAssignOther" */
export type CourseAssignOther_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: CourseAssignOther_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type CourseAssignOther_Stream_Cursor_Value_Input = {
  courseAssignId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** update columns of table "courseAssignOther" */
export enum CourseAssignOther_Update_Column {
  /** column name */
  CourseAssignId = 'courseAssignId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updatedAt'
}

export type CourseAssignOther_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<CourseAssignOther_Set_Input>;
  /** filter the rows which have to be updated */
  where: CourseAssignOther_Bool_Exp;
};

/** columns and relationships of "courseAssignUser" */
export type CourseAssignUser = {
  __typename?: 'courseAssignUser';
  courseAssignId: Scalars['uuid'];
  createdAt: Scalars['timestamptz'];
  id: Scalars['uuid'];
  updatedAt: Scalars['timestamptz'];
  userId: Scalars['uuid'];
};

/** aggregated selection of "courseAssignUser" */
export type CourseAssignUser_Aggregate = {
  __typename?: 'courseAssignUser_aggregate';
  aggregate?: Maybe<CourseAssignUser_Aggregate_Fields>;
  nodes: Array<CourseAssignUser>;
};

export type CourseAssignUser_Aggregate_Bool_Exp = {
  count?: Maybe<CourseAssignUser_Aggregate_Bool_Exp_Count>;
};

export type CourseAssignUser_Aggregate_Bool_Exp_Count = {
  arguments?: Maybe<Array<CourseAssignUser_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<CourseAssignUser_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "courseAssignUser" */
export type CourseAssignUser_Aggregate_Fields = {
  __typename?: 'courseAssignUser_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<CourseAssignUser_Max_Fields>;
  min?: Maybe<CourseAssignUser_Min_Fields>;
};


/** aggregate fields of "courseAssignUser" */
export type CourseAssignUser_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<CourseAssignUser_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "courseAssignUser" */
export type CourseAssignUser_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<CourseAssignUser_Max_Order_By>;
  min?: Maybe<CourseAssignUser_Min_Order_By>;
};

/** input type for inserting array relation for remote table "courseAssignUser" */
export type CourseAssignUser_Arr_Rel_Insert_Input = {
  data: Array<CourseAssignUser_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<CourseAssignUser_On_Conflict>;
};

/** Boolean expression to filter rows from the table "courseAssignUser". All fields are combined with a logical 'AND'. */
export type CourseAssignUser_Bool_Exp = {
  _and?: Maybe<Array<CourseAssignUser_Bool_Exp>>;
  _not?: Maybe<CourseAssignUser_Bool_Exp>;
  _or?: Maybe<Array<CourseAssignUser_Bool_Exp>>;
  courseAssignId?: Maybe<Uuid_Comparison_Exp>;
  createdAt?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  updatedAt?: Maybe<Timestamptz_Comparison_Exp>;
  userId?: Maybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "courseAssignUser" */
export enum CourseAssignUser_Constraint {
  /** unique or primary key constraint on columns "id" */
  CourseAssignUserPkey = 'courseAssignUser_pkey',
  /** unique or primary key constraint on columns "courseAssignId", "userId" */
  UserAssignUserCourseAssignIdUserIdKey = 'userAssignUser_courseAssignId_userId_key'
}

/** input type for inserting data into table "courseAssignUser" */
export type CourseAssignUser_Insert_Input = {
  courseAssignId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  userId?: Maybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type CourseAssignUser_Max_Fields = {
  __typename?: 'courseAssignUser_max_fields';
  courseAssignId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  userId?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "courseAssignUser" */
export type CourseAssignUser_Max_Order_By = {
  courseAssignId?: Maybe<Order_By>;
  createdAt?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  updatedAt?: Maybe<Order_By>;
  userId?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type CourseAssignUser_Min_Fields = {
  __typename?: 'courseAssignUser_min_fields';
  courseAssignId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  userId?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "courseAssignUser" */
export type CourseAssignUser_Min_Order_By = {
  courseAssignId?: Maybe<Order_By>;
  createdAt?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  updatedAt?: Maybe<Order_By>;
  userId?: Maybe<Order_By>;
};

/** response of any mutation on the table "courseAssignUser" */
export type CourseAssignUser_Mutation_Response = {
  __typename?: 'courseAssignUser_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<CourseAssignUser>;
};

/** on_conflict condition type for table "courseAssignUser" */
export type CourseAssignUser_On_Conflict = {
  constraint: CourseAssignUser_Constraint;
  update_columns?: Array<CourseAssignUser_Update_Column>;
  where?: Maybe<CourseAssignUser_Bool_Exp>;
};

/** Ordering options when selecting data from "courseAssignUser". */
export type CourseAssignUser_Order_By = {
  courseAssignId?: Maybe<Order_By>;
  createdAt?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  updatedAt?: Maybe<Order_By>;
  userId?: Maybe<Order_By>;
};

/** primary key columns input for table: courseAssignUser */
export type CourseAssignUser_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "courseAssignUser" */
export enum CourseAssignUser_Select_Column {
  /** column name */
  CourseAssignId = 'courseAssignId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  UserId = 'userId'
}

/** input type for updating data in table "courseAssignUser" */
export type CourseAssignUser_Set_Input = {
  courseAssignId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  userId?: Maybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "courseAssignUser" */
export type CourseAssignUser_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: CourseAssignUser_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type CourseAssignUser_Stream_Cursor_Value_Input = {
  courseAssignId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  userId?: Maybe<Scalars['uuid']>;
};

/** update columns of table "courseAssignUser" */
export enum CourseAssignUser_Update_Column {
  /** column name */
  CourseAssignId = 'courseAssignId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  UserId = 'userId'
}

export type CourseAssignUser_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<CourseAssignUser_Set_Input>;
  /** filter the rows which have to be updated */
  where: CourseAssignUser_Bool_Exp;
};

/** columns and relationships of "courseAssignVolunteer" */
export type CourseAssignVolunteer = {
  __typename?: 'courseAssignVolunteer';
  /** An object relationship */
  courseAssign: CourseAssign;
  courseAssignId: Scalars['uuid'];
  /** An array relationship */
  courseAssignVolunteerFas: Array<CourseAssignVolunteerFa>;
  /** An aggregate relationship */
  courseAssignVolunteerFas_aggregate: CourseAssignVolunteerFa_Aggregate;
  /** An array relationship */
  courseAssignVolunteerJobTitles: Array<CourseAssignVolunteerJobTitle>;
  /** An aggregate relationship */
  courseAssignVolunteerJobTitles_aggregate: CourseAssignVolunteerJobTitle_Aggregate;
  /** An array relationship */
  courseAssignVolunteerSites: Array<CourseAssignVolunteerSite>;
  /** An aggregate relationship */
  courseAssignVolunteerSites_aggregate: CourseAssignVolunteerSite_Aggregate;
  createdAt: Scalars['timestamptz'];
  forHolder?: Maybe<Scalars['Boolean']>;
  forParisCityHall?: Maybe<Scalars['Boolean']>;
  forTeamLeader?: Maybe<Scalars['Boolean']>;
  id: Scalars['uuid'];
  isOlympics: Scalars['Boolean'];
  isParalympics: Scalars['Boolean'];
  isPreGames: Scalars['Boolean'];
  isTestEvent: Scalars['Boolean'];
  updatedAt: Scalars['timestamptz'];
};


/** columns and relationships of "courseAssignVolunteer" */
export type CourseAssignVolunteerCourseAssignVolunteerFasArgs = {
  distinct_on?: Maybe<Array<CourseAssignVolunteerFa_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<CourseAssignVolunteerFa_Order_By>>;
  where?: Maybe<CourseAssignVolunteerFa_Bool_Exp>;
};


/** columns and relationships of "courseAssignVolunteer" */
export type CourseAssignVolunteerCourseAssignVolunteerFas_AggregateArgs = {
  distinct_on?: Maybe<Array<CourseAssignVolunteerFa_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<CourseAssignVolunteerFa_Order_By>>;
  where?: Maybe<CourseAssignVolunteerFa_Bool_Exp>;
};


/** columns and relationships of "courseAssignVolunteer" */
export type CourseAssignVolunteerCourseAssignVolunteerJobTitlesArgs = {
  distinct_on?: Maybe<Array<CourseAssignVolunteerJobTitle_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<CourseAssignVolunteerJobTitle_Order_By>>;
  where?: Maybe<CourseAssignVolunteerJobTitle_Bool_Exp>;
};


/** columns and relationships of "courseAssignVolunteer" */
export type CourseAssignVolunteerCourseAssignVolunteerJobTitles_AggregateArgs = {
  distinct_on?: Maybe<Array<CourseAssignVolunteerJobTitle_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<CourseAssignVolunteerJobTitle_Order_By>>;
  where?: Maybe<CourseAssignVolunteerJobTitle_Bool_Exp>;
};


/** columns and relationships of "courseAssignVolunteer" */
export type CourseAssignVolunteerCourseAssignVolunteerSitesArgs = {
  distinct_on?: Maybe<Array<CourseAssignVolunteerSite_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<CourseAssignVolunteerSite_Order_By>>;
  where?: Maybe<CourseAssignVolunteerSite_Bool_Exp>;
};


/** columns and relationships of "courseAssignVolunteer" */
export type CourseAssignVolunteerCourseAssignVolunteerSites_AggregateArgs = {
  distinct_on?: Maybe<Array<CourseAssignVolunteerSite_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<CourseAssignVolunteerSite_Order_By>>;
  where?: Maybe<CourseAssignVolunteerSite_Bool_Exp>;
};

/** columns and relationships of "courseAssignVolunteerFa" */
export type CourseAssignVolunteerFa = {
  __typename?: 'courseAssignVolunteerFa';
  /** An object relationship */
  courseAssignVolunteer: CourseAssignVolunteer;
  courseAssignVolunteerId: Scalars['uuid'];
  createdAt: Scalars['timestamp'];
  /** An object relationship */
  fa: Fa;
  faId: Scalars['uuid'];
  id: Scalars['uuid'];
  updatedAt: Scalars['timestamptz'];
};

/** aggregated selection of "courseAssignVolunteerFa" */
export type CourseAssignVolunteerFa_Aggregate = {
  __typename?: 'courseAssignVolunteerFa_aggregate';
  aggregate?: Maybe<CourseAssignVolunteerFa_Aggregate_Fields>;
  nodes: Array<CourseAssignVolunteerFa>;
};

export type CourseAssignVolunteerFa_Aggregate_Bool_Exp = {
  count?: Maybe<CourseAssignVolunteerFa_Aggregate_Bool_Exp_Count>;
};

export type CourseAssignVolunteerFa_Aggregate_Bool_Exp_Count = {
  arguments?: Maybe<Array<CourseAssignVolunteerFa_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<CourseAssignVolunteerFa_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "courseAssignVolunteerFa" */
export type CourseAssignVolunteerFa_Aggregate_Fields = {
  __typename?: 'courseAssignVolunteerFa_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<CourseAssignVolunteerFa_Max_Fields>;
  min?: Maybe<CourseAssignVolunteerFa_Min_Fields>;
};


/** aggregate fields of "courseAssignVolunteerFa" */
export type CourseAssignVolunteerFa_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<CourseAssignVolunteerFa_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "courseAssignVolunteerFa" */
export type CourseAssignVolunteerFa_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<CourseAssignVolunteerFa_Max_Order_By>;
  min?: Maybe<CourseAssignVolunteerFa_Min_Order_By>;
};

/** input type for inserting array relation for remote table "courseAssignVolunteerFa" */
export type CourseAssignVolunteerFa_Arr_Rel_Insert_Input = {
  data: Array<CourseAssignVolunteerFa_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<CourseAssignVolunteerFa_On_Conflict>;
};

/** Boolean expression to filter rows from the table "courseAssignVolunteerFa". All fields are combined with a logical 'AND'. */
export type CourseAssignVolunteerFa_Bool_Exp = {
  _and?: Maybe<Array<CourseAssignVolunteerFa_Bool_Exp>>;
  _not?: Maybe<CourseAssignVolunteerFa_Bool_Exp>;
  _or?: Maybe<Array<CourseAssignVolunteerFa_Bool_Exp>>;
  courseAssignVolunteer?: Maybe<CourseAssignVolunteer_Bool_Exp>;
  courseAssignVolunteerId?: Maybe<Uuid_Comparison_Exp>;
  createdAt?: Maybe<Timestamp_Comparison_Exp>;
  fa?: Maybe<Fa_Bool_Exp>;
  faId?: Maybe<Uuid_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  updatedAt?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "courseAssignVolunteerFa" */
export enum CourseAssignVolunteerFa_Constraint {
  /** unique or primary key constraint on columns "faId", "courseAssignVolunteerId" */
  CourseAssignVolunteerFaFaIdCourseAssignVolunteerIdKey = 'courseAssignVolunteerFa_faId_courseAssignVolunteerId_key',
  /** unique or primary key constraint on columns "id" */
  CourseAssignVolunteerFaPkey = 'courseAssignVolunteerFa_pkey'
}

/** input type for inserting data into table "courseAssignVolunteerFa" */
export type CourseAssignVolunteerFa_Insert_Input = {
  courseAssignVolunteer?: Maybe<CourseAssignVolunteer_Obj_Rel_Insert_Input>;
  courseAssignVolunteerId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamp']>;
  fa?: Maybe<Fa_Obj_Rel_Insert_Input>;
  faId?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type CourseAssignVolunteerFa_Max_Fields = {
  __typename?: 'courseAssignVolunteerFa_max_fields';
  courseAssignVolunteerId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamp']>;
  faId?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "courseAssignVolunteerFa" */
export type CourseAssignVolunteerFa_Max_Order_By = {
  courseAssignVolunteerId?: Maybe<Order_By>;
  createdAt?: Maybe<Order_By>;
  faId?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  updatedAt?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type CourseAssignVolunteerFa_Min_Fields = {
  __typename?: 'courseAssignVolunteerFa_min_fields';
  courseAssignVolunteerId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamp']>;
  faId?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "courseAssignVolunteerFa" */
export type CourseAssignVolunteerFa_Min_Order_By = {
  courseAssignVolunteerId?: Maybe<Order_By>;
  createdAt?: Maybe<Order_By>;
  faId?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  updatedAt?: Maybe<Order_By>;
};

/** response of any mutation on the table "courseAssignVolunteerFa" */
export type CourseAssignVolunteerFa_Mutation_Response = {
  __typename?: 'courseAssignVolunteerFa_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<CourseAssignVolunteerFa>;
};

/** on_conflict condition type for table "courseAssignVolunteerFa" */
export type CourseAssignVolunteerFa_On_Conflict = {
  constraint: CourseAssignVolunteerFa_Constraint;
  update_columns?: Array<CourseAssignVolunteerFa_Update_Column>;
  where?: Maybe<CourseAssignVolunteerFa_Bool_Exp>;
};

/** Ordering options when selecting data from "courseAssignVolunteerFa". */
export type CourseAssignVolunteerFa_Order_By = {
  courseAssignVolunteer?: Maybe<CourseAssignVolunteer_Order_By>;
  courseAssignVolunteerId?: Maybe<Order_By>;
  createdAt?: Maybe<Order_By>;
  fa?: Maybe<Fa_Order_By>;
  faId?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  updatedAt?: Maybe<Order_By>;
};

/** primary key columns input for table: courseAssignVolunteerFa */
export type CourseAssignVolunteerFa_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "courseAssignVolunteerFa" */
export enum CourseAssignVolunteerFa_Select_Column {
  /** column name */
  CourseAssignVolunteerId = 'courseAssignVolunteerId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  FaId = 'faId',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updatedAt'
}

/** input type for updating data in table "courseAssignVolunteerFa" */
export type CourseAssignVolunteerFa_Set_Input = {
  courseAssignVolunteerId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamp']>;
  faId?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "courseAssignVolunteerFa" */
export type CourseAssignVolunteerFa_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: CourseAssignVolunteerFa_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type CourseAssignVolunteerFa_Stream_Cursor_Value_Input = {
  courseAssignVolunteerId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamp']>;
  faId?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** update columns of table "courseAssignVolunteerFa" */
export enum CourseAssignVolunteerFa_Update_Column {
  /** column name */
  CourseAssignVolunteerId = 'courseAssignVolunteerId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  FaId = 'faId',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updatedAt'
}

export type CourseAssignVolunteerFa_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<CourseAssignVolunteerFa_Set_Input>;
  /** filter the rows which have to be updated */
  where: CourseAssignVolunteerFa_Bool_Exp;
};

/** columns and relationships of "courseAssignVolunteerJobTitle" */
export type CourseAssignVolunteerJobTitle = {
  __typename?: 'courseAssignVolunteerJobTitle';
  /** An object relationship */
  courseAssignVolunteer: CourseAssignVolunteer;
  courseAssignVolunteerId: Scalars['uuid'];
  createdAt: Scalars['timestamp'];
  id: Scalars['uuid'];
  /** An object relationship */
  jobTitle: JobTitle;
  jobTitleId: Scalars['uuid'];
  updatedAt: Scalars['timestamptz'];
};

/** aggregated selection of "courseAssignVolunteerJobTitle" */
export type CourseAssignVolunteerJobTitle_Aggregate = {
  __typename?: 'courseAssignVolunteerJobTitle_aggregate';
  aggregate?: Maybe<CourseAssignVolunteerJobTitle_Aggregate_Fields>;
  nodes: Array<CourseAssignVolunteerJobTitle>;
};

export type CourseAssignVolunteerJobTitle_Aggregate_Bool_Exp = {
  count?: Maybe<CourseAssignVolunteerJobTitle_Aggregate_Bool_Exp_Count>;
};

export type CourseAssignVolunteerJobTitle_Aggregate_Bool_Exp_Count = {
  arguments?: Maybe<Array<CourseAssignVolunteerJobTitle_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<CourseAssignVolunteerJobTitle_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "courseAssignVolunteerJobTitle" */
export type CourseAssignVolunteerJobTitle_Aggregate_Fields = {
  __typename?: 'courseAssignVolunteerJobTitle_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<CourseAssignVolunteerJobTitle_Max_Fields>;
  min?: Maybe<CourseAssignVolunteerJobTitle_Min_Fields>;
};


/** aggregate fields of "courseAssignVolunteerJobTitle" */
export type CourseAssignVolunteerJobTitle_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<CourseAssignVolunteerJobTitle_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "courseAssignVolunteerJobTitle" */
export type CourseAssignVolunteerJobTitle_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<CourseAssignVolunteerJobTitle_Max_Order_By>;
  min?: Maybe<CourseAssignVolunteerJobTitle_Min_Order_By>;
};

/** input type for inserting array relation for remote table "courseAssignVolunteerJobTitle" */
export type CourseAssignVolunteerJobTitle_Arr_Rel_Insert_Input = {
  data: Array<CourseAssignVolunteerJobTitle_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<CourseAssignVolunteerJobTitle_On_Conflict>;
};

/** Boolean expression to filter rows from the table "courseAssignVolunteerJobTitle". All fields are combined with a logical 'AND'. */
export type CourseAssignVolunteerJobTitle_Bool_Exp = {
  _and?: Maybe<Array<CourseAssignVolunteerJobTitle_Bool_Exp>>;
  _not?: Maybe<CourseAssignVolunteerJobTitle_Bool_Exp>;
  _or?: Maybe<Array<CourseAssignVolunteerJobTitle_Bool_Exp>>;
  courseAssignVolunteer?: Maybe<CourseAssignVolunteer_Bool_Exp>;
  courseAssignVolunteerId?: Maybe<Uuid_Comparison_Exp>;
  createdAt?: Maybe<Timestamp_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  jobTitle?: Maybe<JobTitle_Bool_Exp>;
  jobTitleId?: Maybe<Uuid_Comparison_Exp>;
  updatedAt?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "courseAssignVolunteerJobTitle" */
export enum CourseAssignVolunteerJobTitle_Constraint {
  /** unique or primary key constraint on columns "courseAssignVolunteerId", "jobTitleId" */
  CourseAssignVolunteerJobTitleJobTitleIdCourseAssignVolunteerI = 'courseAssignVolunteerJobTitle_jobTitleId_courseAssignVolunteerI',
  /** unique or primary key constraint on columns "id" */
  CourseAssignVolunteerJobTitlePkey = 'courseAssignVolunteerJobTitle_pkey'
}

/** input type for inserting data into table "courseAssignVolunteerJobTitle" */
export type CourseAssignVolunteerJobTitle_Insert_Input = {
  courseAssignVolunteer?: Maybe<CourseAssignVolunteer_Obj_Rel_Insert_Input>;
  courseAssignVolunteerId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['uuid']>;
  jobTitle?: Maybe<JobTitle_Obj_Rel_Insert_Input>;
  jobTitleId?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type CourseAssignVolunteerJobTitle_Max_Fields = {
  __typename?: 'courseAssignVolunteerJobTitle_max_fields';
  courseAssignVolunteerId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['uuid']>;
  jobTitleId?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "courseAssignVolunteerJobTitle" */
export type CourseAssignVolunteerJobTitle_Max_Order_By = {
  courseAssignVolunteerId?: Maybe<Order_By>;
  createdAt?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  jobTitleId?: Maybe<Order_By>;
  updatedAt?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type CourseAssignVolunteerJobTitle_Min_Fields = {
  __typename?: 'courseAssignVolunteerJobTitle_min_fields';
  courseAssignVolunteerId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['uuid']>;
  jobTitleId?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "courseAssignVolunteerJobTitle" */
export type CourseAssignVolunteerJobTitle_Min_Order_By = {
  courseAssignVolunteerId?: Maybe<Order_By>;
  createdAt?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  jobTitleId?: Maybe<Order_By>;
  updatedAt?: Maybe<Order_By>;
};

/** response of any mutation on the table "courseAssignVolunteerJobTitle" */
export type CourseAssignVolunteerJobTitle_Mutation_Response = {
  __typename?: 'courseAssignVolunteerJobTitle_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<CourseAssignVolunteerJobTitle>;
};

/** on_conflict condition type for table "courseAssignVolunteerJobTitle" */
export type CourseAssignVolunteerJobTitle_On_Conflict = {
  constraint: CourseAssignVolunteerJobTitle_Constraint;
  update_columns?: Array<CourseAssignVolunteerJobTitle_Update_Column>;
  where?: Maybe<CourseAssignVolunteerJobTitle_Bool_Exp>;
};

/** Ordering options when selecting data from "courseAssignVolunteerJobTitle". */
export type CourseAssignVolunteerJobTitle_Order_By = {
  courseAssignVolunteer?: Maybe<CourseAssignVolunteer_Order_By>;
  courseAssignVolunteerId?: Maybe<Order_By>;
  createdAt?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  jobTitle?: Maybe<JobTitle_Order_By>;
  jobTitleId?: Maybe<Order_By>;
  updatedAt?: Maybe<Order_By>;
};

/** primary key columns input for table: courseAssignVolunteerJobTitle */
export type CourseAssignVolunteerJobTitle_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "courseAssignVolunteerJobTitle" */
export enum CourseAssignVolunteerJobTitle_Select_Column {
  /** column name */
  CourseAssignVolunteerId = 'courseAssignVolunteerId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  JobTitleId = 'jobTitleId',
  /** column name */
  UpdatedAt = 'updatedAt'
}

/** input type for updating data in table "courseAssignVolunteerJobTitle" */
export type CourseAssignVolunteerJobTitle_Set_Input = {
  courseAssignVolunteerId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['uuid']>;
  jobTitleId?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "courseAssignVolunteerJobTitle" */
export type CourseAssignVolunteerJobTitle_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: CourseAssignVolunteerJobTitle_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type CourseAssignVolunteerJobTitle_Stream_Cursor_Value_Input = {
  courseAssignVolunteerId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['uuid']>;
  jobTitleId?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** update columns of table "courseAssignVolunteerJobTitle" */
export enum CourseAssignVolunteerJobTitle_Update_Column {
  /** column name */
  CourseAssignVolunteerId = 'courseAssignVolunteerId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  JobTitleId = 'jobTitleId',
  /** column name */
  UpdatedAt = 'updatedAt'
}

export type CourseAssignVolunteerJobTitle_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<CourseAssignVolunteerJobTitle_Set_Input>;
  /** filter the rows which have to be updated */
  where: CourseAssignVolunteerJobTitle_Bool_Exp;
};

/** columns and relationships of "courseAssignVolunteerSite" */
export type CourseAssignVolunteerSite = {
  __typename?: 'courseAssignVolunteerSite';
  /** An object relationship */
  courseAssignVolunteer: CourseAssignVolunteer;
  courseAssignVolunteerId: Scalars['uuid'];
  createdAt: Scalars['timestamptz'];
  id: Scalars['uuid'];
  /** An object relationship */
  site: Site;
  siteId: Scalars['uuid'];
  updatedAt: Scalars['timestamptz'];
};

/** aggregated selection of "courseAssignVolunteerSite" */
export type CourseAssignVolunteerSite_Aggregate = {
  __typename?: 'courseAssignVolunteerSite_aggregate';
  aggregate?: Maybe<CourseAssignVolunteerSite_Aggregate_Fields>;
  nodes: Array<CourseAssignVolunteerSite>;
};

export type CourseAssignVolunteerSite_Aggregate_Bool_Exp = {
  count?: Maybe<CourseAssignVolunteerSite_Aggregate_Bool_Exp_Count>;
};

export type CourseAssignVolunteerSite_Aggregate_Bool_Exp_Count = {
  arguments?: Maybe<Array<CourseAssignVolunteerSite_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<CourseAssignVolunteerSite_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "courseAssignVolunteerSite" */
export type CourseAssignVolunteerSite_Aggregate_Fields = {
  __typename?: 'courseAssignVolunteerSite_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<CourseAssignVolunteerSite_Max_Fields>;
  min?: Maybe<CourseAssignVolunteerSite_Min_Fields>;
};


/** aggregate fields of "courseAssignVolunteerSite" */
export type CourseAssignVolunteerSite_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<CourseAssignVolunteerSite_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "courseAssignVolunteerSite" */
export type CourseAssignVolunteerSite_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<CourseAssignVolunteerSite_Max_Order_By>;
  min?: Maybe<CourseAssignVolunteerSite_Min_Order_By>;
};

/** input type for inserting array relation for remote table "courseAssignVolunteerSite" */
export type CourseAssignVolunteerSite_Arr_Rel_Insert_Input = {
  data: Array<CourseAssignVolunteerSite_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<CourseAssignVolunteerSite_On_Conflict>;
};

/** Boolean expression to filter rows from the table "courseAssignVolunteerSite". All fields are combined with a logical 'AND'. */
export type CourseAssignVolunteerSite_Bool_Exp = {
  _and?: Maybe<Array<CourseAssignVolunteerSite_Bool_Exp>>;
  _not?: Maybe<CourseAssignVolunteerSite_Bool_Exp>;
  _or?: Maybe<Array<CourseAssignVolunteerSite_Bool_Exp>>;
  courseAssignVolunteer?: Maybe<CourseAssignVolunteer_Bool_Exp>;
  courseAssignVolunteerId?: Maybe<Uuid_Comparison_Exp>;
  createdAt?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  site?: Maybe<Site_Bool_Exp>;
  siteId?: Maybe<Uuid_Comparison_Exp>;
  updatedAt?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "courseAssignVolunteerSite" */
export enum CourseAssignVolunteerSite_Constraint {
  /** unique or primary key constraint on columns "id" */
  CourseAssignVolunteerSitePkey = 'courseAssignVolunteerSite_pkey',
  /** unique or primary key constraint on columns "siteId", "courseAssignVolunteerId" */
  CourseAssignVolunteerSiteSiteIdCourseAssignVolunteerIdKey = 'courseAssignVolunteerSite_siteId_courseAssignVolunteerId_key'
}

/** input type for inserting data into table "courseAssignVolunteerSite" */
export type CourseAssignVolunteerSite_Insert_Input = {
  courseAssignVolunteer?: Maybe<CourseAssignVolunteer_Obj_Rel_Insert_Input>;
  courseAssignVolunteerId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  site?: Maybe<Site_Obj_Rel_Insert_Input>;
  siteId?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type CourseAssignVolunteerSite_Max_Fields = {
  __typename?: 'courseAssignVolunteerSite_max_fields';
  courseAssignVolunteerId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  siteId?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "courseAssignVolunteerSite" */
export type CourseAssignVolunteerSite_Max_Order_By = {
  courseAssignVolunteerId?: Maybe<Order_By>;
  createdAt?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  siteId?: Maybe<Order_By>;
  updatedAt?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type CourseAssignVolunteerSite_Min_Fields = {
  __typename?: 'courseAssignVolunteerSite_min_fields';
  courseAssignVolunteerId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  siteId?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "courseAssignVolunteerSite" */
export type CourseAssignVolunteerSite_Min_Order_By = {
  courseAssignVolunteerId?: Maybe<Order_By>;
  createdAt?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  siteId?: Maybe<Order_By>;
  updatedAt?: Maybe<Order_By>;
};

/** response of any mutation on the table "courseAssignVolunteerSite" */
export type CourseAssignVolunteerSite_Mutation_Response = {
  __typename?: 'courseAssignVolunteerSite_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<CourseAssignVolunteerSite>;
};

/** on_conflict condition type for table "courseAssignVolunteerSite" */
export type CourseAssignVolunteerSite_On_Conflict = {
  constraint: CourseAssignVolunteerSite_Constraint;
  update_columns?: Array<CourseAssignVolunteerSite_Update_Column>;
  where?: Maybe<CourseAssignVolunteerSite_Bool_Exp>;
};

/** Ordering options when selecting data from "courseAssignVolunteerSite". */
export type CourseAssignVolunteerSite_Order_By = {
  courseAssignVolunteer?: Maybe<CourseAssignVolunteer_Order_By>;
  courseAssignVolunteerId?: Maybe<Order_By>;
  createdAt?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  site?: Maybe<Site_Order_By>;
  siteId?: Maybe<Order_By>;
  updatedAt?: Maybe<Order_By>;
};

/** primary key columns input for table: courseAssignVolunteerSite */
export type CourseAssignVolunteerSite_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "courseAssignVolunteerSite" */
export enum CourseAssignVolunteerSite_Select_Column {
  /** column name */
  CourseAssignVolunteerId = 'courseAssignVolunteerId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  SiteId = 'siteId',
  /** column name */
  UpdatedAt = 'updatedAt'
}

/** input type for updating data in table "courseAssignVolunteerSite" */
export type CourseAssignVolunteerSite_Set_Input = {
  courseAssignVolunteerId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  siteId?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "courseAssignVolunteerSite" */
export type CourseAssignVolunteerSite_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: CourseAssignVolunteerSite_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type CourseAssignVolunteerSite_Stream_Cursor_Value_Input = {
  courseAssignVolunteerId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  siteId?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** update columns of table "courseAssignVolunteerSite" */
export enum CourseAssignVolunteerSite_Update_Column {
  /** column name */
  CourseAssignVolunteerId = 'courseAssignVolunteerId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  SiteId = 'siteId',
  /** column name */
  UpdatedAt = 'updatedAt'
}

export type CourseAssignVolunteerSite_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<CourseAssignVolunteerSite_Set_Input>;
  /** filter the rows which have to be updated */
  where: CourseAssignVolunteerSite_Bool_Exp;
};

/** aggregated selection of "courseAssignVolunteer" */
export type CourseAssignVolunteer_Aggregate = {
  __typename?: 'courseAssignVolunteer_aggregate';
  aggregate?: Maybe<CourseAssignVolunteer_Aggregate_Fields>;
  nodes: Array<CourseAssignVolunteer>;
};

/** aggregate fields of "courseAssignVolunteer" */
export type CourseAssignVolunteer_Aggregate_Fields = {
  __typename?: 'courseAssignVolunteer_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<CourseAssignVolunteer_Max_Fields>;
  min?: Maybe<CourseAssignVolunteer_Min_Fields>;
};


/** aggregate fields of "courseAssignVolunteer" */
export type CourseAssignVolunteer_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<CourseAssignVolunteer_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "courseAssignVolunteer". All fields are combined with a logical 'AND'. */
export type CourseAssignVolunteer_Bool_Exp = {
  _and?: Maybe<Array<CourseAssignVolunteer_Bool_Exp>>;
  _not?: Maybe<CourseAssignVolunteer_Bool_Exp>;
  _or?: Maybe<Array<CourseAssignVolunteer_Bool_Exp>>;
  courseAssign?: Maybe<CourseAssign_Bool_Exp>;
  courseAssignId?: Maybe<Uuid_Comparison_Exp>;
  courseAssignVolunteerFas?: Maybe<CourseAssignVolunteerFa_Bool_Exp>;
  courseAssignVolunteerFas_aggregate?: Maybe<CourseAssignVolunteerFa_Aggregate_Bool_Exp>;
  courseAssignVolunteerJobTitles?: Maybe<CourseAssignVolunteerJobTitle_Bool_Exp>;
  courseAssignVolunteerJobTitles_aggregate?: Maybe<CourseAssignVolunteerJobTitle_Aggregate_Bool_Exp>;
  courseAssignVolunteerSites?: Maybe<CourseAssignVolunteerSite_Bool_Exp>;
  courseAssignVolunteerSites_aggregate?: Maybe<CourseAssignVolunteerSite_Aggregate_Bool_Exp>;
  createdAt?: Maybe<Timestamptz_Comparison_Exp>;
  forHolder?: Maybe<Boolean_Comparison_Exp>;
  forParisCityHall?: Maybe<Boolean_Comparison_Exp>;
  forTeamLeader?: Maybe<Boolean_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  isOlympics?: Maybe<Boolean_Comparison_Exp>;
  isParalympics?: Maybe<Boolean_Comparison_Exp>;
  isPreGames?: Maybe<Boolean_Comparison_Exp>;
  isTestEvent?: Maybe<Boolean_Comparison_Exp>;
  updatedAt?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "courseAssignVolunteer" */
export enum CourseAssignVolunteer_Constraint {
  /** unique or primary key constraint on columns "courseAssignId" */
  CourseAssignVolunteerCourseAssignIdKey = 'courseAssignVolunteer_courseAssignId_key',
  /** unique or primary key constraint on columns "id" */
  CourseAssignVolunteerPkey = 'courseAssignVolunteer_pkey'
}

/** input type for inserting data into table "courseAssignVolunteer" */
export type CourseAssignVolunteer_Insert_Input = {
  courseAssign?: Maybe<CourseAssign_Obj_Rel_Insert_Input>;
  courseAssignId?: Maybe<Scalars['uuid']>;
  courseAssignVolunteerFas?: Maybe<CourseAssignVolunteerFa_Arr_Rel_Insert_Input>;
  courseAssignVolunteerJobTitles?: Maybe<CourseAssignVolunteerJobTitle_Arr_Rel_Insert_Input>;
  courseAssignVolunteerSites?: Maybe<CourseAssignVolunteerSite_Arr_Rel_Insert_Input>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  forHolder?: Maybe<Scalars['Boolean']>;
  forParisCityHall?: Maybe<Scalars['Boolean']>;
  forTeamLeader?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['uuid']>;
  isOlympics?: Maybe<Scalars['Boolean']>;
  isParalympics?: Maybe<Scalars['Boolean']>;
  isPreGames?: Maybe<Scalars['Boolean']>;
  isTestEvent?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type CourseAssignVolunteer_Max_Fields = {
  __typename?: 'courseAssignVolunteer_max_fields';
  courseAssignId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type CourseAssignVolunteer_Min_Fields = {
  __typename?: 'courseAssignVolunteer_min_fields';
  courseAssignId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "courseAssignVolunteer" */
export type CourseAssignVolunteer_Mutation_Response = {
  __typename?: 'courseAssignVolunteer_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<CourseAssignVolunteer>;
};

/** input type for inserting object relation for remote table "courseAssignVolunteer" */
export type CourseAssignVolunteer_Obj_Rel_Insert_Input = {
  data: CourseAssignVolunteer_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<CourseAssignVolunteer_On_Conflict>;
};

/** on_conflict condition type for table "courseAssignVolunteer" */
export type CourseAssignVolunteer_On_Conflict = {
  constraint: CourseAssignVolunteer_Constraint;
  update_columns?: Array<CourseAssignVolunteer_Update_Column>;
  where?: Maybe<CourseAssignVolunteer_Bool_Exp>;
};

/** Ordering options when selecting data from "courseAssignVolunteer". */
export type CourseAssignVolunteer_Order_By = {
  courseAssign?: Maybe<CourseAssign_Order_By>;
  courseAssignId?: Maybe<Order_By>;
  courseAssignVolunteerFas_aggregate?: Maybe<CourseAssignVolunteerFa_Aggregate_Order_By>;
  courseAssignVolunteerJobTitles_aggregate?: Maybe<CourseAssignVolunteerJobTitle_Aggregate_Order_By>;
  courseAssignVolunteerSites_aggregate?: Maybe<CourseAssignVolunteerSite_Aggregate_Order_By>;
  createdAt?: Maybe<Order_By>;
  forHolder?: Maybe<Order_By>;
  forParisCityHall?: Maybe<Order_By>;
  forTeamLeader?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  isOlympics?: Maybe<Order_By>;
  isParalympics?: Maybe<Order_By>;
  isPreGames?: Maybe<Order_By>;
  isTestEvent?: Maybe<Order_By>;
  updatedAt?: Maybe<Order_By>;
};

/** primary key columns input for table: courseAssignVolunteer */
export type CourseAssignVolunteer_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "courseAssignVolunteer" */
export enum CourseAssignVolunteer_Select_Column {
  /** column name */
  CourseAssignId = 'courseAssignId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  ForHolder = 'forHolder',
  /** column name */
  ForParisCityHall = 'forParisCityHall',
  /** column name */
  ForTeamLeader = 'forTeamLeader',
  /** column name */
  Id = 'id',
  /** column name */
  IsOlympics = 'isOlympics',
  /** column name */
  IsParalympics = 'isParalympics',
  /** column name */
  IsPreGames = 'isPreGames',
  /** column name */
  IsTestEvent = 'isTestEvent',
  /** column name */
  UpdatedAt = 'updatedAt'
}

/** input type for updating data in table "courseAssignVolunteer" */
export type CourseAssignVolunteer_Set_Input = {
  courseAssignId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  forHolder?: Maybe<Scalars['Boolean']>;
  forParisCityHall?: Maybe<Scalars['Boolean']>;
  forTeamLeader?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['uuid']>;
  isOlympics?: Maybe<Scalars['Boolean']>;
  isParalympics?: Maybe<Scalars['Boolean']>;
  isPreGames?: Maybe<Scalars['Boolean']>;
  isTestEvent?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "courseAssignVolunteer" */
export type CourseAssignVolunteer_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: CourseAssignVolunteer_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type CourseAssignVolunteer_Stream_Cursor_Value_Input = {
  courseAssignId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  forHolder?: Maybe<Scalars['Boolean']>;
  forParisCityHall?: Maybe<Scalars['Boolean']>;
  forTeamLeader?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['uuid']>;
  isOlympics?: Maybe<Scalars['Boolean']>;
  isParalympics?: Maybe<Scalars['Boolean']>;
  isPreGames?: Maybe<Scalars['Boolean']>;
  isTestEvent?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** update columns of table "courseAssignVolunteer" */
export enum CourseAssignVolunteer_Update_Column {
  /** column name */
  CourseAssignId = 'courseAssignId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  ForHolder = 'forHolder',
  /** column name */
  ForParisCityHall = 'forParisCityHall',
  /** column name */
  ForTeamLeader = 'forTeamLeader',
  /** column name */
  Id = 'id',
  /** column name */
  IsOlympics = 'isOlympics',
  /** column name */
  IsParalympics = 'isParalympics',
  /** column name */
  IsPreGames = 'isPreGames',
  /** column name */
  IsTestEvent = 'isTestEvent',
  /** column name */
  UpdatedAt = 'updatedAt'
}

export type CourseAssignVolunteer_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<CourseAssignVolunteer_Set_Input>;
  /** filter the rows which have to be updated */
  where: CourseAssignVolunteer_Bool_Exp;
};

/** aggregated selection of "courseAssign" */
export type CourseAssign_Aggregate = {
  __typename?: 'courseAssign_aggregate';
  aggregate?: Maybe<CourseAssign_Aggregate_Fields>;
  nodes: Array<CourseAssign>;
};

/** aggregate fields of "courseAssign" */
export type CourseAssign_Aggregate_Fields = {
  __typename?: 'courseAssign_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<CourseAssign_Max_Fields>;
  min?: Maybe<CourseAssign_Min_Fields>;
};


/** aggregate fields of "courseAssign" */
export type CourseAssign_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<CourseAssign_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "courseAssign". All fields are combined with a logical 'AND'. */
export type CourseAssign_Bool_Exp = {
  _and?: Maybe<Array<CourseAssign_Bool_Exp>>;
  _not?: Maybe<CourseAssign_Bool_Exp>;
  _or?: Maybe<Array<CourseAssign_Bool_Exp>>;
  course?: Maybe<Course_Bool_Exp>;
  courseAssignEmployee?: Maybe<CourseAssignEmployee_Bool_Exp>;
  courseAssignGroups?: Maybe<CourseAssignGroup_Bool_Exp>;
  courseAssignGroups_aggregate?: Maybe<CourseAssignGroup_Aggregate_Bool_Exp>;
  courseAssignOthers?: Maybe<CourseAssignOther_Bool_Exp>;
  courseAssignUsers?: Maybe<CourseAssignUser_Bool_Exp>;
  courseAssignUsers_aggregate?: Maybe<CourseAssignUser_Aggregate_Bool_Exp>;
  courseAssignVolunteer?: Maybe<CourseAssignVolunteer_Bool_Exp>;
  courseId?: Maybe<Uuid_Comparison_Exp>;
  createdAt?: Maybe<Timestamptz_Comparison_Exp>;
  d_forEmployee?: Maybe<Boolean_Comparison_Exp>;
  d_forOther?: Maybe<Boolean_Comparison_Exp>;
  d_forVolunteer?: Maybe<Boolean_Comparison_Exp>;
  forAll?: Maybe<Boolean_Comparison_Exp>;
  hasAutoAssign?: Maybe<Boolean_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  publishAt?: Maybe<Timestamptz_Comparison_Exp>;
  type_match?: Maybe<Boolean_Comparison_Exp>;
  updatedAt?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "courseAssign" */
export enum CourseAssign_Constraint {
  /** unique or primary key constraint on columns "id" */
  CourseAssignPkey = 'courseAssign_pkey',
  /** unique or primary key constraint on columns "courseId" */
  UserAssignCourseIdKey = 'userAssign_courseId_key'
}

/** input type for inserting data into table "courseAssign" */
export type CourseAssign_Insert_Input = {
  course?: Maybe<Course_Obj_Rel_Insert_Input>;
  courseAssignEmployee?: Maybe<CourseAssignEmployee_Obj_Rel_Insert_Input>;
  courseAssignGroups?: Maybe<CourseAssignGroup_Arr_Rel_Insert_Input>;
  courseAssignOthers?: Maybe<CourseAssignOther_Obj_Rel_Insert_Input>;
  courseAssignUsers?: Maybe<CourseAssignUser_Arr_Rel_Insert_Input>;
  courseAssignVolunteer?: Maybe<CourseAssignVolunteer_Obj_Rel_Insert_Input>;
  courseId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  d_forEmployee?: Maybe<Scalars['Boolean']>;
  d_forOther?: Maybe<Scalars['Boolean']>;
  d_forVolunteer?: Maybe<Scalars['Boolean']>;
  forAll?: Maybe<Scalars['Boolean']>;
  hasAutoAssign?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['uuid']>;
  publishAt?: Maybe<Scalars['timestamptz']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type CourseAssign_Max_Fields = {
  __typename?: 'courseAssign_max_fields';
  courseId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  publishAt?: Maybe<Scalars['timestamptz']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type CourseAssign_Min_Fields = {
  __typename?: 'courseAssign_min_fields';
  courseId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  publishAt?: Maybe<Scalars['timestamptz']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "courseAssign" */
export type CourseAssign_Mutation_Response = {
  __typename?: 'courseAssign_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<CourseAssign>;
};

/** input type for inserting object relation for remote table "courseAssign" */
export type CourseAssign_Obj_Rel_Insert_Input = {
  data: CourseAssign_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<CourseAssign_On_Conflict>;
};

/** on_conflict condition type for table "courseAssign" */
export type CourseAssign_On_Conflict = {
  constraint: CourseAssign_Constraint;
  update_columns?: Array<CourseAssign_Update_Column>;
  where?: Maybe<CourseAssign_Bool_Exp>;
};

/** Ordering options when selecting data from "courseAssign". */
export type CourseAssign_Order_By = {
  course?: Maybe<Course_Order_By>;
  courseAssignEmployee?: Maybe<CourseAssignEmployee_Order_By>;
  courseAssignGroups_aggregate?: Maybe<CourseAssignGroup_Aggregate_Order_By>;
  courseAssignOthers?: Maybe<CourseAssignOther_Order_By>;
  courseAssignUsers_aggregate?: Maybe<CourseAssignUser_Aggregate_Order_By>;
  courseAssignVolunteer?: Maybe<CourseAssignVolunteer_Order_By>;
  courseId?: Maybe<Order_By>;
  createdAt?: Maybe<Order_By>;
  d_forEmployee?: Maybe<Order_By>;
  d_forOther?: Maybe<Order_By>;
  d_forVolunteer?: Maybe<Order_By>;
  forAll?: Maybe<Order_By>;
  hasAutoAssign?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  publishAt?: Maybe<Order_By>;
  type_match?: Maybe<Order_By>;
  updatedAt?: Maybe<Order_By>;
};

/** primary key columns input for table: courseAssign */
export type CourseAssign_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "courseAssign" */
export enum CourseAssign_Select_Column {
  /** column name */
  CourseId = 'courseId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  DForEmployee = 'd_forEmployee',
  /** column name */
  DForOther = 'd_forOther',
  /** column name */
  DForVolunteer = 'd_forVolunteer',
  /** column name */
  ForAll = 'forAll',
  /** column name */
  HasAutoAssign = 'hasAutoAssign',
  /** column name */
  Id = 'id',
  /** column name */
  PublishAt = 'publishAt',
  /** column name */
  UpdatedAt = 'updatedAt'
}

/** input type for updating data in table "courseAssign" */
export type CourseAssign_Set_Input = {
  courseId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  d_forEmployee?: Maybe<Scalars['Boolean']>;
  d_forOther?: Maybe<Scalars['Boolean']>;
  d_forVolunteer?: Maybe<Scalars['Boolean']>;
  forAll?: Maybe<Scalars['Boolean']>;
  hasAutoAssign?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['uuid']>;
  publishAt?: Maybe<Scalars['timestamptz']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "courseAssign" */
export type CourseAssign_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: CourseAssign_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type CourseAssign_Stream_Cursor_Value_Input = {
  courseId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  d_forEmployee?: Maybe<Scalars['Boolean']>;
  d_forOther?: Maybe<Scalars['Boolean']>;
  d_forVolunteer?: Maybe<Scalars['Boolean']>;
  forAll?: Maybe<Scalars['Boolean']>;
  hasAutoAssign?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['uuid']>;
  publishAt?: Maybe<Scalars['timestamptz']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** update columns of table "courseAssign" */
export enum CourseAssign_Update_Column {
  /** column name */
  CourseId = 'courseId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  DForEmployee = 'd_forEmployee',
  /** column name */
  DForOther = 'd_forOther',
  /** column name */
  DForVolunteer = 'd_forVolunteer',
  /** column name */
  ForAll = 'forAll',
  /** column name */
  HasAutoAssign = 'hasAutoAssign',
  /** column name */
  Id = 'id',
  /** column name */
  PublishAt = 'publishAt',
  /** column name */
  UpdatedAt = 'updatedAt'
}

export type CourseAssign_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<CourseAssign_Set_Input>;
  /** filter the rows which have to be updated */
  where: CourseAssign_Bool_Exp;
};

/** aggregated selection of "course" */
export type Course_Aggregate = {
  __typename?: 'course_aggregate';
  aggregate?: Maybe<Course_Aggregate_Fields>;
  nodes: Array<Course>;
};

export type Course_Aggregate_Bool_Exp = {
  bool_and?: Maybe<Course_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: Maybe<Course_Aggregate_Bool_Exp_Bool_Or>;
  count?: Maybe<Course_Aggregate_Bool_Exp_Count>;
};

export type Course_Aggregate_Bool_Exp_Bool_And = {
  arguments: Course_Select_Column_Course_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Course_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Course_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Course_Select_Column_Course_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Course_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Course_Aggregate_Bool_Exp_Count = {
  arguments?: Maybe<Array<Course_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Course_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "course" */
export type Course_Aggregate_Fields = {
  __typename?: 'course_aggregate_fields';
  avg?: Maybe<Course_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Course_Max_Fields>;
  min?: Maybe<Course_Min_Fields>;
  stddev?: Maybe<Course_Stddev_Fields>;
  stddev_pop?: Maybe<Course_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Course_Stddev_Samp_Fields>;
  sum?: Maybe<Course_Sum_Fields>;
  var_pop?: Maybe<Course_Var_Pop_Fields>;
  var_samp?: Maybe<Course_Var_Samp_Fields>;
  variance?: Maybe<Course_Variance_Fields>;
};


/** aggregate fields of "course" */
export type Course_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Course_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "course" */
export type Course_Aggregate_Order_By = {
  avg?: Maybe<Course_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Course_Max_Order_By>;
  min?: Maybe<Course_Min_Order_By>;
  stddev?: Maybe<Course_Stddev_Order_By>;
  stddev_pop?: Maybe<Course_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Course_Stddev_Samp_Order_By>;
  sum?: Maybe<Course_Sum_Order_By>;
  var_pop?: Maybe<Course_Var_Pop_Order_By>;
  var_samp?: Maybe<Course_Var_Samp_Order_By>;
  variance?: Maybe<Course_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "course" */
export type Course_Arr_Rel_Insert_Input = {
  data: Array<Course_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Course_On_Conflict>;
};

/** aggregate avg on columns */
export type Course_Avg_Fields = {
  __typename?: 'course_avg_fields';
  completionPercentage?: Maybe<Scalars['Float']>;
  frontPageOrder?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "course" */
export type Course_Avg_Order_By = {
  completionPercentage?: Maybe<Order_By>;
  frontPageOrder?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "course". All fields are combined with a logical 'AND'. */
export type Course_Bool_Exp = {
  _and?: Maybe<Array<Course_Bool_Exp>>;
  _not?: Maybe<Course_Bool_Exp>;
  _or?: Maybe<Array<Course_Bool_Exp>>;
  category?: Maybe<String_Comparison_Exp>;
  completionPercentage?: Maybe<Numeric_Comparison_Exp>;
  courseAssign?: Maybe<CourseAssign_Bool_Exp>;
  createdAt?: Maybe<Timestamptz_Comparison_Exp>;
  creator?: Maybe<User_Bool_Exp>;
  creatorId?: Maybe<Uuid_Comparison_Exp>;
  d_courseUser?: Maybe<D_CourseUser_Bool_Exp>;
  description?: Maybe<I18n_Bool_Exp>;
  descriptionI18n?: Maybe<String_Comparison_Exp>;
  descriptionI18nId?: Maybe<Uuid_Comparison_Exp>;
  descriptionShort?: Maybe<I18n_Bool_Exp>;
  descriptionShortI18n?: Maybe<String_Comparison_Exp>;
  descriptionShortI18nId?: Maybe<Uuid_Comparison_Exp>;
  duration?: Maybe<Int_Comparison_Exp>;
  frontPageOrder?: Maybe<Int_Comparison_Exp>;
  humanId?: Maybe<String_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  imageHeadUri?: Maybe<String_Comparison_Exp>;
  isEmployeesAssignment?: Maybe<Boolean_Comparison_Exp>;
  isInFrontPage?: Maybe<Boolean_Comparison_Exp>;
  isOtherAssignment?: Maybe<Boolean_Comparison_Exp>;
  isVolunteerAssignment?: Maybe<Boolean_Comparison_Exp>;
  lastUpdatedBy?: Maybe<User_Bool_Exp>;
  lastUpdatedById?: Maybe<Uuid_Comparison_Exp>;
  lastUpdatedManuallyAt?: Maybe<Timestamptz_Comparison_Exp>;
  progression?: Maybe<Int_Comparison_Exp>;
  publicationDate?: Maybe<Timestamptz_Comparison_Exp>;
  publicationScope?: Maybe<String_Comparison_Exp>;
  sequences?: Maybe<Sequence_Bool_Exp>;
  sequences_aggregate?: Maybe<Sequence_Aggregate_Bool_Exp>;
  skill_of_courses?: Maybe<Skill_Of_Course_Bool_Exp>;
  skill_of_courses_aggregate?: Maybe<Skill_Of_Course_Aggregate_Bool_Exp>;
  spendTime?: Maybe<Int_Comparison_Exp>;
  status?: Maybe<String_Comparison_Exp>;
  tag_of_courses?: Maybe<Tag_Of_Course_Bool_Exp>;
  tag_of_courses_aggregate?: Maybe<Tag_Of_Course_Aggregate_Bool_Exp>;
  thematic?: Maybe<Thematic_Bool_Exp>;
  thematicId?: Maybe<Uuid_Comparison_Exp>;
  title?: Maybe<I18n_Bool_Exp>;
  titleI18n?: Maybe<String_Comparison_Exp>;
  titleI18nId?: Maybe<Uuid_Comparison_Exp>;
  unaccentDescriptionI18n?: Maybe<String_Comparison_Exp>;
  unaccentTitleI18n?: Maybe<String_Comparison_Exp>;
  updatedAt?: Maybe<Timestamptz_Comparison_Exp>;
  userCourseProgresses?: Maybe<UserCourseProgress_Bool_Exp>;
  userCourseProgresses_aggregate?: Maybe<UserCourseProgress_Aggregate_Bool_Exp>;
};

/** unique or primary key constraints on table "course" */
export enum Course_Constraint {
  /** unique or primary key constraint on columns "id" */
  PkCourse = 'PK_course',
  /** unique or primary key constraint on columns "humanId" */
  CourseHumanIdKey = 'course_humanId_key'
}

/** input type for incrementing numeric columns in table "course" */
export type Course_Inc_Input = {
  completionPercentage?: Maybe<Scalars['numeric']>;
  frontPageOrder?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "course" */
export type Course_Insert_Input = {
  category?: Maybe<Scalars['String']>;
  completionPercentage?: Maybe<Scalars['numeric']>;
  courseAssign?: Maybe<CourseAssign_Obj_Rel_Insert_Input>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  creator?: Maybe<User_Obj_Rel_Insert_Input>;
  creatorId?: Maybe<Scalars['uuid']>;
  d_courseUser?: Maybe<D_CourseUser_Obj_Rel_Insert_Input>;
  description?: Maybe<I18n_Obj_Rel_Insert_Input>;
  descriptionI18nId?: Maybe<Scalars['uuid']>;
  descriptionShort?: Maybe<I18n_Obj_Rel_Insert_Input>;
  descriptionShortI18nId?: Maybe<Scalars['uuid']>;
  frontPageOrder?: Maybe<Scalars['Int']>;
  humanId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  imageHeadUri?: Maybe<Scalars['String']>;
  isEmployeesAssignment?: Maybe<Scalars['Boolean']>;
  isInFrontPage?: Maybe<Scalars['Boolean']>;
  isOtherAssignment?: Maybe<Scalars['Boolean']>;
  isVolunteerAssignment?: Maybe<Scalars['Boolean']>;
  lastUpdatedBy?: Maybe<User_Obj_Rel_Insert_Input>;
  lastUpdatedById?: Maybe<Scalars['uuid']>;
  lastUpdatedManuallyAt?: Maybe<Scalars['timestamptz']>;
  publicationDate?: Maybe<Scalars['timestamptz']>;
  publicationScope?: Maybe<Scalars['String']>;
  sequences?: Maybe<Sequence_Arr_Rel_Insert_Input>;
  skill_of_courses?: Maybe<Skill_Of_Course_Arr_Rel_Insert_Input>;
  status?: Maybe<Scalars['String']>;
  tag_of_courses?: Maybe<Tag_Of_Course_Arr_Rel_Insert_Input>;
  thematic?: Maybe<Thematic_Obj_Rel_Insert_Input>;
  thematicId?: Maybe<Scalars['uuid']>;
  title?: Maybe<I18n_Obj_Rel_Insert_Input>;
  titleI18nId?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  userCourseProgresses?: Maybe<UserCourseProgress_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Course_Max_Fields = {
  __typename?: 'course_max_fields';
  category?: Maybe<Scalars['String']>;
  completionPercentage?: Maybe<Scalars['numeric']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  creatorId?: Maybe<Scalars['uuid']>;
  descriptionI18nId?: Maybe<Scalars['uuid']>;
  descriptionShortI18nId?: Maybe<Scalars['uuid']>;
  frontPageOrder?: Maybe<Scalars['Int']>;
  humanId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  imageHeadUri?: Maybe<Scalars['String']>;
  lastUpdatedById?: Maybe<Scalars['uuid']>;
  lastUpdatedManuallyAt?: Maybe<Scalars['timestamptz']>;
  publicationDate?: Maybe<Scalars['timestamptz']>;
  publicationScope?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  thematicId?: Maybe<Scalars['uuid']>;
  titleI18nId?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "course" */
export type Course_Max_Order_By = {
  category?: Maybe<Order_By>;
  completionPercentage?: Maybe<Order_By>;
  createdAt?: Maybe<Order_By>;
  creatorId?: Maybe<Order_By>;
  descriptionI18nId?: Maybe<Order_By>;
  descriptionShortI18nId?: Maybe<Order_By>;
  frontPageOrder?: Maybe<Order_By>;
  humanId?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  imageHeadUri?: Maybe<Order_By>;
  lastUpdatedById?: Maybe<Order_By>;
  lastUpdatedManuallyAt?: Maybe<Order_By>;
  publicationDate?: Maybe<Order_By>;
  publicationScope?: Maybe<Order_By>;
  status?: Maybe<Order_By>;
  thematicId?: Maybe<Order_By>;
  titleI18nId?: Maybe<Order_By>;
  updatedAt?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Course_Min_Fields = {
  __typename?: 'course_min_fields';
  category?: Maybe<Scalars['String']>;
  completionPercentage?: Maybe<Scalars['numeric']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  creatorId?: Maybe<Scalars['uuid']>;
  descriptionI18nId?: Maybe<Scalars['uuid']>;
  descriptionShortI18nId?: Maybe<Scalars['uuid']>;
  frontPageOrder?: Maybe<Scalars['Int']>;
  humanId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  imageHeadUri?: Maybe<Scalars['String']>;
  lastUpdatedById?: Maybe<Scalars['uuid']>;
  lastUpdatedManuallyAt?: Maybe<Scalars['timestamptz']>;
  publicationDate?: Maybe<Scalars['timestamptz']>;
  publicationScope?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  thematicId?: Maybe<Scalars['uuid']>;
  titleI18nId?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "course" */
export type Course_Min_Order_By = {
  category?: Maybe<Order_By>;
  completionPercentage?: Maybe<Order_By>;
  createdAt?: Maybe<Order_By>;
  creatorId?: Maybe<Order_By>;
  descriptionI18nId?: Maybe<Order_By>;
  descriptionShortI18nId?: Maybe<Order_By>;
  frontPageOrder?: Maybe<Order_By>;
  humanId?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  imageHeadUri?: Maybe<Order_By>;
  lastUpdatedById?: Maybe<Order_By>;
  lastUpdatedManuallyAt?: Maybe<Order_By>;
  publicationDate?: Maybe<Order_By>;
  publicationScope?: Maybe<Order_By>;
  status?: Maybe<Order_By>;
  thematicId?: Maybe<Order_By>;
  titleI18nId?: Maybe<Order_By>;
  updatedAt?: Maybe<Order_By>;
};

/** response of any mutation on the table "course" */
export type Course_Mutation_Response = {
  __typename?: 'course_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Course>;
};

/** input type for inserting object relation for remote table "course" */
export type Course_Obj_Rel_Insert_Input = {
  data: Course_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Course_On_Conflict>;
};

/** on_conflict condition type for table "course" */
export type Course_On_Conflict = {
  constraint: Course_Constraint;
  update_columns?: Array<Course_Update_Column>;
  where?: Maybe<Course_Bool_Exp>;
};

/** Ordering options when selecting data from "course". */
export type Course_Order_By = {
  category?: Maybe<Order_By>;
  completionPercentage?: Maybe<Order_By>;
  courseAssign?: Maybe<CourseAssign_Order_By>;
  createdAt?: Maybe<Order_By>;
  creator?: Maybe<User_Order_By>;
  creatorId?: Maybe<Order_By>;
  d_courseUser?: Maybe<D_CourseUser_Order_By>;
  description?: Maybe<I18n_Order_By>;
  descriptionI18n?: Maybe<Order_By>;
  descriptionI18nId?: Maybe<Order_By>;
  descriptionShort?: Maybe<I18n_Order_By>;
  descriptionShortI18n?: Maybe<Order_By>;
  descriptionShortI18nId?: Maybe<Order_By>;
  duration?: Maybe<Order_By>;
  frontPageOrder?: Maybe<Order_By>;
  humanId?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  imageHeadUri?: Maybe<Order_By>;
  isEmployeesAssignment?: Maybe<Order_By>;
  isInFrontPage?: Maybe<Order_By>;
  isOtherAssignment?: Maybe<Order_By>;
  isVolunteerAssignment?: Maybe<Order_By>;
  lastUpdatedBy?: Maybe<User_Order_By>;
  lastUpdatedById?: Maybe<Order_By>;
  lastUpdatedManuallyAt?: Maybe<Order_By>;
  progression?: Maybe<Order_By>;
  publicationDate?: Maybe<Order_By>;
  publicationScope?: Maybe<Order_By>;
  sequences_aggregate?: Maybe<Sequence_Aggregate_Order_By>;
  skill_of_courses_aggregate?: Maybe<Skill_Of_Course_Aggregate_Order_By>;
  spendTime?: Maybe<Order_By>;
  status?: Maybe<Order_By>;
  tag_of_courses_aggregate?: Maybe<Tag_Of_Course_Aggregate_Order_By>;
  thematic?: Maybe<Thematic_Order_By>;
  thematicId?: Maybe<Order_By>;
  title?: Maybe<I18n_Order_By>;
  titleI18n?: Maybe<Order_By>;
  titleI18nId?: Maybe<Order_By>;
  unaccentDescriptionI18n?: Maybe<Order_By>;
  unaccentTitleI18n?: Maybe<Order_By>;
  updatedAt?: Maybe<Order_By>;
  userCourseProgresses_aggregate?: Maybe<UserCourseProgress_Aggregate_Order_By>;
};

/** primary key columns input for table: course */
export type Course_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "course" */
export enum Course_Select_Column {
  /** column name */
  Category = 'category',
  /** column name */
  CompletionPercentage = 'completionPercentage',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  CreatorId = 'creatorId',
  /** column name */
  DescriptionI18nId = 'descriptionI18nId',
  /** column name */
  DescriptionShortI18nId = 'descriptionShortI18nId',
  /** column name */
  FrontPageOrder = 'frontPageOrder',
  /** column name */
  HumanId = 'humanId',
  /** column name */
  Id = 'id',
  /** column name */
  ImageHeadUri = 'imageHeadUri',
  /** column name */
  IsEmployeesAssignment = 'isEmployeesAssignment',
  /** column name */
  IsInFrontPage = 'isInFrontPage',
  /** column name */
  IsOtherAssignment = 'isOtherAssignment',
  /** column name */
  IsVolunteerAssignment = 'isVolunteerAssignment',
  /** column name */
  LastUpdatedById = 'lastUpdatedById',
  /** column name */
  LastUpdatedManuallyAt = 'lastUpdatedManuallyAt',
  /** column name */
  PublicationDate = 'publicationDate',
  /** column name */
  PublicationScope = 'publicationScope',
  /** column name */
  Status = 'status',
  /** column name */
  ThematicId = 'thematicId',
  /** column name */
  TitleI18nId = 'titleI18nId',
  /** column name */
  UpdatedAt = 'updatedAt'
}

/** select "course_aggregate_bool_exp_bool_and_arguments_columns" columns of table "course" */
export enum Course_Select_Column_Course_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  IsEmployeesAssignment = 'isEmployeesAssignment',
  /** column name */
  IsInFrontPage = 'isInFrontPage',
  /** column name */
  IsOtherAssignment = 'isOtherAssignment',
  /** column name */
  IsVolunteerAssignment = 'isVolunteerAssignment'
}

/** select "course_aggregate_bool_exp_bool_or_arguments_columns" columns of table "course" */
export enum Course_Select_Column_Course_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  IsEmployeesAssignment = 'isEmployeesAssignment',
  /** column name */
  IsInFrontPage = 'isInFrontPage',
  /** column name */
  IsOtherAssignment = 'isOtherAssignment',
  /** column name */
  IsVolunteerAssignment = 'isVolunteerAssignment'
}

/** input type for updating data in table "course" */
export type Course_Set_Input = {
  category?: Maybe<Scalars['String']>;
  completionPercentage?: Maybe<Scalars['numeric']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  creatorId?: Maybe<Scalars['uuid']>;
  descriptionI18nId?: Maybe<Scalars['uuid']>;
  descriptionShortI18nId?: Maybe<Scalars['uuid']>;
  frontPageOrder?: Maybe<Scalars['Int']>;
  humanId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  imageHeadUri?: Maybe<Scalars['String']>;
  isEmployeesAssignment?: Maybe<Scalars['Boolean']>;
  isInFrontPage?: Maybe<Scalars['Boolean']>;
  isOtherAssignment?: Maybe<Scalars['Boolean']>;
  isVolunteerAssignment?: Maybe<Scalars['Boolean']>;
  lastUpdatedById?: Maybe<Scalars['uuid']>;
  lastUpdatedManuallyAt?: Maybe<Scalars['timestamptz']>;
  publicationDate?: Maybe<Scalars['timestamptz']>;
  publicationScope?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  thematicId?: Maybe<Scalars['uuid']>;
  titleI18nId?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Course_Stddev_Fields = {
  __typename?: 'course_stddev_fields';
  completionPercentage?: Maybe<Scalars['Float']>;
  frontPageOrder?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "course" */
export type Course_Stddev_Order_By = {
  completionPercentage?: Maybe<Order_By>;
  frontPageOrder?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Course_Stddev_Pop_Fields = {
  __typename?: 'course_stddev_pop_fields';
  completionPercentage?: Maybe<Scalars['Float']>;
  frontPageOrder?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "course" */
export type Course_Stddev_Pop_Order_By = {
  completionPercentage?: Maybe<Order_By>;
  frontPageOrder?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Course_Stddev_Samp_Fields = {
  __typename?: 'course_stddev_samp_fields';
  completionPercentage?: Maybe<Scalars['Float']>;
  frontPageOrder?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "course" */
export type Course_Stddev_Samp_Order_By = {
  completionPercentage?: Maybe<Order_By>;
  frontPageOrder?: Maybe<Order_By>;
};

/** Streaming cursor of the table "course" */
export type Course_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Course_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Course_Stream_Cursor_Value_Input = {
  category?: Maybe<Scalars['String']>;
  completionPercentage?: Maybe<Scalars['numeric']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  creatorId?: Maybe<Scalars['uuid']>;
  descriptionI18nId?: Maybe<Scalars['uuid']>;
  descriptionShortI18nId?: Maybe<Scalars['uuid']>;
  frontPageOrder?: Maybe<Scalars['Int']>;
  humanId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  imageHeadUri?: Maybe<Scalars['String']>;
  isEmployeesAssignment?: Maybe<Scalars['Boolean']>;
  isInFrontPage?: Maybe<Scalars['Boolean']>;
  isOtherAssignment?: Maybe<Scalars['Boolean']>;
  isVolunteerAssignment?: Maybe<Scalars['Boolean']>;
  lastUpdatedById?: Maybe<Scalars['uuid']>;
  lastUpdatedManuallyAt?: Maybe<Scalars['timestamptz']>;
  publicationDate?: Maybe<Scalars['timestamptz']>;
  publicationScope?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  thematicId?: Maybe<Scalars['uuid']>;
  titleI18nId?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type Course_Sum_Fields = {
  __typename?: 'course_sum_fields';
  completionPercentage?: Maybe<Scalars['numeric']>;
  frontPageOrder?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "course" */
export type Course_Sum_Order_By = {
  completionPercentage?: Maybe<Order_By>;
  frontPageOrder?: Maybe<Order_By>;
};

/** update columns of table "course" */
export enum Course_Update_Column {
  /** column name */
  Category = 'category',
  /** column name */
  CompletionPercentage = 'completionPercentage',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  CreatorId = 'creatorId',
  /** column name */
  DescriptionI18nId = 'descriptionI18nId',
  /** column name */
  DescriptionShortI18nId = 'descriptionShortI18nId',
  /** column name */
  FrontPageOrder = 'frontPageOrder',
  /** column name */
  HumanId = 'humanId',
  /** column name */
  Id = 'id',
  /** column name */
  ImageHeadUri = 'imageHeadUri',
  /** column name */
  IsEmployeesAssignment = 'isEmployeesAssignment',
  /** column name */
  IsInFrontPage = 'isInFrontPage',
  /** column name */
  IsOtherAssignment = 'isOtherAssignment',
  /** column name */
  IsVolunteerAssignment = 'isVolunteerAssignment',
  /** column name */
  LastUpdatedById = 'lastUpdatedById',
  /** column name */
  LastUpdatedManuallyAt = 'lastUpdatedManuallyAt',
  /** column name */
  PublicationDate = 'publicationDate',
  /** column name */
  PublicationScope = 'publicationScope',
  /** column name */
  Status = 'status',
  /** column name */
  ThematicId = 'thematicId',
  /** column name */
  TitleI18nId = 'titleI18nId',
  /** column name */
  UpdatedAt = 'updatedAt'
}

export type Course_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<Course_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Course_Set_Input>;
  /** filter the rows which have to be updated */
  where: Course_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Course_Var_Pop_Fields = {
  __typename?: 'course_var_pop_fields';
  completionPercentage?: Maybe<Scalars['Float']>;
  frontPageOrder?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "course" */
export type Course_Var_Pop_Order_By = {
  completionPercentage?: Maybe<Order_By>;
  frontPageOrder?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Course_Var_Samp_Fields = {
  __typename?: 'course_var_samp_fields';
  completionPercentage?: Maybe<Scalars['Float']>;
  frontPageOrder?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "course" */
export type Course_Var_Samp_Order_By = {
  completionPercentage?: Maybe<Order_By>;
  frontPageOrder?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Course_Variance_Fields = {
  __typename?: 'course_variance_fields';
  completionPercentage?: Maybe<Scalars['Float']>;
  frontPageOrder?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "course" */
export type Course_Variance_Order_By = {
  completionPercentage?: Maybe<Order_By>;
  frontPageOrder?: Maybe<Order_By>;
};

/** ordering argument of a cursor */
export enum Cursor_Ordering {
  /** ascending ordering of the cursor */
  Asc = 'ASC',
  /** descending ordering of the cursor */
  Desc = 'DESC'
}

/** columns and relationships of "d_courseUser" */
export type D_CourseUser = {
  __typename?: 'd_courseUser';
  courseId?: Maybe<Scalars['uuid']>;
  userId?: Maybe<Scalars['uuid']>;
};

/** columns and relationships of "d_courseUserRefresh" */
export type D_CourseUserRefresh = {
  __typename?: 'd_courseUserRefresh';
  comment?: Maybe<Scalars['String']>;
  createdAt: Scalars['timestamptz'];
  id: Scalars['uuid'];
  status: Scalars['String'];
  updatedAt: Scalars['timestamptz'];
};

/** aggregated selection of "d_courseUserRefresh" */
export type D_CourseUserRefresh_Aggregate = {
  __typename?: 'd_courseUserRefresh_aggregate';
  aggregate?: Maybe<D_CourseUserRefresh_Aggregate_Fields>;
  nodes: Array<D_CourseUserRefresh>;
};

/** aggregate fields of "d_courseUserRefresh" */
export type D_CourseUserRefresh_Aggregate_Fields = {
  __typename?: 'd_courseUserRefresh_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<D_CourseUserRefresh_Max_Fields>;
  min?: Maybe<D_CourseUserRefresh_Min_Fields>;
};


/** aggregate fields of "d_courseUserRefresh" */
export type D_CourseUserRefresh_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<D_CourseUserRefresh_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "d_courseUserRefresh". All fields are combined with a logical 'AND'. */
export type D_CourseUserRefresh_Bool_Exp = {
  _and?: Maybe<Array<D_CourseUserRefresh_Bool_Exp>>;
  _not?: Maybe<D_CourseUserRefresh_Bool_Exp>;
  _or?: Maybe<Array<D_CourseUserRefresh_Bool_Exp>>;
  comment?: Maybe<String_Comparison_Exp>;
  createdAt?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  status?: Maybe<String_Comparison_Exp>;
  updatedAt?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "d_courseUserRefresh" */
export enum D_CourseUserRefresh_Constraint {
  /** unique or primary key constraint on columns "id" */
  DCourseUserRefreshPkey = 'd_courseUserRefresh_pkey'
}

/** input type for inserting data into table "d_courseUserRefresh" */
export type D_CourseUserRefresh_Insert_Input = {
  comment?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  status?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type D_CourseUserRefresh_Max_Fields = {
  __typename?: 'd_courseUserRefresh_max_fields';
  comment?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  status?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type D_CourseUserRefresh_Min_Fields = {
  __typename?: 'd_courseUserRefresh_min_fields';
  comment?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  status?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "d_courseUserRefresh" */
export type D_CourseUserRefresh_Mutation_Response = {
  __typename?: 'd_courseUserRefresh_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<D_CourseUserRefresh>;
};

/** on_conflict condition type for table "d_courseUserRefresh" */
export type D_CourseUserRefresh_On_Conflict = {
  constraint: D_CourseUserRefresh_Constraint;
  update_columns?: Array<D_CourseUserRefresh_Update_Column>;
  where?: Maybe<D_CourseUserRefresh_Bool_Exp>;
};

/** Ordering options when selecting data from "d_courseUserRefresh". */
export type D_CourseUserRefresh_Order_By = {
  comment?: Maybe<Order_By>;
  createdAt?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  status?: Maybe<Order_By>;
  updatedAt?: Maybe<Order_By>;
};

/** primary key columns input for table: d_courseUserRefresh */
export type D_CourseUserRefresh_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "d_courseUserRefresh" */
export enum D_CourseUserRefresh_Select_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  Status = 'status',
  /** column name */
  UpdatedAt = 'updatedAt'
}

/** input type for updating data in table "d_courseUserRefresh" */
export type D_CourseUserRefresh_Set_Input = {
  comment?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  status?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "d_courseUserRefresh" */
export type D_CourseUserRefresh_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: D_CourseUserRefresh_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type D_CourseUserRefresh_Stream_Cursor_Value_Input = {
  comment?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  status?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** update columns of table "d_courseUserRefresh" */
export enum D_CourseUserRefresh_Update_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  Status = 'status',
  /** column name */
  UpdatedAt = 'updatedAt'
}

export type D_CourseUserRefresh_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<D_CourseUserRefresh_Set_Input>;
  /** filter the rows which have to be updated */
  where: D_CourseUserRefresh_Bool_Exp;
};

/** aggregated selection of "d_courseUser" */
export type D_CourseUser_Aggregate = {
  __typename?: 'd_courseUser_aggregate';
  aggregate?: Maybe<D_CourseUser_Aggregate_Fields>;
  nodes: Array<D_CourseUser>;
};

/** aggregate fields of "d_courseUser" */
export type D_CourseUser_Aggregate_Fields = {
  __typename?: 'd_courseUser_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<D_CourseUser_Max_Fields>;
  min?: Maybe<D_CourseUser_Min_Fields>;
};


/** aggregate fields of "d_courseUser" */
export type D_CourseUser_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<D_CourseUser_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "d_courseUser". All fields are combined with a logical 'AND'. */
export type D_CourseUser_Bool_Exp = {
  _and?: Maybe<Array<D_CourseUser_Bool_Exp>>;
  _not?: Maybe<D_CourseUser_Bool_Exp>;
  _or?: Maybe<Array<D_CourseUser_Bool_Exp>>;
  courseId?: Maybe<Uuid_Comparison_Exp>;
  userId?: Maybe<Uuid_Comparison_Exp>;
};

/** input type for inserting data into table "d_courseUser" */
export type D_CourseUser_Insert_Input = {
  courseId?: Maybe<Scalars['uuid']>;
  userId?: Maybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type D_CourseUser_Max_Fields = {
  __typename?: 'd_courseUser_max_fields';
  courseId?: Maybe<Scalars['uuid']>;
  userId?: Maybe<Scalars['uuid']>;
};

/** aggregate min on columns */
export type D_CourseUser_Min_Fields = {
  __typename?: 'd_courseUser_min_fields';
  courseId?: Maybe<Scalars['uuid']>;
  userId?: Maybe<Scalars['uuid']>;
};

/** input type for inserting object relation for remote table "d_courseUser" */
export type D_CourseUser_Obj_Rel_Insert_Input = {
  data: D_CourseUser_Insert_Input;
};

/** Ordering options when selecting data from "d_courseUser". */
export type D_CourseUser_Order_By = {
  courseId?: Maybe<Order_By>;
  userId?: Maybe<Order_By>;
};

/** select columns of table "d_courseUser" */
export enum D_CourseUser_Select_Column {
  /** column name */
  CourseId = 'courseId',
  /** column name */
  UserId = 'userId'
}

/** Streaming cursor of the table "d_courseUser" */
export type D_CourseUser_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: D_CourseUser_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type D_CourseUser_Stream_Cursor_Value_Input = {
  courseId?: Maybe<Scalars['uuid']>;
  userId?: Maybe<Scalars['uuid']>;
};

/** columns and relationships of "dataAccessRequest" */
export type DataAccessRequest = {
  __typename?: 'dataAccessRequest';
  created_at: Scalars['timestamptz'];
  /** An object relationship */
  dataAccessRequest_user: User;
  id: Scalars['uuid'];
  status: Scalars['String'];
  type: Scalars['String'];
  updateBy?: Maybe<Scalars['uuid']>;
  updated_at: Scalars['timestamptz'];
  userId: Scalars['uuid'];
};

/** aggregated selection of "dataAccessRequest" */
export type DataAccessRequest_Aggregate = {
  __typename?: 'dataAccessRequest_aggregate';
  aggregate?: Maybe<DataAccessRequest_Aggregate_Fields>;
  nodes: Array<DataAccessRequest>;
};

/** aggregate fields of "dataAccessRequest" */
export type DataAccessRequest_Aggregate_Fields = {
  __typename?: 'dataAccessRequest_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<DataAccessRequest_Max_Fields>;
  min?: Maybe<DataAccessRequest_Min_Fields>;
};


/** aggregate fields of "dataAccessRequest" */
export type DataAccessRequest_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<DataAccessRequest_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "dataAccessRequest". All fields are combined with a logical 'AND'. */
export type DataAccessRequest_Bool_Exp = {
  _and?: Maybe<Array<DataAccessRequest_Bool_Exp>>;
  _not?: Maybe<DataAccessRequest_Bool_Exp>;
  _or?: Maybe<Array<DataAccessRequest_Bool_Exp>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  dataAccessRequest_user?: Maybe<User_Bool_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  status?: Maybe<String_Comparison_Exp>;
  type?: Maybe<String_Comparison_Exp>;
  updateBy?: Maybe<Uuid_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
  userId?: Maybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "dataAccessRequest" */
export enum DataAccessRequest_Constraint {
  /** unique or primary key constraint on columns "id" */
  DataAccessRequestPkey = 'dataAccessRequest_pkey'
}

/** input type for inserting data into table "dataAccessRequest" */
export type DataAccessRequest_Insert_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  dataAccessRequest_user?: Maybe<User_Obj_Rel_Insert_Input>;
  id?: Maybe<Scalars['uuid']>;
  status?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  updateBy?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  userId?: Maybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type DataAccessRequest_Max_Fields = {
  __typename?: 'dataAccessRequest_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  status?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  updateBy?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  userId?: Maybe<Scalars['uuid']>;
};

/** aggregate min on columns */
export type DataAccessRequest_Min_Fields = {
  __typename?: 'dataAccessRequest_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  status?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  updateBy?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  userId?: Maybe<Scalars['uuid']>;
};

/** response of any mutation on the table "dataAccessRequest" */
export type DataAccessRequest_Mutation_Response = {
  __typename?: 'dataAccessRequest_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<DataAccessRequest>;
};

/** on_conflict condition type for table "dataAccessRequest" */
export type DataAccessRequest_On_Conflict = {
  constraint: DataAccessRequest_Constraint;
  update_columns?: Array<DataAccessRequest_Update_Column>;
  where?: Maybe<DataAccessRequest_Bool_Exp>;
};

/** Ordering options when selecting data from "dataAccessRequest". */
export type DataAccessRequest_Order_By = {
  created_at?: Maybe<Order_By>;
  dataAccessRequest_user?: Maybe<User_Order_By>;
  id?: Maybe<Order_By>;
  status?: Maybe<Order_By>;
  type?: Maybe<Order_By>;
  updateBy?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  userId?: Maybe<Order_By>;
};

/** primary key columns input for table: dataAccessRequest */
export type DataAccessRequest_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "dataAccessRequest" */
export enum DataAccessRequest_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Status = 'status',
  /** column name */
  Type = 'type',
  /** column name */
  UpdateBy = 'updateBy',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'userId'
}

/** input type for updating data in table "dataAccessRequest" */
export type DataAccessRequest_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  status?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  updateBy?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  userId?: Maybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "dataAccessRequest" */
export type DataAccessRequest_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: DataAccessRequest_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type DataAccessRequest_Stream_Cursor_Value_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  status?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  updateBy?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  userId?: Maybe<Scalars['uuid']>;
};

/** update columns of table "dataAccessRequest" */
export enum DataAccessRequest_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Status = 'status',
  /** column name */
  Type = 'type',
  /** column name */
  UpdateBy = 'updateBy',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'userId'
}

export type DataAccessRequest_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<DataAccessRequest_Set_Input>;
  /** filter the rows which have to be updated */
  where: DataAccessRequest_Bool_Exp;
};

/** columns and relationships of "direction" */
export type Direction = {
  __typename?: 'direction';
  code?: Maybe<Scalars['String']>;
  createdAt: Scalars['timestamptz'];
  id: Scalars['uuid'];
  label: Scalars['String'];
  /** A computed field, executes function "direction_name_unaccent" */
  unaccentLabel?: Maybe<Scalars['String']>;
  updatedAt: Scalars['timestamptz'];
};

/** aggregated selection of "direction" */
export type Direction_Aggregate = {
  __typename?: 'direction_aggregate';
  aggregate?: Maybe<Direction_Aggregate_Fields>;
  nodes: Array<Direction>;
};

/** aggregate fields of "direction" */
export type Direction_Aggregate_Fields = {
  __typename?: 'direction_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Direction_Max_Fields>;
  min?: Maybe<Direction_Min_Fields>;
};


/** aggregate fields of "direction" */
export type Direction_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Direction_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "direction". All fields are combined with a logical 'AND'. */
export type Direction_Bool_Exp = {
  _and?: Maybe<Array<Direction_Bool_Exp>>;
  _not?: Maybe<Direction_Bool_Exp>;
  _or?: Maybe<Array<Direction_Bool_Exp>>;
  code?: Maybe<String_Comparison_Exp>;
  createdAt?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  label?: Maybe<String_Comparison_Exp>;
  unaccentLabel?: Maybe<String_Comparison_Exp>;
  updatedAt?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "direction" */
export enum Direction_Constraint {
  /** unique or primary key constraint on columns "label" */
  DirectionNameKey = 'direction_name_key',
  /** unique or primary key constraint on columns "id" */
  DirectionPkey = 'direction_pkey'
}

/** input type for inserting data into table "direction" */
export type Direction_Insert_Input = {
  code?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  label?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Direction_Max_Fields = {
  __typename?: 'direction_max_fields';
  code?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  label?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Direction_Min_Fields = {
  __typename?: 'direction_min_fields';
  code?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  label?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "direction" */
export type Direction_Mutation_Response = {
  __typename?: 'direction_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Direction>;
};

/** input type for inserting object relation for remote table "direction" */
export type Direction_Obj_Rel_Insert_Input = {
  data: Direction_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Direction_On_Conflict>;
};

/** on_conflict condition type for table "direction" */
export type Direction_On_Conflict = {
  constraint: Direction_Constraint;
  update_columns?: Array<Direction_Update_Column>;
  where?: Maybe<Direction_Bool_Exp>;
};

/** Ordering options when selecting data from "direction". */
export type Direction_Order_By = {
  code?: Maybe<Order_By>;
  createdAt?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  label?: Maybe<Order_By>;
  unaccentLabel?: Maybe<Order_By>;
  updatedAt?: Maybe<Order_By>;
};

/** primary key columns input for table: direction */
export type Direction_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "direction" */
export enum Direction_Select_Column {
  /** column name */
  Code = 'code',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  Label = 'label',
  /** column name */
  UpdatedAt = 'updatedAt'
}

/** input type for updating data in table "direction" */
export type Direction_Set_Input = {
  code?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  label?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "direction" */
export type Direction_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Direction_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Direction_Stream_Cursor_Value_Input = {
  code?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  label?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** update columns of table "direction" */
export enum Direction_Update_Column {
  /** column name */
  Code = 'code',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  Label = 'label',
  /** column name */
  UpdatedAt = 'updatedAt'
}

export type Direction_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Direction_Set_Input>;
  /** filter the rows which have to be updated */
  where: Direction_Bool_Exp;
};

/** columns and relationships of "emailValidationToken" */
export type EmailValidationToken = {
  __typename?: 'emailValidationToken';
  createdAt: Scalars['timestamptz'];
  emailValidationToken: Scalars['String'];
  expiringAt: Scalars['timestamptz'];
  revokedAt?: Maybe<Scalars['timestamptz']>;
  userId: Scalars['uuid'];
};

/** aggregated selection of "emailValidationToken" */
export type EmailValidationToken_Aggregate = {
  __typename?: 'emailValidationToken_aggregate';
  aggregate?: Maybe<EmailValidationToken_Aggregate_Fields>;
  nodes: Array<EmailValidationToken>;
};

/** aggregate fields of "emailValidationToken" */
export type EmailValidationToken_Aggregate_Fields = {
  __typename?: 'emailValidationToken_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<EmailValidationToken_Max_Fields>;
  min?: Maybe<EmailValidationToken_Min_Fields>;
};


/** aggregate fields of "emailValidationToken" */
export type EmailValidationToken_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<EmailValidationToken_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "emailValidationToken". All fields are combined with a logical 'AND'. */
export type EmailValidationToken_Bool_Exp = {
  _and?: Maybe<Array<EmailValidationToken_Bool_Exp>>;
  _not?: Maybe<EmailValidationToken_Bool_Exp>;
  _or?: Maybe<Array<EmailValidationToken_Bool_Exp>>;
  createdAt?: Maybe<Timestamptz_Comparison_Exp>;
  emailValidationToken?: Maybe<String_Comparison_Exp>;
  expiringAt?: Maybe<Timestamptz_Comparison_Exp>;
  revokedAt?: Maybe<Timestamptz_Comparison_Exp>;
  userId?: Maybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "emailValidationToken" */
export enum EmailValidationToken_Constraint {
  /** unique or primary key constraint on columns "userId" */
  EmailValidationTokenPkey = 'emailValidationToken_pkey'
}

/** input type for inserting data into table "emailValidationToken" */
export type EmailValidationToken_Insert_Input = {
  createdAt?: Maybe<Scalars['timestamptz']>;
  emailValidationToken?: Maybe<Scalars['String']>;
  expiringAt?: Maybe<Scalars['timestamptz']>;
  revokedAt?: Maybe<Scalars['timestamptz']>;
  userId?: Maybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type EmailValidationToken_Max_Fields = {
  __typename?: 'emailValidationToken_max_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  emailValidationToken?: Maybe<Scalars['String']>;
  expiringAt?: Maybe<Scalars['timestamptz']>;
  revokedAt?: Maybe<Scalars['timestamptz']>;
  userId?: Maybe<Scalars['uuid']>;
};

/** aggregate min on columns */
export type EmailValidationToken_Min_Fields = {
  __typename?: 'emailValidationToken_min_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  emailValidationToken?: Maybe<Scalars['String']>;
  expiringAt?: Maybe<Scalars['timestamptz']>;
  revokedAt?: Maybe<Scalars['timestamptz']>;
  userId?: Maybe<Scalars['uuid']>;
};

/** response of any mutation on the table "emailValidationToken" */
export type EmailValidationToken_Mutation_Response = {
  __typename?: 'emailValidationToken_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<EmailValidationToken>;
};

/** on_conflict condition type for table "emailValidationToken" */
export type EmailValidationToken_On_Conflict = {
  constraint: EmailValidationToken_Constraint;
  update_columns?: Array<EmailValidationToken_Update_Column>;
  where?: Maybe<EmailValidationToken_Bool_Exp>;
};

/** Ordering options when selecting data from "emailValidationToken". */
export type EmailValidationToken_Order_By = {
  createdAt?: Maybe<Order_By>;
  emailValidationToken?: Maybe<Order_By>;
  expiringAt?: Maybe<Order_By>;
  revokedAt?: Maybe<Order_By>;
  userId?: Maybe<Order_By>;
};

/** primary key columns input for table: emailValidationToken */
export type EmailValidationToken_Pk_Columns_Input = {
  userId: Scalars['uuid'];
};

/** select columns of table "emailValidationToken" */
export enum EmailValidationToken_Select_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  EmailValidationToken = 'emailValidationToken',
  /** column name */
  ExpiringAt = 'expiringAt',
  /** column name */
  RevokedAt = 'revokedAt',
  /** column name */
  UserId = 'userId'
}

/** input type for updating data in table "emailValidationToken" */
export type EmailValidationToken_Set_Input = {
  createdAt?: Maybe<Scalars['timestamptz']>;
  emailValidationToken?: Maybe<Scalars['String']>;
  expiringAt?: Maybe<Scalars['timestamptz']>;
  revokedAt?: Maybe<Scalars['timestamptz']>;
  userId?: Maybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "emailValidationToken" */
export type EmailValidationToken_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: EmailValidationToken_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type EmailValidationToken_Stream_Cursor_Value_Input = {
  createdAt?: Maybe<Scalars['timestamptz']>;
  emailValidationToken?: Maybe<Scalars['String']>;
  expiringAt?: Maybe<Scalars['timestamptz']>;
  revokedAt?: Maybe<Scalars['timestamptz']>;
  userId?: Maybe<Scalars['uuid']>;
};

/** update columns of table "emailValidationToken" */
export enum EmailValidationToken_Update_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  EmailValidationToken = 'emailValidationToken',
  /** column name */
  ExpiringAt = 'expiringAt',
  /** column name */
  RevokedAt = 'revokedAt',
  /** column name */
  UserId = 'userId'
}

export type EmailValidationToken_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<EmailValidationToken_Set_Input>;
  /** filter the rows which have to be updated */
  where: EmailValidationToken_Bool_Exp;
};

/** columns and relationships of "fa" */
export type Fa = {
  __typename?: 'fa';
  code: Scalars['String'];
  createdAt: Scalars['timestamptz'];
  /** An object relationship */
  direction?: Maybe<Direction>;
  directionId?: Maybe<Scalars['uuid']>;
  id: Scalars['uuid'];
  label?: Maybe<Scalars['String']>;
  updatedAt: Scalars['timestamptz'];
};

/** aggregated selection of "fa" */
export type Fa_Aggregate = {
  __typename?: 'fa_aggregate';
  aggregate?: Maybe<Fa_Aggregate_Fields>;
  nodes: Array<Fa>;
};

/** aggregate fields of "fa" */
export type Fa_Aggregate_Fields = {
  __typename?: 'fa_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Fa_Max_Fields>;
  min?: Maybe<Fa_Min_Fields>;
};


/** aggregate fields of "fa" */
export type Fa_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Fa_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "fa". All fields are combined with a logical 'AND'. */
export type Fa_Bool_Exp = {
  _and?: Maybe<Array<Fa_Bool_Exp>>;
  _not?: Maybe<Fa_Bool_Exp>;
  _or?: Maybe<Array<Fa_Bool_Exp>>;
  code?: Maybe<String_Comparison_Exp>;
  createdAt?: Maybe<Timestamptz_Comparison_Exp>;
  direction?: Maybe<Direction_Bool_Exp>;
  directionId?: Maybe<Uuid_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  label?: Maybe<String_Comparison_Exp>;
  updatedAt?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "fa" */
export enum Fa_Constraint {
  /** unique or primary key constraint on columns "code" */
  FaNameKey = 'fa_name_key',
  /** unique or primary key constraint on columns "id" */
  FaPkey = 'fa_pkey'
}

/** input type for inserting data into table "fa" */
export type Fa_Insert_Input = {
  code?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  direction?: Maybe<Direction_Obj_Rel_Insert_Input>;
  directionId?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  label?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Fa_Max_Fields = {
  __typename?: 'fa_max_fields';
  code?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  directionId?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  label?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Fa_Min_Fields = {
  __typename?: 'fa_min_fields';
  code?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  directionId?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  label?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "fa" */
export type Fa_Mutation_Response = {
  __typename?: 'fa_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Fa>;
};

/** input type for inserting object relation for remote table "fa" */
export type Fa_Obj_Rel_Insert_Input = {
  data: Fa_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Fa_On_Conflict>;
};

/** on_conflict condition type for table "fa" */
export type Fa_On_Conflict = {
  constraint: Fa_Constraint;
  update_columns?: Array<Fa_Update_Column>;
  where?: Maybe<Fa_Bool_Exp>;
};

/** Ordering options when selecting data from "fa". */
export type Fa_Order_By = {
  code?: Maybe<Order_By>;
  createdAt?: Maybe<Order_By>;
  direction?: Maybe<Direction_Order_By>;
  directionId?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  label?: Maybe<Order_By>;
  updatedAt?: Maybe<Order_By>;
};

/** primary key columns input for table: fa */
export type Fa_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "fa" */
export enum Fa_Select_Column {
  /** column name */
  Code = 'code',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  DirectionId = 'directionId',
  /** column name */
  Id = 'id',
  /** column name */
  Label = 'label',
  /** column name */
  UpdatedAt = 'updatedAt'
}

/** input type for updating data in table "fa" */
export type Fa_Set_Input = {
  code?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  directionId?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  label?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "fa" */
export type Fa_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Fa_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Fa_Stream_Cursor_Value_Input = {
  code?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  directionId?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  label?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** update columns of table "fa" */
export enum Fa_Update_Column {
  /** column name */
  Code = 'code',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  DirectionId = 'directionId',
  /** column name */
  Id = 'id',
  /** column name */
  Label = 'label',
  /** column name */
  UpdatedAt = 'updatedAt'
}

export type Fa_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Fa_Set_Input>;
  /** filter the rows which have to be updated */
  where: Fa_Bool_Exp;
};

/** columns and relationships of "faq" */
export type Faq = {
  __typename?: 'faq';
  createdAt: Scalars['timestamptz'];
  id: Scalars['uuid'];
  language: Scalars['String'];
  order: Scalars['Int'];
  question: Scalars['String'];
  response: Scalars['String'];
  updatedAt: Scalars['timestamptz'];
};

/** aggregated selection of "faq" */
export type Faq_Aggregate = {
  __typename?: 'faq_aggregate';
  aggregate?: Maybe<Faq_Aggregate_Fields>;
  nodes: Array<Faq>;
};

/** aggregate fields of "faq" */
export type Faq_Aggregate_Fields = {
  __typename?: 'faq_aggregate_fields';
  avg?: Maybe<Faq_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Faq_Max_Fields>;
  min?: Maybe<Faq_Min_Fields>;
  stddev?: Maybe<Faq_Stddev_Fields>;
  stddev_pop?: Maybe<Faq_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Faq_Stddev_Samp_Fields>;
  sum?: Maybe<Faq_Sum_Fields>;
  var_pop?: Maybe<Faq_Var_Pop_Fields>;
  var_samp?: Maybe<Faq_Var_Samp_Fields>;
  variance?: Maybe<Faq_Variance_Fields>;
};


/** aggregate fields of "faq" */
export type Faq_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Faq_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Faq_Avg_Fields = {
  __typename?: 'faq_avg_fields';
  order?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "faq". All fields are combined with a logical 'AND'. */
export type Faq_Bool_Exp = {
  _and?: Maybe<Array<Faq_Bool_Exp>>;
  _not?: Maybe<Faq_Bool_Exp>;
  _or?: Maybe<Array<Faq_Bool_Exp>>;
  createdAt?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  language?: Maybe<String_Comparison_Exp>;
  order?: Maybe<Int_Comparison_Exp>;
  question?: Maybe<String_Comparison_Exp>;
  response?: Maybe<String_Comparison_Exp>;
  updatedAt?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "faq" */
export enum Faq_Constraint {
  /** unique or primary key constraint on columns "id" */
  FaqPkey = 'faq_pkey'
}

/** input type for incrementing numeric columns in table "faq" */
export type Faq_Inc_Input = {
  order?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "faq" */
export type Faq_Insert_Input = {
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  language?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  question?: Maybe<Scalars['String']>;
  response?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Faq_Max_Fields = {
  __typename?: 'faq_max_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  language?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  question?: Maybe<Scalars['String']>;
  response?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Faq_Min_Fields = {
  __typename?: 'faq_min_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  language?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  question?: Maybe<Scalars['String']>;
  response?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "faq" */
export type Faq_Mutation_Response = {
  __typename?: 'faq_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Faq>;
};

/** on_conflict condition type for table "faq" */
export type Faq_On_Conflict = {
  constraint: Faq_Constraint;
  update_columns?: Array<Faq_Update_Column>;
  where?: Maybe<Faq_Bool_Exp>;
};

/** Ordering options when selecting data from "faq". */
export type Faq_Order_By = {
  createdAt?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  language?: Maybe<Order_By>;
  order?: Maybe<Order_By>;
  question?: Maybe<Order_By>;
  response?: Maybe<Order_By>;
  updatedAt?: Maybe<Order_By>;
};

/** primary key columns input for table: faq */
export type Faq_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "faq" */
export enum Faq_Select_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  Language = 'language',
  /** column name */
  Order = 'order',
  /** column name */
  Question = 'question',
  /** column name */
  Response = 'response',
  /** column name */
  UpdatedAt = 'updatedAt'
}

/** input type for updating data in table "faq" */
export type Faq_Set_Input = {
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  language?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  question?: Maybe<Scalars['String']>;
  response?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Faq_Stddev_Fields = {
  __typename?: 'faq_stddev_fields';
  order?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Faq_Stddev_Pop_Fields = {
  __typename?: 'faq_stddev_pop_fields';
  order?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Faq_Stddev_Samp_Fields = {
  __typename?: 'faq_stddev_samp_fields';
  order?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "faq" */
export type Faq_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Faq_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Faq_Stream_Cursor_Value_Input = {
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  language?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  question?: Maybe<Scalars['String']>;
  response?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type Faq_Sum_Fields = {
  __typename?: 'faq_sum_fields';
  order?: Maybe<Scalars['Int']>;
};

/** update columns of table "faq" */
export enum Faq_Update_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  Language = 'language',
  /** column name */
  Order = 'order',
  /** column name */
  Question = 'question',
  /** column name */
  Response = 'response',
  /** column name */
  UpdatedAt = 'updatedAt'
}

export type Faq_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<Faq_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Faq_Set_Input>;
  /** filter the rows which have to be updated */
  where: Faq_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Faq_Var_Pop_Fields = {
  __typename?: 'faq_var_pop_fields';
  order?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Faq_Var_Samp_Fields = {
  __typename?: 'faq_var_samp_fields';
  order?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Faq_Variance_Fields = {
  __typename?: 'faq_variance_fields';
  order?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "group" */
export type Group = {
  __typename?: 'group';
  createdAt: Scalars['timestamptz'];
  description?: Maybe<Scalars['String']>;
  humanId: Scalars['String'];
  id: Scalars['uuid'];
  name: Scalars['String'];
  status: Scalars['String'];
  /** A computed field, executes function "group_name_unaccent" */
  unaccentName?: Maybe<Scalars['String']>;
  updatedAt: Scalars['timestamptz'];
  /** An array relationship */
  userGroups: Array<UserGroup>;
  /** An aggregate relationship */
  userGroups_aggregate: UserGroup_Aggregate;
};


/** columns and relationships of "group" */
export type GroupUserGroupsArgs = {
  distinct_on?: Maybe<Array<UserGroup_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<UserGroup_Order_By>>;
  where?: Maybe<UserGroup_Bool_Exp>;
};


/** columns and relationships of "group" */
export type GroupUserGroups_AggregateArgs = {
  distinct_on?: Maybe<Array<UserGroup_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<UserGroup_Order_By>>;
  where?: Maybe<UserGroup_Bool_Exp>;
};

/** aggregated selection of "group" */
export type Group_Aggregate = {
  __typename?: 'group_aggregate';
  aggregate?: Maybe<Group_Aggregate_Fields>;
  nodes: Array<Group>;
};

/** aggregate fields of "group" */
export type Group_Aggregate_Fields = {
  __typename?: 'group_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Group_Max_Fields>;
  min?: Maybe<Group_Min_Fields>;
};


/** aggregate fields of "group" */
export type Group_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Group_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "group". All fields are combined with a logical 'AND'. */
export type Group_Bool_Exp = {
  _and?: Maybe<Array<Group_Bool_Exp>>;
  _not?: Maybe<Group_Bool_Exp>;
  _or?: Maybe<Array<Group_Bool_Exp>>;
  createdAt?: Maybe<Timestamptz_Comparison_Exp>;
  description?: Maybe<String_Comparison_Exp>;
  humanId?: Maybe<String_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
  status?: Maybe<String_Comparison_Exp>;
  unaccentName?: Maybe<String_Comparison_Exp>;
  updatedAt?: Maybe<Timestamptz_Comparison_Exp>;
  userGroups?: Maybe<UserGroup_Bool_Exp>;
  userGroups_aggregate?: Maybe<UserGroup_Aggregate_Bool_Exp>;
};

/** unique or primary key constraints on table "group" */
export enum Group_Constraint {
  /** unique or primary key constraint on columns "humanId" */
  GroupHumanIdKey = 'group_humanId_key',
  /** unique or primary key constraint on columns "id" */
  GroupPkey = 'group_pkey'
}

/** input type for inserting data into table "group" */
export type Group_Insert_Input = {
  createdAt?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  humanId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  userGroups?: Maybe<UserGroup_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Group_Max_Fields = {
  __typename?: 'group_max_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  humanId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Group_Min_Fields = {
  __typename?: 'group_min_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  humanId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "group" */
export type Group_Mutation_Response = {
  __typename?: 'group_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Group>;
};

/** input type for inserting object relation for remote table "group" */
export type Group_Obj_Rel_Insert_Input = {
  data: Group_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Group_On_Conflict>;
};

/** on_conflict condition type for table "group" */
export type Group_On_Conflict = {
  constraint: Group_Constraint;
  update_columns?: Array<Group_Update_Column>;
  where?: Maybe<Group_Bool_Exp>;
};

/** Ordering options when selecting data from "group". */
export type Group_Order_By = {
  createdAt?: Maybe<Order_By>;
  description?: Maybe<Order_By>;
  humanId?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  status?: Maybe<Order_By>;
  unaccentName?: Maybe<Order_By>;
  updatedAt?: Maybe<Order_By>;
  userGroups_aggregate?: Maybe<UserGroup_Aggregate_Order_By>;
};

/** primary key columns input for table: group */
export type Group_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "group" */
export enum Group_Select_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Description = 'description',
  /** column name */
  HumanId = 'humanId',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  Status = 'status',
  /** column name */
  UpdatedAt = 'updatedAt'
}

/** input type for updating data in table "group" */
export type Group_Set_Input = {
  createdAt?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  humanId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "group" */
export type Group_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Group_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Group_Stream_Cursor_Value_Input = {
  createdAt?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  humanId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** update columns of table "group" */
export enum Group_Update_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Description = 'description',
  /** column name */
  HumanId = 'humanId',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  Status = 'status',
  /** column name */
  UpdatedAt = 'updatedAt'
}

export type Group_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Group_Set_Input>;
  /** filter the rows which have to be updated */
  where: Group_Bool_Exp;
};

/** columns and relationships of "home" */
export type Home = {
  __typename?: 'home';
  createdAt: Scalars['timestamptz'];
  desktopImageUri: Scalars['String'];
  /** An object relationship */
  i18n: I18n;
  id: Scalars['uuid'];
  mobileImageUri: Scalars['String'];
  titleI18nId: Scalars['uuid'];
  updatedAt: Scalars['timestamptz'];
  updatedBy?: Maybe<Scalars['uuid']>;
};

/** aggregated selection of "home" */
export type Home_Aggregate = {
  __typename?: 'home_aggregate';
  aggregate?: Maybe<Home_Aggregate_Fields>;
  nodes: Array<Home>;
};

/** aggregate fields of "home" */
export type Home_Aggregate_Fields = {
  __typename?: 'home_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Home_Max_Fields>;
  min?: Maybe<Home_Min_Fields>;
};


/** aggregate fields of "home" */
export type Home_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Home_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "home". All fields are combined with a logical 'AND'. */
export type Home_Bool_Exp = {
  _and?: Maybe<Array<Home_Bool_Exp>>;
  _not?: Maybe<Home_Bool_Exp>;
  _or?: Maybe<Array<Home_Bool_Exp>>;
  createdAt?: Maybe<Timestamptz_Comparison_Exp>;
  desktopImageUri?: Maybe<String_Comparison_Exp>;
  i18n?: Maybe<I18n_Bool_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  mobileImageUri?: Maybe<String_Comparison_Exp>;
  titleI18nId?: Maybe<Uuid_Comparison_Exp>;
  updatedAt?: Maybe<Timestamptz_Comparison_Exp>;
  updatedBy?: Maybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "home" */
export enum Home_Constraint {
  /** unique or primary key constraint on columns "id" */
  HomePkey = 'home_pkey'
}

/** input type for inserting data into table "home" */
export type Home_Insert_Input = {
  createdAt?: Maybe<Scalars['timestamptz']>;
  desktopImageUri?: Maybe<Scalars['String']>;
  i18n?: Maybe<I18n_Obj_Rel_Insert_Input>;
  id?: Maybe<Scalars['uuid']>;
  mobileImageUri?: Maybe<Scalars['String']>;
  titleI18nId?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  updatedBy?: Maybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Home_Max_Fields = {
  __typename?: 'home_max_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  desktopImageUri?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  mobileImageUri?: Maybe<Scalars['String']>;
  titleI18nId?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  updatedBy?: Maybe<Scalars['uuid']>;
};

/** aggregate min on columns */
export type Home_Min_Fields = {
  __typename?: 'home_min_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  desktopImageUri?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  mobileImageUri?: Maybe<Scalars['String']>;
  titleI18nId?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  updatedBy?: Maybe<Scalars['uuid']>;
};

/** response of any mutation on the table "home" */
export type Home_Mutation_Response = {
  __typename?: 'home_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Home>;
};

/** on_conflict condition type for table "home" */
export type Home_On_Conflict = {
  constraint: Home_Constraint;
  update_columns?: Array<Home_Update_Column>;
  where?: Maybe<Home_Bool_Exp>;
};

/** Ordering options when selecting data from "home". */
export type Home_Order_By = {
  createdAt?: Maybe<Order_By>;
  desktopImageUri?: Maybe<Order_By>;
  i18n?: Maybe<I18n_Order_By>;
  id?: Maybe<Order_By>;
  mobileImageUri?: Maybe<Order_By>;
  titleI18nId?: Maybe<Order_By>;
  updatedAt?: Maybe<Order_By>;
  updatedBy?: Maybe<Order_By>;
};

/** primary key columns input for table: home */
export type Home_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "home" */
export enum Home_Select_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  DesktopImageUri = 'desktopImageUri',
  /** column name */
  Id = 'id',
  /** column name */
  MobileImageUri = 'mobileImageUri',
  /** column name */
  TitleI18nId = 'titleI18nId',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  UpdatedBy = 'updatedBy'
}

/** input type for updating data in table "home" */
export type Home_Set_Input = {
  createdAt?: Maybe<Scalars['timestamptz']>;
  desktopImageUri?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  mobileImageUri?: Maybe<Scalars['String']>;
  titleI18nId?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  updatedBy?: Maybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "home" */
export type Home_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Home_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Home_Stream_Cursor_Value_Input = {
  createdAt?: Maybe<Scalars['timestamptz']>;
  desktopImageUri?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  mobileImageUri?: Maybe<Scalars['String']>;
  titleI18nId?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  updatedBy?: Maybe<Scalars['uuid']>;
};

/** update columns of table "home" */
export enum Home_Update_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  DesktopImageUri = 'desktopImageUri',
  /** column name */
  Id = 'id',
  /** column name */
  MobileImageUri = 'mobileImageUri',
  /** column name */
  TitleI18nId = 'titleI18nId',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  UpdatedBy = 'updatedBy'
}

export type Home_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Home_Set_Input>;
  /** filter the rows which have to be updated */
  where: Home_Bool_Exp;
};

/** columns and relationships of "i18n" */
export type I18n = {
  __typename?: 'i18n';
  createdAt?: Maybe<Scalars['timestamptz']>;
  id: Scalars['uuid'];
  /** An array relationship */
  translations: Array<Translation>;
  /** An aggregate relationship */
  translations_aggregate: Translation_Aggregate;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};


/** columns and relationships of "i18n" */
export type I18nTranslationsArgs = {
  distinct_on?: Maybe<Array<Translation_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Translation_Order_By>>;
  where?: Maybe<Translation_Bool_Exp>;
};


/** columns and relationships of "i18n" */
export type I18nTranslations_AggregateArgs = {
  distinct_on?: Maybe<Array<Translation_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Translation_Order_By>>;
  where?: Maybe<Translation_Bool_Exp>;
};

/** aggregated selection of "i18n" */
export type I18n_Aggregate = {
  __typename?: 'i18n_aggregate';
  aggregate?: Maybe<I18n_Aggregate_Fields>;
  nodes: Array<I18n>;
};

/** aggregate fields of "i18n" */
export type I18n_Aggregate_Fields = {
  __typename?: 'i18n_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<I18n_Max_Fields>;
  min?: Maybe<I18n_Min_Fields>;
};


/** aggregate fields of "i18n" */
export type I18n_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<I18n_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "i18n". All fields are combined with a logical 'AND'. */
export type I18n_Bool_Exp = {
  _and?: Maybe<Array<I18n_Bool_Exp>>;
  _not?: Maybe<I18n_Bool_Exp>;
  _or?: Maybe<Array<I18n_Bool_Exp>>;
  createdAt?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  translations?: Maybe<Translation_Bool_Exp>;
  translations_aggregate?: Maybe<Translation_Aggregate_Bool_Exp>;
  updatedAt?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "i18n" */
export enum I18n_Constraint {
  /** unique or primary key constraint on columns "id" */
  PkI18n = 'PK_i18n'
}

/** input type for inserting data into table "i18n" */
export type I18n_Insert_Input = {
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  translations?: Maybe<Translation_Arr_Rel_Insert_Input>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type I18n_Max_Fields = {
  __typename?: 'i18n_max_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type I18n_Min_Fields = {
  __typename?: 'i18n_min_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "i18n" */
export type I18n_Mutation_Response = {
  __typename?: 'i18n_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<I18n>;
};

/** input type for inserting object relation for remote table "i18n" */
export type I18n_Obj_Rel_Insert_Input = {
  data: I18n_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<I18n_On_Conflict>;
};

/** on_conflict condition type for table "i18n" */
export type I18n_On_Conflict = {
  constraint: I18n_Constraint;
  update_columns?: Array<I18n_Update_Column>;
  where?: Maybe<I18n_Bool_Exp>;
};

/** Ordering options when selecting data from "i18n". */
export type I18n_Order_By = {
  createdAt?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  translations_aggregate?: Maybe<Translation_Aggregate_Order_By>;
  updatedAt?: Maybe<Order_By>;
};

/** primary key columns input for table: i18n */
export type I18n_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "i18n" */
export enum I18n_Select_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updatedAt'
}

/** input type for updating data in table "i18n" */
export type I18n_Set_Input = {
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "i18n" */
export type I18n_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: I18n_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type I18n_Stream_Cursor_Value_Input = {
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** update columns of table "i18n" */
export enum I18n_Update_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updatedAt'
}

export type I18n_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<I18n_Set_Input>;
  /** filter the rows which have to be updated */
  where: I18n_Bool_Exp;
};

/** columns and relationships of "identity" */
export type Identity = {
  __typename?: 'identity';
  createdAt: Scalars['timestamptz'];
  failedLoginAttempts: Scalars['numeric'];
  id: Scalars['uuid'];
  login?: Maybe<Scalars['String']>;
  passwordHash: Scalars['String'];
  /** An array relationship */
  passwordResetTokens: Array<PasswordResetToken>;
  /** An aggregate relationship */
  passwordResetTokens_aggregate: PasswordResetToken_Aggregate;
  providerName?: Maybe<Scalars['String']>;
  /** An array relationship */
  refreshTokens: Array<RefreshToken>;
  /** An aggregate relationship */
  refreshTokens_aggregate: RefreshToken_Aggregate;
  updatedAt: Scalars['timestamptz'];
  /** An object relationship */
  user: User;
  userId: Scalars['uuid'];
};


/** columns and relationships of "identity" */
export type IdentityPasswordResetTokensArgs = {
  distinct_on?: Maybe<Array<PasswordResetToken_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<PasswordResetToken_Order_By>>;
  where?: Maybe<PasswordResetToken_Bool_Exp>;
};


/** columns and relationships of "identity" */
export type IdentityPasswordResetTokens_AggregateArgs = {
  distinct_on?: Maybe<Array<PasswordResetToken_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<PasswordResetToken_Order_By>>;
  where?: Maybe<PasswordResetToken_Bool_Exp>;
};


/** columns and relationships of "identity" */
export type IdentityRefreshTokensArgs = {
  distinct_on?: Maybe<Array<RefreshToken_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<RefreshToken_Order_By>>;
  where?: Maybe<RefreshToken_Bool_Exp>;
};


/** columns and relationships of "identity" */
export type IdentityRefreshTokens_AggregateArgs = {
  distinct_on?: Maybe<Array<RefreshToken_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<RefreshToken_Order_By>>;
  where?: Maybe<RefreshToken_Bool_Exp>;
};

/** aggregated selection of "identity" */
export type Identity_Aggregate = {
  __typename?: 'identity_aggregate';
  aggregate?: Maybe<Identity_Aggregate_Fields>;
  nodes: Array<Identity>;
};

/** aggregate fields of "identity" */
export type Identity_Aggregate_Fields = {
  __typename?: 'identity_aggregate_fields';
  avg?: Maybe<Identity_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Identity_Max_Fields>;
  min?: Maybe<Identity_Min_Fields>;
  stddev?: Maybe<Identity_Stddev_Fields>;
  stddev_pop?: Maybe<Identity_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Identity_Stddev_Samp_Fields>;
  sum?: Maybe<Identity_Sum_Fields>;
  var_pop?: Maybe<Identity_Var_Pop_Fields>;
  var_samp?: Maybe<Identity_Var_Samp_Fields>;
  variance?: Maybe<Identity_Variance_Fields>;
};


/** aggregate fields of "identity" */
export type Identity_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Identity_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Identity_Avg_Fields = {
  __typename?: 'identity_avg_fields';
  failedLoginAttempts?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "identity". All fields are combined with a logical 'AND'. */
export type Identity_Bool_Exp = {
  _and?: Maybe<Array<Identity_Bool_Exp>>;
  _not?: Maybe<Identity_Bool_Exp>;
  _or?: Maybe<Array<Identity_Bool_Exp>>;
  createdAt?: Maybe<Timestamptz_Comparison_Exp>;
  failedLoginAttempts?: Maybe<Numeric_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  login?: Maybe<String_Comparison_Exp>;
  passwordHash?: Maybe<String_Comparison_Exp>;
  passwordResetTokens?: Maybe<PasswordResetToken_Bool_Exp>;
  passwordResetTokens_aggregate?: Maybe<PasswordResetToken_Aggregate_Bool_Exp>;
  providerName?: Maybe<String_Comparison_Exp>;
  refreshTokens?: Maybe<RefreshToken_Bool_Exp>;
  refreshTokens_aggregate?: Maybe<RefreshToken_Aggregate_Bool_Exp>;
  updatedAt?: Maybe<Timestamptz_Comparison_Exp>;
  user?: Maybe<User_Bool_Exp>;
  userId?: Maybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "identity" */
export enum Identity_Constraint {
  /** unique or primary key constraint on columns "login" */
  IdentityLoginKey = 'identity_login_key',
  /** unique or primary key constraint on columns "id" */
  IdentityPkey = 'identity_pkey'
}

/** input type for incrementing numeric columns in table "identity" */
export type Identity_Inc_Input = {
  failedLoginAttempts?: Maybe<Scalars['numeric']>;
};

/** input type for inserting data into table "identity" */
export type Identity_Insert_Input = {
  createdAt?: Maybe<Scalars['timestamptz']>;
  failedLoginAttempts?: Maybe<Scalars['numeric']>;
  id?: Maybe<Scalars['uuid']>;
  login?: Maybe<Scalars['String']>;
  passwordHash?: Maybe<Scalars['String']>;
  passwordResetTokens?: Maybe<PasswordResetToken_Arr_Rel_Insert_Input>;
  providerName?: Maybe<Scalars['String']>;
  refreshTokens?: Maybe<RefreshToken_Arr_Rel_Insert_Input>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  user?: Maybe<User_Obj_Rel_Insert_Input>;
  userId?: Maybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Identity_Max_Fields = {
  __typename?: 'identity_max_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  failedLoginAttempts?: Maybe<Scalars['numeric']>;
  id?: Maybe<Scalars['uuid']>;
  login?: Maybe<Scalars['String']>;
  passwordHash?: Maybe<Scalars['String']>;
  providerName?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  userId?: Maybe<Scalars['uuid']>;
};

/** aggregate min on columns */
export type Identity_Min_Fields = {
  __typename?: 'identity_min_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  failedLoginAttempts?: Maybe<Scalars['numeric']>;
  id?: Maybe<Scalars['uuid']>;
  login?: Maybe<Scalars['String']>;
  passwordHash?: Maybe<Scalars['String']>;
  providerName?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  userId?: Maybe<Scalars['uuid']>;
};

/** response of any mutation on the table "identity" */
export type Identity_Mutation_Response = {
  __typename?: 'identity_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Identity>;
};

/** input type for inserting object relation for remote table "identity" */
export type Identity_Obj_Rel_Insert_Input = {
  data: Identity_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Identity_On_Conflict>;
};

/** on_conflict condition type for table "identity" */
export type Identity_On_Conflict = {
  constraint: Identity_Constraint;
  update_columns?: Array<Identity_Update_Column>;
  where?: Maybe<Identity_Bool_Exp>;
};

/** Ordering options when selecting data from "identity". */
export type Identity_Order_By = {
  createdAt?: Maybe<Order_By>;
  failedLoginAttempts?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  login?: Maybe<Order_By>;
  passwordHash?: Maybe<Order_By>;
  passwordResetTokens_aggregate?: Maybe<PasswordResetToken_Aggregate_Order_By>;
  providerName?: Maybe<Order_By>;
  refreshTokens_aggregate?: Maybe<RefreshToken_Aggregate_Order_By>;
  updatedAt?: Maybe<Order_By>;
  user?: Maybe<User_Order_By>;
  userId?: Maybe<Order_By>;
};

/** primary key columns input for table: identity */
export type Identity_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "identity" */
export enum Identity_Select_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  FailedLoginAttempts = 'failedLoginAttempts',
  /** column name */
  Id = 'id',
  /** column name */
  Login = 'login',
  /** column name */
  PasswordHash = 'passwordHash',
  /** column name */
  ProviderName = 'providerName',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  UserId = 'userId'
}

/** input type for updating data in table "identity" */
export type Identity_Set_Input = {
  createdAt?: Maybe<Scalars['timestamptz']>;
  failedLoginAttempts?: Maybe<Scalars['numeric']>;
  id?: Maybe<Scalars['uuid']>;
  login?: Maybe<Scalars['String']>;
  passwordHash?: Maybe<Scalars['String']>;
  providerName?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  userId?: Maybe<Scalars['uuid']>;
};

/** aggregate stddev on columns */
export type Identity_Stddev_Fields = {
  __typename?: 'identity_stddev_fields';
  failedLoginAttempts?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Identity_Stddev_Pop_Fields = {
  __typename?: 'identity_stddev_pop_fields';
  failedLoginAttempts?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Identity_Stddev_Samp_Fields = {
  __typename?: 'identity_stddev_samp_fields';
  failedLoginAttempts?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "identity" */
export type Identity_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Identity_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Identity_Stream_Cursor_Value_Input = {
  createdAt?: Maybe<Scalars['timestamptz']>;
  failedLoginAttempts?: Maybe<Scalars['numeric']>;
  id?: Maybe<Scalars['uuid']>;
  login?: Maybe<Scalars['String']>;
  passwordHash?: Maybe<Scalars['String']>;
  providerName?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  userId?: Maybe<Scalars['uuid']>;
};

/** aggregate sum on columns */
export type Identity_Sum_Fields = {
  __typename?: 'identity_sum_fields';
  failedLoginAttempts?: Maybe<Scalars['numeric']>;
};

/** update columns of table "identity" */
export enum Identity_Update_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  FailedLoginAttempts = 'failedLoginAttempts',
  /** column name */
  Id = 'id',
  /** column name */
  Login = 'login',
  /** column name */
  PasswordHash = 'passwordHash',
  /** column name */
  ProviderName = 'providerName',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  UserId = 'userId'
}

export type Identity_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<Identity_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Identity_Set_Input>;
  /** filter the rows which have to be updated */
  where: Identity_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Identity_Var_Pop_Fields = {
  __typename?: 'identity_var_pop_fields';
  failedLoginAttempts?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Identity_Var_Samp_Fields = {
  __typename?: 'identity_var_samp_fields';
  failedLoginAttempts?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Identity_Variance_Fields = {
  __typename?: 'identity_variance_fields';
  failedLoginAttempts?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "import" */
export type Import = {
  __typename?: 'import';
  addedUserAmount: Scalars['Int'];
  createdAt: Scalars['timestamptz'];
  /** An object relationship */
  creator: User;
  creatorId: Scalars['uuid'];
  deletedUserAmount: Scalars['Int'];
  endExecutionDate?: Maybe<Scalars['timestamptz']>;
  erroneousLineAmount: Scalars['Int'];
  executionDate?: Maybe<Scalars['timestamptz']>;
  id: Scalars['uuid'];
  importType?: Maybe<Scalars['String']>;
  modifiedUserAmount: Scalars['Int'];
  pathImportFile: Scalars['String'];
  pathReportFile?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  updatedAt: Scalars['timestamptz'];
  userAmount: Scalars['Int'];
};

/** columns and relationships of "importGroup" */
export type ImportGroup = {
  __typename?: 'importGroup';
  createdAt: Scalars['timestamptz'];
  /** An object relationship */
  creator: User;
  creatorId: Scalars['uuid'];
  erroneousLineAmount: Scalars['Int'];
  id: Scalars['uuid'];
  pathErrorFile?: Maybe<Scalars['String']>;
  pathImportFile: Scalars['String'];
  status?: Maybe<Scalars['String']>;
  updatedAt: Scalars['timestamptz'];
  userAmount: Scalars['Int'];
};

/** aggregated selection of "importGroup" */
export type ImportGroup_Aggregate = {
  __typename?: 'importGroup_aggregate';
  aggregate?: Maybe<ImportGroup_Aggregate_Fields>;
  nodes: Array<ImportGroup>;
};

/** aggregate fields of "importGroup" */
export type ImportGroup_Aggregate_Fields = {
  __typename?: 'importGroup_aggregate_fields';
  avg?: Maybe<ImportGroup_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<ImportGroup_Max_Fields>;
  min?: Maybe<ImportGroup_Min_Fields>;
  stddev?: Maybe<ImportGroup_Stddev_Fields>;
  stddev_pop?: Maybe<ImportGroup_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<ImportGroup_Stddev_Samp_Fields>;
  sum?: Maybe<ImportGroup_Sum_Fields>;
  var_pop?: Maybe<ImportGroup_Var_Pop_Fields>;
  var_samp?: Maybe<ImportGroup_Var_Samp_Fields>;
  variance?: Maybe<ImportGroup_Variance_Fields>;
};


/** aggregate fields of "importGroup" */
export type ImportGroup_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<ImportGroup_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type ImportGroup_Avg_Fields = {
  __typename?: 'importGroup_avg_fields';
  erroneousLineAmount?: Maybe<Scalars['Float']>;
  userAmount?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "importGroup". All fields are combined with a logical 'AND'. */
export type ImportGroup_Bool_Exp = {
  _and?: Maybe<Array<ImportGroup_Bool_Exp>>;
  _not?: Maybe<ImportGroup_Bool_Exp>;
  _or?: Maybe<Array<ImportGroup_Bool_Exp>>;
  createdAt?: Maybe<Timestamptz_Comparison_Exp>;
  creator?: Maybe<User_Bool_Exp>;
  creatorId?: Maybe<Uuid_Comparison_Exp>;
  erroneousLineAmount?: Maybe<Int_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  pathErrorFile?: Maybe<String_Comparison_Exp>;
  pathImportFile?: Maybe<String_Comparison_Exp>;
  status?: Maybe<String_Comparison_Exp>;
  updatedAt?: Maybe<Timestamptz_Comparison_Exp>;
  userAmount?: Maybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "importGroup" */
export enum ImportGroup_Constraint {
  /** unique or primary key constraint on columns "id" */
  ImportGroupPkey = 'importGroup_pkey'
}

/** input type for incrementing numeric columns in table "importGroup" */
export type ImportGroup_Inc_Input = {
  erroneousLineAmount?: Maybe<Scalars['Int']>;
  userAmount?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "importGroup" */
export type ImportGroup_Insert_Input = {
  createdAt?: Maybe<Scalars['timestamptz']>;
  creator?: Maybe<User_Obj_Rel_Insert_Input>;
  creatorId?: Maybe<Scalars['uuid']>;
  erroneousLineAmount?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['uuid']>;
  pathErrorFile?: Maybe<Scalars['String']>;
  pathImportFile?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  userAmount?: Maybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type ImportGroup_Max_Fields = {
  __typename?: 'importGroup_max_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  creatorId?: Maybe<Scalars['uuid']>;
  erroneousLineAmount?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['uuid']>;
  pathErrorFile?: Maybe<Scalars['String']>;
  pathImportFile?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  userAmount?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type ImportGroup_Min_Fields = {
  __typename?: 'importGroup_min_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  creatorId?: Maybe<Scalars['uuid']>;
  erroneousLineAmount?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['uuid']>;
  pathErrorFile?: Maybe<Scalars['String']>;
  pathImportFile?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  userAmount?: Maybe<Scalars['Int']>;
};

/** response of any mutation on the table "importGroup" */
export type ImportGroup_Mutation_Response = {
  __typename?: 'importGroup_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<ImportGroup>;
};

/** on_conflict condition type for table "importGroup" */
export type ImportGroup_On_Conflict = {
  constraint: ImportGroup_Constraint;
  update_columns?: Array<ImportGroup_Update_Column>;
  where?: Maybe<ImportGroup_Bool_Exp>;
};

/** Ordering options when selecting data from "importGroup". */
export type ImportGroup_Order_By = {
  createdAt?: Maybe<Order_By>;
  creator?: Maybe<User_Order_By>;
  creatorId?: Maybe<Order_By>;
  erroneousLineAmount?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  pathErrorFile?: Maybe<Order_By>;
  pathImportFile?: Maybe<Order_By>;
  status?: Maybe<Order_By>;
  updatedAt?: Maybe<Order_By>;
  userAmount?: Maybe<Order_By>;
};

/** primary key columns input for table: importGroup */
export type ImportGroup_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "importGroup" */
export enum ImportGroup_Select_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  CreatorId = 'creatorId',
  /** column name */
  ErroneousLineAmount = 'erroneousLineAmount',
  /** column name */
  Id = 'id',
  /** column name */
  PathErrorFile = 'pathErrorFile',
  /** column name */
  PathImportFile = 'pathImportFile',
  /** column name */
  Status = 'status',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  UserAmount = 'userAmount'
}

/** input type for updating data in table "importGroup" */
export type ImportGroup_Set_Input = {
  createdAt?: Maybe<Scalars['timestamptz']>;
  creatorId?: Maybe<Scalars['uuid']>;
  erroneousLineAmount?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['uuid']>;
  pathErrorFile?: Maybe<Scalars['String']>;
  pathImportFile?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  userAmount?: Maybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type ImportGroup_Stddev_Fields = {
  __typename?: 'importGroup_stddev_fields';
  erroneousLineAmount?: Maybe<Scalars['Float']>;
  userAmount?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type ImportGroup_Stddev_Pop_Fields = {
  __typename?: 'importGroup_stddev_pop_fields';
  erroneousLineAmount?: Maybe<Scalars['Float']>;
  userAmount?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type ImportGroup_Stddev_Samp_Fields = {
  __typename?: 'importGroup_stddev_samp_fields';
  erroneousLineAmount?: Maybe<Scalars['Float']>;
  userAmount?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "importGroup" */
export type ImportGroup_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: ImportGroup_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type ImportGroup_Stream_Cursor_Value_Input = {
  createdAt?: Maybe<Scalars['timestamptz']>;
  creatorId?: Maybe<Scalars['uuid']>;
  erroneousLineAmount?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['uuid']>;
  pathErrorFile?: Maybe<Scalars['String']>;
  pathImportFile?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  userAmount?: Maybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type ImportGroup_Sum_Fields = {
  __typename?: 'importGroup_sum_fields';
  erroneousLineAmount?: Maybe<Scalars['Int']>;
  userAmount?: Maybe<Scalars['Int']>;
};

/** update columns of table "importGroup" */
export enum ImportGroup_Update_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  CreatorId = 'creatorId',
  /** column name */
  ErroneousLineAmount = 'erroneousLineAmount',
  /** column name */
  Id = 'id',
  /** column name */
  PathErrorFile = 'pathErrorFile',
  /** column name */
  PathImportFile = 'pathImportFile',
  /** column name */
  Status = 'status',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  UserAmount = 'userAmount'
}

export type ImportGroup_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<ImportGroup_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<ImportGroup_Set_Input>;
  /** filter the rows which have to be updated */
  where: ImportGroup_Bool_Exp;
};

/** aggregate var_pop on columns */
export type ImportGroup_Var_Pop_Fields = {
  __typename?: 'importGroup_var_pop_fields';
  erroneousLineAmount?: Maybe<Scalars['Float']>;
  userAmount?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type ImportGroup_Var_Samp_Fields = {
  __typename?: 'importGroup_var_samp_fields';
  erroneousLineAmount?: Maybe<Scalars['Float']>;
  userAmount?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type ImportGroup_Variance_Fields = {
  __typename?: 'importGroup_variance_fields';
  erroneousLineAmount?: Maybe<Scalars['Float']>;
  userAmount?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "importSkill" */
export type ImportSkill = {
  __typename?: 'importSkill';
  addedSkillAmount: Scalars['Int'];
  createdAt: Scalars['timestamptz'];
  creatorId: Scalars['uuid'];
  erroneousLineAmount: Scalars['Int'];
  id: Scalars['uuid'];
  pathErrorFile?: Maybe<Scalars['String']>;
  pathImportFile: Scalars['String'];
  status?: Maybe<Scalars['String']>;
  updatedAt: Scalars['timestamptz'];
  updatedSkillAmount: Scalars['Int'];
};

/** aggregated selection of "importSkill" */
export type ImportSkill_Aggregate = {
  __typename?: 'importSkill_aggregate';
  aggregate?: Maybe<ImportSkill_Aggregate_Fields>;
  nodes: Array<ImportSkill>;
};

/** aggregate fields of "importSkill" */
export type ImportSkill_Aggregate_Fields = {
  __typename?: 'importSkill_aggregate_fields';
  avg?: Maybe<ImportSkill_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<ImportSkill_Max_Fields>;
  min?: Maybe<ImportSkill_Min_Fields>;
  stddev?: Maybe<ImportSkill_Stddev_Fields>;
  stddev_pop?: Maybe<ImportSkill_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<ImportSkill_Stddev_Samp_Fields>;
  sum?: Maybe<ImportSkill_Sum_Fields>;
  var_pop?: Maybe<ImportSkill_Var_Pop_Fields>;
  var_samp?: Maybe<ImportSkill_Var_Samp_Fields>;
  variance?: Maybe<ImportSkill_Variance_Fields>;
};


/** aggregate fields of "importSkill" */
export type ImportSkill_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<ImportSkill_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type ImportSkill_Avg_Fields = {
  __typename?: 'importSkill_avg_fields';
  addedSkillAmount?: Maybe<Scalars['Float']>;
  erroneousLineAmount?: Maybe<Scalars['Float']>;
  updatedSkillAmount?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "importSkill". All fields are combined with a logical 'AND'. */
export type ImportSkill_Bool_Exp = {
  _and?: Maybe<Array<ImportSkill_Bool_Exp>>;
  _not?: Maybe<ImportSkill_Bool_Exp>;
  _or?: Maybe<Array<ImportSkill_Bool_Exp>>;
  addedSkillAmount?: Maybe<Int_Comparison_Exp>;
  createdAt?: Maybe<Timestamptz_Comparison_Exp>;
  creatorId?: Maybe<Uuid_Comparison_Exp>;
  erroneousLineAmount?: Maybe<Int_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  pathErrorFile?: Maybe<String_Comparison_Exp>;
  pathImportFile?: Maybe<String_Comparison_Exp>;
  status?: Maybe<String_Comparison_Exp>;
  updatedAt?: Maybe<Timestamptz_Comparison_Exp>;
  updatedSkillAmount?: Maybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "importSkill" */
export enum ImportSkill_Constraint {
  /** unique or primary key constraint on columns "id" */
  ImportSkillPkey = 'importSkill_pkey'
}

/** input type for incrementing numeric columns in table "importSkill" */
export type ImportSkill_Inc_Input = {
  addedSkillAmount?: Maybe<Scalars['Int']>;
  erroneousLineAmount?: Maybe<Scalars['Int']>;
  updatedSkillAmount?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "importSkill" */
export type ImportSkill_Insert_Input = {
  addedSkillAmount?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  creatorId?: Maybe<Scalars['uuid']>;
  erroneousLineAmount?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['uuid']>;
  pathErrorFile?: Maybe<Scalars['String']>;
  pathImportFile?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  updatedSkillAmount?: Maybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type ImportSkill_Max_Fields = {
  __typename?: 'importSkill_max_fields';
  addedSkillAmount?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  creatorId?: Maybe<Scalars['uuid']>;
  erroneousLineAmount?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['uuid']>;
  pathErrorFile?: Maybe<Scalars['String']>;
  pathImportFile?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  updatedSkillAmount?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type ImportSkill_Min_Fields = {
  __typename?: 'importSkill_min_fields';
  addedSkillAmount?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  creatorId?: Maybe<Scalars['uuid']>;
  erroneousLineAmount?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['uuid']>;
  pathErrorFile?: Maybe<Scalars['String']>;
  pathImportFile?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  updatedSkillAmount?: Maybe<Scalars['Int']>;
};

/** response of any mutation on the table "importSkill" */
export type ImportSkill_Mutation_Response = {
  __typename?: 'importSkill_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<ImportSkill>;
};

/** on_conflict condition type for table "importSkill" */
export type ImportSkill_On_Conflict = {
  constraint: ImportSkill_Constraint;
  update_columns?: Array<ImportSkill_Update_Column>;
  where?: Maybe<ImportSkill_Bool_Exp>;
};

/** Ordering options when selecting data from "importSkill". */
export type ImportSkill_Order_By = {
  addedSkillAmount?: Maybe<Order_By>;
  createdAt?: Maybe<Order_By>;
  creatorId?: Maybe<Order_By>;
  erroneousLineAmount?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  pathErrorFile?: Maybe<Order_By>;
  pathImportFile?: Maybe<Order_By>;
  status?: Maybe<Order_By>;
  updatedAt?: Maybe<Order_By>;
  updatedSkillAmount?: Maybe<Order_By>;
};

/** primary key columns input for table: importSkill */
export type ImportSkill_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "importSkill" */
export enum ImportSkill_Select_Column {
  /** column name */
  AddedSkillAmount = 'addedSkillAmount',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  CreatorId = 'creatorId',
  /** column name */
  ErroneousLineAmount = 'erroneousLineAmount',
  /** column name */
  Id = 'id',
  /** column name */
  PathErrorFile = 'pathErrorFile',
  /** column name */
  PathImportFile = 'pathImportFile',
  /** column name */
  Status = 'status',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  UpdatedSkillAmount = 'updatedSkillAmount'
}

/** input type for updating data in table "importSkill" */
export type ImportSkill_Set_Input = {
  addedSkillAmount?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  creatorId?: Maybe<Scalars['uuid']>;
  erroneousLineAmount?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['uuid']>;
  pathErrorFile?: Maybe<Scalars['String']>;
  pathImportFile?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  updatedSkillAmount?: Maybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type ImportSkill_Stddev_Fields = {
  __typename?: 'importSkill_stddev_fields';
  addedSkillAmount?: Maybe<Scalars['Float']>;
  erroneousLineAmount?: Maybe<Scalars['Float']>;
  updatedSkillAmount?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type ImportSkill_Stddev_Pop_Fields = {
  __typename?: 'importSkill_stddev_pop_fields';
  addedSkillAmount?: Maybe<Scalars['Float']>;
  erroneousLineAmount?: Maybe<Scalars['Float']>;
  updatedSkillAmount?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type ImportSkill_Stddev_Samp_Fields = {
  __typename?: 'importSkill_stddev_samp_fields';
  addedSkillAmount?: Maybe<Scalars['Float']>;
  erroneousLineAmount?: Maybe<Scalars['Float']>;
  updatedSkillAmount?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "importSkill" */
export type ImportSkill_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: ImportSkill_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type ImportSkill_Stream_Cursor_Value_Input = {
  addedSkillAmount?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  creatorId?: Maybe<Scalars['uuid']>;
  erroneousLineAmount?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['uuid']>;
  pathErrorFile?: Maybe<Scalars['String']>;
  pathImportFile?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  updatedSkillAmount?: Maybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type ImportSkill_Sum_Fields = {
  __typename?: 'importSkill_sum_fields';
  addedSkillAmount?: Maybe<Scalars['Int']>;
  erroneousLineAmount?: Maybe<Scalars['Int']>;
  updatedSkillAmount?: Maybe<Scalars['Int']>;
};

/** update columns of table "importSkill" */
export enum ImportSkill_Update_Column {
  /** column name */
  AddedSkillAmount = 'addedSkillAmount',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  CreatorId = 'creatorId',
  /** column name */
  ErroneousLineAmount = 'erroneousLineAmount',
  /** column name */
  Id = 'id',
  /** column name */
  PathErrorFile = 'pathErrorFile',
  /** column name */
  PathImportFile = 'pathImportFile',
  /** column name */
  Status = 'status',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  UpdatedSkillAmount = 'updatedSkillAmount'
}

export type ImportSkill_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<ImportSkill_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<ImportSkill_Set_Input>;
  /** filter the rows which have to be updated */
  where: ImportSkill_Bool_Exp;
};

/** aggregate var_pop on columns */
export type ImportSkill_Var_Pop_Fields = {
  __typename?: 'importSkill_var_pop_fields';
  addedSkillAmount?: Maybe<Scalars['Float']>;
  erroneousLineAmount?: Maybe<Scalars['Float']>;
  updatedSkillAmount?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type ImportSkill_Var_Samp_Fields = {
  __typename?: 'importSkill_var_samp_fields';
  addedSkillAmount?: Maybe<Scalars['Float']>;
  erroneousLineAmount?: Maybe<Scalars['Float']>;
  updatedSkillAmount?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type ImportSkill_Variance_Fields = {
  __typename?: 'importSkill_variance_fields';
  addedSkillAmount?: Maybe<Scalars['Float']>;
  erroneousLineAmount?: Maybe<Scalars['Float']>;
  updatedSkillAmount?: Maybe<Scalars['Float']>;
};

/** aggregated selection of "import" */
export type Import_Aggregate = {
  __typename?: 'import_aggregate';
  aggregate?: Maybe<Import_Aggregate_Fields>;
  nodes: Array<Import>;
};

/** aggregate fields of "import" */
export type Import_Aggregate_Fields = {
  __typename?: 'import_aggregate_fields';
  avg?: Maybe<Import_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Import_Max_Fields>;
  min?: Maybe<Import_Min_Fields>;
  stddev?: Maybe<Import_Stddev_Fields>;
  stddev_pop?: Maybe<Import_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Import_Stddev_Samp_Fields>;
  sum?: Maybe<Import_Sum_Fields>;
  var_pop?: Maybe<Import_Var_Pop_Fields>;
  var_samp?: Maybe<Import_Var_Samp_Fields>;
  variance?: Maybe<Import_Variance_Fields>;
};


/** aggregate fields of "import" */
export type Import_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Import_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Import_Avg_Fields = {
  __typename?: 'import_avg_fields';
  addedUserAmount?: Maybe<Scalars['Float']>;
  deletedUserAmount?: Maybe<Scalars['Float']>;
  erroneousLineAmount?: Maybe<Scalars['Float']>;
  modifiedUserAmount?: Maybe<Scalars['Float']>;
  userAmount?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "import". All fields are combined with a logical 'AND'. */
export type Import_Bool_Exp = {
  _and?: Maybe<Array<Import_Bool_Exp>>;
  _not?: Maybe<Import_Bool_Exp>;
  _or?: Maybe<Array<Import_Bool_Exp>>;
  addedUserAmount?: Maybe<Int_Comparison_Exp>;
  createdAt?: Maybe<Timestamptz_Comparison_Exp>;
  creator?: Maybe<User_Bool_Exp>;
  creatorId?: Maybe<Uuid_Comparison_Exp>;
  deletedUserAmount?: Maybe<Int_Comparison_Exp>;
  endExecutionDate?: Maybe<Timestamptz_Comparison_Exp>;
  erroneousLineAmount?: Maybe<Int_Comparison_Exp>;
  executionDate?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  importType?: Maybe<String_Comparison_Exp>;
  modifiedUserAmount?: Maybe<Int_Comparison_Exp>;
  pathImportFile?: Maybe<String_Comparison_Exp>;
  pathReportFile?: Maybe<String_Comparison_Exp>;
  status?: Maybe<String_Comparison_Exp>;
  updatedAt?: Maybe<Timestamptz_Comparison_Exp>;
  userAmount?: Maybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "import" */
export enum Import_Constraint {
  /** unique or primary key constraint on columns "id" */
  ImportPkey = 'import_pkey'
}

/** input type for incrementing numeric columns in table "import" */
export type Import_Inc_Input = {
  addedUserAmount?: Maybe<Scalars['Int']>;
  deletedUserAmount?: Maybe<Scalars['Int']>;
  erroneousLineAmount?: Maybe<Scalars['Int']>;
  modifiedUserAmount?: Maybe<Scalars['Int']>;
  userAmount?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "import" */
export type Import_Insert_Input = {
  addedUserAmount?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  creator?: Maybe<User_Obj_Rel_Insert_Input>;
  creatorId?: Maybe<Scalars['uuid']>;
  deletedUserAmount?: Maybe<Scalars['Int']>;
  endExecutionDate?: Maybe<Scalars['timestamptz']>;
  erroneousLineAmount?: Maybe<Scalars['Int']>;
  executionDate?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  importType?: Maybe<Scalars['String']>;
  modifiedUserAmount?: Maybe<Scalars['Int']>;
  pathImportFile?: Maybe<Scalars['String']>;
  pathReportFile?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  userAmount?: Maybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type Import_Max_Fields = {
  __typename?: 'import_max_fields';
  addedUserAmount?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  creatorId?: Maybe<Scalars['uuid']>;
  deletedUserAmount?: Maybe<Scalars['Int']>;
  endExecutionDate?: Maybe<Scalars['timestamptz']>;
  erroneousLineAmount?: Maybe<Scalars['Int']>;
  executionDate?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  importType?: Maybe<Scalars['String']>;
  modifiedUserAmount?: Maybe<Scalars['Int']>;
  pathImportFile?: Maybe<Scalars['String']>;
  pathReportFile?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  userAmount?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type Import_Min_Fields = {
  __typename?: 'import_min_fields';
  addedUserAmount?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  creatorId?: Maybe<Scalars['uuid']>;
  deletedUserAmount?: Maybe<Scalars['Int']>;
  endExecutionDate?: Maybe<Scalars['timestamptz']>;
  erroneousLineAmount?: Maybe<Scalars['Int']>;
  executionDate?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  importType?: Maybe<Scalars['String']>;
  modifiedUserAmount?: Maybe<Scalars['Int']>;
  pathImportFile?: Maybe<Scalars['String']>;
  pathReportFile?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  userAmount?: Maybe<Scalars['Int']>;
};

/** response of any mutation on the table "import" */
export type Import_Mutation_Response = {
  __typename?: 'import_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Import>;
};

/** on_conflict condition type for table "import" */
export type Import_On_Conflict = {
  constraint: Import_Constraint;
  update_columns?: Array<Import_Update_Column>;
  where?: Maybe<Import_Bool_Exp>;
};

/** Ordering options when selecting data from "import". */
export type Import_Order_By = {
  addedUserAmount?: Maybe<Order_By>;
  createdAt?: Maybe<Order_By>;
  creator?: Maybe<User_Order_By>;
  creatorId?: Maybe<Order_By>;
  deletedUserAmount?: Maybe<Order_By>;
  endExecutionDate?: Maybe<Order_By>;
  erroneousLineAmount?: Maybe<Order_By>;
  executionDate?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  importType?: Maybe<Order_By>;
  modifiedUserAmount?: Maybe<Order_By>;
  pathImportFile?: Maybe<Order_By>;
  pathReportFile?: Maybe<Order_By>;
  status?: Maybe<Order_By>;
  updatedAt?: Maybe<Order_By>;
  userAmount?: Maybe<Order_By>;
};

/** primary key columns input for table: import */
export type Import_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "import" */
export enum Import_Select_Column {
  /** column name */
  AddedUserAmount = 'addedUserAmount',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  CreatorId = 'creatorId',
  /** column name */
  DeletedUserAmount = 'deletedUserAmount',
  /** column name */
  EndExecutionDate = 'endExecutionDate',
  /** column name */
  ErroneousLineAmount = 'erroneousLineAmount',
  /** column name */
  ExecutionDate = 'executionDate',
  /** column name */
  Id = 'id',
  /** column name */
  ImportType = 'importType',
  /** column name */
  ModifiedUserAmount = 'modifiedUserAmount',
  /** column name */
  PathImportFile = 'pathImportFile',
  /** column name */
  PathReportFile = 'pathReportFile',
  /** column name */
  Status = 'status',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  UserAmount = 'userAmount'
}

/** input type for updating data in table "import" */
export type Import_Set_Input = {
  addedUserAmount?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  creatorId?: Maybe<Scalars['uuid']>;
  deletedUserAmount?: Maybe<Scalars['Int']>;
  endExecutionDate?: Maybe<Scalars['timestamptz']>;
  erroneousLineAmount?: Maybe<Scalars['Int']>;
  executionDate?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  importType?: Maybe<Scalars['String']>;
  modifiedUserAmount?: Maybe<Scalars['Int']>;
  pathImportFile?: Maybe<Scalars['String']>;
  pathReportFile?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  userAmount?: Maybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Import_Stddev_Fields = {
  __typename?: 'import_stddev_fields';
  addedUserAmount?: Maybe<Scalars['Float']>;
  deletedUserAmount?: Maybe<Scalars['Float']>;
  erroneousLineAmount?: Maybe<Scalars['Float']>;
  modifiedUserAmount?: Maybe<Scalars['Float']>;
  userAmount?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Import_Stddev_Pop_Fields = {
  __typename?: 'import_stddev_pop_fields';
  addedUserAmount?: Maybe<Scalars['Float']>;
  deletedUserAmount?: Maybe<Scalars['Float']>;
  erroneousLineAmount?: Maybe<Scalars['Float']>;
  modifiedUserAmount?: Maybe<Scalars['Float']>;
  userAmount?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Import_Stddev_Samp_Fields = {
  __typename?: 'import_stddev_samp_fields';
  addedUserAmount?: Maybe<Scalars['Float']>;
  deletedUserAmount?: Maybe<Scalars['Float']>;
  erroneousLineAmount?: Maybe<Scalars['Float']>;
  modifiedUserAmount?: Maybe<Scalars['Float']>;
  userAmount?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "import" */
export type Import_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Import_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Import_Stream_Cursor_Value_Input = {
  addedUserAmount?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  creatorId?: Maybe<Scalars['uuid']>;
  deletedUserAmount?: Maybe<Scalars['Int']>;
  endExecutionDate?: Maybe<Scalars['timestamptz']>;
  erroneousLineAmount?: Maybe<Scalars['Int']>;
  executionDate?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  importType?: Maybe<Scalars['String']>;
  modifiedUserAmount?: Maybe<Scalars['Int']>;
  pathImportFile?: Maybe<Scalars['String']>;
  pathReportFile?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  userAmount?: Maybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type Import_Sum_Fields = {
  __typename?: 'import_sum_fields';
  addedUserAmount?: Maybe<Scalars['Int']>;
  deletedUserAmount?: Maybe<Scalars['Int']>;
  erroneousLineAmount?: Maybe<Scalars['Int']>;
  modifiedUserAmount?: Maybe<Scalars['Int']>;
  userAmount?: Maybe<Scalars['Int']>;
};

/** update columns of table "import" */
export enum Import_Update_Column {
  /** column name */
  AddedUserAmount = 'addedUserAmount',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  CreatorId = 'creatorId',
  /** column name */
  DeletedUserAmount = 'deletedUserAmount',
  /** column name */
  EndExecutionDate = 'endExecutionDate',
  /** column name */
  ErroneousLineAmount = 'erroneousLineAmount',
  /** column name */
  ExecutionDate = 'executionDate',
  /** column name */
  Id = 'id',
  /** column name */
  ImportType = 'importType',
  /** column name */
  ModifiedUserAmount = 'modifiedUserAmount',
  /** column name */
  PathImportFile = 'pathImportFile',
  /** column name */
  PathReportFile = 'pathReportFile',
  /** column name */
  Status = 'status',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  UserAmount = 'userAmount'
}

export type Import_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<Import_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Import_Set_Input>;
  /** filter the rows which have to be updated */
  where: Import_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Import_Var_Pop_Fields = {
  __typename?: 'import_var_pop_fields';
  addedUserAmount?: Maybe<Scalars['Float']>;
  deletedUserAmount?: Maybe<Scalars['Float']>;
  erroneousLineAmount?: Maybe<Scalars['Float']>;
  modifiedUserAmount?: Maybe<Scalars['Float']>;
  userAmount?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Import_Var_Samp_Fields = {
  __typename?: 'import_var_samp_fields';
  addedUserAmount?: Maybe<Scalars['Float']>;
  deletedUserAmount?: Maybe<Scalars['Float']>;
  erroneousLineAmount?: Maybe<Scalars['Float']>;
  modifiedUserAmount?: Maybe<Scalars['Float']>;
  userAmount?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Import_Variance_Fields = {
  __typename?: 'import_variance_fields';
  addedUserAmount?: Maybe<Scalars['Float']>;
  deletedUserAmount?: Maybe<Scalars['Float']>;
  erroneousLineAmount?: Maybe<Scalars['Float']>;
  modifiedUserAmount?: Maybe<Scalars['Float']>;
  userAmount?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "jobTitle" */
export type JobTitle = {
  __typename?: 'jobTitle';
  code?: Maybe<Scalars['String']>;
  createdAt: Scalars['timestamptz'];
  id: Scalars['uuid'];
  /** An object relationship */
  name: I18n;
  /** A computed field, executes function "jobtitle_name_i18n" */
  nameI18n?: Maybe<Scalars['String']>;
  nameI18nId: Scalars['uuid'];
  /** A computed field, executes function "jobtitle_name_unaccent" */
  unaccentNameI18n?: Maybe<Scalars['String']>;
  updatedAt: Scalars['timestamptz'];
  /** An array relationship */
  volunteerPosts: Array<VolunteerPost>;
  /** An aggregate relationship */
  volunteerPosts_aggregate: VolunteerPost_Aggregate;
};


/** columns and relationships of "jobTitle" */
export type JobTitleVolunteerPostsArgs = {
  distinct_on?: Maybe<Array<VolunteerPost_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<VolunteerPost_Order_By>>;
  where?: Maybe<VolunteerPost_Bool_Exp>;
};


/** columns and relationships of "jobTitle" */
export type JobTitleVolunteerPosts_AggregateArgs = {
  distinct_on?: Maybe<Array<VolunteerPost_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<VolunteerPost_Order_By>>;
  where?: Maybe<VolunteerPost_Bool_Exp>;
};

/** aggregated selection of "jobTitle" */
export type JobTitle_Aggregate = {
  __typename?: 'jobTitle_aggregate';
  aggregate?: Maybe<JobTitle_Aggregate_Fields>;
  nodes: Array<JobTitle>;
};

/** aggregate fields of "jobTitle" */
export type JobTitle_Aggregate_Fields = {
  __typename?: 'jobTitle_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<JobTitle_Max_Fields>;
  min?: Maybe<JobTitle_Min_Fields>;
};


/** aggregate fields of "jobTitle" */
export type JobTitle_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<JobTitle_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "jobTitle". All fields are combined with a logical 'AND'. */
export type JobTitle_Bool_Exp = {
  _and?: Maybe<Array<JobTitle_Bool_Exp>>;
  _not?: Maybe<JobTitle_Bool_Exp>;
  _or?: Maybe<Array<JobTitle_Bool_Exp>>;
  code?: Maybe<String_Comparison_Exp>;
  createdAt?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  name?: Maybe<I18n_Bool_Exp>;
  nameI18n?: Maybe<String_Comparison_Exp>;
  nameI18nId?: Maybe<Uuid_Comparison_Exp>;
  unaccentNameI18n?: Maybe<String_Comparison_Exp>;
  updatedAt?: Maybe<Timestamptz_Comparison_Exp>;
  volunteerPosts?: Maybe<VolunteerPost_Bool_Exp>;
  volunteerPosts_aggregate?: Maybe<VolunteerPost_Aggregate_Bool_Exp>;
};

/** unique or primary key constraints on table "jobTitle" */
export enum JobTitle_Constraint {
  /** unique or primary key constraint on columns "code" */
  JobTitleCodeKey = 'jobTitle_code_key',
  /** unique or primary key constraint on columns "id" */
  JobTitlePkey = 'jobTitle_pkey'
}

/** input type for inserting data into table "jobTitle" */
export type JobTitle_Insert_Input = {
  code?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<I18n_Obj_Rel_Insert_Input>;
  nameI18nId?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  volunteerPosts?: Maybe<VolunteerPost_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type JobTitle_Max_Fields = {
  __typename?: 'jobTitle_max_fields';
  code?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  nameI18nId?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type JobTitle_Min_Fields = {
  __typename?: 'jobTitle_min_fields';
  code?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  nameI18nId?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "jobTitle" */
export type JobTitle_Mutation_Response = {
  __typename?: 'jobTitle_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<JobTitle>;
};

/** input type for inserting object relation for remote table "jobTitle" */
export type JobTitle_Obj_Rel_Insert_Input = {
  data: JobTitle_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<JobTitle_On_Conflict>;
};

/** on_conflict condition type for table "jobTitle" */
export type JobTitle_On_Conflict = {
  constraint: JobTitle_Constraint;
  update_columns?: Array<JobTitle_Update_Column>;
  where?: Maybe<JobTitle_Bool_Exp>;
};

/** Ordering options when selecting data from "jobTitle". */
export type JobTitle_Order_By = {
  code?: Maybe<Order_By>;
  createdAt?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  name?: Maybe<I18n_Order_By>;
  nameI18n?: Maybe<Order_By>;
  nameI18nId?: Maybe<Order_By>;
  unaccentNameI18n?: Maybe<Order_By>;
  updatedAt?: Maybe<Order_By>;
  volunteerPosts_aggregate?: Maybe<VolunteerPost_Aggregate_Order_By>;
};

/** primary key columns input for table: jobTitle */
export type JobTitle_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "jobTitle" */
export enum JobTitle_Select_Column {
  /** column name */
  Code = 'code',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  NameI18nId = 'nameI18nId',
  /** column name */
  UpdatedAt = 'updatedAt'
}

/** input type for updating data in table "jobTitle" */
export type JobTitle_Set_Input = {
  code?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  nameI18nId?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "jobTitle" */
export type JobTitle_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: JobTitle_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type JobTitle_Stream_Cursor_Value_Input = {
  code?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  nameI18nId?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** update columns of table "jobTitle" */
export enum JobTitle_Update_Column {
  /** column name */
  Code = 'code',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  NameI18nId = 'nameI18nId',
  /** column name */
  UpdatedAt = 'updatedAt'
}

export type JobTitle_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<JobTitle_Set_Input>;
  /** filter the rows which have to be updated */
  where: JobTitle_Bool_Exp;
};


/** Boolean expression to compare columns of type "json". All fields are combined with logical 'AND'. */
export type Json_Comparison_Exp = {
  _eq?: Maybe<Scalars['json']>;
  _gt?: Maybe<Scalars['json']>;
  _gte?: Maybe<Scalars['json']>;
  _in?: Maybe<Array<Scalars['json']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['json']>;
  _lte?: Maybe<Scalars['json']>;
  _neq?: Maybe<Scalars['json']>;
  _nin?: Maybe<Array<Scalars['json']>>;
};

/** columns and relationships of "module" */
export type Module = {
  __typename?: 'module';
  createdAt: Scalars['timestamptz'];
  creatorId?: Maybe<Scalars['uuid']>;
  /** An object relationship */
  description?: Maybe<I18n>;
  /** A computed field, executes function "module_description_i18n" */
  descriptionI18n?: Maybe<Scalars['String']>;
  descriptionI18nId?: Maybe<Scalars['uuid']>;
  duration: Scalars['Int'];
  humanId: Scalars['String'];
  id: Scalars['uuid'];
  imageHeadUri?: Maybe<Scalars['String']>;
  isAsynchronous: Scalars['Boolean'];
  /** An array relationship */
  moduleAnnexes: Array<ModuleAnnex>;
  /** An aggregate relationship */
  moduleAnnexes_aggregate: ModuleAnnex_Aggregate;
  /** An object relationship */
  moduleContent?: Maybe<ModuleContent>;
  /** An array relationship */
  moduleTasks: Array<ModuleTask>;
  /** An aggregate relationship */
  moduleTasks_aggregate: ModuleTask_Aggregate;
  /** An array relationship */
  sequence_modules: Array<Sequence_Module>;
  /** An aggregate relationship */
  sequence_modules_aggregate: Sequence_Module_Aggregate;
  /** An object relationship */
  title?: Maybe<I18n>;
  /** A computed field, executes function "module_title_i18n" */
  titleI18n?: Maybe<Scalars['String']>;
  titleI18nId?: Maybe<Scalars['uuid']>;
  /** A computed field, executes function "module_title_unaccent" */
  unaccentTitleI18n?: Maybe<Scalars['String']>;
  updatedAt: Scalars['timestamptz'];
  /** An object relationship */
  user?: Maybe<User>;
  /** An array relationship */
  userModuleProgress: Array<UserModuleProgress>;
  /** An aggregate relationship */
  userModuleProgress_aggregate: UserModuleProgress_Aggregate;
};


/** columns and relationships of "module" */
export type ModuleModuleAnnexesArgs = {
  distinct_on?: Maybe<Array<ModuleAnnex_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ModuleAnnex_Order_By>>;
  where?: Maybe<ModuleAnnex_Bool_Exp>;
};


/** columns and relationships of "module" */
export type ModuleModuleAnnexes_AggregateArgs = {
  distinct_on?: Maybe<Array<ModuleAnnex_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ModuleAnnex_Order_By>>;
  where?: Maybe<ModuleAnnex_Bool_Exp>;
};


/** columns and relationships of "module" */
export type ModuleModuleTasksArgs = {
  distinct_on?: Maybe<Array<ModuleTask_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ModuleTask_Order_By>>;
  where?: Maybe<ModuleTask_Bool_Exp>;
};


/** columns and relationships of "module" */
export type ModuleModuleTasks_AggregateArgs = {
  distinct_on?: Maybe<Array<ModuleTask_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ModuleTask_Order_By>>;
  where?: Maybe<ModuleTask_Bool_Exp>;
};


/** columns and relationships of "module" */
export type ModuleSequence_ModulesArgs = {
  distinct_on?: Maybe<Array<Sequence_Module_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Sequence_Module_Order_By>>;
  where?: Maybe<Sequence_Module_Bool_Exp>;
};


/** columns and relationships of "module" */
export type ModuleSequence_Modules_AggregateArgs = {
  distinct_on?: Maybe<Array<Sequence_Module_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Sequence_Module_Order_By>>;
  where?: Maybe<Sequence_Module_Bool_Exp>;
};


/** columns and relationships of "module" */
export type ModuleUserModuleProgressArgs = {
  distinct_on?: Maybe<Array<UserModuleProgress_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<UserModuleProgress_Order_By>>;
  where?: Maybe<UserModuleProgress_Bool_Exp>;
};


/** columns and relationships of "module" */
export type ModuleUserModuleProgress_AggregateArgs = {
  distinct_on?: Maybe<Array<UserModuleProgress_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<UserModuleProgress_Order_By>>;
  where?: Maybe<UserModuleProgress_Bool_Exp>;
};

/** columns and relationships of "moduleAnnex" */
export type ModuleAnnex = {
  __typename?: 'moduleAnnex';
  annexOrder: Scalars['Int'];
  createdAt?: Maybe<Scalars['timestamptz']>;
  /** An object relationship */
  description: I18n;
  descriptionI18n?: Maybe<Scalars['String']>;
  descriptionI18nId: Scalars['uuid'];
  id: Scalars['uuid'];
  link: Scalars['String'];
  /** An object relationship */
  module: Module;
  moduleId: Scalars['uuid'];
  /** An object relationship */
  title: I18n;
  titleI18n?: Maybe<Scalars['String']>;
  titleI18nId: Scalars['uuid'];
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** aggregated selection of "moduleAnnex" */
export type ModuleAnnex_Aggregate = {
  __typename?: 'moduleAnnex_aggregate';
  aggregate?: Maybe<ModuleAnnex_Aggregate_Fields>;
  nodes: Array<ModuleAnnex>;
};

export type ModuleAnnex_Aggregate_Bool_Exp = {
  count?: Maybe<ModuleAnnex_Aggregate_Bool_Exp_Count>;
};

export type ModuleAnnex_Aggregate_Bool_Exp_Count = {
  arguments?: Maybe<Array<ModuleAnnex_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<ModuleAnnex_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "moduleAnnex" */
export type ModuleAnnex_Aggregate_Fields = {
  __typename?: 'moduleAnnex_aggregate_fields';
  avg?: Maybe<ModuleAnnex_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<ModuleAnnex_Max_Fields>;
  min?: Maybe<ModuleAnnex_Min_Fields>;
  stddev?: Maybe<ModuleAnnex_Stddev_Fields>;
  stddev_pop?: Maybe<ModuleAnnex_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<ModuleAnnex_Stddev_Samp_Fields>;
  sum?: Maybe<ModuleAnnex_Sum_Fields>;
  var_pop?: Maybe<ModuleAnnex_Var_Pop_Fields>;
  var_samp?: Maybe<ModuleAnnex_Var_Samp_Fields>;
  variance?: Maybe<ModuleAnnex_Variance_Fields>;
};


/** aggregate fields of "moduleAnnex" */
export type ModuleAnnex_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<ModuleAnnex_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "moduleAnnex" */
export type ModuleAnnex_Aggregate_Order_By = {
  avg?: Maybe<ModuleAnnex_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<ModuleAnnex_Max_Order_By>;
  min?: Maybe<ModuleAnnex_Min_Order_By>;
  stddev?: Maybe<ModuleAnnex_Stddev_Order_By>;
  stddev_pop?: Maybe<ModuleAnnex_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<ModuleAnnex_Stddev_Samp_Order_By>;
  sum?: Maybe<ModuleAnnex_Sum_Order_By>;
  var_pop?: Maybe<ModuleAnnex_Var_Pop_Order_By>;
  var_samp?: Maybe<ModuleAnnex_Var_Samp_Order_By>;
  variance?: Maybe<ModuleAnnex_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "moduleAnnex" */
export type ModuleAnnex_Arr_Rel_Insert_Input = {
  data: Array<ModuleAnnex_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<ModuleAnnex_On_Conflict>;
};

/** aggregate avg on columns */
export type ModuleAnnex_Avg_Fields = {
  __typename?: 'moduleAnnex_avg_fields';
  annexOrder?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "moduleAnnex" */
export type ModuleAnnex_Avg_Order_By = {
  annexOrder?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "moduleAnnex". All fields are combined with a logical 'AND'. */
export type ModuleAnnex_Bool_Exp = {
  _and?: Maybe<Array<ModuleAnnex_Bool_Exp>>;
  _not?: Maybe<ModuleAnnex_Bool_Exp>;
  _or?: Maybe<Array<ModuleAnnex_Bool_Exp>>;
  annexOrder?: Maybe<Int_Comparison_Exp>;
  createdAt?: Maybe<Timestamptz_Comparison_Exp>;
  description?: Maybe<I18n_Bool_Exp>;
  descriptionI18n?: Maybe<String_Comparison_Exp>;
  descriptionI18nId?: Maybe<Uuid_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  link?: Maybe<String_Comparison_Exp>;
  module?: Maybe<Module_Bool_Exp>;
  moduleId?: Maybe<Uuid_Comparison_Exp>;
  title?: Maybe<I18n_Bool_Exp>;
  titleI18n?: Maybe<String_Comparison_Exp>;
  titleI18nId?: Maybe<Uuid_Comparison_Exp>;
  updatedAt?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "moduleAnnex" */
export enum ModuleAnnex_Constraint {
  /** unique or primary key constraint on columns "id" */
  ModuleAnnexPkey = 'moduleAnnex_pkey'
}

/** input type for incrementing numeric columns in table "moduleAnnex" */
export type ModuleAnnex_Inc_Input = {
  annexOrder?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "moduleAnnex" */
export type ModuleAnnex_Insert_Input = {
  annexOrder?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<I18n_Obj_Rel_Insert_Input>;
  descriptionI18nId?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  link?: Maybe<Scalars['String']>;
  module?: Maybe<Module_Obj_Rel_Insert_Input>;
  moduleId?: Maybe<Scalars['uuid']>;
  title?: Maybe<I18n_Obj_Rel_Insert_Input>;
  titleI18nId?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type ModuleAnnex_Max_Fields = {
  __typename?: 'moduleAnnex_max_fields';
  annexOrder?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  descriptionI18nId?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  link?: Maybe<Scalars['String']>;
  moduleId?: Maybe<Scalars['uuid']>;
  titleI18nId?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "moduleAnnex" */
export type ModuleAnnex_Max_Order_By = {
  annexOrder?: Maybe<Order_By>;
  createdAt?: Maybe<Order_By>;
  descriptionI18nId?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  link?: Maybe<Order_By>;
  moduleId?: Maybe<Order_By>;
  titleI18nId?: Maybe<Order_By>;
  updatedAt?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type ModuleAnnex_Min_Fields = {
  __typename?: 'moduleAnnex_min_fields';
  annexOrder?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  descriptionI18nId?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  link?: Maybe<Scalars['String']>;
  moduleId?: Maybe<Scalars['uuid']>;
  titleI18nId?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "moduleAnnex" */
export type ModuleAnnex_Min_Order_By = {
  annexOrder?: Maybe<Order_By>;
  createdAt?: Maybe<Order_By>;
  descriptionI18nId?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  link?: Maybe<Order_By>;
  moduleId?: Maybe<Order_By>;
  titleI18nId?: Maybe<Order_By>;
  updatedAt?: Maybe<Order_By>;
};

/** response of any mutation on the table "moduleAnnex" */
export type ModuleAnnex_Mutation_Response = {
  __typename?: 'moduleAnnex_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<ModuleAnnex>;
};

/** on_conflict condition type for table "moduleAnnex" */
export type ModuleAnnex_On_Conflict = {
  constraint: ModuleAnnex_Constraint;
  update_columns?: Array<ModuleAnnex_Update_Column>;
  where?: Maybe<ModuleAnnex_Bool_Exp>;
};

/** Ordering options when selecting data from "moduleAnnex". */
export type ModuleAnnex_Order_By = {
  annexOrder?: Maybe<Order_By>;
  createdAt?: Maybe<Order_By>;
  description?: Maybe<I18n_Order_By>;
  descriptionI18n?: Maybe<Order_By>;
  descriptionI18nId?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  link?: Maybe<Order_By>;
  module?: Maybe<Module_Order_By>;
  moduleId?: Maybe<Order_By>;
  title?: Maybe<I18n_Order_By>;
  titleI18n?: Maybe<Order_By>;
  titleI18nId?: Maybe<Order_By>;
  updatedAt?: Maybe<Order_By>;
};

/** primary key columns input for table: moduleAnnex */
export type ModuleAnnex_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "moduleAnnex" */
export enum ModuleAnnex_Select_Column {
  /** column name */
  AnnexOrder = 'annexOrder',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  DescriptionI18nId = 'descriptionI18nId',
  /** column name */
  Id = 'id',
  /** column name */
  Link = 'link',
  /** column name */
  ModuleId = 'moduleId',
  /** column name */
  TitleI18nId = 'titleI18nId',
  /** column name */
  UpdatedAt = 'updatedAt'
}

/** input type for updating data in table "moduleAnnex" */
export type ModuleAnnex_Set_Input = {
  annexOrder?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  descriptionI18nId?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  link?: Maybe<Scalars['String']>;
  moduleId?: Maybe<Scalars['uuid']>;
  titleI18nId?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type ModuleAnnex_Stddev_Fields = {
  __typename?: 'moduleAnnex_stddev_fields';
  annexOrder?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "moduleAnnex" */
export type ModuleAnnex_Stddev_Order_By = {
  annexOrder?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type ModuleAnnex_Stddev_Pop_Fields = {
  __typename?: 'moduleAnnex_stddev_pop_fields';
  annexOrder?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "moduleAnnex" */
export type ModuleAnnex_Stddev_Pop_Order_By = {
  annexOrder?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type ModuleAnnex_Stddev_Samp_Fields = {
  __typename?: 'moduleAnnex_stddev_samp_fields';
  annexOrder?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "moduleAnnex" */
export type ModuleAnnex_Stddev_Samp_Order_By = {
  annexOrder?: Maybe<Order_By>;
};

/** Streaming cursor of the table "moduleAnnex" */
export type ModuleAnnex_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: ModuleAnnex_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type ModuleAnnex_Stream_Cursor_Value_Input = {
  annexOrder?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  descriptionI18nId?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  link?: Maybe<Scalars['String']>;
  moduleId?: Maybe<Scalars['uuid']>;
  titleI18nId?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type ModuleAnnex_Sum_Fields = {
  __typename?: 'moduleAnnex_sum_fields';
  annexOrder?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "moduleAnnex" */
export type ModuleAnnex_Sum_Order_By = {
  annexOrder?: Maybe<Order_By>;
};

/** update columns of table "moduleAnnex" */
export enum ModuleAnnex_Update_Column {
  /** column name */
  AnnexOrder = 'annexOrder',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  DescriptionI18nId = 'descriptionI18nId',
  /** column name */
  Id = 'id',
  /** column name */
  Link = 'link',
  /** column name */
  ModuleId = 'moduleId',
  /** column name */
  TitleI18nId = 'titleI18nId',
  /** column name */
  UpdatedAt = 'updatedAt'
}

export type ModuleAnnex_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<ModuleAnnex_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<ModuleAnnex_Set_Input>;
  /** filter the rows which have to be updated */
  where: ModuleAnnex_Bool_Exp;
};

/** aggregate var_pop on columns */
export type ModuleAnnex_Var_Pop_Fields = {
  __typename?: 'moduleAnnex_var_pop_fields';
  annexOrder?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "moduleAnnex" */
export type ModuleAnnex_Var_Pop_Order_By = {
  annexOrder?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type ModuleAnnex_Var_Samp_Fields = {
  __typename?: 'moduleAnnex_var_samp_fields';
  annexOrder?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "moduleAnnex" */
export type ModuleAnnex_Var_Samp_Order_By = {
  annexOrder?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type ModuleAnnex_Variance_Fields = {
  __typename?: 'moduleAnnex_variance_fields';
  annexOrder?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "moduleAnnex" */
export type ModuleAnnex_Variance_Order_By = {
  annexOrder?: Maybe<Order_By>;
};

/** columns and relationships of "moduleContent" */
export type ModuleContent = {
  __typename?: 'moduleContent';
  articleId?: Maybe<Scalars['uuid']>;
  /** An object relationship */
  contentArticle?: Maybe<ContentArticle>;
  /** An object relationship */
  contentCmi5?: Maybe<ContentCmi5>;
  contentCmi5Id?: Maybe<Scalars['uuid']>;
  /** An object relationship */
  contentDocument?: Maybe<ContentDocument>;
  contentDocumentId?: Maybe<Scalars['uuid']>;
  /** An object relationship */
  contentEmbed?: Maybe<ContentEmbed>;
  contentEmbedId?: Maybe<Scalars['uuid']>;
  /** An object relationship */
  contentEvent?: Maybe<ContentEvent>;
  contentEventId?: Maybe<Scalars['uuid']>;
  /** An object relationship */
  contentPodcast?: Maybe<ContentPodcast>;
  contentPodcastId?: Maybe<Scalars['uuid']>;
  /** An object relationship */
  contentVideo?: Maybe<ContentVideo>;
  contentVideoId?: Maybe<Scalars['uuid']>;
  createdAt: Scalars['timestamptz'];
  id: Scalars['uuid'];
  /** An object relationship */
  module: Module;
  moduleId: Scalars['uuid'];
  updatedAt: Scalars['timestamptz'];
};

/** aggregated selection of "moduleContent" */
export type ModuleContent_Aggregate = {
  __typename?: 'moduleContent_aggregate';
  aggregate?: Maybe<ModuleContent_Aggregate_Fields>;
  nodes: Array<ModuleContent>;
};

export type ModuleContent_Aggregate_Bool_Exp = {
  count?: Maybe<ModuleContent_Aggregate_Bool_Exp_Count>;
};

export type ModuleContent_Aggregate_Bool_Exp_Count = {
  arguments?: Maybe<Array<ModuleContent_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<ModuleContent_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "moduleContent" */
export type ModuleContent_Aggregate_Fields = {
  __typename?: 'moduleContent_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<ModuleContent_Max_Fields>;
  min?: Maybe<ModuleContent_Min_Fields>;
};


/** aggregate fields of "moduleContent" */
export type ModuleContent_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<ModuleContent_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "moduleContent" */
export type ModuleContent_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<ModuleContent_Max_Order_By>;
  min?: Maybe<ModuleContent_Min_Order_By>;
};

/** input type for inserting array relation for remote table "moduleContent" */
export type ModuleContent_Arr_Rel_Insert_Input = {
  data: Array<ModuleContent_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<ModuleContent_On_Conflict>;
};

/** Boolean expression to filter rows from the table "moduleContent". All fields are combined with a logical 'AND'. */
export type ModuleContent_Bool_Exp = {
  _and?: Maybe<Array<ModuleContent_Bool_Exp>>;
  _not?: Maybe<ModuleContent_Bool_Exp>;
  _or?: Maybe<Array<ModuleContent_Bool_Exp>>;
  articleId?: Maybe<Uuid_Comparison_Exp>;
  contentArticle?: Maybe<ContentArticle_Bool_Exp>;
  contentCmi5?: Maybe<ContentCmi5_Bool_Exp>;
  contentCmi5Id?: Maybe<Uuid_Comparison_Exp>;
  contentDocument?: Maybe<ContentDocument_Bool_Exp>;
  contentDocumentId?: Maybe<Uuid_Comparison_Exp>;
  contentEmbed?: Maybe<ContentEmbed_Bool_Exp>;
  contentEmbedId?: Maybe<Uuid_Comparison_Exp>;
  contentEvent?: Maybe<ContentEvent_Bool_Exp>;
  contentEventId?: Maybe<Uuid_Comparison_Exp>;
  contentPodcast?: Maybe<ContentPodcast_Bool_Exp>;
  contentPodcastId?: Maybe<Uuid_Comparison_Exp>;
  contentVideo?: Maybe<ContentVideo_Bool_Exp>;
  contentVideoId?: Maybe<Uuid_Comparison_Exp>;
  createdAt?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  module?: Maybe<Module_Bool_Exp>;
  moduleId?: Maybe<Uuid_Comparison_Exp>;
  updatedAt?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "moduleContent" */
export enum ModuleContent_Constraint {
  /** unique or primary key constraint on columns "moduleId" */
  ModuleContentModuleIdKey = 'moduleContent_moduleId_key',
  /** unique or primary key constraint on columns "id" */
  ModuleContentPkey = 'moduleContent_pkey'
}

/** input type for inserting data into table "moduleContent" */
export type ModuleContent_Insert_Input = {
  articleId?: Maybe<Scalars['uuid']>;
  contentArticle?: Maybe<ContentArticle_Obj_Rel_Insert_Input>;
  contentCmi5?: Maybe<ContentCmi5_Obj_Rel_Insert_Input>;
  contentCmi5Id?: Maybe<Scalars['uuid']>;
  contentDocument?: Maybe<ContentDocument_Obj_Rel_Insert_Input>;
  contentDocumentId?: Maybe<Scalars['uuid']>;
  contentEmbed?: Maybe<ContentEmbed_Obj_Rel_Insert_Input>;
  contentEmbedId?: Maybe<Scalars['uuid']>;
  contentEvent?: Maybe<ContentEvent_Obj_Rel_Insert_Input>;
  contentEventId?: Maybe<Scalars['uuid']>;
  contentPodcast?: Maybe<ContentPodcast_Obj_Rel_Insert_Input>;
  contentPodcastId?: Maybe<Scalars['uuid']>;
  contentVideo?: Maybe<ContentVideo_Obj_Rel_Insert_Input>;
  contentVideoId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  module?: Maybe<Module_Obj_Rel_Insert_Input>;
  moduleId?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type ModuleContent_Max_Fields = {
  __typename?: 'moduleContent_max_fields';
  articleId?: Maybe<Scalars['uuid']>;
  contentCmi5Id?: Maybe<Scalars['uuid']>;
  contentDocumentId?: Maybe<Scalars['uuid']>;
  contentEmbedId?: Maybe<Scalars['uuid']>;
  contentEventId?: Maybe<Scalars['uuid']>;
  contentPodcastId?: Maybe<Scalars['uuid']>;
  contentVideoId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  moduleId?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "moduleContent" */
export type ModuleContent_Max_Order_By = {
  articleId?: Maybe<Order_By>;
  contentCmi5Id?: Maybe<Order_By>;
  contentDocumentId?: Maybe<Order_By>;
  contentEmbedId?: Maybe<Order_By>;
  contentEventId?: Maybe<Order_By>;
  contentPodcastId?: Maybe<Order_By>;
  contentVideoId?: Maybe<Order_By>;
  createdAt?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  moduleId?: Maybe<Order_By>;
  updatedAt?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type ModuleContent_Min_Fields = {
  __typename?: 'moduleContent_min_fields';
  articleId?: Maybe<Scalars['uuid']>;
  contentCmi5Id?: Maybe<Scalars['uuid']>;
  contentDocumentId?: Maybe<Scalars['uuid']>;
  contentEmbedId?: Maybe<Scalars['uuid']>;
  contentEventId?: Maybe<Scalars['uuid']>;
  contentPodcastId?: Maybe<Scalars['uuid']>;
  contentVideoId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  moduleId?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "moduleContent" */
export type ModuleContent_Min_Order_By = {
  articleId?: Maybe<Order_By>;
  contentCmi5Id?: Maybe<Order_By>;
  contentDocumentId?: Maybe<Order_By>;
  contentEmbedId?: Maybe<Order_By>;
  contentEventId?: Maybe<Order_By>;
  contentPodcastId?: Maybe<Order_By>;
  contentVideoId?: Maybe<Order_By>;
  createdAt?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  moduleId?: Maybe<Order_By>;
  updatedAt?: Maybe<Order_By>;
};

/** response of any mutation on the table "moduleContent" */
export type ModuleContent_Mutation_Response = {
  __typename?: 'moduleContent_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<ModuleContent>;
};

/** input type for inserting object relation for remote table "moduleContent" */
export type ModuleContent_Obj_Rel_Insert_Input = {
  data: ModuleContent_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<ModuleContent_On_Conflict>;
};

/** on_conflict condition type for table "moduleContent" */
export type ModuleContent_On_Conflict = {
  constraint: ModuleContent_Constraint;
  update_columns?: Array<ModuleContent_Update_Column>;
  where?: Maybe<ModuleContent_Bool_Exp>;
};

/** Ordering options when selecting data from "moduleContent". */
export type ModuleContent_Order_By = {
  articleId?: Maybe<Order_By>;
  contentArticle?: Maybe<ContentArticle_Order_By>;
  contentCmi5?: Maybe<ContentCmi5_Order_By>;
  contentCmi5Id?: Maybe<Order_By>;
  contentDocument?: Maybe<ContentDocument_Order_By>;
  contentDocumentId?: Maybe<Order_By>;
  contentEmbed?: Maybe<ContentEmbed_Order_By>;
  contentEmbedId?: Maybe<Order_By>;
  contentEvent?: Maybe<ContentEvent_Order_By>;
  contentEventId?: Maybe<Order_By>;
  contentPodcast?: Maybe<ContentPodcast_Order_By>;
  contentPodcastId?: Maybe<Order_By>;
  contentVideo?: Maybe<ContentVideo_Order_By>;
  contentVideoId?: Maybe<Order_By>;
  createdAt?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  module?: Maybe<Module_Order_By>;
  moduleId?: Maybe<Order_By>;
  updatedAt?: Maybe<Order_By>;
};

/** primary key columns input for table: moduleContent */
export type ModuleContent_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "moduleContent" */
export enum ModuleContent_Select_Column {
  /** column name */
  ArticleId = 'articleId',
  /** column name */
  ContentCmi5Id = 'contentCmi5Id',
  /** column name */
  ContentDocumentId = 'contentDocumentId',
  /** column name */
  ContentEmbedId = 'contentEmbedId',
  /** column name */
  ContentEventId = 'contentEventId',
  /** column name */
  ContentPodcastId = 'contentPodcastId',
  /** column name */
  ContentVideoId = 'contentVideoId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  ModuleId = 'moduleId',
  /** column name */
  UpdatedAt = 'updatedAt'
}

/** input type for updating data in table "moduleContent" */
export type ModuleContent_Set_Input = {
  articleId?: Maybe<Scalars['uuid']>;
  contentCmi5Id?: Maybe<Scalars['uuid']>;
  contentDocumentId?: Maybe<Scalars['uuid']>;
  contentEmbedId?: Maybe<Scalars['uuid']>;
  contentEventId?: Maybe<Scalars['uuid']>;
  contentPodcastId?: Maybe<Scalars['uuid']>;
  contentVideoId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  moduleId?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "moduleContent" */
export type ModuleContent_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: ModuleContent_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type ModuleContent_Stream_Cursor_Value_Input = {
  articleId?: Maybe<Scalars['uuid']>;
  contentCmi5Id?: Maybe<Scalars['uuid']>;
  contentDocumentId?: Maybe<Scalars['uuid']>;
  contentEmbedId?: Maybe<Scalars['uuid']>;
  contentEventId?: Maybe<Scalars['uuid']>;
  contentPodcastId?: Maybe<Scalars['uuid']>;
  contentVideoId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  moduleId?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** update columns of table "moduleContent" */
export enum ModuleContent_Update_Column {
  /** column name */
  ArticleId = 'articleId',
  /** column name */
  ContentCmi5Id = 'contentCmi5Id',
  /** column name */
  ContentDocumentId = 'contentDocumentId',
  /** column name */
  ContentEmbedId = 'contentEmbedId',
  /** column name */
  ContentEventId = 'contentEventId',
  /** column name */
  ContentPodcastId = 'contentPodcastId',
  /** column name */
  ContentVideoId = 'contentVideoId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  ModuleId = 'moduleId',
  /** column name */
  UpdatedAt = 'updatedAt'
}

export type ModuleContent_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<ModuleContent_Set_Input>;
  /** filter the rows which have to be updated */
  where: ModuleContent_Bool_Exp;
};

/** columns and relationships of "moduleTask" */
export type ModuleTask = {
  __typename?: 'moduleTask';
  createdAt: Scalars['timestamptz'];
  /** An object relationship */
  description: I18n;
  /** A computed field, executes function "module_task_description_i18n" */
  descriptionI18n?: Maybe<Scalars['String']>;
  descriptionI18nId: Scalars['uuid'];
  id: Scalars['uuid'];
  moduleId: Scalars['uuid'];
  taskOrder: Scalars['Int'];
  updatedAt: Scalars['timestamptz'];
};

/** aggregated selection of "moduleTask" */
export type ModuleTask_Aggregate = {
  __typename?: 'moduleTask_aggregate';
  aggregate?: Maybe<ModuleTask_Aggregate_Fields>;
  nodes: Array<ModuleTask>;
};

export type ModuleTask_Aggregate_Bool_Exp = {
  count?: Maybe<ModuleTask_Aggregate_Bool_Exp_Count>;
};

export type ModuleTask_Aggregate_Bool_Exp_Count = {
  arguments?: Maybe<Array<ModuleTask_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<ModuleTask_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "moduleTask" */
export type ModuleTask_Aggregate_Fields = {
  __typename?: 'moduleTask_aggregate_fields';
  avg?: Maybe<ModuleTask_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<ModuleTask_Max_Fields>;
  min?: Maybe<ModuleTask_Min_Fields>;
  stddev?: Maybe<ModuleTask_Stddev_Fields>;
  stddev_pop?: Maybe<ModuleTask_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<ModuleTask_Stddev_Samp_Fields>;
  sum?: Maybe<ModuleTask_Sum_Fields>;
  var_pop?: Maybe<ModuleTask_Var_Pop_Fields>;
  var_samp?: Maybe<ModuleTask_Var_Samp_Fields>;
  variance?: Maybe<ModuleTask_Variance_Fields>;
};


/** aggregate fields of "moduleTask" */
export type ModuleTask_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<ModuleTask_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "moduleTask" */
export type ModuleTask_Aggregate_Order_By = {
  avg?: Maybe<ModuleTask_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<ModuleTask_Max_Order_By>;
  min?: Maybe<ModuleTask_Min_Order_By>;
  stddev?: Maybe<ModuleTask_Stddev_Order_By>;
  stddev_pop?: Maybe<ModuleTask_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<ModuleTask_Stddev_Samp_Order_By>;
  sum?: Maybe<ModuleTask_Sum_Order_By>;
  var_pop?: Maybe<ModuleTask_Var_Pop_Order_By>;
  var_samp?: Maybe<ModuleTask_Var_Samp_Order_By>;
  variance?: Maybe<ModuleTask_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "moduleTask" */
export type ModuleTask_Arr_Rel_Insert_Input = {
  data: Array<ModuleTask_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<ModuleTask_On_Conflict>;
};

/** aggregate avg on columns */
export type ModuleTask_Avg_Fields = {
  __typename?: 'moduleTask_avg_fields';
  taskOrder?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "moduleTask" */
export type ModuleTask_Avg_Order_By = {
  taskOrder?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "moduleTask". All fields are combined with a logical 'AND'. */
export type ModuleTask_Bool_Exp = {
  _and?: Maybe<Array<ModuleTask_Bool_Exp>>;
  _not?: Maybe<ModuleTask_Bool_Exp>;
  _or?: Maybe<Array<ModuleTask_Bool_Exp>>;
  createdAt?: Maybe<Timestamptz_Comparison_Exp>;
  description?: Maybe<I18n_Bool_Exp>;
  descriptionI18n?: Maybe<String_Comparison_Exp>;
  descriptionI18nId?: Maybe<Uuid_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  moduleId?: Maybe<Uuid_Comparison_Exp>;
  taskOrder?: Maybe<Int_Comparison_Exp>;
  updatedAt?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "moduleTask" */
export enum ModuleTask_Constraint {
  /** unique or primary key constraint on columns "id" */
  ModuleTaskPkey = 'moduleTask_pkey'
}

/** input type for incrementing numeric columns in table "moduleTask" */
export type ModuleTask_Inc_Input = {
  taskOrder?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "moduleTask" */
export type ModuleTask_Insert_Input = {
  createdAt?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<I18n_Obj_Rel_Insert_Input>;
  descriptionI18nId?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  moduleId?: Maybe<Scalars['uuid']>;
  taskOrder?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type ModuleTask_Max_Fields = {
  __typename?: 'moduleTask_max_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  descriptionI18nId?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  moduleId?: Maybe<Scalars['uuid']>;
  taskOrder?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "moduleTask" */
export type ModuleTask_Max_Order_By = {
  createdAt?: Maybe<Order_By>;
  descriptionI18nId?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  moduleId?: Maybe<Order_By>;
  taskOrder?: Maybe<Order_By>;
  updatedAt?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type ModuleTask_Min_Fields = {
  __typename?: 'moduleTask_min_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  descriptionI18nId?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  moduleId?: Maybe<Scalars['uuid']>;
  taskOrder?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "moduleTask" */
export type ModuleTask_Min_Order_By = {
  createdAt?: Maybe<Order_By>;
  descriptionI18nId?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  moduleId?: Maybe<Order_By>;
  taskOrder?: Maybe<Order_By>;
  updatedAt?: Maybe<Order_By>;
};

/** response of any mutation on the table "moduleTask" */
export type ModuleTask_Mutation_Response = {
  __typename?: 'moduleTask_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<ModuleTask>;
};

/** on_conflict condition type for table "moduleTask" */
export type ModuleTask_On_Conflict = {
  constraint: ModuleTask_Constraint;
  update_columns?: Array<ModuleTask_Update_Column>;
  where?: Maybe<ModuleTask_Bool_Exp>;
};

/** Ordering options when selecting data from "moduleTask". */
export type ModuleTask_Order_By = {
  createdAt?: Maybe<Order_By>;
  description?: Maybe<I18n_Order_By>;
  descriptionI18n?: Maybe<Order_By>;
  descriptionI18nId?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  moduleId?: Maybe<Order_By>;
  taskOrder?: Maybe<Order_By>;
  updatedAt?: Maybe<Order_By>;
};

/** primary key columns input for table: moduleTask */
export type ModuleTask_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "moduleTask" */
export enum ModuleTask_Select_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  DescriptionI18nId = 'descriptionI18nId',
  /** column name */
  Id = 'id',
  /** column name */
  ModuleId = 'moduleId',
  /** column name */
  TaskOrder = 'taskOrder',
  /** column name */
  UpdatedAt = 'updatedAt'
}

/** input type for updating data in table "moduleTask" */
export type ModuleTask_Set_Input = {
  createdAt?: Maybe<Scalars['timestamptz']>;
  descriptionI18nId?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  moduleId?: Maybe<Scalars['uuid']>;
  taskOrder?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type ModuleTask_Stddev_Fields = {
  __typename?: 'moduleTask_stddev_fields';
  taskOrder?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "moduleTask" */
export type ModuleTask_Stddev_Order_By = {
  taskOrder?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type ModuleTask_Stddev_Pop_Fields = {
  __typename?: 'moduleTask_stddev_pop_fields';
  taskOrder?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "moduleTask" */
export type ModuleTask_Stddev_Pop_Order_By = {
  taskOrder?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type ModuleTask_Stddev_Samp_Fields = {
  __typename?: 'moduleTask_stddev_samp_fields';
  taskOrder?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "moduleTask" */
export type ModuleTask_Stddev_Samp_Order_By = {
  taskOrder?: Maybe<Order_By>;
};

/** Streaming cursor of the table "moduleTask" */
export type ModuleTask_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: ModuleTask_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type ModuleTask_Stream_Cursor_Value_Input = {
  createdAt?: Maybe<Scalars['timestamptz']>;
  descriptionI18nId?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  moduleId?: Maybe<Scalars['uuid']>;
  taskOrder?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type ModuleTask_Sum_Fields = {
  __typename?: 'moduleTask_sum_fields';
  taskOrder?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "moduleTask" */
export type ModuleTask_Sum_Order_By = {
  taskOrder?: Maybe<Order_By>;
};

/** update columns of table "moduleTask" */
export enum ModuleTask_Update_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  DescriptionI18nId = 'descriptionI18nId',
  /** column name */
  Id = 'id',
  /** column name */
  ModuleId = 'moduleId',
  /** column name */
  TaskOrder = 'taskOrder',
  /** column name */
  UpdatedAt = 'updatedAt'
}

export type ModuleTask_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<ModuleTask_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<ModuleTask_Set_Input>;
  /** filter the rows which have to be updated */
  where: ModuleTask_Bool_Exp;
};

/** aggregate var_pop on columns */
export type ModuleTask_Var_Pop_Fields = {
  __typename?: 'moduleTask_var_pop_fields';
  taskOrder?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "moduleTask" */
export type ModuleTask_Var_Pop_Order_By = {
  taskOrder?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type ModuleTask_Var_Samp_Fields = {
  __typename?: 'moduleTask_var_samp_fields';
  taskOrder?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "moduleTask" */
export type ModuleTask_Var_Samp_Order_By = {
  taskOrder?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type ModuleTask_Variance_Fields = {
  __typename?: 'moduleTask_variance_fields';
  taskOrder?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "moduleTask" */
export type ModuleTask_Variance_Order_By = {
  taskOrder?: Maybe<Order_By>;
};

/** aggregated selection of "module" */
export type Module_Aggregate = {
  __typename?: 'module_aggregate';
  aggregate?: Maybe<Module_Aggregate_Fields>;
  nodes: Array<Module>;
};

/** aggregate fields of "module" */
export type Module_Aggregate_Fields = {
  __typename?: 'module_aggregate_fields';
  avg?: Maybe<Module_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Module_Max_Fields>;
  min?: Maybe<Module_Min_Fields>;
  stddev?: Maybe<Module_Stddev_Fields>;
  stddev_pop?: Maybe<Module_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Module_Stddev_Samp_Fields>;
  sum?: Maybe<Module_Sum_Fields>;
  var_pop?: Maybe<Module_Var_Pop_Fields>;
  var_samp?: Maybe<Module_Var_Samp_Fields>;
  variance?: Maybe<Module_Variance_Fields>;
};


/** aggregate fields of "module" */
export type Module_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Module_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Module_Avg_Fields = {
  __typename?: 'module_avg_fields';
  duration?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "module". All fields are combined with a logical 'AND'. */
export type Module_Bool_Exp = {
  _and?: Maybe<Array<Module_Bool_Exp>>;
  _not?: Maybe<Module_Bool_Exp>;
  _or?: Maybe<Array<Module_Bool_Exp>>;
  createdAt?: Maybe<Timestamptz_Comparison_Exp>;
  creatorId?: Maybe<Uuid_Comparison_Exp>;
  description?: Maybe<I18n_Bool_Exp>;
  descriptionI18n?: Maybe<String_Comparison_Exp>;
  descriptionI18nId?: Maybe<Uuid_Comparison_Exp>;
  duration?: Maybe<Int_Comparison_Exp>;
  humanId?: Maybe<String_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  imageHeadUri?: Maybe<String_Comparison_Exp>;
  isAsynchronous?: Maybe<Boolean_Comparison_Exp>;
  moduleAnnexes?: Maybe<ModuleAnnex_Bool_Exp>;
  moduleAnnexes_aggregate?: Maybe<ModuleAnnex_Aggregate_Bool_Exp>;
  moduleContent?: Maybe<ModuleContent_Bool_Exp>;
  moduleTasks?: Maybe<ModuleTask_Bool_Exp>;
  moduleTasks_aggregate?: Maybe<ModuleTask_Aggregate_Bool_Exp>;
  sequence_modules?: Maybe<Sequence_Module_Bool_Exp>;
  sequence_modules_aggregate?: Maybe<Sequence_Module_Aggregate_Bool_Exp>;
  title?: Maybe<I18n_Bool_Exp>;
  titleI18n?: Maybe<String_Comparison_Exp>;
  titleI18nId?: Maybe<Uuid_Comparison_Exp>;
  unaccentTitleI18n?: Maybe<String_Comparison_Exp>;
  updatedAt?: Maybe<Timestamptz_Comparison_Exp>;
  user?: Maybe<User_Bool_Exp>;
  userModuleProgress?: Maybe<UserModuleProgress_Bool_Exp>;
  userModuleProgress_aggregate?: Maybe<UserModuleProgress_Aggregate_Bool_Exp>;
};

/** unique or primary key constraints on table "module" */
export enum Module_Constraint {
  /** unique or primary key constraint on columns "humanId" */
  ModuleHumanIdKey = 'module_humanId_key',
  /** unique or primary key constraint on columns "id" */
  ModulePkey = 'module_pkey'
}

/** input type for incrementing numeric columns in table "module" */
export type Module_Inc_Input = {
  duration?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "module" */
export type Module_Insert_Input = {
  createdAt?: Maybe<Scalars['timestamptz']>;
  creatorId?: Maybe<Scalars['uuid']>;
  description?: Maybe<I18n_Obj_Rel_Insert_Input>;
  descriptionI18nId?: Maybe<Scalars['uuid']>;
  duration?: Maybe<Scalars['Int']>;
  humanId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  imageHeadUri?: Maybe<Scalars['String']>;
  isAsynchronous?: Maybe<Scalars['Boolean']>;
  moduleAnnexes?: Maybe<ModuleAnnex_Arr_Rel_Insert_Input>;
  moduleContent?: Maybe<ModuleContent_Obj_Rel_Insert_Input>;
  moduleTasks?: Maybe<ModuleTask_Arr_Rel_Insert_Input>;
  sequence_modules?: Maybe<Sequence_Module_Arr_Rel_Insert_Input>;
  title?: Maybe<I18n_Obj_Rel_Insert_Input>;
  titleI18nId?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  user?: Maybe<User_Obj_Rel_Insert_Input>;
  userModuleProgress?: Maybe<UserModuleProgress_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Module_Max_Fields = {
  __typename?: 'module_max_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  creatorId?: Maybe<Scalars['uuid']>;
  descriptionI18nId?: Maybe<Scalars['uuid']>;
  duration?: Maybe<Scalars['Int']>;
  humanId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  imageHeadUri?: Maybe<Scalars['String']>;
  titleI18nId?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Module_Min_Fields = {
  __typename?: 'module_min_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  creatorId?: Maybe<Scalars['uuid']>;
  descriptionI18nId?: Maybe<Scalars['uuid']>;
  duration?: Maybe<Scalars['Int']>;
  humanId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  imageHeadUri?: Maybe<Scalars['String']>;
  titleI18nId?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "module" */
export type Module_Mutation_Response = {
  __typename?: 'module_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Module>;
};

/** input type for inserting object relation for remote table "module" */
export type Module_Obj_Rel_Insert_Input = {
  data: Module_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Module_On_Conflict>;
};

/** on_conflict condition type for table "module" */
export type Module_On_Conflict = {
  constraint: Module_Constraint;
  update_columns?: Array<Module_Update_Column>;
  where?: Maybe<Module_Bool_Exp>;
};

/** Ordering options when selecting data from "module". */
export type Module_Order_By = {
  createdAt?: Maybe<Order_By>;
  creatorId?: Maybe<Order_By>;
  description?: Maybe<I18n_Order_By>;
  descriptionI18n?: Maybe<Order_By>;
  descriptionI18nId?: Maybe<Order_By>;
  duration?: Maybe<Order_By>;
  humanId?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  imageHeadUri?: Maybe<Order_By>;
  isAsynchronous?: Maybe<Order_By>;
  moduleAnnexes_aggregate?: Maybe<ModuleAnnex_Aggregate_Order_By>;
  moduleContent?: Maybe<ModuleContent_Order_By>;
  moduleTasks_aggregate?: Maybe<ModuleTask_Aggregate_Order_By>;
  sequence_modules_aggregate?: Maybe<Sequence_Module_Aggregate_Order_By>;
  title?: Maybe<I18n_Order_By>;
  titleI18n?: Maybe<Order_By>;
  titleI18nId?: Maybe<Order_By>;
  unaccentTitleI18n?: Maybe<Order_By>;
  updatedAt?: Maybe<Order_By>;
  user?: Maybe<User_Order_By>;
  userModuleProgress_aggregate?: Maybe<UserModuleProgress_Aggregate_Order_By>;
};

/** primary key columns input for table: module */
export type Module_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "module" */
export enum Module_Select_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  CreatorId = 'creatorId',
  /** column name */
  DescriptionI18nId = 'descriptionI18nId',
  /** column name */
  Duration = 'duration',
  /** column name */
  HumanId = 'humanId',
  /** column name */
  Id = 'id',
  /** column name */
  ImageHeadUri = 'imageHeadUri',
  /** column name */
  IsAsynchronous = 'isAsynchronous',
  /** column name */
  TitleI18nId = 'titleI18nId',
  /** column name */
  UpdatedAt = 'updatedAt'
}

/** input type for updating data in table "module" */
export type Module_Set_Input = {
  createdAt?: Maybe<Scalars['timestamptz']>;
  creatorId?: Maybe<Scalars['uuid']>;
  descriptionI18nId?: Maybe<Scalars['uuid']>;
  duration?: Maybe<Scalars['Int']>;
  humanId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  imageHeadUri?: Maybe<Scalars['String']>;
  isAsynchronous?: Maybe<Scalars['Boolean']>;
  titleI18nId?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Module_Stddev_Fields = {
  __typename?: 'module_stddev_fields';
  duration?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Module_Stddev_Pop_Fields = {
  __typename?: 'module_stddev_pop_fields';
  duration?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Module_Stddev_Samp_Fields = {
  __typename?: 'module_stddev_samp_fields';
  duration?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "module" */
export type Module_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Module_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Module_Stream_Cursor_Value_Input = {
  createdAt?: Maybe<Scalars['timestamptz']>;
  creatorId?: Maybe<Scalars['uuid']>;
  descriptionI18nId?: Maybe<Scalars['uuid']>;
  duration?: Maybe<Scalars['Int']>;
  humanId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  imageHeadUri?: Maybe<Scalars['String']>;
  isAsynchronous?: Maybe<Scalars['Boolean']>;
  titleI18nId?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type Module_Sum_Fields = {
  __typename?: 'module_sum_fields';
  duration?: Maybe<Scalars['Int']>;
};

/** update columns of table "module" */
export enum Module_Update_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  CreatorId = 'creatorId',
  /** column name */
  DescriptionI18nId = 'descriptionI18nId',
  /** column name */
  Duration = 'duration',
  /** column name */
  HumanId = 'humanId',
  /** column name */
  Id = 'id',
  /** column name */
  ImageHeadUri = 'imageHeadUri',
  /** column name */
  IsAsynchronous = 'isAsynchronous',
  /** column name */
  TitleI18nId = 'titleI18nId',
  /** column name */
  UpdatedAt = 'updatedAt'
}

export type Module_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<Module_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Module_Set_Input>;
  /** filter the rows which have to be updated */
  where: Module_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Module_Var_Pop_Fields = {
  __typename?: 'module_var_pop_fields';
  duration?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Module_Var_Samp_Fields = {
  __typename?: 'module_var_samp_fields';
  duration?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Module_Variance_Fields = {
  __typename?: 'module_variance_fields';
  duration?: Maybe<Scalars['Float']>;
};

/** mutation root */
export type Mutation_Root = {
  __typename?: 'mutation_root';
  /** Accept policy for current user in our database */
  acceptPolicy?: Maybe<AcceptPolicyResponse>;
  /** Save a course */
  admin?: Maybe<UserResponse>;
  /** Anonymize a user in our database */
  anonymize?: Maybe<AnonymizeResponse>;
  /** Save an attribute */
  attributes?: Maybe<AttributesResponse>;
  /** Save a skill */
  cmsPage?: Maybe<CmsPageResponse>;
  /** Save a course */
  course?: Maybe<CourseResponse>;
  /** Save a course assign */
  courseAssign?: Maybe<CourseAssignResponse>;
  /** Create a link to download resource from bucket */
  createDownloadUrl?: Maybe<DownloadUrlResponse>;
  /** Create a link to upload resource on bucket or in a precise folder */
  createUploadUrl?: Maybe<UploadUrlResponse>;
  /** Delete a draft */
  deleteDraftCourse?: Maybe<DeleteDraftCourseResponse>;
  deleteModuleById?: Maybe<DeleteModuleByIdResponse>;
  /** Delete a userEventSlot */
  deleteUserFromEventSlot?: Maybe<DeleteUserFromEventSlotResponse>;
  /** delete data from the table: "cmsPage" */
  delete_cmsPage?: Maybe<CmsPage_Mutation_Response>;
  /** delete single row from the table: "cmsPage" */
  delete_cmsPage_by_pk?: Maybe<CmsPage>;
  /** delete data from the table: "contentArticle" */
  delete_contentArticle?: Maybe<ContentArticle_Mutation_Response>;
  /** delete single row from the table: "contentArticle" */
  delete_contentArticle_by_pk?: Maybe<ContentArticle>;
  /** delete data from the table: "contentAudioTranscript" */
  delete_contentAudioTranscript?: Maybe<ContentAudioTranscript_Mutation_Response>;
  /** delete single row from the table: "contentAudioTranscript" */
  delete_contentAudioTranscript_by_pk?: Maybe<ContentAudioTranscript>;
  /** delete data from the table: "contentCmi5" */
  delete_contentCmi5?: Maybe<ContentCmi5_Mutation_Response>;
  /** delete data from the table: "contentCmi5AssignableUnit" */
  delete_contentCmi5AssignableUnit?: Maybe<ContentCmi5AssignableUnit_Mutation_Response>;
  /** delete single row from the table: "contentCmi5AssignableUnit" */
  delete_contentCmi5AssignableUnit_by_pk?: Maybe<ContentCmi5AssignableUnit>;
  /** delete data from the table: "contentCmi5Objective" */
  delete_contentCmi5Objective?: Maybe<ContentCmi5Objective_Mutation_Response>;
  /** delete single row from the table: "contentCmi5Objective" */
  delete_contentCmi5Objective_by_pk?: Maybe<ContentCmi5Objective>;
  /** delete single row from the table: "contentCmi5" */
  delete_contentCmi5_by_pk?: Maybe<ContentCmi5>;
  /** delete data from the table: "contentDocument" */
  delete_contentDocument?: Maybe<ContentDocument_Mutation_Response>;
  /** delete data from the table: "contentDocumentUpload" */
  delete_contentDocumentUpload?: Maybe<ContentDocumentUpload_Mutation_Response>;
  /** delete single row from the table: "contentDocumentUpload" */
  delete_contentDocumentUpload_by_pk?: Maybe<ContentDocumentUpload>;
  /** delete single row from the table: "contentDocument" */
  delete_contentDocument_by_pk?: Maybe<ContentDocument>;
  /** delete data from the table: "contentEmbed" */
  delete_contentEmbed?: Maybe<ContentEmbed_Mutation_Response>;
  /** delete data from the table: "contentEmbedAudioTranscript" */
  delete_contentEmbedAudioTranscript?: Maybe<ContentEmbedAudioTranscript_Mutation_Response>;
  /** delete single row from the table: "contentEmbedAudioTranscript" */
  delete_contentEmbedAudioTranscript_by_pk?: Maybe<ContentEmbedAudioTranscript>;
  /** delete single row from the table: "contentEmbed" */
  delete_contentEmbed_by_pk?: Maybe<ContentEmbed>;
  /** delete data from the table: "contentEvent" */
  delete_contentEvent?: Maybe<ContentEvent_Mutation_Response>;
  /** delete data from the table: "contentEventAttachment" */
  delete_contentEventAttachment?: Maybe<ContentEventAttachment_Mutation_Response>;
  /** delete single row from the table: "contentEventAttachment" */
  delete_contentEventAttachment_by_pk?: Maybe<ContentEventAttachment>;
  /** delete data from the table: "contentEventSlot" */
  delete_contentEventSlot?: Maybe<ContentEventSlot_Mutation_Response>;
  /** delete data from the table: "contentEventSlot_animator" */
  delete_contentEventSlot_animator?: Maybe<ContentEventSlot_Animator_Mutation_Response>;
  /** delete single row from the table: "contentEventSlot_animator" */
  delete_contentEventSlot_animator_by_pk?: Maybe<ContentEventSlot_Animator>;
  /** delete single row from the table: "contentEventSlot" */
  delete_contentEventSlot_by_pk?: Maybe<ContentEventSlot>;
  /** delete single row from the table: "contentEvent" */
  delete_contentEvent_by_pk?: Maybe<ContentEvent>;
  /** delete data from the table: "contentPodcast" */
  delete_contentPodcast?: Maybe<ContentPodcast_Mutation_Response>;
  /** delete data from the table: "contentPodcastUpload" */
  delete_contentPodcastUpload?: Maybe<ContentPodcastUpload_Mutation_Response>;
  /** delete single row from the table: "contentPodcastUpload" */
  delete_contentPodcastUpload_by_pk?: Maybe<ContentPodcastUpload>;
  /** delete single row from the table: "contentPodcast" */
  delete_contentPodcast_by_pk?: Maybe<ContentPodcast>;
  /** delete data from the table: "contentVideo" */
  delete_contentVideo?: Maybe<ContentVideo_Mutation_Response>;
  /** delete data from the table: "contentVideoCaption" */
  delete_contentVideoCaption?: Maybe<ContentVideoCaption_Mutation_Response>;
  /** delete single row from the table: "contentVideoCaption" */
  delete_contentVideoCaption_by_pk?: Maybe<ContentVideoCaption>;
  /** delete data from the table: "contentVideoDetails" */
  delete_contentVideoDetails?: Maybe<ContentVideoDetails_Mutation_Response>;
  /** delete single row from the table: "contentVideoDetails" */
  delete_contentVideoDetails_by_pk?: Maybe<ContentVideoDetails>;
  /** delete single row from the table: "contentVideo" */
  delete_contentVideo_by_pk?: Maybe<ContentVideo>;
  /** delete data from the table: "course" */
  delete_course?: Maybe<Course_Mutation_Response>;
  /** delete data from the table: "courseAssign" */
  delete_courseAssign?: Maybe<CourseAssign_Mutation_Response>;
  /** delete data from the table: "courseAssignEmployee" */
  delete_courseAssignEmployee?: Maybe<CourseAssignEmployee_Mutation_Response>;
  /** delete data from the table: "courseAssignEmployeeDirection" */
  delete_courseAssignEmployeeDirection?: Maybe<CourseAssignEmployeeDirection_Mutation_Response>;
  /** delete single row from the table: "courseAssignEmployeeDirection" */
  delete_courseAssignEmployeeDirection_by_pk?: Maybe<CourseAssignEmployeeDirection>;
  /** delete data from the table: "courseAssignEmployeeFa" */
  delete_courseAssignEmployeeFa?: Maybe<CourseAssignEmployeeFa_Mutation_Response>;
  /** delete single row from the table: "courseAssignEmployeeFa" */
  delete_courseAssignEmployeeFa_by_pk?: Maybe<CourseAssignEmployeeFa>;
  /** delete single row from the table: "courseAssignEmployee" */
  delete_courseAssignEmployee_by_pk?: Maybe<CourseAssignEmployee>;
  /** delete data from the table: "courseAssignGroup" */
  delete_courseAssignGroup?: Maybe<CourseAssignGroup_Mutation_Response>;
  /** delete single row from the table: "courseAssignGroup" */
  delete_courseAssignGroup_by_pk?: Maybe<CourseAssignGroup>;
  /** delete data from the table: "courseAssignOther" */
  delete_courseAssignOther?: Maybe<CourseAssignOther_Mutation_Response>;
  /** delete single row from the table: "courseAssignOther" */
  delete_courseAssignOther_by_pk?: Maybe<CourseAssignOther>;
  /** delete data from the table: "courseAssignUser" */
  delete_courseAssignUser?: Maybe<CourseAssignUser_Mutation_Response>;
  /** delete single row from the table: "courseAssignUser" */
  delete_courseAssignUser_by_pk?: Maybe<CourseAssignUser>;
  /** delete data from the table: "courseAssignVolunteer" */
  delete_courseAssignVolunteer?: Maybe<CourseAssignVolunteer_Mutation_Response>;
  /** delete data from the table: "courseAssignVolunteerFa" */
  delete_courseAssignVolunteerFa?: Maybe<CourseAssignVolunteerFa_Mutation_Response>;
  /** delete single row from the table: "courseAssignVolunteerFa" */
  delete_courseAssignVolunteerFa_by_pk?: Maybe<CourseAssignVolunteerFa>;
  /** delete data from the table: "courseAssignVolunteerJobTitle" */
  delete_courseAssignVolunteerJobTitle?: Maybe<CourseAssignVolunteerJobTitle_Mutation_Response>;
  /** delete single row from the table: "courseAssignVolunteerJobTitle" */
  delete_courseAssignVolunteerJobTitle_by_pk?: Maybe<CourseAssignVolunteerJobTitle>;
  /** delete data from the table: "courseAssignVolunteerSite" */
  delete_courseAssignVolunteerSite?: Maybe<CourseAssignVolunteerSite_Mutation_Response>;
  /** delete single row from the table: "courseAssignVolunteerSite" */
  delete_courseAssignVolunteerSite_by_pk?: Maybe<CourseAssignVolunteerSite>;
  /** delete single row from the table: "courseAssignVolunteer" */
  delete_courseAssignVolunteer_by_pk?: Maybe<CourseAssignVolunteer>;
  /** delete single row from the table: "courseAssign" */
  delete_courseAssign_by_pk?: Maybe<CourseAssign>;
  /** delete single row from the table: "course" */
  delete_course_by_pk?: Maybe<Course>;
  /** delete data from the table: "d_courseUserRefresh" */
  delete_d_courseUserRefresh?: Maybe<D_CourseUserRefresh_Mutation_Response>;
  /** delete single row from the table: "d_courseUserRefresh" */
  delete_d_courseUserRefresh_by_pk?: Maybe<D_CourseUserRefresh>;
  /** delete data from the table: "dataAccessRequest" */
  delete_dataAccessRequest?: Maybe<DataAccessRequest_Mutation_Response>;
  /** delete single row from the table: "dataAccessRequest" */
  delete_dataAccessRequest_by_pk?: Maybe<DataAccessRequest>;
  /** delete data from the table: "direction" */
  delete_direction?: Maybe<Direction_Mutation_Response>;
  /** delete single row from the table: "direction" */
  delete_direction_by_pk?: Maybe<Direction>;
  /** delete data from the table: "emailValidationToken" */
  delete_emailValidationToken?: Maybe<EmailValidationToken_Mutation_Response>;
  /** delete single row from the table: "emailValidationToken" */
  delete_emailValidationToken_by_pk?: Maybe<EmailValidationToken>;
  /** delete data from the table: "fa" */
  delete_fa?: Maybe<Fa_Mutation_Response>;
  /** delete single row from the table: "fa" */
  delete_fa_by_pk?: Maybe<Fa>;
  /** delete data from the table: "faq" */
  delete_faq?: Maybe<Faq_Mutation_Response>;
  /** delete single row from the table: "faq" */
  delete_faq_by_pk?: Maybe<Faq>;
  /** delete data from the table: "group" */
  delete_group?: Maybe<Group_Mutation_Response>;
  /** delete single row from the table: "group" */
  delete_group_by_pk?: Maybe<Group>;
  /** delete data from the table: "home" */
  delete_home?: Maybe<Home_Mutation_Response>;
  /** delete single row from the table: "home" */
  delete_home_by_pk?: Maybe<Home>;
  /** delete data from the table: "i18n" */
  delete_i18n?: Maybe<I18n_Mutation_Response>;
  /** delete single row from the table: "i18n" */
  delete_i18n_by_pk?: Maybe<I18n>;
  /** delete data from the table: "identity" */
  delete_identity?: Maybe<Identity_Mutation_Response>;
  /** delete single row from the table: "identity" */
  delete_identity_by_pk?: Maybe<Identity>;
  /** delete data from the table: "import" */
  delete_import?: Maybe<Import_Mutation_Response>;
  /** delete data from the table: "importGroup" */
  delete_importGroup?: Maybe<ImportGroup_Mutation_Response>;
  /** delete single row from the table: "importGroup" */
  delete_importGroup_by_pk?: Maybe<ImportGroup>;
  /** delete data from the table: "importSkill" */
  delete_importSkill?: Maybe<ImportSkill_Mutation_Response>;
  /** delete single row from the table: "importSkill" */
  delete_importSkill_by_pk?: Maybe<ImportSkill>;
  /** delete single row from the table: "import" */
  delete_import_by_pk?: Maybe<Import>;
  /** delete data from the table: "jobTitle" */
  delete_jobTitle?: Maybe<JobTitle_Mutation_Response>;
  /** delete single row from the table: "jobTitle" */
  delete_jobTitle_by_pk?: Maybe<JobTitle>;
  /** delete data from the table: "module" */
  delete_module?: Maybe<Module_Mutation_Response>;
  /** delete data from the table: "moduleAnnex" */
  delete_moduleAnnex?: Maybe<ModuleAnnex_Mutation_Response>;
  /** delete single row from the table: "moduleAnnex" */
  delete_moduleAnnex_by_pk?: Maybe<ModuleAnnex>;
  /** delete data from the table: "moduleContent" */
  delete_moduleContent?: Maybe<ModuleContent_Mutation_Response>;
  /** delete single row from the table: "moduleContent" */
  delete_moduleContent_by_pk?: Maybe<ModuleContent>;
  /** delete data from the table: "moduleTask" */
  delete_moduleTask?: Maybe<ModuleTask_Mutation_Response>;
  /** delete single row from the table: "moduleTask" */
  delete_moduleTask_by_pk?: Maybe<ModuleTask>;
  /** delete single row from the table: "module" */
  delete_module_by_pk?: Maybe<Module>;
  /** delete data from the table: "organisation" */
  delete_organisation?: Maybe<Organisation_Mutation_Response>;
  /** delete single row from the table: "organisation" */
  delete_organisation_by_pk?: Maybe<Organisation>;
  /** delete data from the table: "passwordResetToken" */
  delete_passwordResetToken?: Maybe<PasswordResetToken_Mutation_Response>;
  /** delete single row from the table: "passwordResetToken" */
  delete_passwordResetToken_by_pk?: Maybe<PasswordResetToken>;
  /** delete data from the table: "refreshToken" */
  delete_refreshToken?: Maybe<RefreshToken_Mutation_Response>;
  /** delete single row from the table: "refreshToken" */
  delete_refreshToken_by_pk?: Maybe<RefreshToken>;
  /** delete data from the table: "role" */
  delete_role?: Maybe<Role_Mutation_Response>;
  /** delete single row from the table: "role" */
  delete_role_by_pk?: Maybe<Role>;
  /** delete data from the table: "sequence" */
  delete_sequence?: Maybe<Sequence_Mutation_Response>;
  /** delete single row from the table: "sequence" */
  delete_sequence_by_pk?: Maybe<Sequence>;
  /** delete data from the table: "sequence_module" */
  delete_sequence_module?: Maybe<Sequence_Module_Mutation_Response>;
  /** delete single row from the table: "sequence_module" */
  delete_sequence_module_by_pk?: Maybe<Sequence_Module>;
  /** delete data from the table: "site" */
  delete_site?: Maybe<Site_Mutation_Response>;
  /** delete single row from the table: "site" */
  delete_site_by_pk?: Maybe<Site>;
  /** delete data from the table: "skill" */
  delete_skill?: Maybe<Skill_Mutation_Response>;
  /** delete data from the table: "skillLevel" */
  delete_skillLevel?: Maybe<SkillLevel_Mutation_Response>;
  /** delete single row from the table: "skillLevel" */
  delete_skillLevel_by_pk?: Maybe<SkillLevel>;
  /** delete single row from the table: "skill" */
  delete_skill_by_pk?: Maybe<Skill>;
  /** delete data from the table: "skill_of_course" */
  delete_skill_of_course?: Maybe<Skill_Of_Course_Mutation_Response>;
  /** delete single row from the table: "skill_of_course" */
  delete_skill_of_course_by_pk?: Maybe<Skill_Of_Course>;
  /** delete data from the table: "tag_course" */
  delete_tag_course?: Maybe<Tag_Course_Mutation_Response>;
  /** delete single row from the table: "tag_course" */
  delete_tag_course_by_pk?: Maybe<Tag_Course>;
  /** delete data from the table: "tag_of_course" */
  delete_tag_of_course?: Maybe<Tag_Of_Course_Mutation_Response>;
  /** delete single row from the table: "tag_of_course" */
  delete_tag_of_course_by_pk?: Maybe<Tag_Of_Course>;
  /** delete data from the table: "thematic" */
  delete_thematic?: Maybe<Thematic_Mutation_Response>;
  /** delete single row from the table: "thematic" */
  delete_thematic_by_pk?: Maybe<Thematic>;
  /** delete data from the table: "translation" */
  delete_translation?: Maybe<Translation_Mutation_Response>;
  /** delete single row from the table: "translation" */
  delete_translation_by_pk?: Maybe<Translation>;
  /** delete data from the table: "user" */
  delete_user?: Maybe<User_Mutation_Response>;
  /** delete data from the table: "userCourseProgress" */
  delete_userCourseProgress?: Maybe<UserCourseProgress_Mutation_Response>;
  /** delete single row from the table: "userCourseProgress" */
  delete_userCourseProgress_by_pk?: Maybe<UserCourseProgress>;
  /** delete data from the table: "userEventSlot" */
  delete_userEventSlot?: Maybe<UserEventSlot_Mutation_Response>;
  /** delete single row from the table: "userEventSlot" */
  delete_userEventSlot_by_pk?: Maybe<UserEventSlot>;
  /** delete data from the table: "userGroup" */
  delete_userGroup?: Maybe<UserGroup_Mutation_Response>;
  /** delete single row from the table: "userGroup" */
  delete_userGroup_by_pk?: Maybe<UserGroup>;
  /** delete data from the table: "userModuleProgress" */
  delete_userModuleProgress?: Maybe<UserModuleProgress_Mutation_Response>;
  /** delete single row from the table: "userModuleProgress" */
  delete_userModuleProgress_by_pk?: Maybe<UserModuleProgress>;
  /** delete data from the table: "userPrivate" */
  delete_userPrivate?: Maybe<UserPrivate_Mutation_Response>;
  /** delete data from the table: "userSkill" */
  delete_userSkill?: Maybe<UserSkill_Mutation_Response>;
  /** delete single row from the table: "userSkill" */
  delete_userSkill_by_pk?: Maybe<UserSkill>;
  /** delete single row from the table: "user" */
  delete_user_by_pk?: Maybe<User>;
  /** delete data from the table: "volunteerPost" */
  delete_volunteerPost?: Maybe<VolunteerPost_Mutation_Response>;
  /** delete single row from the table: "volunteerPost" */
  delete_volunteerPost_by_pk?: Maybe<VolunteerPost>;
  /** delete data from the table: "xapi_statement" */
  delete_xapi_statement?: Maybe<Xapi_Statement_Mutation_Response>;
  /** delete single row from the table: "xapi_statement" */
  delete_xapi_statement_by_pk?: Maybe<Xapi_Statement>;
  /** Duplicate a course */
  duplicateCourse?: Maybe<DuplicateCourseResponse>;
  /** Save a faq entity */
  faq?: Maybe<FaqResponse>;
  /** Save a group */
  group?: Maybe<GroupResponse>;
  hello?: Maybe<Scalars['String']>;
  /** Save a home entity */
  home?: Maybe<HomeResponse>;
  /** Save a group from an import file */
  importGroup?: Maybe<ImportGroupResponse>;
  /** Import JobTitle data */
  importJobTitle?: Maybe<ImportJobTitleResponse>;
  importSkill?: Maybe<ImportSkillResponse>;
  /** Add users to an existing group */
  importUsersToGroup?: Maybe<ImportGroupResponse>;
  /** insert data into the table: "cmsPage" */
  insert_cmsPage?: Maybe<CmsPage_Mutation_Response>;
  /** insert a single row into the table: "cmsPage" */
  insert_cmsPage_one?: Maybe<CmsPage>;
  /** insert data into the table: "contentArticle" */
  insert_contentArticle?: Maybe<ContentArticle_Mutation_Response>;
  /** insert a single row into the table: "contentArticle" */
  insert_contentArticle_one?: Maybe<ContentArticle>;
  /** insert data into the table: "contentAudioTranscript" */
  insert_contentAudioTranscript?: Maybe<ContentAudioTranscript_Mutation_Response>;
  /** insert a single row into the table: "contentAudioTranscript" */
  insert_contentAudioTranscript_one?: Maybe<ContentAudioTranscript>;
  /** insert data into the table: "contentCmi5" */
  insert_contentCmi5?: Maybe<ContentCmi5_Mutation_Response>;
  /** insert data into the table: "contentCmi5AssignableUnit" */
  insert_contentCmi5AssignableUnit?: Maybe<ContentCmi5AssignableUnit_Mutation_Response>;
  /** insert a single row into the table: "contentCmi5AssignableUnit" */
  insert_contentCmi5AssignableUnit_one?: Maybe<ContentCmi5AssignableUnit>;
  /** insert data into the table: "contentCmi5Objective" */
  insert_contentCmi5Objective?: Maybe<ContentCmi5Objective_Mutation_Response>;
  /** insert a single row into the table: "contentCmi5Objective" */
  insert_contentCmi5Objective_one?: Maybe<ContentCmi5Objective>;
  /** insert a single row into the table: "contentCmi5" */
  insert_contentCmi5_one?: Maybe<ContentCmi5>;
  /** insert data into the table: "contentDocument" */
  insert_contentDocument?: Maybe<ContentDocument_Mutation_Response>;
  /** insert data into the table: "contentDocumentUpload" */
  insert_contentDocumentUpload?: Maybe<ContentDocumentUpload_Mutation_Response>;
  /** insert a single row into the table: "contentDocumentUpload" */
  insert_contentDocumentUpload_one?: Maybe<ContentDocumentUpload>;
  /** insert a single row into the table: "contentDocument" */
  insert_contentDocument_one?: Maybe<ContentDocument>;
  /** insert data into the table: "contentEmbed" */
  insert_contentEmbed?: Maybe<ContentEmbed_Mutation_Response>;
  /** insert data into the table: "contentEmbedAudioTranscript" */
  insert_contentEmbedAudioTranscript?: Maybe<ContentEmbedAudioTranscript_Mutation_Response>;
  /** insert a single row into the table: "contentEmbedAudioTranscript" */
  insert_contentEmbedAudioTranscript_one?: Maybe<ContentEmbedAudioTranscript>;
  /** insert a single row into the table: "contentEmbed" */
  insert_contentEmbed_one?: Maybe<ContentEmbed>;
  /** insert data into the table: "contentEvent" */
  insert_contentEvent?: Maybe<ContentEvent_Mutation_Response>;
  /** insert data into the table: "contentEventAttachment" */
  insert_contentEventAttachment?: Maybe<ContentEventAttachment_Mutation_Response>;
  /** insert a single row into the table: "contentEventAttachment" */
  insert_contentEventAttachment_one?: Maybe<ContentEventAttachment>;
  /** insert data into the table: "contentEventSlot" */
  insert_contentEventSlot?: Maybe<ContentEventSlot_Mutation_Response>;
  /** insert data into the table: "contentEventSlot_animator" */
  insert_contentEventSlot_animator?: Maybe<ContentEventSlot_Animator_Mutation_Response>;
  /** insert a single row into the table: "contentEventSlot_animator" */
  insert_contentEventSlot_animator_one?: Maybe<ContentEventSlot_Animator>;
  /** insert a single row into the table: "contentEventSlot" */
  insert_contentEventSlot_one?: Maybe<ContentEventSlot>;
  /** insert a single row into the table: "contentEvent" */
  insert_contentEvent_one?: Maybe<ContentEvent>;
  /** insert data into the table: "contentPodcast" */
  insert_contentPodcast?: Maybe<ContentPodcast_Mutation_Response>;
  /** insert data into the table: "contentPodcastUpload" */
  insert_contentPodcastUpload?: Maybe<ContentPodcastUpload_Mutation_Response>;
  /** insert a single row into the table: "contentPodcastUpload" */
  insert_contentPodcastUpload_one?: Maybe<ContentPodcastUpload>;
  /** insert a single row into the table: "contentPodcast" */
  insert_contentPodcast_one?: Maybe<ContentPodcast>;
  /** insert data into the table: "contentVideo" */
  insert_contentVideo?: Maybe<ContentVideo_Mutation_Response>;
  /** insert data into the table: "contentVideoCaption" */
  insert_contentVideoCaption?: Maybe<ContentVideoCaption_Mutation_Response>;
  /** insert a single row into the table: "contentVideoCaption" */
  insert_contentVideoCaption_one?: Maybe<ContentVideoCaption>;
  /** insert data into the table: "contentVideoDetails" */
  insert_contentVideoDetails?: Maybe<ContentVideoDetails_Mutation_Response>;
  /** insert a single row into the table: "contentVideoDetails" */
  insert_contentVideoDetails_one?: Maybe<ContentVideoDetails>;
  /** insert a single row into the table: "contentVideo" */
  insert_contentVideo_one?: Maybe<ContentVideo>;
  /** insert data into the table: "course" */
  insert_course?: Maybe<Course_Mutation_Response>;
  /** insert data into the table: "courseAssign" */
  insert_courseAssign?: Maybe<CourseAssign_Mutation_Response>;
  /** insert data into the table: "courseAssignEmployee" */
  insert_courseAssignEmployee?: Maybe<CourseAssignEmployee_Mutation_Response>;
  /** insert data into the table: "courseAssignEmployeeDirection" */
  insert_courseAssignEmployeeDirection?: Maybe<CourseAssignEmployeeDirection_Mutation_Response>;
  /** insert a single row into the table: "courseAssignEmployeeDirection" */
  insert_courseAssignEmployeeDirection_one?: Maybe<CourseAssignEmployeeDirection>;
  /** insert data into the table: "courseAssignEmployeeFa" */
  insert_courseAssignEmployeeFa?: Maybe<CourseAssignEmployeeFa_Mutation_Response>;
  /** insert a single row into the table: "courseAssignEmployeeFa" */
  insert_courseAssignEmployeeFa_one?: Maybe<CourseAssignEmployeeFa>;
  /** insert a single row into the table: "courseAssignEmployee" */
  insert_courseAssignEmployee_one?: Maybe<CourseAssignEmployee>;
  /** insert data into the table: "courseAssignGroup" */
  insert_courseAssignGroup?: Maybe<CourseAssignGroup_Mutation_Response>;
  /** insert a single row into the table: "courseAssignGroup" */
  insert_courseAssignGroup_one?: Maybe<CourseAssignGroup>;
  /** insert data into the table: "courseAssignOther" */
  insert_courseAssignOther?: Maybe<CourseAssignOther_Mutation_Response>;
  /** insert a single row into the table: "courseAssignOther" */
  insert_courseAssignOther_one?: Maybe<CourseAssignOther>;
  /** insert data into the table: "courseAssignUser" */
  insert_courseAssignUser?: Maybe<CourseAssignUser_Mutation_Response>;
  /** insert a single row into the table: "courseAssignUser" */
  insert_courseAssignUser_one?: Maybe<CourseAssignUser>;
  /** insert data into the table: "courseAssignVolunteer" */
  insert_courseAssignVolunteer?: Maybe<CourseAssignVolunteer_Mutation_Response>;
  /** insert data into the table: "courseAssignVolunteerFa" */
  insert_courseAssignVolunteerFa?: Maybe<CourseAssignVolunteerFa_Mutation_Response>;
  /** insert a single row into the table: "courseAssignVolunteerFa" */
  insert_courseAssignVolunteerFa_one?: Maybe<CourseAssignVolunteerFa>;
  /** insert data into the table: "courseAssignVolunteerJobTitle" */
  insert_courseAssignVolunteerJobTitle?: Maybe<CourseAssignVolunteerJobTitle_Mutation_Response>;
  /** insert a single row into the table: "courseAssignVolunteerJobTitle" */
  insert_courseAssignVolunteerJobTitle_one?: Maybe<CourseAssignVolunteerJobTitle>;
  /** insert data into the table: "courseAssignVolunteerSite" */
  insert_courseAssignVolunteerSite?: Maybe<CourseAssignVolunteerSite_Mutation_Response>;
  /** insert a single row into the table: "courseAssignVolunteerSite" */
  insert_courseAssignVolunteerSite_one?: Maybe<CourseAssignVolunteerSite>;
  /** insert a single row into the table: "courseAssignVolunteer" */
  insert_courseAssignVolunteer_one?: Maybe<CourseAssignVolunteer>;
  /** insert a single row into the table: "courseAssign" */
  insert_courseAssign_one?: Maybe<CourseAssign>;
  /** insert a single row into the table: "course" */
  insert_course_one?: Maybe<Course>;
  /** insert data into the table: "d_courseUserRefresh" */
  insert_d_courseUserRefresh?: Maybe<D_CourseUserRefresh_Mutation_Response>;
  /** insert a single row into the table: "d_courseUserRefresh" */
  insert_d_courseUserRefresh_one?: Maybe<D_CourseUserRefresh>;
  /** insert data into the table: "dataAccessRequest" */
  insert_dataAccessRequest?: Maybe<DataAccessRequest_Mutation_Response>;
  /** insert a single row into the table: "dataAccessRequest" */
  insert_dataAccessRequest_one?: Maybe<DataAccessRequest>;
  /** insert data into the table: "direction" */
  insert_direction?: Maybe<Direction_Mutation_Response>;
  /** insert a single row into the table: "direction" */
  insert_direction_one?: Maybe<Direction>;
  /** insert data into the table: "emailValidationToken" */
  insert_emailValidationToken?: Maybe<EmailValidationToken_Mutation_Response>;
  /** insert a single row into the table: "emailValidationToken" */
  insert_emailValidationToken_one?: Maybe<EmailValidationToken>;
  /** insert data into the table: "fa" */
  insert_fa?: Maybe<Fa_Mutation_Response>;
  /** insert a single row into the table: "fa" */
  insert_fa_one?: Maybe<Fa>;
  /** insert data into the table: "faq" */
  insert_faq?: Maybe<Faq_Mutation_Response>;
  /** insert a single row into the table: "faq" */
  insert_faq_one?: Maybe<Faq>;
  /** insert data into the table: "group" */
  insert_group?: Maybe<Group_Mutation_Response>;
  /** insert a single row into the table: "group" */
  insert_group_one?: Maybe<Group>;
  /** insert data into the table: "home" */
  insert_home?: Maybe<Home_Mutation_Response>;
  /** insert a single row into the table: "home" */
  insert_home_one?: Maybe<Home>;
  /** insert data into the table: "i18n" */
  insert_i18n?: Maybe<I18n_Mutation_Response>;
  /** insert a single row into the table: "i18n" */
  insert_i18n_one?: Maybe<I18n>;
  /** insert data into the table: "identity" */
  insert_identity?: Maybe<Identity_Mutation_Response>;
  /** insert a single row into the table: "identity" */
  insert_identity_one?: Maybe<Identity>;
  /** insert data into the table: "import" */
  insert_import?: Maybe<Import_Mutation_Response>;
  /** insert data into the table: "importGroup" */
  insert_importGroup?: Maybe<ImportGroup_Mutation_Response>;
  /** insert a single row into the table: "importGroup" */
  insert_importGroup_one?: Maybe<ImportGroup>;
  /** insert data into the table: "importSkill" */
  insert_importSkill?: Maybe<ImportSkill_Mutation_Response>;
  /** insert a single row into the table: "importSkill" */
  insert_importSkill_one?: Maybe<ImportSkill>;
  /** insert a single row into the table: "import" */
  insert_import_one?: Maybe<Import>;
  /** insert data into the table: "jobTitle" */
  insert_jobTitle?: Maybe<JobTitle_Mutation_Response>;
  /** insert a single row into the table: "jobTitle" */
  insert_jobTitle_one?: Maybe<JobTitle>;
  /** insert data into the table: "module" */
  insert_module?: Maybe<Module_Mutation_Response>;
  /** insert data into the table: "moduleAnnex" */
  insert_moduleAnnex?: Maybe<ModuleAnnex_Mutation_Response>;
  /** insert a single row into the table: "moduleAnnex" */
  insert_moduleAnnex_one?: Maybe<ModuleAnnex>;
  /** insert data into the table: "moduleContent" */
  insert_moduleContent?: Maybe<ModuleContent_Mutation_Response>;
  /** insert a single row into the table: "moduleContent" */
  insert_moduleContent_one?: Maybe<ModuleContent>;
  /** insert data into the table: "moduleTask" */
  insert_moduleTask?: Maybe<ModuleTask_Mutation_Response>;
  /** insert a single row into the table: "moduleTask" */
  insert_moduleTask_one?: Maybe<ModuleTask>;
  /** insert a single row into the table: "module" */
  insert_module_one?: Maybe<Module>;
  /** insert data into the table: "organisation" */
  insert_organisation?: Maybe<Organisation_Mutation_Response>;
  /** insert a single row into the table: "organisation" */
  insert_organisation_one?: Maybe<Organisation>;
  /** insert data into the table: "passwordResetToken" */
  insert_passwordResetToken?: Maybe<PasswordResetToken_Mutation_Response>;
  /** insert a single row into the table: "passwordResetToken" */
  insert_passwordResetToken_one?: Maybe<PasswordResetToken>;
  /** insert data into the table: "refreshToken" */
  insert_refreshToken?: Maybe<RefreshToken_Mutation_Response>;
  /** insert a single row into the table: "refreshToken" */
  insert_refreshToken_one?: Maybe<RefreshToken>;
  /** insert data into the table: "role" */
  insert_role?: Maybe<Role_Mutation_Response>;
  /** insert a single row into the table: "role" */
  insert_role_one?: Maybe<Role>;
  /** insert data into the table: "sequence" */
  insert_sequence?: Maybe<Sequence_Mutation_Response>;
  /** insert data into the table: "sequence_module" */
  insert_sequence_module?: Maybe<Sequence_Module_Mutation_Response>;
  /** insert a single row into the table: "sequence_module" */
  insert_sequence_module_one?: Maybe<Sequence_Module>;
  /** insert a single row into the table: "sequence" */
  insert_sequence_one?: Maybe<Sequence>;
  /** insert data into the table: "site" */
  insert_site?: Maybe<Site_Mutation_Response>;
  /** insert a single row into the table: "site" */
  insert_site_one?: Maybe<Site>;
  /** insert data into the table: "skill" */
  insert_skill?: Maybe<Skill_Mutation_Response>;
  /** insert data into the table: "skillLevel" */
  insert_skillLevel?: Maybe<SkillLevel_Mutation_Response>;
  /** insert a single row into the table: "skillLevel" */
  insert_skillLevel_one?: Maybe<SkillLevel>;
  /** insert data into the table: "skill_of_course" */
  insert_skill_of_course?: Maybe<Skill_Of_Course_Mutation_Response>;
  /** insert a single row into the table: "skill_of_course" */
  insert_skill_of_course_one?: Maybe<Skill_Of_Course>;
  /** insert a single row into the table: "skill" */
  insert_skill_one?: Maybe<Skill>;
  /** insert data into the table: "tag_course" */
  insert_tag_course?: Maybe<Tag_Course_Mutation_Response>;
  /** insert a single row into the table: "tag_course" */
  insert_tag_course_one?: Maybe<Tag_Course>;
  /** insert data into the table: "tag_of_course" */
  insert_tag_of_course?: Maybe<Tag_Of_Course_Mutation_Response>;
  /** insert a single row into the table: "tag_of_course" */
  insert_tag_of_course_one?: Maybe<Tag_Of_Course>;
  /** insert data into the table: "thematic" */
  insert_thematic?: Maybe<Thematic_Mutation_Response>;
  /** insert a single row into the table: "thematic" */
  insert_thematic_one?: Maybe<Thematic>;
  /** insert data into the table: "translation" */
  insert_translation?: Maybe<Translation_Mutation_Response>;
  /** insert a single row into the table: "translation" */
  insert_translation_one?: Maybe<Translation>;
  /** insert data into the table: "user" */
  insert_user?: Maybe<User_Mutation_Response>;
  /** insert data into the table: "userCourseProgress" */
  insert_userCourseProgress?: Maybe<UserCourseProgress_Mutation_Response>;
  /** insert a single row into the table: "userCourseProgress" */
  insert_userCourseProgress_one?: Maybe<UserCourseProgress>;
  /** insert data into the table: "userEventSlot" */
  insert_userEventSlot?: Maybe<UserEventSlot_Mutation_Response>;
  /** insert a single row into the table: "userEventSlot" */
  insert_userEventSlot_one?: Maybe<UserEventSlot>;
  /** insert data into the table: "userGroup" */
  insert_userGroup?: Maybe<UserGroup_Mutation_Response>;
  /** insert a single row into the table: "userGroup" */
  insert_userGroup_one?: Maybe<UserGroup>;
  /** insert data into the table: "userModuleProgress" */
  insert_userModuleProgress?: Maybe<UserModuleProgress_Mutation_Response>;
  /** insert a single row into the table: "userModuleProgress" */
  insert_userModuleProgress_one?: Maybe<UserModuleProgress>;
  /** insert data into the table: "userPrivate" */
  insert_userPrivate?: Maybe<UserPrivate_Mutation_Response>;
  /** insert a single row into the table: "userPrivate" */
  insert_userPrivate_one?: Maybe<UserPrivate>;
  /** insert data into the table: "userSkill" */
  insert_userSkill?: Maybe<UserSkill_Mutation_Response>;
  /** insert a single row into the table: "userSkill" */
  insert_userSkill_one?: Maybe<UserSkill>;
  /** insert a single row into the table: "user" */
  insert_user_one?: Maybe<User>;
  /** insert data into the table: "volunteerPost" */
  insert_volunteerPost?: Maybe<VolunteerPost_Mutation_Response>;
  /** insert a single row into the table: "volunteerPost" */
  insert_volunteerPost_one?: Maybe<VolunteerPost>;
  /** insert data into the table: "xapi_statement" */
  insert_xapi_statement?: Maybe<Xapi_Statement_Mutation_Response>;
  /** insert a single row into the table: "xapi_statement" */
  insert_xapi_statement_one?: Maybe<Xapi_Statement>;
  /** Save a learner */
  learner?: Maybe<UserResponse>;
  /** Remove refreshToken from the database and from the headers */
  logout?: Maybe<LogoutResponse>;
  /** Save a module */
  module?: Maybe<ModuleResponse>;
  /** Save organisation attribute */
  organisationAttribute?: Maybe<OrganisationAttributeResponse>;
  /** Publish a course */
  publishCourse?: Maybe<PublicationCourseResponse>;
  /**
   * Verify presence of a refreshToken in the cookies
   * Then set a new one and return an authResult
   */
  queryRefreshToken?: Maybe<RefreshTokenResponse>;
  /** Save a role */
  role?: Maybe<RoleResponse>;
  /** Delete a role */
  roleDeletion?: Maybe<RoleResponse>;
  saveCurrentUserEventSlot?: Maybe<SaveUsersEventSlotResponse>;
  /** Save a dataAccessRequest entity */
  saveDataAccessRequest?: Maybe<DataAccessRequestResponse>;
  /** Save a userEventSlot */
  saveUsersEventSlot?: Maybe<SaveUsersEventSlotResponse>;
  /** Set user attendance to an event slot */
  setUserAttendanceSlot?: Maybe<BasicResponse>;
  /** Anonymize a user in our database */
  setUserStatus?: Maybe<SetUserStatusResponse>;
  setValidateSlotAttendance?: Maybe<SetValidateSlotAttendanceResponse>;
  /** Sign in a user with email and password */
  signIn?: Maybe<SignInResponse>;
  /** Save a skill */
  skill?: Maybe<SkillResponse>;
  /** Start a CMI5 content */
  startCmi5Content?: Maybe<StartCmi5ContentResponse>;
  /** Save a tag */
  tag_course?: Maybe<TagCourseResponse>;
  /** Save a thematic */
  thematic?: Maybe<ThematicResponse>;
  /** Update user module progression. Compared to insert_userModuleProgress_one allow to upsert only progression is higher than the current value in DB */
  updateUserModuleProgression?: Maybe<UpdateUserModuleProgressionResponse>;
  /** update data of the table: "cmsPage" */
  update_cmsPage?: Maybe<CmsPage_Mutation_Response>;
  /** update single row of the table: "cmsPage" */
  update_cmsPage_by_pk?: Maybe<CmsPage>;
  /** update multiples rows of table: "cmsPage" */
  update_cmsPage_many?: Maybe<Array<Maybe<CmsPage_Mutation_Response>>>;
  /** update data of the table: "contentArticle" */
  update_contentArticle?: Maybe<ContentArticle_Mutation_Response>;
  /** update single row of the table: "contentArticle" */
  update_contentArticle_by_pk?: Maybe<ContentArticle>;
  /** update multiples rows of table: "contentArticle" */
  update_contentArticle_many?: Maybe<Array<Maybe<ContentArticle_Mutation_Response>>>;
  /** update data of the table: "contentAudioTranscript" */
  update_contentAudioTranscript?: Maybe<ContentAudioTranscript_Mutation_Response>;
  /** update single row of the table: "contentAudioTranscript" */
  update_contentAudioTranscript_by_pk?: Maybe<ContentAudioTranscript>;
  /** update multiples rows of table: "contentAudioTranscript" */
  update_contentAudioTranscript_many?: Maybe<Array<Maybe<ContentAudioTranscript_Mutation_Response>>>;
  /** update data of the table: "contentCmi5" */
  update_contentCmi5?: Maybe<ContentCmi5_Mutation_Response>;
  /** update data of the table: "contentCmi5AssignableUnit" */
  update_contentCmi5AssignableUnit?: Maybe<ContentCmi5AssignableUnit_Mutation_Response>;
  /** update single row of the table: "contentCmi5AssignableUnit" */
  update_contentCmi5AssignableUnit_by_pk?: Maybe<ContentCmi5AssignableUnit>;
  /** update multiples rows of table: "contentCmi5AssignableUnit" */
  update_contentCmi5AssignableUnit_many?: Maybe<Array<Maybe<ContentCmi5AssignableUnit_Mutation_Response>>>;
  /** update data of the table: "contentCmi5Objective" */
  update_contentCmi5Objective?: Maybe<ContentCmi5Objective_Mutation_Response>;
  /** update single row of the table: "contentCmi5Objective" */
  update_contentCmi5Objective_by_pk?: Maybe<ContentCmi5Objective>;
  /** update multiples rows of table: "contentCmi5Objective" */
  update_contentCmi5Objective_many?: Maybe<Array<Maybe<ContentCmi5Objective_Mutation_Response>>>;
  /** update single row of the table: "contentCmi5" */
  update_contentCmi5_by_pk?: Maybe<ContentCmi5>;
  /** update multiples rows of table: "contentCmi5" */
  update_contentCmi5_many?: Maybe<Array<Maybe<ContentCmi5_Mutation_Response>>>;
  /** update data of the table: "contentDocument" */
  update_contentDocument?: Maybe<ContentDocument_Mutation_Response>;
  /** update data of the table: "contentDocumentUpload" */
  update_contentDocumentUpload?: Maybe<ContentDocumentUpload_Mutation_Response>;
  /** update single row of the table: "contentDocumentUpload" */
  update_contentDocumentUpload_by_pk?: Maybe<ContentDocumentUpload>;
  /** update multiples rows of table: "contentDocumentUpload" */
  update_contentDocumentUpload_many?: Maybe<Array<Maybe<ContentDocumentUpload_Mutation_Response>>>;
  /** update single row of the table: "contentDocument" */
  update_contentDocument_by_pk?: Maybe<ContentDocument>;
  /** update multiples rows of table: "contentDocument" */
  update_contentDocument_many?: Maybe<Array<Maybe<ContentDocument_Mutation_Response>>>;
  /** update data of the table: "contentEmbed" */
  update_contentEmbed?: Maybe<ContentEmbed_Mutation_Response>;
  /** update data of the table: "contentEmbedAudioTranscript" */
  update_contentEmbedAudioTranscript?: Maybe<ContentEmbedAudioTranscript_Mutation_Response>;
  /** update single row of the table: "contentEmbedAudioTranscript" */
  update_contentEmbedAudioTranscript_by_pk?: Maybe<ContentEmbedAudioTranscript>;
  /** update multiples rows of table: "contentEmbedAudioTranscript" */
  update_contentEmbedAudioTranscript_many?: Maybe<Array<Maybe<ContentEmbedAudioTranscript_Mutation_Response>>>;
  /** update single row of the table: "contentEmbed" */
  update_contentEmbed_by_pk?: Maybe<ContentEmbed>;
  /** update multiples rows of table: "contentEmbed" */
  update_contentEmbed_many?: Maybe<Array<Maybe<ContentEmbed_Mutation_Response>>>;
  /** update data of the table: "contentEvent" */
  update_contentEvent?: Maybe<ContentEvent_Mutation_Response>;
  /** update data of the table: "contentEventAttachment" */
  update_contentEventAttachment?: Maybe<ContentEventAttachment_Mutation_Response>;
  /** update single row of the table: "contentEventAttachment" */
  update_contentEventAttachment_by_pk?: Maybe<ContentEventAttachment>;
  /** update multiples rows of table: "contentEventAttachment" */
  update_contentEventAttachment_many?: Maybe<Array<Maybe<ContentEventAttachment_Mutation_Response>>>;
  /** update data of the table: "contentEventSlot" */
  update_contentEventSlot?: Maybe<ContentEventSlot_Mutation_Response>;
  /** update data of the table: "contentEventSlot_animator" */
  update_contentEventSlot_animator?: Maybe<ContentEventSlot_Animator_Mutation_Response>;
  /** update single row of the table: "contentEventSlot_animator" */
  update_contentEventSlot_animator_by_pk?: Maybe<ContentEventSlot_Animator>;
  /** update multiples rows of table: "contentEventSlot_animator" */
  update_contentEventSlot_animator_many?: Maybe<Array<Maybe<ContentEventSlot_Animator_Mutation_Response>>>;
  /** update single row of the table: "contentEventSlot" */
  update_contentEventSlot_by_pk?: Maybe<ContentEventSlot>;
  /** update multiples rows of table: "contentEventSlot" */
  update_contentEventSlot_many?: Maybe<Array<Maybe<ContentEventSlot_Mutation_Response>>>;
  /** update single row of the table: "contentEvent" */
  update_contentEvent_by_pk?: Maybe<ContentEvent>;
  /** update multiples rows of table: "contentEvent" */
  update_contentEvent_many?: Maybe<Array<Maybe<ContentEvent_Mutation_Response>>>;
  /** update data of the table: "contentPodcast" */
  update_contentPodcast?: Maybe<ContentPodcast_Mutation_Response>;
  /** update data of the table: "contentPodcastUpload" */
  update_contentPodcastUpload?: Maybe<ContentPodcastUpload_Mutation_Response>;
  /** update single row of the table: "contentPodcastUpload" */
  update_contentPodcastUpload_by_pk?: Maybe<ContentPodcastUpload>;
  /** update multiples rows of table: "contentPodcastUpload" */
  update_contentPodcastUpload_many?: Maybe<Array<Maybe<ContentPodcastUpload_Mutation_Response>>>;
  /** update single row of the table: "contentPodcast" */
  update_contentPodcast_by_pk?: Maybe<ContentPodcast>;
  /** update multiples rows of table: "contentPodcast" */
  update_contentPodcast_many?: Maybe<Array<Maybe<ContentPodcast_Mutation_Response>>>;
  /** update data of the table: "contentVideo" */
  update_contentVideo?: Maybe<ContentVideo_Mutation_Response>;
  /** update data of the table: "contentVideoCaption" */
  update_contentVideoCaption?: Maybe<ContentVideoCaption_Mutation_Response>;
  /** update single row of the table: "contentVideoCaption" */
  update_contentVideoCaption_by_pk?: Maybe<ContentVideoCaption>;
  /** update multiples rows of table: "contentVideoCaption" */
  update_contentVideoCaption_many?: Maybe<Array<Maybe<ContentVideoCaption_Mutation_Response>>>;
  /** update data of the table: "contentVideoDetails" */
  update_contentVideoDetails?: Maybe<ContentVideoDetails_Mutation_Response>;
  /** update single row of the table: "contentVideoDetails" */
  update_contentVideoDetails_by_pk?: Maybe<ContentVideoDetails>;
  /** update multiples rows of table: "contentVideoDetails" */
  update_contentVideoDetails_many?: Maybe<Array<Maybe<ContentVideoDetails_Mutation_Response>>>;
  /** update single row of the table: "contentVideo" */
  update_contentVideo_by_pk?: Maybe<ContentVideo>;
  /** update multiples rows of table: "contentVideo" */
  update_contentVideo_many?: Maybe<Array<Maybe<ContentVideo_Mutation_Response>>>;
  /** update data of the table: "course" */
  update_course?: Maybe<Course_Mutation_Response>;
  /** update data of the table: "courseAssign" */
  update_courseAssign?: Maybe<CourseAssign_Mutation_Response>;
  /** update data of the table: "courseAssignEmployee" */
  update_courseAssignEmployee?: Maybe<CourseAssignEmployee_Mutation_Response>;
  /** update data of the table: "courseAssignEmployeeDirection" */
  update_courseAssignEmployeeDirection?: Maybe<CourseAssignEmployeeDirection_Mutation_Response>;
  /** update single row of the table: "courseAssignEmployeeDirection" */
  update_courseAssignEmployeeDirection_by_pk?: Maybe<CourseAssignEmployeeDirection>;
  /** update multiples rows of table: "courseAssignEmployeeDirection" */
  update_courseAssignEmployeeDirection_many?: Maybe<Array<Maybe<CourseAssignEmployeeDirection_Mutation_Response>>>;
  /** update data of the table: "courseAssignEmployeeFa" */
  update_courseAssignEmployeeFa?: Maybe<CourseAssignEmployeeFa_Mutation_Response>;
  /** update single row of the table: "courseAssignEmployeeFa" */
  update_courseAssignEmployeeFa_by_pk?: Maybe<CourseAssignEmployeeFa>;
  /** update multiples rows of table: "courseAssignEmployeeFa" */
  update_courseAssignEmployeeFa_many?: Maybe<Array<Maybe<CourseAssignEmployeeFa_Mutation_Response>>>;
  /** update single row of the table: "courseAssignEmployee" */
  update_courseAssignEmployee_by_pk?: Maybe<CourseAssignEmployee>;
  /** update multiples rows of table: "courseAssignEmployee" */
  update_courseAssignEmployee_many?: Maybe<Array<Maybe<CourseAssignEmployee_Mutation_Response>>>;
  /** update data of the table: "courseAssignGroup" */
  update_courseAssignGroup?: Maybe<CourseAssignGroup_Mutation_Response>;
  /** update single row of the table: "courseAssignGroup" */
  update_courseAssignGroup_by_pk?: Maybe<CourseAssignGroup>;
  /** update multiples rows of table: "courseAssignGroup" */
  update_courseAssignGroup_many?: Maybe<Array<Maybe<CourseAssignGroup_Mutation_Response>>>;
  /** update data of the table: "courseAssignOther" */
  update_courseAssignOther?: Maybe<CourseAssignOther_Mutation_Response>;
  /** update single row of the table: "courseAssignOther" */
  update_courseAssignOther_by_pk?: Maybe<CourseAssignOther>;
  /** update multiples rows of table: "courseAssignOther" */
  update_courseAssignOther_many?: Maybe<Array<Maybe<CourseAssignOther_Mutation_Response>>>;
  /** update data of the table: "courseAssignUser" */
  update_courseAssignUser?: Maybe<CourseAssignUser_Mutation_Response>;
  /** update single row of the table: "courseAssignUser" */
  update_courseAssignUser_by_pk?: Maybe<CourseAssignUser>;
  /** update multiples rows of table: "courseAssignUser" */
  update_courseAssignUser_many?: Maybe<Array<Maybe<CourseAssignUser_Mutation_Response>>>;
  /** update data of the table: "courseAssignVolunteer" */
  update_courseAssignVolunteer?: Maybe<CourseAssignVolunteer_Mutation_Response>;
  /** update data of the table: "courseAssignVolunteerFa" */
  update_courseAssignVolunteerFa?: Maybe<CourseAssignVolunteerFa_Mutation_Response>;
  /** update single row of the table: "courseAssignVolunteerFa" */
  update_courseAssignVolunteerFa_by_pk?: Maybe<CourseAssignVolunteerFa>;
  /** update multiples rows of table: "courseAssignVolunteerFa" */
  update_courseAssignVolunteerFa_many?: Maybe<Array<Maybe<CourseAssignVolunteerFa_Mutation_Response>>>;
  /** update data of the table: "courseAssignVolunteerJobTitle" */
  update_courseAssignVolunteerJobTitle?: Maybe<CourseAssignVolunteerJobTitle_Mutation_Response>;
  /** update single row of the table: "courseAssignVolunteerJobTitle" */
  update_courseAssignVolunteerJobTitle_by_pk?: Maybe<CourseAssignVolunteerJobTitle>;
  /** update multiples rows of table: "courseAssignVolunteerJobTitle" */
  update_courseAssignVolunteerJobTitle_many?: Maybe<Array<Maybe<CourseAssignVolunteerJobTitle_Mutation_Response>>>;
  /** update data of the table: "courseAssignVolunteerSite" */
  update_courseAssignVolunteerSite?: Maybe<CourseAssignVolunteerSite_Mutation_Response>;
  /** update single row of the table: "courseAssignVolunteerSite" */
  update_courseAssignVolunteerSite_by_pk?: Maybe<CourseAssignVolunteerSite>;
  /** update multiples rows of table: "courseAssignVolunteerSite" */
  update_courseAssignVolunteerSite_many?: Maybe<Array<Maybe<CourseAssignVolunteerSite_Mutation_Response>>>;
  /** update single row of the table: "courseAssignVolunteer" */
  update_courseAssignVolunteer_by_pk?: Maybe<CourseAssignVolunteer>;
  /** update multiples rows of table: "courseAssignVolunteer" */
  update_courseAssignVolunteer_many?: Maybe<Array<Maybe<CourseAssignVolunteer_Mutation_Response>>>;
  /** update single row of the table: "courseAssign" */
  update_courseAssign_by_pk?: Maybe<CourseAssign>;
  /** update multiples rows of table: "courseAssign" */
  update_courseAssign_many?: Maybe<Array<Maybe<CourseAssign_Mutation_Response>>>;
  /** update single row of the table: "course" */
  update_course_by_pk?: Maybe<Course>;
  /** update multiples rows of table: "course" */
  update_course_many?: Maybe<Array<Maybe<Course_Mutation_Response>>>;
  /** update data of the table: "d_courseUserRefresh" */
  update_d_courseUserRefresh?: Maybe<D_CourseUserRefresh_Mutation_Response>;
  /** update single row of the table: "d_courseUserRefresh" */
  update_d_courseUserRefresh_by_pk?: Maybe<D_CourseUserRefresh>;
  /** update multiples rows of table: "d_courseUserRefresh" */
  update_d_courseUserRefresh_many?: Maybe<Array<Maybe<D_CourseUserRefresh_Mutation_Response>>>;
  /** update data of the table: "dataAccessRequest" */
  update_dataAccessRequest?: Maybe<DataAccessRequest_Mutation_Response>;
  /** update single row of the table: "dataAccessRequest" */
  update_dataAccessRequest_by_pk?: Maybe<DataAccessRequest>;
  /** update multiples rows of table: "dataAccessRequest" */
  update_dataAccessRequest_many?: Maybe<Array<Maybe<DataAccessRequest_Mutation_Response>>>;
  /** update data of the table: "direction" */
  update_direction?: Maybe<Direction_Mutation_Response>;
  /** update single row of the table: "direction" */
  update_direction_by_pk?: Maybe<Direction>;
  /** update multiples rows of table: "direction" */
  update_direction_many?: Maybe<Array<Maybe<Direction_Mutation_Response>>>;
  /** update data of the table: "emailValidationToken" */
  update_emailValidationToken?: Maybe<EmailValidationToken_Mutation_Response>;
  /** update single row of the table: "emailValidationToken" */
  update_emailValidationToken_by_pk?: Maybe<EmailValidationToken>;
  /** update multiples rows of table: "emailValidationToken" */
  update_emailValidationToken_many?: Maybe<Array<Maybe<EmailValidationToken_Mutation_Response>>>;
  /** update data of the table: "fa" */
  update_fa?: Maybe<Fa_Mutation_Response>;
  /** update single row of the table: "fa" */
  update_fa_by_pk?: Maybe<Fa>;
  /** update multiples rows of table: "fa" */
  update_fa_many?: Maybe<Array<Maybe<Fa_Mutation_Response>>>;
  /** update data of the table: "faq" */
  update_faq?: Maybe<Faq_Mutation_Response>;
  /** update single row of the table: "faq" */
  update_faq_by_pk?: Maybe<Faq>;
  /** update multiples rows of table: "faq" */
  update_faq_many?: Maybe<Array<Maybe<Faq_Mutation_Response>>>;
  /** update data of the table: "group" */
  update_group?: Maybe<Group_Mutation_Response>;
  /** update single row of the table: "group" */
  update_group_by_pk?: Maybe<Group>;
  /** update multiples rows of table: "group" */
  update_group_many?: Maybe<Array<Maybe<Group_Mutation_Response>>>;
  /** update data of the table: "home" */
  update_home?: Maybe<Home_Mutation_Response>;
  /** update single row of the table: "home" */
  update_home_by_pk?: Maybe<Home>;
  /** update multiples rows of table: "home" */
  update_home_many?: Maybe<Array<Maybe<Home_Mutation_Response>>>;
  /** update data of the table: "i18n" */
  update_i18n?: Maybe<I18n_Mutation_Response>;
  /** update single row of the table: "i18n" */
  update_i18n_by_pk?: Maybe<I18n>;
  /** update multiples rows of table: "i18n" */
  update_i18n_many?: Maybe<Array<Maybe<I18n_Mutation_Response>>>;
  /** update data of the table: "identity" */
  update_identity?: Maybe<Identity_Mutation_Response>;
  /** update single row of the table: "identity" */
  update_identity_by_pk?: Maybe<Identity>;
  /** update multiples rows of table: "identity" */
  update_identity_many?: Maybe<Array<Maybe<Identity_Mutation_Response>>>;
  /** update data of the table: "import" */
  update_import?: Maybe<Import_Mutation_Response>;
  /** update data of the table: "importGroup" */
  update_importGroup?: Maybe<ImportGroup_Mutation_Response>;
  /** update single row of the table: "importGroup" */
  update_importGroup_by_pk?: Maybe<ImportGroup>;
  /** update multiples rows of table: "importGroup" */
  update_importGroup_many?: Maybe<Array<Maybe<ImportGroup_Mutation_Response>>>;
  /** update data of the table: "importSkill" */
  update_importSkill?: Maybe<ImportSkill_Mutation_Response>;
  /** update single row of the table: "importSkill" */
  update_importSkill_by_pk?: Maybe<ImportSkill>;
  /** update multiples rows of table: "importSkill" */
  update_importSkill_many?: Maybe<Array<Maybe<ImportSkill_Mutation_Response>>>;
  /** update single row of the table: "import" */
  update_import_by_pk?: Maybe<Import>;
  /** update multiples rows of table: "import" */
  update_import_many?: Maybe<Array<Maybe<Import_Mutation_Response>>>;
  /** update data of the table: "jobTitle" */
  update_jobTitle?: Maybe<JobTitle_Mutation_Response>;
  /** update single row of the table: "jobTitle" */
  update_jobTitle_by_pk?: Maybe<JobTitle>;
  /** update multiples rows of table: "jobTitle" */
  update_jobTitle_many?: Maybe<Array<Maybe<JobTitle_Mutation_Response>>>;
  /** update data of the table: "module" */
  update_module?: Maybe<Module_Mutation_Response>;
  /** update data of the table: "moduleAnnex" */
  update_moduleAnnex?: Maybe<ModuleAnnex_Mutation_Response>;
  /** update single row of the table: "moduleAnnex" */
  update_moduleAnnex_by_pk?: Maybe<ModuleAnnex>;
  /** update multiples rows of table: "moduleAnnex" */
  update_moduleAnnex_many?: Maybe<Array<Maybe<ModuleAnnex_Mutation_Response>>>;
  /** update data of the table: "moduleContent" */
  update_moduleContent?: Maybe<ModuleContent_Mutation_Response>;
  /** update single row of the table: "moduleContent" */
  update_moduleContent_by_pk?: Maybe<ModuleContent>;
  /** update multiples rows of table: "moduleContent" */
  update_moduleContent_many?: Maybe<Array<Maybe<ModuleContent_Mutation_Response>>>;
  /** update data of the table: "moduleTask" */
  update_moduleTask?: Maybe<ModuleTask_Mutation_Response>;
  /** update single row of the table: "moduleTask" */
  update_moduleTask_by_pk?: Maybe<ModuleTask>;
  /** update multiples rows of table: "moduleTask" */
  update_moduleTask_many?: Maybe<Array<Maybe<ModuleTask_Mutation_Response>>>;
  /** update single row of the table: "module" */
  update_module_by_pk?: Maybe<Module>;
  /** update multiples rows of table: "module" */
  update_module_many?: Maybe<Array<Maybe<Module_Mutation_Response>>>;
  /** update data of the table: "organisation" */
  update_organisation?: Maybe<Organisation_Mutation_Response>;
  /** update single row of the table: "organisation" */
  update_organisation_by_pk?: Maybe<Organisation>;
  /** update multiples rows of table: "organisation" */
  update_organisation_many?: Maybe<Array<Maybe<Organisation_Mutation_Response>>>;
  /** update data of the table: "passwordResetToken" */
  update_passwordResetToken?: Maybe<PasswordResetToken_Mutation_Response>;
  /** update single row of the table: "passwordResetToken" */
  update_passwordResetToken_by_pk?: Maybe<PasswordResetToken>;
  /** update multiples rows of table: "passwordResetToken" */
  update_passwordResetToken_many?: Maybe<Array<Maybe<PasswordResetToken_Mutation_Response>>>;
  /** update data of the table: "refreshToken" */
  update_refreshToken?: Maybe<RefreshToken_Mutation_Response>;
  /** update single row of the table: "refreshToken" */
  update_refreshToken_by_pk?: Maybe<RefreshToken>;
  /** update multiples rows of table: "refreshToken" */
  update_refreshToken_many?: Maybe<Array<Maybe<RefreshToken_Mutation_Response>>>;
  /** update data of the table: "role" */
  update_role?: Maybe<Role_Mutation_Response>;
  /** update single row of the table: "role" */
  update_role_by_pk?: Maybe<Role>;
  /** update multiples rows of table: "role" */
  update_role_many?: Maybe<Array<Maybe<Role_Mutation_Response>>>;
  /** update data of the table: "sequence" */
  update_sequence?: Maybe<Sequence_Mutation_Response>;
  /** update single row of the table: "sequence" */
  update_sequence_by_pk?: Maybe<Sequence>;
  /** update multiples rows of table: "sequence" */
  update_sequence_many?: Maybe<Array<Maybe<Sequence_Mutation_Response>>>;
  /** update data of the table: "sequence_module" */
  update_sequence_module?: Maybe<Sequence_Module_Mutation_Response>;
  /** update single row of the table: "sequence_module" */
  update_sequence_module_by_pk?: Maybe<Sequence_Module>;
  /** update multiples rows of table: "sequence_module" */
  update_sequence_module_many?: Maybe<Array<Maybe<Sequence_Module_Mutation_Response>>>;
  /** update data of the table: "site" */
  update_site?: Maybe<Site_Mutation_Response>;
  /** update single row of the table: "site" */
  update_site_by_pk?: Maybe<Site>;
  /** update multiples rows of table: "site" */
  update_site_many?: Maybe<Array<Maybe<Site_Mutation_Response>>>;
  /** update data of the table: "skill" */
  update_skill?: Maybe<Skill_Mutation_Response>;
  /** update data of the table: "skillLevel" */
  update_skillLevel?: Maybe<SkillLevel_Mutation_Response>;
  /** update single row of the table: "skillLevel" */
  update_skillLevel_by_pk?: Maybe<SkillLevel>;
  /** update multiples rows of table: "skillLevel" */
  update_skillLevel_many?: Maybe<Array<Maybe<SkillLevel_Mutation_Response>>>;
  /** update single row of the table: "skill" */
  update_skill_by_pk?: Maybe<Skill>;
  /** update multiples rows of table: "skill" */
  update_skill_many?: Maybe<Array<Maybe<Skill_Mutation_Response>>>;
  /** update data of the table: "skill_of_course" */
  update_skill_of_course?: Maybe<Skill_Of_Course_Mutation_Response>;
  /** update single row of the table: "skill_of_course" */
  update_skill_of_course_by_pk?: Maybe<Skill_Of_Course>;
  /** update multiples rows of table: "skill_of_course" */
  update_skill_of_course_many?: Maybe<Array<Maybe<Skill_Of_Course_Mutation_Response>>>;
  /** update data of the table: "tag_course" */
  update_tag_course?: Maybe<Tag_Course_Mutation_Response>;
  /** update single row of the table: "tag_course" */
  update_tag_course_by_pk?: Maybe<Tag_Course>;
  /** update multiples rows of table: "tag_course" */
  update_tag_course_many?: Maybe<Array<Maybe<Tag_Course_Mutation_Response>>>;
  /** update data of the table: "tag_of_course" */
  update_tag_of_course?: Maybe<Tag_Of_Course_Mutation_Response>;
  /** update single row of the table: "tag_of_course" */
  update_tag_of_course_by_pk?: Maybe<Tag_Of_Course>;
  /** update multiples rows of table: "tag_of_course" */
  update_tag_of_course_many?: Maybe<Array<Maybe<Tag_Of_Course_Mutation_Response>>>;
  /** update data of the table: "thematic" */
  update_thematic?: Maybe<Thematic_Mutation_Response>;
  /** update single row of the table: "thematic" */
  update_thematic_by_pk?: Maybe<Thematic>;
  /** update multiples rows of table: "thematic" */
  update_thematic_many?: Maybe<Array<Maybe<Thematic_Mutation_Response>>>;
  /** update data of the table: "translation" */
  update_translation?: Maybe<Translation_Mutation_Response>;
  /** update single row of the table: "translation" */
  update_translation_by_pk?: Maybe<Translation>;
  /** update multiples rows of table: "translation" */
  update_translation_many?: Maybe<Array<Maybe<Translation_Mutation_Response>>>;
  /** update data of the table: "user" */
  update_user?: Maybe<User_Mutation_Response>;
  /** update data of the table: "userCourseProgress" */
  update_userCourseProgress?: Maybe<UserCourseProgress_Mutation_Response>;
  /** update single row of the table: "userCourseProgress" */
  update_userCourseProgress_by_pk?: Maybe<UserCourseProgress>;
  /** update multiples rows of table: "userCourseProgress" */
  update_userCourseProgress_many?: Maybe<Array<Maybe<UserCourseProgress_Mutation_Response>>>;
  /** update data of the table: "userEventSlot" */
  update_userEventSlot?: Maybe<UserEventSlot_Mutation_Response>;
  /** update single row of the table: "userEventSlot" */
  update_userEventSlot_by_pk?: Maybe<UserEventSlot>;
  /** update multiples rows of table: "userEventSlot" */
  update_userEventSlot_many?: Maybe<Array<Maybe<UserEventSlot_Mutation_Response>>>;
  /** update data of the table: "userGroup" */
  update_userGroup?: Maybe<UserGroup_Mutation_Response>;
  /** update single row of the table: "userGroup" */
  update_userGroup_by_pk?: Maybe<UserGroup>;
  /** update multiples rows of table: "userGroup" */
  update_userGroup_many?: Maybe<Array<Maybe<UserGroup_Mutation_Response>>>;
  /** update data of the table: "userModuleProgress" */
  update_userModuleProgress?: Maybe<UserModuleProgress_Mutation_Response>;
  /** update single row of the table: "userModuleProgress" */
  update_userModuleProgress_by_pk?: Maybe<UserModuleProgress>;
  /** update multiples rows of table: "userModuleProgress" */
  update_userModuleProgress_many?: Maybe<Array<Maybe<UserModuleProgress_Mutation_Response>>>;
  /** update data of the table: "userPrivate" */
  update_userPrivate?: Maybe<UserPrivate_Mutation_Response>;
  /** update multiples rows of table: "userPrivate" */
  update_userPrivate_many?: Maybe<Array<Maybe<UserPrivate_Mutation_Response>>>;
  /** update data of the table: "userSkill" */
  update_userSkill?: Maybe<UserSkill_Mutation_Response>;
  /** update single row of the table: "userSkill" */
  update_userSkill_by_pk?: Maybe<UserSkill>;
  /** update multiples rows of table: "userSkill" */
  update_userSkill_many?: Maybe<Array<Maybe<UserSkill_Mutation_Response>>>;
  /** update single row of the table: "user" */
  update_user_by_pk?: Maybe<User>;
  /** update multiples rows of table: "user" */
  update_user_many?: Maybe<Array<Maybe<User_Mutation_Response>>>;
  /** update data of the table: "volunteerPost" */
  update_volunteerPost?: Maybe<VolunteerPost_Mutation_Response>;
  /** update single row of the table: "volunteerPost" */
  update_volunteerPost_by_pk?: Maybe<VolunteerPost>;
  /** update multiples rows of table: "volunteerPost" */
  update_volunteerPost_many?: Maybe<Array<Maybe<VolunteerPost_Mutation_Response>>>;
  /** update data of the table: "xapi_statement" */
  update_xapi_statement?: Maybe<Xapi_Statement_Mutation_Response>;
  /** update single row of the table: "xapi_statement" */
  update_xapi_statement_by_pk?: Maybe<Xapi_Statement>;
  /** update multiples rows of table: "xapi_statement" */
  update_xapi_statement_many?: Maybe<Array<Maybe<Xapi_Statement_Mutation_Response>>>;
  validateDataAccessRequest?: Maybe<ValidateDataAccessRequestResponse>;
  /** Save a volunteer attribute */
  volunteerAttributes?: Maybe<VolunteerAttributesResponse>;
};


/** mutation root */
export type Mutation_RootAdminArgs = {
  input: UserInput;
};


/** mutation root */
export type Mutation_RootAnonymizeArgs = {
  userId: Scalars['ID'];
};


/** mutation root */
export type Mutation_RootAttributesArgs = {
  input: AttributesInput;
};


/** mutation root */
export type Mutation_RootCmsPageArgs = {
  input: CmsPageInput;
};


/** mutation root */
export type Mutation_RootCourseArgs = {
  input: CourseInput;
};


/** mutation root */
export type Mutation_RootCourseAssignArgs = {
  input: CourseAssignInput;
};


/** mutation root */
export type Mutation_RootCreateDownloadUrlArgs = {
  keys: Array<Scalars['String']>;
};


/** mutation root */
export type Mutation_RootCreateUploadUrlArgs = {
  colisionId?: Maybe<Scalars['String']>;
  fileName: Scalars['String'];
  folderName?: Maybe<Scalars['String']>;
};


/** mutation root */
export type Mutation_RootDeleteDraftCourseArgs = {
  id: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDeleteModuleByIdArgs = {
  input: DeleteModuleByIdInput;
};


/** mutation root */
export type Mutation_RootDeleteUserFromEventSlotArgs = {
  input: DeleteUserFromEventSlotInput;
};


/** mutation root */
export type Mutation_RootDelete_CmsPageArgs = {
  where: CmsPage_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_CmsPage_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_ContentArticleArgs = {
  where: ContentArticle_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_ContentArticle_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_ContentAudioTranscriptArgs = {
  where: ContentAudioTranscript_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_ContentAudioTranscript_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_ContentCmi5Args = {
  where: ContentCmi5_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_ContentCmi5AssignableUnitArgs = {
  where: ContentCmi5AssignableUnit_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_ContentCmi5AssignableUnit_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_ContentCmi5ObjectiveArgs = {
  where: ContentCmi5Objective_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_ContentCmi5Objective_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_ContentCmi5_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_ContentDocumentArgs = {
  where: ContentDocument_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_ContentDocumentUploadArgs = {
  where: ContentDocumentUpload_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_ContentDocumentUpload_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_ContentDocument_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_ContentEmbedArgs = {
  where: ContentEmbed_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_ContentEmbedAudioTranscriptArgs = {
  where: ContentEmbedAudioTranscript_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_ContentEmbedAudioTranscript_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_ContentEmbed_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_ContentEventArgs = {
  where: ContentEvent_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_ContentEventAttachmentArgs = {
  where: ContentEventAttachment_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_ContentEventAttachment_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_ContentEventSlotArgs = {
  where: ContentEventSlot_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_ContentEventSlot_AnimatorArgs = {
  where: ContentEventSlot_Animator_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_ContentEventSlot_Animator_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_ContentEventSlot_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_ContentEvent_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_ContentPodcastArgs = {
  where: ContentPodcast_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_ContentPodcastUploadArgs = {
  where: ContentPodcastUpload_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_ContentPodcastUpload_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_ContentPodcast_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_ContentVideoArgs = {
  where: ContentVideo_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_ContentVideoCaptionArgs = {
  where: ContentVideoCaption_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_ContentVideoCaption_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_ContentVideoDetailsArgs = {
  where: ContentVideoDetails_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_ContentVideoDetails_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_ContentVideo_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_CourseArgs = {
  where: Course_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_CourseAssignArgs = {
  where: CourseAssign_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_CourseAssignEmployeeArgs = {
  where: CourseAssignEmployee_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_CourseAssignEmployeeDirectionArgs = {
  where: CourseAssignEmployeeDirection_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_CourseAssignEmployeeDirection_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_CourseAssignEmployeeFaArgs = {
  where: CourseAssignEmployeeFa_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_CourseAssignEmployeeFa_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_CourseAssignEmployee_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_CourseAssignGroupArgs = {
  where: CourseAssignGroup_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_CourseAssignGroup_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_CourseAssignOtherArgs = {
  where: CourseAssignOther_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_CourseAssignOther_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_CourseAssignUserArgs = {
  where: CourseAssignUser_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_CourseAssignUser_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_CourseAssignVolunteerArgs = {
  where: CourseAssignVolunteer_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_CourseAssignVolunteerFaArgs = {
  where: CourseAssignVolunteerFa_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_CourseAssignVolunteerFa_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_CourseAssignVolunteerJobTitleArgs = {
  where: CourseAssignVolunteerJobTitle_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_CourseAssignVolunteerJobTitle_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_CourseAssignVolunteerSiteArgs = {
  where: CourseAssignVolunteerSite_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_CourseAssignVolunteerSite_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_CourseAssignVolunteer_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_CourseAssign_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Course_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_D_CourseUserRefreshArgs = {
  where: D_CourseUserRefresh_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_D_CourseUserRefresh_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_DataAccessRequestArgs = {
  where: DataAccessRequest_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_DataAccessRequest_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_DirectionArgs = {
  where: Direction_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Direction_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_EmailValidationTokenArgs = {
  where: EmailValidationToken_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_EmailValidationToken_By_PkArgs = {
  userId: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_FaArgs = {
  where: Fa_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Fa_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_FaqArgs = {
  where: Faq_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Faq_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_GroupArgs = {
  where: Group_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Group_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_HomeArgs = {
  where: Home_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Home_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_I18nArgs = {
  where: I18n_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_I18n_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_IdentityArgs = {
  where: Identity_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Identity_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_ImportArgs = {
  where: Import_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_ImportGroupArgs = {
  where: ImportGroup_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_ImportGroup_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_ImportSkillArgs = {
  where: ImportSkill_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_ImportSkill_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Import_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_JobTitleArgs = {
  where: JobTitle_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_JobTitle_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_ModuleArgs = {
  where: Module_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_ModuleAnnexArgs = {
  where: ModuleAnnex_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_ModuleAnnex_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_ModuleContentArgs = {
  where: ModuleContent_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_ModuleContent_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_ModuleTaskArgs = {
  where: ModuleTask_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_ModuleTask_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Module_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_OrganisationArgs = {
  where: Organisation_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Organisation_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_PasswordResetTokenArgs = {
  where: PasswordResetToken_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_PasswordResetToken_By_PkArgs = {
  passwordResetToken: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_RefreshTokenArgs = {
  where: RefreshToken_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_RefreshToken_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_RoleArgs = {
  where: Role_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Role_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_SequenceArgs = {
  where: Sequence_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Sequence_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Sequence_ModuleArgs = {
  where: Sequence_Module_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Sequence_Module_By_PkArgs = {
  moduleId: Scalars['uuid'];
  sequenceId: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_SiteArgs = {
  where: Site_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Site_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_SkillArgs = {
  where: Skill_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_SkillLevelArgs = {
  where: SkillLevel_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_SkillLevel_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Skill_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Skill_Of_CourseArgs = {
  where: Skill_Of_Course_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Skill_Of_Course_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Tag_CourseArgs = {
  where: Tag_Course_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Tag_Course_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Tag_Of_CourseArgs = {
  where: Tag_Of_Course_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Tag_Of_Course_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_ThematicArgs = {
  where: Thematic_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Thematic_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_TranslationArgs = {
  where: Translation_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Translation_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_UserArgs = {
  where: User_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_UserCourseProgressArgs = {
  where: UserCourseProgress_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_UserCourseProgress_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_UserEventSlotArgs = {
  where: UserEventSlot_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_UserEventSlot_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_UserGroupArgs = {
  where: UserGroup_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_UserGroup_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_UserModuleProgressArgs = {
  where: UserModuleProgress_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_UserModuleProgress_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_UserPrivateArgs = {
  where: UserPrivate_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_UserSkillArgs = {
  where: UserSkill_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_UserSkill_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_User_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_VolunteerPostArgs = {
  where: VolunteerPost_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_VolunteerPost_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Xapi_StatementArgs = {
  where: Xapi_Statement_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Xapi_Statement_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDuplicateCourseArgs = {
  date: Scalars['String'];
  id: Scalars['String'];
};


/** mutation root */
export type Mutation_RootFaqArgs = {
  input: Array<FaqInput>;
};


/** mutation root */
export type Mutation_RootGroupArgs = {
  input: GroupInput;
};


/** mutation root */
export type Mutation_RootHomeArgs = {
  input: HomeInput;
};


/** mutation root */
export type Mutation_RootImportGroupArgs = {
  input: ImportGroupInput;
};


/** mutation root */
export type Mutation_RootImportJobTitleArgs = {
  input: ImportJobTitleInput;
};


/** mutation root */
export type Mutation_RootImportSkillArgs = {
  input: ImportSkillInput;
};


/** mutation root */
export type Mutation_RootImportUsersToGroupArgs = {
  input: ImportUsersToGroupInput;
};


/** mutation root */
export type Mutation_RootInsert_CmsPageArgs = {
  objects: Array<CmsPage_Insert_Input>;
  on_conflict?: Maybe<CmsPage_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_CmsPage_OneArgs = {
  object: CmsPage_Insert_Input;
  on_conflict?: Maybe<CmsPage_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_ContentArticleArgs = {
  objects: Array<ContentArticle_Insert_Input>;
  on_conflict?: Maybe<ContentArticle_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_ContentArticle_OneArgs = {
  object: ContentArticle_Insert_Input;
  on_conflict?: Maybe<ContentArticle_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_ContentAudioTranscriptArgs = {
  objects: Array<ContentAudioTranscript_Insert_Input>;
  on_conflict?: Maybe<ContentAudioTranscript_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_ContentAudioTranscript_OneArgs = {
  object: ContentAudioTranscript_Insert_Input;
  on_conflict?: Maybe<ContentAudioTranscript_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_ContentCmi5Args = {
  objects: Array<ContentCmi5_Insert_Input>;
  on_conflict?: Maybe<ContentCmi5_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_ContentCmi5AssignableUnitArgs = {
  objects: Array<ContentCmi5AssignableUnit_Insert_Input>;
  on_conflict?: Maybe<ContentCmi5AssignableUnit_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_ContentCmi5AssignableUnit_OneArgs = {
  object: ContentCmi5AssignableUnit_Insert_Input;
  on_conflict?: Maybe<ContentCmi5AssignableUnit_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_ContentCmi5ObjectiveArgs = {
  objects: Array<ContentCmi5Objective_Insert_Input>;
  on_conflict?: Maybe<ContentCmi5Objective_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_ContentCmi5Objective_OneArgs = {
  object: ContentCmi5Objective_Insert_Input;
  on_conflict?: Maybe<ContentCmi5Objective_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_ContentCmi5_OneArgs = {
  object: ContentCmi5_Insert_Input;
  on_conflict?: Maybe<ContentCmi5_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_ContentDocumentArgs = {
  objects: Array<ContentDocument_Insert_Input>;
  on_conflict?: Maybe<ContentDocument_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_ContentDocumentUploadArgs = {
  objects: Array<ContentDocumentUpload_Insert_Input>;
  on_conflict?: Maybe<ContentDocumentUpload_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_ContentDocumentUpload_OneArgs = {
  object: ContentDocumentUpload_Insert_Input;
  on_conflict?: Maybe<ContentDocumentUpload_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_ContentDocument_OneArgs = {
  object: ContentDocument_Insert_Input;
  on_conflict?: Maybe<ContentDocument_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_ContentEmbedArgs = {
  objects: Array<ContentEmbed_Insert_Input>;
  on_conflict?: Maybe<ContentEmbed_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_ContentEmbedAudioTranscriptArgs = {
  objects: Array<ContentEmbedAudioTranscript_Insert_Input>;
  on_conflict?: Maybe<ContentEmbedAudioTranscript_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_ContentEmbedAudioTranscript_OneArgs = {
  object: ContentEmbedAudioTranscript_Insert_Input;
  on_conflict?: Maybe<ContentEmbedAudioTranscript_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_ContentEmbed_OneArgs = {
  object: ContentEmbed_Insert_Input;
  on_conflict?: Maybe<ContentEmbed_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_ContentEventArgs = {
  objects: Array<ContentEvent_Insert_Input>;
  on_conflict?: Maybe<ContentEvent_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_ContentEventAttachmentArgs = {
  objects: Array<ContentEventAttachment_Insert_Input>;
  on_conflict?: Maybe<ContentEventAttachment_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_ContentEventAttachment_OneArgs = {
  object: ContentEventAttachment_Insert_Input;
  on_conflict?: Maybe<ContentEventAttachment_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_ContentEventSlotArgs = {
  objects: Array<ContentEventSlot_Insert_Input>;
  on_conflict?: Maybe<ContentEventSlot_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_ContentEventSlot_AnimatorArgs = {
  objects: Array<ContentEventSlot_Animator_Insert_Input>;
  on_conflict?: Maybe<ContentEventSlot_Animator_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_ContentEventSlot_Animator_OneArgs = {
  object: ContentEventSlot_Animator_Insert_Input;
  on_conflict?: Maybe<ContentEventSlot_Animator_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_ContentEventSlot_OneArgs = {
  object: ContentEventSlot_Insert_Input;
  on_conflict?: Maybe<ContentEventSlot_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_ContentEvent_OneArgs = {
  object: ContentEvent_Insert_Input;
  on_conflict?: Maybe<ContentEvent_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_ContentPodcastArgs = {
  objects: Array<ContentPodcast_Insert_Input>;
  on_conflict?: Maybe<ContentPodcast_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_ContentPodcastUploadArgs = {
  objects: Array<ContentPodcastUpload_Insert_Input>;
  on_conflict?: Maybe<ContentPodcastUpload_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_ContentPodcastUpload_OneArgs = {
  object: ContentPodcastUpload_Insert_Input;
  on_conflict?: Maybe<ContentPodcastUpload_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_ContentPodcast_OneArgs = {
  object: ContentPodcast_Insert_Input;
  on_conflict?: Maybe<ContentPodcast_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_ContentVideoArgs = {
  objects: Array<ContentVideo_Insert_Input>;
  on_conflict?: Maybe<ContentVideo_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_ContentVideoCaptionArgs = {
  objects: Array<ContentVideoCaption_Insert_Input>;
  on_conflict?: Maybe<ContentVideoCaption_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_ContentVideoCaption_OneArgs = {
  object: ContentVideoCaption_Insert_Input;
  on_conflict?: Maybe<ContentVideoCaption_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_ContentVideoDetailsArgs = {
  objects: Array<ContentVideoDetails_Insert_Input>;
  on_conflict?: Maybe<ContentVideoDetails_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_ContentVideoDetails_OneArgs = {
  object: ContentVideoDetails_Insert_Input;
  on_conflict?: Maybe<ContentVideoDetails_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_ContentVideo_OneArgs = {
  object: ContentVideo_Insert_Input;
  on_conflict?: Maybe<ContentVideo_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_CourseArgs = {
  objects: Array<Course_Insert_Input>;
  on_conflict?: Maybe<Course_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_CourseAssignArgs = {
  objects: Array<CourseAssign_Insert_Input>;
  on_conflict?: Maybe<CourseAssign_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_CourseAssignEmployeeArgs = {
  objects: Array<CourseAssignEmployee_Insert_Input>;
  on_conflict?: Maybe<CourseAssignEmployee_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_CourseAssignEmployeeDirectionArgs = {
  objects: Array<CourseAssignEmployeeDirection_Insert_Input>;
  on_conflict?: Maybe<CourseAssignEmployeeDirection_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_CourseAssignEmployeeDirection_OneArgs = {
  object: CourseAssignEmployeeDirection_Insert_Input;
  on_conflict?: Maybe<CourseAssignEmployeeDirection_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_CourseAssignEmployeeFaArgs = {
  objects: Array<CourseAssignEmployeeFa_Insert_Input>;
  on_conflict?: Maybe<CourseAssignEmployeeFa_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_CourseAssignEmployeeFa_OneArgs = {
  object: CourseAssignEmployeeFa_Insert_Input;
  on_conflict?: Maybe<CourseAssignEmployeeFa_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_CourseAssignEmployee_OneArgs = {
  object: CourseAssignEmployee_Insert_Input;
  on_conflict?: Maybe<CourseAssignEmployee_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_CourseAssignGroupArgs = {
  objects: Array<CourseAssignGroup_Insert_Input>;
  on_conflict?: Maybe<CourseAssignGroup_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_CourseAssignGroup_OneArgs = {
  object: CourseAssignGroup_Insert_Input;
  on_conflict?: Maybe<CourseAssignGroup_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_CourseAssignOtherArgs = {
  objects: Array<CourseAssignOther_Insert_Input>;
  on_conflict?: Maybe<CourseAssignOther_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_CourseAssignOther_OneArgs = {
  object: CourseAssignOther_Insert_Input;
  on_conflict?: Maybe<CourseAssignOther_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_CourseAssignUserArgs = {
  objects: Array<CourseAssignUser_Insert_Input>;
  on_conflict?: Maybe<CourseAssignUser_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_CourseAssignUser_OneArgs = {
  object: CourseAssignUser_Insert_Input;
  on_conflict?: Maybe<CourseAssignUser_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_CourseAssignVolunteerArgs = {
  objects: Array<CourseAssignVolunteer_Insert_Input>;
  on_conflict?: Maybe<CourseAssignVolunteer_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_CourseAssignVolunteerFaArgs = {
  objects: Array<CourseAssignVolunteerFa_Insert_Input>;
  on_conflict?: Maybe<CourseAssignVolunteerFa_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_CourseAssignVolunteerFa_OneArgs = {
  object: CourseAssignVolunteerFa_Insert_Input;
  on_conflict?: Maybe<CourseAssignVolunteerFa_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_CourseAssignVolunteerJobTitleArgs = {
  objects: Array<CourseAssignVolunteerJobTitle_Insert_Input>;
  on_conflict?: Maybe<CourseAssignVolunteerJobTitle_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_CourseAssignVolunteerJobTitle_OneArgs = {
  object: CourseAssignVolunteerJobTitle_Insert_Input;
  on_conflict?: Maybe<CourseAssignVolunteerJobTitle_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_CourseAssignVolunteerSiteArgs = {
  objects: Array<CourseAssignVolunteerSite_Insert_Input>;
  on_conflict?: Maybe<CourseAssignVolunteerSite_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_CourseAssignVolunteerSite_OneArgs = {
  object: CourseAssignVolunteerSite_Insert_Input;
  on_conflict?: Maybe<CourseAssignVolunteerSite_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_CourseAssignVolunteer_OneArgs = {
  object: CourseAssignVolunteer_Insert_Input;
  on_conflict?: Maybe<CourseAssignVolunteer_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_CourseAssign_OneArgs = {
  object: CourseAssign_Insert_Input;
  on_conflict?: Maybe<CourseAssign_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Course_OneArgs = {
  object: Course_Insert_Input;
  on_conflict?: Maybe<Course_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_D_CourseUserRefreshArgs = {
  objects: Array<D_CourseUserRefresh_Insert_Input>;
  on_conflict?: Maybe<D_CourseUserRefresh_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_D_CourseUserRefresh_OneArgs = {
  object: D_CourseUserRefresh_Insert_Input;
  on_conflict?: Maybe<D_CourseUserRefresh_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_DataAccessRequestArgs = {
  objects: Array<DataAccessRequest_Insert_Input>;
  on_conflict?: Maybe<DataAccessRequest_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_DataAccessRequest_OneArgs = {
  object: DataAccessRequest_Insert_Input;
  on_conflict?: Maybe<DataAccessRequest_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_DirectionArgs = {
  objects: Array<Direction_Insert_Input>;
  on_conflict?: Maybe<Direction_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Direction_OneArgs = {
  object: Direction_Insert_Input;
  on_conflict?: Maybe<Direction_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_EmailValidationTokenArgs = {
  objects: Array<EmailValidationToken_Insert_Input>;
  on_conflict?: Maybe<EmailValidationToken_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_EmailValidationToken_OneArgs = {
  object: EmailValidationToken_Insert_Input;
  on_conflict?: Maybe<EmailValidationToken_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_FaArgs = {
  objects: Array<Fa_Insert_Input>;
  on_conflict?: Maybe<Fa_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Fa_OneArgs = {
  object: Fa_Insert_Input;
  on_conflict?: Maybe<Fa_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_FaqArgs = {
  objects: Array<Faq_Insert_Input>;
  on_conflict?: Maybe<Faq_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Faq_OneArgs = {
  object: Faq_Insert_Input;
  on_conflict?: Maybe<Faq_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_GroupArgs = {
  objects: Array<Group_Insert_Input>;
  on_conflict?: Maybe<Group_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Group_OneArgs = {
  object: Group_Insert_Input;
  on_conflict?: Maybe<Group_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_HomeArgs = {
  objects: Array<Home_Insert_Input>;
  on_conflict?: Maybe<Home_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Home_OneArgs = {
  object: Home_Insert_Input;
  on_conflict?: Maybe<Home_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_I18nArgs = {
  objects: Array<I18n_Insert_Input>;
  on_conflict?: Maybe<I18n_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_I18n_OneArgs = {
  object: I18n_Insert_Input;
  on_conflict?: Maybe<I18n_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_IdentityArgs = {
  objects: Array<Identity_Insert_Input>;
  on_conflict?: Maybe<Identity_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Identity_OneArgs = {
  object: Identity_Insert_Input;
  on_conflict?: Maybe<Identity_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_ImportArgs = {
  objects: Array<Import_Insert_Input>;
  on_conflict?: Maybe<Import_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_ImportGroupArgs = {
  objects: Array<ImportGroup_Insert_Input>;
  on_conflict?: Maybe<ImportGroup_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_ImportGroup_OneArgs = {
  object: ImportGroup_Insert_Input;
  on_conflict?: Maybe<ImportGroup_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_ImportSkillArgs = {
  objects: Array<ImportSkill_Insert_Input>;
  on_conflict?: Maybe<ImportSkill_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_ImportSkill_OneArgs = {
  object: ImportSkill_Insert_Input;
  on_conflict?: Maybe<ImportSkill_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Import_OneArgs = {
  object: Import_Insert_Input;
  on_conflict?: Maybe<Import_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_JobTitleArgs = {
  objects: Array<JobTitle_Insert_Input>;
  on_conflict?: Maybe<JobTitle_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_JobTitle_OneArgs = {
  object: JobTitle_Insert_Input;
  on_conflict?: Maybe<JobTitle_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_ModuleArgs = {
  objects: Array<Module_Insert_Input>;
  on_conflict?: Maybe<Module_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_ModuleAnnexArgs = {
  objects: Array<ModuleAnnex_Insert_Input>;
  on_conflict?: Maybe<ModuleAnnex_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_ModuleAnnex_OneArgs = {
  object: ModuleAnnex_Insert_Input;
  on_conflict?: Maybe<ModuleAnnex_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_ModuleContentArgs = {
  objects: Array<ModuleContent_Insert_Input>;
  on_conflict?: Maybe<ModuleContent_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_ModuleContent_OneArgs = {
  object: ModuleContent_Insert_Input;
  on_conflict?: Maybe<ModuleContent_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_ModuleTaskArgs = {
  objects: Array<ModuleTask_Insert_Input>;
  on_conflict?: Maybe<ModuleTask_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_ModuleTask_OneArgs = {
  object: ModuleTask_Insert_Input;
  on_conflict?: Maybe<ModuleTask_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Module_OneArgs = {
  object: Module_Insert_Input;
  on_conflict?: Maybe<Module_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_OrganisationArgs = {
  objects: Array<Organisation_Insert_Input>;
  on_conflict?: Maybe<Organisation_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Organisation_OneArgs = {
  object: Organisation_Insert_Input;
  on_conflict?: Maybe<Organisation_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_PasswordResetTokenArgs = {
  objects: Array<PasswordResetToken_Insert_Input>;
  on_conflict?: Maybe<PasswordResetToken_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_PasswordResetToken_OneArgs = {
  object: PasswordResetToken_Insert_Input;
  on_conflict?: Maybe<PasswordResetToken_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_RefreshTokenArgs = {
  objects: Array<RefreshToken_Insert_Input>;
  on_conflict?: Maybe<RefreshToken_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_RefreshToken_OneArgs = {
  object: RefreshToken_Insert_Input;
  on_conflict?: Maybe<RefreshToken_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_RoleArgs = {
  objects: Array<Role_Insert_Input>;
  on_conflict?: Maybe<Role_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Role_OneArgs = {
  object: Role_Insert_Input;
  on_conflict?: Maybe<Role_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_SequenceArgs = {
  objects: Array<Sequence_Insert_Input>;
  on_conflict?: Maybe<Sequence_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Sequence_ModuleArgs = {
  objects: Array<Sequence_Module_Insert_Input>;
  on_conflict?: Maybe<Sequence_Module_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Sequence_Module_OneArgs = {
  object: Sequence_Module_Insert_Input;
  on_conflict?: Maybe<Sequence_Module_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Sequence_OneArgs = {
  object: Sequence_Insert_Input;
  on_conflict?: Maybe<Sequence_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_SiteArgs = {
  objects: Array<Site_Insert_Input>;
  on_conflict?: Maybe<Site_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Site_OneArgs = {
  object: Site_Insert_Input;
  on_conflict?: Maybe<Site_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_SkillArgs = {
  objects: Array<Skill_Insert_Input>;
  on_conflict?: Maybe<Skill_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_SkillLevelArgs = {
  objects: Array<SkillLevel_Insert_Input>;
  on_conflict?: Maybe<SkillLevel_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_SkillLevel_OneArgs = {
  object: SkillLevel_Insert_Input;
  on_conflict?: Maybe<SkillLevel_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Skill_Of_CourseArgs = {
  objects: Array<Skill_Of_Course_Insert_Input>;
  on_conflict?: Maybe<Skill_Of_Course_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Skill_Of_Course_OneArgs = {
  object: Skill_Of_Course_Insert_Input;
  on_conflict?: Maybe<Skill_Of_Course_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Skill_OneArgs = {
  object: Skill_Insert_Input;
  on_conflict?: Maybe<Skill_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Tag_CourseArgs = {
  objects: Array<Tag_Course_Insert_Input>;
  on_conflict?: Maybe<Tag_Course_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Tag_Course_OneArgs = {
  object: Tag_Course_Insert_Input;
  on_conflict?: Maybe<Tag_Course_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Tag_Of_CourseArgs = {
  objects: Array<Tag_Of_Course_Insert_Input>;
  on_conflict?: Maybe<Tag_Of_Course_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Tag_Of_Course_OneArgs = {
  object: Tag_Of_Course_Insert_Input;
  on_conflict?: Maybe<Tag_Of_Course_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_ThematicArgs = {
  objects: Array<Thematic_Insert_Input>;
  on_conflict?: Maybe<Thematic_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Thematic_OneArgs = {
  object: Thematic_Insert_Input;
  on_conflict?: Maybe<Thematic_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_TranslationArgs = {
  objects: Array<Translation_Insert_Input>;
  on_conflict?: Maybe<Translation_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Translation_OneArgs = {
  object: Translation_Insert_Input;
  on_conflict?: Maybe<Translation_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_UserArgs = {
  objects: Array<User_Insert_Input>;
  on_conflict?: Maybe<User_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_UserCourseProgressArgs = {
  objects: Array<UserCourseProgress_Insert_Input>;
  on_conflict?: Maybe<UserCourseProgress_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_UserCourseProgress_OneArgs = {
  object: UserCourseProgress_Insert_Input;
  on_conflict?: Maybe<UserCourseProgress_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_UserEventSlotArgs = {
  objects: Array<UserEventSlot_Insert_Input>;
  on_conflict?: Maybe<UserEventSlot_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_UserEventSlot_OneArgs = {
  object: UserEventSlot_Insert_Input;
  on_conflict?: Maybe<UserEventSlot_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_UserGroupArgs = {
  objects: Array<UserGroup_Insert_Input>;
  on_conflict?: Maybe<UserGroup_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_UserGroup_OneArgs = {
  object: UserGroup_Insert_Input;
  on_conflict?: Maybe<UserGroup_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_UserModuleProgressArgs = {
  objects: Array<UserModuleProgress_Insert_Input>;
  on_conflict?: Maybe<UserModuleProgress_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_UserModuleProgress_OneArgs = {
  object: UserModuleProgress_Insert_Input;
  on_conflict?: Maybe<UserModuleProgress_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_UserPrivateArgs = {
  objects: Array<UserPrivate_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_UserPrivate_OneArgs = {
  object: UserPrivate_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_UserSkillArgs = {
  objects: Array<UserSkill_Insert_Input>;
  on_conflict?: Maybe<UserSkill_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_UserSkill_OneArgs = {
  object: UserSkill_Insert_Input;
  on_conflict?: Maybe<UserSkill_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_User_OneArgs = {
  object: User_Insert_Input;
  on_conflict?: Maybe<User_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_VolunteerPostArgs = {
  objects: Array<VolunteerPost_Insert_Input>;
  on_conflict?: Maybe<VolunteerPost_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_VolunteerPost_OneArgs = {
  object: VolunteerPost_Insert_Input;
  on_conflict?: Maybe<VolunteerPost_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Xapi_StatementArgs = {
  objects: Array<Xapi_Statement_Insert_Input>;
  on_conflict?: Maybe<Xapi_Statement_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Xapi_Statement_OneArgs = {
  object: Xapi_Statement_Insert_Input;
  on_conflict?: Maybe<Xapi_Statement_On_Conflict>;
};


/** mutation root */
export type Mutation_RootLearnerArgs = {
  input: UserInput;
};


/** mutation root */
export type Mutation_RootModuleArgs = {
  input: ModuleInput;
};


/** mutation root */
export type Mutation_RootOrganisationAttributeArgs = {
  input: OrganisationAttributeInput;
};


/** mutation root */
export type Mutation_RootPublishCourseArgs = {
  input: CoursePublicationInput;
};


/** mutation root */
export type Mutation_RootRoleArgs = {
  input: RoleInput;
};


/** mutation root */
export type Mutation_RootRoleDeletionArgs = {
  id: Scalars['String'];
};


/** mutation root */
export type Mutation_RootSaveCurrentUserEventSlotArgs = {
  input: SaveCurrentUserEventSlotInput;
};


/** mutation root */
export type Mutation_RootSaveDataAccessRequestArgs = {
  input: DataAccessRequestInput;
};


/** mutation root */
export type Mutation_RootSaveUsersEventSlotArgs = {
  input: SaveUsersEventSlotInput;
};


/** mutation root */
export type Mutation_RootSetUserAttendanceSlotArgs = {
  input: SetUserAttendanceSlotInput;
};


/** mutation root */
export type Mutation_RootSetUserStatusArgs = {
  isActive: Scalars['Boolean'];
  userId: Scalars['ID'];
};


/** mutation root */
export type Mutation_RootSetValidateSlotAttendanceArgs = {
  input: SetValidateSlotAttendanceInput;
};


/** mutation root */
export type Mutation_RootSignInArgs = {
  email: Scalars['String'];
  password: Scalars['String'];
};


/** mutation root */
export type Mutation_RootSkillArgs = {
  input: SkillInput;
};


/** mutation root */
export type Mutation_RootStartCmi5ContentArgs = {
  input: StartCmi5ContentInput;
};


/** mutation root */
export type Mutation_RootTag_CourseArgs = {
  input: TagCourseInput;
};


/** mutation root */
export type Mutation_RootThematicArgs = {
  input: ThematicInput;
};


/** mutation root */
export type Mutation_RootUpdateUserModuleProgressionArgs = {
  moduleId: Scalars['ID'];
  progression: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootUpdate_CmsPageArgs = {
  _set?: Maybe<CmsPage_Set_Input>;
  where: CmsPage_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_CmsPage_By_PkArgs = {
  _set?: Maybe<CmsPage_Set_Input>;
  pk_columns: CmsPage_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_CmsPage_ManyArgs = {
  updates: Array<CmsPage_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_ContentArticleArgs = {
  _set?: Maybe<ContentArticle_Set_Input>;
  where: ContentArticle_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_ContentArticle_By_PkArgs = {
  _set?: Maybe<ContentArticle_Set_Input>;
  pk_columns: ContentArticle_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_ContentArticle_ManyArgs = {
  updates: Array<ContentArticle_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_ContentAudioTranscriptArgs = {
  _inc?: Maybe<ContentAudioTranscript_Inc_Input>;
  _set?: Maybe<ContentAudioTranscript_Set_Input>;
  where: ContentAudioTranscript_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_ContentAudioTranscript_By_PkArgs = {
  _inc?: Maybe<ContentAudioTranscript_Inc_Input>;
  _set?: Maybe<ContentAudioTranscript_Set_Input>;
  pk_columns: ContentAudioTranscript_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_ContentAudioTranscript_ManyArgs = {
  updates: Array<ContentAudioTranscript_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_ContentCmi5Args = {
  _set?: Maybe<ContentCmi5_Set_Input>;
  where: ContentCmi5_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_ContentCmi5AssignableUnitArgs = {
  _inc?: Maybe<ContentCmi5AssignableUnit_Inc_Input>;
  _set?: Maybe<ContentCmi5AssignableUnit_Set_Input>;
  where: ContentCmi5AssignableUnit_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_ContentCmi5AssignableUnit_By_PkArgs = {
  _inc?: Maybe<ContentCmi5AssignableUnit_Inc_Input>;
  _set?: Maybe<ContentCmi5AssignableUnit_Set_Input>;
  pk_columns: ContentCmi5AssignableUnit_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_ContentCmi5AssignableUnit_ManyArgs = {
  updates: Array<ContentCmi5AssignableUnit_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_ContentCmi5ObjectiveArgs = {
  _set?: Maybe<ContentCmi5Objective_Set_Input>;
  where: ContentCmi5Objective_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_ContentCmi5Objective_By_PkArgs = {
  _set?: Maybe<ContentCmi5Objective_Set_Input>;
  pk_columns: ContentCmi5Objective_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_ContentCmi5Objective_ManyArgs = {
  updates: Array<ContentCmi5Objective_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_ContentCmi5_By_PkArgs = {
  _set?: Maybe<ContentCmi5_Set_Input>;
  pk_columns: ContentCmi5_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_ContentCmi5_ManyArgs = {
  updates: Array<ContentCmi5_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_ContentDocumentArgs = {
  _set?: Maybe<ContentDocument_Set_Input>;
  where: ContentDocument_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_ContentDocumentUploadArgs = {
  _inc?: Maybe<ContentDocumentUpload_Inc_Input>;
  _set?: Maybe<ContentDocumentUpload_Set_Input>;
  where: ContentDocumentUpload_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_ContentDocumentUpload_By_PkArgs = {
  _inc?: Maybe<ContentDocumentUpload_Inc_Input>;
  _set?: Maybe<ContentDocumentUpload_Set_Input>;
  pk_columns: ContentDocumentUpload_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_ContentDocumentUpload_ManyArgs = {
  updates: Array<ContentDocumentUpload_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_ContentDocument_By_PkArgs = {
  _set?: Maybe<ContentDocument_Set_Input>;
  pk_columns: ContentDocument_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_ContentDocument_ManyArgs = {
  updates: Array<ContentDocument_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_ContentEmbedArgs = {
  _set?: Maybe<ContentEmbed_Set_Input>;
  where: ContentEmbed_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_ContentEmbedAudioTranscriptArgs = {
  _inc?: Maybe<ContentEmbedAudioTranscript_Inc_Input>;
  _set?: Maybe<ContentEmbedAudioTranscript_Set_Input>;
  where: ContentEmbedAudioTranscript_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_ContentEmbedAudioTranscript_By_PkArgs = {
  _inc?: Maybe<ContentEmbedAudioTranscript_Inc_Input>;
  _set?: Maybe<ContentEmbedAudioTranscript_Set_Input>;
  pk_columns: ContentEmbedAudioTranscript_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_ContentEmbedAudioTranscript_ManyArgs = {
  updates: Array<ContentEmbedAudioTranscript_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_ContentEmbed_By_PkArgs = {
  _set?: Maybe<ContentEmbed_Set_Input>;
  pk_columns: ContentEmbed_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_ContentEmbed_ManyArgs = {
  updates: Array<ContentEmbed_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_ContentEventArgs = {
  _set?: Maybe<ContentEvent_Set_Input>;
  where: ContentEvent_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_ContentEventAttachmentArgs = {
  _inc?: Maybe<ContentEventAttachment_Inc_Input>;
  _set?: Maybe<ContentEventAttachment_Set_Input>;
  where: ContentEventAttachment_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_ContentEventAttachment_By_PkArgs = {
  _inc?: Maybe<ContentEventAttachment_Inc_Input>;
  _set?: Maybe<ContentEventAttachment_Set_Input>;
  pk_columns: ContentEventAttachment_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_ContentEventAttachment_ManyArgs = {
  updates: Array<ContentEventAttachment_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_ContentEventSlotArgs = {
  _inc?: Maybe<ContentEventSlot_Inc_Input>;
  _set?: Maybe<ContentEventSlot_Set_Input>;
  where: ContentEventSlot_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_ContentEventSlot_AnimatorArgs = {
  _set?: Maybe<ContentEventSlot_Animator_Set_Input>;
  where: ContentEventSlot_Animator_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_ContentEventSlot_Animator_By_PkArgs = {
  _set?: Maybe<ContentEventSlot_Animator_Set_Input>;
  pk_columns: ContentEventSlot_Animator_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_ContentEventSlot_Animator_ManyArgs = {
  updates: Array<ContentEventSlot_Animator_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_ContentEventSlot_By_PkArgs = {
  _inc?: Maybe<ContentEventSlot_Inc_Input>;
  _set?: Maybe<ContentEventSlot_Set_Input>;
  pk_columns: ContentEventSlot_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_ContentEventSlot_ManyArgs = {
  updates: Array<ContentEventSlot_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_ContentEvent_By_PkArgs = {
  _set?: Maybe<ContentEvent_Set_Input>;
  pk_columns: ContentEvent_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_ContentEvent_ManyArgs = {
  updates: Array<ContentEvent_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_ContentPodcastArgs = {
  _set?: Maybe<ContentPodcast_Set_Input>;
  where: ContentPodcast_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_ContentPodcastUploadArgs = {
  _inc?: Maybe<ContentPodcastUpload_Inc_Input>;
  _set?: Maybe<ContentPodcastUpload_Set_Input>;
  where: ContentPodcastUpload_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_ContentPodcastUpload_By_PkArgs = {
  _inc?: Maybe<ContentPodcastUpload_Inc_Input>;
  _set?: Maybe<ContentPodcastUpload_Set_Input>;
  pk_columns: ContentPodcastUpload_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_ContentPodcastUpload_ManyArgs = {
  updates: Array<ContentPodcastUpload_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_ContentPodcast_By_PkArgs = {
  _set?: Maybe<ContentPodcast_Set_Input>;
  pk_columns: ContentPodcast_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_ContentPodcast_ManyArgs = {
  updates: Array<ContentPodcast_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_ContentVideoArgs = {
  _set?: Maybe<ContentVideo_Set_Input>;
  where: ContentVideo_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_ContentVideoCaptionArgs = {
  _inc?: Maybe<ContentVideoCaption_Inc_Input>;
  _set?: Maybe<ContentVideoCaption_Set_Input>;
  where: ContentVideoCaption_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_ContentVideoCaption_By_PkArgs = {
  _inc?: Maybe<ContentVideoCaption_Inc_Input>;
  _set?: Maybe<ContentVideoCaption_Set_Input>;
  pk_columns: ContentVideoCaption_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_ContentVideoCaption_ManyArgs = {
  updates: Array<ContentVideoCaption_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_ContentVideoDetailsArgs = {
  _set?: Maybe<ContentVideoDetails_Set_Input>;
  where: ContentVideoDetails_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_ContentVideoDetails_By_PkArgs = {
  _set?: Maybe<ContentVideoDetails_Set_Input>;
  pk_columns: ContentVideoDetails_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_ContentVideoDetails_ManyArgs = {
  updates: Array<ContentVideoDetails_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_ContentVideo_By_PkArgs = {
  _set?: Maybe<ContentVideo_Set_Input>;
  pk_columns: ContentVideo_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_ContentVideo_ManyArgs = {
  updates: Array<ContentVideo_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_CourseArgs = {
  _inc?: Maybe<Course_Inc_Input>;
  _set?: Maybe<Course_Set_Input>;
  where: Course_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_CourseAssignArgs = {
  _set?: Maybe<CourseAssign_Set_Input>;
  where: CourseAssign_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_CourseAssignEmployeeArgs = {
  _set?: Maybe<CourseAssignEmployee_Set_Input>;
  where: CourseAssignEmployee_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_CourseAssignEmployeeDirectionArgs = {
  _set?: Maybe<CourseAssignEmployeeDirection_Set_Input>;
  where: CourseAssignEmployeeDirection_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_CourseAssignEmployeeDirection_By_PkArgs = {
  _set?: Maybe<CourseAssignEmployeeDirection_Set_Input>;
  pk_columns: CourseAssignEmployeeDirection_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_CourseAssignEmployeeDirection_ManyArgs = {
  updates: Array<CourseAssignEmployeeDirection_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_CourseAssignEmployeeFaArgs = {
  _set?: Maybe<CourseAssignEmployeeFa_Set_Input>;
  where: CourseAssignEmployeeFa_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_CourseAssignEmployeeFa_By_PkArgs = {
  _set?: Maybe<CourseAssignEmployeeFa_Set_Input>;
  pk_columns: CourseAssignEmployeeFa_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_CourseAssignEmployeeFa_ManyArgs = {
  updates: Array<CourseAssignEmployeeFa_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_CourseAssignEmployee_By_PkArgs = {
  _set?: Maybe<CourseAssignEmployee_Set_Input>;
  pk_columns: CourseAssignEmployee_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_CourseAssignEmployee_ManyArgs = {
  updates: Array<CourseAssignEmployee_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_CourseAssignGroupArgs = {
  _set?: Maybe<CourseAssignGroup_Set_Input>;
  where: CourseAssignGroup_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_CourseAssignGroup_By_PkArgs = {
  _set?: Maybe<CourseAssignGroup_Set_Input>;
  pk_columns: CourseAssignGroup_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_CourseAssignGroup_ManyArgs = {
  updates: Array<CourseAssignGroup_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_CourseAssignOtherArgs = {
  _set?: Maybe<CourseAssignOther_Set_Input>;
  where: CourseAssignOther_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_CourseAssignOther_By_PkArgs = {
  _set?: Maybe<CourseAssignOther_Set_Input>;
  pk_columns: CourseAssignOther_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_CourseAssignOther_ManyArgs = {
  updates: Array<CourseAssignOther_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_CourseAssignUserArgs = {
  _set?: Maybe<CourseAssignUser_Set_Input>;
  where: CourseAssignUser_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_CourseAssignUser_By_PkArgs = {
  _set?: Maybe<CourseAssignUser_Set_Input>;
  pk_columns: CourseAssignUser_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_CourseAssignUser_ManyArgs = {
  updates: Array<CourseAssignUser_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_CourseAssignVolunteerArgs = {
  _set?: Maybe<CourseAssignVolunteer_Set_Input>;
  where: CourseAssignVolunteer_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_CourseAssignVolunteerFaArgs = {
  _set?: Maybe<CourseAssignVolunteerFa_Set_Input>;
  where: CourseAssignVolunteerFa_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_CourseAssignVolunteerFa_By_PkArgs = {
  _set?: Maybe<CourseAssignVolunteerFa_Set_Input>;
  pk_columns: CourseAssignVolunteerFa_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_CourseAssignVolunteerFa_ManyArgs = {
  updates: Array<CourseAssignVolunteerFa_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_CourseAssignVolunteerJobTitleArgs = {
  _set?: Maybe<CourseAssignVolunteerJobTitle_Set_Input>;
  where: CourseAssignVolunteerJobTitle_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_CourseAssignVolunteerJobTitle_By_PkArgs = {
  _set?: Maybe<CourseAssignVolunteerJobTitle_Set_Input>;
  pk_columns: CourseAssignVolunteerJobTitle_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_CourseAssignVolunteerJobTitle_ManyArgs = {
  updates: Array<CourseAssignVolunteerJobTitle_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_CourseAssignVolunteerSiteArgs = {
  _set?: Maybe<CourseAssignVolunteerSite_Set_Input>;
  where: CourseAssignVolunteerSite_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_CourseAssignVolunteerSite_By_PkArgs = {
  _set?: Maybe<CourseAssignVolunteerSite_Set_Input>;
  pk_columns: CourseAssignVolunteerSite_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_CourseAssignVolunteerSite_ManyArgs = {
  updates: Array<CourseAssignVolunteerSite_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_CourseAssignVolunteer_By_PkArgs = {
  _set?: Maybe<CourseAssignVolunteer_Set_Input>;
  pk_columns: CourseAssignVolunteer_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_CourseAssignVolunteer_ManyArgs = {
  updates: Array<CourseAssignVolunteer_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_CourseAssign_By_PkArgs = {
  _set?: Maybe<CourseAssign_Set_Input>;
  pk_columns: CourseAssign_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_CourseAssign_ManyArgs = {
  updates: Array<CourseAssign_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Course_By_PkArgs = {
  _inc?: Maybe<Course_Inc_Input>;
  _set?: Maybe<Course_Set_Input>;
  pk_columns: Course_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Course_ManyArgs = {
  updates: Array<Course_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_D_CourseUserRefreshArgs = {
  _set?: Maybe<D_CourseUserRefresh_Set_Input>;
  where: D_CourseUserRefresh_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_D_CourseUserRefresh_By_PkArgs = {
  _set?: Maybe<D_CourseUserRefresh_Set_Input>;
  pk_columns: D_CourseUserRefresh_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_D_CourseUserRefresh_ManyArgs = {
  updates: Array<D_CourseUserRefresh_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_DataAccessRequestArgs = {
  _set?: Maybe<DataAccessRequest_Set_Input>;
  where: DataAccessRequest_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_DataAccessRequest_By_PkArgs = {
  _set?: Maybe<DataAccessRequest_Set_Input>;
  pk_columns: DataAccessRequest_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_DataAccessRequest_ManyArgs = {
  updates: Array<DataAccessRequest_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_DirectionArgs = {
  _set?: Maybe<Direction_Set_Input>;
  where: Direction_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Direction_By_PkArgs = {
  _set?: Maybe<Direction_Set_Input>;
  pk_columns: Direction_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Direction_ManyArgs = {
  updates: Array<Direction_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_EmailValidationTokenArgs = {
  _set?: Maybe<EmailValidationToken_Set_Input>;
  where: EmailValidationToken_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_EmailValidationToken_By_PkArgs = {
  _set?: Maybe<EmailValidationToken_Set_Input>;
  pk_columns: EmailValidationToken_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_EmailValidationToken_ManyArgs = {
  updates: Array<EmailValidationToken_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_FaArgs = {
  _set?: Maybe<Fa_Set_Input>;
  where: Fa_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Fa_By_PkArgs = {
  _set?: Maybe<Fa_Set_Input>;
  pk_columns: Fa_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Fa_ManyArgs = {
  updates: Array<Fa_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_FaqArgs = {
  _inc?: Maybe<Faq_Inc_Input>;
  _set?: Maybe<Faq_Set_Input>;
  where: Faq_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Faq_By_PkArgs = {
  _inc?: Maybe<Faq_Inc_Input>;
  _set?: Maybe<Faq_Set_Input>;
  pk_columns: Faq_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Faq_ManyArgs = {
  updates: Array<Faq_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_GroupArgs = {
  _set?: Maybe<Group_Set_Input>;
  where: Group_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Group_By_PkArgs = {
  _set?: Maybe<Group_Set_Input>;
  pk_columns: Group_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Group_ManyArgs = {
  updates: Array<Group_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_HomeArgs = {
  _set?: Maybe<Home_Set_Input>;
  where: Home_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Home_By_PkArgs = {
  _set?: Maybe<Home_Set_Input>;
  pk_columns: Home_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Home_ManyArgs = {
  updates: Array<Home_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_I18nArgs = {
  _set?: Maybe<I18n_Set_Input>;
  where: I18n_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_I18n_By_PkArgs = {
  _set?: Maybe<I18n_Set_Input>;
  pk_columns: I18n_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_I18n_ManyArgs = {
  updates: Array<I18n_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_IdentityArgs = {
  _inc?: Maybe<Identity_Inc_Input>;
  _set?: Maybe<Identity_Set_Input>;
  where: Identity_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Identity_By_PkArgs = {
  _inc?: Maybe<Identity_Inc_Input>;
  _set?: Maybe<Identity_Set_Input>;
  pk_columns: Identity_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Identity_ManyArgs = {
  updates: Array<Identity_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_ImportArgs = {
  _inc?: Maybe<Import_Inc_Input>;
  _set?: Maybe<Import_Set_Input>;
  where: Import_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_ImportGroupArgs = {
  _inc?: Maybe<ImportGroup_Inc_Input>;
  _set?: Maybe<ImportGroup_Set_Input>;
  where: ImportGroup_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_ImportGroup_By_PkArgs = {
  _inc?: Maybe<ImportGroup_Inc_Input>;
  _set?: Maybe<ImportGroup_Set_Input>;
  pk_columns: ImportGroup_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_ImportGroup_ManyArgs = {
  updates: Array<ImportGroup_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_ImportSkillArgs = {
  _inc?: Maybe<ImportSkill_Inc_Input>;
  _set?: Maybe<ImportSkill_Set_Input>;
  where: ImportSkill_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_ImportSkill_By_PkArgs = {
  _inc?: Maybe<ImportSkill_Inc_Input>;
  _set?: Maybe<ImportSkill_Set_Input>;
  pk_columns: ImportSkill_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_ImportSkill_ManyArgs = {
  updates: Array<ImportSkill_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Import_By_PkArgs = {
  _inc?: Maybe<Import_Inc_Input>;
  _set?: Maybe<Import_Set_Input>;
  pk_columns: Import_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Import_ManyArgs = {
  updates: Array<Import_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_JobTitleArgs = {
  _set?: Maybe<JobTitle_Set_Input>;
  where: JobTitle_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_JobTitle_By_PkArgs = {
  _set?: Maybe<JobTitle_Set_Input>;
  pk_columns: JobTitle_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_JobTitle_ManyArgs = {
  updates: Array<JobTitle_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_ModuleArgs = {
  _inc?: Maybe<Module_Inc_Input>;
  _set?: Maybe<Module_Set_Input>;
  where: Module_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_ModuleAnnexArgs = {
  _inc?: Maybe<ModuleAnnex_Inc_Input>;
  _set?: Maybe<ModuleAnnex_Set_Input>;
  where: ModuleAnnex_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_ModuleAnnex_By_PkArgs = {
  _inc?: Maybe<ModuleAnnex_Inc_Input>;
  _set?: Maybe<ModuleAnnex_Set_Input>;
  pk_columns: ModuleAnnex_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_ModuleAnnex_ManyArgs = {
  updates: Array<ModuleAnnex_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_ModuleContentArgs = {
  _set?: Maybe<ModuleContent_Set_Input>;
  where: ModuleContent_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_ModuleContent_By_PkArgs = {
  _set?: Maybe<ModuleContent_Set_Input>;
  pk_columns: ModuleContent_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_ModuleContent_ManyArgs = {
  updates: Array<ModuleContent_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_ModuleTaskArgs = {
  _inc?: Maybe<ModuleTask_Inc_Input>;
  _set?: Maybe<ModuleTask_Set_Input>;
  where: ModuleTask_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_ModuleTask_By_PkArgs = {
  _inc?: Maybe<ModuleTask_Inc_Input>;
  _set?: Maybe<ModuleTask_Set_Input>;
  pk_columns: ModuleTask_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_ModuleTask_ManyArgs = {
  updates: Array<ModuleTask_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Module_By_PkArgs = {
  _inc?: Maybe<Module_Inc_Input>;
  _set?: Maybe<Module_Set_Input>;
  pk_columns: Module_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Module_ManyArgs = {
  updates: Array<Module_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_OrganisationArgs = {
  _set?: Maybe<Organisation_Set_Input>;
  where: Organisation_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Organisation_By_PkArgs = {
  _set?: Maybe<Organisation_Set_Input>;
  pk_columns: Organisation_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Organisation_ManyArgs = {
  updates: Array<Organisation_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_PasswordResetTokenArgs = {
  _set?: Maybe<PasswordResetToken_Set_Input>;
  where: PasswordResetToken_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_PasswordResetToken_By_PkArgs = {
  _set?: Maybe<PasswordResetToken_Set_Input>;
  pk_columns: PasswordResetToken_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_PasswordResetToken_ManyArgs = {
  updates: Array<PasswordResetToken_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_RefreshTokenArgs = {
  _set?: Maybe<RefreshToken_Set_Input>;
  where: RefreshToken_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_RefreshToken_By_PkArgs = {
  _set?: Maybe<RefreshToken_Set_Input>;
  pk_columns: RefreshToken_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_RefreshToken_ManyArgs = {
  updates: Array<RefreshToken_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_RoleArgs = {
  _set?: Maybe<Role_Set_Input>;
  where: Role_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Role_By_PkArgs = {
  _set?: Maybe<Role_Set_Input>;
  pk_columns: Role_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Role_ManyArgs = {
  updates: Array<Role_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_SequenceArgs = {
  _inc?: Maybe<Sequence_Inc_Input>;
  _set?: Maybe<Sequence_Set_Input>;
  where: Sequence_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Sequence_By_PkArgs = {
  _inc?: Maybe<Sequence_Inc_Input>;
  _set?: Maybe<Sequence_Set_Input>;
  pk_columns: Sequence_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Sequence_ManyArgs = {
  updates: Array<Sequence_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Sequence_ModuleArgs = {
  _inc?: Maybe<Sequence_Module_Inc_Input>;
  _set?: Maybe<Sequence_Module_Set_Input>;
  where: Sequence_Module_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Sequence_Module_By_PkArgs = {
  _inc?: Maybe<Sequence_Module_Inc_Input>;
  _set?: Maybe<Sequence_Module_Set_Input>;
  pk_columns: Sequence_Module_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Sequence_Module_ManyArgs = {
  updates: Array<Sequence_Module_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_SiteArgs = {
  _set?: Maybe<Site_Set_Input>;
  where: Site_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Site_By_PkArgs = {
  _set?: Maybe<Site_Set_Input>;
  pk_columns: Site_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Site_ManyArgs = {
  updates: Array<Site_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_SkillArgs = {
  _set?: Maybe<Skill_Set_Input>;
  where: Skill_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_SkillLevelArgs = {
  _inc?: Maybe<SkillLevel_Inc_Input>;
  _set?: Maybe<SkillLevel_Set_Input>;
  where: SkillLevel_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_SkillLevel_By_PkArgs = {
  _inc?: Maybe<SkillLevel_Inc_Input>;
  _set?: Maybe<SkillLevel_Set_Input>;
  pk_columns: SkillLevel_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_SkillLevel_ManyArgs = {
  updates: Array<SkillLevel_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Skill_By_PkArgs = {
  _set?: Maybe<Skill_Set_Input>;
  pk_columns: Skill_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Skill_ManyArgs = {
  updates: Array<Skill_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Skill_Of_CourseArgs = {
  _inc?: Maybe<Skill_Of_Course_Inc_Input>;
  _set?: Maybe<Skill_Of_Course_Set_Input>;
  where: Skill_Of_Course_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Skill_Of_Course_By_PkArgs = {
  _inc?: Maybe<Skill_Of_Course_Inc_Input>;
  _set?: Maybe<Skill_Of_Course_Set_Input>;
  pk_columns: Skill_Of_Course_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Skill_Of_Course_ManyArgs = {
  updates: Array<Skill_Of_Course_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Tag_CourseArgs = {
  _set?: Maybe<Tag_Course_Set_Input>;
  where: Tag_Course_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Tag_Course_By_PkArgs = {
  _set?: Maybe<Tag_Course_Set_Input>;
  pk_columns: Tag_Course_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Tag_Course_ManyArgs = {
  updates: Array<Tag_Course_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Tag_Of_CourseArgs = {
  _set?: Maybe<Tag_Of_Course_Set_Input>;
  where: Tag_Of_Course_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Tag_Of_Course_By_PkArgs = {
  _set?: Maybe<Tag_Of_Course_Set_Input>;
  pk_columns: Tag_Of_Course_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Tag_Of_Course_ManyArgs = {
  updates: Array<Tag_Of_Course_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_ThematicArgs = {
  _set?: Maybe<Thematic_Set_Input>;
  where: Thematic_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Thematic_By_PkArgs = {
  _set?: Maybe<Thematic_Set_Input>;
  pk_columns: Thematic_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Thematic_ManyArgs = {
  updates: Array<Thematic_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_TranslationArgs = {
  _set?: Maybe<Translation_Set_Input>;
  where: Translation_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Translation_By_PkArgs = {
  _set?: Maybe<Translation_Set_Input>;
  pk_columns: Translation_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Translation_ManyArgs = {
  updates: Array<Translation_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_UserArgs = {
  _inc?: Maybe<User_Inc_Input>;
  _set?: Maybe<User_Set_Input>;
  where: User_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_UserCourseProgressArgs = {
  _set?: Maybe<UserCourseProgress_Set_Input>;
  where: UserCourseProgress_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_UserCourseProgress_By_PkArgs = {
  _set?: Maybe<UserCourseProgress_Set_Input>;
  pk_columns: UserCourseProgress_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_UserCourseProgress_ManyArgs = {
  updates: Array<UserCourseProgress_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_UserEventSlotArgs = {
  _set?: Maybe<UserEventSlot_Set_Input>;
  where: UserEventSlot_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_UserEventSlot_By_PkArgs = {
  _set?: Maybe<UserEventSlot_Set_Input>;
  pk_columns: UserEventSlot_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_UserEventSlot_ManyArgs = {
  updates: Array<UserEventSlot_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_UserGroupArgs = {
  _set?: Maybe<UserGroup_Set_Input>;
  where: UserGroup_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_UserGroup_By_PkArgs = {
  _set?: Maybe<UserGroup_Set_Input>;
  pk_columns: UserGroup_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_UserGroup_ManyArgs = {
  updates: Array<UserGroup_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_UserModuleProgressArgs = {
  _inc?: Maybe<UserModuleProgress_Inc_Input>;
  _set?: Maybe<UserModuleProgress_Set_Input>;
  where: UserModuleProgress_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_UserModuleProgress_By_PkArgs = {
  _inc?: Maybe<UserModuleProgress_Inc_Input>;
  _set?: Maybe<UserModuleProgress_Set_Input>;
  pk_columns: UserModuleProgress_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_UserModuleProgress_ManyArgs = {
  updates: Array<UserModuleProgress_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_UserPrivateArgs = {
  _set?: Maybe<UserPrivate_Set_Input>;
  where: UserPrivate_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_UserPrivate_ManyArgs = {
  updates: Array<UserPrivate_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_UserSkillArgs = {
  _inc?: Maybe<UserSkill_Inc_Input>;
  _set?: Maybe<UserSkill_Set_Input>;
  where: UserSkill_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_UserSkill_By_PkArgs = {
  _inc?: Maybe<UserSkill_Inc_Input>;
  _set?: Maybe<UserSkill_Set_Input>;
  pk_columns: UserSkill_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_UserSkill_ManyArgs = {
  updates: Array<UserSkill_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_User_By_PkArgs = {
  _inc?: Maybe<User_Inc_Input>;
  _set?: Maybe<User_Set_Input>;
  pk_columns: User_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_User_ManyArgs = {
  updates: Array<User_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_VolunteerPostArgs = {
  _set?: Maybe<VolunteerPost_Set_Input>;
  where: VolunteerPost_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_VolunteerPost_By_PkArgs = {
  _set?: Maybe<VolunteerPost_Set_Input>;
  pk_columns: VolunteerPost_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_VolunteerPost_ManyArgs = {
  updates: Array<VolunteerPost_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Xapi_StatementArgs = {
  _set?: Maybe<Xapi_Statement_Set_Input>;
  where: Xapi_Statement_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Xapi_Statement_By_PkArgs = {
  _set?: Maybe<Xapi_Statement_Set_Input>;
  pk_columns: Xapi_Statement_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Xapi_Statement_ManyArgs = {
  updates: Array<Xapi_Statement_Updates>;
};


/** mutation root */
export type Mutation_RootValidateDataAccessRequestArgs = {
  input: ValidateDataAccessRequestInput;
};


/** mutation root */
export type Mutation_RootVolunteerAttributesArgs = {
  input: VolunteerAttributesInput;
};


/** Boolean expression to compare columns of type "numeric". All fields are combined with logical 'AND'. */
export type Numeric_Comparison_Exp = {
  _eq?: Maybe<Scalars['numeric']>;
  _gt?: Maybe<Scalars['numeric']>;
  _gte?: Maybe<Scalars['numeric']>;
  _in?: Maybe<Array<Scalars['numeric']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['numeric']>;
  _lte?: Maybe<Scalars['numeric']>;
  _neq?: Maybe<Scalars['numeric']>;
  _nin?: Maybe<Array<Scalars['numeric']>>;
};

/** column ordering options */
export enum Order_By {
  /** in ascending order, nulls last */
  Asc = 'asc',
  /** in ascending order, nulls first */
  AscNullsFirst = 'asc_nulls_first',
  /** in ascending order, nulls last */
  AscNullsLast = 'asc_nulls_last',
  /** in descending order, nulls first */
  Desc = 'desc',
  /** in descending order, nulls first */
  DescNullsFirst = 'desc_nulls_first',
  /** in descending order, nulls last */
  DescNullsLast = 'desc_nulls_last'
}

/** columns and relationships of "organisation" */
export type Organisation = {
  __typename?: 'organisation';
  id: Scalars['uuid'];
  name: Scalars['String'];
};

/** aggregated selection of "organisation" */
export type Organisation_Aggregate = {
  __typename?: 'organisation_aggregate';
  aggregate?: Maybe<Organisation_Aggregate_Fields>;
  nodes: Array<Organisation>;
};

/** aggregate fields of "organisation" */
export type Organisation_Aggregate_Fields = {
  __typename?: 'organisation_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Organisation_Max_Fields>;
  min?: Maybe<Organisation_Min_Fields>;
};


/** aggregate fields of "organisation" */
export type Organisation_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Organisation_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "organisation". All fields are combined with a logical 'AND'. */
export type Organisation_Bool_Exp = {
  _and?: Maybe<Array<Organisation_Bool_Exp>>;
  _not?: Maybe<Organisation_Bool_Exp>;
  _or?: Maybe<Array<Organisation_Bool_Exp>>;
  id?: Maybe<Uuid_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "organisation" */
export enum Organisation_Constraint {
  /** unique or primary key constraint on columns "name" */
  OrganisationNameKey = 'organisation_name_key',
  /** unique or primary key constraint on columns "id" */
  OrganisationPkey = 'organisation_pkey'
}

/** input type for inserting data into table "organisation" */
export type Organisation_Insert_Input = {
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Organisation_Max_Fields = {
  __typename?: 'organisation_max_fields';
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Organisation_Min_Fields = {
  __typename?: 'organisation_min_fields';
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "organisation" */
export type Organisation_Mutation_Response = {
  __typename?: 'organisation_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Organisation>;
};

/** on_conflict condition type for table "organisation" */
export type Organisation_On_Conflict = {
  constraint: Organisation_Constraint;
  update_columns?: Array<Organisation_Update_Column>;
  where?: Maybe<Organisation_Bool_Exp>;
};

/** Ordering options when selecting data from "organisation". */
export type Organisation_Order_By = {
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
};

/** primary key columns input for table: organisation */
export type Organisation_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "organisation" */
export enum Organisation_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name'
}

/** input type for updating data in table "organisation" */
export type Organisation_Set_Input = {
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
};

/** Streaming cursor of the table "organisation" */
export type Organisation_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Organisation_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Organisation_Stream_Cursor_Value_Input = {
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
};

/** update columns of table "organisation" */
export enum Organisation_Update_Column {
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name'
}

export type Organisation_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Organisation_Set_Input>;
  /** filter the rows which have to be updated */
  where: Organisation_Bool_Exp;
};

/** columns and relationships of "passwordResetToken" */
export type PasswordResetToken = {
  __typename?: 'passwordResetToken';
  createdAt: Scalars['timestamptz'];
  expiringAt: Scalars['timestamptz'];
  /** An object relationship */
  identity: Identity;
  identityId: Scalars['uuid'];
  passwordResetToken: Scalars['String'];
  revokedAt?: Maybe<Scalars['timestamptz']>;
};

/** aggregated selection of "passwordResetToken" */
export type PasswordResetToken_Aggregate = {
  __typename?: 'passwordResetToken_aggregate';
  aggregate?: Maybe<PasswordResetToken_Aggregate_Fields>;
  nodes: Array<PasswordResetToken>;
};

export type PasswordResetToken_Aggregate_Bool_Exp = {
  count?: Maybe<PasswordResetToken_Aggregate_Bool_Exp_Count>;
};

export type PasswordResetToken_Aggregate_Bool_Exp_Count = {
  arguments?: Maybe<Array<PasswordResetToken_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<PasswordResetToken_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "passwordResetToken" */
export type PasswordResetToken_Aggregate_Fields = {
  __typename?: 'passwordResetToken_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<PasswordResetToken_Max_Fields>;
  min?: Maybe<PasswordResetToken_Min_Fields>;
};


/** aggregate fields of "passwordResetToken" */
export type PasswordResetToken_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<PasswordResetToken_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "passwordResetToken" */
export type PasswordResetToken_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<PasswordResetToken_Max_Order_By>;
  min?: Maybe<PasswordResetToken_Min_Order_By>;
};

/** input type for inserting array relation for remote table "passwordResetToken" */
export type PasswordResetToken_Arr_Rel_Insert_Input = {
  data: Array<PasswordResetToken_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<PasswordResetToken_On_Conflict>;
};

/** Boolean expression to filter rows from the table "passwordResetToken". All fields are combined with a logical 'AND'. */
export type PasswordResetToken_Bool_Exp = {
  _and?: Maybe<Array<PasswordResetToken_Bool_Exp>>;
  _not?: Maybe<PasswordResetToken_Bool_Exp>;
  _or?: Maybe<Array<PasswordResetToken_Bool_Exp>>;
  createdAt?: Maybe<Timestamptz_Comparison_Exp>;
  expiringAt?: Maybe<Timestamptz_Comparison_Exp>;
  identity?: Maybe<Identity_Bool_Exp>;
  identityId?: Maybe<Uuid_Comparison_Exp>;
  passwordResetToken?: Maybe<String_Comparison_Exp>;
  revokedAt?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "passwordResetToken" */
export enum PasswordResetToken_Constraint {
  /** unique or primary key constraint on columns "passwordResetToken" */
  PasswordResetTokenPkey = 'passwordResetToken_pkey'
}

/** input type for inserting data into table "passwordResetToken" */
export type PasswordResetToken_Insert_Input = {
  createdAt?: Maybe<Scalars['timestamptz']>;
  expiringAt?: Maybe<Scalars['timestamptz']>;
  identity?: Maybe<Identity_Obj_Rel_Insert_Input>;
  identityId?: Maybe<Scalars['uuid']>;
  passwordResetToken?: Maybe<Scalars['String']>;
  revokedAt?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type PasswordResetToken_Max_Fields = {
  __typename?: 'passwordResetToken_max_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  expiringAt?: Maybe<Scalars['timestamptz']>;
  identityId?: Maybe<Scalars['uuid']>;
  passwordResetToken?: Maybe<Scalars['String']>;
  revokedAt?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "passwordResetToken" */
export type PasswordResetToken_Max_Order_By = {
  createdAt?: Maybe<Order_By>;
  expiringAt?: Maybe<Order_By>;
  identityId?: Maybe<Order_By>;
  passwordResetToken?: Maybe<Order_By>;
  revokedAt?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type PasswordResetToken_Min_Fields = {
  __typename?: 'passwordResetToken_min_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  expiringAt?: Maybe<Scalars['timestamptz']>;
  identityId?: Maybe<Scalars['uuid']>;
  passwordResetToken?: Maybe<Scalars['String']>;
  revokedAt?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "passwordResetToken" */
export type PasswordResetToken_Min_Order_By = {
  createdAt?: Maybe<Order_By>;
  expiringAt?: Maybe<Order_By>;
  identityId?: Maybe<Order_By>;
  passwordResetToken?: Maybe<Order_By>;
  revokedAt?: Maybe<Order_By>;
};

/** response of any mutation on the table "passwordResetToken" */
export type PasswordResetToken_Mutation_Response = {
  __typename?: 'passwordResetToken_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<PasswordResetToken>;
};

/** on_conflict condition type for table "passwordResetToken" */
export type PasswordResetToken_On_Conflict = {
  constraint: PasswordResetToken_Constraint;
  update_columns?: Array<PasswordResetToken_Update_Column>;
  where?: Maybe<PasswordResetToken_Bool_Exp>;
};

/** Ordering options when selecting data from "passwordResetToken". */
export type PasswordResetToken_Order_By = {
  createdAt?: Maybe<Order_By>;
  expiringAt?: Maybe<Order_By>;
  identity?: Maybe<Identity_Order_By>;
  identityId?: Maybe<Order_By>;
  passwordResetToken?: Maybe<Order_By>;
  revokedAt?: Maybe<Order_By>;
};

/** primary key columns input for table: passwordResetToken */
export type PasswordResetToken_Pk_Columns_Input = {
  passwordResetToken: Scalars['String'];
};

/** select columns of table "passwordResetToken" */
export enum PasswordResetToken_Select_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  ExpiringAt = 'expiringAt',
  /** column name */
  IdentityId = 'identityId',
  /** column name */
  PasswordResetToken = 'passwordResetToken',
  /** column name */
  RevokedAt = 'revokedAt'
}

/** input type for updating data in table "passwordResetToken" */
export type PasswordResetToken_Set_Input = {
  createdAt?: Maybe<Scalars['timestamptz']>;
  expiringAt?: Maybe<Scalars['timestamptz']>;
  identityId?: Maybe<Scalars['uuid']>;
  passwordResetToken?: Maybe<Scalars['String']>;
  revokedAt?: Maybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "passwordResetToken" */
export type PasswordResetToken_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: PasswordResetToken_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type PasswordResetToken_Stream_Cursor_Value_Input = {
  createdAt?: Maybe<Scalars['timestamptz']>;
  expiringAt?: Maybe<Scalars['timestamptz']>;
  identityId?: Maybe<Scalars['uuid']>;
  passwordResetToken?: Maybe<Scalars['String']>;
  revokedAt?: Maybe<Scalars['timestamptz']>;
};

/** update columns of table "passwordResetToken" */
export enum PasswordResetToken_Update_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  ExpiringAt = 'expiringAt',
  /** column name */
  IdentityId = 'identityId',
  /** column name */
  PasswordResetToken = 'passwordResetToken',
  /** column name */
  RevokedAt = 'revokedAt'
}

export type PasswordResetToken_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<PasswordResetToken_Set_Input>;
  /** filter the rows which have to be updated */
  where: PasswordResetToken_Bool_Exp;
};

export type Query_Root = {
  __typename?: 'query_root';
  /** fetch data from the table: "cmsPage" */
  cmsPage: Array<CmsPage>;
  /** fetch aggregated fields from the table: "cmsPage" */
  cmsPage_aggregate: CmsPage_Aggregate;
  /** fetch data from the table: "cmsPage" using primary key columns */
  cmsPage_by_pk?: Maybe<CmsPage>;
  /** fetch data from the table: "contentArticle" */
  contentArticle: Array<ContentArticle>;
  /** fetch aggregated fields from the table: "contentArticle" */
  contentArticle_aggregate: ContentArticle_Aggregate;
  /** fetch data from the table: "contentArticle" using primary key columns */
  contentArticle_by_pk?: Maybe<ContentArticle>;
  /** fetch data from the table: "contentAudioTranscript" */
  contentAudioTranscript: Array<ContentAudioTranscript>;
  /** fetch aggregated fields from the table: "contentAudioTranscript" */
  contentAudioTranscript_aggregate: ContentAudioTranscript_Aggregate;
  /** fetch data from the table: "contentAudioTranscript" using primary key columns */
  contentAudioTranscript_by_pk?: Maybe<ContentAudioTranscript>;
  /** fetch data from the table: "contentCmi5" */
  contentCmi5: Array<ContentCmi5>;
  /** fetch data from the table: "contentCmi5AssignableUnit" */
  contentCmi5AssignableUnit: Array<ContentCmi5AssignableUnit>;
  /** fetch aggregated fields from the table: "contentCmi5AssignableUnit" */
  contentCmi5AssignableUnit_aggregate: ContentCmi5AssignableUnit_Aggregate;
  /** fetch data from the table: "contentCmi5AssignableUnit" using primary key columns */
  contentCmi5AssignableUnit_by_pk?: Maybe<ContentCmi5AssignableUnit>;
  /** fetch data from the table: "contentCmi5Objective" */
  contentCmi5Objective: Array<ContentCmi5Objective>;
  /** fetch aggregated fields from the table: "contentCmi5Objective" */
  contentCmi5Objective_aggregate: ContentCmi5Objective_Aggregate;
  /** fetch data from the table: "contentCmi5Objective" using primary key columns */
  contentCmi5Objective_by_pk?: Maybe<ContentCmi5Objective>;
  /** fetch aggregated fields from the table: "contentCmi5" */
  contentCmi5_aggregate: ContentCmi5_Aggregate;
  /** fetch data from the table: "contentCmi5" using primary key columns */
  contentCmi5_by_pk?: Maybe<ContentCmi5>;
  /** fetch data from the table: "contentDocument" */
  contentDocument: Array<ContentDocument>;
  /** fetch data from the table: "contentDocumentUpload" */
  contentDocumentUpload: Array<ContentDocumentUpload>;
  /** fetch aggregated fields from the table: "contentDocumentUpload" */
  contentDocumentUpload_aggregate: ContentDocumentUpload_Aggregate;
  /** fetch data from the table: "contentDocumentUpload" using primary key columns */
  contentDocumentUpload_by_pk?: Maybe<ContentDocumentUpload>;
  /** fetch aggregated fields from the table: "contentDocument" */
  contentDocument_aggregate: ContentDocument_Aggregate;
  /** fetch data from the table: "contentDocument" using primary key columns */
  contentDocument_by_pk?: Maybe<ContentDocument>;
  /** fetch data from the table: "contentEmbed" */
  contentEmbed: Array<ContentEmbed>;
  /** fetch data from the table: "contentEmbedAudioTranscript" */
  contentEmbedAudioTranscript: Array<ContentEmbedAudioTranscript>;
  /** fetch aggregated fields from the table: "contentEmbedAudioTranscript" */
  contentEmbedAudioTranscript_aggregate: ContentEmbedAudioTranscript_Aggregate;
  /** fetch data from the table: "contentEmbedAudioTranscript" using primary key columns */
  contentEmbedAudioTranscript_by_pk?: Maybe<ContentEmbedAudioTranscript>;
  /** fetch aggregated fields from the table: "contentEmbed" */
  contentEmbed_aggregate: ContentEmbed_Aggregate;
  /** fetch data from the table: "contentEmbed" using primary key columns */
  contentEmbed_by_pk?: Maybe<ContentEmbed>;
  /** fetch data from the table: "contentEvent" */
  contentEvent: Array<ContentEvent>;
  /** fetch data from the table: "contentEventAttachment" */
  contentEventAttachment: Array<ContentEventAttachment>;
  /** fetch aggregated fields from the table: "contentEventAttachment" */
  contentEventAttachment_aggregate: ContentEventAttachment_Aggregate;
  /** fetch data from the table: "contentEventAttachment" using primary key columns */
  contentEventAttachment_by_pk?: Maybe<ContentEventAttachment>;
  /** fetch data from the table: "contentEventSlot" */
  contentEventSlot: Array<ContentEventSlot>;
  /** fetch aggregated fields from the table: "contentEventSlot" */
  contentEventSlot_aggregate: ContentEventSlot_Aggregate;
  /** fetch data from the table: "contentEventSlot_animator" */
  contentEventSlot_animator: Array<ContentEventSlot_Animator>;
  /** fetch aggregated fields from the table: "contentEventSlot_animator" */
  contentEventSlot_animator_aggregate: ContentEventSlot_Animator_Aggregate;
  /** fetch data from the table: "contentEventSlot_animator" using primary key columns */
  contentEventSlot_animator_by_pk?: Maybe<ContentEventSlot_Animator>;
  /** fetch data from the table: "contentEventSlot" using primary key columns */
  contentEventSlot_by_pk?: Maybe<ContentEventSlot>;
  /** fetch aggregated fields from the table: "contentEvent" */
  contentEvent_aggregate: ContentEvent_Aggregate;
  /** fetch data from the table: "contentEvent" using primary key columns */
  contentEvent_by_pk?: Maybe<ContentEvent>;
  /** fetch data from the table: "contentPodcast" */
  contentPodcast: Array<ContentPodcast>;
  /** fetch data from the table: "contentPodcastUpload" */
  contentPodcastUpload: Array<ContentPodcastUpload>;
  /** fetch aggregated fields from the table: "contentPodcastUpload" */
  contentPodcastUpload_aggregate: ContentPodcastUpload_Aggregate;
  /** fetch data from the table: "contentPodcastUpload" using primary key columns */
  contentPodcastUpload_by_pk?: Maybe<ContentPodcastUpload>;
  /** fetch aggregated fields from the table: "contentPodcast" */
  contentPodcast_aggregate: ContentPodcast_Aggregate;
  /** fetch data from the table: "contentPodcast" using primary key columns */
  contentPodcast_by_pk?: Maybe<ContentPodcast>;
  /** fetch data from the table: "contentVideo" */
  contentVideo: Array<ContentVideo>;
  /** fetch data from the table: "contentVideoCaption" */
  contentVideoCaption: Array<ContentVideoCaption>;
  /** fetch aggregated fields from the table: "contentVideoCaption" */
  contentVideoCaption_aggregate: ContentVideoCaption_Aggregate;
  /** fetch data from the table: "contentVideoCaption" using primary key columns */
  contentVideoCaption_by_pk?: Maybe<ContentVideoCaption>;
  /** An array relationship */
  contentVideoDetails: Array<ContentVideoDetails>;
  /** An aggregate relationship */
  contentVideoDetails_aggregate: ContentVideoDetails_Aggregate;
  /** fetch data from the table: "contentVideoDetails" using primary key columns */
  contentVideoDetails_by_pk?: Maybe<ContentVideoDetails>;
  /** fetch aggregated fields from the table: "contentVideo" */
  contentVideo_aggregate: ContentVideo_Aggregate;
  /** fetch data from the table: "contentVideo" using primary key columns */
  contentVideo_by_pk?: Maybe<ContentVideo>;
  /** fetch data from the table: "course" */
  course: Array<Course>;
  /** fetch data from the table: "courseAssign" */
  courseAssign: Array<CourseAssign>;
  /** fetch data from the table: "courseAssignEmployee" */
  courseAssignEmployee: Array<CourseAssignEmployee>;
  /** fetch data from the table: "courseAssignEmployeeDirection" */
  courseAssignEmployeeDirection: Array<CourseAssignEmployeeDirection>;
  /** fetch aggregated fields from the table: "courseAssignEmployeeDirection" */
  courseAssignEmployeeDirection_aggregate: CourseAssignEmployeeDirection_Aggregate;
  /** fetch data from the table: "courseAssignEmployeeDirection" using primary key columns */
  courseAssignEmployeeDirection_by_pk?: Maybe<CourseAssignEmployeeDirection>;
  /** fetch data from the table: "courseAssignEmployeeFa" */
  courseAssignEmployeeFa: Array<CourseAssignEmployeeFa>;
  /** fetch aggregated fields from the table: "courseAssignEmployeeFa" */
  courseAssignEmployeeFa_aggregate: CourseAssignEmployeeFa_Aggregate;
  /** fetch data from the table: "courseAssignEmployeeFa" using primary key columns */
  courseAssignEmployeeFa_by_pk?: Maybe<CourseAssignEmployeeFa>;
  /** fetch aggregated fields from the table: "courseAssignEmployee" */
  courseAssignEmployee_aggregate: CourseAssignEmployee_Aggregate;
  /** fetch data from the table: "courseAssignEmployee" using primary key columns */
  courseAssignEmployee_by_pk?: Maybe<CourseAssignEmployee>;
  /** fetch data from the table: "courseAssignGroup" */
  courseAssignGroup: Array<CourseAssignGroup>;
  /** fetch aggregated fields from the table: "courseAssignGroup" */
  courseAssignGroup_aggregate: CourseAssignGroup_Aggregate;
  /** fetch data from the table: "courseAssignGroup" using primary key columns */
  courseAssignGroup_by_pk?: Maybe<CourseAssignGroup>;
  /** fetch data from the table: "courseAssignOther" */
  courseAssignOther: Array<CourseAssignOther>;
  /** fetch aggregated fields from the table: "courseAssignOther" */
  courseAssignOther_aggregate: CourseAssignOther_Aggregate;
  /** fetch data from the table: "courseAssignOther" using primary key columns */
  courseAssignOther_by_pk?: Maybe<CourseAssignOther>;
  /** fetch data from the table: "courseAssignUser" */
  courseAssignUser: Array<CourseAssignUser>;
  /** fetch aggregated fields from the table: "courseAssignUser" */
  courseAssignUser_aggregate: CourseAssignUser_Aggregate;
  /** fetch data from the table: "courseAssignUser" using primary key columns */
  courseAssignUser_by_pk?: Maybe<CourseAssignUser>;
  /** fetch data from the table: "courseAssignVolunteer" */
  courseAssignVolunteer: Array<CourseAssignVolunteer>;
  /** fetch data from the table: "courseAssignVolunteerFa" */
  courseAssignVolunteerFa: Array<CourseAssignVolunteerFa>;
  /** fetch aggregated fields from the table: "courseAssignVolunteerFa" */
  courseAssignVolunteerFa_aggregate: CourseAssignVolunteerFa_Aggregate;
  /** fetch data from the table: "courseAssignVolunteerFa" using primary key columns */
  courseAssignVolunteerFa_by_pk?: Maybe<CourseAssignVolunteerFa>;
  /** fetch data from the table: "courseAssignVolunteerJobTitle" */
  courseAssignVolunteerJobTitle: Array<CourseAssignVolunteerJobTitle>;
  /** fetch aggregated fields from the table: "courseAssignVolunteerJobTitle" */
  courseAssignVolunteerJobTitle_aggregate: CourseAssignVolunteerJobTitle_Aggregate;
  /** fetch data from the table: "courseAssignVolunteerJobTitle" using primary key columns */
  courseAssignVolunteerJobTitle_by_pk?: Maybe<CourseAssignVolunteerJobTitle>;
  /** fetch data from the table: "courseAssignVolunteerSite" */
  courseAssignVolunteerSite: Array<CourseAssignVolunteerSite>;
  /** fetch aggregated fields from the table: "courseAssignVolunteerSite" */
  courseAssignVolunteerSite_aggregate: CourseAssignVolunteerSite_Aggregate;
  /** fetch data from the table: "courseAssignVolunteerSite" using primary key columns */
  courseAssignVolunteerSite_by_pk?: Maybe<CourseAssignVolunteerSite>;
  /** fetch aggregated fields from the table: "courseAssignVolunteer" */
  courseAssignVolunteer_aggregate: CourseAssignVolunteer_Aggregate;
  /** fetch data from the table: "courseAssignVolunteer" using primary key columns */
  courseAssignVolunteer_by_pk?: Maybe<CourseAssignVolunteer>;
  /** fetch aggregated fields from the table: "courseAssign" */
  courseAssign_aggregate: CourseAssign_Aggregate;
  /** fetch data from the table: "courseAssign" using primary key columns */
  courseAssign_by_pk?: Maybe<CourseAssign>;
  /** fetch aggregated fields from the table: "course" */
  course_aggregate: Course_Aggregate;
  /** fetch data from the table: "course" using primary key columns */
  course_by_pk?: Maybe<Course>;
  /** fetch data from the table: "d_courseUser" */
  d_courseUser: Array<D_CourseUser>;
  /** fetch data from the table: "d_courseUserRefresh" */
  d_courseUserRefresh: Array<D_CourseUserRefresh>;
  /** fetch aggregated fields from the table: "d_courseUserRefresh" */
  d_courseUserRefresh_aggregate: D_CourseUserRefresh_Aggregate;
  /** fetch data from the table: "d_courseUserRefresh" using primary key columns */
  d_courseUserRefresh_by_pk?: Maybe<D_CourseUserRefresh>;
  /** fetch aggregated fields from the table: "d_courseUser" */
  d_courseUser_aggregate: D_CourseUser_Aggregate;
  /** fetch data from the table: "dataAccessRequest" */
  dataAccessRequest: Array<DataAccessRequest>;
  /** fetch aggregated fields from the table: "dataAccessRequest" */
  dataAccessRequest_aggregate: DataAccessRequest_Aggregate;
  /** fetch data from the table: "dataAccessRequest" using primary key columns */
  dataAccessRequest_by_pk?: Maybe<DataAccessRequest>;
  /** fetch data from the table: "direction" */
  direction: Array<Direction>;
  /** fetch aggregated fields from the table: "direction" */
  direction_aggregate: Direction_Aggregate;
  /** fetch data from the table: "direction" using primary key columns */
  direction_by_pk?: Maybe<Direction>;
  downloadJobTitleReferential?: Maybe<ReferentialJobTitleResponse>;
  /** Get skill referential */
  downloadSkillReferential?: Maybe<ReferentialResponse>;
  /** fetch data from the table: "emailValidationToken" */
  emailValidationToken: Array<EmailValidationToken>;
  /** fetch aggregated fields from the table: "emailValidationToken" */
  emailValidationToken_aggregate: EmailValidationToken_Aggregate;
  /** fetch data from the table: "emailValidationToken" using primary key columns */
  emailValidationToken_by_pk?: Maybe<EmailValidationToken>;
  exportMonitoringCourse?: Maybe<ExportMonitoringCourseResponse>;
  externalAPICall?: Maybe<Scalars['String']>;
  /** fetch data from the table: "fa" */
  fa: Array<Fa>;
  /** fetch aggregated fields from the table: "fa" */
  fa_aggregate: Fa_Aggregate;
  /** fetch data from the table: "fa" using primary key columns */
  fa_by_pk?: Maybe<Fa>;
  /** fetch data from the table: "faq" */
  faq: Array<Faq>;
  /** fetch aggregated fields from the table: "faq" */
  faq_aggregate: Faq_Aggregate;
  /** fetch data from the table: "faq" using primary key columns */
  faq_by_pk?: Maybe<Faq>;
  getModuleResourceUri?: Maybe<GetModuleResourceUriResponse>;
  getMonitoringCourse?: Maybe<GetMonitoringCourseResponse>;
  getPaginatedEventList?: Maybe<GetPaginatedEventListResponse>;
  getPaginatedSlotAssignedLearnerList?: Maybe<GetPaginatedSlotAssignedLearnerListResponse>;
  /** Get a delegated upload token for api video */
  getVideoDelegatedToken?: Maybe<DelegatedTokenResponse>;
  /** fetch data from the table: "group" */
  group: Array<Group>;
  /** fetch aggregated fields from the table: "group" */
  group_aggregate: Group_Aggregate;
  /** fetch data from the table: "group" using primary key columns */
  group_by_pk?: Maybe<Group>;
  hello?: Maybe<Scalars['String']>;
  /** fetch data from the table: "home" */
  home: Array<Home>;
  /** fetch aggregated fields from the table: "home" */
  home_aggregate: Home_Aggregate;
  /** fetch data from the table: "home" using primary key columns */
  home_by_pk?: Maybe<Home>;
  /** fetch data from the table: "i18n" */
  i18n: Array<I18n>;
  /** fetch aggregated fields from the table: "i18n" */
  i18n_aggregate: I18n_Aggregate;
  /** fetch data from the table: "i18n" using primary key columns */
  i18n_by_pk?: Maybe<I18n>;
  /** fetch data from the table: "identity" */
  identity: Array<Identity>;
  /** fetch aggregated fields from the table: "identity" */
  identity_aggregate: Identity_Aggregate;
  /** fetch data from the table: "identity" using primary key columns */
  identity_by_pk?: Maybe<Identity>;
  /** fetch data from the table: "import" */
  import: Array<Import>;
  /** fetch data from the table: "importGroup" */
  importGroup: Array<ImportGroup>;
  /** fetch aggregated fields from the table: "importGroup" */
  importGroup_aggregate: ImportGroup_Aggregate;
  /** fetch data from the table: "importGroup" using primary key columns */
  importGroup_by_pk?: Maybe<ImportGroup>;
  /** fetch data from the table: "importSkill" */
  importSkill: Array<ImportSkill>;
  /** fetch aggregated fields from the table: "importSkill" */
  importSkill_aggregate: ImportSkill_Aggregate;
  /** fetch data from the table: "importSkill" using primary key columns */
  importSkill_by_pk?: Maybe<ImportSkill>;
  /** fetch aggregated fields from the table: "import" */
  import_aggregate: Import_Aggregate;
  /** fetch data from the table: "import" using primary key columns */
  import_by_pk?: Maybe<Import>;
  /** fetch data from the table: "jobTitle" */
  jobTitle: Array<JobTitle>;
  /** fetch aggregated fields from the table: "jobTitle" */
  jobTitle_aggregate: JobTitle_Aggregate;
  /** fetch data from the table: "jobTitle" using primary key columns */
  jobTitle_by_pk?: Maybe<JobTitle>;
  /** fetch data from the table: "module" */
  module: Array<Module>;
  /** fetch data from the table: "moduleAnnex" */
  moduleAnnex: Array<ModuleAnnex>;
  /** fetch aggregated fields from the table: "moduleAnnex" */
  moduleAnnex_aggregate: ModuleAnnex_Aggregate;
  /** fetch data from the table: "moduleAnnex" using primary key columns */
  moduleAnnex_by_pk?: Maybe<ModuleAnnex>;
  /** fetch data from the table: "moduleContent" */
  moduleContent: Array<ModuleContent>;
  /** fetch aggregated fields from the table: "moduleContent" */
  moduleContent_aggregate: ModuleContent_Aggregate;
  /** fetch data from the table: "moduleContent" using primary key columns */
  moduleContent_by_pk?: Maybe<ModuleContent>;
  /** fetch data from the table: "moduleTask" */
  moduleTask: Array<ModuleTask>;
  /** fetch aggregated fields from the table: "moduleTask" */
  moduleTask_aggregate: ModuleTask_Aggregate;
  /** fetch data from the table: "moduleTask" using primary key columns */
  moduleTask_by_pk?: Maybe<ModuleTask>;
  /** fetch aggregated fields from the table: "module" */
  module_aggregate: Module_Aggregate;
  /** fetch data from the table: "module" using primary key columns */
  module_by_pk?: Maybe<Module>;
  /** fetch data from the table: "organisation" */
  organisation: Array<Organisation>;
  /** fetch aggregated fields from the table: "organisation" */
  organisation_aggregate: Organisation_Aggregate;
  /** fetch data from the table: "organisation" using primary key columns */
  organisation_by_pk?: Maybe<Organisation>;
  /** fetch data from the table: "passwordResetToken" */
  passwordResetToken: Array<PasswordResetToken>;
  /** fetch aggregated fields from the table: "passwordResetToken" */
  passwordResetToken_aggregate: PasswordResetToken_Aggregate;
  /** fetch data from the table: "passwordResetToken" using primary key columns */
  passwordResetToken_by_pk?: Maybe<PasswordResetToken>;
  /** fetch data from the table: "refreshToken" */
  refreshToken: Array<RefreshToken>;
  /** fetch aggregated fields from the table: "refreshToken" */
  refreshToken_aggregate: RefreshToken_Aggregate;
  /** fetch data from the table: "refreshToken" using primary key columns */
  refreshToken_by_pk?: Maybe<RefreshToken>;
  /** fetch data from the table: "role" */
  role: Array<Role>;
  /** fetch aggregated fields from the table: "role" */
  role_aggregate: Role_Aggregate;
  /** fetch data from the table: "role" using primary key columns */
  role_by_pk?: Maybe<Role>;
  /** get self role on the app following my connection context */
  roles: Array<Roles>;
  /** fetch data from the table: "sequence" */
  sequence: Array<Sequence>;
  /** fetch aggregated fields from the table: "sequence" */
  sequence_aggregate: Sequence_Aggregate;
  /** fetch data from the table: "sequence" using primary key columns */
  sequence_by_pk?: Maybe<Sequence>;
  /** fetch data from the table: "sequence_module" */
  sequence_module: Array<Sequence_Module>;
  /** fetch aggregated fields from the table: "sequence_module" */
  sequence_module_aggregate: Sequence_Module_Aggregate;
  /** fetch data from the table: "sequence_module" using primary key columns */
  sequence_module_by_pk?: Maybe<Sequence_Module>;
  /** fetch data from the table: "site" */
  site: Array<Site>;
  /** fetch aggregated fields from the table: "site" */
  site_aggregate: Site_Aggregate;
  /** fetch data from the table: "site" using primary key columns */
  site_by_pk?: Maybe<Site>;
  /** fetch data from the table: "skill" */
  skill: Array<Skill>;
  /** fetch data from the table: "skillLevel" */
  skillLevel: Array<SkillLevel>;
  /** fetch aggregated fields from the table: "skillLevel" */
  skillLevel_aggregate: SkillLevel_Aggregate;
  /** fetch data from the table: "skillLevel" using primary key columns */
  skillLevel_by_pk?: Maybe<SkillLevel>;
  /** fetch aggregated fields from the table: "skill" */
  skill_aggregate: Skill_Aggregate;
  /** fetch data from the table: "skill" using primary key columns */
  skill_by_pk?: Maybe<Skill>;
  /** fetch data from the table: "skill_of_course" */
  skill_of_course: Array<Skill_Of_Course>;
  /** fetch aggregated fields from the table: "skill_of_course" */
  skill_of_course_aggregate: Skill_Of_Course_Aggregate;
  /** fetch data from the table: "skill_of_course" using primary key columns */
  skill_of_course_by_pk?: Maybe<Skill_Of_Course>;
  /** fetch data from the table: "tag_course" */
  tag_course: Array<Tag_Course>;
  /** fetch aggregated fields from the table: "tag_course" */
  tag_course_aggregate: Tag_Course_Aggregate;
  /** fetch data from the table: "tag_course" using primary key columns */
  tag_course_by_pk?: Maybe<Tag_Course>;
  /** fetch data from the table: "tag_of_course" */
  tag_of_course: Array<Tag_Of_Course>;
  /** fetch aggregated fields from the table: "tag_of_course" */
  tag_of_course_aggregate: Tag_Of_Course_Aggregate;
  /** fetch data from the table: "tag_of_course" using primary key columns */
  tag_of_course_by_pk?: Maybe<Tag_Of_Course>;
  /** fetch data from the table: "thematic" */
  thematic: Array<Thematic>;
  /** fetch aggregated fields from the table: "thematic" */
  thematic_aggregate: Thematic_Aggregate;
  /** fetch data from the table: "thematic" using primary key columns */
  thematic_by_pk?: Maybe<Thematic>;
  /** fetch data from the table: "translation" */
  translation: Array<Translation>;
  /** fetch aggregated fields from the table: "translation" */
  translation_aggregate: Translation_Aggregate;
  /** fetch data from the table: "translation" using primary key columns */
  translation_by_pk?: Maybe<Translation>;
  /** fetch data from the table: "user" */
  user: Array<User>;
  /** fetch data from the table: "userCourseProgress" */
  userCourseProgress: Array<UserCourseProgress>;
  /** fetch aggregated fields from the table: "userCourseProgress" */
  userCourseProgress_aggregate: UserCourseProgress_Aggregate;
  /** fetch data from the table: "userCourseProgress" using primary key columns */
  userCourseProgress_by_pk?: Maybe<UserCourseProgress>;
  /** fetch data from the table: "userEventSlot" */
  userEventSlot: Array<UserEventSlot>;
  /** fetch aggregated fields from the table: "userEventSlot" */
  userEventSlot_aggregate: UserEventSlot_Aggregate;
  /** fetch data from the table: "userEventSlot" using primary key columns */
  userEventSlot_by_pk?: Maybe<UserEventSlot>;
  /** fetch data from the table: "userGroup" */
  userGroup: Array<UserGroup>;
  /** fetch aggregated fields from the table: "userGroup" */
  userGroup_aggregate: UserGroup_Aggregate;
  /** fetch data from the table: "userGroup" using primary key columns */
  userGroup_by_pk?: Maybe<UserGroup>;
  /** An array relationship */
  userModuleProgress: Array<UserModuleProgress>;
  /** An aggregate relationship */
  userModuleProgress_aggregate: UserModuleProgress_Aggregate;
  /** fetch data from the table: "userModuleProgress" using primary key columns */
  userModuleProgress_by_pk?: Maybe<UserModuleProgress>;
  /** fetch data from the table: "userPrivate" */
  userPrivate: Array<UserPrivate>;
  /** fetch aggregated fields from the table: "userPrivate" */
  userPrivate_aggregate: UserPrivate_Aggregate;
  /** fetch data from the table: "userSkill" */
  userSkill: Array<UserSkill>;
  /** fetch aggregated fields from the table: "userSkill" */
  userSkill_aggregate: UserSkill_Aggregate;
  /** fetch data from the table: "userSkill" using primary key columns */
  userSkill_by_pk?: Maybe<UserSkill>;
  /** fetch aggregated fields from the table: "user" */
  user_aggregate: User_Aggregate;
  /** fetch data from the table: "user" using primary key columns */
  user_by_pk?: Maybe<User>;
  /** fetch data from the table: "volunteerPost" */
  volunteerPost: Array<VolunteerPost>;
  /** fetch aggregated fields from the table: "volunteerPost" */
  volunteerPost_aggregate: VolunteerPost_Aggregate;
  /** fetch data from the table: "volunteerPost" using primary key columns */
  volunteerPost_by_pk?: Maybe<VolunteerPost>;
  /** fetch data from the table: "xapi_statement" */
  xapi_statement: Array<Xapi_Statement>;
  /** fetch aggregated fields from the table: "xapi_statement" */
  xapi_statement_aggregate: Xapi_Statement_Aggregate;
  /** fetch data from the table: "xapi_statement" using primary key columns */
  xapi_statement_by_pk?: Maybe<Xapi_Statement>;
};


export type Query_RootCmsPageArgs = {
  distinct_on?: Maybe<Array<CmsPage_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<CmsPage_Order_By>>;
  where?: Maybe<CmsPage_Bool_Exp>;
};


export type Query_RootCmsPage_AggregateArgs = {
  distinct_on?: Maybe<Array<CmsPage_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<CmsPage_Order_By>>;
  where?: Maybe<CmsPage_Bool_Exp>;
};


export type Query_RootCmsPage_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootContentArticleArgs = {
  distinct_on?: Maybe<Array<ContentArticle_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ContentArticle_Order_By>>;
  where?: Maybe<ContentArticle_Bool_Exp>;
};


export type Query_RootContentArticle_AggregateArgs = {
  distinct_on?: Maybe<Array<ContentArticle_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ContentArticle_Order_By>>;
  where?: Maybe<ContentArticle_Bool_Exp>;
};


export type Query_RootContentArticle_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootContentAudioTranscriptArgs = {
  distinct_on?: Maybe<Array<ContentAudioTranscript_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ContentAudioTranscript_Order_By>>;
  where?: Maybe<ContentAudioTranscript_Bool_Exp>;
};


export type Query_RootContentAudioTranscript_AggregateArgs = {
  distinct_on?: Maybe<Array<ContentAudioTranscript_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ContentAudioTranscript_Order_By>>;
  where?: Maybe<ContentAudioTranscript_Bool_Exp>;
};


export type Query_RootContentAudioTranscript_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootContentCmi5Args = {
  distinct_on?: Maybe<Array<ContentCmi5_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ContentCmi5_Order_By>>;
  where?: Maybe<ContentCmi5_Bool_Exp>;
};


export type Query_RootContentCmi5AssignableUnitArgs = {
  distinct_on?: Maybe<Array<ContentCmi5AssignableUnit_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ContentCmi5AssignableUnit_Order_By>>;
  where?: Maybe<ContentCmi5AssignableUnit_Bool_Exp>;
};


export type Query_RootContentCmi5AssignableUnit_AggregateArgs = {
  distinct_on?: Maybe<Array<ContentCmi5AssignableUnit_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ContentCmi5AssignableUnit_Order_By>>;
  where?: Maybe<ContentCmi5AssignableUnit_Bool_Exp>;
};


export type Query_RootContentCmi5AssignableUnit_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootContentCmi5ObjectiveArgs = {
  distinct_on?: Maybe<Array<ContentCmi5Objective_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ContentCmi5Objective_Order_By>>;
  where?: Maybe<ContentCmi5Objective_Bool_Exp>;
};


export type Query_RootContentCmi5Objective_AggregateArgs = {
  distinct_on?: Maybe<Array<ContentCmi5Objective_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ContentCmi5Objective_Order_By>>;
  where?: Maybe<ContentCmi5Objective_Bool_Exp>;
};


export type Query_RootContentCmi5Objective_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootContentCmi5_AggregateArgs = {
  distinct_on?: Maybe<Array<ContentCmi5_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ContentCmi5_Order_By>>;
  where?: Maybe<ContentCmi5_Bool_Exp>;
};


export type Query_RootContentCmi5_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootContentDocumentArgs = {
  distinct_on?: Maybe<Array<ContentDocument_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ContentDocument_Order_By>>;
  where?: Maybe<ContentDocument_Bool_Exp>;
};


export type Query_RootContentDocumentUploadArgs = {
  distinct_on?: Maybe<Array<ContentDocumentUpload_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ContentDocumentUpload_Order_By>>;
  where?: Maybe<ContentDocumentUpload_Bool_Exp>;
};


export type Query_RootContentDocumentUpload_AggregateArgs = {
  distinct_on?: Maybe<Array<ContentDocumentUpload_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ContentDocumentUpload_Order_By>>;
  where?: Maybe<ContentDocumentUpload_Bool_Exp>;
};


export type Query_RootContentDocumentUpload_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootContentDocument_AggregateArgs = {
  distinct_on?: Maybe<Array<ContentDocument_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ContentDocument_Order_By>>;
  where?: Maybe<ContentDocument_Bool_Exp>;
};


export type Query_RootContentDocument_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootContentEmbedArgs = {
  distinct_on?: Maybe<Array<ContentEmbed_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ContentEmbed_Order_By>>;
  where?: Maybe<ContentEmbed_Bool_Exp>;
};


export type Query_RootContentEmbedAudioTranscriptArgs = {
  distinct_on?: Maybe<Array<ContentEmbedAudioTranscript_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ContentEmbedAudioTranscript_Order_By>>;
  where?: Maybe<ContentEmbedAudioTranscript_Bool_Exp>;
};


export type Query_RootContentEmbedAudioTranscript_AggregateArgs = {
  distinct_on?: Maybe<Array<ContentEmbedAudioTranscript_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ContentEmbedAudioTranscript_Order_By>>;
  where?: Maybe<ContentEmbedAudioTranscript_Bool_Exp>;
};


export type Query_RootContentEmbedAudioTranscript_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootContentEmbed_AggregateArgs = {
  distinct_on?: Maybe<Array<ContentEmbed_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ContentEmbed_Order_By>>;
  where?: Maybe<ContentEmbed_Bool_Exp>;
};


export type Query_RootContentEmbed_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootContentEventArgs = {
  distinct_on?: Maybe<Array<ContentEvent_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ContentEvent_Order_By>>;
  where?: Maybe<ContentEvent_Bool_Exp>;
};


export type Query_RootContentEventAttachmentArgs = {
  distinct_on?: Maybe<Array<ContentEventAttachment_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ContentEventAttachment_Order_By>>;
  where?: Maybe<ContentEventAttachment_Bool_Exp>;
};


export type Query_RootContentEventAttachment_AggregateArgs = {
  distinct_on?: Maybe<Array<ContentEventAttachment_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ContentEventAttachment_Order_By>>;
  where?: Maybe<ContentEventAttachment_Bool_Exp>;
};


export type Query_RootContentEventAttachment_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootContentEventSlotArgs = {
  distinct_on?: Maybe<Array<ContentEventSlot_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ContentEventSlot_Order_By>>;
  where?: Maybe<ContentEventSlot_Bool_Exp>;
};


export type Query_RootContentEventSlot_AggregateArgs = {
  distinct_on?: Maybe<Array<ContentEventSlot_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ContentEventSlot_Order_By>>;
  where?: Maybe<ContentEventSlot_Bool_Exp>;
};


export type Query_RootContentEventSlot_AnimatorArgs = {
  distinct_on?: Maybe<Array<ContentEventSlot_Animator_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ContentEventSlot_Animator_Order_By>>;
  where?: Maybe<ContentEventSlot_Animator_Bool_Exp>;
};


export type Query_RootContentEventSlot_Animator_AggregateArgs = {
  distinct_on?: Maybe<Array<ContentEventSlot_Animator_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ContentEventSlot_Animator_Order_By>>;
  where?: Maybe<ContentEventSlot_Animator_Bool_Exp>;
};


export type Query_RootContentEventSlot_Animator_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootContentEventSlot_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootContentEvent_AggregateArgs = {
  distinct_on?: Maybe<Array<ContentEvent_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ContentEvent_Order_By>>;
  where?: Maybe<ContentEvent_Bool_Exp>;
};


export type Query_RootContentEvent_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootContentPodcastArgs = {
  distinct_on?: Maybe<Array<ContentPodcast_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ContentPodcast_Order_By>>;
  where?: Maybe<ContentPodcast_Bool_Exp>;
};


export type Query_RootContentPodcastUploadArgs = {
  distinct_on?: Maybe<Array<ContentPodcastUpload_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ContentPodcastUpload_Order_By>>;
  where?: Maybe<ContentPodcastUpload_Bool_Exp>;
};


export type Query_RootContentPodcastUpload_AggregateArgs = {
  distinct_on?: Maybe<Array<ContentPodcastUpload_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ContentPodcastUpload_Order_By>>;
  where?: Maybe<ContentPodcastUpload_Bool_Exp>;
};


export type Query_RootContentPodcastUpload_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootContentPodcast_AggregateArgs = {
  distinct_on?: Maybe<Array<ContentPodcast_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ContentPodcast_Order_By>>;
  where?: Maybe<ContentPodcast_Bool_Exp>;
};


export type Query_RootContentPodcast_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootContentVideoArgs = {
  distinct_on?: Maybe<Array<ContentVideo_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ContentVideo_Order_By>>;
  where?: Maybe<ContentVideo_Bool_Exp>;
};


export type Query_RootContentVideoCaptionArgs = {
  distinct_on?: Maybe<Array<ContentVideoCaption_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ContentVideoCaption_Order_By>>;
  where?: Maybe<ContentVideoCaption_Bool_Exp>;
};


export type Query_RootContentVideoCaption_AggregateArgs = {
  distinct_on?: Maybe<Array<ContentVideoCaption_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ContentVideoCaption_Order_By>>;
  where?: Maybe<ContentVideoCaption_Bool_Exp>;
};


export type Query_RootContentVideoCaption_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootContentVideoDetailsArgs = {
  distinct_on?: Maybe<Array<ContentVideoDetails_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ContentVideoDetails_Order_By>>;
  where?: Maybe<ContentVideoDetails_Bool_Exp>;
};


export type Query_RootContentVideoDetails_AggregateArgs = {
  distinct_on?: Maybe<Array<ContentVideoDetails_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ContentVideoDetails_Order_By>>;
  where?: Maybe<ContentVideoDetails_Bool_Exp>;
};


export type Query_RootContentVideoDetails_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootContentVideo_AggregateArgs = {
  distinct_on?: Maybe<Array<ContentVideo_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ContentVideo_Order_By>>;
  where?: Maybe<ContentVideo_Bool_Exp>;
};


export type Query_RootContentVideo_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootCourseArgs = {
  distinct_on?: Maybe<Array<Course_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Course_Order_By>>;
  where?: Maybe<Course_Bool_Exp>;
};


export type Query_RootCourseAssignArgs = {
  distinct_on?: Maybe<Array<CourseAssign_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<CourseAssign_Order_By>>;
  where?: Maybe<CourseAssign_Bool_Exp>;
};


export type Query_RootCourseAssignEmployeeArgs = {
  distinct_on?: Maybe<Array<CourseAssignEmployee_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<CourseAssignEmployee_Order_By>>;
  where?: Maybe<CourseAssignEmployee_Bool_Exp>;
};


export type Query_RootCourseAssignEmployeeDirectionArgs = {
  distinct_on?: Maybe<Array<CourseAssignEmployeeDirection_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<CourseAssignEmployeeDirection_Order_By>>;
  where?: Maybe<CourseAssignEmployeeDirection_Bool_Exp>;
};


export type Query_RootCourseAssignEmployeeDirection_AggregateArgs = {
  distinct_on?: Maybe<Array<CourseAssignEmployeeDirection_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<CourseAssignEmployeeDirection_Order_By>>;
  where?: Maybe<CourseAssignEmployeeDirection_Bool_Exp>;
};


export type Query_RootCourseAssignEmployeeDirection_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootCourseAssignEmployeeFaArgs = {
  distinct_on?: Maybe<Array<CourseAssignEmployeeFa_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<CourseAssignEmployeeFa_Order_By>>;
  where?: Maybe<CourseAssignEmployeeFa_Bool_Exp>;
};


export type Query_RootCourseAssignEmployeeFa_AggregateArgs = {
  distinct_on?: Maybe<Array<CourseAssignEmployeeFa_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<CourseAssignEmployeeFa_Order_By>>;
  where?: Maybe<CourseAssignEmployeeFa_Bool_Exp>;
};


export type Query_RootCourseAssignEmployeeFa_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootCourseAssignEmployee_AggregateArgs = {
  distinct_on?: Maybe<Array<CourseAssignEmployee_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<CourseAssignEmployee_Order_By>>;
  where?: Maybe<CourseAssignEmployee_Bool_Exp>;
};


export type Query_RootCourseAssignEmployee_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootCourseAssignGroupArgs = {
  distinct_on?: Maybe<Array<CourseAssignGroup_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<CourseAssignGroup_Order_By>>;
  where?: Maybe<CourseAssignGroup_Bool_Exp>;
};


export type Query_RootCourseAssignGroup_AggregateArgs = {
  distinct_on?: Maybe<Array<CourseAssignGroup_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<CourseAssignGroup_Order_By>>;
  where?: Maybe<CourseAssignGroup_Bool_Exp>;
};


export type Query_RootCourseAssignGroup_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootCourseAssignOtherArgs = {
  distinct_on?: Maybe<Array<CourseAssignOther_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<CourseAssignOther_Order_By>>;
  where?: Maybe<CourseAssignOther_Bool_Exp>;
};


export type Query_RootCourseAssignOther_AggregateArgs = {
  distinct_on?: Maybe<Array<CourseAssignOther_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<CourseAssignOther_Order_By>>;
  where?: Maybe<CourseAssignOther_Bool_Exp>;
};


export type Query_RootCourseAssignOther_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootCourseAssignUserArgs = {
  distinct_on?: Maybe<Array<CourseAssignUser_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<CourseAssignUser_Order_By>>;
  where?: Maybe<CourseAssignUser_Bool_Exp>;
};


export type Query_RootCourseAssignUser_AggregateArgs = {
  distinct_on?: Maybe<Array<CourseAssignUser_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<CourseAssignUser_Order_By>>;
  where?: Maybe<CourseAssignUser_Bool_Exp>;
};


export type Query_RootCourseAssignUser_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootCourseAssignVolunteerArgs = {
  distinct_on?: Maybe<Array<CourseAssignVolunteer_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<CourseAssignVolunteer_Order_By>>;
  where?: Maybe<CourseAssignVolunteer_Bool_Exp>;
};


export type Query_RootCourseAssignVolunteerFaArgs = {
  distinct_on?: Maybe<Array<CourseAssignVolunteerFa_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<CourseAssignVolunteerFa_Order_By>>;
  where?: Maybe<CourseAssignVolunteerFa_Bool_Exp>;
};


export type Query_RootCourseAssignVolunteerFa_AggregateArgs = {
  distinct_on?: Maybe<Array<CourseAssignVolunteerFa_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<CourseAssignVolunteerFa_Order_By>>;
  where?: Maybe<CourseAssignVolunteerFa_Bool_Exp>;
};


export type Query_RootCourseAssignVolunteerFa_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootCourseAssignVolunteerJobTitleArgs = {
  distinct_on?: Maybe<Array<CourseAssignVolunteerJobTitle_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<CourseAssignVolunteerJobTitle_Order_By>>;
  where?: Maybe<CourseAssignVolunteerJobTitle_Bool_Exp>;
};


export type Query_RootCourseAssignVolunteerJobTitle_AggregateArgs = {
  distinct_on?: Maybe<Array<CourseAssignVolunteerJobTitle_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<CourseAssignVolunteerJobTitle_Order_By>>;
  where?: Maybe<CourseAssignVolunteerJobTitle_Bool_Exp>;
};


export type Query_RootCourseAssignVolunteerJobTitle_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootCourseAssignVolunteerSiteArgs = {
  distinct_on?: Maybe<Array<CourseAssignVolunteerSite_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<CourseAssignVolunteerSite_Order_By>>;
  where?: Maybe<CourseAssignVolunteerSite_Bool_Exp>;
};


export type Query_RootCourseAssignVolunteerSite_AggregateArgs = {
  distinct_on?: Maybe<Array<CourseAssignVolunteerSite_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<CourseAssignVolunteerSite_Order_By>>;
  where?: Maybe<CourseAssignVolunteerSite_Bool_Exp>;
};


export type Query_RootCourseAssignVolunteerSite_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootCourseAssignVolunteer_AggregateArgs = {
  distinct_on?: Maybe<Array<CourseAssignVolunteer_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<CourseAssignVolunteer_Order_By>>;
  where?: Maybe<CourseAssignVolunteer_Bool_Exp>;
};


export type Query_RootCourseAssignVolunteer_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootCourseAssign_AggregateArgs = {
  distinct_on?: Maybe<Array<CourseAssign_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<CourseAssign_Order_By>>;
  where?: Maybe<CourseAssign_Bool_Exp>;
};


export type Query_RootCourseAssign_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootCourse_AggregateArgs = {
  distinct_on?: Maybe<Array<Course_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Course_Order_By>>;
  where?: Maybe<Course_Bool_Exp>;
};


export type Query_RootCourse_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootD_CourseUserArgs = {
  distinct_on?: Maybe<Array<D_CourseUser_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<D_CourseUser_Order_By>>;
  where?: Maybe<D_CourseUser_Bool_Exp>;
};


export type Query_RootD_CourseUserRefreshArgs = {
  distinct_on?: Maybe<Array<D_CourseUserRefresh_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<D_CourseUserRefresh_Order_By>>;
  where?: Maybe<D_CourseUserRefresh_Bool_Exp>;
};


export type Query_RootD_CourseUserRefresh_AggregateArgs = {
  distinct_on?: Maybe<Array<D_CourseUserRefresh_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<D_CourseUserRefresh_Order_By>>;
  where?: Maybe<D_CourseUserRefresh_Bool_Exp>;
};


export type Query_RootD_CourseUserRefresh_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootD_CourseUser_AggregateArgs = {
  distinct_on?: Maybe<Array<D_CourseUser_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<D_CourseUser_Order_By>>;
  where?: Maybe<D_CourseUser_Bool_Exp>;
};


export type Query_RootDataAccessRequestArgs = {
  distinct_on?: Maybe<Array<DataAccessRequest_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DataAccessRequest_Order_By>>;
  where?: Maybe<DataAccessRequest_Bool_Exp>;
};


export type Query_RootDataAccessRequest_AggregateArgs = {
  distinct_on?: Maybe<Array<DataAccessRequest_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DataAccessRequest_Order_By>>;
  where?: Maybe<DataAccessRequest_Bool_Exp>;
};


export type Query_RootDataAccessRequest_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootDirectionArgs = {
  distinct_on?: Maybe<Array<Direction_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Direction_Order_By>>;
  where?: Maybe<Direction_Bool_Exp>;
};


export type Query_RootDirection_AggregateArgs = {
  distinct_on?: Maybe<Array<Direction_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Direction_Order_By>>;
  where?: Maybe<Direction_Bool_Exp>;
};


export type Query_RootDirection_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootDownloadSkillReferentialArgs = {
  input: ReferentialInput;
};


export type Query_RootEmailValidationTokenArgs = {
  distinct_on?: Maybe<Array<EmailValidationToken_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<EmailValidationToken_Order_By>>;
  where?: Maybe<EmailValidationToken_Bool_Exp>;
};


export type Query_RootEmailValidationToken_AggregateArgs = {
  distinct_on?: Maybe<Array<EmailValidationToken_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<EmailValidationToken_Order_By>>;
  where?: Maybe<EmailValidationToken_Bool_Exp>;
};


export type Query_RootEmailValidationToken_By_PkArgs = {
  userId: Scalars['uuid'];
};


export type Query_RootExportMonitoringCourseArgs = {
  input: ExportMonitoringCourseInput;
};


export type Query_RootFaArgs = {
  distinct_on?: Maybe<Array<Fa_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Fa_Order_By>>;
  where?: Maybe<Fa_Bool_Exp>;
};


export type Query_RootFa_AggregateArgs = {
  distinct_on?: Maybe<Array<Fa_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Fa_Order_By>>;
  where?: Maybe<Fa_Bool_Exp>;
};


export type Query_RootFa_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootFaqArgs = {
  distinct_on?: Maybe<Array<Faq_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Faq_Order_By>>;
  where?: Maybe<Faq_Bool_Exp>;
};


export type Query_RootFaq_AggregateArgs = {
  distinct_on?: Maybe<Array<Faq_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Faq_Order_By>>;
  where?: Maybe<Faq_Bool_Exp>;
};


export type Query_RootFaq_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootGetModuleResourceUriArgs = {
  input: GetModuleResourceUriInput;
};


export type Query_RootGetMonitoringCourseArgs = {
  input: GetMonitoringCourseInput;
};


export type Query_RootGetPaginatedEventListArgs = {
  input: GetPaginatedEventListInput;
};


export type Query_RootGetPaginatedSlotAssignedLearnerListArgs = {
  input: GetPaginatedSlotAssignedLearnerListInput;
};


export type Query_RootGroupArgs = {
  distinct_on?: Maybe<Array<Group_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Group_Order_By>>;
  where?: Maybe<Group_Bool_Exp>;
};


export type Query_RootGroup_AggregateArgs = {
  distinct_on?: Maybe<Array<Group_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Group_Order_By>>;
  where?: Maybe<Group_Bool_Exp>;
};


export type Query_RootGroup_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootHomeArgs = {
  distinct_on?: Maybe<Array<Home_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Home_Order_By>>;
  where?: Maybe<Home_Bool_Exp>;
};


export type Query_RootHome_AggregateArgs = {
  distinct_on?: Maybe<Array<Home_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Home_Order_By>>;
  where?: Maybe<Home_Bool_Exp>;
};


export type Query_RootHome_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootI18nArgs = {
  distinct_on?: Maybe<Array<I18n_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<I18n_Order_By>>;
  where?: Maybe<I18n_Bool_Exp>;
};


export type Query_RootI18n_AggregateArgs = {
  distinct_on?: Maybe<Array<I18n_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<I18n_Order_By>>;
  where?: Maybe<I18n_Bool_Exp>;
};


export type Query_RootI18n_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootIdentityArgs = {
  distinct_on?: Maybe<Array<Identity_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Identity_Order_By>>;
  where?: Maybe<Identity_Bool_Exp>;
};


export type Query_RootIdentity_AggregateArgs = {
  distinct_on?: Maybe<Array<Identity_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Identity_Order_By>>;
  where?: Maybe<Identity_Bool_Exp>;
};


export type Query_RootIdentity_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootImportArgs = {
  distinct_on?: Maybe<Array<Import_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Import_Order_By>>;
  where?: Maybe<Import_Bool_Exp>;
};


export type Query_RootImportGroupArgs = {
  distinct_on?: Maybe<Array<ImportGroup_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ImportGroup_Order_By>>;
  where?: Maybe<ImportGroup_Bool_Exp>;
};


export type Query_RootImportGroup_AggregateArgs = {
  distinct_on?: Maybe<Array<ImportGroup_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ImportGroup_Order_By>>;
  where?: Maybe<ImportGroup_Bool_Exp>;
};


export type Query_RootImportGroup_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootImportSkillArgs = {
  distinct_on?: Maybe<Array<ImportSkill_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ImportSkill_Order_By>>;
  where?: Maybe<ImportSkill_Bool_Exp>;
};


export type Query_RootImportSkill_AggregateArgs = {
  distinct_on?: Maybe<Array<ImportSkill_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ImportSkill_Order_By>>;
  where?: Maybe<ImportSkill_Bool_Exp>;
};


export type Query_RootImportSkill_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootImport_AggregateArgs = {
  distinct_on?: Maybe<Array<Import_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Import_Order_By>>;
  where?: Maybe<Import_Bool_Exp>;
};


export type Query_RootImport_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootJobTitleArgs = {
  distinct_on?: Maybe<Array<JobTitle_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<JobTitle_Order_By>>;
  where?: Maybe<JobTitle_Bool_Exp>;
};


export type Query_RootJobTitle_AggregateArgs = {
  distinct_on?: Maybe<Array<JobTitle_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<JobTitle_Order_By>>;
  where?: Maybe<JobTitle_Bool_Exp>;
};


export type Query_RootJobTitle_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootModuleArgs = {
  distinct_on?: Maybe<Array<Module_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Module_Order_By>>;
  where?: Maybe<Module_Bool_Exp>;
};


export type Query_RootModuleAnnexArgs = {
  distinct_on?: Maybe<Array<ModuleAnnex_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ModuleAnnex_Order_By>>;
  where?: Maybe<ModuleAnnex_Bool_Exp>;
};


export type Query_RootModuleAnnex_AggregateArgs = {
  distinct_on?: Maybe<Array<ModuleAnnex_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ModuleAnnex_Order_By>>;
  where?: Maybe<ModuleAnnex_Bool_Exp>;
};


export type Query_RootModuleAnnex_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootModuleContentArgs = {
  distinct_on?: Maybe<Array<ModuleContent_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ModuleContent_Order_By>>;
  where?: Maybe<ModuleContent_Bool_Exp>;
};


export type Query_RootModuleContent_AggregateArgs = {
  distinct_on?: Maybe<Array<ModuleContent_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ModuleContent_Order_By>>;
  where?: Maybe<ModuleContent_Bool_Exp>;
};


export type Query_RootModuleContent_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootModuleTaskArgs = {
  distinct_on?: Maybe<Array<ModuleTask_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ModuleTask_Order_By>>;
  where?: Maybe<ModuleTask_Bool_Exp>;
};


export type Query_RootModuleTask_AggregateArgs = {
  distinct_on?: Maybe<Array<ModuleTask_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ModuleTask_Order_By>>;
  where?: Maybe<ModuleTask_Bool_Exp>;
};


export type Query_RootModuleTask_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootModule_AggregateArgs = {
  distinct_on?: Maybe<Array<Module_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Module_Order_By>>;
  where?: Maybe<Module_Bool_Exp>;
};


export type Query_RootModule_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootOrganisationArgs = {
  distinct_on?: Maybe<Array<Organisation_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Organisation_Order_By>>;
  where?: Maybe<Organisation_Bool_Exp>;
};


export type Query_RootOrganisation_AggregateArgs = {
  distinct_on?: Maybe<Array<Organisation_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Organisation_Order_By>>;
  where?: Maybe<Organisation_Bool_Exp>;
};


export type Query_RootOrganisation_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootPasswordResetTokenArgs = {
  distinct_on?: Maybe<Array<PasswordResetToken_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<PasswordResetToken_Order_By>>;
  where?: Maybe<PasswordResetToken_Bool_Exp>;
};


export type Query_RootPasswordResetToken_AggregateArgs = {
  distinct_on?: Maybe<Array<PasswordResetToken_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<PasswordResetToken_Order_By>>;
  where?: Maybe<PasswordResetToken_Bool_Exp>;
};


export type Query_RootPasswordResetToken_By_PkArgs = {
  passwordResetToken: Scalars['String'];
};


export type Query_RootRefreshTokenArgs = {
  distinct_on?: Maybe<Array<RefreshToken_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<RefreshToken_Order_By>>;
  where?: Maybe<RefreshToken_Bool_Exp>;
};


export type Query_RootRefreshToken_AggregateArgs = {
  distinct_on?: Maybe<Array<RefreshToken_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<RefreshToken_Order_By>>;
  where?: Maybe<RefreshToken_Bool_Exp>;
};


export type Query_RootRefreshToken_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootRoleArgs = {
  distinct_on?: Maybe<Array<Role_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Role_Order_By>>;
  where?: Maybe<Role_Bool_Exp>;
};


export type Query_RootRole_AggregateArgs = {
  distinct_on?: Maybe<Array<Role_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Role_Order_By>>;
  where?: Maybe<Role_Bool_Exp>;
};


export type Query_RootRole_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootSequenceArgs = {
  distinct_on?: Maybe<Array<Sequence_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Sequence_Order_By>>;
  where?: Maybe<Sequence_Bool_Exp>;
};


export type Query_RootSequence_AggregateArgs = {
  distinct_on?: Maybe<Array<Sequence_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Sequence_Order_By>>;
  where?: Maybe<Sequence_Bool_Exp>;
};


export type Query_RootSequence_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootSequence_ModuleArgs = {
  distinct_on?: Maybe<Array<Sequence_Module_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Sequence_Module_Order_By>>;
  where?: Maybe<Sequence_Module_Bool_Exp>;
};


export type Query_RootSequence_Module_AggregateArgs = {
  distinct_on?: Maybe<Array<Sequence_Module_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Sequence_Module_Order_By>>;
  where?: Maybe<Sequence_Module_Bool_Exp>;
};


export type Query_RootSequence_Module_By_PkArgs = {
  moduleId: Scalars['uuid'];
  sequenceId: Scalars['uuid'];
};


export type Query_RootSiteArgs = {
  distinct_on?: Maybe<Array<Site_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Site_Order_By>>;
  where?: Maybe<Site_Bool_Exp>;
};


export type Query_RootSite_AggregateArgs = {
  distinct_on?: Maybe<Array<Site_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Site_Order_By>>;
  where?: Maybe<Site_Bool_Exp>;
};


export type Query_RootSite_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootSkillArgs = {
  distinct_on?: Maybe<Array<Skill_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Skill_Order_By>>;
  where?: Maybe<Skill_Bool_Exp>;
};


export type Query_RootSkillLevelArgs = {
  distinct_on?: Maybe<Array<SkillLevel_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<SkillLevel_Order_By>>;
  where?: Maybe<SkillLevel_Bool_Exp>;
};


export type Query_RootSkillLevel_AggregateArgs = {
  distinct_on?: Maybe<Array<SkillLevel_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<SkillLevel_Order_By>>;
  where?: Maybe<SkillLevel_Bool_Exp>;
};


export type Query_RootSkillLevel_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootSkill_AggregateArgs = {
  distinct_on?: Maybe<Array<Skill_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Skill_Order_By>>;
  where?: Maybe<Skill_Bool_Exp>;
};


export type Query_RootSkill_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootSkill_Of_CourseArgs = {
  distinct_on?: Maybe<Array<Skill_Of_Course_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Skill_Of_Course_Order_By>>;
  where?: Maybe<Skill_Of_Course_Bool_Exp>;
};


export type Query_RootSkill_Of_Course_AggregateArgs = {
  distinct_on?: Maybe<Array<Skill_Of_Course_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Skill_Of_Course_Order_By>>;
  where?: Maybe<Skill_Of_Course_Bool_Exp>;
};


export type Query_RootSkill_Of_Course_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootTag_CourseArgs = {
  distinct_on?: Maybe<Array<Tag_Course_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Tag_Course_Order_By>>;
  where?: Maybe<Tag_Course_Bool_Exp>;
};


export type Query_RootTag_Course_AggregateArgs = {
  distinct_on?: Maybe<Array<Tag_Course_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Tag_Course_Order_By>>;
  where?: Maybe<Tag_Course_Bool_Exp>;
};


export type Query_RootTag_Course_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootTag_Of_CourseArgs = {
  distinct_on?: Maybe<Array<Tag_Of_Course_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Tag_Of_Course_Order_By>>;
  where?: Maybe<Tag_Of_Course_Bool_Exp>;
};


export type Query_RootTag_Of_Course_AggregateArgs = {
  distinct_on?: Maybe<Array<Tag_Of_Course_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Tag_Of_Course_Order_By>>;
  where?: Maybe<Tag_Of_Course_Bool_Exp>;
};


export type Query_RootTag_Of_Course_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootThematicArgs = {
  distinct_on?: Maybe<Array<Thematic_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Thematic_Order_By>>;
  where?: Maybe<Thematic_Bool_Exp>;
};


export type Query_RootThematic_AggregateArgs = {
  distinct_on?: Maybe<Array<Thematic_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Thematic_Order_By>>;
  where?: Maybe<Thematic_Bool_Exp>;
};


export type Query_RootThematic_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootTranslationArgs = {
  distinct_on?: Maybe<Array<Translation_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Translation_Order_By>>;
  where?: Maybe<Translation_Bool_Exp>;
};


export type Query_RootTranslation_AggregateArgs = {
  distinct_on?: Maybe<Array<Translation_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Translation_Order_By>>;
  where?: Maybe<Translation_Bool_Exp>;
};


export type Query_RootTranslation_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootUserArgs = {
  distinct_on?: Maybe<Array<User_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<User_Order_By>>;
  where?: Maybe<User_Bool_Exp>;
};


export type Query_RootUserCourseProgressArgs = {
  distinct_on?: Maybe<Array<UserCourseProgress_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<UserCourseProgress_Order_By>>;
  where?: Maybe<UserCourseProgress_Bool_Exp>;
};


export type Query_RootUserCourseProgress_AggregateArgs = {
  distinct_on?: Maybe<Array<UserCourseProgress_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<UserCourseProgress_Order_By>>;
  where?: Maybe<UserCourseProgress_Bool_Exp>;
};


export type Query_RootUserCourseProgress_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootUserEventSlotArgs = {
  distinct_on?: Maybe<Array<UserEventSlot_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<UserEventSlot_Order_By>>;
  where?: Maybe<UserEventSlot_Bool_Exp>;
};


export type Query_RootUserEventSlot_AggregateArgs = {
  distinct_on?: Maybe<Array<UserEventSlot_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<UserEventSlot_Order_By>>;
  where?: Maybe<UserEventSlot_Bool_Exp>;
};


export type Query_RootUserEventSlot_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootUserGroupArgs = {
  distinct_on?: Maybe<Array<UserGroup_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<UserGroup_Order_By>>;
  where?: Maybe<UserGroup_Bool_Exp>;
};


export type Query_RootUserGroup_AggregateArgs = {
  distinct_on?: Maybe<Array<UserGroup_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<UserGroup_Order_By>>;
  where?: Maybe<UserGroup_Bool_Exp>;
};


export type Query_RootUserGroup_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootUserModuleProgressArgs = {
  distinct_on?: Maybe<Array<UserModuleProgress_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<UserModuleProgress_Order_By>>;
  where?: Maybe<UserModuleProgress_Bool_Exp>;
};


export type Query_RootUserModuleProgress_AggregateArgs = {
  distinct_on?: Maybe<Array<UserModuleProgress_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<UserModuleProgress_Order_By>>;
  where?: Maybe<UserModuleProgress_Bool_Exp>;
};


export type Query_RootUserModuleProgress_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootUserPrivateArgs = {
  distinct_on?: Maybe<Array<UserPrivate_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<UserPrivate_Order_By>>;
  where?: Maybe<UserPrivate_Bool_Exp>;
};


export type Query_RootUserPrivate_AggregateArgs = {
  distinct_on?: Maybe<Array<UserPrivate_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<UserPrivate_Order_By>>;
  where?: Maybe<UserPrivate_Bool_Exp>;
};


export type Query_RootUserSkillArgs = {
  distinct_on?: Maybe<Array<UserSkill_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<UserSkill_Order_By>>;
  where?: Maybe<UserSkill_Bool_Exp>;
};


export type Query_RootUserSkill_AggregateArgs = {
  distinct_on?: Maybe<Array<UserSkill_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<UserSkill_Order_By>>;
  where?: Maybe<UserSkill_Bool_Exp>;
};


export type Query_RootUserSkill_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootUser_AggregateArgs = {
  distinct_on?: Maybe<Array<User_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<User_Order_By>>;
  where?: Maybe<User_Bool_Exp>;
};


export type Query_RootUser_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootVolunteerPostArgs = {
  distinct_on?: Maybe<Array<VolunteerPost_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<VolunteerPost_Order_By>>;
  where?: Maybe<VolunteerPost_Bool_Exp>;
};


export type Query_RootVolunteerPost_AggregateArgs = {
  distinct_on?: Maybe<Array<VolunteerPost_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<VolunteerPost_Order_By>>;
  where?: Maybe<VolunteerPost_Bool_Exp>;
};


export type Query_RootVolunteerPost_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootXapi_StatementArgs = {
  distinct_on?: Maybe<Array<Xapi_Statement_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Xapi_Statement_Order_By>>;
  where?: Maybe<Xapi_Statement_Bool_Exp>;
};


export type Query_RootXapi_Statement_AggregateArgs = {
  distinct_on?: Maybe<Array<Xapi_Statement_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Xapi_Statement_Order_By>>;
  where?: Maybe<Xapi_Statement_Bool_Exp>;
};


export type Query_RootXapi_Statement_By_PkArgs = {
  id: Scalars['uuid'];
};

/** columns and relationships of "refreshToken" */
export type RefreshToken = {
  __typename?: 'refreshToken';
  createdAt: Scalars['timestamptz'];
  expiringAt: Scalars['timestamptz'];
  id: Scalars['uuid'];
  /** An object relationship */
  identity: Identity;
  identityId: Scalars['uuid'];
  refreshToken: Scalars['String'];
  revokedAt?: Maybe<Scalars['timestamptz']>;
};

/** aggregated selection of "refreshToken" */
export type RefreshToken_Aggregate = {
  __typename?: 'refreshToken_aggregate';
  aggregate?: Maybe<RefreshToken_Aggregate_Fields>;
  nodes: Array<RefreshToken>;
};

export type RefreshToken_Aggregate_Bool_Exp = {
  count?: Maybe<RefreshToken_Aggregate_Bool_Exp_Count>;
};

export type RefreshToken_Aggregate_Bool_Exp_Count = {
  arguments?: Maybe<Array<RefreshToken_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<RefreshToken_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "refreshToken" */
export type RefreshToken_Aggregate_Fields = {
  __typename?: 'refreshToken_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<RefreshToken_Max_Fields>;
  min?: Maybe<RefreshToken_Min_Fields>;
};


/** aggregate fields of "refreshToken" */
export type RefreshToken_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<RefreshToken_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "refreshToken" */
export type RefreshToken_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<RefreshToken_Max_Order_By>;
  min?: Maybe<RefreshToken_Min_Order_By>;
};

/** input type for inserting array relation for remote table "refreshToken" */
export type RefreshToken_Arr_Rel_Insert_Input = {
  data: Array<RefreshToken_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<RefreshToken_On_Conflict>;
};

/** Boolean expression to filter rows from the table "refreshToken". All fields are combined with a logical 'AND'. */
export type RefreshToken_Bool_Exp = {
  _and?: Maybe<Array<RefreshToken_Bool_Exp>>;
  _not?: Maybe<RefreshToken_Bool_Exp>;
  _or?: Maybe<Array<RefreshToken_Bool_Exp>>;
  createdAt?: Maybe<Timestamptz_Comparison_Exp>;
  expiringAt?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  identity?: Maybe<Identity_Bool_Exp>;
  identityId?: Maybe<Uuid_Comparison_Exp>;
  refreshToken?: Maybe<String_Comparison_Exp>;
  revokedAt?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "refreshToken" */
export enum RefreshToken_Constraint {
  /** unique or primary key constraint on columns "id" */
  RefreshTokenPkey = 'refreshToken_pkey'
}

/** input type for inserting data into table "refreshToken" */
export type RefreshToken_Insert_Input = {
  createdAt?: Maybe<Scalars['timestamptz']>;
  expiringAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  identity?: Maybe<Identity_Obj_Rel_Insert_Input>;
  identityId?: Maybe<Scalars['uuid']>;
  refreshToken?: Maybe<Scalars['String']>;
  revokedAt?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type RefreshToken_Max_Fields = {
  __typename?: 'refreshToken_max_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  expiringAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  identityId?: Maybe<Scalars['uuid']>;
  refreshToken?: Maybe<Scalars['String']>;
  revokedAt?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "refreshToken" */
export type RefreshToken_Max_Order_By = {
  createdAt?: Maybe<Order_By>;
  expiringAt?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  identityId?: Maybe<Order_By>;
  refreshToken?: Maybe<Order_By>;
  revokedAt?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type RefreshToken_Min_Fields = {
  __typename?: 'refreshToken_min_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  expiringAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  identityId?: Maybe<Scalars['uuid']>;
  refreshToken?: Maybe<Scalars['String']>;
  revokedAt?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "refreshToken" */
export type RefreshToken_Min_Order_By = {
  createdAt?: Maybe<Order_By>;
  expiringAt?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  identityId?: Maybe<Order_By>;
  refreshToken?: Maybe<Order_By>;
  revokedAt?: Maybe<Order_By>;
};

/** response of any mutation on the table "refreshToken" */
export type RefreshToken_Mutation_Response = {
  __typename?: 'refreshToken_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<RefreshToken>;
};

/** on_conflict condition type for table "refreshToken" */
export type RefreshToken_On_Conflict = {
  constraint: RefreshToken_Constraint;
  update_columns?: Array<RefreshToken_Update_Column>;
  where?: Maybe<RefreshToken_Bool_Exp>;
};

/** Ordering options when selecting data from "refreshToken". */
export type RefreshToken_Order_By = {
  createdAt?: Maybe<Order_By>;
  expiringAt?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  identity?: Maybe<Identity_Order_By>;
  identityId?: Maybe<Order_By>;
  refreshToken?: Maybe<Order_By>;
  revokedAt?: Maybe<Order_By>;
};

/** primary key columns input for table: refreshToken */
export type RefreshToken_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "refreshToken" */
export enum RefreshToken_Select_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  ExpiringAt = 'expiringAt',
  /** column name */
  Id = 'id',
  /** column name */
  IdentityId = 'identityId',
  /** column name */
  RefreshToken = 'refreshToken',
  /** column name */
  RevokedAt = 'revokedAt'
}

/** input type for updating data in table "refreshToken" */
export type RefreshToken_Set_Input = {
  createdAt?: Maybe<Scalars['timestamptz']>;
  expiringAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  identityId?: Maybe<Scalars['uuid']>;
  refreshToken?: Maybe<Scalars['String']>;
  revokedAt?: Maybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "refreshToken" */
export type RefreshToken_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: RefreshToken_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type RefreshToken_Stream_Cursor_Value_Input = {
  createdAt?: Maybe<Scalars['timestamptz']>;
  expiringAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  identityId?: Maybe<Scalars['uuid']>;
  refreshToken?: Maybe<Scalars['String']>;
  revokedAt?: Maybe<Scalars['timestamptz']>;
};

/** update columns of table "refreshToken" */
export enum RefreshToken_Update_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  ExpiringAt = 'expiringAt',
  /** column name */
  Id = 'id',
  /** column name */
  IdentityId = 'identityId',
  /** column name */
  RefreshToken = 'refreshToken',
  /** column name */
  RevokedAt = 'revokedAt'
}

export type RefreshToken_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<RefreshToken_Set_Input>;
  /** filter the rows which have to be updated */
  where: RefreshToken_Bool_Exp;
};

/** columns and relationships of "role" */
export type Role = {
  __typename?: 'role';
  createdAt: Scalars['timestamptz'];
  id: Scalars['uuid'];
  manageAdmin: Scalars['Boolean'];
  manageAttribute: Scalars['Boolean'];
  manageCms: Scalars['Boolean'];
  manageCourse: Scalars['Boolean'];
  manageCoursePublication: Scalars['Boolean'];
  manageDataAccess: Scalars['Boolean'];
  manageEvent: Scalars['Boolean'];
  manageImportUser: Scalars['Boolean'];
  manageLearner: Scalars['Boolean'];
  manageTag: Scalars['Boolean'];
  manageThematic: Scalars['Boolean'];
  manageVolunteerAttribute: Scalars['Boolean'];
  name: Scalars['String'];
  updatedAt: Scalars['timestamptz'];
  userCount?: Maybe<Scalars['Int']>;
};

/** aggregated selection of "role" */
export type Role_Aggregate = {
  __typename?: 'role_aggregate';
  aggregate?: Maybe<Role_Aggregate_Fields>;
  nodes: Array<Role>;
};

/** aggregate fields of "role" */
export type Role_Aggregate_Fields = {
  __typename?: 'role_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Role_Max_Fields>;
  min?: Maybe<Role_Min_Fields>;
};


/** aggregate fields of "role" */
export type Role_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Role_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "role". All fields are combined with a logical 'AND'. */
export type Role_Bool_Exp = {
  _and?: Maybe<Array<Role_Bool_Exp>>;
  _not?: Maybe<Role_Bool_Exp>;
  _or?: Maybe<Array<Role_Bool_Exp>>;
  createdAt?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  manageAdmin?: Maybe<Boolean_Comparison_Exp>;
  manageAttribute?: Maybe<Boolean_Comparison_Exp>;
  manageCms?: Maybe<Boolean_Comparison_Exp>;
  manageCourse?: Maybe<Boolean_Comparison_Exp>;
  manageCoursePublication?: Maybe<Boolean_Comparison_Exp>;
  manageDataAccess?: Maybe<Boolean_Comparison_Exp>;
  manageEvent?: Maybe<Boolean_Comparison_Exp>;
  manageImportUser?: Maybe<Boolean_Comparison_Exp>;
  manageLearner?: Maybe<Boolean_Comparison_Exp>;
  manageTag?: Maybe<Boolean_Comparison_Exp>;
  manageThematic?: Maybe<Boolean_Comparison_Exp>;
  manageVolunteerAttribute?: Maybe<Boolean_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
  updatedAt?: Maybe<Timestamptz_Comparison_Exp>;
  userCount?: Maybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "role" */
export enum Role_Constraint {
  /** unique or primary key constraint on columns "id" */
  PkRole = 'PK_role'
}

/** input type for inserting data into table "role" */
export type Role_Insert_Input = {
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  manageAdmin?: Maybe<Scalars['Boolean']>;
  manageAttribute?: Maybe<Scalars['Boolean']>;
  manageCms?: Maybe<Scalars['Boolean']>;
  manageCourse?: Maybe<Scalars['Boolean']>;
  manageCoursePublication?: Maybe<Scalars['Boolean']>;
  manageDataAccess?: Maybe<Scalars['Boolean']>;
  manageEvent?: Maybe<Scalars['Boolean']>;
  manageImportUser?: Maybe<Scalars['Boolean']>;
  manageLearner?: Maybe<Scalars['Boolean']>;
  manageTag?: Maybe<Scalars['Boolean']>;
  manageThematic?: Maybe<Scalars['Boolean']>;
  manageVolunteerAttribute?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Role_Max_Fields = {
  __typename?: 'role_max_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Role_Min_Fields = {
  __typename?: 'role_min_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "role" */
export type Role_Mutation_Response = {
  __typename?: 'role_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Role>;
};

/** input type for inserting object relation for remote table "role" */
export type Role_Obj_Rel_Insert_Input = {
  data: Role_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Role_On_Conflict>;
};

/** on_conflict condition type for table "role" */
export type Role_On_Conflict = {
  constraint: Role_Constraint;
  update_columns?: Array<Role_Update_Column>;
  where?: Maybe<Role_Bool_Exp>;
};

/** Ordering options when selecting data from "role". */
export type Role_Order_By = {
  createdAt?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  manageAdmin?: Maybe<Order_By>;
  manageAttribute?: Maybe<Order_By>;
  manageCms?: Maybe<Order_By>;
  manageCourse?: Maybe<Order_By>;
  manageCoursePublication?: Maybe<Order_By>;
  manageDataAccess?: Maybe<Order_By>;
  manageEvent?: Maybe<Order_By>;
  manageImportUser?: Maybe<Order_By>;
  manageLearner?: Maybe<Order_By>;
  manageTag?: Maybe<Order_By>;
  manageThematic?: Maybe<Order_By>;
  manageVolunteerAttribute?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  updatedAt?: Maybe<Order_By>;
  userCount?: Maybe<Order_By>;
};

/** primary key columns input for table: role */
export type Role_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "role" */
export enum Role_Select_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  ManageAdmin = 'manageAdmin',
  /** column name */
  ManageAttribute = 'manageAttribute',
  /** column name */
  ManageCms = 'manageCms',
  /** column name */
  ManageCourse = 'manageCourse',
  /** column name */
  ManageCoursePublication = 'manageCoursePublication',
  /** column name */
  ManageDataAccess = 'manageDataAccess',
  /** column name */
  ManageEvent = 'manageEvent',
  /** column name */
  ManageImportUser = 'manageImportUser',
  /** column name */
  ManageLearner = 'manageLearner',
  /** column name */
  ManageTag = 'manageTag',
  /** column name */
  ManageThematic = 'manageThematic',
  /** column name */
  ManageVolunteerAttribute = 'manageVolunteerAttribute',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updatedAt'
}

/** input type for updating data in table "role" */
export type Role_Set_Input = {
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  manageAdmin?: Maybe<Scalars['Boolean']>;
  manageAttribute?: Maybe<Scalars['Boolean']>;
  manageCms?: Maybe<Scalars['Boolean']>;
  manageCourse?: Maybe<Scalars['Boolean']>;
  manageCoursePublication?: Maybe<Scalars['Boolean']>;
  manageDataAccess?: Maybe<Scalars['Boolean']>;
  manageEvent?: Maybe<Scalars['Boolean']>;
  manageImportUser?: Maybe<Scalars['Boolean']>;
  manageLearner?: Maybe<Scalars['Boolean']>;
  manageTag?: Maybe<Scalars['Boolean']>;
  manageThematic?: Maybe<Scalars['Boolean']>;
  manageVolunteerAttribute?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "role" */
export type Role_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Role_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Role_Stream_Cursor_Value_Input = {
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  manageAdmin?: Maybe<Scalars['Boolean']>;
  manageAttribute?: Maybe<Scalars['Boolean']>;
  manageCms?: Maybe<Scalars['Boolean']>;
  manageCourse?: Maybe<Scalars['Boolean']>;
  manageCoursePublication?: Maybe<Scalars['Boolean']>;
  manageDataAccess?: Maybe<Scalars['Boolean']>;
  manageEvent?: Maybe<Scalars['Boolean']>;
  manageImportUser?: Maybe<Scalars['Boolean']>;
  manageLearner?: Maybe<Scalars['Boolean']>;
  manageTag?: Maybe<Scalars['Boolean']>;
  manageThematic?: Maybe<Scalars['Boolean']>;
  manageVolunteerAttribute?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** update columns of table "role" */
export enum Role_Update_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  ManageAdmin = 'manageAdmin',
  /** column name */
  ManageAttribute = 'manageAttribute',
  /** column name */
  ManageCms = 'manageCms',
  /** column name */
  ManageCourse = 'manageCourse',
  /** column name */
  ManageCoursePublication = 'manageCoursePublication',
  /** column name */
  ManageDataAccess = 'manageDataAccess',
  /** column name */
  ManageEvent = 'manageEvent',
  /** column name */
  ManageImportUser = 'manageImportUser',
  /** column name */
  ManageLearner = 'manageLearner',
  /** column name */
  ManageTag = 'manageTag',
  /** column name */
  ManageThematic = 'manageThematic',
  /** column name */
  ManageVolunteerAttribute = 'manageVolunteerAttribute',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updatedAt'
}

export type Role_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Role_Set_Input>;
  /** filter the rows which have to be updated */
  where: Role_Bool_Exp;
};

/** columns and relationships of "sequence" */
export type Sequence = {
  __typename?: 'sequence';
  /** An object relationship */
  course: Course;
  courseId: Scalars['uuid'];
  createdAt: Scalars['timestamptz'];
  id: Scalars['uuid'];
  orderInCourse: Scalars['Int'];
  /** An array relationship */
  sequence_modules: Array<Sequence_Module>;
  /** An aggregate relationship */
  sequence_modules_aggregate: Sequence_Module_Aggregate;
  /** An object relationship */
  title?: Maybe<I18n>;
  /** A computed field, executes function "sequence_title_i18n" */
  titleI18n?: Maybe<Scalars['String']>;
  titleI18nId?: Maybe<Scalars['uuid']>;
  updatedAt: Scalars['timestamptz'];
};


/** columns and relationships of "sequence" */
export type SequenceSequence_ModulesArgs = {
  distinct_on?: Maybe<Array<Sequence_Module_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Sequence_Module_Order_By>>;
  where?: Maybe<Sequence_Module_Bool_Exp>;
};


/** columns and relationships of "sequence" */
export type SequenceSequence_Modules_AggregateArgs = {
  distinct_on?: Maybe<Array<Sequence_Module_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Sequence_Module_Order_By>>;
  where?: Maybe<Sequence_Module_Bool_Exp>;
};

/** aggregated selection of "sequence" */
export type Sequence_Aggregate = {
  __typename?: 'sequence_aggregate';
  aggregate?: Maybe<Sequence_Aggregate_Fields>;
  nodes: Array<Sequence>;
};

export type Sequence_Aggregate_Bool_Exp = {
  count?: Maybe<Sequence_Aggregate_Bool_Exp_Count>;
};

export type Sequence_Aggregate_Bool_Exp_Count = {
  arguments?: Maybe<Array<Sequence_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Sequence_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "sequence" */
export type Sequence_Aggregate_Fields = {
  __typename?: 'sequence_aggregate_fields';
  avg?: Maybe<Sequence_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Sequence_Max_Fields>;
  min?: Maybe<Sequence_Min_Fields>;
  stddev?: Maybe<Sequence_Stddev_Fields>;
  stddev_pop?: Maybe<Sequence_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Sequence_Stddev_Samp_Fields>;
  sum?: Maybe<Sequence_Sum_Fields>;
  var_pop?: Maybe<Sequence_Var_Pop_Fields>;
  var_samp?: Maybe<Sequence_Var_Samp_Fields>;
  variance?: Maybe<Sequence_Variance_Fields>;
};


/** aggregate fields of "sequence" */
export type Sequence_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Sequence_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "sequence" */
export type Sequence_Aggregate_Order_By = {
  avg?: Maybe<Sequence_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Sequence_Max_Order_By>;
  min?: Maybe<Sequence_Min_Order_By>;
  stddev?: Maybe<Sequence_Stddev_Order_By>;
  stddev_pop?: Maybe<Sequence_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Sequence_Stddev_Samp_Order_By>;
  sum?: Maybe<Sequence_Sum_Order_By>;
  var_pop?: Maybe<Sequence_Var_Pop_Order_By>;
  var_samp?: Maybe<Sequence_Var_Samp_Order_By>;
  variance?: Maybe<Sequence_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "sequence" */
export type Sequence_Arr_Rel_Insert_Input = {
  data: Array<Sequence_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Sequence_On_Conflict>;
};

/** aggregate avg on columns */
export type Sequence_Avg_Fields = {
  __typename?: 'sequence_avg_fields';
  orderInCourse?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "sequence" */
export type Sequence_Avg_Order_By = {
  orderInCourse?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "sequence". All fields are combined with a logical 'AND'. */
export type Sequence_Bool_Exp = {
  _and?: Maybe<Array<Sequence_Bool_Exp>>;
  _not?: Maybe<Sequence_Bool_Exp>;
  _or?: Maybe<Array<Sequence_Bool_Exp>>;
  course?: Maybe<Course_Bool_Exp>;
  courseId?: Maybe<Uuid_Comparison_Exp>;
  createdAt?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  orderInCourse?: Maybe<Int_Comparison_Exp>;
  sequence_modules?: Maybe<Sequence_Module_Bool_Exp>;
  sequence_modules_aggregate?: Maybe<Sequence_Module_Aggregate_Bool_Exp>;
  title?: Maybe<I18n_Bool_Exp>;
  titleI18n?: Maybe<String_Comparison_Exp>;
  titleI18nId?: Maybe<Uuid_Comparison_Exp>;
  updatedAt?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "sequence" */
export enum Sequence_Constraint {
  /** unique or primary key constraint on columns "id" */
  PkSequence = 'PK_sequence'
}

/** input type for incrementing numeric columns in table "sequence" */
export type Sequence_Inc_Input = {
  orderInCourse?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "sequence" */
export type Sequence_Insert_Input = {
  course?: Maybe<Course_Obj_Rel_Insert_Input>;
  courseId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  orderInCourse?: Maybe<Scalars['Int']>;
  sequence_modules?: Maybe<Sequence_Module_Arr_Rel_Insert_Input>;
  title?: Maybe<I18n_Obj_Rel_Insert_Input>;
  titleI18nId?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Sequence_Max_Fields = {
  __typename?: 'sequence_max_fields';
  courseId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  orderInCourse?: Maybe<Scalars['Int']>;
  titleI18nId?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "sequence" */
export type Sequence_Max_Order_By = {
  courseId?: Maybe<Order_By>;
  createdAt?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  orderInCourse?: Maybe<Order_By>;
  titleI18nId?: Maybe<Order_By>;
  updatedAt?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Sequence_Min_Fields = {
  __typename?: 'sequence_min_fields';
  courseId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  orderInCourse?: Maybe<Scalars['Int']>;
  titleI18nId?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "sequence" */
export type Sequence_Min_Order_By = {
  courseId?: Maybe<Order_By>;
  createdAt?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  orderInCourse?: Maybe<Order_By>;
  titleI18nId?: Maybe<Order_By>;
  updatedAt?: Maybe<Order_By>;
};

/** columns and relationships of "sequence_module" */
export type Sequence_Module = {
  __typename?: 'sequence_module';
  createdAt: Scalars['timestamptz'];
  /** An object relationship */
  module: Module;
  moduleId: Scalars['uuid'];
  orderInSequence: Scalars['Int'];
  /** An object relationship */
  sequence: Sequence;
  sequenceId: Scalars['uuid'];
  updatedAt: Scalars['timestamptz'];
};

/** aggregated selection of "sequence_module" */
export type Sequence_Module_Aggregate = {
  __typename?: 'sequence_module_aggregate';
  aggregate?: Maybe<Sequence_Module_Aggregate_Fields>;
  nodes: Array<Sequence_Module>;
};

export type Sequence_Module_Aggregate_Bool_Exp = {
  count?: Maybe<Sequence_Module_Aggregate_Bool_Exp_Count>;
};

export type Sequence_Module_Aggregate_Bool_Exp_Count = {
  arguments?: Maybe<Array<Sequence_Module_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Sequence_Module_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "sequence_module" */
export type Sequence_Module_Aggregate_Fields = {
  __typename?: 'sequence_module_aggregate_fields';
  avg?: Maybe<Sequence_Module_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Sequence_Module_Max_Fields>;
  min?: Maybe<Sequence_Module_Min_Fields>;
  stddev?: Maybe<Sequence_Module_Stddev_Fields>;
  stddev_pop?: Maybe<Sequence_Module_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Sequence_Module_Stddev_Samp_Fields>;
  sum?: Maybe<Sequence_Module_Sum_Fields>;
  var_pop?: Maybe<Sequence_Module_Var_Pop_Fields>;
  var_samp?: Maybe<Sequence_Module_Var_Samp_Fields>;
  variance?: Maybe<Sequence_Module_Variance_Fields>;
};


/** aggregate fields of "sequence_module" */
export type Sequence_Module_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Sequence_Module_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "sequence_module" */
export type Sequence_Module_Aggregate_Order_By = {
  avg?: Maybe<Sequence_Module_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Sequence_Module_Max_Order_By>;
  min?: Maybe<Sequence_Module_Min_Order_By>;
  stddev?: Maybe<Sequence_Module_Stddev_Order_By>;
  stddev_pop?: Maybe<Sequence_Module_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Sequence_Module_Stddev_Samp_Order_By>;
  sum?: Maybe<Sequence_Module_Sum_Order_By>;
  var_pop?: Maybe<Sequence_Module_Var_Pop_Order_By>;
  var_samp?: Maybe<Sequence_Module_Var_Samp_Order_By>;
  variance?: Maybe<Sequence_Module_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "sequence_module" */
export type Sequence_Module_Arr_Rel_Insert_Input = {
  data: Array<Sequence_Module_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Sequence_Module_On_Conflict>;
};

/** aggregate avg on columns */
export type Sequence_Module_Avg_Fields = {
  __typename?: 'sequence_module_avg_fields';
  orderInSequence?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "sequence_module" */
export type Sequence_Module_Avg_Order_By = {
  orderInSequence?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "sequence_module". All fields are combined with a logical 'AND'. */
export type Sequence_Module_Bool_Exp = {
  _and?: Maybe<Array<Sequence_Module_Bool_Exp>>;
  _not?: Maybe<Sequence_Module_Bool_Exp>;
  _or?: Maybe<Array<Sequence_Module_Bool_Exp>>;
  createdAt?: Maybe<Timestamptz_Comparison_Exp>;
  module?: Maybe<Module_Bool_Exp>;
  moduleId?: Maybe<Uuid_Comparison_Exp>;
  orderInSequence?: Maybe<Int_Comparison_Exp>;
  sequence?: Maybe<Sequence_Bool_Exp>;
  sequenceId?: Maybe<Uuid_Comparison_Exp>;
  updatedAt?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "sequence_module" */
export enum Sequence_Module_Constraint {
  /** unique or primary key constraint on columns "sequenceId", "moduleId" */
  PkSequenceModule = 'PK_sequence_module'
}

/** input type for incrementing numeric columns in table "sequence_module" */
export type Sequence_Module_Inc_Input = {
  orderInSequence?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "sequence_module" */
export type Sequence_Module_Insert_Input = {
  createdAt?: Maybe<Scalars['timestamptz']>;
  module?: Maybe<Module_Obj_Rel_Insert_Input>;
  moduleId?: Maybe<Scalars['uuid']>;
  orderInSequence?: Maybe<Scalars['Int']>;
  sequence?: Maybe<Sequence_Obj_Rel_Insert_Input>;
  sequenceId?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Sequence_Module_Max_Fields = {
  __typename?: 'sequence_module_max_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  moduleId?: Maybe<Scalars['uuid']>;
  orderInSequence?: Maybe<Scalars['Int']>;
  sequenceId?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "sequence_module" */
export type Sequence_Module_Max_Order_By = {
  createdAt?: Maybe<Order_By>;
  moduleId?: Maybe<Order_By>;
  orderInSequence?: Maybe<Order_By>;
  sequenceId?: Maybe<Order_By>;
  updatedAt?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Sequence_Module_Min_Fields = {
  __typename?: 'sequence_module_min_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  moduleId?: Maybe<Scalars['uuid']>;
  orderInSequence?: Maybe<Scalars['Int']>;
  sequenceId?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "sequence_module" */
export type Sequence_Module_Min_Order_By = {
  createdAt?: Maybe<Order_By>;
  moduleId?: Maybe<Order_By>;
  orderInSequence?: Maybe<Order_By>;
  sequenceId?: Maybe<Order_By>;
  updatedAt?: Maybe<Order_By>;
};

/** response of any mutation on the table "sequence_module" */
export type Sequence_Module_Mutation_Response = {
  __typename?: 'sequence_module_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Sequence_Module>;
};

/** on_conflict condition type for table "sequence_module" */
export type Sequence_Module_On_Conflict = {
  constraint: Sequence_Module_Constraint;
  update_columns?: Array<Sequence_Module_Update_Column>;
  where?: Maybe<Sequence_Module_Bool_Exp>;
};

/** Ordering options when selecting data from "sequence_module". */
export type Sequence_Module_Order_By = {
  createdAt?: Maybe<Order_By>;
  module?: Maybe<Module_Order_By>;
  moduleId?: Maybe<Order_By>;
  orderInSequence?: Maybe<Order_By>;
  sequence?: Maybe<Sequence_Order_By>;
  sequenceId?: Maybe<Order_By>;
  updatedAt?: Maybe<Order_By>;
};

/** primary key columns input for table: sequence_module */
export type Sequence_Module_Pk_Columns_Input = {
  moduleId: Scalars['uuid'];
  sequenceId: Scalars['uuid'];
};

/** select columns of table "sequence_module" */
export enum Sequence_Module_Select_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  ModuleId = 'moduleId',
  /** column name */
  OrderInSequence = 'orderInSequence',
  /** column name */
  SequenceId = 'sequenceId',
  /** column name */
  UpdatedAt = 'updatedAt'
}

/** input type for updating data in table "sequence_module" */
export type Sequence_Module_Set_Input = {
  createdAt?: Maybe<Scalars['timestamptz']>;
  moduleId?: Maybe<Scalars['uuid']>;
  orderInSequence?: Maybe<Scalars['Int']>;
  sequenceId?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Sequence_Module_Stddev_Fields = {
  __typename?: 'sequence_module_stddev_fields';
  orderInSequence?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "sequence_module" */
export type Sequence_Module_Stddev_Order_By = {
  orderInSequence?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Sequence_Module_Stddev_Pop_Fields = {
  __typename?: 'sequence_module_stddev_pop_fields';
  orderInSequence?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "sequence_module" */
export type Sequence_Module_Stddev_Pop_Order_By = {
  orderInSequence?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Sequence_Module_Stddev_Samp_Fields = {
  __typename?: 'sequence_module_stddev_samp_fields';
  orderInSequence?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "sequence_module" */
export type Sequence_Module_Stddev_Samp_Order_By = {
  orderInSequence?: Maybe<Order_By>;
};

/** Streaming cursor of the table "sequence_module" */
export type Sequence_Module_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Sequence_Module_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Sequence_Module_Stream_Cursor_Value_Input = {
  createdAt?: Maybe<Scalars['timestamptz']>;
  moduleId?: Maybe<Scalars['uuid']>;
  orderInSequence?: Maybe<Scalars['Int']>;
  sequenceId?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type Sequence_Module_Sum_Fields = {
  __typename?: 'sequence_module_sum_fields';
  orderInSequence?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "sequence_module" */
export type Sequence_Module_Sum_Order_By = {
  orderInSequence?: Maybe<Order_By>;
};

/** update columns of table "sequence_module" */
export enum Sequence_Module_Update_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  ModuleId = 'moduleId',
  /** column name */
  OrderInSequence = 'orderInSequence',
  /** column name */
  SequenceId = 'sequenceId',
  /** column name */
  UpdatedAt = 'updatedAt'
}

export type Sequence_Module_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<Sequence_Module_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Sequence_Module_Set_Input>;
  /** filter the rows which have to be updated */
  where: Sequence_Module_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Sequence_Module_Var_Pop_Fields = {
  __typename?: 'sequence_module_var_pop_fields';
  orderInSequence?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "sequence_module" */
export type Sequence_Module_Var_Pop_Order_By = {
  orderInSequence?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Sequence_Module_Var_Samp_Fields = {
  __typename?: 'sequence_module_var_samp_fields';
  orderInSequence?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "sequence_module" */
export type Sequence_Module_Var_Samp_Order_By = {
  orderInSequence?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Sequence_Module_Variance_Fields = {
  __typename?: 'sequence_module_variance_fields';
  orderInSequence?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "sequence_module" */
export type Sequence_Module_Variance_Order_By = {
  orderInSequence?: Maybe<Order_By>;
};

/** response of any mutation on the table "sequence" */
export type Sequence_Mutation_Response = {
  __typename?: 'sequence_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Sequence>;
};

/** input type for inserting object relation for remote table "sequence" */
export type Sequence_Obj_Rel_Insert_Input = {
  data: Sequence_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Sequence_On_Conflict>;
};

/** on_conflict condition type for table "sequence" */
export type Sequence_On_Conflict = {
  constraint: Sequence_Constraint;
  update_columns?: Array<Sequence_Update_Column>;
  where?: Maybe<Sequence_Bool_Exp>;
};

/** Ordering options when selecting data from "sequence". */
export type Sequence_Order_By = {
  course?: Maybe<Course_Order_By>;
  courseId?: Maybe<Order_By>;
  createdAt?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  orderInCourse?: Maybe<Order_By>;
  sequence_modules_aggregate?: Maybe<Sequence_Module_Aggregate_Order_By>;
  title?: Maybe<I18n_Order_By>;
  titleI18n?: Maybe<Order_By>;
  titleI18nId?: Maybe<Order_By>;
  updatedAt?: Maybe<Order_By>;
};

/** primary key columns input for table: sequence */
export type Sequence_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "sequence" */
export enum Sequence_Select_Column {
  /** column name */
  CourseId = 'courseId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  OrderInCourse = 'orderInCourse',
  /** column name */
  TitleI18nId = 'titleI18nId',
  /** column name */
  UpdatedAt = 'updatedAt'
}

/** input type for updating data in table "sequence" */
export type Sequence_Set_Input = {
  courseId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  orderInCourse?: Maybe<Scalars['Int']>;
  titleI18nId?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Sequence_Stddev_Fields = {
  __typename?: 'sequence_stddev_fields';
  orderInCourse?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "sequence" */
export type Sequence_Stddev_Order_By = {
  orderInCourse?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Sequence_Stddev_Pop_Fields = {
  __typename?: 'sequence_stddev_pop_fields';
  orderInCourse?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "sequence" */
export type Sequence_Stddev_Pop_Order_By = {
  orderInCourse?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Sequence_Stddev_Samp_Fields = {
  __typename?: 'sequence_stddev_samp_fields';
  orderInCourse?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "sequence" */
export type Sequence_Stddev_Samp_Order_By = {
  orderInCourse?: Maybe<Order_By>;
};

/** Streaming cursor of the table "sequence" */
export type Sequence_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Sequence_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Sequence_Stream_Cursor_Value_Input = {
  courseId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  orderInCourse?: Maybe<Scalars['Int']>;
  titleI18nId?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type Sequence_Sum_Fields = {
  __typename?: 'sequence_sum_fields';
  orderInCourse?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "sequence" */
export type Sequence_Sum_Order_By = {
  orderInCourse?: Maybe<Order_By>;
};

/** update columns of table "sequence" */
export enum Sequence_Update_Column {
  /** column name */
  CourseId = 'courseId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  OrderInCourse = 'orderInCourse',
  /** column name */
  TitleI18nId = 'titleI18nId',
  /** column name */
  UpdatedAt = 'updatedAt'
}

export type Sequence_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<Sequence_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Sequence_Set_Input>;
  /** filter the rows which have to be updated */
  where: Sequence_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Sequence_Var_Pop_Fields = {
  __typename?: 'sequence_var_pop_fields';
  orderInCourse?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "sequence" */
export type Sequence_Var_Pop_Order_By = {
  orderInCourse?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Sequence_Var_Samp_Fields = {
  __typename?: 'sequence_var_samp_fields';
  orderInCourse?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "sequence" */
export type Sequence_Var_Samp_Order_By = {
  orderInCourse?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Sequence_Variance_Fields = {
  __typename?: 'sequence_variance_fields';
  orderInCourse?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "sequence" */
export type Sequence_Variance_Order_By = {
  orderInCourse?: Maybe<Order_By>;
};

/** columns and relationships of "site" */
export type Site = {
  __typename?: 'site';
  code?: Maybe<Scalars['String']>;
  createdAt: Scalars['timestamptz'];
  id: Scalars['uuid'];
  /** An object relationship */
  name: I18n;
  /** A computed field, executes function "site_name_i18n" */
  nameI18n?: Maybe<Scalars['String']>;
  nameI18nId: Scalars['uuid'];
  /** A computed field, executes function "site_name_unaccent" */
  unaccentNameI18n?: Maybe<Scalars['String']>;
  updatedAt: Scalars['timestamptz'];
  /** An array relationship */
  volunteerPosts: Array<VolunteerPost>;
  /** An aggregate relationship */
  volunteerPosts_aggregate: VolunteerPost_Aggregate;
};


/** columns and relationships of "site" */
export type SiteVolunteerPostsArgs = {
  distinct_on?: Maybe<Array<VolunteerPost_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<VolunteerPost_Order_By>>;
  where?: Maybe<VolunteerPost_Bool_Exp>;
};


/** columns and relationships of "site" */
export type SiteVolunteerPosts_AggregateArgs = {
  distinct_on?: Maybe<Array<VolunteerPost_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<VolunteerPost_Order_By>>;
  where?: Maybe<VolunteerPost_Bool_Exp>;
};

/** aggregated selection of "site" */
export type Site_Aggregate = {
  __typename?: 'site_aggregate';
  aggregate?: Maybe<Site_Aggregate_Fields>;
  nodes: Array<Site>;
};

/** aggregate fields of "site" */
export type Site_Aggregate_Fields = {
  __typename?: 'site_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Site_Max_Fields>;
  min?: Maybe<Site_Min_Fields>;
};


/** aggregate fields of "site" */
export type Site_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Site_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "site". All fields are combined with a logical 'AND'. */
export type Site_Bool_Exp = {
  _and?: Maybe<Array<Site_Bool_Exp>>;
  _not?: Maybe<Site_Bool_Exp>;
  _or?: Maybe<Array<Site_Bool_Exp>>;
  code?: Maybe<String_Comparison_Exp>;
  createdAt?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  name?: Maybe<I18n_Bool_Exp>;
  nameI18n?: Maybe<String_Comparison_Exp>;
  nameI18nId?: Maybe<Uuid_Comparison_Exp>;
  unaccentNameI18n?: Maybe<String_Comparison_Exp>;
  updatedAt?: Maybe<Timestamptz_Comparison_Exp>;
  volunteerPosts?: Maybe<VolunteerPost_Bool_Exp>;
  volunteerPosts_aggregate?: Maybe<VolunteerPost_Aggregate_Bool_Exp>;
};

/** unique or primary key constraints on table "site" */
export enum Site_Constraint {
  /** unique or primary key constraint on columns "code" */
  SiteCodeKey = 'site_code_key',
  /** unique or primary key constraint on columns "id" */
  SitePkey = 'site_pkey'
}

/** input type for inserting data into table "site" */
export type Site_Insert_Input = {
  code?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<I18n_Obj_Rel_Insert_Input>;
  nameI18nId?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  volunteerPosts?: Maybe<VolunteerPost_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Site_Max_Fields = {
  __typename?: 'site_max_fields';
  code?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  nameI18nId?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Site_Min_Fields = {
  __typename?: 'site_min_fields';
  code?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  nameI18nId?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "site" */
export type Site_Mutation_Response = {
  __typename?: 'site_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Site>;
};

/** input type for inserting object relation for remote table "site" */
export type Site_Obj_Rel_Insert_Input = {
  data: Site_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Site_On_Conflict>;
};

/** on_conflict condition type for table "site" */
export type Site_On_Conflict = {
  constraint: Site_Constraint;
  update_columns?: Array<Site_Update_Column>;
  where?: Maybe<Site_Bool_Exp>;
};

/** Ordering options when selecting data from "site". */
export type Site_Order_By = {
  code?: Maybe<Order_By>;
  createdAt?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  name?: Maybe<I18n_Order_By>;
  nameI18n?: Maybe<Order_By>;
  nameI18nId?: Maybe<Order_By>;
  unaccentNameI18n?: Maybe<Order_By>;
  updatedAt?: Maybe<Order_By>;
  volunteerPosts_aggregate?: Maybe<VolunteerPost_Aggregate_Order_By>;
};

/** primary key columns input for table: site */
export type Site_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "site" */
export enum Site_Select_Column {
  /** column name */
  Code = 'code',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  NameI18nId = 'nameI18nId',
  /** column name */
  UpdatedAt = 'updatedAt'
}

/** input type for updating data in table "site" */
export type Site_Set_Input = {
  code?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  nameI18nId?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "site" */
export type Site_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Site_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Site_Stream_Cursor_Value_Input = {
  code?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  nameI18nId?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** update columns of table "site" */
export enum Site_Update_Column {
  /** column name */
  Code = 'code',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  NameI18nId = 'nameI18nId',
  /** column name */
  UpdatedAt = 'updatedAt'
}

export type Site_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Site_Set_Input>;
  /** filter the rows which have to be updated */
  where: Site_Bool_Exp;
};

/** columns and relationships of "skill" */
export type Skill = {
  __typename?: 'skill';
  createdAt: Scalars['timestamptz'];
  family?: Maybe<Scalars['String']>;
  humanId: Scalars['String'];
  id: Scalars['uuid'];
  isActive: Scalars['Boolean'];
  /** An object relationship */
  name: I18n;
  /** A computed field, executes function "skill_name_i18n" */
  nameI18n?: Maybe<Scalars['String']>;
  nameI18nId: Scalars['uuid'];
  referential: Scalars['String'];
  /** An array relationship */
  skillLevels: Array<SkillLevel>;
  /** An aggregate relationship */
  skillLevels_aggregate: SkillLevel_Aggregate;
  /** A computed field, executes function "skill_name_unaccent" */
  unaccentNameI18n?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};


/** columns and relationships of "skill" */
export type SkillSkillLevelsArgs = {
  distinct_on?: Maybe<Array<SkillLevel_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<SkillLevel_Order_By>>;
  where?: Maybe<SkillLevel_Bool_Exp>;
};


/** columns and relationships of "skill" */
export type SkillSkillLevels_AggregateArgs = {
  distinct_on?: Maybe<Array<SkillLevel_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<SkillLevel_Order_By>>;
  where?: Maybe<SkillLevel_Bool_Exp>;
};

/** columns and relationships of "skillLevel" */
export type SkillLevel = {
  __typename?: 'skillLevel';
  createdAt: Scalars['timestamptz'];
  /** An object relationship */
  description?: Maybe<I18n>;
  /** A computed field, executes function "skilllevel_description_i18n" */
  descriptionI18n?: Maybe<Scalars['String']>;
  descriptionI18nId?: Maybe<Scalars['uuid']>;
  id: Scalars['uuid'];
  level: Scalars['Int'];
  /** A computed field, executes function "skilllevel_name_i18n" */
  nameI18n?: Maybe<Scalars['String']>;
  nameI18nId: Scalars['uuid'];
  /** An object relationship */
  skill?: Maybe<Skill>;
  skillId?: Maybe<Scalars['uuid']>;
  updatedAt: Scalars['timestamp'];
};

/** aggregated selection of "skillLevel" */
export type SkillLevel_Aggregate = {
  __typename?: 'skillLevel_aggregate';
  aggregate?: Maybe<SkillLevel_Aggregate_Fields>;
  nodes: Array<SkillLevel>;
};

export type SkillLevel_Aggregate_Bool_Exp = {
  count?: Maybe<SkillLevel_Aggregate_Bool_Exp_Count>;
};

export type SkillLevel_Aggregate_Bool_Exp_Count = {
  arguments?: Maybe<Array<SkillLevel_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<SkillLevel_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "skillLevel" */
export type SkillLevel_Aggregate_Fields = {
  __typename?: 'skillLevel_aggregate_fields';
  avg?: Maybe<SkillLevel_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<SkillLevel_Max_Fields>;
  min?: Maybe<SkillLevel_Min_Fields>;
  stddev?: Maybe<SkillLevel_Stddev_Fields>;
  stddev_pop?: Maybe<SkillLevel_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<SkillLevel_Stddev_Samp_Fields>;
  sum?: Maybe<SkillLevel_Sum_Fields>;
  var_pop?: Maybe<SkillLevel_Var_Pop_Fields>;
  var_samp?: Maybe<SkillLevel_Var_Samp_Fields>;
  variance?: Maybe<SkillLevel_Variance_Fields>;
};


/** aggregate fields of "skillLevel" */
export type SkillLevel_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<SkillLevel_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "skillLevel" */
export type SkillLevel_Aggregate_Order_By = {
  avg?: Maybe<SkillLevel_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<SkillLevel_Max_Order_By>;
  min?: Maybe<SkillLevel_Min_Order_By>;
  stddev?: Maybe<SkillLevel_Stddev_Order_By>;
  stddev_pop?: Maybe<SkillLevel_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<SkillLevel_Stddev_Samp_Order_By>;
  sum?: Maybe<SkillLevel_Sum_Order_By>;
  var_pop?: Maybe<SkillLevel_Var_Pop_Order_By>;
  var_samp?: Maybe<SkillLevel_Var_Samp_Order_By>;
  variance?: Maybe<SkillLevel_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "skillLevel" */
export type SkillLevel_Arr_Rel_Insert_Input = {
  data: Array<SkillLevel_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<SkillLevel_On_Conflict>;
};

/** aggregate avg on columns */
export type SkillLevel_Avg_Fields = {
  __typename?: 'skillLevel_avg_fields';
  level?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "skillLevel" */
export type SkillLevel_Avg_Order_By = {
  level?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "skillLevel". All fields are combined with a logical 'AND'. */
export type SkillLevel_Bool_Exp = {
  _and?: Maybe<Array<SkillLevel_Bool_Exp>>;
  _not?: Maybe<SkillLevel_Bool_Exp>;
  _or?: Maybe<Array<SkillLevel_Bool_Exp>>;
  createdAt?: Maybe<Timestamptz_Comparison_Exp>;
  description?: Maybe<I18n_Bool_Exp>;
  descriptionI18n?: Maybe<String_Comparison_Exp>;
  descriptionI18nId?: Maybe<Uuid_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  level?: Maybe<Int_Comparison_Exp>;
  nameI18n?: Maybe<String_Comparison_Exp>;
  nameI18nId?: Maybe<Uuid_Comparison_Exp>;
  skill?: Maybe<Skill_Bool_Exp>;
  skillId?: Maybe<Uuid_Comparison_Exp>;
  updatedAt?: Maybe<Timestamp_Comparison_Exp>;
};

/** unique or primary key constraints on table "skillLevel" */
export enum SkillLevel_Constraint {
  /** unique or primary key constraint on columns "id" */
  SkillLevelPkey = 'skillLevel_pkey'
}

/** input type for incrementing numeric columns in table "skillLevel" */
export type SkillLevel_Inc_Input = {
  level?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "skillLevel" */
export type SkillLevel_Insert_Input = {
  createdAt?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<I18n_Obj_Rel_Insert_Input>;
  descriptionI18nId?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  level?: Maybe<Scalars['Int']>;
  nameI18nId?: Maybe<Scalars['uuid']>;
  skill?: Maybe<Skill_Obj_Rel_Insert_Input>;
  skillId?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamp']>;
};

/** aggregate max on columns */
export type SkillLevel_Max_Fields = {
  __typename?: 'skillLevel_max_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  descriptionI18nId?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  level?: Maybe<Scalars['Int']>;
  nameI18nId?: Maybe<Scalars['uuid']>;
  skillId?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamp']>;
};

/** order by max() on columns of table "skillLevel" */
export type SkillLevel_Max_Order_By = {
  createdAt?: Maybe<Order_By>;
  descriptionI18nId?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  level?: Maybe<Order_By>;
  nameI18nId?: Maybe<Order_By>;
  skillId?: Maybe<Order_By>;
  updatedAt?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type SkillLevel_Min_Fields = {
  __typename?: 'skillLevel_min_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  descriptionI18nId?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  level?: Maybe<Scalars['Int']>;
  nameI18nId?: Maybe<Scalars['uuid']>;
  skillId?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamp']>;
};

/** order by min() on columns of table "skillLevel" */
export type SkillLevel_Min_Order_By = {
  createdAt?: Maybe<Order_By>;
  descriptionI18nId?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  level?: Maybe<Order_By>;
  nameI18nId?: Maybe<Order_By>;
  skillId?: Maybe<Order_By>;
  updatedAt?: Maybe<Order_By>;
};

/** response of any mutation on the table "skillLevel" */
export type SkillLevel_Mutation_Response = {
  __typename?: 'skillLevel_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<SkillLevel>;
};

/** on_conflict condition type for table "skillLevel" */
export type SkillLevel_On_Conflict = {
  constraint: SkillLevel_Constraint;
  update_columns?: Array<SkillLevel_Update_Column>;
  where?: Maybe<SkillLevel_Bool_Exp>;
};

/** Ordering options when selecting data from "skillLevel". */
export type SkillLevel_Order_By = {
  createdAt?: Maybe<Order_By>;
  description?: Maybe<I18n_Order_By>;
  descriptionI18n?: Maybe<Order_By>;
  descriptionI18nId?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  level?: Maybe<Order_By>;
  nameI18n?: Maybe<Order_By>;
  nameI18nId?: Maybe<Order_By>;
  skill?: Maybe<Skill_Order_By>;
  skillId?: Maybe<Order_By>;
  updatedAt?: Maybe<Order_By>;
};

/** primary key columns input for table: skillLevel */
export type SkillLevel_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "skillLevel" */
export enum SkillLevel_Select_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  DescriptionI18nId = 'descriptionI18nId',
  /** column name */
  Id = 'id',
  /** column name */
  Level = 'level',
  /** column name */
  NameI18nId = 'nameI18nId',
  /** column name */
  SkillId = 'skillId',
  /** column name */
  UpdatedAt = 'updatedAt'
}

/** input type for updating data in table "skillLevel" */
export type SkillLevel_Set_Input = {
  createdAt?: Maybe<Scalars['timestamptz']>;
  descriptionI18nId?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  level?: Maybe<Scalars['Int']>;
  nameI18nId?: Maybe<Scalars['uuid']>;
  skillId?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamp']>;
};

/** aggregate stddev on columns */
export type SkillLevel_Stddev_Fields = {
  __typename?: 'skillLevel_stddev_fields';
  level?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "skillLevel" */
export type SkillLevel_Stddev_Order_By = {
  level?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type SkillLevel_Stddev_Pop_Fields = {
  __typename?: 'skillLevel_stddev_pop_fields';
  level?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "skillLevel" */
export type SkillLevel_Stddev_Pop_Order_By = {
  level?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type SkillLevel_Stddev_Samp_Fields = {
  __typename?: 'skillLevel_stddev_samp_fields';
  level?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "skillLevel" */
export type SkillLevel_Stddev_Samp_Order_By = {
  level?: Maybe<Order_By>;
};

/** Streaming cursor of the table "skillLevel" */
export type SkillLevel_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: SkillLevel_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type SkillLevel_Stream_Cursor_Value_Input = {
  createdAt?: Maybe<Scalars['timestamptz']>;
  descriptionI18nId?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  level?: Maybe<Scalars['Int']>;
  nameI18nId?: Maybe<Scalars['uuid']>;
  skillId?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamp']>;
};

/** aggregate sum on columns */
export type SkillLevel_Sum_Fields = {
  __typename?: 'skillLevel_sum_fields';
  level?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "skillLevel" */
export type SkillLevel_Sum_Order_By = {
  level?: Maybe<Order_By>;
};

/** update columns of table "skillLevel" */
export enum SkillLevel_Update_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  DescriptionI18nId = 'descriptionI18nId',
  /** column name */
  Id = 'id',
  /** column name */
  Level = 'level',
  /** column name */
  NameI18nId = 'nameI18nId',
  /** column name */
  SkillId = 'skillId',
  /** column name */
  UpdatedAt = 'updatedAt'
}

export type SkillLevel_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<SkillLevel_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<SkillLevel_Set_Input>;
  /** filter the rows which have to be updated */
  where: SkillLevel_Bool_Exp;
};

/** aggregate var_pop on columns */
export type SkillLevel_Var_Pop_Fields = {
  __typename?: 'skillLevel_var_pop_fields';
  level?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "skillLevel" */
export type SkillLevel_Var_Pop_Order_By = {
  level?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type SkillLevel_Var_Samp_Fields = {
  __typename?: 'skillLevel_var_samp_fields';
  level?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "skillLevel" */
export type SkillLevel_Var_Samp_Order_By = {
  level?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type SkillLevel_Variance_Fields = {
  __typename?: 'skillLevel_variance_fields';
  level?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "skillLevel" */
export type SkillLevel_Variance_Order_By = {
  level?: Maybe<Order_By>;
};

/** aggregated selection of "skill" */
export type Skill_Aggregate = {
  __typename?: 'skill_aggregate';
  aggregate?: Maybe<Skill_Aggregate_Fields>;
  nodes: Array<Skill>;
};

/** aggregate fields of "skill" */
export type Skill_Aggregate_Fields = {
  __typename?: 'skill_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Skill_Max_Fields>;
  min?: Maybe<Skill_Min_Fields>;
};


/** aggregate fields of "skill" */
export type Skill_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Skill_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "skill". All fields are combined with a logical 'AND'. */
export type Skill_Bool_Exp = {
  _and?: Maybe<Array<Skill_Bool_Exp>>;
  _not?: Maybe<Skill_Bool_Exp>;
  _or?: Maybe<Array<Skill_Bool_Exp>>;
  createdAt?: Maybe<Timestamptz_Comparison_Exp>;
  family?: Maybe<String_Comparison_Exp>;
  humanId?: Maybe<String_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  isActive?: Maybe<Boolean_Comparison_Exp>;
  name?: Maybe<I18n_Bool_Exp>;
  nameI18n?: Maybe<String_Comparison_Exp>;
  nameI18nId?: Maybe<Uuid_Comparison_Exp>;
  referential?: Maybe<String_Comparison_Exp>;
  skillLevels?: Maybe<SkillLevel_Bool_Exp>;
  skillLevels_aggregate?: Maybe<SkillLevel_Aggregate_Bool_Exp>;
  unaccentNameI18n?: Maybe<String_Comparison_Exp>;
  updatedAt?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "skill" */
export enum Skill_Constraint {
  /** unique or primary key constraint on columns "id" */
  SkillPkey = 'skill_pkey'
}

/** input type for inserting data into table "skill" */
export type Skill_Insert_Input = {
  createdAt?: Maybe<Scalars['timestamptz']>;
  family?: Maybe<Scalars['String']>;
  humanId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  isActive?: Maybe<Scalars['Boolean']>;
  name?: Maybe<I18n_Obj_Rel_Insert_Input>;
  nameI18nId?: Maybe<Scalars['uuid']>;
  referential?: Maybe<Scalars['String']>;
  skillLevels?: Maybe<SkillLevel_Arr_Rel_Insert_Input>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Skill_Max_Fields = {
  __typename?: 'skill_max_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  family?: Maybe<Scalars['String']>;
  humanId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  nameI18nId?: Maybe<Scalars['uuid']>;
  referential?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Skill_Min_Fields = {
  __typename?: 'skill_min_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  family?: Maybe<Scalars['String']>;
  humanId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  nameI18nId?: Maybe<Scalars['uuid']>;
  referential?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "skill" */
export type Skill_Mutation_Response = {
  __typename?: 'skill_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Skill>;
};

/** input type for inserting object relation for remote table "skill" */
export type Skill_Obj_Rel_Insert_Input = {
  data: Skill_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Skill_On_Conflict>;
};

/** columns and relationships of "skill_of_course" */
export type Skill_Of_Course = {
  __typename?: 'skill_of_course';
  courseId: Scalars['uuid'];
  id: Scalars['uuid'];
  level: Scalars['Int'];
  /** A computed field, executes function "skill_course_level_i18n" */
  levelDescription?: Maybe<Scalars['String']>;
  /** An object relationship */
  skill: Skill;
  skillId: Scalars['uuid'];
};

/** aggregated selection of "skill_of_course" */
export type Skill_Of_Course_Aggregate = {
  __typename?: 'skill_of_course_aggregate';
  aggregate?: Maybe<Skill_Of_Course_Aggregate_Fields>;
  nodes: Array<Skill_Of_Course>;
};

export type Skill_Of_Course_Aggregate_Bool_Exp = {
  count?: Maybe<Skill_Of_Course_Aggregate_Bool_Exp_Count>;
};

export type Skill_Of_Course_Aggregate_Bool_Exp_Count = {
  arguments?: Maybe<Array<Skill_Of_Course_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Skill_Of_Course_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "skill_of_course" */
export type Skill_Of_Course_Aggregate_Fields = {
  __typename?: 'skill_of_course_aggregate_fields';
  avg?: Maybe<Skill_Of_Course_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Skill_Of_Course_Max_Fields>;
  min?: Maybe<Skill_Of_Course_Min_Fields>;
  stddev?: Maybe<Skill_Of_Course_Stddev_Fields>;
  stddev_pop?: Maybe<Skill_Of_Course_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Skill_Of_Course_Stddev_Samp_Fields>;
  sum?: Maybe<Skill_Of_Course_Sum_Fields>;
  var_pop?: Maybe<Skill_Of_Course_Var_Pop_Fields>;
  var_samp?: Maybe<Skill_Of_Course_Var_Samp_Fields>;
  variance?: Maybe<Skill_Of_Course_Variance_Fields>;
};


/** aggregate fields of "skill_of_course" */
export type Skill_Of_Course_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Skill_Of_Course_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "skill_of_course" */
export type Skill_Of_Course_Aggregate_Order_By = {
  avg?: Maybe<Skill_Of_Course_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Skill_Of_Course_Max_Order_By>;
  min?: Maybe<Skill_Of_Course_Min_Order_By>;
  stddev?: Maybe<Skill_Of_Course_Stddev_Order_By>;
  stddev_pop?: Maybe<Skill_Of_Course_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Skill_Of_Course_Stddev_Samp_Order_By>;
  sum?: Maybe<Skill_Of_Course_Sum_Order_By>;
  var_pop?: Maybe<Skill_Of_Course_Var_Pop_Order_By>;
  var_samp?: Maybe<Skill_Of_Course_Var_Samp_Order_By>;
  variance?: Maybe<Skill_Of_Course_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "skill_of_course" */
export type Skill_Of_Course_Arr_Rel_Insert_Input = {
  data: Array<Skill_Of_Course_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Skill_Of_Course_On_Conflict>;
};

/** aggregate avg on columns */
export type Skill_Of_Course_Avg_Fields = {
  __typename?: 'skill_of_course_avg_fields';
  level?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "skill_of_course" */
export type Skill_Of_Course_Avg_Order_By = {
  level?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "skill_of_course". All fields are combined with a logical 'AND'. */
export type Skill_Of_Course_Bool_Exp = {
  _and?: Maybe<Array<Skill_Of_Course_Bool_Exp>>;
  _not?: Maybe<Skill_Of_Course_Bool_Exp>;
  _or?: Maybe<Array<Skill_Of_Course_Bool_Exp>>;
  courseId?: Maybe<Uuid_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  level?: Maybe<Int_Comparison_Exp>;
  levelDescription?: Maybe<String_Comparison_Exp>;
  skill?: Maybe<Skill_Bool_Exp>;
  skillId?: Maybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "skill_of_course" */
export enum Skill_Of_Course_Constraint {
  /** unique or primary key constraint on columns "id" */
  SkillOfCoursePkey = 'skill_of_course_pkey',
  /** unique or primary key constraint on columns "courseId", "level", "skillId" */
  SkillOfCourseSkillIdCourseIdLevelKey = 'skill_of_course_skillId_courseId_level_key'
}

/** input type for incrementing numeric columns in table "skill_of_course" */
export type Skill_Of_Course_Inc_Input = {
  level?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "skill_of_course" */
export type Skill_Of_Course_Insert_Input = {
  courseId?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  level?: Maybe<Scalars['Int']>;
  skill?: Maybe<Skill_Obj_Rel_Insert_Input>;
  skillId?: Maybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Skill_Of_Course_Max_Fields = {
  __typename?: 'skill_of_course_max_fields';
  courseId?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  level?: Maybe<Scalars['Int']>;
  skillId?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "skill_of_course" */
export type Skill_Of_Course_Max_Order_By = {
  courseId?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  level?: Maybe<Order_By>;
  skillId?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Skill_Of_Course_Min_Fields = {
  __typename?: 'skill_of_course_min_fields';
  courseId?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  level?: Maybe<Scalars['Int']>;
  skillId?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "skill_of_course" */
export type Skill_Of_Course_Min_Order_By = {
  courseId?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  level?: Maybe<Order_By>;
  skillId?: Maybe<Order_By>;
};

/** response of any mutation on the table "skill_of_course" */
export type Skill_Of_Course_Mutation_Response = {
  __typename?: 'skill_of_course_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Skill_Of_Course>;
};

/** on_conflict condition type for table "skill_of_course" */
export type Skill_Of_Course_On_Conflict = {
  constraint: Skill_Of_Course_Constraint;
  update_columns?: Array<Skill_Of_Course_Update_Column>;
  where?: Maybe<Skill_Of_Course_Bool_Exp>;
};

/** Ordering options when selecting data from "skill_of_course". */
export type Skill_Of_Course_Order_By = {
  courseId?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  level?: Maybe<Order_By>;
  levelDescription?: Maybe<Order_By>;
  skill?: Maybe<Skill_Order_By>;
  skillId?: Maybe<Order_By>;
};

/** primary key columns input for table: skill_of_course */
export type Skill_Of_Course_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "skill_of_course" */
export enum Skill_Of_Course_Select_Column {
  /** column name */
  CourseId = 'courseId',
  /** column name */
  Id = 'id',
  /** column name */
  Level = 'level',
  /** column name */
  SkillId = 'skillId'
}

/** input type for updating data in table "skill_of_course" */
export type Skill_Of_Course_Set_Input = {
  courseId?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  level?: Maybe<Scalars['Int']>;
  skillId?: Maybe<Scalars['uuid']>;
};

/** aggregate stddev on columns */
export type Skill_Of_Course_Stddev_Fields = {
  __typename?: 'skill_of_course_stddev_fields';
  level?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "skill_of_course" */
export type Skill_Of_Course_Stddev_Order_By = {
  level?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Skill_Of_Course_Stddev_Pop_Fields = {
  __typename?: 'skill_of_course_stddev_pop_fields';
  level?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "skill_of_course" */
export type Skill_Of_Course_Stddev_Pop_Order_By = {
  level?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Skill_Of_Course_Stddev_Samp_Fields = {
  __typename?: 'skill_of_course_stddev_samp_fields';
  level?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "skill_of_course" */
export type Skill_Of_Course_Stddev_Samp_Order_By = {
  level?: Maybe<Order_By>;
};

/** Streaming cursor of the table "skill_of_course" */
export type Skill_Of_Course_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Skill_Of_Course_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Skill_Of_Course_Stream_Cursor_Value_Input = {
  courseId?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  level?: Maybe<Scalars['Int']>;
  skillId?: Maybe<Scalars['uuid']>;
};

/** aggregate sum on columns */
export type Skill_Of_Course_Sum_Fields = {
  __typename?: 'skill_of_course_sum_fields';
  level?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "skill_of_course" */
export type Skill_Of_Course_Sum_Order_By = {
  level?: Maybe<Order_By>;
};

/** update columns of table "skill_of_course" */
export enum Skill_Of_Course_Update_Column {
  /** column name */
  CourseId = 'courseId',
  /** column name */
  Id = 'id',
  /** column name */
  Level = 'level',
  /** column name */
  SkillId = 'skillId'
}

export type Skill_Of_Course_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<Skill_Of_Course_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Skill_Of_Course_Set_Input>;
  /** filter the rows which have to be updated */
  where: Skill_Of_Course_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Skill_Of_Course_Var_Pop_Fields = {
  __typename?: 'skill_of_course_var_pop_fields';
  level?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "skill_of_course" */
export type Skill_Of_Course_Var_Pop_Order_By = {
  level?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Skill_Of_Course_Var_Samp_Fields = {
  __typename?: 'skill_of_course_var_samp_fields';
  level?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "skill_of_course" */
export type Skill_Of_Course_Var_Samp_Order_By = {
  level?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Skill_Of_Course_Variance_Fields = {
  __typename?: 'skill_of_course_variance_fields';
  level?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "skill_of_course" */
export type Skill_Of_Course_Variance_Order_By = {
  level?: Maybe<Order_By>;
};

/** on_conflict condition type for table "skill" */
export type Skill_On_Conflict = {
  constraint: Skill_Constraint;
  update_columns?: Array<Skill_Update_Column>;
  where?: Maybe<Skill_Bool_Exp>;
};

/** Ordering options when selecting data from "skill". */
export type Skill_Order_By = {
  createdAt?: Maybe<Order_By>;
  family?: Maybe<Order_By>;
  humanId?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  isActive?: Maybe<Order_By>;
  name?: Maybe<I18n_Order_By>;
  nameI18n?: Maybe<Order_By>;
  nameI18nId?: Maybe<Order_By>;
  referential?: Maybe<Order_By>;
  skillLevels_aggregate?: Maybe<SkillLevel_Aggregate_Order_By>;
  unaccentNameI18n?: Maybe<Order_By>;
  updatedAt?: Maybe<Order_By>;
};

/** primary key columns input for table: skill */
export type Skill_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "skill" */
export enum Skill_Select_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Family = 'family',
  /** column name */
  HumanId = 'humanId',
  /** column name */
  Id = 'id',
  /** column name */
  IsActive = 'isActive',
  /** column name */
  NameI18nId = 'nameI18nId',
  /** column name */
  Referential = 'referential',
  /** column name */
  UpdatedAt = 'updatedAt'
}

/** input type for updating data in table "skill" */
export type Skill_Set_Input = {
  createdAt?: Maybe<Scalars['timestamptz']>;
  family?: Maybe<Scalars['String']>;
  humanId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  isActive?: Maybe<Scalars['Boolean']>;
  nameI18nId?: Maybe<Scalars['uuid']>;
  referential?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "skill" */
export type Skill_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Skill_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Skill_Stream_Cursor_Value_Input = {
  createdAt?: Maybe<Scalars['timestamptz']>;
  family?: Maybe<Scalars['String']>;
  humanId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  isActive?: Maybe<Scalars['Boolean']>;
  nameI18nId?: Maybe<Scalars['uuid']>;
  referential?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** update columns of table "skill" */
export enum Skill_Update_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Family = 'family',
  /** column name */
  HumanId = 'humanId',
  /** column name */
  Id = 'id',
  /** column name */
  IsActive = 'isActive',
  /** column name */
  NameI18nId = 'nameI18nId',
  /** column name */
  Referential = 'referential',
  /** column name */
  UpdatedAt = 'updatedAt'
}

export type Skill_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Skill_Set_Input>;
  /** filter the rows which have to be updated */
  where: Skill_Bool_Exp;
};

export type Subscription_Root = {
  __typename?: 'subscription_root';
  /** fetch data from the table: "cmsPage" */
  cmsPage: Array<CmsPage>;
  /** fetch aggregated fields from the table: "cmsPage" */
  cmsPage_aggregate: CmsPage_Aggregate;
  /** fetch data from the table: "cmsPage" using primary key columns */
  cmsPage_by_pk?: Maybe<CmsPage>;
  /** fetch data from the table in a streaming manner: "cmsPage" */
  cmsPage_stream: Array<CmsPage>;
  /** fetch data from the table: "contentArticle" */
  contentArticle: Array<ContentArticle>;
  /** fetch aggregated fields from the table: "contentArticle" */
  contentArticle_aggregate: ContentArticle_Aggregate;
  /** fetch data from the table: "contentArticle" using primary key columns */
  contentArticle_by_pk?: Maybe<ContentArticle>;
  /** fetch data from the table in a streaming manner: "contentArticle" */
  contentArticle_stream: Array<ContentArticle>;
  /** fetch data from the table: "contentAudioTranscript" */
  contentAudioTranscript: Array<ContentAudioTranscript>;
  /** fetch aggregated fields from the table: "contentAudioTranscript" */
  contentAudioTranscript_aggregate: ContentAudioTranscript_Aggregate;
  /** fetch data from the table: "contentAudioTranscript" using primary key columns */
  contentAudioTranscript_by_pk?: Maybe<ContentAudioTranscript>;
  /** fetch data from the table in a streaming manner: "contentAudioTranscript" */
  contentAudioTranscript_stream: Array<ContentAudioTranscript>;
  /** fetch data from the table: "contentCmi5" */
  contentCmi5: Array<ContentCmi5>;
  /** fetch data from the table: "contentCmi5AssignableUnit" */
  contentCmi5AssignableUnit: Array<ContentCmi5AssignableUnit>;
  /** fetch aggregated fields from the table: "contentCmi5AssignableUnit" */
  contentCmi5AssignableUnit_aggregate: ContentCmi5AssignableUnit_Aggregate;
  /** fetch data from the table: "contentCmi5AssignableUnit" using primary key columns */
  contentCmi5AssignableUnit_by_pk?: Maybe<ContentCmi5AssignableUnit>;
  /** fetch data from the table in a streaming manner: "contentCmi5AssignableUnit" */
  contentCmi5AssignableUnit_stream: Array<ContentCmi5AssignableUnit>;
  /** fetch data from the table: "contentCmi5Objective" */
  contentCmi5Objective: Array<ContentCmi5Objective>;
  /** fetch aggregated fields from the table: "contentCmi5Objective" */
  contentCmi5Objective_aggregate: ContentCmi5Objective_Aggregate;
  /** fetch data from the table: "contentCmi5Objective" using primary key columns */
  contentCmi5Objective_by_pk?: Maybe<ContentCmi5Objective>;
  /** fetch data from the table in a streaming manner: "contentCmi5Objective" */
  contentCmi5Objective_stream: Array<ContentCmi5Objective>;
  /** fetch aggregated fields from the table: "contentCmi5" */
  contentCmi5_aggregate: ContentCmi5_Aggregate;
  /** fetch data from the table: "contentCmi5" using primary key columns */
  contentCmi5_by_pk?: Maybe<ContentCmi5>;
  /** fetch data from the table in a streaming manner: "contentCmi5" */
  contentCmi5_stream: Array<ContentCmi5>;
  /** fetch data from the table: "contentDocument" */
  contentDocument: Array<ContentDocument>;
  /** fetch data from the table: "contentDocumentUpload" */
  contentDocumentUpload: Array<ContentDocumentUpload>;
  /** fetch aggregated fields from the table: "contentDocumentUpload" */
  contentDocumentUpload_aggregate: ContentDocumentUpload_Aggregate;
  /** fetch data from the table: "contentDocumentUpload" using primary key columns */
  contentDocumentUpload_by_pk?: Maybe<ContentDocumentUpload>;
  /** fetch data from the table in a streaming manner: "contentDocumentUpload" */
  contentDocumentUpload_stream: Array<ContentDocumentUpload>;
  /** fetch aggregated fields from the table: "contentDocument" */
  contentDocument_aggregate: ContentDocument_Aggregate;
  /** fetch data from the table: "contentDocument" using primary key columns */
  contentDocument_by_pk?: Maybe<ContentDocument>;
  /** fetch data from the table in a streaming manner: "contentDocument" */
  contentDocument_stream: Array<ContentDocument>;
  /** fetch data from the table: "contentEmbed" */
  contentEmbed: Array<ContentEmbed>;
  /** fetch data from the table: "contentEmbedAudioTranscript" */
  contentEmbedAudioTranscript: Array<ContentEmbedAudioTranscript>;
  /** fetch aggregated fields from the table: "contentEmbedAudioTranscript" */
  contentEmbedAudioTranscript_aggregate: ContentEmbedAudioTranscript_Aggregate;
  /** fetch data from the table: "contentEmbedAudioTranscript" using primary key columns */
  contentEmbedAudioTranscript_by_pk?: Maybe<ContentEmbedAudioTranscript>;
  /** fetch data from the table in a streaming manner: "contentEmbedAudioTranscript" */
  contentEmbedAudioTranscript_stream: Array<ContentEmbedAudioTranscript>;
  /** fetch aggregated fields from the table: "contentEmbed" */
  contentEmbed_aggregate: ContentEmbed_Aggregate;
  /** fetch data from the table: "contentEmbed" using primary key columns */
  contentEmbed_by_pk?: Maybe<ContentEmbed>;
  /** fetch data from the table in a streaming manner: "contentEmbed" */
  contentEmbed_stream: Array<ContentEmbed>;
  /** fetch data from the table: "contentEvent" */
  contentEvent: Array<ContentEvent>;
  /** fetch data from the table: "contentEventAttachment" */
  contentEventAttachment: Array<ContentEventAttachment>;
  /** fetch aggregated fields from the table: "contentEventAttachment" */
  contentEventAttachment_aggregate: ContentEventAttachment_Aggregate;
  /** fetch data from the table: "contentEventAttachment" using primary key columns */
  contentEventAttachment_by_pk?: Maybe<ContentEventAttachment>;
  /** fetch data from the table in a streaming manner: "contentEventAttachment" */
  contentEventAttachment_stream: Array<ContentEventAttachment>;
  /** fetch data from the table: "contentEventSlot" */
  contentEventSlot: Array<ContentEventSlot>;
  /** fetch aggregated fields from the table: "contentEventSlot" */
  contentEventSlot_aggregate: ContentEventSlot_Aggregate;
  /** fetch data from the table: "contentEventSlot_animator" */
  contentEventSlot_animator: Array<ContentEventSlot_Animator>;
  /** fetch aggregated fields from the table: "contentEventSlot_animator" */
  contentEventSlot_animator_aggregate: ContentEventSlot_Animator_Aggregate;
  /** fetch data from the table: "contentEventSlot_animator" using primary key columns */
  contentEventSlot_animator_by_pk?: Maybe<ContentEventSlot_Animator>;
  /** fetch data from the table in a streaming manner: "contentEventSlot_animator" */
  contentEventSlot_animator_stream: Array<ContentEventSlot_Animator>;
  /** fetch data from the table: "contentEventSlot" using primary key columns */
  contentEventSlot_by_pk?: Maybe<ContentEventSlot>;
  /** fetch data from the table in a streaming manner: "contentEventSlot" */
  contentEventSlot_stream: Array<ContentEventSlot>;
  /** fetch aggregated fields from the table: "contentEvent" */
  contentEvent_aggregate: ContentEvent_Aggregate;
  /** fetch data from the table: "contentEvent" using primary key columns */
  contentEvent_by_pk?: Maybe<ContentEvent>;
  /** fetch data from the table in a streaming manner: "contentEvent" */
  contentEvent_stream: Array<ContentEvent>;
  /** fetch data from the table: "contentPodcast" */
  contentPodcast: Array<ContentPodcast>;
  /** fetch data from the table: "contentPodcastUpload" */
  contentPodcastUpload: Array<ContentPodcastUpload>;
  /** fetch aggregated fields from the table: "contentPodcastUpload" */
  contentPodcastUpload_aggregate: ContentPodcastUpload_Aggregate;
  /** fetch data from the table: "contentPodcastUpload" using primary key columns */
  contentPodcastUpload_by_pk?: Maybe<ContentPodcastUpload>;
  /** fetch data from the table in a streaming manner: "contentPodcastUpload" */
  contentPodcastUpload_stream: Array<ContentPodcastUpload>;
  /** fetch aggregated fields from the table: "contentPodcast" */
  contentPodcast_aggregate: ContentPodcast_Aggregate;
  /** fetch data from the table: "contentPodcast" using primary key columns */
  contentPodcast_by_pk?: Maybe<ContentPodcast>;
  /** fetch data from the table in a streaming manner: "contentPodcast" */
  contentPodcast_stream: Array<ContentPodcast>;
  /** fetch data from the table: "contentVideo" */
  contentVideo: Array<ContentVideo>;
  /** fetch data from the table: "contentVideoCaption" */
  contentVideoCaption: Array<ContentVideoCaption>;
  /** fetch aggregated fields from the table: "contentVideoCaption" */
  contentVideoCaption_aggregate: ContentVideoCaption_Aggregate;
  /** fetch data from the table: "contentVideoCaption" using primary key columns */
  contentVideoCaption_by_pk?: Maybe<ContentVideoCaption>;
  /** fetch data from the table in a streaming manner: "contentVideoCaption" */
  contentVideoCaption_stream: Array<ContentVideoCaption>;
  /** An array relationship */
  contentVideoDetails: Array<ContentVideoDetails>;
  /** An aggregate relationship */
  contentVideoDetails_aggregate: ContentVideoDetails_Aggregate;
  /** fetch data from the table: "contentVideoDetails" using primary key columns */
  contentVideoDetails_by_pk?: Maybe<ContentVideoDetails>;
  /** fetch data from the table in a streaming manner: "contentVideoDetails" */
  contentVideoDetails_stream: Array<ContentVideoDetails>;
  /** fetch aggregated fields from the table: "contentVideo" */
  contentVideo_aggregate: ContentVideo_Aggregate;
  /** fetch data from the table: "contentVideo" using primary key columns */
  contentVideo_by_pk?: Maybe<ContentVideo>;
  /** fetch data from the table in a streaming manner: "contentVideo" */
  contentVideo_stream: Array<ContentVideo>;
  /** fetch data from the table: "course" */
  course: Array<Course>;
  /** fetch data from the table: "courseAssign" */
  courseAssign: Array<CourseAssign>;
  /** fetch data from the table: "courseAssignEmployee" */
  courseAssignEmployee: Array<CourseAssignEmployee>;
  /** fetch data from the table: "courseAssignEmployeeDirection" */
  courseAssignEmployeeDirection: Array<CourseAssignEmployeeDirection>;
  /** fetch aggregated fields from the table: "courseAssignEmployeeDirection" */
  courseAssignEmployeeDirection_aggregate: CourseAssignEmployeeDirection_Aggregate;
  /** fetch data from the table: "courseAssignEmployeeDirection" using primary key columns */
  courseAssignEmployeeDirection_by_pk?: Maybe<CourseAssignEmployeeDirection>;
  /** fetch data from the table in a streaming manner: "courseAssignEmployeeDirection" */
  courseAssignEmployeeDirection_stream: Array<CourseAssignEmployeeDirection>;
  /** fetch data from the table: "courseAssignEmployeeFa" */
  courseAssignEmployeeFa: Array<CourseAssignEmployeeFa>;
  /** fetch aggregated fields from the table: "courseAssignEmployeeFa" */
  courseAssignEmployeeFa_aggregate: CourseAssignEmployeeFa_Aggregate;
  /** fetch data from the table: "courseAssignEmployeeFa" using primary key columns */
  courseAssignEmployeeFa_by_pk?: Maybe<CourseAssignEmployeeFa>;
  /** fetch data from the table in a streaming manner: "courseAssignEmployeeFa" */
  courseAssignEmployeeFa_stream: Array<CourseAssignEmployeeFa>;
  /** fetch aggregated fields from the table: "courseAssignEmployee" */
  courseAssignEmployee_aggregate: CourseAssignEmployee_Aggregate;
  /** fetch data from the table: "courseAssignEmployee" using primary key columns */
  courseAssignEmployee_by_pk?: Maybe<CourseAssignEmployee>;
  /** fetch data from the table in a streaming manner: "courseAssignEmployee" */
  courseAssignEmployee_stream: Array<CourseAssignEmployee>;
  /** fetch data from the table: "courseAssignGroup" */
  courseAssignGroup: Array<CourseAssignGroup>;
  /** fetch aggregated fields from the table: "courseAssignGroup" */
  courseAssignGroup_aggregate: CourseAssignGroup_Aggregate;
  /** fetch data from the table: "courseAssignGroup" using primary key columns */
  courseAssignGroup_by_pk?: Maybe<CourseAssignGroup>;
  /** fetch data from the table in a streaming manner: "courseAssignGroup" */
  courseAssignGroup_stream: Array<CourseAssignGroup>;
  /** fetch data from the table: "courseAssignOther" */
  courseAssignOther: Array<CourseAssignOther>;
  /** fetch aggregated fields from the table: "courseAssignOther" */
  courseAssignOther_aggregate: CourseAssignOther_Aggregate;
  /** fetch data from the table: "courseAssignOther" using primary key columns */
  courseAssignOther_by_pk?: Maybe<CourseAssignOther>;
  /** fetch data from the table in a streaming manner: "courseAssignOther" */
  courseAssignOther_stream: Array<CourseAssignOther>;
  /** fetch data from the table: "courseAssignUser" */
  courseAssignUser: Array<CourseAssignUser>;
  /** fetch aggregated fields from the table: "courseAssignUser" */
  courseAssignUser_aggregate: CourseAssignUser_Aggregate;
  /** fetch data from the table: "courseAssignUser" using primary key columns */
  courseAssignUser_by_pk?: Maybe<CourseAssignUser>;
  /** fetch data from the table in a streaming manner: "courseAssignUser" */
  courseAssignUser_stream: Array<CourseAssignUser>;
  /** fetch data from the table: "courseAssignVolunteer" */
  courseAssignVolunteer: Array<CourseAssignVolunteer>;
  /** fetch data from the table: "courseAssignVolunteerFa" */
  courseAssignVolunteerFa: Array<CourseAssignVolunteerFa>;
  /** fetch aggregated fields from the table: "courseAssignVolunteerFa" */
  courseAssignVolunteerFa_aggregate: CourseAssignVolunteerFa_Aggregate;
  /** fetch data from the table: "courseAssignVolunteerFa" using primary key columns */
  courseAssignVolunteerFa_by_pk?: Maybe<CourseAssignVolunteerFa>;
  /** fetch data from the table in a streaming manner: "courseAssignVolunteerFa" */
  courseAssignVolunteerFa_stream: Array<CourseAssignVolunteerFa>;
  /** fetch data from the table: "courseAssignVolunteerJobTitle" */
  courseAssignVolunteerJobTitle: Array<CourseAssignVolunteerJobTitle>;
  /** fetch aggregated fields from the table: "courseAssignVolunteerJobTitle" */
  courseAssignVolunteerJobTitle_aggregate: CourseAssignVolunteerJobTitle_Aggregate;
  /** fetch data from the table: "courseAssignVolunteerJobTitle" using primary key columns */
  courseAssignVolunteerJobTitle_by_pk?: Maybe<CourseAssignVolunteerJobTitle>;
  /** fetch data from the table in a streaming manner: "courseAssignVolunteerJobTitle" */
  courseAssignVolunteerJobTitle_stream: Array<CourseAssignVolunteerJobTitle>;
  /** fetch data from the table: "courseAssignVolunteerSite" */
  courseAssignVolunteerSite: Array<CourseAssignVolunteerSite>;
  /** fetch aggregated fields from the table: "courseAssignVolunteerSite" */
  courseAssignVolunteerSite_aggregate: CourseAssignVolunteerSite_Aggregate;
  /** fetch data from the table: "courseAssignVolunteerSite" using primary key columns */
  courseAssignVolunteerSite_by_pk?: Maybe<CourseAssignVolunteerSite>;
  /** fetch data from the table in a streaming manner: "courseAssignVolunteerSite" */
  courseAssignVolunteerSite_stream: Array<CourseAssignVolunteerSite>;
  /** fetch aggregated fields from the table: "courseAssignVolunteer" */
  courseAssignVolunteer_aggregate: CourseAssignVolunteer_Aggregate;
  /** fetch data from the table: "courseAssignVolunteer" using primary key columns */
  courseAssignVolunteer_by_pk?: Maybe<CourseAssignVolunteer>;
  /** fetch data from the table in a streaming manner: "courseAssignVolunteer" */
  courseAssignVolunteer_stream: Array<CourseAssignVolunteer>;
  /** fetch aggregated fields from the table: "courseAssign" */
  courseAssign_aggregate: CourseAssign_Aggregate;
  /** fetch data from the table: "courseAssign" using primary key columns */
  courseAssign_by_pk?: Maybe<CourseAssign>;
  /** fetch data from the table in a streaming manner: "courseAssign" */
  courseAssign_stream: Array<CourseAssign>;
  /** fetch aggregated fields from the table: "course" */
  course_aggregate: Course_Aggregate;
  /** fetch data from the table: "course" using primary key columns */
  course_by_pk?: Maybe<Course>;
  /** fetch data from the table in a streaming manner: "course" */
  course_stream: Array<Course>;
  /** fetch data from the table: "d_courseUser" */
  d_courseUser: Array<D_CourseUser>;
  /** fetch data from the table: "d_courseUserRefresh" */
  d_courseUserRefresh: Array<D_CourseUserRefresh>;
  /** fetch aggregated fields from the table: "d_courseUserRefresh" */
  d_courseUserRefresh_aggregate: D_CourseUserRefresh_Aggregate;
  /** fetch data from the table: "d_courseUserRefresh" using primary key columns */
  d_courseUserRefresh_by_pk?: Maybe<D_CourseUserRefresh>;
  /** fetch data from the table in a streaming manner: "d_courseUserRefresh" */
  d_courseUserRefresh_stream: Array<D_CourseUserRefresh>;
  /** fetch aggregated fields from the table: "d_courseUser" */
  d_courseUser_aggregate: D_CourseUser_Aggregate;
  /** fetch data from the table in a streaming manner: "d_courseUser" */
  d_courseUser_stream: Array<D_CourseUser>;
  /** fetch data from the table: "dataAccessRequest" */
  dataAccessRequest: Array<DataAccessRequest>;
  /** fetch aggregated fields from the table: "dataAccessRequest" */
  dataAccessRequest_aggregate: DataAccessRequest_Aggregate;
  /** fetch data from the table: "dataAccessRequest" using primary key columns */
  dataAccessRequest_by_pk?: Maybe<DataAccessRequest>;
  /** fetch data from the table in a streaming manner: "dataAccessRequest" */
  dataAccessRequest_stream: Array<DataAccessRequest>;
  /** fetch data from the table: "direction" */
  direction: Array<Direction>;
  /** fetch aggregated fields from the table: "direction" */
  direction_aggregate: Direction_Aggregate;
  /** fetch data from the table: "direction" using primary key columns */
  direction_by_pk?: Maybe<Direction>;
  /** fetch data from the table in a streaming manner: "direction" */
  direction_stream: Array<Direction>;
  /** fetch data from the table: "emailValidationToken" */
  emailValidationToken: Array<EmailValidationToken>;
  /** fetch aggregated fields from the table: "emailValidationToken" */
  emailValidationToken_aggregate: EmailValidationToken_Aggregate;
  /** fetch data from the table: "emailValidationToken" using primary key columns */
  emailValidationToken_by_pk?: Maybe<EmailValidationToken>;
  /** fetch data from the table in a streaming manner: "emailValidationToken" */
  emailValidationToken_stream: Array<EmailValidationToken>;
  /** fetch data from the table: "fa" */
  fa: Array<Fa>;
  /** fetch aggregated fields from the table: "fa" */
  fa_aggregate: Fa_Aggregate;
  /** fetch data from the table: "fa" using primary key columns */
  fa_by_pk?: Maybe<Fa>;
  /** fetch data from the table in a streaming manner: "fa" */
  fa_stream: Array<Fa>;
  /** fetch data from the table: "faq" */
  faq: Array<Faq>;
  /** fetch aggregated fields from the table: "faq" */
  faq_aggregate: Faq_Aggregate;
  /** fetch data from the table: "faq" using primary key columns */
  faq_by_pk?: Maybe<Faq>;
  /** fetch data from the table in a streaming manner: "faq" */
  faq_stream: Array<Faq>;
  /** fetch data from the table: "group" */
  group: Array<Group>;
  /** fetch aggregated fields from the table: "group" */
  group_aggregate: Group_Aggregate;
  /** fetch data from the table: "group" using primary key columns */
  group_by_pk?: Maybe<Group>;
  /** fetch data from the table in a streaming manner: "group" */
  group_stream: Array<Group>;
  /** fetch data from the table: "home" */
  home: Array<Home>;
  /** fetch aggregated fields from the table: "home" */
  home_aggregate: Home_Aggregate;
  /** fetch data from the table: "home" using primary key columns */
  home_by_pk?: Maybe<Home>;
  /** fetch data from the table in a streaming manner: "home" */
  home_stream: Array<Home>;
  /** fetch data from the table: "i18n" */
  i18n: Array<I18n>;
  /** fetch aggregated fields from the table: "i18n" */
  i18n_aggregate: I18n_Aggregate;
  /** fetch data from the table: "i18n" using primary key columns */
  i18n_by_pk?: Maybe<I18n>;
  /** fetch data from the table in a streaming manner: "i18n" */
  i18n_stream: Array<I18n>;
  /** fetch data from the table: "identity" */
  identity: Array<Identity>;
  /** fetch aggregated fields from the table: "identity" */
  identity_aggregate: Identity_Aggregate;
  /** fetch data from the table: "identity" using primary key columns */
  identity_by_pk?: Maybe<Identity>;
  /** fetch data from the table in a streaming manner: "identity" */
  identity_stream: Array<Identity>;
  /** fetch data from the table: "import" */
  import: Array<Import>;
  /** fetch data from the table: "importGroup" */
  importGroup: Array<ImportGroup>;
  /** fetch aggregated fields from the table: "importGroup" */
  importGroup_aggregate: ImportGroup_Aggregate;
  /** fetch data from the table: "importGroup" using primary key columns */
  importGroup_by_pk?: Maybe<ImportGroup>;
  /** fetch data from the table in a streaming manner: "importGroup" */
  importGroup_stream: Array<ImportGroup>;
  /** fetch data from the table: "importSkill" */
  importSkill: Array<ImportSkill>;
  /** fetch aggregated fields from the table: "importSkill" */
  importSkill_aggregate: ImportSkill_Aggregate;
  /** fetch data from the table: "importSkill" using primary key columns */
  importSkill_by_pk?: Maybe<ImportSkill>;
  /** fetch data from the table in a streaming manner: "importSkill" */
  importSkill_stream: Array<ImportSkill>;
  /** fetch aggregated fields from the table: "import" */
  import_aggregate: Import_Aggregate;
  /** fetch data from the table: "import" using primary key columns */
  import_by_pk?: Maybe<Import>;
  /** fetch data from the table in a streaming manner: "import" */
  import_stream: Array<Import>;
  /** fetch data from the table: "jobTitle" */
  jobTitle: Array<JobTitle>;
  /** fetch aggregated fields from the table: "jobTitle" */
  jobTitle_aggregate: JobTitle_Aggregate;
  /** fetch data from the table: "jobTitle" using primary key columns */
  jobTitle_by_pk?: Maybe<JobTitle>;
  /** fetch data from the table in a streaming manner: "jobTitle" */
  jobTitle_stream: Array<JobTitle>;
  /** fetch data from the table: "module" */
  module: Array<Module>;
  /** fetch data from the table: "moduleAnnex" */
  moduleAnnex: Array<ModuleAnnex>;
  /** fetch aggregated fields from the table: "moduleAnnex" */
  moduleAnnex_aggregate: ModuleAnnex_Aggregate;
  /** fetch data from the table: "moduleAnnex" using primary key columns */
  moduleAnnex_by_pk?: Maybe<ModuleAnnex>;
  /** fetch data from the table in a streaming manner: "moduleAnnex" */
  moduleAnnex_stream: Array<ModuleAnnex>;
  /** fetch data from the table: "moduleContent" */
  moduleContent: Array<ModuleContent>;
  /** fetch aggregated fields from the table: "moduleContent" */
  moduleContent_aggregate: ModuleContent_Aggregate;
  /** fetch data from the table: "moduleContent" using primary key columns */
  moduleContent_by_pk?: Maybe<ModuleContent>;
  /** fetch data from the table in a streaming manner: "moduleContent" */
  moduleContent_stream: Array<ModuleContent>;
  /** fetch data from the table: "moduleTask" */
  moduleTask: Array<ModuleTask>;
  /** fetch aggregated fields from the table: "moduleTask" */
  moduleTask_aggregate: ModuleTask_Aggregate;
  /** fetch data from the table: "moduleTask" using primary key columns */
  moduleTask_by_pk?: Maybe<ModuleTask>;
  /** fetch data from the table in a streaming manner: "moduleTask" */
  moduleTask_stream: Array<ModuleTask>;
  /** fetch aggregated fields from the table: "module" */
  module_aggregate: Module_Aggregate;
  /** fetch data from the table: "module" using primary key columns */
  module_by_pk?: Maybe<Module>;
  /** fetch data from the table in a streaming manner: "module" */
  module_stream: Array<Module>;
  /** fetch data from the table: "organisation" */
  organisation: Array<Organisation>;
  /** fetch aggregated fields from the table: "organisation" */
  organisation_aggregate: Organisation_Aggregate;
  /** fetch data from the table: "organisation" using primary key columns */
  organisation_by_pk?: Maybe<Organisation>;
  /** fetch data from the table in a streaming manner: "organisation" */
  organisation_stream: Array<Organisation>;
  /** fetch data from the table: "passwordResetToken" */
  passwordResetToken: Array<PasswordResetToken>;
  /** fetch aggregated fields from the table: "passwordResetToken" */
  passwordResetToken_aggregate: PasswordResetToken_Aggregate;
  /** fetch data from the table: "passwordResetToken" using primary key columns */
  passwordResetToken_by_pk?: Maybe<PasswordResetToken>;
  /** fetch data from the table in a streaming manner: "passwordResetToken" */
  passwordResetToken_stream: Array<PasswordResetToken>;
  /** fetch data from the table: "refreshToken" */
  refreshToken: Array<RefreshToken>;
  /** fetch aggregated fields from the table: "refreshToken" */
  refreshToken_aggregate: RefreshToken_Aggregate;
  /** fetch data from the table: "refreshToken" using primary key columns */
  refreshToken_by_pk?: Maybe<RefreshToken>;
  /** fetch data from the table in a streaming manner: "refreshToken" */
  refreshToken_stream: Array<RefreshToken>;
  /** fetch data from the table: "role" */
  role: Array<Role>;
  /** fetch aggregated fields from the table: "role" */
  role_aggregate: Role_Aggregate;
  /** fetch data from the table: "role" using primary key columns */
  role_by_pk?: Maybe<Role>;
  /** fetch data from the table in a streaming manner: "role" */
  role_stream: Array<Role>;
  /** fetch data from the table: "sequence" */
  sequence: Array<Sequence>;
  /** fetch aggregated fields from the table: "sequence" */
  sequence_aggregate: Sequence_Aggregate;
  /** fetch data from the table: "sequence" using primary key columns */
  sequence_by_pk?: Maybe<Sequence>;
  /** fetch data from the table: "sequence_module" */
  sequence_module: Array<Sequence_Module>;
  /** fetch aggregated fields from the table: "sequence_module" */
  sequence_module_aggregate: Sequence_Module_Aggregate;
  /** fetch data from the table: "sequence_module" using primary key columns */
  sequence_module_by_pk?: Maybe<Sequence_Module>;
  /** fetch data from the table in a streaming manner: "sequence_module" */
  sequence_module_stream: Array<Sequence_Module>;
  /** fetch data from the table in a streaming manner: "sequence" */
  sequence_stream: Array<Sequence>;
  /** fetch data from the table: "site" */
  site: Array<Site>;
  /** fetch aggregated fields from the table: "site" */
  site_aggregate: Site_Aggregate;
  /** fetch data from the table: "site" using primary key columns */
  site_by_pk?: Maybe<Site>;
  /** fetch data from the table in a streaming manner: "site" */
  site_stream: Array<Site>;
  /** fetch data from the table: "skill" */
  skill: Array<Skill>;
  /** fetch data from the table: "skillLevel" */
  skillLevel: Array<SkillLevel>;
  /** fetch aggregated fields from the table: "skillLevel" */
  skillLevel_aggregate: SkillLevel_Aggregate;
  /** fetch data from the table: "skillLevel" using primary key columns */
  skillLevel_by_pk?: Maybe<SkillLevel>;
  /** fetch data from the table in a streaming manner: "skillLevel" */
  skillLevel_stream: Array<SkillLevel>;
  /** fetch aggregated fields from the table: "skill" */
  skill_aggregate: Skill_Aggregate;
  /** fetch data from the table: "skill" using primary key columns */
  skill_by_pk?: Maybe<Skill>;
  /** fetch data from the table: "skill_of_course" */
  skill_of_course: Array<Skill_Of_Course>;
  /** fetch aggregated fields from the table: "skill_of_course" */
  skill_of_course_aggregate: Skill_Of_Course_Aggregate;
  /** fetch data from the table: "skill_of_course" using primary key columns */
  skill_of_course_by_pk?: Maybe<Skill_Of_Course>;
  /** fetch data from the table in a streaming manner: "skill_of_course" */
  skill_of_course_stream: Array<Skill_Of_Course>;
  /** fetch data from the table in a streaming manner: "skill" */
  skill_stream: Array<Skill>;
  /** fetch data from the table: "tag_course" */
  tag_course: Array<Tag_Course>;
  /** fetch aggregated fields from the table: "tag_course" */
  tag_course_aggregate: Tag_Course_Aggregate;
  /** fetch data from the table: "tag_course" using primary key columns */
  tag_course_by_pk?: Maybe<Tag_Course>;
  /** fetch data from the table in a streaming manner: "tag_course" */
  tag_course_stream: Array<Tag_Course>;
  /** fetch data from the table: "tag_of_course" */
  tag_of_course: Array<Tag_Of_Course>;
  /** fetch aggregated fields from the table: "tag_of_course" */
  tag_of_course_aggregate: Tag_Of_Course_Aggregate;
  /** fetch data from the table: "tag_of_course" using primary key columns */
  tag_of_course_by_pk?: Maybe<Tag_Of_Course>;
  /** fetch data from the table in a streaming manner: "tag_of_course" */
  tag_of_course_stream: Array<Tag_Of_Course>;
  /** fetch data from the table: "thematic" */
  thematic: Array<Thematic>;
  /** fetch aggregated fields from the table: "thematic" */
  thematic_aggregate: Thematic_Aggregate;
  /** fetch data from the table: "thematic" using primary key columns */
  thematic_by_pk?: Maybe<Thematic>;
  /** fetch data from the table in a streaming manner: "thematic" */
  thematic_stream: Array<Thematic>;
  /** fetch data from the table: "translation" */
  translation: Array<Translation>;
  /** fetch aggregated fields from the table: "translation" */
  translation_aggregate: Translation_Aggregate;
  /** fetch data from the table: "translation" using primary key columns */
  translation_by_pk?: Maybe<Translation>;
  /** fetch data from the table in a streaming manner: "translation" */
  translation_stream: Array<Translation>;
  /** fetch data from the table: "user" */
  user: Array<User>;
  /** fetch data from the table: "userCourseProgress" */
  userCourseProgress: Array<UserCourseProgress>;
  /** fetch aggregated fields from the table: "userCourseProgress" */
  userCourseProgress_aggregate: UserCourseProgress_Aggregate;
  /** fetch data from the table: "userCourseProgress" using primary key columns */
  userCourseProgress_by_pk?: Maybe<UserCourseProgress>;
  /** fetch data from the table in a streaming manner: "userCourseProgress" */
  userCourseProgress_stream: Array<UserCourseProgress>;
  /** fetch data from the table: "userEventSlot" */
  userEventSlot: Array<UserEventSlot>;
  /** fetch aggregated fields from the table: "userEventSlot" */
  userEventSlot_aggregate: UserEventSlot_Aggregate;
  /** fetch data from the table: "userEventSlot" using primary key columns */
  userEventSlot_by_pk?: Maybe<UserEventSlot>;
  /** fetch data from the table in a streaming manner: "userEventSlot" */
  userEventSlot_stream: Array<UserEventSlot>;
  /** fetch data from the table: "userGroup" */
  userGroup: Array<UserGroup>;
  /** fetch aggregated fields from the table: "userGroup" */
  userGroup_aggregate: UserGroup_Aggregate;
  /** fetch data from the table: "userGroup" using primary key columns */
  userGroup_by_pk?: Maybe<UserGroup>;
  /** fetch data from the table in a streaming manner: "userGroup" */
  userGroup_stream: Array<UserGroup>;
  /** An array relationship */
  userModuleProgress: Array<UserModuleProgress>;
  /** An aggregate relationship */
  userModuleProgress_aggregate: UserModuleProgress_Aggregate;
  /** fetch data from the table: "userModuleProgress" using primary key columns */
  userModuleProgress_by_pk?: Maybe<UserModuleProgress>;
  /** fetch data from the table in a streaming manner: "userModuleProgress" */
  userModuleProgress_stream: Array<UserModuleProgress>;
  /** fetch data from the table: "userPrivate" */
  userPrivate: Array<UserPrivate>;
  /** fetch aggregated fields from the table: "userPrivate" */
  userPrivate_aggregate: UserPrivate_Aggregate;
  /** fetch data from the table in a streaming manner: "userPrivate" */
  userPrivate_stream: Array<UserPrivate>;
  /** fetch data from the table: "userSkill" */
  userSkill: Array<UserSkill>;
  /** fetch aggregated fields from the table: "userSkill" */
  userSkill_aggregate: UserSkill_Aggregate;
  /** fetch data from the table: "userSkill" using primary key columns */
  userSkill_by_pk?: Maybe<UserSkill>;
  /** fetch data from the table in a streaming manner: "userSkill" */
  userSkill_stream: Array<UserSkill>;
  /** fetch aggregated fields from the table: "user" */
  user_aggregate: User_Aggregate;
  /** fetch data from the table: "user" using primary key columns */
  user_by_pk?: Maybe<User>;
  /** fetch data from the table in a streaming manner: "user" */
  user_stream: Array<User>;
  /** fetch data from the table: "volunteerPost" */
  volunteerPost: Array<VolunteerPost>;
  /** fetch aggregated fields from the table: "volunteerPost" */
  volunteerPost_aggregate: VolunteerPost_Aggregate;
  /** fetch data from the table: "volunteerPost" using primary key columns */
  volunteerPost_by_pk?: Maybe<VolunteerPost>;
  /** fetch data from the table in a streaming manner: "volunteerPost" */
  volunteerPost_stream: Array<VolunteerPost>;
  /** fetch data from the table: "xapi_statement" */
  xapi_statement: Array<Xapi_Statement>;
  /** fetch aggregated fields from the table: "xapi_statement" */
  xapi_statement_aggregate: Xapi_Statement_Aggregate;
  /** fetch data from the table: "xapi_statement" using primary key columns */
  xapi_statement_by_pk?: Maybe<Xapi_Statement>;
  /** fetch data from the table in a streaming manner: "xapi_statement" */
  xapi_statement_stream: Array<Xapi_Statement>;
};


export type Subscription_RootCmsPageArgs = {
  distinct_on?: Maybe<Array<CmsPage_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<CmsPage_Order_By>>;
  where?: Maybe<CmsPage_Bool_Exp>;
};


export type Subscription_RootCmsPage_AggregateArgs = {
  distinct_on?: Maybe<Array<CmsPage_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<CmsPage_Order_By>>;
  where?: Maybe<CmsPage_Bool_Exp>;
};


export type Subscription_RootCmsPage_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootCmsPage_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<CmsPage_Stream_Cursor_Input>>;
  where?: Maybe<CmsPage_Bool_Exp>;
};


export type Subscription_RootContentArticleArgs = {
  distinct_on?: Maybe<Array<ContentArticle_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ContentArticle_Order_By>>;
  where?: Maybe<ContentArticle_Bool_Exp>;
};


export type Subscription_RootContentArticle_AggregateArgs = {
  distinct_on?: Maybe<Array<ContentArticle_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ContentArticle_Order_By>>;
  where?: Maybe<ContentArticle_Bool_Exp>;
};


export type Subscription_RootContentArticle_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootContentArticle_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<ContentArticle_Stream_Cursor_Input>>;
  where?: Maybe<ContentArticle_Bool_Exp>;
};


export type Subscription_RootContentAudioTranscriptArgs = {
  distinct_on?: Maybe<Array<ContentAudioTranscript_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ContentAudioTranscript_Order_By>>;
  where?: Maybe<ContentAudioTranscript_Bool_Exp>;
};


export type Subscription_RootContentAudioTranscript_AggregateArgs = {
  distinct_on?: Maybe<Array<ContentAudioTranscript_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ContentAudioTranscript_Order_By>>;
  where?: Maybe<ContentAudioTranscript_Bool_Exp>;
};


export type Subscription_RootContentAudioTranscript_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootContentAudioTranscript_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<ContentAudioTranscript_Stream_Cursor_Input>>;
  where?: Maybe<ContentAudioTranscript_Bool_Exp>;
};


export type Subscription_RootContentCmi5Args = {
  distinct_on?: Maybe<Array<ContentCmi5_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ContentCmi5_Order_By>>;
  where?: Maybe<ContentCmi5_Bool_Exp>;
};


export type Subscription_RootContentCmi5AssignableUnitArgs = {
  distinct_on?: Maybe<Array<ContentCmi5AssignableUnit_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ContentCmi5AssignableUnit_Order_By>>;
  where?: Maybe<ContentCmi5AssignableUnit_Bool_Exp>;
};


export type Subscription_RootContentCmi5AssignableUnit_AggregateArgs = {
  distinct_on?: Maybe<Array<ContentCmi5AssignableUnit_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ContentCmi5AssignableUnit_Order_By>>;
  where?: Maybe<ContentCmi5AssignableUnit_Bool_Exp>;
};


export type Subscription_RootContentCmi5AssignableUnit_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootContentCmi5AssignableUnit_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<ContentCmi5AssignableUnit_Stream_Cursor_Input>>;
  where?: Maybe<ContentCmi5AssignableUnit_Bool_Exp>;
};


export type Subscription_RootContentCmi5ObjectiveArgs = {
  distinct_on?: Maybe<Array<ContentCmi5Objective_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ContentCmi5Objective_Order_By>>;
  where?: Maybe<ContentCmi5Objective_Bool_Exp>;
};


export type Subscription_RootContentCmi5Objective_AggregateArgs = {
  distinct_on?: Maybe<Array<ContentCmi5Objective_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ContentCmi5Objective_Order_By>>;
  where?: Maybe<ContentCmi5Objective_Bool_Exp>;
};


export type Subscription_RootContentCmi5Objective_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootContentCmi5Objective_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<ContentCmi5Objective_Stream_Cursor_Input>>;
  where?: Maybe<ContentCmi5Objective_Bool_Exp>;
};


export type Subscription_RootContentCmi5_AggregateArgs = {
  distinct_on?: Maybe<Array<ContentCmi5_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ContentCmi5_Order_By>>;
  where?: Maybe<ContentCmi5_Bool_Exp>;
};


export type Subscription_RootContentCmi5_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootContentCmi5_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<ContentCmi5_Stream_Cursor_Input>>;
  where?: Maybe<ContentCmi5_Bool_Exp>;
};


export type Subscription_RootContentDocumentArgs = {
  distinct_on?: Maybe<Array<ContentDocument_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ContentDocument_Order_By>>;
  where?: Maybe<ContentDocument_Bool_Exp>;
};


export type Subscription_RootContentDocumentUploadArgs = {
  distinct_on?: Maybe<Array<ContentDocumentUpload_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ContentDocumentUpload_Order_By>>;
  where?: Maybe<ContentDocumentUpload_Bool_Exp>;
};


export type Subscription_RootContentDocumentUpload_AggregateArgs = {
  distinct_on?: Maybe<Array<ContentDocumentUpload_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ContentDocumentUpload_Order_By>>;
  where?: Maybe<ContentDocumentUpload_Bool_Exp>;
};


export type Subscription_RootContentDocumentUpload_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootContentDocumentUpload_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<ContentDocumentUpload_Stream_Cursor_Input>>;
  where?: Maybe<ContentDocumentUpload_Bool_Exp>;
};


export type Subscription_RootContentDocument_AggregateArgs = {
  distinct_on?: Maybe<Array<ContentDocument_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ContentDocument_Order_By>>;
  where?: Maybe<ContentDocument_Bool_Exp>;
};


export type Subscription_RootContentDocument_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootContentDocument_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<ContentDocument_Stream_Cursor_Input>>;
  where?: Maybe<ContentDocument_Bool_Exp>;
};


export type Subscription_RootContentEmbedArgs = {
  distinct_on?: Maybe<Array<ContentEmbed_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ContentEmbed_Order_By>>;
  where?: Maybe<ContentEmbed_Bool_Exp>;
};


export type Subscription_RootContentEmbedAudioTranscriptArgs = {
  distinct_on?: Maybe<Array<ContentEmbedAudioTranscript_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ContentEmbedAudioTranscript_Order_By>>;
  where?: Maybe<ContentEmbedAudioTranscript_Bool_Exp>;
};


export type Subscription_RootContentEmbedAudioTranscript_AggregateArgs = {
  distinct_on?: Maybe<Array<ContentEmbedAudioTranscript_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ContentEmbedAudioTranscript_Order_By>>;
  where?: Maybe<ContentEmbedAudioTranscript_Bool_Exp>;
};


export type Subscription_RootContentEmbedAudioTranscript_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootContentEmbedAudioTranscript_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<ContentEmbedAudioTranscript_Stream_Cursor_Input>>;
  where?: Maybe<ContentEmbedAudioTranscript_Bool_Exp>;
};


export type Subscription_RootContentEmbed_AggregateArgs = {
  distinct_on?: Maybe<Array<ContentEmbed_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ContentEmbed_Order_By>>;
  where?: Maybe<ContentEmbed_Bool_Exp>;
};


export type Subscription_RootContentEmbed_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootContentEmbed_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<ContentEmbed_Stream_Cursor_Input>>;
  where?: Maybe<ContentEmbed_Bool_Exp>;
};


export type Subscription_RootContentEventArgs = {
  distinct_on?: Maybe<Array<ContentEvent_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ContentEvent_Order_By>>;
  where?: Maybe<ContentEvent_Bool_Exp>;
};


export type Subscription_RootContentEventAttachmentArgs = {
  distinct_on?: Maybe<Array<ContentEventAttachment_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ContentEventAttachment_Order_By>>;
  where?: Maybe<ContentEventAttachment_Bool_Exp>;
};


export type Subscription_RootContentEventAttachment_AggregateArgs = {
  distinct_on?: Maybe<Array<ContentEventAttachment_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ContentEventAttachment_Order_By>>;
  where?: Maybe<ContentEventAttachment_Bool_Exp>;
};


export type Subscription_RootContentEventAttachment_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootContentEventAttachment_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<ContentEventAttachment_Stream_Cursor_Input>>;
  where?: Maybe<ContentEventAttachment_Bool_Exp>;
};


export type Subscription_RootContentEventSlotArgs = {
  distinct_on?: Maybe<Array<ContentEventSlot_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ContentEventSlot_Order_By>>;
  where?: Maybe<ContentEventSlot_Bool_Exp>;
};


export type Subscription_RootContentEventSlot_AggregateArgs = {
  distinct_on?: Maybe<Array<ContentEventSlot_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ContentEventSlot_Order_By>>;
  where?: Maybe<ContentEventSlot_Bool_Exp>;
};


export type Subscription_RootContentEventSlot_AnimatorArgs = {
  distinct_on?: Maybe<Array<ContentEventSlot_Animator_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ContentEventSlot_Animator_Order_By>>;
  where?: Maybe<ContentEventSlot_Animator_Bool_Exp>;
};


export type Subscription_RootContentEventSlot_Animator_AggregateArgs = {
  distinct_on?: Maybe<Array<ContentEventSlot_Animator_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ContentEventSlot_Animator_Order_By>>;
  where?: Maybe<ContentEventSlot_Animator_Bool_Exp>;
};


export type Subscription_RootContentEventSlot_Animator_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootContentEventSlot_Animator_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<ContentEventSlot_Animator_Stream_Cursor_Input>>;
  where?: Maybe<ContentEventSlot_Animator_Bool_Exp>;
};


export type Subscription_RootContentEventSlot_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootContentEventSlot_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<ContentEventSlot_Stream_Cursor_Input>>;
  where?: Maybe<ContentEventSlot_Bool_Exp>;
};


export type Subscription_RootContentEvent_AggregateArgs = {
  distinct_on?: Maybe<Array<ContentEvent_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ContentEvent_Order_By>>;
  where?: Maybe<ContentEvent_Bool_Exp>;
};


export type Subscription_RootContentEvent_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootContentEvent_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<ContentEvent_Stream_Cursor_Input>>;
  where?: Maybe<ContentEvent_Bool_Exp>;
};


export type Subscription_RootContentPodcastArgs = {
  distinct_on?: Maybe<Array<ContentPodcast_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ContentPodcast_Order_By>>;
  where?: Maybe<ContentPodcast_Bool_Exp>;
};


export type Subscription_RootContentPodcastUploadArgs = {
  distinct_on?: Maybe<Array<ContentPodcastUpload_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ContentPodcastUpload_Order_By>>;
  where?: Maybe<ContentPodcastUpload_Bool_Exp>;
};


export type Subscription_RootContentPodcastUpload_AggregateArgs = {
  distinct_on?: Maybe<Array<ContentPodcastUpload_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ContentPodcastUpload_Order_By>>;
  where?: Maybe<ContentPodcastUpload_Bool_Exp>;
};


export type Subscription_RootContentPodcastUpload_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootContentPodcastUpload_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<ContentPodcastUpload_Stream_Cursor_Input>>;
  where?: Maybe<ContentPodcastUpload_Bool_Exp>;
};


export type Subscription_RootContentPodcast_AggregateArgs = {
  distinct_on?: Maybe<Array<ContentPodcast_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ContentPodcast_Order_By>>;
  where?: Maybe<ContentPodcast_Bool_Exp>;
};


export type Subscription_RootContentPodcast_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootContentPodcast_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<ContentPodcast_Stream_Cursor_Input>>;
  where?: Maybe<ContentPodcast_Bool_Exp>;
};


export type Subscription_RootContentVideoArgs = {
  distinct_on?: Maybe<Array<ContentVideo_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ContentVideo_Order_By>>;
  where?: Maybe<ContentVideo_Bool_Exp>;
};


export type Subscription_RootContentVideoCaptionArgs = {
  distinct_on?: Maybe<Array<ContentVideoCaption_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ContentVideoCaption_Order_By>>;
  where?: Maybe<ContentVideoCaption_Bool_Exp>;
};


export type Subscription_RootContentVideoCaption_AggregateArgs = {
  distinct_on?: Maybe<Array<ContentVideoCaption_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ContentVideoCaption_Order_By>>;
  where?: Maybe<ContentVideoCaption_Bool_Exp>;
};


export type Subscription_RootContentVideoCaption_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootContentVideoCaption_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<ContentVideoCaption_Stream_Cursor_Input>>;
  where?: Maybe<ContentVideoCaption_Bool_Exp>;
};


export type Subscription_RootContentVideoDetailsArgs = {
  distinct_on?: Maybe<Array<ContentVideoDetails_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ContentVideoDetails_Order_By>>;
  where?: Maybe<ContentVideoDetails_Bool_Exp>;
};


export type Subscription_RootContentVideoDetails_AggregateArgs = {
  distinct_on?: Maybe<Array<ContentVideoDetails_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ContentVideoDetails_Order_By>>;
  where?: Maybe<ContentVideoDetails_Bool_Exp>;
};


export type Subscription_RootContentVideoDetails_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootContentVideoDetails_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<ContentVideoDetails_Stream_Cursor_Input>>;
  where?: Maybe<ContentVideoDetails_Bool_Exp>;
};


export type Subscription_RootContentVideo_AggregateArgs = {
  distinct_on?: Maybe<Array<ContentVideo_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ContentVideo_Order_By>>;
  where?: Maybe<ContentVideo_Bool_Exp>;
};


export type Subscription_RootContentVideo_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootContentVideo_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<ContentVideo_Stream_Cursor_Input>>;
  where?: Maybe<ContentVideo_Bool_Exp>;
};


export type Subscription_RootCourseArgs = {
  distinct_on?: Maybe<Array<Course_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Course_Order_By>>;
  where?: Maybe<Course_Bool_Exp>;
};


export type Subscription_RootCourseAssignArgs = {
  distinct_on?: Maybe<Array<CourseAssign_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<CourseAssign_Order_By>>;
  where?: Maybe<CourseAssign_Bool_Exp>;
};


export type Subscription_RootCourseAssignEmployeeArgs = {
  distinct_on?: Maybe<Array<CourseAssignEmployee_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<CourseAssignEmployee_Order_By>>;
  where?: Maybe<CourseAssignEmployee_Bool_Exp>;
};


export type Subscription_RootCourseAssignEmployeeDirectionArgs = {
  distinct_on?: Maybe<Array<CourseAssignEmployeeDirection_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<CourseAssignEmployeeDirection_Order_By>>;
  where?: Maybe<CourseAssignEmployeeDirection_Bool_Exp>;
};


export type Subscription_RootCourseAssignEmployeeDirection_AggregateArgs = {
  distinct_on?: Maybe<Array<CourseAssignEmployeeDirection_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<CourseAssignEmployeeDirection_Order_By>>;
  where?: Maybe<CourseAssignEmployeeDirection_Bool_Exp>;
};


export type Subscription_RootCourseAssignEmployeeDirection_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootCourseAssignEmployeeDirection_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<CourseAssignEmployeeDirection_Stream_Cursor_Input>>;
  where?: Maybe<CourseAssignEmployeeDirection_Bool_Exp>;
};


export type Subscription_RootCourseAssignEmployeeFaArgs = {
  distinct_on?: Maybe<Array<CourseAssignEmployeeFa_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<CourseAssignEmployeeFa_Order_By>>;
  where?: Maybe<CourseAssignEmployeeFa_Bool_Exp>;
};


export type Subscription_RootCourseAssignEmployeeFa_AggregateArgs = {
  distinct_on?: Maybe<Array<CourseAssignEmployeeFa_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<CourseAssignEmployeeFa_Order_By>>;
  where?: Maybe<CourseAssignEmployeeFa_Bool_Exp>;
};


export type Subscription_RootCourseAssignEmployeeFa_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootCourseAssignEmployeeFa_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<CourseAssignEmployeeFa_Stream_Cursor_Input>>;
  where?: Maybe<CourseAssignEmployeeFa_Bool_Exp>;
};


export type Subscription_RootCourseAssignEmployee_AggregateArgs = {
  distinct_on?: Maybe<Array<CourseAssignEmployee_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<CourseAssignEmployee_Order_By>>;
  where?: Maybe<CourseAssignEmployee_Bool_Exp>;
};


export type Subscription_RootCourseAssignEmployee_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootCourseAssignEmployee_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<CourseAssignEmployee_Stream_Cursor_Input>>;
  where?: Maybe<CourseAssignEmployee_Bool_Exp>;
};


export type Subscription_RootCourseAssignGroupArgs = {
  distinct_on?: Maybe<Array<CourseAssignGroup_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<CourseAssignGroup_Order_By>>;
  where?: Maybe<CourseAssignGroup_Bool_Exp>;
};


export type Subscription_RootCourseAssignGroup_AggregateArgs = {
  distinct_on?: Maybe<Array<CourseAssignGroup_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<CourseAssignGroup_Order_By>>;
  where?: Maybe<CourseAssignGroup_Bool_Exp>;
};


export type Subscription_RootCourseAssignGroup_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootCourseAssignGroup_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<CourseAssignGroup_Stream_Cursor_Input>>;
  where?: Maybe<CourseAssignGroup_Bool_Exp>;
};


export type Subscription_RootCourseAssignOtherArgs = {
  distinct_on?: Maybe<Array<CourseAssignOther_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<CourseAssignOther_Order_By>>;
  where?: Maybe<CourseAssignOther_Bool_Exp>;
};


export type Subscription_RootCourseAssignOther_AggregateArgs = {
  distinct_on?: Maybe<Array<CourseAssignOther_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<CourseAssignOther_Order_By>>;
  where?: Maybe<CourseAssignOther_Bool_Exp>;
};


export type Subscription_RootCourseAssignOther_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootCourseAssignOther_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<CourseAssignOther_Stream_Cursor_Input>>;
  where?: Maybe<CourseAssignOther_Bool_Exp>;
};


export type Subscription_RootCourseAssignUserArgs = {
  distinct_on?: Maybe<Array<CourseAssignUser_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<CourseAssignUser_Order_By>>;
  where?: Maybe<CourseAssignUser_Bool_Exp>;
};


export type Subscription_RootCourseAssignUser_AggregateArgs = {
  distinct_on?: Maybe<Array<CourseAssignUser_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<CourseAssignUser_Order_By>>;
  where?: Maybe<CourseAssignUser_Bool_Exp>;
};


export type Subscription_RootCourseAssignUser_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootCourseAssignUser_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<CourseAssignUser_Stream_Cursor_Input>>;
  where?: Maybe<CourseAssignUser_Bool_Exp>;
};


export type Subscription_RootCourseAssignVolunteerArgs = {
  distinct_on?: Maybe<Array<CourseAssignVolunteer_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<CourseAssignVolunteer_Order_By>>;
  where?: Maybe<CourseAssignVolunteer_Bool_Exp>;
};


export type Subscription_RootCourseAssignVolunteerFaArgs = {
  distinct_on?: Maybe<Array<CourseAssignVolunteerFa_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<CourseAssignVolunteerFa_Order_By>>;
  where?: Maybe<CourseAssignVolunteerFa_Bool_Exp>;
};


export type Subscription_RootCourseAssignVolunteerFa_AggregateArgs = {
  distinct_on?: Maybe<Array<CourseAssignVolunteerFa_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<CourseAssignVolunteerFa_Order_By>>;
  where?: Maybe<CourseAssignVolunteerFa_Bool_Exp>;
};


export type Subscription_RootCourseAssignVolunteerFa_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootCourseAssignVolunteerFa_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<CourseAssignVolunteerFa_Stream_Cursor_Input>>;
  where?: Maybe<CourseAssignVolunteerFa_Bool_Exp>;
};


export type Subscription_RootCourseAssignVolunteerJobTitleArgs = {
  distinct_on?: Maybe<Array<CourseAssignVolunteerJobTitle_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<CourseAssignVolunteerJobTitle_Order_By>>;
  where?: Maybe<CourseAssignVolunteerJobTitle_Bool_Exp>;
};


export type Subscription_RootCourseAssignVolunteerJobTitle_AggregateArgs = {
  distinct_on?: Maybe<Array<CourseAssignVolunteerJobTitle_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<CourseAssignVolunteerJobTitle_Order_By>>;
  where?: Maybe<CourseAssignVolunteerJobTitle_Bool_Exp>;
};


export type Subscription_RootCourseAssignVolunteerJobTitle_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootCourseAssignVolunteerJobTitle_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<CourseAssignVolunteerJobTitle_Stream_Cursor_Input>>;
  where?: Maybe<CourseAssignVolunteerJobTitle_Bool_Exp>;
};


export type Subscription_RootCourseAssignVolunteerSiteArgs = {
  distinct_on?: Maybe<Array<CourseAssignVolunteerSite_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<CourseAssignVolunteerSite_Order_By>>;
  where?: Maybe<CourseAssignVolunteerSite_Bool_Exp>;
};


export type Subscription_RootCourseAssignVolunteerSite_AggregateArgs = {
  distinct_on?: Maybe<Array<CourseAssignVolunteerSite_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<CourseAssignVolunteerSite_Order_By>>;
  where?: Maybe<CourseAssignVolunteerSite_Bool_Exp>;
};


export type Subscription_RootCourseAssignVolunteerSite_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootCourseAssignVolunteerSite_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<CourseAssignVolunteerSite_Stream_Cursor_Input>>;
  where?: Maybe<CourseAssignVolunteerSite_Bool_Exp>;
};


export type Subscription_RootCourseAssignVolunteer_AggregateArgs = {
  distinct_on?: Maybe<Array<CourseAssignVolunteer_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<CourseAssignVolunteer_Order_By>>;
  where?: Maybe<CourseAssignVolunteer_Bool_Exp>;
};


export type Subscription_RootCourseAssignVolunteer_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootCourseAssignVolunteer_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<CourseAssignVolunteer_Stream_Cursor_Input>>;
  where?: Maybe<CourseAssignVolunteer_Bool_Exp>;
};


export type Subscription_RootCourseAssign_AggregateArgs = {
  distinct_on?: Maybe<Array<CourseAssign_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<CourseAssign_Order_By>>;
  where?: Maybe<CourseAssign_Bool_Exp>;
};


export type Subscription_RootCourseAssign_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootCourseAssign_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<CourseAssign_Stream_Cursor_Input>>;
  where?: Maybe<CourseAssign_Bool_Exp>;
};


export type Subscription_RootCourse_AggregateArgs = {
  distinct_on?: Maybe<Array<Course_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Course_Order_By>>;
  where?: Maybe<Course_Bool_Exp>;
};


export type Subscription_RootCourse_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootCourse_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Course_Stream_Cursor_Input>>;
  where?: Maybe<Course_Bool_Exp>;
};


export type Subscription_RootD_CourseUserArgs = {
  distinct_on?: Maybe<Array<D_CourseUser_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<D_CourseUser_Order_By>>;
  where?: Maybe<D_CourseUser_Bool_Exp>;
};


export type Subscription_RootD_CourseUserRefreshArgs = {
  distinct_on?: Maybe<Array<D_CourseUserRefresh_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<D_CourseUserRefresh_Order_By>>;
  where?: Maybe<D_CourseUserRefresh_Bool_Exp>;
};


export type Subscription_RootD_CourseUserRefresh_AggregateArgs = {
  distinct_on?: Maybe<Array<D_CourseUserRefresh_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<D_CourseUserRefresh_Order_By>>;
  where?: Maybe<D_CourseUserRefresh_Bool_Exp>;
};


export type Subscription_RootD_CourseUserRefresh_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootD_CourseUserRefresh_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<D_CourseUserRefresh_Stream_Cursor_Input>>;
  where?: Maybe<D_CourseUserRefresh_Bool_Exp>;
};


export type Subscription_RootD_CourseUser_AggregateArgs = {
  distinct_on?: Maybe<Array<D_CourseUser_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<D_CourseUser_Order_By>>;
  where?: Maybe<D_CourseUser_Bool_Exp>;
};


export type Subscription_RootD_CourseUser_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<D_CourseUser_Stream_Cursor_Input>>;
  where?: Maybe<D_CourseUser_Bool_Exp>;
};


export type Subscription_RootDataAccessRequestArgs = {
  distinct_on?: Maybe<Array<DataAccessRequest_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DataAccessRequest_Order_By>>;
  where?: Maybe<DataAccessRequest_Bool_Exp>;
};


export type Subscription_RootDataAccessRequest_AggregateArgs = {
  distinct_on?: Maybe<Array<DataAccessRequest_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DataAccessRequest_Order_By>>;
  where?: Maybe<DataAccessRequest_Bool_Exp>;
};


export type Subscription_RootDataAccessRequest_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootDataAccessRequest_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<DataAccessRequest_Stream_Cursor_Input>>;
  where?: Maybe<DataAccessRequest_Bool_Exp>;
};


export type Subscription_RootDirectionArgs = {
  distinct_on?: Maybe<Array<Direction_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Direction_Order_By>>;
  where?: Maybe<Direction_Bool_Exp>;
};


export type Subscription_RootDirection_AggregateArgs = {
  distinct_on?: Maybe<Array<Direction_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Direction_Order_By>>;
  where?: Maybe<Direction_Bool_Exp>;
};


export type Subscription_RootDirection_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootDirection_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Direction_Stream_Cursor_Input>>;
  where?: Maybe<Direction_Bool_Exp>;
};


export type Subscription_RootEmailValidationTokenArgs = {
  distinct_on?: Maybe<Array<EmailValidationToken_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<EmailValidationToken_Order_By>>;
  where?: Maybe<EmailValidationToken_Bool_Exp>;
};


export type Subscription_RootEmailValidationToken_AggregateArgs = {
  distinct_on?: Maybe<Array<EmailValidationToken_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<EmailValidationToken_Order_By>>;
  where?: Maybe<EmailValidationToken_Bool_Exp>;
};


export type Subscription_RootEmailValidationToken_By_PkArgs = {
  userId: Scalars['uuid'];
};


export type Subscription_RootEmailValidationToken_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<EmailValidationToken_Stream_Cursor_Input>>;
  where?: Maybe<EmailValidationToken_Bool_Exp>;
};


export type Subscription_RootFaArgs = {
  distinct_on?: Maybe<Array<Fa_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Fa_Order_By>>;
  where?: Maybe<Fa_Bool_Exp>;
};


export type Subscription_RootFa_AggregateArgs = {
  distinct_on?: Maybe<Array<Fa_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Fa_Order_By>>;
  where?: Maybe<Fa_Bool_Exp>;
};


export type Subscription_RootFa_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootFa_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Fa_Stream_Cursor_Input>>;
  where?: Maybe<Fa_Bool_Exp>;
};


export type Subscription_RootFaqArgs = {
  distinct_on?: Maybe<Array<Faq_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Faq_Order_By>>;
  where?: Maybe<Faq_Bool_Exp>;
};


export type Subscription_RootFaq_AggregateArgs = {
  distinct_on?: Maybe<Array<Faq_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Faq_Order_By>>;
  where?: Maybe<Faq_Bool_Exp>;
};


export type Subscription_RootFaq_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootFaq_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Faq_Stream_Cursor_Input>>;
  where?: Maybe<Faq_Bool_Exp>;
};


export type Subscription_RootGroupArgs = {
  distinct_on?: Maybe<Array<Group_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Group_Order_By>>;
  where?: Maybe<Group_Bool_Exp>;
};


export type Subscription_RootGroup_AggregateArgs = {
  distinct_on?: Maybe<Array<Group_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Group_Order_By>>;
  where?: Maybe<Group_Bool_Exp>;
};


export type Subscription_RootGroup_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootGroup_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Group_Stream_Cursor_Input>>;
  where?: Maybe<Group_Bool_Exp>;
};


export type Subscription_RootHomeArgs = {
  distinct_on?: Maybe<Array<Home_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Home_Order_By>>;
  where?: Maybe<Home_Bool_Exp>;
};


export type Subscription_RootHome_AggregateArgs = {
  distinct_on?: Maybe<Array<Home_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Home_Order_By>>;
  where?: Maybe<Home_Bool_Exp>;
};


export type Subscription_RootHome_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootHome_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Home_Stream_Cursor_Input>>;
  where?: Maybe<Home_Bool_Exp>;
};


export type Subscription_RootI18nArgs = {
  distinct_on?: Maybe<Array<I18n_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<I18n_Order_By>>;
  where?: Maybe<I18n_Bool_Exp>;
};


export type Subscription_RootI18n_AggregateArgs = {
  distinct_on?: Maybe<Array<I18n_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<I18n_Order_By>>;
  where?: Maybe<I18n_Bool_Exp>;
};


export type Subscription_RootI18n_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootI18n_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<I18n_Stream_Cursor_Input>>;
  where?: Maybe<I18n_Bool_Exp>;
};


export type Subscription_RootIdentityArgs = {
  distinct_on?: Maybe<Array<Identity_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Identity_Order_By>>;
  where?: Maybe<Identity_Bool_Exp>;
};


export type Subscription_RootIdentity_AggregateArgs = {
  distinct_on?: Maybe<Array<Identity_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Identity_Order_By>>;
  where?: Maybe<Identity_Bool_Exp>;
};


export type Subscription_RootIdentity_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootIdentity_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Identity_Stream_Cursor_Input>>;
  where?: Maybe<Identity_Bool_Exp>;
};


export type Subscription_RootImportArgs = {
  distinct_on?: Maybe<Array<Import_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Import_Order_By>>;
  where?: Maybe<Import_Bool_Exp>;
};


export type Subscription_RootImportGroupArgs = {
  distinct_on?: Maybe<Array<ImportGroup_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ImportGroup_Order_By>>;
  where?: Maybe<ImportGroup_Bool_Exp>;
};


export type Subscription_RootImportGroup_AggregateArgs = {
  distinct_on?: Maybe<Array<ImportGroup_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ImportGroup_Order_By>>;
  where?: Maybe<ImportGroup_Bool_Exp>;
};


export type Subscription_RootImportGroup_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootImportGroup_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<ImportGroup_Stream_Cursor_Input>>;
  where?: Maybe<ImportGroup_Bool_Exp>;
};


export type Subscription_RootImportSkillArgs = {
  distinct_on?: Maybe<Array<ImportSkill_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ImportSkill_Order_By>>;
  where?: Maybe<ImportSkill_Bool_Exp>;
};


export type Subscription_RootImportSkill_AggregateArgs = {
  distinct_on?: Maybe<Array<ImportSkill_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ImportSkill_Order_By>>;
  where?: Maybe<ImportSkill_Bool_Exp>;
};


export type Subscription_RootImportSkill_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootImportSkill_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<ImportSkill_Stream_Cursor_Input>>;
  where?: Maybe<ImportSkill_Bool_Exp>;
};


export type Subscription_RootImport_AggregateArgs = {
  distinct_on?: Maybe<Array<Import_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Import_Order_By>>;
  where?: Maybe<Import_Bool_Exp>;
};


export type Subscription_RootImport_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootImport_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Import_Stream_Cursor_Input>>;
  where?: Maybe<Import_Bool_Exp>;
};


export type Subscription_RootJobTitleArgs = {
  distinct_on?: Maybe<Array<JobTitle_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<JobTitle_Order_By>>;
  where?: Maybe<JobTitle_Bool_Exp>;
};


export type Subscription_RootJobTitle_AggregateArgs = {
  distinct_on?: Maybe<Array<JobTitle_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<JobTitle_Order_By>>;
  where?: Maybe<JobTitle_Bool_Exp>;
};


export type Subscription_RootJobTitle_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootJobTitle_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<JobTitle_Stream_Cursor_Input>>;
  where?: Maybe<JobTitle_Bool_Exp>;
};


export type Subscription_RootModuleArgs = {
  distinct_on?: Maybe<Array<Module_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Module_Order_By>>;
  where?: Maybe<Module_Bool_Exp>;
};


export type Subscription_RootModuleAnnexArgs = {
  distinct_on?: Maybe<Array<ModuleAnnex_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ModuleAnnex_Order_By>>;
  where?: Maybe<ModuleAnnex_Bool_Exp>;
};


export type Subscription_RootModuleAnnex_AggregateArgs = {
  distinct_on?: Maybe<Array<ModuleAnnex_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ModuleAnnex_Order_By>>;
  where?: Maybe<ModuleAnnex_Bool_Exp>;
};


export type Subscription_RootModuleAnnex_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootModuleAnnex_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<ModuleAnnex_Stream_Cursor_Input>>;
  where?: Maybe<ModuleAnnex_Bool_Exp>;
};


export type Subscription_RootModuleContentArgs = {
  distinct_on?: Maybe<Array<ModuleContent_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ModuleContent_Order_By>>;
  where?: Maybe<ModuleContent_Bool_Exp>;
};


export type Subscription_RootModuleContent_AggregateArgs = {
  distinct_on?: Maybe<Array<ModuleContent_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ModuleContent_Order_By>>;
  where?: Maybe<ModuleContent_Bool_Exp>;
};


export type Subscription_RootModuleContent_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootModuleContent_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<ModuleContent_Stream_Cursor_Input>>;
  where?: Maybe<ModuleContent_Bool_Exp>;
};


export type Subscription_RootModuleTaskArgs = {
  distinct_on?: Maybe<Array<ModuleTask_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ModuleTask_Order_By>>;
  where?: Maybe<ModuleTask_Bool_Exp>;
};


export type Subscription_RootModuleTask_AggregateArgs = {
  distinct_on?: Maybe<Array<ModuleTask_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ModuleTask_Order_By>>;
  where?: Maybe<ModuleTask_Bool_Exp>;
};


export type Subscription_RootModuleTask_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootModuleTask_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<ModuleTask_Stream_Cursor_Input>>;
  where?: Maybe<ModuleTask_Bool_Exp>;
};


export type Subscription_RootModule_AggregateArgs = {
  distinct_on?: Maybe<Array<Module_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Module_Order_By>>;
  where?: Maybe<Module_Bool_Exp>;
};


export type Subscription_RootModule_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootModule_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Module_Stream_Cursor_Input>>;
  where?: Maybe<Module_Bool_Exp>;
};


export type Subscription_RootOrganisationArgs = {
  distinct_on?: Maybe<Array<Organisation_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Organisation_Order_By>>;
  where?: Maybe<Organisation_Bool_Exp>;
};


export type Subscription_RootOrganisation_AggregateArgs = {
  distinct_on?: Maybe<Array<Organisation_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Organisation_Order_By>>;
  where?: Maybe<Organisation_Bool_Exp>;
};


export type Subscription_RootOrganisation_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootOrganisation_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Organisation_Stream_Cursor_Input>>;
  where?: Maybe<Organisation_Bool_Exp>;
};


export type Subscription_RootPasswordResetTokenArgs = {
  distinct_on?: Maybe<Array<PasswordResetToken_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<PasswordResetToken_Order_By>>;
  where?: Maybe<PasswordResetToken_Bool_Exp>;
};


export type Subscription_RootPasswordResetToken_AggregateArgs = {
  distinct_on?: Maybe<Array<PasswordResetToken_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<PasswordResetToken_Order_By>>;
  where?: Maybe<PasswordResetToken_Bool_Exp>;
};


export type Subscription_RootPasswordResetToken_By_PkArgs = {
  passwordResetToken: Scalars['String'];
};


export type Subscription_RootPasswordResetToken_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<PasswordResetToken_Stream_Cursor_Input>>;
  where?: Maybe<PasswordResetToken_Bool_Exp>;
};


export type Subscription_RootRefreshTokenArgs = {
  distinct_on?: Maybe<Array<RefreshToken_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<RefreshToken_Order_By>>;
  where?: Maybe<RefreshToken_Bool_Exp>;
};


export type Subscription_RootRefreshToken_AggregateArgs = {
  distinct_on?: Maybe<Array<RefreshToken_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<RefreshToken_Order_By>>;
  where?: Maybe<RefreshToken_Bool_Exp>;
};


export type Subscription_RootRefreshToken_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootRefreshToken_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<RefreshToken_Stream_Cursor_Input>>;
  where?: Maybe<RefreshToken_Bool_Exp>;
};


export type Subscription_RootRoleArgs = {
  distinct_on?: Maybe<Array<Role_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Role_Order_By>>;
  where?: Maybe<Role_Bool_Exp>;
};


export type Subscription_RootRole_AggregateArgs = {
  distinct_on?: Maybe<Array<Role_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Role_Order_By>>;
  where?: Maybe<Role_Bool_Exp>;
};


export type Subscription_RootRole_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootRole_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Role_Stream_Cursor_Input>>;
  where?: Maybe<Role_Bool_Exp>;
};


export type Subscription_RootSequenceArgs = {
  distinct_on?: Maybe<Array<Sequence_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Sequence_Order_By>>;
  where?: Maybe<Sequence_Bool_Exp>;
};


export type Subscription_RootSequence_AggregateArgs = {
  distinct_on?: Maybe<Array<Sequence_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Sequence_Order_By>>;
  where?: Maybe<Sequence_Bool_Exp>;
};


export type Subscription_RootSequence_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootSequence_ModuleArgs = {
  distinct_on?: Maybe<Array<Sequence_Module_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Sequence_Module_Order_By>>;
  where?: Maybe<Sequence_Module_Bool_Exp>;
};


export type Subscription_RootSequence_Module_AggregateArgs = {
  distinct_on?: Maybe<Array<Sequence_Module_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Sequence_Module_Order_By>>;
  where?: Maybe<Sequence_Module_Bool_Exp>;
};


export type Subscription_RootSequence_Module_By_PkArgs = {
  moduleId: Scalars['uuid'];
  sequenceId: Scalars['uuid'];
};


export type Subscription_RootSequence_Module_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Sequence_Module_Stream_Cursor_Input>>;
  where?: Maybe<Sequence_Module_Bool_Exp>;
};


export type Subscription_RootSequence_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Sequence_Stream_Cursor_Input>>;
  where?: Maybe<Sequence_Bool_Exp>;
};


export type Subscription_RootSiteArgs = {
  distinct_on?: Maybe<Array<Site_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Site_Order_By>>;
  where?: Maybe<Site_Bool_Exp>;
};


export type Subscription_RootSite_AggregateArgs = {
  distinct_on?: Maybe<Array<Site_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Site_Order_By>>;
  where?: Maybe<Site_Bool_Exp>;
};


export type Subscription_RootSite_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootSite_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Site_Stream_Cursor_Input>>;
  where?: Maybe<Site_Bool_Exp>;
};


export type Subscription_RootSkillArgs = {
  distinct_on?: Maybe<Array<Skill_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Skill_Order_By>>;
  where?: Maybe<Skill_Bool_Exp>;
};


export type Subscription_RootSkillLevelArgs = {
  distinct_on?: Maybe<Array<SkillLevel_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<SkillLevel_Order_By>>;
  where?: Maybe<SkillLevel_Bool_Exp>;
};


export type Subscription_RootSkillLevel_AggregateArgs = {
  distinct_on?: Maybe<Array<SkillLevel_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<SkillLevel_Order_By>>;
  where?: Maybe<SkillLevel_Bool_Exp>;
};


export type Subscription_RootSkillLevel_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootSkillLevel_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<SkillLevel_Stream_Cursor_Input>>;
  where?: Maybe<SkillLevel_Bool_Exp>;
};


export type Subscription_RootSkill_AggregateArgs = {
  distinct_on?: Maybe<Array<Skill_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Skill_Order_By>>;
  where?: Maybe<Skill_Bool_Exp>;
};


export type Subscription_RootSkill_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootSkill_Of_CourseArgs = {
  distinct_on?: Maybe<Array<Skill_Of_Course_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Skill_Of_Course_Order_By>>;
  where?: Maybe<Skill_Of_Course_Bool_Exp>;
};


export type Subscription_RootSkill_Of_Course_AggregateArgs = {
  distinct_on?: Maybe<Array<Skill_Of_Course_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Skill_Of_Course_Order_By>>;
  where?: Maybe<Skill_Of_Course_Bool_Exp>;
};


export type Subscription_RootSkill_Of_Course_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootSkill_Of_Course_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Skill_Of_Course_Stream_Cursor_Input>>;
  where?: Maybe<Skill_Of_Course_Bool_Exp>;
};


export type Subscription_RootSkill_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Skill_Stream_Cursor_Input>>;
  where?: Maybe<Skill_Bool_Exp>;
};


export type Subscription_RootTag_CourseArgs = {
  distinct_on?: Maybe<Array<Tag_Course_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Tag_Course_Order_By>>;
  where?: Maybe<Tag_Course_Bool_Exp>;
};


export type Subscription_RootTag_Course_AggregateArgs = {
  distinct_on?: Maybe<Array<Tag_Course_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Tag_Course_Order_By>>;
  where?: Maybe<Tag_Course_Bool_Exp>;
};


export type Subscription_RootTag_Course_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootTag_Course_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Tag_Course_Stream_Cursor_Input>>;
  where?: Maybe<Tag_Course_Bool_Exp>;
};


export type Subscription_RootTag_Of_CourseArgs = {
  distinct_on?: Maybe<Array<Tag_Of_Course_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Tag_Of_Course_Order_By>>;
  where?: Maybe<Tag_Of_Course_Bool_Exp>;
};


export type Subscription_RootTag_Of_Course_AggregateArgs = {
  distinct_on?: Maybe<Array<Tag_Of_Course_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Tag_Of_Course_Order_By>>;
  where?: Maybe<Tag_Of_Course_Bool_Exp>;
};


export type Subscription_RootTag_Of_Course_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootTag_Of_Course_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Tag_Of_Course_Stream_Cursor_Input>>;
  where?: Maybe<Tag_Of_Course_Bool_Exp>;
};


export type Subscription_RootThematicArgs = {
  distinct_on?: Maybe<Array<Thematic_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Thematic_Order_By>>;
  where?: Maybe<Thematic_Bool_Exp>;
};


export type Subscription_RootThematic_AggregateArgs = {
  distinct_on?: Maybe<Array<Thematic_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Thematic_Order_By>>;
  where?: Maybe<Thematic_Bool_Exp>;
};


export type Subscription_RootThematic_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootThematic_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Thematic_Stream_Cursor_Input>>;
  where?: Maybe<Thematic_Bool_Exp>;
};


export type Subscription_RootTranslationArgs = {
  distinct_on?: Maybe<Array<Translation_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Translation_Order_By>>;
  where?: Maybe<Translation_Bool_Exp>;
};


export type Subscription_RootTranslation_AggregateArgs = {
  distinct_on?: Maybe<Array<Translation_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Translation_Order_By>>;
  where?: Maybe<Translation_Bool_Exp>;
};


export type Subscription_RootTranslation_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootTranslation_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Translation_Stream_Cursor_Input>>;
  where?: Maybe<Translation_Bool_Exp>;
};


export type Subscription_RootUserArgs = {
  distinct_on?: Maybe<Array<User_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<User_Order_By>>;
  where?: Maybe<User_Bool_Exp>;
};


export type Subscription_RootUserCourseProgressArgs = {
  distinct_on?: Maybe<Array<UserCourseProgress_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<UserCourseProgress_Order_By>>;
  where?: Maybe<UserCourseProgress_Bool_Exp>;
};


export type Subscription_RootUserCourseProgress_AggregateArgs = {
  distinct_on?: Maybe<Array<UserCourseProgress_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<UserCourseProgress_Order_By>>;
  where?: Maybe<UserCourseProgress_Bool_Exp>;
};


export type Subscription_RootUserCourseProgress_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootUserCourseProgress_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<UserCourseProgress_Stream_Cursor_Input>>;
  where?: Maybe<UserCourseProgress_Bool_Exp>;
};


export type Subscription_RootUserEventSlotArgs = {
  distinct_on?: Maybe<Array<UserEventSlot_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<UserEventSlot_Order_By>>;
  where?: Maybe<UserEventSlot_Bool_Exp>;
};


export type Subscription_RootUserEventSlot_AggregateArgs = {
  distinct_on?: Maybe<Array<UserEventSlot_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<UserEventSlot_Order_By>>;
  where?: Maybe<UserEventSlot_Bool_Exp>;
};


export type Subscription_RootUserEventSlot_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootUserEventSlot_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<UserEventSlot_Stream_Cursor_Input>>;
  where?: Maybe<UserEventSlot_Bool_Exp>;
};


export type Subscription_RootUserGroupArgs = {
  distinct_on?: Maybe<Array<UserGroup_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<UserGroup_Order_By>>;
  where?: Maybe<UserGroup_Bool_Exp>;
};


export type Subscription_RootUserGroup_AggregateArgs = {
  distinct_on?: Maybe<Array<UserGroup_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<UserGroup_Order_By>>;
  where?: Maybe<UserGroup_Bool_Exp>;
};


export type Subscription_RootUserGroup_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootUserGroup_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<UserGroup_Stream_Cursor_Input>>;
  where?: Maybe<UserGroup_Bool_Exp>;
};


export type Subscription_RootUserModuleProgressArgs = {
  distinct_on?: Maybe<Array<UserModuleProgress_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<UserModuleProgress_Order_By>>;
  where?: Maybe<UserModuleProgress_Bool_Exp>;
};


export type Subscription_RootUserModuleProgress_AggregateArgs = {
  distinct_on?: Maybe<Array<UserModuleProgress_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<UserModuleProgress_Order_By>>;
  where?: Maybe<UserModuleProgress_Bool_Exp>;
};


export type Subscription_RootUserModuleProgress_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootUserModuleProgress_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<UserModuleProgress_Stream_Cursor_Input>>;
  where?: Maybe<UserModuleProgress_Bool_Exp>;
};


export type Subscription_RootUserPrivateArgs = {
  distinct_on?: Maybe<Array<UserPrivate_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<UserPrivate_Order_By>>;
  where?: Maybe<UserPrivate_Bool_Exp>;
};


export type Subscription_RootUserPrivate_AggregateArgs = {
  distinct_on?: Maybe<Array<UserPrivate_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<UserPrivate_Order_By>>;
  where?: Maybe<UserPrivate_Bool_Exp>;
};


export type Subscription_RootUserPrivate_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<UserPrivate_Stream_Cursor_Input>>;
  where?: Maybe<UserPrivate_Bool_Exp>;
};


export type Subscription_RootUserSkillArgs = {
  distinct_on?: Maybe<Array<UserSkill_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<UserSkill_Order_By>>;
  where?: Maybe<UserSkill_Bool_Exp>;
};


export type Subscription_RootUserSkill_AggregateArgs = {
  distinct_on?: Maybe<Array<UserSkill_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<UserSkill_Order_By>>;
  where?: Maybe<UserSkill_Bool_Exp>;
};


export type Subscription_RootUserSkill_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootUserSkill_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<UserSkill_Stream_Cursor_Input>>;
  where?: Maybe<UserSkill_Bool_Exp>;
};


export type Subscription_RootUser_AggregateArgs = {
  distinct_on?: Maybe<Array<User_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<User_Order_By>>;
  where?: Maybe<User_Bool_Exp>;
};


export type Subscription_RootUser_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootUser_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<User_Stream_Cursor_Input>>;
  where?: Maybe<User_Bool_Exp>;
};


export type Subscription_RootVolunteerPostArgs = {
  distinct_on?: Maybe<Array<VolunteerPost_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<VolunteerPost_Order_By>>;
  where?: Maybe<VolunteerPost_Bool_Exp>;
};


export type Subscription_RootVolunteerPost_AggregateArgs = {
  distinct_on?: Maybe<Array<VolunteerPost_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<VolunteerPost_Order_By>>;
  where?: Maybe<VolunteerPost_Bool_Exp>;
};


export type Subscription_RootVolunteerPost_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootVolunteerPost_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<VolunteerPost_Stream_Cursor_Input>>;
  where?: Maybe<VolunteerPost_Bool_Exp>;
};


export type Subscription_RootXapi_StatementArgs = {
  distinct_on?: Maybe<Array<Xapi_Statement_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Xapi_Statement_Order_By>>;
  where?: Maybe<Xapi_Statement_Bool_Exp>;
};


export type Subscription_RootXapi_Statement_AggregateArgs = {
  distinct_on?: Maybe<Array<Xapi_Statement_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Xapi_Statement_Order_By>>;
  where?: Maybe<Xapi_Statement_Bool_Exp>;
};


export type Subscription_RootXapi_Statement_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootXapi_Statement_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Xapi_Statement_Stream_Cursor_Input>>;
  where?: Maybe<Xapi_Statement_Bool_Exp>;
};

/** columns and relationships of "tag_course" */
export type Tag_Course = {
  __typename?: 'tag_course';
  createdAt: Scalars['timestamptz'];
  disabledAt?: Maybe<Scalars['timestamptz']>;
  id: Scalars['uuid'];
  /** An object relationship */
  name?: Maybe<I18n>;
  /** A computed field, executes function "tag_course_name_i18n" */
  nameI18n?: Maybe<Scalars['String']>;
  nameI18nId?: Maybe<Scalars['uuid']>;
  /** A computed field, executes function "tag_course_name_unaccent" */
  unaccentNameI18n?: Maybe<Scalars['String']>;
  updatedAt: Scalars['timestamptz'];
};

/** aggregated selection of "tag_course" */
export type Tag_Course_Aggregate = {
  __typename?: 'tag_course_aggregate';
  aggregate?: Maybe<Tag_Course_Aggregate_Fields>;
  nodes: Array<Tag_Course>;
};

/** aggregate fields of "tag_course" */
export type Tag_Course_Aggregate_Fields = {
  __typename?: 'tag_course_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Tag_Course_Max_Fields>;
  min?: Maybe<Tag_Course_Min_Fields>;
};


/** aggregate fields of "tag_course" */
export type Tag_Course_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Tag_Course_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "tag_course". All fields are combined with a logical 'AND'. */
export type Tag_Course_Bool_Exp = {
  _and?: Maybe<Array<Tag_Course_Bool_Exp>>;
  _not?: Maybe<Tag_Course_Bool_Exp>;
  _or?: Maybe<Array<Tag_Course_Bool_Exp>>;
  createdAt?: Maybe<Timestamptz_Comparison_Exp>;
  disabledAt?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  name?: Maybe<I18n_Bool_Exp>;
  nameI18n?: Maybe<String_Comparison_Exp>;
  nameI18nId?: Maybe<Uuid_Comparison_Exp>;
  unaccentNameI18n?: Maybe<String_Comparison_Exp>;
  updatedAt?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "tag_course" */
export enum Tag_Course_Constraint {
  /** unique or primary key constraint on columns "id" */
  TagCoursePkey = 'tag_course_pkey'
}

/** input type for inserting data into table "tag_course" */
export type Tag_Course_Insert_Input = {
  createdAt?: Maybe<Scalars['timestamptz']>;
  disabledAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<I18n_Obj_Rel_Insert_Input>;
  nameI18nId?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Tag_Course_Max_Fields = {
  __typename?: 'tag_course_max_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  disabledAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  nameI18nId?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Tag_Course_Min_Fields = {
  __typename?: 'tag_course_min_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  disabledAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  nameI18nId?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "tag_course" */
export type Tag_Course_Mutation_Response = {
  __typename?: 'tag_course_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Tag_Course>;
};

/** on_conflict condition type for table "tag_course" */
export type Tag_Course_On_Conflict = {
  constraint: Tag_Course_Constraint;
  update_columns?: Array<Tag_Course_Update_Column>;
  where?: Maybe<Tag_Course_Bool_Exp>;
};

/** Ordering options when selecting data from "tag_course". */
export type Tag_Course_Order_By = {
  createdAt?: Maybe<Order_By>;
  disabledAt?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  name?: Maybe<I18n_Order_By>;
  nameI18n?: Maybe<Order_By>;
  nameI18nId?: Maybe<Order_By>;
  unaccentNameI18n?: Maybe<Order_By>;
  updatedAt?: Maybe<Order_By>;
};

/** primary key columns input for table: tag_course */
export type Tag_Course_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "tag_course" */
export enum Tag_Course_Select_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  DisabledAt = 'disabledAt',
  /** column name */
  Id = 'id',
  /** column name */
  NameI18nId = 'nameI18nId',
  /** column name */
  UpdatedAt = 'updatedAt'
}

/** input type for updating data in table "tag_course" */
export type Tag_Course_Set_Input = {
  createdAt?: Maybe<Scalars['timestamptz']>;
  disabledAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  nameI18nId?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "tag_course" */
export type Tag_Course_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Tag_Course_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Tag_Course_Stream_Cursor_Value_Input = {
  createdAt?: Maybe<Scalars['timestamptz']>;
  disabledAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  nameI18nId?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** update columns of table "tag_course" */
export enum Tag_Course_Update_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  DisabledAt = 'disabledAt',
  /** column name */
  Id = 'id',
  /** column name */
  NameI18nId = 'nameI18nId',
  /** column name */
  UpdatedAt = 'updatedAt'
}

export type Tag_Course_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Tag_Course_Set_Input>;
  /** filter the rows which have to be updated */
  where: Tag_Course_Bool_Exp;
};

/** columns and relationships of "tag_of_course" */
export type Tag_Of_Course = {
  __typename?: 'tag_of_course';
  courseId: Scalars['uuid'];
  id: Scalars['uuid'];
  tagCourseId: Scalars['uuid'];
};

/** aggregated selection of "tag_of_course" */
export type Tag_Of_Course_Aggregate = {
  __typename?: 'tag_of_course_aggregate';
  aggregate?: Maybe<Tag_Of_Course_Aggregate_Fields>;
  nodes: Array<Tag_Of_Course>;
};

export type Tag_Of_Course_Aggregate_Bool_Exp = {
  count?: Maybe<Tag_Of_Course_Aggregate_Bool_Exp_Count>;
};

export type Tag_Of_Course_Aggregate_Bool_Exp_Count = {
  arguments?: Maybe<Array<Tag_Of_Course_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Tag_Of_Course_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "tag_of_course" */
export type Tag_Of_Course_Aggregate_Fields = {
  __typename?: 'tag_of_course_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Tag_Of_Course_Max_Fields>;
  min?: Maybe<Tag_Of_Course_Min_Fields>;
};


/** aggregate fields of "tag_of_course" */
export type Tag_Of_Course_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Tag_Of_Course_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "tag_of_course" */
export type Tag_Of_Course_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Tag_Of_Course_Max_Order_By>;
  min?: Maybe<Tag_Of_Course_Min_Order_By>;
};

/** input type for inserting array relation for remote table "tag_of_course" */
export type Tag_Of_Course_Arr_Rel_Insert_Input = {
  data: Array<Tag_Of_Course_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Tag_Of_Course_On_Conflict>;
};

/** Boolean expression to filter rows from the table "tag_of_course". All fields are combined with a logical 'AND'. */
export type Tag_Of_Course_Bool_Exp = {
  _and?: Maybe<Array<Tag_Of_Course_Bool_Exp>>;
  _not?: Maybe<Tag_Of_Course_Bool_Exp>;
  _or?: Maybe<Array<Tag_Of_Course_Bool_Exp>>;
  courseId?: Maybe<Uuid_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  tagCourseId?: Maybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "tag_of_course" */
export enum Tag_Of_Course_Constraint {
  /** unique or primary key constraint on columns "id" */
  TagOfCoursePkey = 'tag_of_course_pkey',
  /** unique or primary key constraint on columns "tagCourseId", "courseId" */
  TagOfCourseTagCourseIdCourseIdKey = 'tag_of_course_tagCourseId_courseId_key'
}

/** input type for inserting data into table "tag_of_course" */
export type Tag_Of_Course_Insert_Input = {
  courseId?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  tagCourseId?: Maybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Tag_Of_Course_Max_Fields = {
  __typename?: 'tag_of_course_max_fields';
  courseId?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  tagCourseId?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "tag_of_course" */
export type Tag_Of_Course_Max_Order_By = {
  courseId?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  tagCourseId?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Tag_Of_Course_Min_Fields = {
  __typename?: 'tag_of_course_min_fields';
  courseId?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  tagCourseId?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "tag_of_course" */
export type Tag_Of_Course_Min_Order_By = {
  courseId?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  tagCourseId?: Maybe<Order_By>;
};

/** response of any mutation on the table "tag_of_course" */
export type Tag_Of_Course_Mutation_Response = {
  __typename?: 'tag_of_course_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Tag_Of_Course>;
};

/** on_conflict condition type for table "tag_of_course" */
export type Tag_Of_Course_On_Conflict = {
  constraint: Tag_Of_Course_Constraint;
  update_columns?: Array<Tag_Of_Course_Update_Column>;
  where?: Maybe<Tag_Of_Course_Bool_Exp>;
};

/** Ordering options when selecting data from "tag_of_course". */
export type Tag_Of_Course_Order_By = {
  courseId?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  tagCourseId?: Maybe<Order_By>;
};

/** primary key columns input for table: tag_of_course */
export type Tag_Of_Course_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "tag_of_course" */
export enum Tag_Of_Course_Select_Column {
  /** column name */
  CourseId = 'courseId',
  /** column name */
  Id = 'id',
  /** column name */
  TagCourseId = 'tagCourseId'
}

/** input type for updating data in table "tag_of_course" */
export type Tag_Of_Course_Set_Input = {
  courseId?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  tagCourseId?: Maybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "tag_of_course" */
export type Tag_Of_Course_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Tag_Of_Course_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Tag_Of_Course_Stream_Cursor_Value_Input = {
  courseId?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  tagCourseId?: Maybe<Scalars['uuid']>;
};

/** update columns of table "tag_of_course" */
export enum Tag_Of_Course_Update_Column {
  /** column name */
  CourseId = 'courseId',
  /** column name */
  Id = 'id',
  /** column name */
  TagCourseId = 'tagCourseId'
}

export type Tag_Of_Course_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Tag_Of_Course_Set_Input>;
  /** filter the rows which have to be updated */
  where: Tag_Of_Course_Bool_Exp;
};

/** columns and relationships of "thematic" */
export type Thematic = {
  __typename?: 'thematic';
  /** An array relationship */
  courses: Array<Course>;
  /** An aggregate relationship */
  courses_aggregate: Course_Aggregate;
  createdAt: Scalars['timestamptz'];
  disabledAt?: Maybe<Scalars['timestamptz']>;
  /** An object relationship */
  i18n: I18n;
  id: Scalars['uuid'];
  /** A computed field, executes function "label_i18n" */
  labelI18n?: Maybe<Scalars['String']>;
  labelI18nId: Scalars['uuid'];
  /** A computed field, executes function "thematic_label_unaccent" */
  unaccentLabelI18n?: Maybe<Scalars['String']>;
  updatedAt: Scalars['timestamptz'];
};


/** columns and relationships of "thematic" */
export type ThematicCoursesArgs = {
  distinct_on?: Maybe<Array<Course_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Course_Order_By>>;
  where?: Maybe<Course_Bool_Exp>;
};


/** columns and relationships of "thematic" */
export type ThematicCourses_AggregateArgs = {
  distinct_on?: Maybe<Array<Course_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Course_Order_By>>;
  where?: Maybe<Course_Bool_Exp>;
};

/** aggregated selection of "thematic" */
export type Thematic_Aggregate = {
  __typename?: 'thematic_aggregate';
  aggregate?: Maybe<Thematic_Aggregate_Fields>;
  nodes: Array<Thematic>;
};

/** aggregate fields of "thematic" */
export type Thematic_Aggregate_Fields = {
  __typename?: 'thematic_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Thematic_Max_Fields>;
  min?: Maybe<Thematic_Min_Fields>;
};


/** aggregate fields of "thematic" */
export type Thematic_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Thematic_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "thematic". All fields are combined with a logical 'AND'. */
export type Thematic_Bool_Exp = {
  _and?: Maybe<Array<Thematic_Bool_Exp>>;
  _not?: Maybe<Thematic_Bool_Exp>;
  _or?: Maybe<Array<Thematic_Bool_Exp>>;
  courses?: Maybe<Course_Bool_Exp>;
  courses_aggregate?: Maybe<Course_Aggregate_Bool_Exp>;
  createdAt?: Maybe<Timestamptz_Comparison_Exp>;
  disabledAt?: Maybe<Timestamptz_Comparison_Exp>;
  i18n?: Maybe<I18n_Bool_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  labelI18n?: Maybe<String_Comparison_Exp>;
  labelI18nId?: Maybe<Uuid_Comparison_Exp>;
  unaccentLabelI18n?: Maybe<String_Comparison_Exp>;
  updatedAt?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "thematic" */
export enum Thematic_Constraint {
  /** unique or primary key constraint on columns "id" */
  ThematicPkey = 'thematic_pkey'
}

/** input type for inserting data into table "thematic" */
export type Thematic_Insert_Input = {
  courses?: Maybe<Course_Arr_Rel_Insert_Input>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  disabledAt?: Maybe<Scalars['timestamptz']>;
  i18n?: Maybe<I18n_Obj_Rel_Insert_Input>;
  id?: Maybe<Scalars['uuid']>;
  labelI18nId?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Thematic_Max_Fields = {
  __typename?: 'thematic_max_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  disabledAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  labelI18nId?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Thematic_Min_Fields = {
  __typename?: 'thematic_min_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  disabledAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  labelI18nId?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "thematic" */
export type Thematic_Mutation_Response = {
  __typename?: 'thematic_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Thematic>;
};

/** input type for inserting object relation for remote table "thematic" */
export type Thematic_Obj_Rel_Insert_Input = {
  data: Thematic_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Thematic_On_Conflict>;
};

/** on_conflict condition type for table "thematic" */
export type Thematic_On_Conflict = {
  constraint: Thematic_Constraint;
  update_columns?: Array<Thematic_Update_Column>;
  where?: Maybe<Thematic_Bool_Exp>;
};

/** Ordering options when selecting data from "thematic". */
export type Thematic_Order_By = {
  courses_aggregate?: Maybe<Course_Aggregate_Order_By>;
  createdAt?: Maybe<Order_By>;
  disabledAt?: Maybe<Order_By>;
  i18n?: Maybe<I18n_Order_By>;
  id?: Maybe<Order_By>;
  labelI18n?: Maybe<Order_By>;
  labelI18nId?: Maybe<Order_By>;
  unaccentLabelI18n?: Maybe<Order_By>;
  updatedAt?: Maybe<Order_By>;
};

/** primary key columns input for table: thematic */
export type Thematic_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "thematic" */
export enum Thematic_Select_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  DisabledAt = 'disabledAt',
  /** column name */
  Id = 'id',
  /** column name */
  LabelI18nId = 'labelI18nId',
  /** column name */
  UpdatedAt = 'updatedAt'
}

/** input type for updating data in table "thematic" */
export type Thematic_Set_Input = {
  createdAt?: Maybe<Scalars['timestamptz']>;
  disabledAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  labelI18nId?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "thematic" */
export type Thematic_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Thematic_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Thematic_Stream_Cursor_Value_Input = {
  createdAt?: Maybe<Scalars['timestamptz']>;
  disabledAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  labelI18nId?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** update columns of table "thematic" */
export enum Thematic_Update_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  DisabledAt = 'disabledAt',
  /** column name */
  Id = 'id',
  /** column name */
  LabelI18nId = 'labelI18nId',
  /** column name */
  UpdatedAt = 'updatedAt'
}

export type Thematic_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Thematic_Set_Input>;
  /** filter the rows which have to be updated */
  where: Thematic_Bool_Exp;
};


/** Boolean expression to compare columns of type "timestamp". All fields are combined with logical 'AND'. */
export type Timestamp_Comparison_Exp = {
  _eq?: Maybe<Scalars['timestamp']>;
  _gt?: Maybe<Scalars['timestamp']>;
  _gte?: Maybe<Scalars['timestamp']>;
  _in?: Maybe<Array<Scalars['timestamp']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['timestamp']>;
  _lte?: Maybe<Scalars['timestamp']>;
  _neq?: Maybe<Scalars['timestamp']>;
  _nin?: Maybe<Array<Scalars['timestamp']>>;
};


/** Boolean expression to compare columns of type "timestamptz". All fields are combined with logical 'AND'. */
export type Timestamptz_Comparison_Exp = {
  _eq?: Maybe<Scalars['timestamptz']>;
  _gt?: Maybe<Scalars['timestamptz']>;
  _gte?: Maybe<Scalars['timestamptz']>;
  _in?: Maybe<Array<Scalars['timestamptz']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['timestamptz']>;
  _lte?: Maybe<Scalars['timestamptz']>;
  _neq?: Maybe<Scalars['timestamptz']>;
  _nin?: Maybe<Array<Scalars['timestamptz']>>;
};

/** columns and relationships of "translation" */
export type Translation = {
  __typename?: 'translation';
  createdAt?: Maybe<Scalars['timestamptz']>;
  /** An object relationship */
  i18n: I18n;
  i18nId: Scalars['uuid'];
  id: Scalars['uuid'];
  locale: Scalars['String'];
  updatedAt?: Maybe<Scalars['timestamptz']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregated selection of "translation" */
export type Translation_Aggregate = {
  __typename?: 'translation_aggregate';
  aggregate?: Maybe<Translation_Aggregate_Fields>;
  nodes: Array<Translation>;
};

export type Translation_Aggregate_Bool_Exp = {
  count?: Maybe<Translation_Aggregate_Bool_Exp_Count>;
};

export type Translation_Aggregate_Bool_Exp_Count = {
  arguments?: Maybe<Array<Translation_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Translation_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "translation" */
export type Translation_Aggregate_Fields = {
  __typename?: 'translation_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Translation_Max_Fields>;
  min?: Maybe<Translation_Min_Fields>;
};


/** aggregate fields of "translation" */
export type Translation_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Translation_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "translation" */
export type Translation_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Translation_Max_Order_By>;
  min?: Maybe<Translation_Min_Order_By>;
};

/** input type for inserting array relation for remote table "translation" */
export type Translation_Arr_Rel_Insert_Input = {
  data: Array<Translation_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Translation_On_Conflict>;
};

/** Boolean expression to filter rows from the table "translation". All fields are combined with a logical 'AND'. */
export type Translation_Bool_Exp = {
  _and?: Maybe<Array<Translation_Bool_Exp>>;
  _not?: Maybe<Translation_Bool_Exp>;
  _or?: Maybe<Array<Translation_Bool_Exp>>;
  createdAt?: Maybe<Timestamptz_Comparison_Exp>;
  i18n?: Maybe<I18n_Bool_Exp>;
  i18nId?: Maybe<Uuid_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  locale?: Maybe<String_Comparison_Exp>;
  updatedAt?: Maybe<Timestamptz_Comparison_Exp>;
  value?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "translation" */
export enum Translation_Constraint {
  /** unique or primary key constraint on columns "i18nId", "locale" */
  CTranslationI18nIdLocale = 'C_translation_i18n_id_locale',
  /** unique or primary key constraint on columns "id" */
  PkTranslation = 'PK_translation'
}

/** input type for inserting data into table "translation" */
export type Translation_Insert_Input = {
  createdAt?: Maybe<Scalars['timestamptz']>;
  i18n?: Maybe<I18n_Obj_Rel_Insert_Input>;
  i18nId?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  locale?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Translation_Max_Fields = {
  __typename?: 'translation_max_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  i18nId?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  locale?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  value?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "translation" */
export type Translation_Max_Order_By = {
  createdAt?: Maybe<Order_By>;
  i18nId?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  locale?: Maybe<Order_By>;
  updatedAt?: Maybe<Order_By>;
  value?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Translation_Min_Fields = {
  __typename?: 'translation_min_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  i18nId?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  locale?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  value?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "translation" */
export type Translation_Min_Order_By = {
  createdAt?: Maybe<Order_By>;
  i18nId?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  locale?: Maybe<Order_By>;
  updatedAt?: Maybe<Order_By>;
  value?: Maybe<Order_By>;
};

/** response of any mutation on the table "translation" */
export type Translation_Mutation_Response = {
  __typename?: 'translation_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Translation>;
};

/** on_conflict condition type for table "translation" */
export type Translation_On_Conflict = {
  constraint: Translation_Constraint;
  update_columns?: Array<Translation_Update_Column>;
  where?: Maybe<Translation_Bool_Exp>;
};

/** Ordering options when selecting data from "translation". */
export type Translation_Order_By = {
  createdAt?: Maybe<Order_By>;
  i18n?: Maybe<I18n_Order_By>;
  i18nId?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  locale?: Maybe<Order_By>;
  updatedAt?: Maybe<Order_By>;
  value?: Maybe<Order_By>;
};

/** primary key columns input for table: translation */
export type Translation_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "translation" */
export enum Translation_Select_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  I18nId = 'i18nId',
  /** column name */
  Id = 'id',
  /** column name */
  Locale = 'locale',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "translation" */
export type Translation_Set_Input = {
  createdAt?: Maybe<Scalars['timestamptz']>;
  i18nId?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  locale?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  value?: Maybe<Scalars['String']>;
};

/** Streaming cursor of the table "translation" */
export type Translation_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Translation_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Translation_Stream_Cursor_Value_Input = {
  createdAt?: Maybe<Scalars['timestamptz']>;
  i18nId?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  locale?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  value?: Maybe<Scalars['String']>;
};

/** update columns of table "translation" */
export enum Translation_Update_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  I18nId = 'i18nId',
  /** column name */
  Id = 'id',
  /** column name */
  Locale = 'locale',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  Value = 'value'
}

export type Translation_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Translation_Set_Input>;
  /** filter the rows which have to be updated */
  where: Translation_Bool_Exp;
};

/** columns and relationships of "user" */
export type User = {
  __typename?: 'user';
  birthDate?: Maybe<Scalars['timestamptz']>;
  cadre?: Maybe<Scalars['String']>;
  classification?: Maybe<Scalars['String']>;
  connectedCount: Scalars['Int'];
  contract?: Maybe<Scalars['String']>;
  createdAt: Scalars['timestamptz'];
  deletedAt?: Maybe<Scalars['timestamptz']>;
  /** An object relationship */
  direction?: Maybe<Direction>;
  directionId?: Maybe<Scalars['uuid']>;
  email: Scalars['String'];
  emailVerified: Scalars['Boolean'];
  employer?: Maybe<Scalars['String']>;
  employmentDate?: Maybe<Scalars['timestamptz']>;
  endDate?: Maybe<Scalars['timestamptz']>;
  /** An object relationship */
  fa?: Maybe<Fa>;
  faId?: Maybe<Scalars['uuid']>;
  firstConnectedAt?: Maybe<Scalars['timestamptz']>;
  firstName?: Maybe<Scalars['String']>;
  gender?: Maybe<Scalars['String']>;
  hasAcceptedPolicy: Scalars['Boolean'];
  hasSeenOnboarding: Scalars['Boolean'];
  id: Scalars['uuid'];
  /** An object relationship */
  identity?: Maybe<Identity>;
  isActive: Scalars['Boolean'];
  isAdmin: Scalars['Boolean'];
  isManager: Scalars['Boolean'];
  isParisCityHall: Scalars['Boolean'];
  isStudent: Scalars['Boolean'];
  isSuperAdmin: Scalars['Boolean'];
  language?: Maybe<Scalars['String']>;
  lastConnectedAt?: Maybe<Scalars['timestamptz']>;
  lastName?: Maybe<Scalars['String']>;
  matricule?: Maybe<Scalars['String']>;
  organisationId?: Maybe<Scalars['uuid']>;
  registrationNumber?: Maybe<Scalars['String']>;
  /** An object relationship */
  role?: Maybe<Role>;
  roleId?: Maybe<Scalars['uuid']>;
  startDate?: Maybe<Scalars['timestamptz']>;
  type: Scalars['String'];
  /** A computed field, executes function "user_firstname_unaccent" */
  unaccentFirstName?: Maybe<Scalars['String']>;
  /** A computed field, executes function "user_lastname_unaccent" */
  unaccentLastName?: Maybe<Scalars['String']>;
  updatedAt: Scalars['timestamptz'];
  /** An array relationship */
  userGroups: Array<UserGroup>;
  /** An aggregate relationship */
  userGroups_aggregate: UserGroup_Aggregate;
  /** An array relationship */
  user_courses_creator: Array<Course>;
  /** An aggregate relationship */
  user_courses_creator_aggregate: Course_Aggregate;
  /** An object relationship */
  volunteer?: Maybe<VolunteerPost>;
};


/** columns and relationships of "user" */
export type UserUserGroupsArgs = {
  distinct_on?: Maybe<Array<UserGroup_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<UserGroup_Order_By>>;
  where?: Maybe<UserGroup_Bool_Exp>;
};


/** columns and relationships of "user" */
export type UserUserGroups_AggregateArgs = {
  distinct_on?: Maybe<Array<UserGroup_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<UserGroup_Order_By>>;
  where?: Maybe<UserGroup_Bool_Exp>;
};


/** columns and relationships of "user" */
export type UserUser_Courses_CreatorArgs = {
  distinct_on?: Maybe<Array<Course_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Course_Order_By>>;
  where?: Maybe<Course_Bool_Exp>;
};


/** columns and relationships of "user" */
export type UserUser_Courses_Creator_AggregateArgs = {
  distinct_on?: Maybe<Array<Course_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Course_Order_By>>;
  where?: Maybe<Course_Bool_Exp>;
};

/** columns and relationships of "userCourseProgress" */
export type UserCourseProgress = {
  __typename?: 'userCourseProgress';
  completedAt?: Maybe<Scalars['timestamptz']>;
  /** An object relationship */
  course: Course;
  courseId: Scalars['uuid'];
  createdAt: Scalars['timestamptz'];
  id: Scalars['uuid'];
  status: Scalars['String'];
  updatedAt: Scalars['timestamptz'];
  /** An object relationship */
  user: User;
  userId: Scalars['uuid'];
};

/** aggregated selection of "userCourseProgress" */
export type UserCourseProgress_Aggregate = {
  __typename?: 'userCourseProgress_aggregate';
  aggregate?: Maybe<UserCourseProgress_Aggregate_Fields>;
  nodes: Array<UserCourseProgress>;
};

export type UserCourseProgress_Aggregate_Bool_Exp = {
  count?: Maybe<UserCourseProgress_Aggregate_Bool_Exp_Count>;
};

export type UserCourseProgress_Aggregate_Bool_Exp_Count = {
  arguments?: Maybe<Array<UserCourseProgress_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<UserCourseProgress_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "userCourseProgress" */
export type UserCourseProgress_Aggregate_Fields = {
  __typename?: 'userCourseProgress_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<UserCourseProgress_Max_Fields>;
  min?: Maybe<UserCourseProgress_Min_Fields>;
};


/** aggregate fields of "userCourseProgress" */
export type UserCourseProgress_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<UserCourseProgress_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "userCourseProgress" */
export type UserCourseProgress_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<UserCourseProgress_Max_Order_By>;
  min?: Maybe<UserCourseProgress_Min_Order_By>;
};

/** input type for inserting array relation for remote table "userCourseProgress" */
export type UserCourseProgress_Arr_Rel_Insert_Input = {
  data: Array<UserCourseProgress_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<UserCourseProgress_On_Conflict>;
};

/** Boolean expression to filter rows from the table "userCourseProgress". All fields are combined with a logical 'AND'. */
export type UserCourseProgress_Bool_Exp = {
  _and?: Maybe<Array<UserCourseProgress_Bool_Exp>>;
  _not?: Maybe<UserCourseProgress_Bool_Exp>;
  _or?: Maybe<Array<UserCourseProgress_Bool_Exp>>;
  completedAt?: Maybe<Timestamptz_Comparison_Exp>;
  course?: Maybe<Course_Bool_Exp>;
  courseId?: Maybe<Uuid_Comparison_Exp>;
  createdAt?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  status?: Maybe<String_Comparison_Exp>;
  updatedAt?: Maybe<Timestamptz_Comparison_Exp>;
  user?: Maybe<User_Bool_Exp>;
  userId?: Maybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "userCourseProgress" */
export enum UserCourseProgress_Constraint {
  /** unique or primary key constraint on columns "id" */
  RegistrationPkey = 'registration_pkey',
  /** unique or primary key constraint on columns "courseId", "userId" */
  RegistrationUserIdCourseIdKey = 'registration_userId_courseId_key'
}

/** input type for inserting data into table "userCourseProgress" */
export type UserCourseProgress_Insert_Input = {
  completedAt?: Maybe<Scalars['timestamptz']>;
  course?: Maybe<Course_Obj_Rel_Insert_Input>;
  courseId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  status?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  user?: Maybe<User_Obj_Rel_Insert_Input>;
  userId?: Maybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type UserCourseProgress_Max_Fields = {
  __typename?: 'userCourseProgress_max_fields';
  completedAt?: Maybe<Scalars['timestamptz']>;
  courseId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  status?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  userId?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "userCourseProgress" */
export type UserCourseProgress_Max_Order_By = {
  completedAt?: Maybe<Order_By>;
  courseId?: Maybe<Order_By>;
  createdAt?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  status?: Maybe<Order_By>;
  updatedAt?: Maybe<Order_By>;
  userId?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type UserCourseProgress_Min_Fields = {
  __typename?: 'userCourseProgress_min_fields';
  completedAt?: Maybe<Scalars['timestamptz']>;
  courseId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  status?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  userId?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "userCourseProgress" */
export type UserCourseProgress_Min_Order_By = {
  completedAt?: Maybe<Order_By>;
  courseId?: Maybe<Order_By>;
  createdAt?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  status?: Maybe<Order_By>;
  updatedAt?: Maybe<Order_By>;
  userId?: Maybe<Order_By>;
};

/** response of any mutation on the table "userCourseProgress" */
export type UserCourseProgress_Mutation_Response = {
  __typename?: 'userCourseProgress_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<UserCourseProgress>;
};

/** on_conflict condition type for table "userCourseProgress" */
export type UserCourseProgress_On_Conflict = {
  constraint: UserCourseProgress_Constraint;
  update_columns?: Array<UserCourseProgress_Update_Column>;
  where?: Maybe<UserCourseProgress_Bool_Exp>;
};

/** Ordering options when selecting data from "userCourseProgress". */
export type UserCourseProgress_Order_By = {
  completedAt?: Maybe<Order_By>;
  course?: Maybe<Course_Order_By>;
  courseId?: Maybe<Order_By>;
  createdAt?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  status?: Maybe<Order_By>;
  updatedAt?: Maybe<Order_By>;
  user?: Maybe<User_Order_By>;
  userId?: Maybe<Order_By>;
};

/** primary key columns input for table: userCourseProgress */
export type UserCourseProgress_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "userCourseProgress" */
export enum UserCourseProgress_Select_Column {
  /** column name */
  CompletedAt = 'completedAt',
  /** column name */
  CourseId = 'courseId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  Status = 'status',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  UserId = 'userId'
}

/** input type for updating data in table "userCourseProgress" */
export type UserCourseProgress_Set_Input = {
  completedAt?: Maybe<Scalars['timestamptz']>;
  courseId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  status?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  userId?: Maybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "userCourseProgress" */
export type UserCourseProgress_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: UserCourseProgress_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type UserCourseProgress_Stream_Cursor_Value_Input = {
  completedAt?: Maybe<Scalars['timestamptz']>;
  courseId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  status?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  userId?: Maybe<Scalars['uuid']>;
};

/** update columns of table "userCourseProgress" */
export enum UserCourseProgress_Update_Column {
  /** column name */
  CompletedAt = 'completedAt',
  /** column name */
  CourseId = 'courseId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  Status = 'status',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  UserId = 'userId'
}

export type UserCourseProgress_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<UserCourseProgress_Set_Input>;
  /** filter the rows which have to be updated */
  where: UserCourseProgress_Bool_Exp;
};

/** columns and relationships of "userEventSlot" */
export type UserEventSlot = {
  __typename?: 'userEventSlot';
  /** An object relationship */
  contentEventSlot: ContentEventSlot;
  createdAt: Scalars['timestamptz'];
  eventSlotId: Scalars['uuid'];
  hasAttended?: Maybe<Scalars['Boolean']>;
  id: Scalars['uuid'];
  updatedAt: Scalars['timestamptz'];
  userId: Scalars['uuid'];
};

/** aggregated selection of "userEventSlot" */
export type UserEventSlot_Aggregate = {
  __typename?: 'userEventSlot_aggregate';
  aggregate?: Maybe<UserEventSlot_Aggregate_Fields>;
  nodes: Array<UserEventSlot>;
};

export type UserEventSlot_Aggregate_Bool_Exp = {
  bool_and?: Maybe<UserEventSlot_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: Maybe<UserEventSlot_Aggregate_Bool_Exp_Bool_Or>;
  count?: Maybe<UserEventSlot_Aggregate_Bool_Exp_Count>;
};

export type UserEventSlot_Aggregate_Bool_Exp_Bool_And = {
  arguments: UserEventSlot_Select_Column_UserEventSlot_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<UserEventSlot_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type UserEventSlot_Aggregate_Bool_Exp_Bool_Or = {
  arguments: UserEventSlot_Select_Column_UserEventSlot_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<UserEventSlot_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type UserEventSlot_Aggregate_Bool_Exp_Count = {
  arguments?: Maybe<Array<UserEventSlot_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<UserEventSlot_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "userEventSlot" */
export type UserEventSlot_Aggregate_Fields = {
  __typename?: 'userEventSlot_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<UserEventSlot_Max_Fields>;
  min?: Maybe<UserEventSlot_Min_Fields>;
};


/** aggregate fields of "userEventSlot" */
export type UserEventSlot_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<UserEventSlot_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "userEventSlot" */
export type UserEventSlot_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<UserEventSlot_Max_Order_By>;
  min?: Maybe<UserEventSlot_Min_Order_By>;
};

/** input type for inserting array relation for remote table "userEventSlot" */
export type UserEventSlot_Arr_Rel_Insert_Input = {
  data: Array<UserEventSlot_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<UserEventSlot_On_Conflict>;
};

/** Boolean expression to filter rows from the table "userEventSlot". All fields are combined with a logical 'AND'. */
export type UserEventSlot_Bool_Exp = {
  _and?: Maybe<Array<UserEventSlot_Bool_Exp>>;
  _not?: Maybe<UserEventSlot_Bool_Exp>;
  _or?: Maybe<Array<UserEventSlot_Bool_Exp>>;
  contentEventSlot?: Maybe<ContentEventSlot_Bool_Exp>;
  createdAt?: Maybe<Timestamptz_Comparison_Exp>;
  eventSlotId?: Maybe<Uuid_Comparison_Exp>;
  hasAttended?: Maybe<Boolean_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  updatedAt?: Maybe<Timestamptz_Comparison_Exp>;
  userId?: Maybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "userEventSlot" */
export enum UserEventSlot_Constraint {
  /** unique or primary key constraint on columns "eventSlotId", "userId" */
  UserEventSlotEventSlotIdUserIdKey = 'userEventSlot_eventSlotId_userId_key',
  /** unique or primary key constraint on columns "id" */
  UserEventSlotPkey = 'userEventSlot_pkey'
}

/** input type for inserting data into table "userEventSlot" */
export type UserEventSlot_Insert_Input = {
  contentEventSlot?: Maybe<ContentEventSlot_Obj_Rel_Insert_Input>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  eventSlotId?: Maybe<Scalars['uuid']>;
  hasAttended?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  userId?: Maybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type UserEventSlot_Max_Fields = {
  __typename?: 'userEventSlot_max_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  eventSlotId?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  userId?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "userEventSlot" */
export type UserEventSlot_Max_Order_By = {
  createdAt?: Maybe<Order_By>;
  eventSlotId?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  updatedAt?: Maybe<Order_By>;
  userId?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type UserEventSlot_Min_Fields = {
  __typename?: 'userEventSlot_min_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  eventSlotId?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  userId?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "userEventSlot" */
export type UserEventSlot_Min_Order_By = {
  createdAt?: Maybe<Order_By>;
  eventSlotId?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  updatedAt?: Maybe<Order_By>;
  userId?: Maybe<Order_By>;
};

/** response of any mutation on the table "userEventSlot" */
export type UserEventSlot_Mutation_Response = {
  __typename?: 'userEventSlot_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<UserEventSlot>;
};

/** on_conflict condition type for table "userEventSlot" */
export type UserEventSlot_On_Conflict = {
  constraint: UserEventSlot_Constraint;
  update_columns?: Array<UserEventSlot_Update_Column>;
  where?: Maybe<UserEventSlot_Bool_Exp>;
};

/** Ordering options when selecting data from "userEventSlot". */
export type UserEventSlot_Order_By = {
  contentEventSlot?: Maybe<ContentEventSlot_Order_By>;
  createdAt?: Maybe<Order_By>;
  eventSlotId?: Maybe<Order_By>;
  hasAttended?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  updatedAt?: Maybe<Order_By>;
  userId?: Maybe<Order_By>;
};

/** primary key columns input for table: userEventSlot */
export type UserEventSlot_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "userEventSlot" */
export enum UserEventSlot_Select_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  EventSlotId = 'eventSlotId',
  /** column name */
  HasAttended = 'hasAttended',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  UserId = 'userId'
}

/** select "userEventSlot_aggregate_bool_exp_bool_and_arguments_columns" columns of table "userEventSlot" */
export enum UserEventSlot_Select_Column_UserEventSlot_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  HasAttended = 'hasAttended'
}

/** select "userEventSlot_aggregate_bool_exp_bool_or_arguments_columns" columns of table "userEventSlot" */
export enum UserEventSlot_Select_Column_UserEventSlot_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  HasAttended = 'hasAttended'
}

/** input type for updating data in table "userEventSlot" */
export type UserEventSlot_Set_Input = {
  createdAt?: Maybe<Scalars['timestamptz']>;
  eventSlotId?: Maybe<Scalars['uuid']>;
  hasAttended?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  userId?: Maybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "userEventSlot" */
export type UserEventSlot_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: UserEventSlot_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type UserEventSlot_Stream_Cursor_Value_Input = {
  createdAt?: Maybe<Scalars['timestamptz']>;
  eventSlotId?: Maybe<Scalars['uuid']>;
  hasAttended?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  userId?: Maybe<Scalars['uuid']>;
};

/** update columns of table "userEventSlot" */
export enum UserEventSlot_Update_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  EventSlotId = 'eventSlotId',
  /** column name */
  HasAttended = 'hasAttended',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  UserId = 'userId'
}

export type UserEventSlot_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<UserEventSlot_Set_Input>;
  /** filter the rows which have to be updated */
  where: UserEventSlot_Bool_Exp;
};

/** columns and relationships of "userGroup" */
export type UserGroup = {
  __typename?: 'userGroup';
  /** An object relationship */
  group: Group;
  groupId: Scalars['uuid'];
  id: Scalars['uuid'];
  /** An object relationship */
  user: User;
  userId: Scalars['uuid'];
};

/** aggregated selection of "userGroup" */
export type UserGroup_Aggregate = {
  __typename?: 'userGroup_aggregate';
  aggregate?: Maybe<UserGroup_Aggregate_Fields>;
  nodes: Array<UserGroup>;
};

export type UserGroup_Aggregate_Bool_Exp = {
  count?: Maybe<UserGroup_Aggregate_Bool_Exp_Count>;
};

export type UserGroup_Aggregate_Bool_Exp_Count = {
  arguments?: Maybe<Array<UserGroup_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<UserGroup_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "userGroup" */
export type UserGroup_Aggregate_Fields = {
  __typename?: 'userGroup_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<UserGroup_Max_Fields>;
  min?: Maybe<UserGroup_Min_Fields>;
};


/** aggregate fields of "userGroup" */
export type UserGroup_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<UserGroup_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "userGroup" */
export type UserGroup_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<UserGroup_Max_Order_By>;
  min?: Maybe<UserGroup_Min_Order_By>;
};

/** input type for inserting array relation for remote table "userGroup" */
export type UserGroup_Arr_Rel_Insert_Input = {
  data: Array<UserGroup_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<UserGroup_On_Conflict>;
};

/** Boolean expression to filter rows from the table "userGroup". All fields are combined with a logical 'AND'. */
export type UserGroup_Bool_Exp = {
  _and?: Maybe<Array<UserGroup_Bool_Exp>>;
  _not?: Maybe<UserGroup_Bool_Exp>;
  _or?: Maybe<Array<UserGroup_Bool_Exp>>;
  group?: Maybe<Group_Bool_Exp>;
  groupId?: Maybe<Uuid_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  user?: Maybe<User_Bool_Exp>;
  userId?: Maybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "userGroup" */
export enum UserGroup_Constraint {
  /** unique or primary key constraint on columns "id" */
  UserGroupPkey = 'userGroup_pkey',
  /** unique or primary key constraint on columns "userId", "groupId" */
  UserGroupUserIdGroupIdKey = 'userGroup_userId_groupId_key'
}

/** input type for inserting data into table "userGroup" */
export type UserGroup_Insert_Input = {
  group?: Maybe<Group_Obj_Rel_Insert_Input>;
  groupId?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  user?: Maybe<User_Obj_Rel_Insert_Input>;
  userId?: Maybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type UserGroup_Max_Fields = {
  __typename?: 'userGroup_max_fields';
  groupId?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  userId?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "userGroup" */
export type UserGroup_Max_Order_By = {
  groupId?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  userId?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type UserGroup_Min_Fields = {
  __typename?: 'userGroup_min_fields';
  groupId?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  userId?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "userGroup" */
export type UserGroup_Min_Order_By = {
  groupId?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  userId?: Maybe<Order_By>;
};

/** response of any mutation on the table "userGroup" */
export type UserGroup_Mutation_Response = {
  __typename?: 'userGroup_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<UserGroup>;
};

/** on_conflict condition type for table "userGroup" */
export type UserGroup_On_Conflict = {
  constraint: UserGroup_Constraint;
  update_columns?: Array<UserGroup_Update_Column>;
  where?: Maybe<UserGroup_Bool_Exp>;
};

/** Ordering options when selecting data from "userGroup". */
export type UserGroup_Order_By = {
  group?: Maybe<Group_Order_By>;
  groupId?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  user?: Maybe<User_Order_By>;
  userId?: Maybe<Order_By>;
};

/** primary key columns input for table: userGroup */
export type UserGroup_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "userGroup" */
export enum UserGroup_Select_Column {
  /** column name */
  GroupId = 'groupId',
  /** column name */
  Id = 'id',
  /** column name */
  UserId = 'userId'
}

/** input type for updating data in table "userGroup" */
export type UserGroup_Set_Input = {
  groupId?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  userId?: Maybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "userGroup" */
export type UserGroup_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: UserGroup_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type UserGroup_Stream_Cursor_Value_Input = {
  groupId?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  userId?: Maybe<Scalars['uuid']>;
};

/** update columns of table "userGroup" */
export enum UserGroup_Update_Column {
  /** column name */
  GroupId = 'groupId',
  /** column name */
  Id = 'id',
  /** column name */
  UserId = 'userId'
}

export type UserGroup_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<UserGroup_Set_Input>;
  /** filter the rows which have to be updated */
  where: UserGroup_Bool_Exp;
};

/** columns and relationships of "userModuleProgress" */
export type UserModuleProgress = {
  __typename?: 'userModuleProgress';
  createdAt?: Maybe<Scalars['timestamptz']>;
  id: Scalars['uuid'];
  moduleId: Scalars['uuid'];
  progression: Scalars['Int'];
  updatedAt?: Maybe<Scalars['timestamptz']>;
  userId: Scalars['uuid'];
};

/** aggregated selection of "userModuleProgress" */
export type UserModuleProgress_Aggregate = {
  __typename?: 'userModuleProgress_aggregate';
  aggregate?: Maybe<UserModuleProgress_Aggregate_Fields>;
  nodes: Array<UserModuleProgress>;
};

export type UserModuleProgress_Aggregate_Bool_Exp = {
  count?: Maybe<UserModuleProgress_Aggregate_Bool_Exp_Count>;
};

export type UserModuleProgress_Aggregate_Bool_Exp_Count = {
  arguments?: Maybe<Array<UserModuleProgress_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<UserModuleProgress_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "userModuleProgress" */
export type UserModuleProgress_Aggregate_Fields = {
  __typename?: 'userModuleProgress_aggregate_fields';
  avg?: Maybe<UserModuleProgress_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<UserModuleProgress_Max_Fields>;
  min?: Maybe<UserModuleProgress_Min_Fields>;
  stddev?: Maybe<UserModuleProgress_Stddev_Fields>;
  stddev_pop?: Maybe<UserModuleProgress_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<UserModuleProgress_Stddev_Samp_Fields>;
  sum?: Maybe<UserModuleProgress_Sum_Fields>;
  var_pop?: Maybe<UserModuleProgress_Var_Pop_Fields>;
  var_samp?: Maybe<UserModuleProgress_Var_Samp_Fields>;
  variance?: Maybe<UserModuleProgress_Variance_Fields>;
};


/** aggregate fields of "userModuleProgress" */
export type UserModuleProgress_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<UserModuleProgress_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "userModuleProgress" */
export type UserModuleProgress_Aggregate_Order_By = {
  avg?: Maybe<UserModuleProgress_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<UserModuleProgress_Max_Order_By>;
  min?: Maybe<UserModuleProgress_Min_Order_By>;
  stddev?: Maybe<UserModuleProgress_Stddev_Order_By>;
  stddev_pop?: Maybe<UserModuleProgress_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<UserModuleProgress_Stddev_Samp_Order_By>;
  sum?: Maybe<UserModuleProgress_Sum_Order_By>;
  var_pop?: Maybe<UserModuleProgress_Var_Pop_Order_By>;
  var_samp?: Maybe<UserModuleProgress_Var_Samp_Order_By>;
  variance?: Maybe<UserModuleProgress_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "userModuleProgress" */
export type UserModuleProgress_Arr_Rel_Insert_Input = {
  data: Array<UserModuleProgress_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<UserModuleProgress_On_Conflict>;
};

/** aggregate avg on columns */
export type UserModuleProgress_Avg_Fields = {
  __typename?: 'userModuleProgress_avg_fields';
  progression?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "userModuleProgress" */
export type UserModuleProgress_Avg_Order_By = {
  progression?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "userModuleProgress". All fields are combined with a logical 'AND'. */
export type UserModuleProgress_Bool_Exp = {
  _and?: Maybe<Array<UserModuleProgress_Bool_Exp>>;
  _not?: Maybe<UserModuleProgress_Bool_Exp>;
  _or?: Maybe<Array<UserModuleProgress_Bool_Exp>>;
  createdAt?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  moduleId?: Maybe<Uuid_Comparison_Exp>;
  progression?: Maybe<Int_Comparison_Exp>;
  updatedAt?: Maybe<Timestamptz_Comparison_Exp>;
  userId?: Maybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "userModuleProgress" */
export enum UserModuleProgress_Constraint {
  /** unique or primary key constraint on columns "userId", "moduleId" */
  CUserModuleProgressUserIdModuleId = 'c_user_module_progress_user_id_module_id',
  /** unique or primary key constraint on columns "id" */
  UserModuleProgressPkey = 'userModuleProgress_pkey'
}

/** input type for incrementing numeric columns in table "userModuleProgress" */
export type UserModuleProgress_Inc_Input = {
  progression?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "userModuleProgress" */
export type UserModuleProgress_Insert_Input = {
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  moduleId?: Maybe<Scalars['uuid']>;
  progression?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  userId?: Maybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type UserModuleProgress_Max_Fields = {
  __typename?: 'userModuleProgress_max_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  moduleId?: Maybe<Scalars['uuid']>;
  progression?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  userId?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "userModuleProgress" */
export type UserModuleProgress_Max_Order_By = {
  createdAt?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  moduleId?: Maybe<Order_By>;
  progression?: Maybe<Order_By>;
  updatedAt?: Maybe<Order_By>;
  userId?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type UserModuleProgress_Min_Fields = {
  __typename?: 'userModuleProgress_min_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  moduleId?: Maybe<Scalars['uuid']>;
  progression?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  userId?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "userModuleProgress" */
export type UserModuleProgress_Min_Order_By = {
  createdAt?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  moduleId?: Maybe<Order_By>;
  progression?: Maybe<Order_By>;
  updatedAt?: Maybe<Order_By>;
  userId?: Maybe<Order_By>;
};

/** response of any mutation on the table "userModuleProgress" */
export type UserModuleProgress_Mutation_Response = {
  __typename?: 'userModuleProgress_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<UserModuleProgress>;
};

/** on_conflict condition type for table "userModuleProgress" */
export type UserModuleProgress_On_Conflict = {
  constraint: UserModuleProgress_Constraint;
  update_columns?: Array<UserModuleProgress_Update_Column>;
  where?: Maybe<UserModuleProgress_Bool_Exp>;
};

/** Ordering options when selecting data from "userModuleProgress". */
export type UserModuleProgress_Order_By = {
  createdAt?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  moduleId?: Maybe<Order_By>;
  progression?: Maybe<Order_By>;
  updatedAt?: Maybe<Order_By>;
  userId?: Maybe<Order_By>;
};

/** primary key columns input for table: userModuleProgress */
export type UserModuleProgress_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "userModuleProgress" */
export enum UserModuleProgress_Select_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  ModuleId = 'moduleId',
  /** column name */
  Progression = 'progression',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  UserId = 'userId'
}

/** input type for updating data in table "userModuleProgress" */
export type UserModuleProgress_Set_Input = {
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  moduleId?: Maybe<Scalars['uuid']>;
  progression?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  userId?: Maybe<Scalars['uuid']>;
};

/** aggregate stddev on columns */
export type UserModuleProgress_Stddev_Fields = {
  __typename?: 'userModuleProgress_stddev_fields';
  progression?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "userModuleProgress" */
export type UserModuleProgress_Stddev_Order_By = {
  progression?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type UserModuleProgress_Stddev_Pop_Fields = {
  __typename?: 'userModuleProgress_stddev_pop_fields';
  progression?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "userModuleProgress" */
export type UserModuleProgress_Stddev_Pop_Order_By = {
  progression?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type UserModuleProgress_Stddev_Samp_Fields = {
  __typename?: 'userModuleProgress_stddev_samp_fields';
  progression?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "userModuleProgress" */
export type UserModuleProgress_Stddev_Samp_Order_By = {
  progression?: Maybe<Order_By>;
};

/** Streaming cursor of the table "userModuleProgress" */
export type UserModuleProgress_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: UserModuleProgress_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type UserModuleProgress_Stream_Cursor_Value_Input = {
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  moduleId?: Maybe<Scalars['uuid']>;
  progression?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  userId?: Maybe<Scalars['uuid']>;
};

/** aggregate sum on columns */
export type UserModuleProgress_Sum_Fields = {
  __typename?: 'userModuleProgress_sum_fields';
  progression?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "userModuleProgress" */
export type UserModuleProgress_Sum_Order_By = {
  progression?: Maybe<Order_By>;
};

/** update columns of table "userModuleProgress" */
export enum UserModuleProgress_Update_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  ModuleId = 'moduleId',
  /** column name */
  Progression = 'progression',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  UserId = 'userId'
}

export type UserModuleProgress_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<UserModuleProgress_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<UserModuleProgress_Set_Input>;
  /** filter the rows which have to be updated */
  where: UserModuleProgress_Bool_Exp;
};

/** aggregate var_pop on columns */
export type UserModuleProgress_Var_Pop_Fields = {
  __typename?: 'userModuleProgress_var_pop_fields';
  progression?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "userModuleProgress" */
export type UserModuleProgress_Var_Pop_Order_By = {
  progression?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type UserModuleProgress_Var_Samp_Fields = {
  __typename?: 'userModuleProgress_var_samp_fields';
  progression?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "userModuleProgress" */
export type UserModuleProgress_Var_Samp_Order_By = {
  progression?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type UserModuleProgress_Variance_Fields = {
  __typename?: 'userModuleProgress_variance_fields';
  progression?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "userModuleProgress" */
export type UserModuleProgress_Variance_Order_By = {
  progression?: Maybe<Order_By>;
};

/** columns and relationships of "userPrivate" */
export type UserPrivate = {
  __typename?: 'userPrivate';
  createdAt?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  isSuperAdmin?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  /** An object relationship */
  user?: Maybe<User>;
};

/** aggregated selection of "userPrivate" */
export type UserPrivate_Aggregate = {
  __typename?: 'userPrivate_aggregate';
  aggregate?: Maybe<UserPrivate_Aggregate_Fields>;
  nodes: Array<UserPrivate>;
};

/** aggregate fields of "userPrivate" */
export type UserPrivate_Aggregate_Fields = {
  __typename?: 'userPrivate_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<UserPrivate_Max_Fields>;
  min?: Maybe<UserPrivate_Min_Fields>;
};


/** aggregate fields of "userPrivate" */
export type UserPrivate_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<UserPrivate_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "userPrivate". All fields are combined with a logical 'AND'. */
export type UserPrivate_Bool_Exp = {
  _and?: Maybe<Array<UserPrivate_Bool_Exp>>;
  _not?: Maybe<UserPrivate_Bool_Exp>;
  _or?: Maybe<Array<UserPrivate_Bool_Exp>>;
  createdAt?: Maybe<Timestamptz_Comparison_Exp>;
  email?: Maybe<String_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  isActive?: Maybe<Boolean_Comparison_Exp>;
  isAdmin?: Maybe<Boolean_Comparison_Exp>;
  isSuperAdmin?: Maybe<Boolean_Comparison_Exp>;
  type?: Maybe<String_Comparison_Exp>;
  updatedAt?: Maybe<Timestamptz_Comparison_Exp>;
  user?: Maybe<User_Bool_Exp>;
};

/** input type for inserting data into table "userPrivate" */
export type UserPrivate_Insert_Input = {
  createdAt?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  isSuperAdmin?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  user?: Maybe<User_Obj_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type UserPrivate_Max_Fields = {
  __typename?: 'userPrivate_max_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  type?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type UserPrivate_Min_Fields = {
  __typename?: 'userPrivate_min_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  type?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "userPrivate" */
export type UserPrivate_Mutation_Response = {
  __typename?: 'userPrivate_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<UserPrivate>;
};

/** Ordering options when selecting data from "userPrivate". */
export type UserPrivate_Order_By = {
  createdAt?: Maybe<Order_By>;
  email?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  isActive?: Maybe<Order_By>;
  isAdmin?: Maybe<Order_By>;
  isSuperAdmin?: Maybe<Order_By>;
  type?: Maybe<Order_By>;
  updatedAt?: Maybe<Order_By>;
  user?: Maybe<User_Order_By>;
};

/** select columns of table "userPrivate" */
export enum UserPrivate_Select_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Email = 'email',
  /** column name */
  Id = 'id',
  /** column name */
  IsActive = 'isActive',
  /** column name */
  IsAdmin = 'isAdmin',
  /** column name */
  IsSuperAdmin = 'isSuperAdmin',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updatedAt'
}

/** input type for updating data in table "userPrivate" */
export type UserPrivate_Set_Input = {
  createdAt?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  isSuperAdmin?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "userPrivate" */
export type UserPrivate_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: UserPrivate_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type UserPrivate_Stream_Cursor_Value_Input = {
  createdAt?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  isSuperAdmin?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

export type UserPrivate_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<UserPrivate_Set_Input>;
  /** filter the rows which have to be updated */
  where: UserPrivate_Bool_Exp;
};

/** columns and relationships of "userSkill" */
export type UserSkill = {
  __typename?: 'userSkill';
  createdAt: Scalars['timestamptz'];
  id: Scalars['uuid'];
  level: Scalars['Int'];
  skillId: Scalars['uuid'];
  updatedAt: Scalars['timestamptz'];
  userId: Scalars['uuid'];
};

/** aggregated selection of "userSkill" */
export type UserSkill_Aggregate = {
  __typename?: 'userSkill_aggregate';
  aggregate?: Maybe<UserSkill_Aggregate_Fields>;
  nodes: Array<UserSkill>;
};

/** aggregate fields of "userSkill" */
export type UserSkill_Aggregate_Fields = {
  __typename?: 'userSkill_aggregate_fields';
  avg?: Maybe<UserSkill_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<UserSkill_Max_Fields>;
  min?: Maybe<UserSkill_Min_Fields>;
  stddev?: Maybe<UserSkill_Stddev_Fields>;
  stddev_pop?: Maybe<UserSkill_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<UserSkill_Stddev_Samp_Fields>;
  sum?: Maybe<UserSkill_Sum_Fields>;
  var_pop?: Maybe<UserSkill_Var_Pop_Fields>;
  var_samp?: Maybe<UserSkill_Var_Samp_Fields>;
  variance?: Maybe<UserSkill_Variance_Fields>;
};


/** aggregate fields of "userSkill" */
export type UserSkill_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<UserSkill_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type UserSkill_Avg_Fields = {
  __typename?: 'userSkill_avg_fields';
  level?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "userSkill". All fields are combined with a logical 'AND'. */
export type UserSkill_Bool_Exp = {
  _and?: Maybe<Array<UserSkill_Bool_Exp>>;
  _not?: Maybe<UserSkill_Bool_Exp>;
  _or?: Maybe<Array<UserSkill_Bool_Exp>>;
  createdAt?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  level?: Maybe<Int_Comparison_Exp>;
  skillId?: Maybe<Uuid_Comparison_Exp>;
  updatedAt?: Maybe<Timestamptz_Comparison_Exp>;
  userId?: Maybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "userSkill" */
export enum UserSkill_Constraint {
  /** unique or primary key constraint on columns "id" */
  UserSkillPkey = 'userSkill_pkey',
  /** unique or primary key constraint on columns "userId", "skillId" */
  UserSkillSkillIdUserIdLevelKey = 'user_skill_skillId_userId_level_key'
}

/** input type for incrementing numeric columns in table "userSkill" */
export type UserSkill_Inc_Input = {
  level?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "userSkill" */
export type UserSkill_Insert_Input = {
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  level?: Maybe<Scalars['Int']>;
  skillId?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  userId?: Maybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type UserSkill_Max_Fields = {
  __typename?: 'userSkill_max_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  level?: Maybe<Scalars['Int']>;
  skillId?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  userId?: Maybe<Scalars['uuid']>;
};

/** aggregate min on columns */
export type UserSkill_Min_Fields = {
  __typename?: 'userSkill_min_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  level?: Maybe<Scalars['Int']>;
  skillId?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  userId?: Maybe<Scalars['uuid']>;
};

/** response of any mutation on the table "userSkill" */
export type UserSkill_Mutation_Response = {
  __typename?: 'userSkill_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<UserSkill>;
};

/** on_conflict condition type for table "userSkill" */
export type UserSkill_On_Conflict = {
  constraint: UserSkill_Constraint;
  update_columns?: Array<UserSkill_Update_Column>;
  where?: Maybe<UserSkill_Bool_Exp>;
};

/** Ordering options when selecting data from "userSkill". */
export type UserSkill_Order_By = {
  createdAt?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  level?: Maybe<Order_By>;
  skillId?: Maybe<Order_By>;
  updatedAt?: Maybe<Order_By>;
  userId?: Maybe<Order_By>;
};

/** primary key columns input for table: userSkill */
export type UserSkill_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "userSkill" */
export enum UserSkill_Select_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  Level = 'level',
  /** column name */
  SkillId = 'skillId',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  UserId = 'userId'
}

/** input type for updating data in table "userSkill" */
export type UserSkill_Set_Input = {
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  level?: Maybe<Scalars['Int']>;
  skillId?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  userId?: Maybe<Scalars['uuid']>;
};

/** aggregate stddev on columns */
export type UserSkill_Stddev_Fields = {
  __typename?: 'userSkill_stddev_fields';
  level?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type UserSkill_Stddev_Pop_Fields = {
  __typename?: 'userSkill_stddev_pop_fields';
  level?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type UserSkill_Stddev_Samp_Fields = {
  __typename?: 'userSkill_stddev_samp_fields';
  level?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "userSkill" */
export type UserSkill_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: UserSkill_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type UserSkill_Stream_Cursor_Value_Input = {
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  level?: Maybe<Scalars['Int']>;
  skillId?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  userId?: Maybe<Scalars['uuid']>;
};

/** aggregate sum on columns */
export type UserSkill_Sum_Fields = {
  __typename?: 'userSkill_sum_fields';
  level?: Maybe<Scalars['Int']>;
};

/** update columns of table "userSkill" */
export enum UserSkill_Update_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  Level = 'level',
  /** column name */
  SkillId = 'skillId',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  UserId = 'userId'
}

export type UserSkill_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<UserSkill_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<UserSkill_Set_Input>;
  /** filter the rows which have to be updated */
  where: UserSkill_Bool_Exp;
};

/** aggregate var_pop on columns */
export type UserSkill_Var_Pop_Fields = {
  __typename?: 'userSkill_var_pop_fields';
  level?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type UserSkill_Var_Samp_Fields = {
  __typename?: 'userSkill_var_samp_fields';
  level?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type UserSkill_Variance_Fields = {
  __typename?: 'userSkill_variance_fields';
  level?: Maybe<Scalars['Float']>;
};

/** aggregated selection of "user" */
export type User_Aggregate = {
  __typename?: 'user_aggregate';
  aggregate?: Maybe<User_Aggregate_Fields>;
  nodes: Array<User>;
};

/** aggregate fields of "user" */
export type User_Aggregate_Fields = {
  __typename?: 'user_aggregate_fields';
  avg?: Maybe<User_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<User_Max_Fields>;
  min?: Maybe<User_Min_Fields>;
  stddev?: Maybe<User_Stddev_Fields>;
  stddev_pop?: Maybe<User_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<User_Stddev_Samp_Fields>;
  sum?: Maybe<User_Sum_Fields>;
  var_pop?: Maybe<User_Var_Pop_Fields>;
  var_samp?: Maybe<User_Var_Samp_Fields>;
  variance?: Maybe<User_Variance_Fields>;
};


/** aggregate fields of "user" */
export type User_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<User_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type User_Avg_Fields = {
  __typename?: 'user_avg_fields';
  connectedCount?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "user". All fields are combined with a logical 'AND'. */
export type User_Bool_Exp = {
  _and?: Maybe<Array<User_Bool_Exp>>;
  _not?: Maybe<User_Bool_Exp>;
  _or?: Maybe<Array<User_Bool_Exp>>;
  birthDate?: Maybe<Timestamptz_Comparison_Exp>;
  cadre?: Maybe<String_Comparison_Exp>;
  classification?: Maybe<String_Comparison_Exp>;
  connectedCount?: Maybe<Int_Comparison_Exp>;
  contract?: Maybe<String_Comparison_Exp>;
  createdAt?: Maybe<Timestamptz_Comparison_Exp>;
  deletedAt?: Maybe<Timestamptz_Comparison_Exp>;
  direction?: Maybe<Direction_Bool_Exp>;
  directionId?: Maybe<Uuid_Comparison_Exp>;
  email?: Maybe<String_Comparison_Exp>;
  emailVerified?: Maybe<Boolean_Comparison_Exp>;
  employer?: Maybe<String_Comparison_Exp>;
  employmentDate?: Maybe<Timestamptz_Comparison_Exp>;
  endDate?: Maybe<Timestamptz_Comparison_Exp>;
  fa?: Maybe<Fa_Bool_Exp>;
  faId?: Maybe<Uuid_Comparison_Exp>;
  firstConnectedAt?: Maybe<Timestamptz_Comparison_Exp>;
  firstName?: Maybe<String_Comparison_Exp>;
  gender?: Maybe<String_Comparison_Exp>;
  hasAcceptedPolicy?: Maybe<Boolean_Comparison_Exp>;
  hasSeenOnboarding?: Maybe<Boolean_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  identity?: Maybe<Identity_Bool_Exp>;
  isActive?: Maybe<Boolean_Comparison_Exp>;
  isAdmin?: Maybe<Boolean_Comparison_Exp>;
  isManager?: Maybe<Boolean_Comparison_Exp>;
  isParisCityHall?: Maybe<Boolean_Comparison_Exp>;
  isStudent?: Maybe<Boolean_Comparison_Exp>;
  isSuperAdmin?: Maybe<Boolean_Comparison_Exp>;
  language?: Maybe<String_Comparison_Exp>;
  lastConnectedAt?: Maybe<Timestamptz_Comparison_Exp>;
  lastName?: Maybe<String_Comparison_Exp>;
  matricule?: Maybe<String_Comparison_Exp>;
  organisationId?: Maybe<Uuid_Comparison_Exp>;
  registrationNumber?: Maybe<String_Comparison_Exp>;
  role?: Maybe<Role_Bool_Exp>;
  roleId?: Maybe<Uuid_Comparison_Exp>;
  startDate?: Maybe<Timestamptz_Comparison_Exp>;
  type?: Maybe<String_Comparison_Exp>;
  unaccentFirstName?: Maybe<String_Comparison_Exp>;
  unaccentLastName?: Maybe<String_Comparison_Exp>;
  updatedAt?: Maybe<Timestamptz_Comparison_Exp>;
  userGroups?: Maybe<UserGroup_Bool_Exp>;
  userGroups_aggregate?: Maybe<UserGroup_Aggregate_Bool_Exp>;
  user_courses_creator?: Maybe<Course_Bool_Exp>;
  user_courses_creator_aggregate?: Maybe<Course_Aggregate_Bool_Exp>;
  volunteer?: Maybe<VolunteerPost_Bool_Exp>;
};

/** unique or primary key constraints on table "user" */
export enum User_Constraint {
  /** unique or primary key constraint on columns "email" */
  UserEmailKey = 'user_email_key',
  /** unique or primary key constraint on columns "id" */
  UserPkey = 'user_pkey',
  /** unique or primary key constraint on columns "registrationNumber" */
  UserRegistrationNumberKey = 'user_registrationNumber_key'
}

/** input type for incrementing numeric columns in table "user" */
export type User_Inc_Input = {
  connectedCount?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "user" */
export type User_Insert_Input = {
  birthDate?: Maybe<Scalars['timestamptz']>;
  cadre?: Maybe<Scalars['String']>;
  classification?: Maybe<Scalars['String']>;
  connectedCount?: Maybe<Scalars['Int']>;
  contract?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  deletedAt?: Maybe<Scalars['timestamptz']>;
  direction?: Maybe<Direction_Obj_Rel_Insert_Input>;
  directionId?: Maybe<Scalars['uuid']>;
  email?: Maybe<Scalars['String']>;
  emailVerified?: Maybe<Scalars['Boolean']>;
  employer?: Maybe<Scalars['String']>;
  employmentDate?: Maybe<Scalars['timestamptz']>;
  endDate?: Maybe<Scalars['timestamptz']>;
  fa?: Maybe<Fa_Obj_Rel_Insert_Input>;
  faId?: Maybe<Scalars['uuid']>;
  firstConnectedAt?: Maybe<Scalars['timestamptz']>;
  firstName?: Maybe<Scalars['String']>;
  gender?: Maybe<Scalars['String']>;
  hasAcceptedPolicy?: Maybe<Scalars['Boolean']>;
  hasSeenOnboarding?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['uuid']>;
  identity?: Maybe<Identity_Obj_Rel_Insert_Input>;
  isActive?: Maybe<Scalars['Boolean']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  isManager?: Maybe<Scalars['Boolean']>;
  isParisCityHall?: Maybe<Scalars['Boolean']>;
  isStudent?: Maybe<Scalars['Boolean']>;
  isSuperAdmin?: Maybe<Scalars['Boolean']>;
  language?: Maybe<Scalars['String']>;
  lastConnectedAt?: Maybe<Scalars['timestamptz']>;
  lastName?: Maybe<Scalars['String']>;
  matricule?: Maybe<Scalars['String']>;
  organisationId?: Maybe<Scalars['uuid']>;
  registrationNumber?: Maybe<Scalars['String']>;
  role?: Maybe<Role_Obj_Rel_Insert_Input>;
  roleId?: Maybe<Scalars['uuid']>;
  startDate?: Maybe<Scalars['timestamptz']>;
  type?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  userGroups?: Maybe<UserGroup_Arr_Rel_Insert_Input>;
  user_courses_creator?: Maybe<Course_Arr_Rel_Insert_Input>;
  volunteer?: Maybe<VolunteerPost_Obj_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type User_Max_Fields = {
  __typename?: 'user_max_fields';
  birthDate?: Maybe<Scalars['timestamptz']>;
  cadre?: Maybe<Scalars['String']>;
  classification?: Maybe<Scalars['String']>;
  connectedCount?: Maybe<Scalars['Int']>;
  contract?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  deletedAt?: Maybe<Scalars['timestamptz']>;
  directionId?: Maybe<Scalars['uuid']>;
  email?: Maybe<Scalars['String']>;
  employer?: Maybe<Scalars['String']>;
  employmentDate?: Maybe<Scalars['timestamptz']>;
  endDate?: Maybe<Scalars['timestamptz']>;
  faId?: Maybe<Scalars['uuid']>;
  firstConnectedAt?: Maybe<Scalars['timestamptz']>;
  firstName?: Maybe<Scalars['String']>;
  gender?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  language?: Maybe<Scalars['String']>;
  lastConnectedAt?: Maybe<Scalars['timestamptz']>;
  lastName?: Maybe<Scalars['String']>;
  matricule?: Maybe<Scalars['String']>;
  organisationId?: Maybe<Scalars['uuid']>;
  registrationNumber?: Maybe<Scalars['String']>;
  roleId?: Maybe<Scalars['uuid']>;
  startDate?: Maybe<Scalars['timestamptz']>;
  type?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type User_Min_Fields = {
  __typename?: 'user_min_fields';
  birthDate?: Maybe<Scalars['timestamptz']>;
  cadre?: Maybe<Scalars['String']>;
  classification?: Maybe<Scalars['String']>;
  connectedCount?: Maybe<Scalars['Int']>;
  contract?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  deletedAt?: Maybe<Scalars['timestamptz']>;
  directionId?: Maybe<Scalars['uuid']>;
  email?: Maybe<Scalars['String']>;
  employer?: Maybe<Scalars['String']>;
  employmentDate?: Maybe<Scalars['timestamptz']>;
  endDate?: Maybe<Scalars['timestamptz']>;
  faId?: Maybe<Scalars['uuid']>;
  firstConnectedAt?: Maybe<Scalars['timestamptz']>;
  firstName?: Maybe<Scalars['String']>;
  gender?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  language?: Maybe<Scalars['String']>;
  lastConnectedAt?: Maybe<Scalars['timestamptz']>;
  lastName?: Maybe<Scalars['String']>;
  matricule?: Maybe<Scalars['String']>;
  organisationId?: Maybe<Scalars['uuid']>;
  registrationNumber?: Maybe<Scalars['String']>;
  roleId?: Maybe<Scalars['uuid']>;
  startDate?: Maybe<Scalars['timestamptz']>;
  type?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "user" */
export type User_Mutation_Response = {
  __typename?: 'user_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<User>;
};

/** input type for inserting object relation for remote table "user" */
export type User_Obj_Rel_Insert_Input = {
  data: User_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<User_On_Conflict>;
};

/** on_conflict condition type for table "user" */
export type User_On_Conflict = {
  constraint: User_Constraint;
  update_columns?: Array<User_Update_Column>;
  where?: Maybe<User_Bool_Exp>;
};

/** Ordering options when selecting data from "user". */
export type User_Order_By = {
  birthDate?: Maybe<Order_By>;
  cadre?: Maybe<Order_By>;
  classification?: Maybe<Order_By>;
  connectedCount?: Maybe<Order_By>;
  contract?: Maybe<Order_By>;
  createdAt?: Maybe<Order_By>;
  deletedAt?: Maybe<Order_By>;
  direction?: Maybe<Direction_Order_By>;
  directionId?: Maybe<Order_By>;
  email?: Maybe<Order_By>;
  emailVerified?: Maybe<Order_By>;
  employer?: Maybe<Order_By>;
  employmentDate?: Maybe<Order_By>;
  endDate?: Maybe<Order_By>;
  fa?: Maybe<Fa_Order_By>;
  faId?: Maybe<Order_By>;
  firstConnectedAt?: Maybe<Order_By>;
  firstName?: Maybe<Order_By>;
  gender?: Maybe<Order_By>;
  hasAcceptedPolicy?: Maybe<Order_By>;
  hasSeenOnboarding?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  identity?: Maybe<Identity_Order_By>;
  isActive?: Maybe<Order_By>;
  isAdmin?: Maybe<Order_By>;
  isManager?: Maybe<Order_By>;
  isParisCityHall?: Maybe<Order_By>;
  isStudent?: Maybe<Order_By>;
  isSuperAdmin?: Maybe<Order_By>;
  language?: Maybe<Order_By>;
  lastConnectedAt?: Maybe<Order_By>;
  lastName?: Maybe<Order_By>;
  matricule?: Maybe<Order_By>;
  organisationId?: Maybe<Order_By>;
  registrationNumber?: Maybe<Order_By>;
  role?: Maybe<Role_Order_By>;
  roleId?: Maybe<Order_By>;
  startDate?: Maybe<Order_By>;
  type?: Maybe<Order_By>;
  unaccentFirstName?: Maybe<Order_By>;
  unaccentLastName?: Maybe<Order_By>;
  updatedAt?: Maybe<Order_By>;
  userGroups_aggregate?: Maybe<UserGroup_Aggregate_Order_By>;
  user_courses_creator_aggregate?: Maybe<Course_Aggregate_Order_By>;
  volunteer?: Maybe<VolunteerPost_Order_By>;
};

/** primary key columns input for table: user */
export type User_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "user" */
export enum User_Select_Column {
  /** column name */
  BirthDate = 'birthDate',
  /** column name */
  Cadre = 'cadre',
  /** column name */
  Classification = 'classification',
  /** column name */
  ConnectedCount = 'connectedCount',
  /** column name */
  Contract = 'contract',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  DeletedAt = 'deletedAt',
  /** column name */
  DirectionId = 'directionId',
  /** column name */
  Email = 'email',
  /** column name */
  EmailVerified = 'emailVerified',
  /** column name */
  Employer = 'employer',
  /** column name */
  EmploymentDate = 'employmentDate',
  /** column name */
  EndDate = 'endDate',
  /** column name */
  FaId = 'faId',
  /** column name */
  FirstConnectedAt = 'firstConnectedAt',
  /** column name */
  FirstName = 'firstName',
  /** column name */
  Gender = 'gender',
  /** column name */
  HasAcceptedPolicy = 'hasAcceptedPolicy',
  /** column name */
  HasSeenOnboarding = 'hasSeenOnboarding',
  /** column name */
  Id = 'id',
  /** column name */
  IsActive = 'isActive',
  /** column name */
  IsAdmin = 'isAdmin',
  /** column name */
  IsManager = 'isManager',
  /** column name */
  IsParisCityHall = 'isParisCityHall',
  /** column name */
  IsStudent = 'isStudent',
  /** column name */
  IsSuperAdmin = 'isSuperAdmin',
  /** column name */
  Language = 'language',
  /** column name */
  LastConnectedAt = 'lastConnectedAt',
  /** column name */
  LastName = 'lastName',
  /** column name */
  Matricule = 'matricule',
  /** column name */
  OrganisationId = 'organisationId',
  /** column name */
  RegistrationNumber = 'registrationNumber',
  /** column name */
  RoleId = 'roleId',
  /** column name */
  StartDate = 'startDate',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updatedAt'
}

/** input type for updating data in table "user" */
export type User_Set_Input = {
  birthDate?: Maybe<Scalars['timestamptz']>;
  cadre?: Maybe<Scalars['String']>;
  classification?: Maybe<Scalars['String']>;
  connectedCount?: Maybe<Scalars['Int']>;
  contract?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  deletedAt?: Maybe<Scalars['timestamptz']>;
  directionId?: Maybe<Scalars['uuid']>;
  email?: Maybe<Scalars['String']>;
  emailVerified?: Maybe<Scalars['Boolean']>;
  employer?: Maybe<Scalars['String']>;
  employmentDate?: Maybe<Scalars['timestamptz']>;
  endDate?: Maybe<Scalars['timestamptz']>;
  faId?: Maybe<Scalars['uuid']>;
  firstConnectedAt?: Maybe<Scalars['timestamptz']>;
  firstName?: Maybe<Scalars['String']>;
  gender?: Maybe<Scalars['String']>;
  hasAcceptedPolicy?: Maybe<Scalars['Boolean']>;
  hasSeenOnboarding?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['uuid']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  isManager?: Maybe<Scalars['Boolean']>;
  isParisCityHall?: Maybe<Scalars['Boolean']>;
  isStudent?: Maybe<Scalars['Boolean']>;
  isSuperAdmin?: Maybe<Scalars['Boolean']>;
  language?: Maybe<Scalars['String']>;
  lastConnectedAt?: Maybe<Scalars['timestamptz']>;
  lastName?: Maybe<Scalars['String']>;
  matricule?: Maybe<Scalars['String']>;
  organisationId?: Maybe<Scalars['uuid']>;
  registrationNumber?: Maybe<Scalars['String']>;
  roleId?: Maybe<Scalars['uuid']>;
  startDate?: Maybe<Scalars['timestamptz']>;
  type?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type User_Stddev_Fields = {
  __typename?: 'user_stddev_fields';
  connectedCount?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type User_Stddev_Pop_Fields = {
  __typename?: 'user_stddev_pop_fields';
  connectedCount?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type User_Stddev_Samp_Fields = {
  __typename?: 'user_stddev_samp_fields';
  connectedCount?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "user" */
export type User_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: User_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type User_Stream_Cursor_Value_Input = {
  birthDate?: Maybe<Scalars['timestamptz']>;
  cadre?: Maybe<Scalars['String']>;
  classification?: Maybe<Scalars['String']>;
  connectedCount?: Maybe<Scalars['Int']>;
  contract?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  deletedAt?: Maybe<Scalars['timestamptz']>;
  directionId?: Maybe<Scalars['uuid']>;
  email?: Maybe<Scalars['String']>;
  emailVerified?: Maybe<Scalars['Boolean']>;
  employer?: Maybe<Scalars['String']>;
  employmentDate?: Maybe<Scalars['timestamptz']>;
  endDate?: Maybe<Scalars['timestamptz']>;
  faId?: Maybe<Scalars['uuid']>;
  firstConnectedAt?: Maybe<Scalars['timestamptz']>;
  firstName?: Maybe<Scalars['String']>;
  gender?: Maybe<Scalars['String']>;
  hasAcceptedPolicy?: Maybe<Scalars['Boolean']>;
  hasSeenOnboarding?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['uuid']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  isManager?: Maybe<Scalars['Boolean']>;
  isParisCityHall?: Maybe<Scalars['Boolean']>;
  isStudent?: Maybe<Scalars['Boolean']>;
  isSuperAdmin?: Maybe<Scalars['Boolean']>;
  language?: Maybe<Scalars['String']>;
  lastConnectedAt?: Maybe<Scalars['timestamptz']>;
  lastName?: Maybe<Scalars['String']>;
  matricule?: Maybe<Scalars['String']>;
  organisationId?: Maybe<Scalars['uuid']>;
  registrationNumber?: Maybe<Scalars['String']>;
  roleId?: Maybe<Scalars['uuid']>;
  startDate?: Maybe<Scalars['timestamptz']>;
  type?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type User_Sum_Fields = {
  __typename?: 'user_sum_fields';
  connectedCount?: Maybe<Scalars['Int']>;
};

/** update columns of table "user" */
export enum User_Update_Column {
  /** column name */
  BirthDate = 'birthDate',
  /** column name */
  Cadre = 'cadre',
  /** column name */
  Classification = 'classification',
  /** column name */
  ConnectedCount = 'connectedCount',
  /** column name */
  Contract = 'contract',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  DeletedAt = 'deletedAt',
  /** column name */
  DirectionId = 'directionId',
  /** column name */
  Email = 'email',
  /** column name */
  EmailVerified = 'emailVerified',
  /** column name */
  Employer = 'employer',
  /** column name */
  EmploymentDate = 'employmentDate',
  /** column name */
  EndDate = 'endDate',
  /** column name */
  FaId = 'faId',
  /** column name */
  FirstConnectedAt = 'firstConnectedAt',
  /** column name */
  FirstName = 'firstName',
  /** column name */
  Gender = 'gender',
  /** column name */
  HasAcceptedPolicy = 'hasAcceptedPolicy',
  /** column name */
  HasSeenOnboarding = 'hasSeenOnboarding',
  /** column name */
  Id = 'id',
  /** column name */
  IsActive = 'isActive',
  /** column name */
  IsAdmin = 'isAdmin',
  /** column name */
  IsManager = 'isManager',
  /** column name */
  IsParisCityHall = 'isParisCityHall',
  /** column name */
  IsStudent = 'isStudent',
  /** column name */
  IsSuperAdmin = 'isSuperAdmin',
  /** column name */
  Language = 'language',
  /** column name */
  LastConnectedAt = 'lastConnectedAt',
  /** column name */
  LastName = 'lastName',
  /** column name */
  Matricule = 'matricule',
  /** column name */
  OrganisationId = 'organisationId',
  /** column name */
  RegistrationNumber = 'registrationNumber',
  /** column name */
  RoleId = 'roleId',
  /** column name */
  StartDate = 'startDate',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updatedAt'
}

export type User_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<User_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<User_Set_Input>;
  /** filter the rows which have to be updated */
  where: User_Bool_Exp;
};

/** aggregate var_pop on columns */
export type User_Var_Pop_Fields = {
  __typename?: 'user_var_pop_fields';
  connectedCount?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type User_Var_Samp_Fields = {
  __typename?: 'user_var_samp_fields';
  connectedCount?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type User_Variance_Fields = {
  __typename?: 'user_variance_fields';
  connectedCount?: Maybe<Scalars['Float']>;
};


/** Boolean expression to compare columns of type "uuid". All fields are combined with logical 'AND'. */
export type Uuid_Comparison_Exp = {
  _eq?: Maybe<Scalars['uuid']>;
  _gt?: Maybe<Scalars['uuid']>;
  _gte?: Maybe<Scalars['uuid']>;
  _in?: Maybe<Array<Scalars['uuid']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['uuid']>;
  _lte?: Maybe<Scalars['uuid']>;
  _neq?: Maybe<Scalars['uuid']>;
  _nin?: Maybe<Array<Scalars['uuid']>>;
};

/** columns and relationships of "volunteerPost" */
export type VolunteerPost = {
  __typename?: 'volunteerPost';
  createdAt: Scalars['timestamptz'];
  event: Scalars['String'];
  faId: Scalars['uuid'];
  id: Scalars['uuid'];
  /** An object relationship */
  jobTitle: JobTitle;
  jobTitleId: Scalars['uuid'];
  /** An object relationship */
  site: Site;
  siteId: Scalars['uuid'];
  status?: Maybe<Scalars['String']>;
  teamLeader: Scalars['Boolean'];
  updatedAt: Scalars['timestamptz'];
  /** An object relationship */
  volunteer: User;
  volunteerId: Scalars['uuid'];
};

/** aggregated selection of "volunteerPost" */
export type VolunteerPost_Aggregate = {
  __typename?: 'volunteerPost_aggregate';
  aggregate?: Maybe<VolunteerPost_Aggregate_Fields>;
  nodes: Array<VolunteerPost>;
};

export type VolunteerPost_Aggregate_Bool_Exp = {
  bool_and?: Maybe<VolunteerPost_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: Maybe<VolunteerPost_Aggregate_Bool_Exp_Bool_Or>;
  count?: Maybe<VolunteerPost_Aggregate_Bool_Exp_Count>;
};

export type VolunteerPost_Aggregate_Bool_Exp_Bool_And = {
  arguments: VolunteerPost_Select_Column_VolunteerPost_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<VolunteerPost_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type VolunteerPost_Aggregate_Bool_Exp_Bool_Or = {
  arguments: VolunteerPost_Select_Column_VolunteerPost_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<VolunteerPost_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type VolunteerPost_Aggregate_Bool_Exp_Count = {
  arguments?: Maybe<Array<VolunteerPost_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<VolunteerPost_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "volunteerPost" */
export type VolunteerPost_Aggregate_Fields = {
  __typename?: 'volunteerPost_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<VolunteerPost_Max_Fields>;
  min?: Maybe<VolunteerPost_Min_Fields>;
};


/** aggregate fields of "volunteerPost" */
export type VolunteerPost_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<VolunteerPost_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "volunteerPost" */
export type VolunteerPost_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<VolunteerPost_Max_Order_By>;
  min?: Maybe<VolunteerPost_Min_Order_By>;
};

/** input type for inserting array relation for remote table "volunteerPost" */
export type VolunteerPost_Arr_Rel_Insert_Input = {
  data: Array<VolunteerPost_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<VolunteerPost_On_Conflict>;
};

/** Boolean expression to filter rows from the table "volunteerPost". All fields are combined with a logical 'AND'. */
export type VolunteerPost_Bool_Exp = {
  _and?: Maybe<Array<VolunteerPost_Bool_Exp>>;
  _not?: Maybe<VolunteerPost_Bool_Exp>;
  _or?: Maybe<Array<VolunteerPost_Bool_Exp>>;
  createdAt?: Maybe<Timestamptz_Comparison_Exp>;
  event?: Maybe<String_Comparison_Exp>;
  faId?: Maybe<Uuid_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  jobTitle?: Maybe<JobTitle_Bool_Exp>;
  jobTitleId?: Maybe<Uuid_Comparison_Exp>;
  site?: Maybe<Site_Bool_Exp>;
  siteId?: Maybe<Uuid_Comparison_Exp>;
  status?: Maybe<String_Comparison_Exp>;
  teamLeader?: Maybe<Boolean_Comparison_Exp>;
  updatedAt?: Maybe<Timestamptz_Comparison_Exp>;
  volunteer?: Maybe<User_Bool_Exp>;
  volunteerId?: Maybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "volunteerPost" */
export enum VolunteerPost_Constraint {
  /** unique or primary key constraint on columns "id" */
  VolunteerPostPkey = 'volunteerPost_pkey'
}

/** input type for inserting data into table "volunteerPost" */
export type VolunteerPost_Insert_Input = {
  createdAt?: Maybe<Scalars['timestamptz']>;
  event?: Maybe<Scalars['String']>;
  faId?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  jobTitle?: Maybe<JobTitle_Obj_Rel_Insert_Input>;
  jobTitleId?: Maybe<Scalars['uuid']>;
  site?: Maybe<Site_Obj_Rel_Insert_Input>;
  siteId?: Maybe<Scalars['uuid']>;
  status?: Maybe<Scalars['String']>;
  teamLeader?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  volunteer?: Maybe<User_Obj_Rel_Insert_Input>;
  volunteerId?: Maybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type VolunteerPost_Max_Fields = {
  __typename?: 'volunteerPost_max_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  event?: Maybe<Scalars['String']>;
  faId?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  jobTitleId?: Maybe<Scalars['uuid']>;
  siteId?: Maybe<Scalars['uuid']>;
  status?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  volunteerId?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "volunteerPost" */
export type VolunteerPost_Max_Order_By = {
  createdAt?: Maybe<Order_By>;
  event?: Maybe<Order_By>;
  faId?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  jobTitleId?: Maybe<Order_By>;
  siteId?: Maybe<Order_By>;
  status?: Maybe<Order_By>;
  updatedAt?: Maybe<Order_By>;
  volunteerId?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type VolunteerPost_Min_Fields = {
  __typename?: 'volunteerPost_min_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  event?: Maybe<Scalars['String']>;
  faId?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  jobTitleId?: Maybe<Scalars['uuid']>;
  siteId?: Maybe<Scalars['uuid']>;
  status?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  volunteerId?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "volunteerPost" */
export type VolunteerPost_Min_Order_By = {
  createdAt?: Maybe<Order_By>;
  event?: Maybe<Order_By>;
  faId?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  jobTitleId?: Maybe<Order_By>;
  siteId?: Maybe<Order_By>;
  status?: Maybe<Order_By>;
  updatedAt?: Maybe<Order_By>;
  volunteerId?: Maybe<Order_By>;
};

/** response of any mutation on the table "volunteerPost" */
export type VolunteerPost_Mutation_Response = {
  __typename?: 'volunteerPost_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<VolunteerPost>;
};

/** input type for inserting object relation for remote table "volunteerPost" */
export type VolunteerPost_Obj_Rel_Insert_Input = {
  data: VolunteerPost_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<VolunteerPost_On_Conflict>;
};

/** on_conflict condition type for table "volunteerPost" */
export type VolunteerPost_On_Conflict = {
  constraint: VolunteerPost_Constraint;
  update_columns?: Array<VolunteerPost_Update_Column>;
  where?: Maybe<VolunteerPost_Bool_Exp>;
};

/** Ordering options when selecting data from "volunteerPost". */
export type VolunteerPost_Order_By = {
  createdAt?: Maybe<Order_By>;
  event?: Maybe<Order_By>;
  faId?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  jobTitle?: Maybe<JobTitle_Order_By>;
  jobTitleId?: Maybe<Order_By>;
  site?: Maybe<Site_Order_By>;
  siteId?: Maybe<Order_By>;
  status?: Maybe<Order_By>;
  teamLeader?: Maybe<Order_By>;
  updatedAt?: Maybe<Order_By>;
  volunteer?: Maybe<User_Order_By>;
  volunteerId?: Maybe<Order_By>;
};

/** primary key columns input for table: volunteerPost */
export type VolunteerPost_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "volunteerPost" */
export enum VolunteerPost_Select_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Event = 'event',
  /** column name */
  FaId = 'faId',
  /** column name */
  Id = 'id',
  /** column name */
  JobTitleId = 'jobTitleId',
  /** column name */
  SiteId = 'siteId',
  /** column name */
  Status = 'status',
  /** column name */
  TeamLeader = 'teamLeader',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  VolunteerId = 'volunteerId'
}

/** select "volunteerPost_aggregate_bool_exp_bool_and_arguments_columns" columns of table "volunteerPost" */
export enum VolunteerPost_Select_Column_VolunteerPost_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  TeamLeader = 'teamLeader'
}

/** select "volunteerPost_aggregate_bool_exp_bool_or_arguments_columns" columns of table "volunteerPost" */
export enum VolunteerPost_Select_Column_VolunteerPost_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  TeamLeader = 'teamLeader'
}

/** input type for updating data in table "volunteerPost" */
export type VolunteerPost_Set_Input = {
  createdAt?: Maybe<Scalars['timestamptz']>;
  event?: Maybe<Scalars['String']>;
  faId?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  jobTitleId?: Maybe<Scalars['uuid']>;
  siteId?: Maybe<Scalars['uuid']>;
  status?: Maybe<Scalars['String']>;
  teamLeader?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  volunteerId?: Maybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "volunteerPost" */
export type VolunteerPost_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: VolunteerPost_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type VolunteerPost_Stream_Cursor_Value_Input = {
  createdAt?: Maybe<Scalars['timestamptz']>;
  event?: Maybe<Scalars['String']>;
  faId?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  jobTitleId?: Maybe<Scalars['uuid']>;
  siteId?: Maybe<Scalars['uuid']>;
  status?: Maybe<Scalars['String']>;
  teamLeader?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  volunteerId?: Maybe<Scalars['uuid']>;
};

/** update columns of table "volunteerPost" */
export enum VolunteerPost_Update_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Event = 'event',
  /** column name */
  FaId = 'faId',
  /** column name */
  Id = 'id',
  /** column name */
  JobTitleId = 'jobTitleId',
  /** column name */
  SiteId = 'siteId',
  /** column name */
  Status = 'status',
  /** column name */
  TeamLeader = 'teamLeader',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  VolunteerId = 'volunteerId'
}

export type VolunteerPost_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<VolunteerPost_Set_Input>;
  /** filter the rows which have to be updated */
  where: VolunteerPost_Bool_Exp;
};

/** columns and relationships of "xapi_statement" */
export type Xapi_Statement = {
  __typename?: 'xapi_statement';
  id: Scalars['uuid'];
  is_voided: Scalars['Boolean'];
  payload: Scalars['json'];
  registration?: Maybe<Scalars['uuid']>;
  statement_id: Scalars['uuid'];
  verb_iri: Scalars['String'];
};


/** columns and relationships of "xapi_statement" */
export type Xapi_StatementPayloadArgs = {
  path?: Maybe<Scalars['String']>;
};

/** aggregated selection of "xapi_statement" */
export type Xapi_Statement_Aggregate = {
  __typename?: 'xapi_statement_aggregate';
  aggregate?: Maybe<Xapi_Statement_Aggregate_Fields>;
  nodes: Array<Xapi_Statement>;
};

/** aggregate fields of "xapi_statement" */
export type Xapi_Statement_Aggregate_Fields = {
  __typename?: 'xapi_statement_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Xapi_Statement_Max_Fields>;
  min?: Maybe<Xapi_Statement_Min_Fields>;
};


/** aggregate fields of "xapi_statement" */
export type Xapi_Statement_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Xapi_Statement_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "xapi_statement". All fields are combined with a logical 'AND'. */
export type Xapi_Statement_Bool_Exp = {
  _and?: Maybe<Array<Xapi_Statement_Bool_Exp>>;
  _not?: Maybe<Xapi_Statement_Bool_Exp>;
  _or?: Maybe<Array<Xapi_Statement_Bool_Exp>>;
  id?: Maybe<Uuid_Comparison_Exp>;
  is_voided?: Maybe<Boolean_Comparison_Exp>;
  payload?: Maybe<Json_Comparison_Exp>;
  registration?: Maybe<Uuid_Comparison_Exp>;
  statement_id?: Maybe<Uuid_Comparison_Exp>;
  verb_iri?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "xapi_statement" */
export enum Xapi_Statement_Constraint {
  /** unique or primary key constraint on columns "id" */
  XapiStatementPkey = 'xapi_statement_pkey',
  /** unique or primary key constraint on columns "statement_id" */
  XapiStatementStatementIdKey = 'xapi_statement_statement_id_key'
}

/** input type for inserting data into table "xapi_statement" */
export type Xapi_Statement_Insert_Input = {
  id?: Maybe<Scalars['uuid']>;
  is_voided?: Maybe<Scalars['Boolean']>;
  payload?: Maybe<Scalars['json']>;
  registration?: Maybe<Scalars['uuid']>;
  statement_id?: Maybe<Scalars['uuid']>;
  verb_iri?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Xapi_Statement_Max_Fields = {
  __typename?: 'xapi_statement_max_fields';
  id?: Maybe<Scalars['uuid']>;
  registration?: Maybe<Scalars['uuid']>;
  statement_id?: Maybe<Scalars['uuid']>;
  verb_iri?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Xapi_Statement_Min_Fields = {
  __typename?: 'xapi_statement_min_fields';
  id?: Maybe<Scalars['uuid']>;
  registration?: Maybe<Scalars['uuid']>;
  statement_id?: Maybe<Scalars['uuid']>;
  verb_iri?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "xapi_statement" */
export type Xapi_Statement_Mutation_Response = {
  __typename?: 'xapi_statement_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Xapi_Statement>;
};

/** on_conflict condition type for table "xapi_statement" */
export type Xapi_Statement_On_Conflict = {
  constraint: Xapi_Statement_Constraint;
  update_columns?: Array<Xapi_Statement_Update_Column>;
  where?: Maybe<Xapi_Statement_Bool_Exp>;
};

/** Ordering options when selecting data from "xapi_statement". */
export type Xapi_Statement_Order_By = {
  id?: Maybe<Order_By>;
  is_voided?: Maybe<Order_By>;
  payload?: Maybe<Order_By>;
  registration?: Maybe<Order_By>;
  statement_id?: Maybe<Order_By>;
  verb_iri?: Maybe<Order_By>;
};

/** primary key columns input for table: xapi_statement */
export type Xapi_Statement_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "xapi_statement" */
export enum Xapi_Statement_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  IsVoided = 'is_voided',
  /** column name */
  Payload = 'payload',
  /** column name */
  Registration = 'registration',
  /** column name */
  StatementId = 'statement_id',
  /** column name */
  VerbIri = 'verb_iri'
}

/** input type for updating data in table "xapi_statement" */
export type Xapi_Statement_Set_Input = {
  id?: Maybe<Scalars['uuid']>;
  is_voided?: Maybe<Scalars['Boolean']>;
  payload?: Maybe<Scalars['json']>;
  registration?: Maybe<Scalars['uuid']>;
  statement_id?: Maybe<Scalars['uuid']>;
  verb_iri?: Maybe<Scalars['String']>;
};

/** Streaming cursor of the table "xapi_statement" */
export type Xapi_Statement_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Xapi_Statement_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Xapi_Statement_Stream_Cursor_Value_Input = {
  id?: Maybe<Scalars['uuid']>;
  is_voided?: Maybe<Scalars['Boolean']>;
  payload?: Maybe<Scalars['json']>;
  registration?: Maybe<Scalars['uuid']>;
  statement_id?: Maybe<Scalars['uuid']>;
  verb_iri?: Maybe<Scalars['String']>;
};

/** update columns of table "xapi_statement" */
export enum Xapi_Statement_Update_Column {
  /** column name */
  Id = 'id',
  /** column name */
  IsVoided = 'is_voided',
  /** column name */
  Payload = 'payload',
  /** column name */
  Registration = 'registration',
  /** column name */
  StatementId = 'statement_id',
  /** column name */
  VerbIri = 'verb_iri'
}

export type Xapi_Statement_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Xapi_Statement_Set_Input>;
  /** filter the rows which have to be updated */
  where: Xapi_Statement_Bool_Exp;
};

export type InsertDataQueryAccessRequestMutationVariables = Exact<{
  status: Scalars['String'];
  type: Scalars['String'];
}>;


export type InsertDataQueryAccessRequestMutation = (
  { __typename?: 'mutation_root' }
  & { saveDataAccessRequest?: Maybe<(
    { __typename?: 'DataAccessRequestResponse' }
    & Pick<DataAccessRequestResponse, 'success'>
  )> }
);

export type GetUserDataAccessRequestQueryVariables = Exact<{
  type: Scalars['String'];
}>;


export type GetUserDataAccessRequestQuery = (
  { __typename?: 'query_root' }
  & { dataAccessRequest: Array<(
    { __typename?: 'dataAccessRequest' }
    & Pick<DataAccessRequest, 'id' | 'created_at'>
  )> }
);

export type QueryCmsPageByKeyQueryVariables = Exact<{
  key: Scalars['String'];
}>;


export type QueryCmsPageByKeyQuery = (
  { __typename?: 'query_root' }
  & { cmsPage: Array<(
    { __typename?: 'cmsPage' }
    & Pick<CmsPage, 'textI18n' | 'titleI18n'>
  )> }
);

export type QueryFaqByLanguageQueryVariables = Exact<{
  language: Scalars['String'];
}>;


export type QueryFaqByLanguageQuery = (
  { __typename?: 'query_root' }
  & { faq: Array<(
    { __typename?: 'faq' }
    & Pick<Faq, 'id' | 'question' | 'response'>
  )> }
);

export type QueryBannerDataQueryVariables = Exact<{
  language: Scalars['String'];
}>;


export type QueryBannerDataQuery = (
  { __typename?: 'query_root' }
  & { home: Array<(
    { __typename?: 'home' }
    & Pick<Home, 'desktopImageUri' | 'mobileImageUri'>
    & { i18n: (
      { __typename?: 'i18n' }
      & { translations: Array<(
        { __typename?: 'translation' }
        & Pick<Translation, 'value' | 'locale'>
      )> }
    ) }
  )> }
);

export type GetCurrentCourseQueryVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type GetCurrentCourseQuery = (
  { __typename?: 'query_root' }
  & { course: Array<(
    { __typename?: 'course' }
    & Pick<Course, 'id' | 'imageHeadUri' | 'publicationDate' | 'duration' | 'progression' | 'descriptionI18n' | 'descriptionShortI18n' | 'titleI18n' | 'category' | 'status'>
    & { skill_of_courses: Array<(
      { __typename?: 'skill_of_course' }
      & Pick<Skill_Of_Course, 'level' | 'levelDescription'>
      & { skill: (
        { __typename?: 'skill' }
        & Pick<Skill, 'nameI18n'>
      ) }
    )>, skill_of_courses_aggregate: (
      { __typename?: 'skill_of_course_aggregate' }
      & { aggregate?: Maybe<(
        { __typename?: 'skill_of_course_aggregate_fields' }
        & Pick<Skill_Of_Course_Aggregate_Fields, 'count'>
      )> }
    ), thematic?: Maybe<(
      { __typename?: 'thematic' }
      & Pick<Thematic, 'labelI18n'>
    )>, sequences_aggregate: (
      { __typename?: 'sequence_aggregate' }
      & { aggregate?: Maybe<(
        { __typename?: 'sequence_aggregate_fields' }
        & Pick<Sequence_Aggregate_Fields, 'count'>
      )> }
    ), sequences: Array<(
      { __typename?: 'sequence' }
      & Pick<Sequence, 'id' | 'orderInCourse' | 'titleI18n'>
      & { sequence_modules_aggregate: (
        { __typename?: 'sequence_module_aggregate' }
        & { aggregate?: Maybe<(
          { __typename?: 'sequence_module_aggregate_fields' }
          & Pick<Sequence_Module_Aggregate_Fields, 'count'>
        )> }
      ), sequence_modules: Array<(
        { __typename?: 'sequence_module' }
        & Pick<Sequence_Module, 'moduleId' | 'sequenceId'>
        & { module: (
          { __typename?: 'module' }
          & Pick<Module, 'duration' | 'titleI18n' | 'imageHeadUri' | 'id'>
          & { moduleContent?: Maybe<(
            { __typename?: 'moduleContent' }
            & Pick<ModuleContent, 'contentVideoId' | 'articleId' | 'contentEmbedId' | 'contentDocumentId' | 'contentPodcastId' | 'contentEventId' | 'contentCmi5Id'>
            & { contentEmbed?: Maybe<(
              { __typename?: 'contentEmbed' }
              & Pick<ContentEmbed, 'subtype'>
            )> }
          )> }
        ) }
      )> }
    )> }
  )> }
);

export type GetCurrentCourseProgressQueryVariables = Exact<{
  id: Scalars['uuid'];
  where: UserModuleProgress_Bool_Exp;
  whereEvent: UserEventSlot_Bool_Exp;
}>;


export type GetCurrentCourseProgressQuery = (
  { __typename?: 'query_root' }
  & { course: Array<(
    { __typename?: 'course' }
    & Pick<Course, 'progression'>
    & { sequences: Array<(
      { __typename?: 'sequence' }
      & Pick<Sequence, 'id'>
      & { sequence_modules: Array<(
        { __typename?: 'sequence_module' }
        & { module: (
          { __typename?: 'module' }
          & Pick<Module, 'id'>
          & { userModuleProgress: Array<(
            { __typename?: 'userModuleProgress' }
            & Pick<UserModuleProgress, 'progression'>
          )>, moduleContent?: Maybe<(
            { __typename?: 'moduleContent' }
            & { contentEvent?: Maybe<(
              { __typename?: 'contentEvent' }
              & { contentEventSlots: Array<(
                { __typename?: 'contentEventSlot' }
                & Pick<ContentEventSlot, 'endDate'>
                & { userEventSlots: Array<(
                  { __typename?: 'userEventSlot' }
                  & Pick<UserEventSlot, 'id' | 'hasAttended'>
                )> }
              )> }
            )> }
          )> }
        ) }
      )> }
    )> }
  )> }
);

export type GetLastCultureCoursesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetLastCultureCoursesQuery = (
  { __typename?: 'query_root' }
  & { course: Array<(
    { __typename?: 'course' }
    & Pick<Course, 'descriptionShortI18n' | 'titleI18n' | 'imageHeadUri' | 'id' | 'duration'>
    & { thematic?: Maybe<(
      { __typename?: 'thematic' }
      & Pick<Thematic, 'labelI18n'>
    )> }
  )> }
);

export type GetMyValidatedSkillsQueryVariables = Exact<{
  userId: Scalars['uuid'];
}>;


export type GetMyValidatedSkillsQuery = (
  { __typename?: 'query_root' }
  & { userSkill_aggregate: (
    { __typename?: 'userSkill_aggregate' }
    & { aggregate?: Maybe<(
      { __typename?: 'userSkill_aggregate_fields' }
      & Pick<UserSkill_Aggregate_Fields, 'count'>
    )> }
  ) }
);

export type GetMyCoursesQueryVariables = Exact<{
  limit: Scalars['Int'];
  offset: Scalars['Int'];
  where?: Maybe<Course_Bool_Exp>;
  progressionWhere?: Maybe<Course_Bool_Exp>;
}>;


export type GetMyCoursesQuery = (
  { __typename?: 'query_root' }
  & { course: Array<(
    { __typename?: 'course' }
    & Pick<Course, 'descriptionI18n' | 'descriptionShortI18n' | 'titleI18n' | 'imageHeadUri' | 'id' | 'duration' | 'progression'>
    & { skill_of_courses: Array<(
      { __typename?: 'skill_of_course' }
      & Pick<Skill_Of_Course, 'level' | 'levelDescription'>
      & { skill: (
        { __typename?: 'skill' }
        & Pick<Skill, 'nameI18n'>
      ) }
    )>, skill_of_courses_aggregate: (
      { __typename?: 'skill_of_course_aggregate' }
      & { aggregate?: Maybe<(
        { __typename?: 'skill_of_course_aggregate_fields' }
        & Pick<Skill_Of_Course_Aggregate_Fields, 'count'>
      )> }
    ) }
  )>, course_aggregate: (
    { __typename?: 'course_aggregate' }
    & { aggregate?: Maybe<(
      { __typename?: 'course_aggregate_fields' }
      & { totalCount: Course_Aggregate_Fields['count'] }
    )> }
  ), courseProgression: Array<(
    { __typename?: 'course' }
    & Pick<Course, 'duration' | 'spendTime' | 'progression'>
  )>, courseSkill: Array<(
    { __typename?: 'course' }
    & { skill_of_courses: Array<(
      { __typename?: 'skill_of_course' }
      & { skill: (
        { __typename?: 'skill' }
        & Pick<Skill, 'id'>
      ) }
    )> }
  )> }
);

export type GetMyEventSlotsQueryVariables = Exact<{
  userId: Scalars['uuid'];
  limit: Scalars['Int'];
  offset: Scalars['Int'];
  today: Scalars['timestamptz'];
}>;


export type GetMyEventSlotsQuery = (
  { __typename?: 'query_root' }
  & { contentEventSlot: Array<(
    { __typename?: 'contentEventSlot' }
    & Pick<ContentEventSlot, 'id' | 'endDate' | 'type' | 'startDate' | 'room' | 'address' | 'webexLink'>
    & { contentEvent: (
      { __typename?: 'contentEvent' }
      & { moduleContents: Array<(
        { __typename?: 'moduleContent' }
        & { module: (
          { __typename?: 'module' }
          & Pick<Module, 'id'>
          & { sequence_modules: Array<(
            { __typename?: 'sequence_module' }
            & { sequence: (
              { __typename?: 'sequence' }
              & Pick<Sequence, 'id'>
              & { course: (
                { __typename?: 'course' }
                & Pick<Course, 'titleI18n' | 'id'>
              ) }
            ) }
          )> }
        ) }
      )> }
    ) }
  )>, contentEventSlot_aggregate: (
    { __typename?: 'contentEventSlot_aggregate' }
    & { aggregate?: Maybe<(
      { __typename?: 'contentEventSlot_aggregate_fields' }
      & { totalCount: ContentEventSlot_Aggregate_Fields['count'] }
    )> }
  ) }
);

export type GetMyEventSlotQueryVariables = Exact<{
  contentEventId?: Maybe<Scalars['uuid']>;
}>;


export type GetMyEventSlotQuery = (
  { __typename?: 'query_root' }
  & { userEventSlot: Array<(
    { __typename?: 'userEventSlot' }
    & Pick<UserEventSlot, 'id' | 'eventSlotId' | 'hasAttended'>
    & { contentEventSlot: (
      { __typename?: 'contentEventSlot' }
      & Pick<ContentEventSlot, 'endDate' | 'startDate' | 'type' | 'room' | 'address' | 'webexLink' | 'validateDate'>
    ) }
  )> }
);

export type UnsubscribeEventSlotMutationVariables = Exact<{
  eventSlotId?: Maybe<Scalars['uuid']>;
}>;


export type UnsubscribeEventSlotMutation = (
  { __typename?: 'mutation_root' }
  & { delete_userEventSlot?: Maybe<(
    { __typename?: 'userEventSlot_mutation_response' }
    & Pick<UserEventSlot_Mutation_Response, 'affected_rows'>
  )> }
);

export type CourseFragment = (
  { __typename?: 'course' }
  & Pick<Course, 'id' | 'imageHeadUri' | 'titleI18n' | 'descriptionShortI18n' | 'duration'>
  & { thematic?: Maybe<(
    { __typename?: 'thematic' }
    & Pick<Thematic, 'disabledAt' | 'labelI18n'>
  )>, sequences_aggregate: (
    { __typename?: 'sequence_aggregate' }
    & { aggregate?: Maybe<(
      { __typename?: 'sequence_aggregate_fields' }
      & Pick<Sequence_Aggregate_Fields, 'count'>
    )> }
  ), sequences: Array<(
    { __typename?: 'sequence' }
    & { sequence_modules: Array<(
      { __typename?: 'sequence_module' }
      & { module: (
        { __typename?: 'module' }
        & { moduleContent?: Maybe<(
          { __typename?: 'moduleContent' }
          & Pick<ModuleContent, 'contentEmbedId' | 'contentDocumentId' | 'contentVideoId' | 'contentPodcastId' | 'articleId'>
          & { contentEmbed?: Maybe<(
            { __typename?: 'contentEmbed' }
            & Pick<ContentEmbed, 'subtype'>
          )> }
        )> }
      ) }
    )>, sequence_modules_aggregate: (
      { __typename?: 'sequence_module_aggregate' }
      & { aggregate?: Maybe<(
        { __typename?: 'sequence_module_aggregate_fields' }
        & Pick<Sequence_Module_Aggregate_Fields, 'count'>
      )> }
    ) }
  )> }
);

export type ThematicFragment = (
  { __typename?: 'thematic' }
  & Pick<Thematic, 'id' | 'labelI18n'>
);

export type ModuleFragment = (
  { __typename?: 'module' }
  & Pick<Module, 'titleI18n' | 'descriptionI18n' | 'imageHeadUri'>
);

export type ModuleAnnexesFragment = (
  { __typename?: 'moduleAnnex' }
  & Pick<ModuleAnnex, 'id' | 'annexOrder' | 'titleI18n' | 'descriptionI18n' | 'link'>
);

export type ContentPodcastUploadsFragment = (
  { __typename?: 'contentPodcastUpload' }
  & Pick<ContentPodcastUpload, 'id' | 'url' | 'size' | 'language' | 'transcriptUrl' | 'transcriptSize'>
);

export type ContentAudioTranscriptsFragment = (
  { __typename?: 'contentAudioTranscript' }
  & Pick<ContentAudioTranscript, 'url' | 'size' | 'language' | 'id'>
);

export type ContentVideoDetailsFragment = (
  { __typename?: 'contentVideoDetails' }
  & Pick<ContentVideoDetails, 'videoId' | 'uploadUrl' | 'language'>
);

export type ContentArticleFragment = (
  { __typename?: 'contentArticle' }
  & Pick<ContentArticle, 'contentI18n'>
  & { contentId?: Maybe<(
    { __typename?: 'i18n' }
    & { translations: Array<(
      { __typename?: 'translation' }
      & Pick<Translation, 'value' | 'locale'>
    )> }
  )> }
);

export type ContentDocumentUploadsFragment = (
  { __typename?: 'contentDocumentUpload' }
  & Pick<ContentDocumentUpload, 'id' | 'language' | 'url' | 'size'>
);

export type ContentEventSlotsFragment = (
  { __typename?: 'contentEventSlot' }
  & Pick<ContentEventSlot, 'id' | 'startDate' | 'type' | 'maxPlaces' | 'endDate' | 'address' | 'webexLink' | 'room' | 'descriptionI18n' | 'participantCount'>
);

export type ContentCmi5AssignableUnitsFragment = (
  { __typename?: 'contentCmi5AssignableUnit' }
  & Pick<ContentCmi5AssignableUnit, 'idCmi5' | 'language' | 'launchMethod'>
);

export type ContentEmbedAudioTranscriptsFragment = (
  { __typename?: 'contentEmbedAudioTranscript' }
  & Pick<ContentEmbedAudioTranscript, 'url' | 'size' | 'language' | 'id'>
);

export type QueryModuleByIdQueryVariables = Exact<{
  courseId: Scalars['uuid'];
  sequenceId: Scalars['uuid'];
  moduleId: Scalars['uuid'];
}>;


export type QueryModuleByIdQuery = (
  { __typename?: 'query_root' }
  & { course_by_pk?: Maybe<(
    { __typename?: 'course' }
    & { sequences: Array<(
      { __typename?: 'sequence' }
      & { sequence_modules: Array<(
        { __typename?: 'sequence_module' }
        & { module: (
          { __typename?: 'module' }
          & { moduleAnnexes: Array<(
            { __typename?: 'moduleAnnex' }
            & ModuleAnnexesFragment
          )>, moduleContent?: Maybe<(
            { __typename?: 'moduleContent' }
            & { contentEmbed?: Maybe<(
              { __typename?: 'contentEmbed' }
              & Pick<ContentEmbed, 'link' | 'subtype'>
              & { contentEmbedAudioTranscripts: Array<(
                { __typename?: 'contentEmbedAudioTranscript' }
                & ContentEmbedAudioTranscriptsFragment
              )> }
            )>, contentPodcast?: Maybe<(
              { __typename?: 'contentPodcast' }
              & Pick<ContentPodcast, 'id'>
              & { contentPodcastUploads: Array<(
                { __typename?: 'contentPodcastUpload' }
                & ContentPodcastUploadsFragment
              )> }
            )>, contentVideo?: Maybe<(
              { __typename?: 'contentVideo' }
              & Pick<ContentVideo, 'id'>
              & { contentAudioTranscripts: Array<(
                { __typename?: 'contentAudioTranscript' }
                & ContentAudioTranscriptsFragment
              )>, contentVideoDetails: Array<(
                { __typename?: 'contentVideoDetails' }
                & ContentVideoDetailsFragment
              )> }
            )>, contentArticle?: Maybe<(
              { __typename?: 'contentArticle' }
              & ContentArticleFragment
            )>, contentDocument?: Maybe<(
              { __typename?: 'contentDocument' }
              & Pick<ContentDocument, 'id'>
              & { contentDocumentUploads: Array<(
                { __typename?: 'contentDocumentUpload' }
                & ContentDocumentUploadsFragment
              )> }
            )>, contentEvent?: Maybe<(
              { __typename?: 'contentEvent' }
              & Pick<ContentEvent, 'id'>
              & { contentEventSlots: Array<(
                { __typename?: 'contentEventSlot' }
                & ContentEventSlotsFragment
              )> }
            )>, contentCmi5?: Maybe<(
              { __typename?: 'contentCmi5' }
              & { contentCmi5AssignableUnits: Array<(
                { __typename?: 'contentCmi5AssignableUnit' }
                & ContentCmi5AssignableUnitsFragment
              )> }
            )> }
          )> }
          & ModuleFragment
        ) }
      )> }
    )> }
  )> }
);

export type QueryUserModuleProgressQueryVariables = Exact<{
  userId?: Maybe<Scalars['uuid']>;
  moduleId?: Maybe<Scalars['uuid']>;
}>;


export type QueryUserModuleProgressQuery = (
  { __typename?: 'query_root' }
  & { userModuleProgress: Array<(
    { __typename?: 'userModuleProgress' }
    & Pick<UserModuleProgress, 'progression'>
  )> }
);

export type QueryModuleTasksByIdQueryVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type QueryModuleTasksByIdQuery = (
  { __typename?: 'query_root' }
  & { module_by_pk?: Maybe<(
    { __typename?: 'module' }
    & { moduleTasks: Array<(
      { __typename?: 'moduleTask' }
      & Pick<ModuleTask, 'id' | 'taskOrder' | 'descriptionI18n'>
    )> }
  )> }
);

export type QueryCourseSequencesQueryVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type QueryCourseSequencesQuery = (
  { __typename?: 'query_root' }
  & { course_by_pk?: Maybe<(
    { __typename?: 'course' }
    & Pick<Course, 'category'>
    & { sequences: Array<(
      { __typename?: 'sequence' }
      & { sequence_modules: Array<(
        { __typename?: 'sequence_module' }
        & Pick<Sequence_Module, 'sequenceId' | 'orderInSequence' | 'moduleId'>
      )>, sequence_modules_aggregate: (
        { __typename?: 'sequence_module_aggregate' }
        & { aggregate?: Maybe<(
          { __typename?: 'sequence_module_aggregate_fields' }
          & Pick<Sequence_Module_Aggregate_Fields, 'count'>
        )> }
      ) }
    )> }
  )> }
);

export type PublishedCultureCoursesQueryVariables = Exact<{
  limit: Scalars['Int'];
  offset: Scalars['Int'];
  where?: Maybe<Course_Bool_Exp>;
}>;


export type PublishedCultureCoursesQuery = (
  { __typename?: 'query_root' }
  & { thematic: Array<(
    { __typename?: 'thematic' }
    & ThematicFragment
  )>, course_aggregate: (
    { __typename?: 'course_aggregate' }
    & { aggregate?: Maybe<(
      { __typename?: 'course_aggregate_fields' }
      & { totalCount: Course_Aggregate_Fields['count'] }
    )> }
  ), course: Array<(
    { __typename?: 'course' }
    & CourseFragment
  )> }
);

export type StartCmi5ContentMutationVariables = Exact<{
  input: StartCmi5ContentInput;
}>;


export type StartCmi5ContentMutation = (
  { __typename?: 'mutation_root' }
  & { startCmi5Content?: Maybe<(
    { __typename?: 'StartCmi5ContentResponse' }
    & Pick<StartCmi5ContentResponse, 'success' | 'url'>
  )> }
);

export type SaveCurrentUserEventSlotMutationVariables = Exact<{
  input: SaveCurrentUserEventSlotInput;
}>;


export type SaveCurrentUserEventSlotMutation = (
  { __typename?: 'mutation_root' }
  & { saveCurrentUserEventSlot?: Maybe<(
    { __typename?: 'SaveUsersEventSlotResponse' }
    & Pick<SaveUsersEventSlotResponse, 'success' | 'message'>
  )> }
);

export type DeleteUserFromEventSlotMutationVariables = Exact<{
  input: DeleteUserFromEventSlotInput;
}>;


export type DeleteUserFromEventSlotMutation = (
  { __typename?: 'mutation_root' }
  & { deleteUserFromEventSlot?: Maybe<(
    { __typename?: 'DeleteUserFromEventSlotResponse' }
    & Pick<DeleteUserFromEventSlotResponse, 'success'>
  )> }
);

export type UploadUrlMutationVariables = Exact<{
  fileName: Scalars['String'];
}>;


export type UploadUrlMutation = (
  { __typename?: 'mutation_root' }
  & { createUploadUrl?: Maybe<(
    { __typename?: 'UploadUrlResponse' }
    & Pick<UploadUrlResponse, 'uploadUrl' | 'publicUrl' | 'filePath'>
  )> }
);

export type DownloadUrlQueryVariables = Exact<{
  input: GetModuleResourceUriInput;
}>;


export type DownloadUrlQuery = (
  { __typename?: 'query_root' }
  & { getModuleResourceUri?: Maybe<(
    { __typename?: 'GetModuleResourceUriResponse' }
    & Pick<GetModuleResourceUriResponse, 'uri'>
  )> }
);

export type QueryMyUserQueryVariables = Exact<{ [key: string]: never; }>;


export type QueryMyUserQuery = (
  { __typename?: 'query_root' }
  & Pick<Query_Root, 'roles'>
  & { userPrivate: Array<(
    { __typename?: 'userPrivate' }
    & Pick<UserPrivate, 'id' | 'email' | 'isAdmin' | 'isActive' | 'createdAt' | 'updatedAt' | 'type'>
    & { user?: Maybe<(
      { __typename?: 'user' }
      & Pick<User, 'firstName' | 'lastName' | 'hasSeenOnboarding' | 'hasAcceptedPolicy'>
    )> }
  )> }
);

export type SignInMutationVariables = Exact<{
  email: Scalars['String'];
  password: Scalars['String'];
}>;


export type SignInMutation = (
  { __typename?: 'mutation_root' }
  & { signIn?: Maybe<(
    { __typename?: 'SignInResponse' }
    & Pick<SignInResponse, 'success' | 'accessToken' | 'message'>
  )> }
);

export type RefreshTokenMutationVariables = Exact<{ [key: string]: never; }>;


export type RefreshTokenMutation = (
  { __typename?: 'mutation_root' }
  & { queryRefreshToken?: Maybe<(
    { __typename?: 'RefreshTokenResponse' }
    & Pick<RefreshTokenResponse, 'success' | 'accessToken' | 'message'>
  )> }
);

export type LogoutUserMutationVariables = Exact<{ [key: string]: never; }>;


export type LogoutUserMutation = (
  { __typename?: 'mutation_root' }
  & { logout?: Maybe<(
    { __typename?: 'LogoutResponse' }
    & Pick<LogoutResponse, 'success'>
  )> }
);

export type SetProgressMutationVariables = Exact<{
  moduleId: Scalars['uuid'];
  progression: Scalars['Int'];
}>;


export type SetProgressMutation = (
  { __typename?: 'mutation_root' }
  & { insert_userModuleProgress_one?: Maybe<(
    { __typename?: 'userModuleProgress' }
    & Pick<UserModuleProgress, 'id'>
  )> }
);

export type SetHasSeenOnboardingMutationVariables = Exact<{
  userId: Scalars['uuid'];
}>;


export type SetHasSeenOnboardingMutation = (
  { __typename?: 'mutation_root' }
  & { update_user_by_pk?: Maybe<(
    { __typename?: 'user' }
    & Pick<User, 'id'>
  )> }
);

export type UpdateUserModuleProgressMutationVariables = Exact<{
  moduleId: Scalars['ID'];
  progression: Scalars['Int'];
}>;


export type UpdateUserModuleProgressMutation = (
  { __typename?: 'mutation_root' }
  & { updateUserModuleProgression?: Maybe<(
    { __typename?: 'UpdateUserModuleProgressionResponse' }
    & Pick<UpdateUserModuleProgressionResponse, 'success'>
  )> }
);

export type AcceptPolicyMutationVariables = Exact<{ [key: string]: never; }>;


export type AcceptPolicyMutation = (
  { __typename?: 'mutation_root' }
  & { acceptPolicy?: Maybe<(
    { __typename?: 'AcceptPolicyResponse' }
    & Pick<AcceptPolicyResponse, 'success'>
  )> }
);

export const CourseFragmentDoc = gql`
    fragment Course on course {
  id
  imageHeadUri
  titleI18n
  descriptionShortI18n
  duration
  thematic {
    disabledAt
    labelI18n
  }
  sequences_aggregate {
    aggregate {
      count
    }
  }
  sequences {
    sequence_modules {
      module {
        moduleContent {
          contentEmbedId
          contentDocumentId
          contentVideoId
          contentPodcastId
          articleId
          contentEmbed {
            subtype
          }
        }
      }
    }
    sequence_modules_aggregate {
      aggregate {
        count
      }
    }
  }
}
    `;
export const ThematicFragmentDoc = gql`
    fragment Thematic on thematic {
  id
  labelI18n
}
    `;
export const ModuleFragmentDoc = gql`
    fragment Module on module {
  titleI18n
  descriptionI18n
  imageHeadUri
}
    `;
export const ModuleAnnexesFragmentDoc = gql`
    fragment ModuleAnnexes on moduleAnnex {
  id
  annexOrder
  titleI18n
  descriptionI18n
  link
}
    `;
export const ContentPodcastUploadsFragmentDoc = gql`
    fragment ContentPodcastUploads on contentPodcastUpload {
  id
  url
  size
  language
  transcriptUrl
  transcriptSize
}
    `;
export const ContentAudioTranscriptsFragmentDoc = gql`
    fragment ContentAudioTranscripts on contentAudioTranscript {
  url
  size
  language
  id
}
    `;
export const ContentVideoDetailsFragmentDoc = gql`
    fragment ContentVideoDetails on contentVideoDetails {
  videoId
  uploadUrl
  language
}
    `;
export const ContentArticleFragmentDoc = gql`
    fragment ContentArticle on contentArticle {
  contentI18n
  contentId {
    translations {
      value
      locale
    }
  }
}
    `;
export const ContentDocumentUploadsFragmentDoc = gql`
    fragment ContentDocumentUploads on contentDocumentUpload {
  id
  language
  url
  size
}
    `;
export const ContentEventSlotsFragmentDoc = gql`
    fragment ContentEventSlots on contentEventSlot {
  id
  startDate
  type
  maxPlaces
  endDate
  address
  webexLink
  room
  descriptionI18n
  participantCount
}
    `;
export const ContentCmi5AssignableUnitsFragmentDoc = gql`
    fragment ContentCmi5AssignableUnits on contentCmi5AssignableUnit {
  idCmi5
  language
  launchMethod
}
    `;
export const ContentEmbedAudioTranscriptsFragmentDoc = gql`
    fragment ContentEmbedAudioTranscripts on contentEmbedAudioTranscript {
  url
  size
  language
  id
}
    `;
export const InsertDataQueryAccessRequestDocument = gql`
    mutation insertDataQueryAccessRequest($status: String!, $type: String!) {
  saveDataAccessRequest(input: {status: $status, type: $type}) {
    success
  }
}
    `;
export type InsertDataQueryAccessRequestMutationFn = Apollo.MutationFunction<InsertDataQueryAccessRequestMutation, InsertDataQueryAccessRequestMutationVariables>;

/**
 * __useInsertDataQueryAccessRequestMutation__
 *
 * To run a mutation, you first call `useInsertDataQueryAccessRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertDataQueryAccessRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertDataQueryAccessRequestMutation, { data, loading, error }] = useInsertDataQueryAccessRequestMutation({
 *   variables: {
 *      status: // value for 'status'
 *      type: // value for 'type'
 *   },
 * });
 */
export function useInsertDataQueryAccessRequestMutation(baseOptions?: Apollo.MutationHookOptions<InsertDataQueryAccessRequestMutation, InsertDataQueryAccessRequestMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InsertDataQueryAccessRequestMutation, InsertDataQueryAccessRequestMutationVariables>(InsertDataQueryAccessRequestDocument, options);
      }
export type InsertDataQueryAccessRequestMutationHookResult = ReturnType<typeof useInsertDataQueryAccessRequestMutation>;
export type InsertDataQueryAccessRequestMutationResult = Apollo.MutationResult<InsertDataQueryAccessRequestMutation>;
export type InsertDataQueryAccessRequestMutationOptions = Apollo.BaseMutationOptions<InsertDataQueryAccessRequestMutation, InsertDataQueryAccessRequestMutationVariables>;
export const GetUserDataAccessRequestDocument = gql`
    query getUserDataAccessRequest($type: String!) {
  dataAccessRequest(where: {type: {_eq: $type}}) {
    id
    created_at
  }
}
    `;

/**
 * __useGetUserDataAccessRequestQuery__
 *
 * To run a query within a React component, call `useGetUserDataAccessRequestQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserDataAccessRequestQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserDataAccessRequestQuery({
 *   variables: {
 *      type: // value for 'type'
 *   },
 * });
 */
export function useGetUserDataAccessRequestQuery(baseOptions: Apollo.QueryHookOptions<GetUserDataAccessRequestQuery, GetUserDataAccessRequestQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserDataAccessRequestQuery, GetUserDataAccessRequestQueryVariables>(GetUserDataAccessRequestDocument, options);
      }
export function useGetUserDataAccessRequestLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserDataAccessRequestQuery, GetUserDataAccessRequestQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserDataAccessRequestQuery, GetUserDataAccessRequestQueryVariables>(GetUserDataAccessRequestDocument, options);
        }
export type GetUserDataAccessRequestQueryHookResult = ReturnType<typeof useGetUserDataAccessRequestQuery>;
export type GetUserDataAccessRequestLazyQueryHookResult = ReturnType<typeof useGetUserDataAccessRequestLazyQuery>;
export type GetUserDataAccessRequestQueryResult = Apollo.QueryResult<GetUserDataAccessRequestQuery, GetUserDataAccessRequestQueryVariables>;
export const QueryCmsPageByKeyDocument = gql`
    query QueryCMSPageByKey($key: String!) {
  cmsPage(where: {key: {_eq: $key}}) {
    textI18n
    titleI18n
  }
}
    `;

/**
 * __useQueryCmsPageByKeyQuery__
 *
 * To run a query within a React component, call `useQueryCmsPageByKeyQuery` and pass it any options that fit your needs.
 * When your component renders, `useQueryCmsPageByKeyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQueryCmsPageByKeyQuery({
 *   variables: {
 *      key: // value for 'key'
 *   },
 * });
 */
export function useQueryCmsPageByKeyQuery(baseOptions: Apollo.QueryHookOptions<QueryCmsPageByKeyQuery, QueryCmsPageByKeyQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<QueryCmsPageByKeyQuery, QueryCmsPageByKeyQueryVariables>(QueryCmsPageByKeyDocument, options);
      }
export function useQueryCmsPageByKeyLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<QueryCmsPageByKeyQuery, QueryCmsPageByKeyQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<QueryCmsPageByKeyQuery, QueryCmsPageByKeyQueryVariables>(QueryCmsPageByKeyDocument, options);
        }
export type QueryCmsPageByKeyQueryHookResult = ReturnType<typeof useQueryCmsPageByKeyQuery>;
export type QueryCmsPageByKeyLazyQueryHookResult = ReturnType<typeof useQueryCmsPageByKeyLazyQuery>;
export type QueryCmsPageByKeyQueryResult = Apollo.QueryResult<QueryCmsPageByKeyQuery, QueryCmsPageByKeyQueryVariables>;
export const QueryFaqByLanguageDocument = gql`
    query QueryFaqByLanguage($language: String!) {
  faq(where: {language: {_eq: $language}}) {
    id
    question
    response
  }
}
    `;

/**
 * __useQueryFaqByLanguageQuery__
 *
 * To run a query within a React component, call `useQueryFaqByLanguageQuery` and pass it any options that fit your needs.
 * When your component renders, `useQueryFaqByLanguageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQueryFaqByLanguageQuery({
 *   variables: {
 *      language: // value for 'language'
 *   },
 * });
 */
export function useQueryFaqByLanguageQuery(baseOptions: Apollo.QueryHookOptions<QueryFaqByLanguageQuery, QueryFaqByLanguageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<QueryFaqByLanguageQuery, QueryFaqByLanguageQueryVariables>(QueryFaqByLanguageDocument, options);
      }
export function useQueryFaqByLanguageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<QueryFaqByLanguageQuery, QueryFaqByLanguageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<QueryFaqByLanguageQuery, QueryFaqByLanguageQueryVariables>(QueryFaqByLanguageDocument, options);
        }
export type QueryFaqByLanguageQueryHookResult = ReturnType<typeof useQueryFaqByLanguageQuery>;
export type QueryFaqByLanguageLazyQueryHookResult = ReturnType<typeof useQueryFaqByLanguageLazyQuery>;
export type QueryFaqByLanguageQueryResult = Apollo.QueryResult<QueryFaqByLanguageQuery, QueryFaqByLanguageQueryVariables>;
export const QueryBannerDataDocument = gql`
    query QueryBannerData($language: String!) {
  home {
    i18n {
      translations(where: {locale: {_eq: $language}}) {
        value
        locale
      }
    }
    desktopImageUri
    mobileImageUri
  }
}
    `;

/**
 * __useQueryBannerDataQuery__
 *
 * To run a query within a React component, call `useQueryBannerDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useQueryBannerDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQueryBannerDataQuery({
 *   variables: {
 *      language: // value for 'language'
 *   },
 * });
 */
export function useQueryBannerDataQuery(baseOptions: Apollo.QueryHookOptions<QueryBannerDataQuery, QueryBannerDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<QueryBannerDataQuery, QueryBannerDataQueryVariables>(QueryBannerDataDocument, options);
      }
export function useQueryBannerDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<QueryBannerDataQuery, QueryBannerDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<QueryBannerDataQuery, QueryBannerDataQueryVariables>(QueryBannerDataDocument, options);
        }
export type QueryBannerDataQueryHookResult = ReturnType<typeof useQueryBannerDataQuery>;
export type QueryBannerDataLazyQueryHookResult = ReturnType<typeof useQueryBannerDataLazyQuery>;
export type QueryBannerDataQueryResult = Apollo.QueryResult<QueryBannerDataQuery, QueryBannerDataQueryVariables>;
export const GetCurrentCourseDocument = gql`
    query GetCurrentCourse($id: uuid!) {
  course(where: {id: {_eq: $id}}) {
    id
    imageHeadUri
    publicationDate
    duration
    progression
    descriptionI18n
    descriptionShortI18n
    titleI18n
    category
    skill_of_courses(order_by: {id: asc}, where: {skill: {isActive: {_eq: true}}}) {
      level
      levelDescription
      skill {
        nameI18n
      }
    }
    skill_of_courses_aggregate(where: {skill: {isActive: {_eq: true}}}) {
      aggregate {
        count
      }
    }
    thematic {
      labelI18n
    }
    status
    sequences_aggregate {
      aggregate {
        count
      }
    }
    sequences(order_by: [{orderInCourse: asc}, {id: asc}]) {
      id
      orderInCourse
      titleI18n
      sequence_modules_aggregate {
        aggregate {
          count
        }
      }
      sequence_modules(order_by: {orderInSequence: asc}) {
        moduleId
        sequenceId
        module {
          duration
          titleI18n
          imageHeadUri
          id
          moduleContent {
            contentVideoId
            articleId
            contentEmbedId
            contentDocumentId
            contentPodcastId
            contentEventId
            contentCmi5Id
            contentEmbed {
              subtype
            }
          }
        }
      }
    }
  }
}
    `;

/**
 * __useGetCurrentCourseQuery__
 *
 * To run a query within a React component, call `useGetCurrentCourseQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCurrentCourseQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCurrentCourseQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetCurrentCourseQuery(baseOptions: Apollo.QueryHookOptions<GetCurrentCourseQuery, GetCurrentCourseQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCurrentCourseQuery, GetCurrentCourseQueryVariables>(GetCurrentCourseDocument, options);
      }
export function useGetCurrentCourseLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCurrentCourseQuery, GetCurrentCourseQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCurrentCourseQuery, GetCurrentCourseQueryVariables>(GetCurrentCourseDocument, options);
        }
export type GetCurrentCourseQueryHookResult = ReturnType<typeof useGetCurrentCourseQuery>;
export type GetCurrentCourseLazyQueryHookResult = ReturnType<typeof useGetCurrentCourseLazyQuery>;
export type GetCurrentCourseQueryResult = Apollo.QueryResult<GetCurrentCourseQuery, GetCurrentCourseQueryVariables>;
export const GetCurrentCourseProgressDocument = gql`
    query GetCurrentCourseProgress($id: uuid!, $where: userModuleProgress_bool_exp!, $whereEvent: userEventSlot_bool_exp!) {
  course(where: {id: {_eq: $id}}) {
    progression
    sequences(order_by: [{orderInCourse: asc}, {id: asc}]) {
      id
      sequence_modules(order_by: {orderInSequence: asc}) {
        module {
          id
          userModuleProgress(where: $where) {
            progression
          }
          moduleContent {
            contentEvent {
              contentEventSlots(order_by: {id: asc}) {
                endDate
                userEventSlots(where: $whereEvent) {
                  id
                  hasAttended
                }
              }
            }
          }
        }
      }
    }
  }
}
    `;

/**
 * __useGetCurrentCourseProgressQuery__
 *
 * To run a query within a React component, call `useGetCurrentCourseProgressQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCurrentCourseProgressQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCurrentCourseProgressQuery({
 *   variables: {
 *      id: // value for 'id'
 *      where: // value for 'where'
 *      whereEvent: // value for 'whereEvent'
 *   },
 * });
 */
export function useGetCurrentCourseProgressQuery(baseOptions: Apollo.QueryHookOptions<GetCurrentCourseProgressQuery, GetCurrentCourseProgressQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCurrentCourseProgressQuery, GetCurrentCourseProgressQueryVariables>(GetCurrentCourseProgressDocument, options);
      }
export function useGetCurrentCourseProgressLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCurrentCourseProgressQuery, GetCurrentCourseProgressQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCurrentCourseProgressQuery, GetCurrentCourseProgressQueryVariables>(GetCurrentCourseProgressDocument, options);
        }
export type GetCurrentCourseProgressQueryHookResult = ReturnType<typeof useGetCurrentCourseProgressQuery>;
export type GetCurrentCourseProgressLazyQueryHookResult = ReturnType<typeof useGetCurrentCourseProgressLazyQuery>;
export type GetCurrentCourseProgressQueryResult = Apollo.QueryResult<GetCurrentCourseProgressQuery, GetCurrentCourseProgressQueryVariables>;
export const GetLastCultureCoursesDocument = gql`
    query GetLastCultureCourses {
  course(
    limit: 10
    order_by: [{frontPageOrder: asc}, {publicationDate: desc}, {id: asc}]
    where: {isInFrontPage: {_eq: true}}
  ) {
    descriptionShortI18n
    titleI18n
    imageHeadUri
    id
    duration
    thematic {
      labelI18n
    }
  }
}
    `;

/**
 * __useGetLastCultureCoursesQuery__
 *
 * To run a query within a React component, call `useGetLastCultureCoursesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLastCultureCoursesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLastCultureCoursesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetLastCultureCoursesQuery(baseOptions?: Apollo.QueryHookOptions<GetLastCultureCoursesQuery, GetLastCultureCoursesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetLastCultureCoursesQuery, GetLastCultureCoursesQueryVariables>(GetLastCultureCoursesDocument, options);
      }
export function useGetLastCultureCoursesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetLastCultureCoursesQuery, GetLastCultureCoursesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetLastCultureCoursesQuery, GetLastCultureCoursesQueryVariables>(GetLastCultureCoursesDocument, options);
        }
export type GetLastCultureCoursesQueryHookResult = ReturnType<typeof useGetLastCultureCoursesQuery>;
export type GetLastCultureCoursesLazyQueryHookResult = ReturnType<typeof useGetLastCultureCoursesLazyQuery>;
export type GetLastCultureCoursesQueryResult = Apollo.QueryResult<GetLastCultureCoursesQuery, GetLastCultureCoursesQueryVariables>;
export const GetMyValidatedSkillsDocument = gql`
    query GetMyValidatedSkills($userId: uuid!) {
  userSkill_aggregate(where: {userId: {_eq: $userId}}) {
    aggregate {
      count
    }
  }
}
    `;

/**
 * __useGetMyValidatedSkillsQuery__
 *
 * To run a query within a React component, call `useGetMyValidatedSkillsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMyValidatedSkillsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMyValidatedSkillsQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useGetMyValidatedSkillsQuery(baseOptions: Apollo.QueryHookOptions<GetMyValidatedSkillsQuery, GetMyValidatedSkillsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMyValidatedSkillsQuery, GetMyValidatedSkillsQueryVariables>(GetMyValidatedSkillsDocument, options);
      }
export function useGetMyValidatedSkillsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMyValidatedSkillsQuery, GetMyValidatedSkillsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMyValidatedSkillsQuery, GetMyValidatedSkillsQueryVariables>(GetMyValidatedSkillsDocument, options);
        }
export type GetMyValidatedSkillsQueryHookResult = ReturnType<typeof useGetMyValidatedSkillsQuery>;
export type GetMyValidatedSkillsLazyQueryHookResult = ReturnType<typeof useGetMyValidatedSkillsLazyQuery>;
export type GetMyValidatedSkillsQueryResult = Apollo.QueryResult<GetMyValidatedSkillsQuery, GetMyValidatedSkillsQueryVariables>;
export const GetMyCoursesDocument = gql`
    query GetMyCourses($limit: Int!, $offset: Int!, $where: course_bool_exp, $progressionWhere: course_bool_exp) {
  course(
    limit: $limit
    offset: $offset
    order_by: [{courseAssign: {publishAt: desc}}, {id: asc}]
    where: $where
  ) {
    descriptionI18n
    descriptionShortI18n
    titleI18n
    imageHeadUri
    id
    duration
    progression
    skill_of_courses(order_by: {id: asc}) {
      level
      levelDescription
      skill {
        nameI18n
      }
    }
    skill_of_courses_aggregate(where: {skill: {isActive: {_eq: true}}}) {
      aggregate {
        count
      }
    }
  }
  course_aggregate(where: $where) {
    aggregate {
      totalCount: count
    }
  }
  courseProgression: course(where: $progressionWhere) {
    duration
    spendTime
    progression
  }
  courseSkill: course(where: $where) {
    skill_of_courses(order_by: {id: asc}, where: {skill: {isActive: {_eq: true}}}) {
      skill {
        id
      }
    }
  }
}
    `;

/**
 * __useGetMyCoursesQuery__
 *
 * To run a query within a React component, call `useGetMyCoursesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMyCoursesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMyCoursesQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      where: // value for 'where'
 *      progressionWhere: // value for 'progressionWhere'
 *   },
 * });
 */
export function useGetMyCoursesQuery(baseOptions: Apollo.QueryHookOptions<GetMyCoursesQuery, GetMyCoursesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMyCoursesQuery, GetMyCoursesQueryVariables>(GetMyCoursesDocument, options);
      }
export function useGetMyCoursesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMyCoursesQuery, GetMyCoursesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMyCoursesQuery, GetMyCoursesQueryVariables>(GetMyCoursesDocument, options);
        }
export type GetMyCoursesQueryHookResult = ReturnType<typeof useGetMyCoursesQuery>;
export type GetMyCoursesLazyQueryHookResult = ReturnType<typeof useGetMyCoursesLazyQuery>;
export type GetMyCoursesQueryResult = Apollo.QueryResult<GetMyCoursesQuery, GetMyCoursesQueryVariables>;
export const GetMyEventSlotsDocument = gql`
    query getMyEventSlots($userId: uuid!, $limit: Int!, $offset: Int!, $today: timestamptz!) {
  contentEventSlot(
    limit: $limit
    offset: $offset
    where: {userEventSlots: {userId: {_eq: $userId}}, contentEvent: {moduleContents: {module: {id: {_is_null: false}}}}, endDate: {_gte: $today}, validateDate: {_is_null: true}}
    order_by: [{startDate: asc}, {id: asc}]
  ) {
    id
    endDate
    type
    startDate
    room
    address
    webexLink
    contentEvent {
      moduleContents(order_by: {id: asc}) {
        module {
          id
          sequence_modules {
            sequence {
              id
              course {
                titleI18n
                id
              }
            }
          }
        }
      }
    }
  }
  contentEventSlot_aggregate(
    where: {userEventSlots: {userId: {_eq: $userId}}, contentEvent: {moduleContents: {module: {id: {_is_null: false}}}}, endDate: {_gte: $today}}
  ) {
    aggregate {
      totalCount: count
    }
  }
}
    `;

/**
 * __useGetMyEventSlotsQuery__
 *
 * To run a query within a React component, call `useGetMyEventSlotsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMyEventSlotsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMyEventSlotsQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      today: // value for 'today'
 *   },
 * });
 */
export function useGetMyEventSlotsQuery(baseOptions: Apollo.QueryHookOptions<GetMyEventSlotsQuery, GetMyEventSlotsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMyEventSlotsQuery, GetMyEventSlotsQueryVariables>(GetMyEventSlotsDocument, options);
      }
export function useGetMyEventSlotsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMyEventSlotsQuery, GetMyEventSlotsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMyEventSlotsQuery, GetMyEventSlotsQueryVariables>(GetMyEventSlotsDocument, options);
        }
export type GetMyEventSlotsQueryHookResult = ReturnType<typeof useGetMyEventSlotsQuery>;
export type GetMyEventSlotsLazyQueryHookResult = ReturnType<typeof useGetMyEventSlotsLazyQuery>;
export type GetMyEventSlotsQueryResult = Apollo.QueryResult<GetMyEventSlotsQuery, GetMyEventSlotsQueryVariables>;
export const GetMyEventSlotDocument = gql`
    query getMyEventSlot($contentEventId: uuid) {
  userEventSlot(
    where: {contentEventSlot: {contentEventId: {_eq: $contentEventId}}}
  ) {
    id
    eventSlotId
    hasAttended
    contentEventSlot {
      endDate
      startDate
      type
      room
      address
      webexLink
      validateDate
    }
  }
}
    `;

/**
 * __useGetMyEventSlotQuery__
 *
 * To run a query within a React component, call `useGetMyEventSlotQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMyEventSlotQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMyEventSlotQuery({
 *   variables: {
 *      contentEventId: // value for 'contentEventId'
 *   },
 * });
 */
export function useGetMyEventSlotQuery(baseOptions?: Apollo.QueryHookOptions<GetMyEventSlotQuery, GetMyEventSlotQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMyEventSlotQuery, GetMyEventSlotQueryVariables>(GetMyEventSlotDocument, options);
      }
export function useGetMyEventSlotLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMyEventSlotQuery, GetMyEventSlotQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMyEventSlotQuery, GetMyEventSlotQueryVariables>(GetMyEventSlotDocument, options);
        }
export type GetMyEventSlotQueryHookResult = ReturnType<typeof useGetMyEventSlotQuery>;
export type GetMyEventSlotLazyQueryHookResult = ReturnType<typeof useGetMyEventSlotLazyQuery>;
export type GetMyEventSlotQueryResult = Apollo.QueryResult<GetMyEventSlotQuery, GetMyEventSlotQueryVariables>;
export const UnsubscribeEventSlotDocument = gql`
    mutation unsubscribeEventSlot($eventSlotId: uuid) {
  delete_userEventSlot(where: {eventSlotId: {_eq: $eventSlotId}}) {
    affected_rows
  }
}
    `;
export type UnsubscribeEventSlotMutationFn = Apollo.MutationFunction<UnsubscribeEventSlotMutation, UnsubscribeEventSlotMutationVariables>;

/**
 * __useUnsubscribeEventSlotMutation__
 *
 * To run a mutation, you first call `useUnsubscribeEventSlotMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnsubscribeEventSlotMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unsubscribeEventSlotMutation, { data, loading, error }] = useUnsubscribeEventSlotMutation({
 *   variables: {
 *      eventSlotId: // value for 'eventSlotId'
 *   },
 * });
 */
export function useUnsubscribeEventSlotMutation(baseOptions?: Apollo.MutationHookOptions<UnsubscribeEventSlotMutation, UnsubscribeEventSlotMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UnsubscribeEventSlotMutation, UnsubscribeEventSlotMutationVariables>(UnsubscribeEventSlotDocument, options);
      }
export type UnsubscribeEventSlotMutationHookResult = ReturnType<typeof useUnsubscribeEventSlotMutation>;
export type UnsubscribeEventSlotMutationResult = Apollo.MutationResult<UnsubscribeEventSlotMutation>;
export type UnsubscribeEventSlotMutationOptions = Apollo.BaseMutationOptions<UnsubscribeEventSlotMutation, UnsubscribeEventSlotMutationVariables>;
export const QueryModuleByIdDocument = gql`
    query QueryModuleById($courseId: uuid!, $sequenceId: uuid!, $moduleId: uuid!) {
  course_by_pk(id: $courseId) {
    sequences(where: {id: {_eq: $sequenceId}}) {
      sequence_modules(where: {moduleId: {_eq: $moduleId}}) {
        module {
          ...Module
          moduleAnnexes(order_by: [{annexOrder: asc}, {id: asc}], limit: 5) {
            ...ModuleAnnexes
          }
          moduleContent {
            contentEmbed {
              link
              subtype
              contentEmbedAudioTranscripts(order_by: {id: asc}) {
                ...ContentEmbedAudioTranscripts
              }
            }
            contentPodcast {
              id
              contentPodcastUploads(order_by: {id: asc}) {
                ...ContentPodcastUploads
              }
            }
            contentVideo {
              id
              contentAudioTranscripts(order_by: {id: asc}) {
                ...ContentAudioTranscripts
              }
              contentVideoDetails {
                ...ContentVideoDetails
              }
            }
            contentArticle {
              ...ContentArticle
            }
            contentDocument {
              id
              contentDocumentUploads(order_by: {id: asc}) {
                ...ContentDocumentUploads
              }
            }
            contentEvent {
              id
              contentEventSlots(order_by: {id: asc}) {
                ...ContentEventSlots
              }
            }
            contentCmi5 {
              contentCmi5AssignableUnits(order_by: {id: asc}) {
                ...ContentCmi5AssignableUnits
              }
            }
          }
        }
      }
    }
  }
}
    ${ModuleFragmentDoc}
${ModuleAnnexesFragmentDoc}
${ContentEmbedAudioTranscriptsFragmentDoc}
${ContentPodcastUploadsFragmentDoc}
${ContentAudioTranscriptsFragmentDoc}
${ContentVideoDetailsFragmentDoc}
${ContentArticleFragmentDoc}
${ContentDocumentUploadsFragmentDoc}
${ContentEventSlotsFragmentDoc}
${ContentCmi5AssignableUnitsFragmentDoc}`;

/**
 * __useQueryModuleByIdQuery__
 *
 * To run a query within a React component, call `useQueryModuleByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useQueryModuleByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQueryModuleByIdQuery({
 *   variables: {
 *      courseId: // value for 'courseId'
 *      sequenceId: // value for 'sequenceId'
 *      moduleId: // value for 'moduleId'
 *   },
 * });
 */
export function useQueryModuleByIdQuery(baseOptions: Apollo.QueryHookOptions<QueryModuleByIdQuery, QueryModuleByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<QueryModuleByIdQuery, QueryModuleByIdQueryVariables>(QueryModuleByIdDocument, options);
      }
export function useQueryModuleByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<QueryModuleByIdQuery, QueryModuleByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<QueryModuleByIdQuery, QueryModuleByIdQueryVariables>(QueryModuleByIdDocument, options);
        }
export type QueryModuleByIdQueryHookResult = ReturnType<typeof useQueryModuleByIdQuery>;
export type QueryModuleByIdLazyQueryHookResult = ReturnType<typeof useQueryModuleByIdLazyQuery>;
export type QueryModuleByIdQueryResult = Apollo.QueryResult<QueryModuleByIdQuery, QueryModuleByIdQueryVariables>;
export const QueryUserModuleProgressDocument = gql`
    query QueryUserModuleProgress($userId: uuid, $moduleId: uuid) {
  userModuleProgress(where: {userId: {_eq: $userId}, moduleId: {_eq: $moduleId}}) {
    progression
  }
}
    `;

/**
 * __useQueryUserModuleProgressQuery__
 *
 * To run a query within a React component, call `useQueryUserModuleProgressQuery` and pass it any options that fit your needs.
 * When your component renders, `useQueryUserModuleProgressQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQueryUserModuleProgressQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *      moduleId: // value for 'moduleId'
 *   },
 * });
 */
export function useQueryUserModuleProgressQuery(baseOptions?: Apollo.QueryHookOptions<QueryUserModuleProgressQuery, QueryUserModuleProgressQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<QueryUserModuleProgressQuery, QueryUserModuleProgressQueryVariables>(QueryUserModuleProgressDocument, options);
      }
export function useQueryUserModuleProgressLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<QueryUserModuleProgressQuery, QueryUserModuleProgressQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<QueryUserModuleProgressQuery, QueryUserModuleProgressQueryVariables>(QueryUserModuleProgressDocument, options);
        }
export type QueryUserModuleProgressQueryHookResult = ReturnType<typeof useQueryUserModuleProgressQuery>;
export type QueryUserModuleProgressLazyQueryHookResult = ReturnType<typeof useQueryUserModuleProgressLazyQuery>;
export type QueryUserModuleProgressQueryResult = Apollo.QueryResult<QueryUserModuleProgressQuery, QueryUserModuleProgressQueryVariables>;
export const QueryModuleTasksByIdDocument = gql`
    query QueryModuleTasksById($id: uuid!) {
  module_by_pk(id: $id) {
    moduleTasks(order_by: [{taskOrder: asc}, {id: asc}]) {
      id
      taskOrder
      descriptionI18n
    }
  }
}
    `;

/**
 * __useQueryModuleTasksByIdQuery__
 *
 * To run a query within a React component, call `useQueryModuleTasksByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useQueryModuleTasksByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQueryModuleTasksByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useQueryModuleTasksByIdQuery(baseOptions: Apollo.QueryHookOptions<QueryModuleTasksByIdQuery, QueryModuleTasksByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<QueryModuleTasksByIdQuery, QueryModuleTasksByIdQueryVariables>(QueryModuleTasksByIdDocument, options);
      }
export function useQueryModuleTasksByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<QueryModuleTasksByIdQuery, QueryModuleTasksByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<QueryModuleTasksByIdQuery, QueryModuleTasksByIdQueryVariables>(QueryModuleTasksByIdDocument, options);
        }
export type QueryModuleTasksByIdQueryHookResult = ReturnType<typeof useQueryModuleTasksByIdQuery>;
export type QueryModuleTasksByIdLazyQueryHookResult = ReturnType<typeof useQueryModuleTasksByIdLazyQuery>;
export type QueryModuleTasksByIdQueryResult = Apollo.QueryResult<QueryModuleTasksByIdQuery, QueryModuleTasksByIdQueryVariables>;
export const QueryCourseSequencesDocument = gql`
    query QueryCourseSequences($id: uuid!) {
  course_by_pk(id: $id) {
    category
    sequences(order_by: {id: asc}) {
      sequence_modules {
        sequenceId
        orderInSequence
        moduleId
      }
      sequence_modules_aggregate {
        aggregate {
          count
        }
      }
    }
  }
}
    `;

/**
 * __useQueryCourseSequencesQuery__
 *
 * To run a query within a React component, call `useQueryCourseSequencesQuery` and pass it any options that fit your needs.
 * When your component renders, `useQueryCourseSequencesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQueryCourseSequencesQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useQueryCourseSequencesQuery(baseOptions: Apollo.QueryHookOptions<QueryCourseSequencesQuery, QueryCourseSequencesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<QueryCourseSequencesQuery, QueryCourseSequencesQueryVariables>(QueryCourseSequencesDocument, options);
      }
export function useQueryCourseSequencesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<QueryCourseSequencesQuery, QueryCourseSequencesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<QueryCourseSequencesQuery, QueryCourseSequencesQueryVariables>(QueryCourseSequencesDocument, options);
        }
export type QueryCourseSequencesQueryHookResult = ReturnType<typeof useQueryCourseSequencesQuery>;
export type QueryCourseSequencesLazyQueryHookResult = ReturnType<typeof useQueryCourseSequencesLazyQuery>;
export type QueryCourseSequencesQueryResult = Apollo.QueryResult<QueryCourseSequencesQuery, QueryCourseSequencesQueryVariables>;
export const PublishedCultureCoursesDocument = gql`
    query PublishedCultureCourses($limit: Int!, $offset: Int!, $where: course_bool_exp) {
  thematic(
    where: {disabledAt: {_is_null: true}, courses: {category: {_eq: "culture"}, publicationDate: {_lte: "NOW()"}, status: {_eq: "published"}}}
    order_by: {id: asc}
  ) {
    ...Thematic
  }
  course_aggregate(where: $where) {
    aggregate {
      totalCount: count
    }
  }
  course(where: $where, limit: $limit, offset: $offset, order_by: {id: asc}) {
    ...Course
  }
}
    ${ThematicFragmentDoc}
${CourseFragmentDoc}`;

/**
 * __usePublishedCultureCoursesQuery__
 *
 * To run a query within a React component, call `usePublishedCultureCoursesQuery` and pass it any options that fit your needs.
 * When your component renders, `usePublishedCultureCoursesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePublishedCultureCoursesQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      where: // value for 'where'
 *   },
 * });
 */
export function usePublishedCultureCoursesQuery(baseOptions: Apollo.QueryHookOptions<PublishedCultureCoursesQuery, PublishedCultureCoursesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PublishedCultureCoursesQuery, PublishedCultureCoursesQueryVariables>(PublishedCultureCoursesDocument, options);
      }
export function usePublishedCultureCoursesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PublishedCultureCoursesQuery, PublishedCultureCoursesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PublishedCultureCoursesQuery, PublishedCultureCoursesQueryVariables>(PublishedCultureCoursesDocument, options);
        }
export type PublishedCultureCoursesQueryHookResult = ReturnType<typeof usePublishedCultureCoursesQuery>;
export type PublishedCultureCoursesLazyQueryHookResult = ReturnType<typeof usePublishedCultureCoursesLazyQuery>;
export type PublishedCultureCoursesQueryResult = Apollo.QueryResult<PublishedCultureCoursesQuery, PublishedCultureCoursesQueryVariables>;
export const StartCmi5ContentDocument = gql`
    mutation startCmi5Content($input: StartCmi5ContentInput!) {
  startCmi5Content(input: $input) {
    success
    url
  }
}
    `;
export type StartCmi5ContentMutationFn = Apollo.MutationFunction<StartCmi5ContentMutation, StartCmi5ContentMutationVariables>;

/**
 * __useStartCmi5ContentMutation__
 *
 * To run a mutation, you first call `useStartCmi5ContentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStartCmi5ContentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [startCmi5ContentMutation, { data, loading, error }] = useStartCmi5ContentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useStartCmi5ContentMutation(baseOptions?: Apollo.MutationHookOptions<StartCmi5ContentMutation, StartCmi5ContentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<StartCmi5ContentMutation, StartCmi5ContentMutationVariables>(StartCmi5ContentDocument, options);
      }
export type StartCmi5ContentMutationHookResult = ReturnType<typeof useStartCmi5ContentMutation>;
export type StartCmi5ContentMutationResult = Apollo.MutationResult<StartCmi5ContentMutation>;
export type StartCmi5ContentMutationOptions = Apollo.BaseMutationOptions<StartCmi5ContentMutation, StartCmi5ContentMutationVariables>;
export const SaveCurrentUserEventSlotDocument = gql`
    mutation saveCurrentUserEventSlot($input: SaveCurrentUserEventSlotInput!) {
  saveCurrentUserEventSlot(input: $input) {
    success
    message
  }
}
    `;
export type SaveCurrentUserEventSlotMutationFn = Apollo.MutationFunction<SaveCurrentUserEventSlotMutation, SaveCurrentUserEventSlotMutationVariables>;

/**
 * __useSaveCurrentUserEventSlotMutation__
 *
 * To run a mutation, you first call `useSaveCurrentUserEventSlotMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveCurrentUserEventSlotMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveCurrentUserEventSlotMutation, { data, loading, error }] = useSaveCurrentUserEventSlotMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSaveCurrentUserEventSlotMutation(baseOptions?: Apollo.MutationHookOptions<SaveCurrentUserEventSlotMutation, SaveCurrentUserEventSlotMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SaveCurrentUserEventSlotMutation, SaveCurrentUserEventSlotMutationVariables>(SaveCurrentUserEventSlotDocument, options);
      }
export type SaveCurrentUserEventSlotMutationHookResult = ReturnType<typeof useSaveCurrentUserEventSlotMutation>;
export type SaveCurrentUserEventSlotMutationResult = Apollo.MutationResult<SaveCurrentUserEventSlotMutation>;
export type SaveCurrentUserEventSlotMutationOptions = Apollo.BaseMutationOptions<SaveCurrentUserEventSlotMutation, SaveCurrentUserEventSlotMutationVariables>;
export const DeleteUserFromEventSlotDocument = gql`
    mutation deleteUserFromEventSlot($input: DeleteUserFromEventSlotInput!) {
  deleteUserFromEventSlot(input: $input) {
    success
  }
}
    `;
export type DeleteUserFromEventSlotMutationFn = Apollo.MutationFunction<DeleteUserFromEventSlotMutation, DeleteUserFromEventSlotMutationVariables>;

/**
 * __useDeleteUserFromEventSlotMutation__
 *
 * To run a mutation, you first call `useDeleteUserFromEventSlotMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteUserFromEventSlotMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteUserFromEventSlotMutation, { data, loading, error }] = useDeleteUserFromEventSlotMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteUserFromEventSlotMutation(baseOptions?: Apollo.MutationHookOptions<DeleteUserFromEventSlotMutation, DeleteUserFromEventSlotMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteUserFromEventSlotMutation, DeleteUserFromEventSlotMutationVariables>(DeleteUserFromEventSlotDocument, options);
      }
export type DeleteUserFromEventSlotMutationHookResult = ReturnType<typeof useDeleteUserFromEventSlotMutation>;
export type DeleteUserFromEventSlotMutationResult = Apollo.MutationResult<DeleteUserFromEventSlotMutation>;
export type DeleteUserFromEventSlotMutationOptions = Apollo.BaseMutationOptions<DeleteUserFromEventSlotMutation, DeleteUserFromEventSlotMutationVariables>;
export const UploadUrlDocument = gql`
    mutation UploadUrl($fileName: String!) {
  createUploadUrl(fileName: $fileName) {
    uploadUrl
    publicUrl
    filePath
  }
}
    `;
export type UploadUrlMutationFn = Apollo.MutationFunction<UploadUrlMutation, UploadUrlMutationVariables>;

/**
 * __useUploadUrlMutation__
 *
 * To run a mutation, you first call `useUploadUrlMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadUrlMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadUrlMutation, { data, loading, error }] = useUploadUrlMutation({
 *   variables: {
 *      fileName: // value for 'fileName'
 *   },
 * });
 */
export function useUploadUrlMutation(baseOptions?: Apollo.MutationHookOptions<UploadUrlMutation, UploadUrlMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UploadUrlMutation, UploadUrlMutationVariables>(UploadUrlDocument, options);
      }
export type UploadUrlMutationHookResult = ReturnType<typeof useUploadUrlMutation>;
export type UploadUrlMutationResult = Apollo.MutationResult<UploadUrlMutation>;
export type UploadUrlMutationOptions = Apollo.BaseMutationOptions<UploadUrlMutation, UploadUrlMutationVariables>;
export const DownloadUrlDocument = gql`
    query DownloadUrl($input: GetModuleResourceUriInput!) {
  getModuleResourceUri(input: $input) {
    uri
  }
}
    `;

/**
 * __useDownloadUrlQuery__
 *
 * To run a query within a React component, call `useDownloadUrlQuery` and pass it any options that fit your needs.
 * When your component renders, `useDownloadUrlQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDownloadUrlQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDownloadUrlQuery(baseOptions: Apollo.QueryHookOptions<DownloadUrlQuery, DownloadUrlQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DownloadUrlQuery, DownloadUrlQueryVariables>(DownloadUrlDocument, options);
      }
export function useDownloadUrlLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DownloadUrlQuery, DownloadUrlQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DownloadUrlQuery, DownloadUrlQueryVariables>(DownloadUrlDocument, options);
        }
export type DownloadUrlQueryHookResult = ReturnType<typeof useDownloadUrlQuery>;
export type DownloadUrlLazyQueryHookResult = ReturnType<typeof useDownloadUrlLazyQuery>;
export type DownloadUrlQueryResult = Apollo.QueryResult<DownloadUrlQuery, DownloadUrlQueryVariables>;
export const QueryMyUserDocument = gql`
    query QueryMyUser {
  userPrivate {
    user {
      firstName
      lastName
      hasSeenOnboarding
      hasAcceptedPolicy
    }
    id
    email
    isAdmin
    isActive
    createdAt
    updatedAt
    type
  }
  roles
}
    `;

/**
 * __useQueryMyUserQuery__
 *
 * To run a query within a React component, call `useQueryMyUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useQueryMyUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQueryMyUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useQueryMyUserQuery(baseOptions?: Apollo.QueryHookOptions<QueryMyUserQuery, QueryMyUserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<QueryMyUserQuery, QueryMyUserQueryVariables>(QueryMyUserDocument, options);
      }
export function useQueryMyUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<QueryMyUserQuery, QueryMyUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<QueryMyUserQuery, QueryMyUserQueryVariables>(QueryMyUserDocument, options);
        }
export type QueryMyUserQueryHookResult = ReturnType<typeof useQueryMyUserQuery>;
export type QueryMyUserLazyQueryHookResult = ReturnType<typeof useQueryMyUserLazyQuery>;
export type QueryMyUserQueryResult = Apollo.QueryResult<QueryMyUserQuery, QueryMyUserQueryVariables>;
export const SignInDocument = gql`
    mutation SignIn($email: String!, $password: String!) {
  signIn(email: $email, password: $password) {
    success
    accessToken
    message
  }
}
    `;
export type SignInMutationFn = Apollo.MutationFunction<SignInMutation, SignInMutationVariables>;

/**
 * __useSignInMutation__
 *
 * To run a mutation, you first call `useSignInMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSignInMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signInMutation, { data, loading, error }] = useSignInMutation({
 *   variables: {
 *      email: // value for 'email'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useSignInMutation(baseOptions?: Apollo.MutationHookOptions<SignInMutation, SignInMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SignInMutation, SignInMutationVariables>(SignInDocument, options);
      }
export type SignInMutationHookResult = ReturnType<typeof useSignInMutation>;
export type SignInMutationResult = Apollo.MutationResult<SignInMutation>;
export type SignInMutationOptions = Apollo.BaseMutationOptions<SignInMutation, SignInMutationVariables>;
export const RefreshTokenDocument = gql`
    mutation RefreshToken {
  queryRefreshToken {
    success
    accessToken
    message
  }
}
    `;
export type RefreshTokenMutationFn = Apollo.MutationFunction<RefreshTokenMutation, RefreshTokenMutationVariables>;

/**
 * __useRefreshTokenMutation__
 *
 * To run a mutation, you first call `useRefreshTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRefreshTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [refreshTokenMutation, { data, loading, error }] = useRefreshTokenMutation({
 *   variables: {
 *   },
 * });
 */
export function useRefreshTokenMutation(baseOptions?: Apollo.MutationHookOptions<RefreshTokenMutation, RefreshTokenMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RefreshTokenMutation, RefreshTokenMutationVariables>(RefreshTokenDocument, options);
      }
export type RefreshTokenMutationHookResult = ReturnType<typeof useRefreshTokenMutation>;
export type RefreshTokenMutationResult = Apollo.MutationResult<RefreshTokenMutation>;
export type RefreshTokenMutationOptions = Apollo.BaseMutationOptions<RefreshTokenMutation, RefreshTokenMutationVariables>;
export const LogoutUserDocument = gql`
    mutation LogoutUser {
  logout {
    success
  }
}
    `;
export type LogoutUserMutationFn = Apollo.MutationFunction<LogoutUserMutation, LogoutUserMutationVariables>;

/**
 * __useLogoutUserMutation__
 *
 * To run a mutation, you first call `useLogoutUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLogoutUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [logoutUserMutation, { data, loading, error }] = useLogoutUserMutation({
 *   variables: {
 *   },
 * });
 */
export function useLogoutUserMutation(baseOptions?: Apollo.MutationHookOptions<LogoutUserMutation, LogoutUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LogoutUserMutation, LogoutUserMutationVariables>(LogoutUserDocument, options);
      }
export type LogoutUserMutationHookResult = ReturnType<typeof useLogoutUserMutation>;
export type LogoutUserMutationResult = Apollo.MutationResult<LogoutUserMutation>;
export type LogoutUserMutationOptions = Apollo.BaseMutationOptions<LogoutUserMutation, LogoutUserMutationVariables>;
export const SetProgressDocument = gql`
    mutation SetProgress($moduleId: uuid!, $progression: Int!) {
  insert_userModuleProgress_one(
    object: {progression: $progression, moduleId: $moduleId}
    on_conflict: {constraint: c_user_module_progress_user_id_module_id, update_columns: [progression]}
  ) {
    id
  }
}
    `;
export type SetProgressMutationFn = Apollo.MutationFunction<SetProgressMutation, SetProgressMutationVariables>;

/**
 * __useSetProgressMutation__
 *
 * To run a mutation, you first call `useSetProgressMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetProgressMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setProgressMutation, { data, loading, error }] = useSetProgressMutation({
 *   variables: {
 *      moduleId: // value for 'moduleId'
 *      progression: // value for 'progression'
 *   },
 * });
 */
export function useSetProgressMutation(baseOptions?: Apollo.MutationHookOptions<SetProgressMutation, SetProgressMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetProgressMutation, SetProgressMutationVariables>(SetProgressDocument, options);
      }
export type SetProgressMutationHookResult = ReturnType<typeof useSetProgressMutation>;
export type SetProgressMutationResult = Apollo.MutationResult<SetProgressMutation>;
export type SetProgressMutationOptions = Apollo.BaseMutationOptions<SetProgressMutation, SetProgressMutationVariables>;
export const SetHasSeenOnboardingDocument = gql`
    mutation SetHasSeenOnboarding($userId: uuid!) {
  update_user_by_pk(pk_columns: {id: $userId}, _set: {hasSeenOnboarding: true}) {
    id
  }
}
    `;
export type SetHasSeenOnboardingMutationFn = Apollo.MutationFunction<SetHasSeenOnboardingMutation, SetHasSeenOnboardingMutationVariables>;

/**
 * __useSetHasSeenOnboardingMutation__
 *
 * To run a mutation, you first call `useSetHasSeenOnboardingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetHasSeenOnboardingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setHasSeenOnboardingMutation, { data, loading, error }] = useSetHasSeenOnboardingMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useSetHasSeenOnboardingMutation(baseOptions?: Apollo.MutationHookOptions<SetHasSeenOnboardingMutation, SetHasSeenOnboardingMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetHasSeenOnboardingMutation, SetHasSeenOnboardingMutationVariables>(SetHasSeenOnboardingDocument, options);
      }
export type SetHasSeenOnboardingMutationHookResult = ReturnType<typeof useSetHasSeenOnboardingMutation>;
export type SetHasSeenOnboardingMutationResult = Apollo.MutationResult<SetHasSeenOnboardingMutation>;
export type SetHasSeenOnboardingMutationOptions = Apollo.BaseMutationOptions<SetHasSeenOnboardingMutation, SetHasSeenOnboardingMutationVariables>;
export const UpdateUserModuleProgressDocument = gql`
    mutation UpdateUserModuleProgress($moduleId: ID!, $progression: Int!) {
  updateUserModuleProgression(moduleId: $moduleId, progression: $progression) {
    success
  }
}
    `;
export type UpdateUserModuleProgressMutationFn = Apollo.MutationFunction<UpdateUserModuleProgressMutation, UpdateUserModuleProgressMutationVariables>;

/**
 * __useUpdateUserModuleProgressMutation__
 *
 * To run a mutation, you first call `useUpdateUserModuleProgressMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserModuleProgressMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserModuleProgressMutation, { data, loading, error }] = useUpdateUserModuleProgressMutation({
 *   variables: {
 *      moduleId: // value for 'moduleId'
 *      progression: // value for 'progression'
 *   },
 * });
 */
export function useUpdateUserModuleProgressMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUserModuleProgressMutation, UpdateUserModuleProgressMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateUserModuleProgressMutation, UpdateUserModuleProgressMutationVariables>(UpdateUserModuleProgressDocument, options);
      }
export type UpdateUserModuleProgressMutationHookResult = ReturnType<typeof useUpdateUserModuleProgressMutation>;
export type UpdateUserModuleProgressMutationResult = Apollo.MutationResult<UpdateUserModuleProgressMutation>;
export type UpdateUserModuleProgressMutationOptions = Apollo.BaseMutationOptions<UpdateUserModuleProgressMutation, UpdateUserModuleProgressMutationVariables>;
export const AcceptPolicyDocument = gql`
    mutation acceptPolicy {
  acceptPolicy {
    success
  }
}
    `;
export type AcceptPolicyMutationFn = Apollo.MutationFunction<AcceptPolicyMutation, AcceptPolicyMutationVariables>;

/**
 * __useAcceptPolicyMutation__
 *
 * To run a mutation, you first call `useAcceptPolicyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAcceptPolicyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [acceptPolicyMutation, { data, loading, error }] = useAcceptPolicyMutation({
 *   variables: {
 *   },
 * });
 */
export function useAcceptPolicyMutation(baseOptions?: Apollo.MutationHookOptions<AcceptPolicyMutation, AcceptPolicyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AcceptPolicyMutation, AcceptPolicyMutationVariables>(AcceptPolicyDocument, options);
      }
export type AcceptPolicyMutationHookResult = ReturnType<typeof useAcceptPolicyMutation>;
export type AcceptPolicyMutationResult = Apollo.MutationResult<AcceptPolicyMutation>;
export type AcceptPolicyMutationOptions = Apollo.BaseMutationOptions<AcceptPolicyMutation, AcceptPolicyMutationVariables>;