import { ResourceType } from 'generated/graphql';
import { ApolloClient } from '@apollo/client';
import { FileBucket } from 'technical/fileManagement/bucket';
import { FileModule } from 'technical/fileManagement/bucket/module';
import { FilePublic } from 'technical/fileManagement/bucket/public';
import { Bucket } from 'technical/fileManagement/types';

/**
 * Allow to retrieve file by list or direct without wondering if path is public/private/...
 */
export class FileService {
  private publicBucket;

  private moduleBucket;

  public constructor(apolloClient: ApolloClient<unknown>) {
    this.publicBucket = new FilePublic();
    this.moduleBucket = new FileModule(apolloClient);
  }

  /**
   * Get full path of a file
   *
   * @param filePath
   * @returns
   */
  public async getPublicFile(filePath: string): Promise<string> {
    const bucket = FileBucket.matchBucketPath(filePath);

    if (!bucket || !(bucket === Bucket.Public)) {
      return Promise.resolve(filePath);
    }

    const provider = this.publicBucket;
    const url = provider.getPublicFile(filePath);

    return Promise.resolve(url);
  }

  /**
   * Get full path of a file
   *
   * @param filePath
   * @returns
   */
  public async getModuleFile(
    filePath: string,
    moduleId: string,
    resourceType: ResourceType,
  ): Promise<string> {
    const bucket = FileBucket.matchBucketPath(filePath);
    let url;
    if (!bucket) {
      return Promise.resolve(filePath);
    }
    if (bucket === Bucket.Module) {
      const provider = this.moduleBucket;
      url = provider.getModuleFile(filePath, moduleId, resourceType);
    } else {
      const provider = this.publicBucket;
      url = provider.getPublicFile(filePath);
    }

    return Promise.resolve(url);
  }
}
