import { FileBucket } from 'technical/fileManagement/bucket';
import config from 'config/index';

export class FilePublic extends FileBucket {
  public getPublicFile(filePath: string): string {
    const pathWithoutBucketMark = this.removeBucketPart(filePath);

    return `${config.buckets.public}/${pathWithoutBucketMark}`;
  }
}
