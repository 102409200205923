import { ApolloClient } from '@apollo/client';
import { DOWNLOAD_URL } from 'business/upload/services/queries.gql';
import {
  DownloadUrlQuery,
  DownloadUrlQueryVariables,
  ResourceType,
} from 'generated/graphql';
import { FileBucket } from 'technical/fileManagement/bucket';
import logger from 'technical/logger';

export class FileModule extends FileBucket {
  private client: ApolloClient<unknown>;

  public constructor(apolloClient: ApolloClient<unknown>) {
    super();
    this.client = apolloClient;
  }

  public async getModuleFile(
    resourceUrl: string,
    moduleId: string,
    resourceType: ResourceType,
  ): Promise<string> {
    try {
      const queryResult = await this.client.query<
        DownloadUrlQuery,
        DownloadUrlQueryVariables
      >({
        query: DOWNLOAD_URL,
        variables: { input: { resourceUrl, moduleId, resourceType } },
      });

      if (!queryResult.data.getModuleResourceUri?.uri) {
        throw new Error('download url not retrieve');
      }
      return queryResult.data.getModuleResourceUri?.uri;
    } catch (error) {
      logger.error(`Fail to query the download url mutation`, error);
      throw this.loadError(resourceUrl);
    }
  }

  private loadError(filePath: string): Error {
    return new Error(`Unable to get result for ${filePath}`);
  }
}
