import { ApolloClient, useApolloClient } from '@apollo/client';
import { FileService } from 'technical/fileManagement';

let fileService: FileService;

export function getFileManager(client: ApolloClient<unknown>) {
  if (!fileService) {
    fileService = new FileService(client);
  }

  return fileService;
}

function useFileManager() {
  const client = useApolloClient();

  return getFileManager(client);
}

export default useFileManager;
