import { UserWithPrivateInfos } from 'business/user/types/user';
import { Roles } from 'generated/graphql';

/**
 * Return true if user have the allowed role
 * @param allowedRoles
 * @param user
 * @returns
 */
export function userHasRole(
  allowedRoles: Array<Roles>,
  user: UserWithPrivateInfos | undefined,
): { haveRole: boolean; intersection: Roles[] } {
  // if user not connected we use role Anonymous
  const userRoles = user ? user.roles : [Roles.Anonymous];

  // extract user role that match the allowed
  const intersection = allowedRoles.filter((value) =>
    userRoles.includes(value),
  );

  const haveRole = intersection.length !== 0;

  return { haveRole, intersection };
}

/**
 * Define a header context with the expected role and match of user role for apollo query
 * @param allowedRoles
 * @param user
 * @returns
 */
export function getContextForUser(
  allowedRoles: Array<Roles>,
  user: UserWithPrivateInfos | undefined,
) {
  const hasRole = userHasRole(allowedRoles, user);

  if (user && hasRole.haveRole) {
    // return the context with the first role that match the allowed list
    return {
      headers: {
        'x-hasura-role': hasRole.intersection[0],
      },
    };
  }

  return {};
}
