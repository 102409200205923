import UiConnectedPage from 'lms-ui/layout/connected-page/front';
import Header, { NavItems } from 'business/components/header';
import React, { ReactNode, useEffect, useState } from 'react';
import Media from 'business/components/media';
import MobileHeader from 'lms-ui/layout/header/front/mobile';
import MobileFooter from 'business/components/footer/mobile';
import Footer from 'business/components/footer';
import { useTranslation } from 'next-i18next';
import LoginFooter from 'business/components/footer/login';
import Modal, { useModalState } from 'lms-ui/modal';
import Button from 'lms-ui/button';
import { useAppContext } from 'business/AppBootstrapper';
import { Roles, useAcceptPolicyMutation } from 'generated/graphql';
import { getContextForUser } from 'business/user/services/access';
import CenteredTitle from 'lms-ui/src/modal/title/centeredTitle';
import Flex from 'lms-ui/flex';
import TypographyText from 'lms-ui/typography/text';
import Spacer from 'lms-ui/spacer';
import CenteredFooter from 'lms-ui/src/modal/footer/centeredFooter';
import { FilePathEnum } from 'business/components/footer/services';
import useFileManager from 'technical/fileManagement/hook';
import styles from './index.module.scss';

interface Props {
  children: ReactNode;
  header?: ReactNode;
  mobileHeader?: ReactNode;
  topContent?: ReactNode;
  sticky?: boolean;
  preview?: boolean;
  CGUMandatory?: boolean;
}

/**
 * Layout for pages
 */
const Page = ({
  children,
  header,
  sticky,
  mobileHeader,
  topContent,
  preview,
  CGUMandatory = true,
}: Props) => {
  const { t, i18n } = useTranslation();

  const { isModalVisible, open, close } = useModalState(false);
  const { user, isConnected, setHasAcceptedPolicy } = useAppContext();
  const context = getContextForUser([Roles.User], user ?? undefined);

  const [acceptPolicyMutation] = useAcceptPolicyMutation({
    fetchPolicy: 'network-only',
    context,
  });

  const [privacyPolicyFileUrl, setPrivacyPolicyFileUrl] = useState<
    string | undefined
  >();

  const fileManager = useFileManager();

  const loadDownloadUrls = async () => {
    return i18n.language === 'fr'
      ? fileManager
          .getPublicFile(FilePathEnum.PRIVACY_POLICY_FR)
          .then(setPrivacyPolicyFileUrl)
      : fileManager
          .getPublicFile(FilePathEnum.PRIVACY_POLICY_EN)
          .then(setPrivacyPolicyFileUrl);
  };

  useEffect(() => {
    loadDownloadUrls();
  });

  const onClose = () => {
    acceptPolicyMutation();
    if (setHasAcceptedPolicy) {
      setHasAcceptedPolicy(true);
    }
    close();
  };

  useEffect(() => {
    if (isConnected && CGUMandatory && !preview && !user?.hasAcceptedPolicy) {
      open();
    }
  }, [CGUMandatory, isConnected, open, preview, user]);

  return (
    <>
      <Media
        mobileContent={
          <UiConnectedPage
            sticky={sticky ?? false}
            header={
              mobileHeader ?? (
                <>
                  <LoginFooter>
                    <NavItems isDarkBackground={false} context="mobile" />
                  </LoginFooter>
                  <MobileHeader
                    academyAlt={t('common.logoAcaAlt')}
                    emblemAlt={t('common.emblemLogoAlt')}
                  />
                </>
              )
            }
            footer={<MobileFooter />}
            topContent={topContent}
          >
            {children}
          </UiConnectedPage>
        }
        desktopContent={
          <UiConnectedPage
            sticky={sticky ?? false}
            header={
              (!preview && header) ?? (
                <Header
                  academyAlt={t('common.logoAcaAlt')}
                  emblemAlt={t('common.emblemLogoAlt')}
                />
              )
            }
            footer={<Footer />}
            topContent={topContent}
          >
            {children}
          </UiConnectedPage>
        }
      />

      <Modal
        title={<CenteredTitle text={t('common.modalCGU.title')} />}
        visible={isModalVisible}
        footer={
          <CenteredFooter>
            <Button type="primary" onClick={onClose} size="large">
              {t('common.modalCGU.accept')}
            </Button>
          </CenteredFooter>
        }
        width="700px"
        destroyOnClose
        closable={false}
      >
        <Flex column alignItems="center">
          <TypographyText>{t('common.modalCGU.desc')}</TypographyText>

          <a
            className={styles.downloadLink}
            href={privacyPolicyFileUrl}
            download
            rel="noreferrer"
          >
            {t('common.footer.options.privacyPolicy')}
          </a>

          <Spacer direction="vertical" size="small" align="center">
            <TypographyText>{t('common.modalCGU.law')}</TypographyText>
            <div className={styles.contact}>{t('common.modalCGU.contact')}</div>
          </Spacer>
        </Flex>
      </Modal>
    </>
  );
};

export default Page;
