import GridSpacerFrame from 'lms-ui/gridSpacer/frame';
import GridSpacerSection from 'lms-ui/gridSpacer/section';
import PetitDot from 'lms-ui/petitDot';
import React, { useEffect, useState } from 'react';
import Logo from 'lms-ui/logo';
import classnames from 'classnames';
import youtube from 'lms-ui/assets/logo/youtube.svg';
import emblemLogo from 'lms-ui/assets/logo/emblem-logo.svg';
import facebook from 'lms-ui/assets/logo/facebook.svg';
import instagram from 'lms-ui/assets/logo/instagram.svg';
import linkedin from 'lms-ui/assets/logo/linkedin.svg';
import twitter from 'lms-ui/assets/logo/twitter.svg';
import logoAca from 'lms-ui/assets/logo/logoAca.svg';
import { Trans, useTranslation } from 'next-i18next';
import { ExternalRoutes, Routes } from 'business/router/routes';
import { ExternalLink, LinkableContainer } from 'technical/link';
import useFileManager from 'technical/fileManagement/hook';
import {
  FilePathEnum,
  renderDownloadLink,
} from 'business/components/footer/services';
import styles from './index.module.scss';

interface Props {
  className?: string;
}

const Footer = ({ className }: Props) => {
  const { t, i18n } = useTranslation();

  const [privacyPolicyFileUrl, setPrivacyPolicyFileUrl] = useState<
    string | undefined
  >();

  const fileManager = useFileManager();

  const loadDownloadUrls = async () => {
    return i18n.language === 'fr'
      ? fileManager
          .getPublicFile(FilePathEnum.PRIVACY_POLICY_FR)
          .then(setPrivacyPolicyFileUrl)
      : fileManager
          .getPublicFile(FilePathEnum.PRIVACY_POLICY_EN)
          .then(setPrivacyPolicyFileUrl);
  };

  useEffect(() => {
    loadDownloadUrls();
  });

  return (
    <div className={className}>
      <GridSpacerFrame
        top
        bottom
        left
        right
        frameClassName={styles.iconContainer}
        role="list"
      >
        <PetitDot height={220} className={styles.centered} color="dark">
          <div className={styles.topIcons}>
            <ExternalLink
              href={ExternalRoutes.Youtube}
              role="listitem"
              ariaLabel={t('networks.ariaLabel.youtube')}
            >
              <Logo
                image={youtube}
                alt="youtube"
                className={classnames(styles.icon, styles.notLastChild)}
              />
            </ExternalLink>
            <ExternalLink
              href={ExternalRoutes.Twitter}
              role="listitem"
              ariaLabel={t('networks.ariaLabel.twitter')}
            >
              <Logo
                image={twitter}
                alt="twitter"
                className={classnames(styles.icon, styles.notLastChild)}
              />
            </ExternalLink>
            <ExternalLink
              href={ExternalRoutes.Linkedin}
              role="listitem"
              ariaLabel={t('networks.ariaLabel.linkedin')}
            >
              <Logo image={linkedin} alt="linkedin" className={styles.icon} />
            </ExternalLink>
          </div>
          <div className={styles.bottomIcons}>
            <ExternalLink
              href={ExternalRoutes.Facebook}
              role="listitem"
              ariaLabel={t('networks.ariaLabel.facebook')}
            >
              <Logo
                image={facebook}
                alt="facebook"
                className={classnames(styles.icon, styles.notLastChild)}
              />
            </ExternalLink>
            <ExternalLink
              href={ExternalRoutes.Instagram}
              role="listitem"
              ariaLabel={t('networks.ariaLabel.instagram')}
            >
              <Logo image={instagram} alt="instagram" className={styles.icon} />
            </ExternalLink>
          </div>
        </PetitDot>
      </GridSpacerFrame>
      <GridSpacerFrame left right frameClassName={styles.logoContainer}>
        <GridSpacerSection className={styles.logoSection}>
          <div className={classnames(styles.logo, styles.logoLeft)}>
            <LinkableContainer
              href={Routes.Home}
              ariaLabel={t('logoLink.ariaLabel.aca')}
            >
              <Logo image={logoAca} alt={t('common.logoAcaAlt')} />
            </LinkableContainer>
          </div>
          <div className={classnames(styles.logo, styles.logoRight)}>
            <ExternalLink
              href={ExternalRoutes.Paris2024}
              ariaLabel={t('logolink.ariaLabel.emblem')}
            >
              <Logo image={emblemLogo} alt={t('common.emblemLogoAlt')} />
            </ExternalLink>
          </div>
        </GridSpacerSection>
      </GridSpacerFrame>
      <GridSpacerFrame bottom top left right>
        <p className={styles.context}>
          <Trans
            i18nKey="common.footer.context"
            components={{
              blue: <span className={styles.contextTitle} />,
            }}
          />
        </p>
      </GridSpacerFrame>
      <div className={styles.link}>
        <div className={styles.options}>
          <div className={styles.optionText}>
            <LinkableContainer
              className={styles.optionText}
              href={`${Routes.Footer}/legals`}
            >
              {t('common.footer.options.legalMentions')}
            </LinkableContainer>
          </div>
          <div className={styles.optionText}>
            <LinkableContainer
              className={styles.optionText}
              href={`${Routes.Footer}/access`}
            >
              {t('common.footer.options.accessSite')}
            </LinkableContainer>
          </div>
          <div className={classnames(styles.optionText)}>
            {renderDownloadLink(
              t('common.footer.options.privacyPolicy'),
              privacyPolicyFileUrl,
            )}
          </div>
          <LinkableContainer
            href={Routes.Sitemap}
            className={classnames(styles.optionText)}
          >
            {t('common.footer.options.sitemap')}
          </LinkableContainer>
        </div>
      </div>
    </div>
  );
};

export default Footer;
