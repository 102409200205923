import Link from 'next/link';

interface LinkableContainerProps {
  href?: string;
  children: React.ReactNode;
  className?: string;
  'data-testid'?: string;
  role?: string;
  ariaLabel?: string;
}

export const LinkableContainer = ({
  href,
  children,
  className,
  'data-testid': dataTestId,
  role,
  ariaLabel,
}: LinkableContainerProps) => {
  if (href) {
    return (
      <Link href={href} passHref role={role}>
        <a
          href={href}
          className={className}
          data-testid={dataTestId}
          aria-label={ariaLabel}
        >
          {children}
        </a>
      </Link>
    );
  }
  return <>{children}</>;
};

export const ExternalLink = ({
  href,
  children,
  className,
  'data-testid': dataTestId,
  role,
  ariaLabel,
}: LinkableContainerProps) => {
  if (href) {
    return (
      <a
        href={href}
        target="_blank"
        rel="noopener noreferrer"
        className={className}
        data-testid={dataTestId}
        role={role}
        aria-label={ariaLabel}
      >
        {children}
      </a>
    );
  }
  return <>{children}</>;
};
