import { useAppContext } from 'business/AppBootstrapper';
import { UserWithPrivateInfos } from 'business/user/types/user';
import { ReactElement, ReactNode, useCallback } from 'react';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import config from 'config/index';
import { Routes } from 'business/router/routes';
import Header from 'lms-ui/src/layout/header/front';
import TypographyText from 'lms-ui/src/typography/text';
import { Divider } from 'lms-ui/src/divider';
import ClickableAvatar from 'lms-ui/src/clickable-avatar';
import { capitalizeFirstLetter } from 'technical/string/format';
import { LinkableContainer } from 'technical/link';
import {
  RightArrowIcon,
  SchoolIcon,
  SpacesIcon,
  SwitchUserIcon,
} from 'lms-ui/icons';
import { ButtonLanguage } from 'lms-ui/switch-language';
import Screen from 'business/components/media/screenTypes';

import classnames from 'classnames';
import { useSetHasSeenOnboardingMutation } from 'generated/graphql';
import Button from 'lms-ui/button';
import Flex from 'lms-ui/flex';
import styles from './index.module.scss';

interface MenuElementProps {
  href: string;
  text: string;
  icon: ReactElement;
  ariaLabel: string;
}

/* Remove unused scripts genereated each time the language switches.
    Could lead to performance issues if not removed. */
const deleteUnusedPartnersScripts = () => {
  const scriptElements = Array.from(document.getElementsByTagName('script'));
  scriptElements.forEach((scriptElement) => {
    const src = scriptElement.getAttribute('src');
    if (
      config.sponsor.footerStyle &&
      src?.startsWith(config.sponsor.footerStyle)
    ) {
      scriptElement.remove();
    }
  });
};

const getUserInitials = (user: UserWithPrivateInfos) =>
  ((user.firstName || '')[0] + (user.lastName || '')[0] || '').toUpperCase();

const capitalizeUserName = (user: UserWithPrivateInfos) =>
  `${capitalizeFirstLetter(user.firstName)} ${capitalizeFirstLetter(
    user.lastName,
  )}`;

export const NavItems = ({
  isDarkBackground,
  context,
}: {
  isDarkBackground: boolean;
  context?: 'mobile' | 'desktop';
}) => {
  const { t, i18n } = useTranslation();
  const { user } = useAppContext();
  const router = useRouter();
  const { pathname, asPath, query } = router;
  const [setHasSeenOnboarding] = useSetHasSeenOnboardingMutation();
  const goToDashboard = async () => {
    await setHasSeenOnboarding({
      variables: {
        userId: user?.id,
      },
    });
    router.push({
      pathname: Routes.Dashboard,
    });
  };
  const logOut = useCallback(async () => {
    await fetch(Routes.Logout);
    router.push(Routes.Home);
  }, [router]);

  const manageAccount = useCallback(async () => {
    router.push(Routes.ManageAccount);
  }, [router]);

  const renderElementMenu = ({ href, text, icon }: MenuElementProps) => {
    const isActive = href === router.pathname;
    return (
      <li
        key={text}
        className={classnames(styles.navLink, {
          [styles.active]: isActive,
        })}
      >
        <Link href={href} passHref>
          {/* href pass by Link */}
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          <a href="#" className={styles.linkMenuContent}>
            {icon}
            <span className={styles.linkText}>{text}</span>
          </a>
        </Link>
      </li>
    );
  };

  const switchAriaLabel = t('common.language.switch', {
    context: i18n.resolvedLanguage,
  });

  const switchAriaLabelCurrent = t('common.language.switchCurrent', {
    context: i18n.resolvedLanguage,
  });

  const unconnectedRoutes = [
    {
      href: '/',
      text: t('navbar.academy'),
      icon: <SchoolIcon />,
      ariaLabel: t('menu.ariaLabel.academy'),
    },
  ];

  const connectedRoutes = [
    {
      href: '/dashboard',
      text: t('navbar.courses', { context }),
      icon: <SpacesIcon />,
      ariaLabel: t('menu.ariaLabel.dashboard'),
    },
    {
      href: '/',
      text: t('navbar.academy'),
      icon: <SchoolIcon />,
      ariaLabel: t('menu.ariaLabel.academy'),
    },
  ];

  const placement = context === 'mobile' ? 'topRight' : undefined;

  if (!user) {
    return (
      <ul
        className={classnames(styles.navList, {
          [styles.whiteNavLink]: isDarkBackground,
        })}
      >
        {unconnectedRoutes.map(renderElementMenu)}
        <li className={styles.avatar} key="rightItems">
          {unconnectedRoutes.length ? (
            <Divider type="vertical" className={styles.verticalDivider} />
          ) : null}
          <ButtonLanguage
            ariaLabel={switchAriaLabel}
            ariaLabelCurrent={switchAriaLabelCurrent}
            className={styles.switch}
            value={i18n.resolvedLanguage}
            color={isDarkBackground ? 'light' : 'dark'}
            onChange={async () => {
              deleteUnusedPartnersScripts();
              if (i18n.resolvedLanguage === 'fr') {
                await router.push({ pathname, query }, asPath, {
                  locale: 'en',
                });
              } else {
                await router.push({ pathname, query }, asPath, {
                  locale: 'fr',
                });
              }
            }}
          />
        </li>
      </ul>
    );
  }

  return (
    <ul
      className={classnames(styles.navList, {
        [styles.whiteNavLink]: isDarkBackground,
      })}
    >
      {!config.public.firstConnectionPageEnabled || user.hasSeenOnboarding ? (
        connectedRoutes.map(renderElementMenu)
      ) : (
        <li className={styles.finishOnboardingButton}>
          <Button
            type="primary"
            className={styles.ctaContainer}
            onClick={goToDashboard}
          >
            <Flex alignItems="center" justify="space-between">
              {t('pages.firstConnection.headerCta')}
              <RightArrowIcon />
            </Flex>
          </Button>
        </li>
      )}
      <li className={styles.avatar} key="rightItems">
        <Divider type="vertical" className={styles.verticalDivider} />
        <ButtonLanguage
          ariaLabel={switchAriaLabel}
          ariaLabelCurrent={switchAriaLabelCurrent}
          className={classnames(styles.switch, Screen.Medium)}
          value={i18n.resolvedLanguage}
          color={isDarkBackground ? 'light' : 'dark'}
          onChange={() => {
            deleteUnusedPartnersScripts();
            if (i18n.resolvedLanguage === 'fr') {
              router.push({ pathname, query }, asPath, { locale: 'en' });
            } else {
              router.push({ pathname, query }, asPath, { locale: 'fr' });
            }
          }}
        />
        <ClickableAvatar
          clickableAvatarLabel={t('navbar.clickableAvatarLabel')}
          placement={placement}
          text={getUserInitials(user)}
          email={user.email}
          name={capitalizeUserName(user)}
          logoutText={t('common.logOut')}
          onLogoutClick={logOut}
          manageAccountText={t('common.manageAccount')}
          onManageAccountClick={manageAccount}
          switchLangElement={
            context !== 'mobile' ? undefined : (
              <ButtonLanguage
                ariaLabel={switchAriaLabel}
                ariaLabelCurrent={switchAriaLabelCurrent}
                className={classnames(styles.switch, Screen.Small)}
                value={i18n.resolvedLanguage}
                onChange={() => {
                  deleteUnusedPartnersScripts();
                  if (i18n.resolvedLanguage === 'fr') {
                    router.push({ pathname, query }, asPath, { locale: 'en' });
                  } else {
                    router.push({ pathname, query }, asPath, { locale: 'fr' });
                  }
                }}
              />
            )
          }
          interListElement={
            user.isAdmin ? (
              <>
                <LinkableContainer href={config.public.backOfficeUrl}>
                  <TypographyText className={styles.adminLink}>
                    <SwitchUserIcon /> {t('common.switchAdmin')}
                  </TypographyText>
                </LinkableContainer>
              </>
            ) : undefined
          }
        />
      </li>
    </ul>
  );
};

const HeaderComponent = ({
  academyAlt,
  emblemAlt,
  courseImageUri,
  whiteMode,
  bottomMenu,
  className,
}: {
  academyAlt: string;
  emblemAlt: string;
  courseImageUri?: string;
  whiteMode?: boolean;
  bottomMenu?: ReactNode;
  className?: string;
}) => {
  const { user } = useAppContext();
  const { t } = useTranslation();

  return (
    <Header
      academyAlt={academyAlt}
      emblemAlt={emblemAlt}
      emblemAria={t('logolink.ariaLabel.emblem')}
      academyAria={t('logolink.ariaLabel.aca')}
      imageHeader={courseImageUri}
      headerClassName={user ? styles.headerFront : undefined}
      navItems={<NavItems isDarkBackground={!!courseImageUri} />}
      whiteMode={whiteMode}
      bottomMenu={bottomMenu}
      skipToContentAria={t('pages.skipToContentAria')}
      className={className}
    />
  );
};

export default HeaderComponent;
