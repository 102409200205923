import { GetModuleResourceUriInput } from 'generated/graphql';

import gql from 'graphql-tag';

export const UPLOAD_URL = gql`
  mutation UploadUrl($fileName: String!) {
    createUploadUrl(fileName: $fileName) {
      uploadUrl
      publicUrl
      filePath
    }
  }
`;

export const DOWNLOAD_URL = gql`
  query DownloadUrl($input: GetModuleResourceUriInput!) {
    getModuleResourceUri(input: $input) {
      uri
    }
  }
`;
