import React, { PropsWithChildren } from 'react';
import classnames from 'classnames';
import styles from './index.module.scss';

interface Props {
  className?: string;
}

const LoginFooter = ({ className, children }: PropsWithChildren<Props>) => {
  return (
    <div className={classnames(className, styles.loginFooter)}>
      <div className={classnames(styles.divider, styles.left)} />
      <nav className={styles.nav}>{children}</nav>
      <div className={classnames(styles.divider, styles.right)} />
    </div>
  );
};

export default LoginFooter;
