import { Routes, ExternalRoutes } from 'business/router/routes';
import facebook from 'lms-ui/assets/logo/facebook.svg';
import instagram from 'lms-ui/assets/logo/instagram.svg';
import linkedin from 'lms-ui/assets/logo/linkedin.svg';
import logoAca from 'lms-ui/assets/logo/logoAca.svg';
import emblemLogo from 'lms-ui/assets/logo/emblem-logo.svg';
import twitter from 'lms-ui/assets/logo/twitter.svg';
import youtube from 'lms-ui/assets/logo/youtube.svg';
import GridSpacerFrame from 'lms-ui/gridSpacer/frame';
import GridSpacerSection from 'lms-ui/gridSpacer/section';
import Logo from 'lms-ui/logo';
import PetitDot from 'lms-ui/petitDot';
import { Trans, useTranslation } from 'next-i18next';
import React, { ReactNode, useEffect, useState } from 'react';
import { ExternalLink, LinkableContainer } from 'technical/link';
import ArchGroup from 'lms-ui/layout/arch/arch-group';
import ArchPicture from 'lms-ui/layout/arch/arch-picture';
import joResource1 from 'lms-ui/assets/logo/joResource1.jpeg';
import joResource2 from 'lms-ui/assets/logo/joResource2.jpeg';
import joResource3 from 'lms-ui/assets/logo/joResource3.jpeg';
import {
  FilePathEnum,
  renderDownloadLink,
} from 'business/components/footer/services';
import useFileManager from 'technical/fileManagement/hook';
import styles from './index.module.scss';

interface Props {
  className?: string;
}

interface SocialNetworkProps {
  name: string;
  link: string;
  image: ReactNode;
  role?: string;
  ariaLabel?: string;
}

const SocialNetwork = ({
  name,
  link,
  image,
  role,
  ariaLabel,
}: SocialNetworkProps) => {
  return (
    <div className={styles.socialNetworkContainer} role={role}>
      <ExternalLink href={link} ariaLabel={ariaLabel}>
        <PetitDot height={170} className={styles.centerVertically}>
          <Logo image={image} alt={name} className={styles.icon} />
        </PetitDot>
      </ExternalLink>
    </div>
  );
};

const Footer = ({ className }: Props) => {
  const { t, i18n } = useTranslation();

  const [privacyPolicyFileUrl, setPrivacyPolicyFileUrl] = useState<
    string | undefined
  >();

  const fileManager = useFileManager();

  const loadDownloadUrls = async () => {
    return i18n.language === 'fr'
      ? fileManager
          .getPublicFile(FilePathEnum.PRIVACY_POLICY_FR)
          .then(setPrivacyPolicyFileUrl)
      : fileManager
          .getPublicFile(FilePathEnum.PRIVACY_POLICY_EN)
          .then(setPrivacyPolicyFileUrl);
  };

  useEffect(() => {
    loadDownloadUrls();
  });

  return (
    <div className={className}>
      <GridSpacerFrame top bottom left right>
        <GridSpacerSection className={styles.content} role="list">
          <GridSpacerSection column className={styles.youtube}>
            <SocialNetwork
              name="youtube"
              link={ExternalRoutes.Youtube}
              image={youtube}
              role="listitem"
              ariaLabel={t('networks.ariaLabel.youtube')}
            />
            <div className={styles.asset}>
              <LinkableContainer
                href={Routes.Home}
                ariaLabel={t('logolink.ariaLabel.emblem')}
              >
                <Logo
                  image={logoAca}
                  alt={t('common.logoAcaAlt')}
                  className={styles.logo}
                />
              </LinkableContainer>
            </div>
          </GridSpacerSection>
          <GridSpacerSection column className={styles.facebook}>
            <SocialNetwork
              name="facebook"
              link={ExternalRoutes.Facebook}
              image={facebook}
              role="listitem"
              ariaLabel={t('networks.ariaLabel.facebook')}
            />
            <p className={styles.context}>
              <Trans
                i18nKey="common.footer.context"
                components={{
                  blue: <span className={styles.contextTitle} />,
                }}
              />
            </p>
          </GridSpacerSection>
          <GridSpacerSection column className={styles.linkedin}>
            <GridSpacerSection>
              <SocialNetwork
                name="linkedin"
                link={ExternalRoutes.Linkedin}
                image={linkedin}
                role="listitem"
                ariaLabel={t('networks.ariaLabel.linkedin')}
              />
              <SocialNetwork
                name="twitter"
                link={ExternalRoutes.Twitter}
                image={twitter}
                role="listitem"
                ariaLabel={t('networks.ariaLabel.twitter')}
              />
            </GridSpacerSection>
            <div className={styles.arch}>
              <ArchGroup croped>
                <ArchPicture src={joResource1} />
                <ArchPicture src={joResource2} />
                <ArchPicture src={joResource3} />
              </ArchGroup>
            </div>
          </GridSpacerSection>
          <GridSpacerSection column className={styles.instagram}>
            <SocialNetwork
              name="instagram"
              link={ExternalRoutes.Instagram}
              image={instagram}
              role="listitem"
              ariaLabel={t('networks.ariaLabel.instagram')}
            />
            <div className={styles.asset}>
              <ExternalLink
                href={ExternalRoutes.Paris2024}
                ariaLabel={t('logolink.ariaLabel.emblem')}
              >
                <Logo
                  image={emblemLogo}
                  alt={t('common.emblemLogoAlt')}
                  className={styles.logo}
                />
              </ExternalLink>
            </div>
          </GridSpacerSection>
        </GridSpacerSection>
      </GridSpacerFrame>
      <div className={styles.link}>
        <ul className={styles.options}>
          <li className={styles.optionContent}>
            <LinkableContainer
              className={styles.optionContent}
              href={`${Routes.Footer}/legals`}
            >
              {t('common.footer.options.legalMentions')}
            </LinkableContainer>
          </li>
          <li className={styles.optionContent}>
            <LinkableContainer
              className={styles.optionContent}
              href={`${Routes.Footer}/access`}
            >
              {t('common.footer.options.accessSite')}
            </LinkableContainer>
          </li>
          <li className={styles.optionContent}>
            {renderDownloadLink(
              t('common.footer.options.privacyPolicy'),
              privacyPolicyFileUrl,
            )}
          </li>
          <li>
            <LinkableContainer
              href={Routes.Sitemap}
              className={styles.optionContent}
            >
              {t('common.footer.options.sitemap')}
            </LinkableContainer>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Footer;
